import dayjs, { Dayjs } from 'dayjs'
import { useMemo, useState } from 'react'
import { ReadableTask, TaskFilters } from '../../interfaces'
import { useMyTasks } from '../../hooks'
import { TemplateEntryType, useTemplatesForTasks } from '@/features/templates'
import { useValidationsRequestByEntityIds } from '@/features/validationRequests'
import { useIssuesByIds } from '@/features/issues'

export const useMonthlyNavigation = () => {
  const [currentDate, setCurrentDate] = useState<Dayjs>(() => dayjs())

  const monthData = useMemo(() => {
    return {
      startDate: currentDate.startOf('month'),
      endDate: currentDate.endOf('month'),
      monthDays: currentDate.daysInMonth(),
      month: currentDate.month(),
      year: currentDate.year(),
      displayMonthAndYear: currentDate.format('MMMM YYYY'),
    }
  }, [currentDate])

  const previousMonth = () => setCurrentDate(previous => previous.subtract(1, 'month'))
  const nextMonth = () => setCurrentDate(previous => previous.add(1, 'month'))
  const goToDate = (date: Date | string | number) => {
    setCurrentDate(dayjs(date))
  }
  const goToNow = () => setCurrentDate(dayjs())

  return {
    ...monthData,
    previousMonth,
    nextMonth,
    goToDate,
    goToNow,
  }
}

const useGroupTasksByTemplate = (targetDate: dayjs.Dayjs, tasks: ReadableTask[]) => {
  return useMemo(
    () =>
      tasks.reduce<{ [key: string]: ReadableTask[] }>((accumulator, task) => {
        const dueDate = dayjs(task.dueDate)
        const templateId = task.formId

        if (dueDate.isSame(targetDate, 'month')) {
          if (!accumulator[templateId]) {
            accumulator[templateId] = []
          }

          accumulator[templateId].push(task)
        }
        return accumulator
      }, {}),
    [tasks, targetDate]
  )
}

export const useTasksInMonth = ({
  filters,
  monthRange: { startDate, endDate },
}: {
  filters?: TaskFilters
  monthRange: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs }
}) => {
  const monthFilters =
    filters?.startDate || filters?.endDate
      ? undefined
      : {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        }

  const {
    tasks,
    isLoading: isTaskLoading,
    isFetched: isMyTasksFetched,
  } = useMyTasks(
    {
      ...(filters as Record<string, string | string[]>),
      ...monthFilters,
    },
    {
      keepPreviousData: true,
    }
  )

  const monthTasksFormIds = tasks.map(task => task.formId)
  const templateFormIds = [...(filters?.formId || []), ...Array.from(new Set(monthTasksFormIds))]

  const {
    templates,
    isLoading: isTemplatesLoading,
    isFetched: isTemplatesFetched,
  } = useTemplatesForTasks(
    { sortBy: 'name', sortOrder: 'asc' },
    {
      select: templates => {
        const formIds = templateFormIds
        if (formIds && formIds.length > 0) {
          return templates.filter(template => formIds.includes(template._id))
        } else if (formIds && formIds.length === 0) {
          return []
        }
        return templates
      },
    }
  )

  const { validationRequests, isLoading: isValidationRequestsLoading } =
    useValidationsRequestByEntityIds(
      tasks.map(task => task._id),
      {
        enabled: isMyTasksFetched,
      }
    )

  const issuesIds = useMemo(
    () =>
      tasks.flatMap(({ entries }) =>
        entries
          // Filter entries type issue that are active
          .filter(
            entry => entry.type === TemplateEntryType.ISSUE_REFERENCE && entry.values.length >= 1
          )
          // Extract issues ids
          .flatMap(({ values }) => values as string[])
      ),
    [tasks]
  )

  const { issues, isLoading: isIssuesLoading } = useIssuesByIds(issuesIds, {
    enabled: issuesIds.length !== 0,
  })

  const fulfilledTasks = useMemo<ReadableTask[]>(() => {
    return tasks
      .map(task => {
        const taskValidationRequests = validationRequests.filter(
          validationRequest => validationRequest.entity.id === task._id
        )
        const lastValidationRequest = taskValidationRequests.sort(
          (requestA, requestB) =>
            new Date(requestA.createdAt).getTime() - new Date(requestB.createdAt).getTime()
        )[0]
        return {
          ...task,
          template: templates.find(template => template._id === task.formId),
          issues: issues.filter(issue => issue.associatedTaskId === task._id),
          validationRequests: taskValidationRequests,
          lastValidationRequest,
        }
      }) // sort by template name and creation date
      .sort(
        (taskA, taskB) =>
          new Intl.Collator().compare(taskA.template?.name ?? '', taskB.template?.name ?? '') ||
          new Date(taskA.createdAt).getTime() - new Date(taskB.createdAt).getTime()
      )
  }, [tasks, templates, issues, validationRequests])

  const tasksGroupByTemplate = useGroupTasksByTemplate(startDate, fulfilledTasks)

  return {
    isLoading:
      isTaskLoading || isTemplatesLoading || isValidationRequestsLoading || isIssuesLoading,
    isFetched: isMyTasksFetched && isTemplatesFetched,
    templates,
    tasks: fulfilledTasks,
    tasksGroupByTemplate,
  }
}
