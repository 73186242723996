import {
  ReactNode,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react'

interface LayoutState {
  sidebar: {
    isOpen: boolean
  }

  modalCreateNew: {
    isOpen: boolean
  }

  mainContentElementRef: RefObject<HTMLElement>

  toggleSidebar: (value?: boolean) => void
  toggleModalCreateNew: (value?: boolean) => void

  scrollToTop: () => void
}

const LayoutContext = createContext<LayoutState | null>(null)
LayoutContext.displayName = 'LayoutContext'

export const LayoutProvider = ({ children }: { children?: ReactNode }): JSX.Element => {
  const [sidebarState, setSidebarState] = useState({
    isOpen: false,
  })
  const [modalCreateNewState, setModalCreateNewState] = useState({
    isOpen: false,
  })

  const mainContentElementRef = useRef<HTMLElement>(null)

  const toggleSidebar = useCallback((value?: boolean) => {
    if (value === undefined) {
      setSidebarState(currentState => ({ ...currentState, isOpen: !currentState.isOpen }))
    } else {
      setSidebarState(currentState => ({ ...currentState, isOpen: value }))
    }
  }, [])

  const toggleModalCreateNew = useCallback((value?: boolean) => {
    if (value === undefined) {
      setModalCreateNewState(currentState => ({ ...currentState, isOpen: !currentState.isOpen }))
    } else {
      setModalCreateNewState(currentState => ({ ...currentState, isOpen: value }))
    }
  }, [])

  // Scroll to top of main content element
  const scrollToTop = () => {
    mainContentElementRef.current?.scrollTo({ top: 0 })
  }

  const value = {
    sidebar: sidebarState,
    modalCreateNew: modalCreateNewState,
    mainContentElementRef,
    toggleSidebar,
    toggleModalCreateNew,
    scrollToTop,
  }

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export const useLayout = (): LayoutState => {
  const context = useContext(LayoutContext)
  if (!context) {
    throw new Error(`useLayout must be used within an LayoutProvider`)
  }
  return context
}
