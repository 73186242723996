import { PDF_MANUAL_EN_URL, PDF_MANUAL_ES_URL } from '@/app/constants'
import { filterAlgorithm } from '@/hooks/use-filter-algorithm'
import i18n from '@/i18n'
import { noop } from '@/utils/noop'
import { Button, COLOR, ItemCardGroup, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { CogIcon } from '@heroicons/react/outline'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'

export type MetadataTemplatesListItem = {
  id: string
  name: string
  entries: string[]
  totalProducts: number
}
export type MetadataTemplatesListItemClickResult = Row<MetadataTemplatesListItem>

export interface MetadataTemplatesListProps {
  globalFilter?: string
  /**
   * Must be memoized to avoid extra renders
   */
  data: MetadataTemplatesListItem[]
  /**
   * Must be memoized to avoid extra renders
   */
  onItemManage: (itemId: string) => void
}

export const MetadataTemplatesList = memo(
  ({ globalFilter, data, onItemManage = noop }: MetadataTemplatesListProps) => {
    const { t } = useTranslation('nsMetadataTemplatesListPage')
    const dataFiltered = useMemo(
      () =>
        globalFilter
          ? data.filter(({ id, ...itemWithoutId }) =>
              filterAlgorithm(itemWithoutId as unknown as Record<string, string>, globalFilter)
            )
          : data,
      [data, globalFilter]
    )

    const language = i18n.language

    const userManualUrl = useMemo(() => {
      if (language === 'es') {
        return PDF_MANUAL_ES_URL
      }
      return PDF_MANUAL_EN_URL
    }, [language])

    return dataFiltered.length === 0 ? (
      <div className='w-full'>
        <div className='text-center p-8 space-y-6'>
          <p className='text-4xl leading-10 font-extrabold text-center whitespace-pre-wrap'>
            {t('table.noData')}
          </p>

          <Button
            color='secondary'
            size={SIZE.EXTRA_LARGE}
            className='m-auto'
            onClick={() => {
              window.analytics.track('ACTION_CLICK_HELP', {
                Source: 'Data templates section',
              })
              window.open(userManualUrl, '_blank')
            }}
          >
            {t('table.needHelp')}
          </Button>
        </div>
      </div>
    ) : (
      <ItemCardGroup
        items={dataFiltered.map(({ id, name, entries, totalProducts }) => ({
          id,
          title: name,
          description: (
            <>
              <b>{t('table.fields')}:</b> {entries.join(', ')}
            </>
          ),
          badgeText: t('table.productsCount', {
            count: totalProducts,
          }),
          badgeColor: COLOR.GREEN,
        }))}
        actions={[
          {
            text: t('table.manage'),
            icon: CogIcon,
            onItemClick: onItemManage,
          },
        ]}
        previousText={t('table.pagination.previous')}
        nextText={t('table.pagination.next')}
        showText={t('table.pagination.show')}
        toText={t('table.pagination.to')}
        ofText={t('table.pagination.of')}
        rowsText={t('table.pagination.rows')}
      />
    )
  }
)
