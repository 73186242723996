import { IssueModalMode } from '@/features/issues'
import { TAB_INDEX } from '@/pages/tasks/TasksList/TasksListPage'

/**
 * @description Get the issue modal mode based on the active tab, this is used to handle the issue modal autoclose.
 */
export const getIssueModalMode = (activeTab: TAB_INDEX) => {
  if (activeTab === TAB_INDEX.TASKS_WITH_RESOLVED_ISSUES) {
    return IssueModalMode.RESOLVED
  } else if (activeTab === TAB_INDEX.TASKS_WITH_ISSUES) {
    return IssueModalMode.ACTIVE
  }
  return undefined
}
