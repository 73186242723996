import { api } from '@/app/api'
import { CompanyCreation } from '../company'
import { SSODetectionResult, VerifyTokenResponse } from './interfaces'
import { mapResponseToVerifyTokenResult } from './utils'
import { AxiosError } from 'axios'

const AUTH_URL = '/auth'

export const verifyToken = async (options?: {
  ignoreActivation?: boolean
}): Promise<VerifyTokenResponse> => {
  const path = options?.ignoreActivation ? '/verify?ignoreActivation=true' : '/verify'
  const { data } = await api.get<VerifyTokenResponse>(`${AUTH_URL}${path}`)

  return mapResponseToVerifyTokenResult(data)
}

/**
 * Registry a new company
 */
export const register = async (
  company: CompanyCreation,
  headers?: Record<string, string>
): Promise<string> => {
  const { data: paymentUrl } = await api.post<string>(`${AUTH_URL}/register`, company, { headers })
  return paymentUrl
}

export const changeLanguage = async (language: string) => {
  await api.patch<void>(`${AUTH_URL}/language/change`, {
    language,
  })
}

/**
 * Generate a new company's API key
 *
 * @returns {Promise<{ apiKeyId: string, apiKey: string }>} new API key info
 */
export const generateApiKey = async (): Promise<{
  apiKeyId: string
  apiKey: string
}> => {
  const { data: apiKeyInfo } = await api.get<{
    apiKeyId: string
    apiKey: string
  }>(`${AUTH_URL}/apiKey`)

  return apiKeyInfo
}

/**
 * Get all API keys ids
 *
 * @returns {Promise<string[]>} All current API keys ids
 */
export const getApiKeys = async (): Promise<string[]> => {
  const { data: apiKeysIds } = await api.get<string[] | void>(`${AUTH_URL}/apiKeys`)

  return apiKeysIds || []
}

/**
 * Delete an API key by id
 *
 * @param apiKeyId - the api key id to delete
 */
export const deleteApiKey = async (apiKeyId: string): Promise<void> => {
  await api.delete<void>(`${AUTH_URL}/apiKey/${apiKeyId}`)
}

/**
 * Update the current password
 *
 * @param {{ currentPassword: string, newPassword: string }}
 *
 * @returns {Promise<void>}
 */
export const updatePassword = async ({
  currentPassword,
  newPassword,
}: {
  currentPassword: string
  newPassword: string
}): Promise<void> => {
  await api.post<void>(`${AUTH_URL}/password`, {
    currentPassword,
    newPassword,
  })
}

/**
 * Update the current password
 *
 * @param email - user email to send the recovery password
 * @param redirectURL - the dashboard url to use the recovery token
 * @returns {Promise<void>}
 */
export const requestPasswordRecovery = async (
  email: string,
  redirectURL: string,
  headers?: Record<string, string>
): Promise<void> => {
  await api.post<void>(
    `${AUTH_URL}/password/recovery`,
    {
      email,
      redirectURL,
    },
    { headers }
  )
}

/**
 * Update the current password with a password recovery token
 *
 * @param token - The password recovery token
 * @param password - The new password
 */
export const passwordReset = async (
  token: string,
  password: string,
  headers?: Record<string, string>
): Promise<void> => {
  try {
    await api.post<void>(
      `${AUTH_URL}/password/change`,
      {
        token,
        password,
      },
      { headers }
    )
  } catch (error) {
    throw new Error((error as AxiosError).response?.data)
  }
}

export const detectSSOProvider = async (email: string): Promise<SSODetectionResult> => {
  const { data } = await api.post<SSODetectionResult>(`${AUTH_URL}/sso/detect`, {
    email,
  })
  return data
}

export const ensureSSOUser = async (): Promise<void> => {
  await api.post<SSODetectionResult>(`${AUTH_URL}/sso/user`)
}
