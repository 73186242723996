import { isDevelopment } from '@/utils/check-env'
import { AnalyticsInstance, AnalyticsSourceMiddlewareData } from './interfaces'

export const analyticsInitialize = (writeKey: string) => {
  window.analytics.load(writeKey)

  // Log tracks in development mode
  window.analytics.addSourceMiddleware(({ payload, next }: AnalyticsSourceMiddlewareData) => {
    if (isDevelopment()) {
      console.debug(payload.obj.event || 'Page', payload.obj.properties)
    }

    next(payload)
  })
}

export const useAnalytics = (): AnalyticsInstance => {
  return window.analytics
}
