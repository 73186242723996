import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  InlineSelectField,
  InlineSelectFieldProps,
  SelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { useMemo, useState } from 'react'
import { usePaginatedReceptions } from '../hooks'
import { ReceptionFilters } from '../interfaces'

export const ReceptionsInlineSelectField = <isMulti extends boolean>({
  stakeHolderId,
  stakeHolderProductId,
  all,
  ...props
}: Omit<InlineSelectFieldProps<isMulti>, 'options'> &
  Omit<ReceptionFilters, 'search'> & {
    all?: { label: string; value: string }
  }) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const { receptions, fetchNextPage } = usePaginatedReceptions({
    filters: {
      search: debouncedSearch,
      stakeHolderId,
      stakeHolderProductId,
    },
  })

  const receptionsOptions: SelectorOption[] = useMemo(() => {
    const options = receptions.map(reception => ({
      label: reception.reference,
      value: reception._id,
    }))

    let allOption: SelectorOption[] = []
    if (all?.label && all?.value) {
      allOption = [{ label: all.label, value: all.value }]
    }

    return [...allOption, ...options]
  }, [receptions, all?.label, all?.value])

  return (
    <InlineSelectField
      {...props}
      searchable
      options={receptionsOptions}
      onSearch={inputValue => {
        setSearch(inputValue)
      }}
      onMenuScrollToBottom={() => {
        fetchNextPage()
      }}
    />
  )
}
