import { api } from '@/app/api'
import { Metadata, MetadataCreation, MetadataUpdate } from './interfaces'

/**
 * Define the url path
 * @constant
 */
export const METADATA_URL = '/metadata/metadatas'

export const create = async (metadata: MetadataCreation): Promise<void> => {
  await api.post<void>(METADATA_URL, metadata)
}

/**
 * Get Metadatas by belongsTo ids
 *
 * @returns All the Metadatas that match
 */
export const getByBelongsTos = async (belongsTos: string[]): Promise<Metadata[]> => {
  if (belongsTos.length === 0) return []

  const { data: metadatas } = await api.post<Metadata[] | void>(`${METADATA_URL}/belongsTos`, {
    ids: belongsTos,
  })
  return metadatas || []
}

/**
 * Update metadata by id
 *
 * @param metadataId Metadata id to update
 * @param metadata New Metadata properties
 */
export const update = async (metadataId: string, metadata: MetadataUpdate): Promise<void> => {
  await api.patch<void>(`${METADATA_URL}/${encodeURIComponent(metadataId)}`, metadata)
}

/**
 * Delete metadata by id
 *
 * @param metadataId Metadata id to delete
 */
export const remove = async (metadataId: string): Promise<void> => {
  await api.delete<void>(`${METADATA_URL}/${encodeURIComponent(metadataId)}`)
}
