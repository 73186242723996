export enum TraceabilityView {
  TREE,
  MAP,
}

export enum TraceabilityMode {
  COMPANY = 'Company',
  PRODUCT = 'Product',
  TRU = 'TRU',
}
