import { downloadPublicDocument, useDocumentById } from '@/features/documents'
import { openTaskFile } from '@/features/tasks'
import { Entry } from '@/features/entries'
import { TemplateEntryReferenceValue, TemplateEntryType } from '@/features/templates'
import { Button, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { useTranslation } from 'react-i18next'
import backgroundTransparent from '@/assets/images/backgroundTransparent.png'
import config from '@/config'

interface IssueEntryProps {
  entry: Entry
}

const EMPTY_VALUE = '-'

export const IssueEntry = ({ entry: { type, values, mimeType } }: IssueEntryProps) => {
  const { t } = useTranslation('nsEntriesReport')

  const { document } = useDocumentById(
    type === TemplateEntryType.DOCUMENT && values[0] ? (values[0] as string) : '',
    {
      enabled: type === TemplateEntryType.DOCUMENT && !!values[0],
    }
  )

  const handleClickDocument = async (documentId: string) => {
    try {
      await downloadPublicDocument(documentId)
    } catch {}
  }

  switch (type) {
    case TemplateEntryType.LONG_TEXT:
    case TemplateEntryType.NUMBER:
    case TemplateEntryType.SHORT_TEXT:
    case TemplateEntryType.SINGLE_CHOICE:
      return <>{values[0] || EMPTY_VALUE}</>

    case TemplateEntryType.MULTIPLE_CHOICE:
      return <>{values && values.length !== 0 ? values.join(', ') : EMPTY_VALUE}</>

    case TemplateEntryType.BOOLEAN:
      return <>{t(`boolean.${values[0] || 'false'}`)}</>

    case TemplateEntryType.DATE:
      return <>{values[0] ? t('date', { date: new Date(values[0] as string) }) : EMPTY_VALUE}</>

    case TemplateEntryType.LINK:
      return values[0] ? (
        <a
          href={values[0] as string}
          target='_blank'
          rel='noreferrer'
          className='underline hover:text-gray-600'
        >
          {t('link')}
        </a>
      ) : (
        <>{EMPTY_VALUE}</>
      )

    case TemplateEntryType.TIME:
      return <>{values[0] || EMPTY_VALUE}</>

    case TemplateEntryType.REFERENCE: {
      const reference = values[0] as TemplateEntryReferenceValue

      return <>{reference.name || EMPTY_VALUE}</>
    }

    case TemplateEntryType.DOCUMENT:
      if (!values[0]) return <>{EMPTY_VALUE}</>
      return (
        <>
          {document?.mimeType?.includes('image') ? (
            <div
              className='position-relative mr-3'
              style={{
                height: 250,
                width: 250,
                backgroundImage: `url(${backgroundTransparent})`,
              }}
            >
              <div
                style={{
                  width: '100%',
                  paddingBottom: '100%',
                  backgroundImage: `url(${document?.publicUrl})`,
                  backgroundSize: '100%',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                className='image-none-preview'
              />
            </div>
          ) : (
            EMPTY_VALUE
          )}
          {values[0] && (
            <p className='mt-3'>
              <Button
                color='white'
                size={SIZE.EXTRA_SMALL}
                className='mr-3'
                onClick={() => handleClickDocument(values[0] as string)}
              >
                {t('document')}
              </Button>
            </p>
          )}
        </>
      )

    case TemplateEntryType.FILE:
      return (
        <>
          {mimeType?.includes('image') ? (
            <div
              className='position-relative mr-3'
              style={{
                height: 250,
                width: 250,
                backgroundImage: `url(${backgroundTransparent})`,
              }}
            >
              <div
                style={{
                  width: '100%',
                  paddingBottom: '100%',
                  backgroundImage: `url(${config.BUCKET_TASKS}/${values[0] || ''})`,
                  backgroundSize: '100%',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                className='image-none-preview'
              />
            </div>
          ) : (
            EMPTY_VALUE
          )}
          {values[0] && (
            <p className='mt-3'>
              <Button
                color='white'
                size={SIZE.EXTRA_SMALL}
                className='mr-3'
                onClick={() => openTaskFile(values[0] as string)}
              >
                {t('document')}
              </Button>
            </p>
          )}
        </>
      )

    default:
      return <>{t('na')}</>
  }
}
