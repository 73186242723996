/**
 * Check if the current environment is development
 */
export const isDevelopment = (): boolean => {
  return process.env.NODE_ENV === 'development'
}

/**
 * Check if the current environment is production
 */
export const isProduction = (): boolean => {
  return process.env.NODE_ENV === 'production'
}

/**
 * Check if all features flags are disabled
 */
export const isAllFeatureFlagsDisabled = (): boolean => {
  return process.env.REACT_APP_ALL_FEATURE_FLAGS_DISABLED === 'true'
}
