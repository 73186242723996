import { useCallback, useMemo } from 'react'
import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import {
  create,
  createFromCategory,
  createWithCSV,
  createWithCSVBeta,
  getAll,
  getByIds,
  getByProduct,
  getByStakeHolderProduct,
  getByStakeHolderProductIds,
  getCsvTemplate,
  getCsvTemplateBeta,
  getPaginatedReceptions,
  remove,
  update,
} from './service'
import { Reception, ReceptionCreation, ReceptionFilters, ReceptionUpdate } from './interfaces'
import { AxiosError } from 'axios'
import { Paginated } from '@/hooks/use-pagination'
import { mergeInfiniteQueryOptions, mergeQueryOptions } from '@/utils/merge-query-options'
import * as NotifyService from '@/services/notify.service'
import { customErrorToTrack, useAnalytics } from '@/features/analytics'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { downloader } from '@/helpers/downloader'
import { BulkError, CustomError, ErrorCodes } from '@blockchain-traceability-sl/custom-error-codes'
import { downloadReceptionBulkErrorReport } from './helpers'
import { useFeatureFlag } from '@/features/flags'

export const QUERY_RECEPTIONS_KEY = 'receptions'

export const useReceptionsByStakeHolderProduct = (
  stakeHolderProductId: string,
  options?: Omit<UseQueryOptions<Reception[], AxiosError, Reception[]>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_RECEPTIONS_KEY, { stakeHolderProduct: stakeHolderProductId }],
    () => getByStakeHolderProduct(stakeHolderProductId),
    options
  )
  const receptions = useMemo(() => data || [], [data])

  return { ...rest, receptions }
}

export const useReceptionsByStakeHolderProductIds = (
  stakeHolderProductIds: string[],
  options?: Omit<UseQueryOptions<Reception[], AxiosError, Reception[]>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_RECEPTIONS_KEY, { stakeHolderProductIds }],
    () => getByStakeHolderProductIds(stakeHolderProductIds),
    options
  )

  const receptions = useMemo(() => data || [], [data])

  return { ...rest, receptions }
}

export const useReceptions = (
  options?: Omit<UseQueryOptions<Reception[], AxiosError, Reception[]>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery([QUERY_RECEPTIONS_KEY], () => getAll(), options)
  const receptions = useMemo(() => data || [], [data])

  return { ...rest, receptions }
}

export const useReceptionsByProduct = (
  productId: string,
  options?: Omit<UseQueryOptions<Reception[], AxiosError, Reception[]>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_RECEPTIONS_KEY, { productId }],
    () => getByProduct(productId),
    options
  )
  const receptions = useMemo(() => data || [], [data])

  return { ...rest, receptions }
}

export const useReceptionById = (
  receptionId: string,
  options?: Omit<UseQueryOptions<Reception | void, AxiosError, Reception>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_RECEPTIONS_KEY, receptionId],
    async () => {
      const [reception] = await getByIds([receptionId])
      return reception as Reception | undefined
    },
    options
  )

  return { ...rest, reception: data }
}

export const useReceptionsByIds = (
  ids: string[],
  overrideOptions?: Omit<UseQueryOptions<Reception[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const queryClient = useQueryClient()

  const options = mergeQueryOptions(overrideOptions, {
    // Cache each reception as individual cached item by id
    onSuccess(data) {
      overrideOptions?.onSuccess?.(data)
      data.forEach(item => {
        queryClient.setQueryData([QUERY_RECEPTIONS_KEY, item._id], item)
      })
    },
  })

  const { data, ...rest } = useQuery([QUERY_RECEPTIONS_KEY, { ids }], () => getByIds(ids), options)
  const receptions = useMemo(() => data || [], [data])

  return {
    ...rest,
    receptions,
  }
}

export const usePaginatedReceptions = ({
  filters,
  pageSize = 10,
  ...overrideOptions
}: Omit<UseInfiniteQueryOptions<Paginated<Reception>, AxiosError>, 'queryKey' | 'queryFn'> & {
  filters: ReceptionFilters
  pageSize?: number
}) => {
  const queryClient = useQueryClient()

  const options = mergeInfiniteQueryOptions(overrideOptions, {
    keepPreviousData: true,
    // Cache each product as individual cached item by id
    onSuccess(data) {
      overrideOptions?.onSuccess?.(data)
      data.pages
        .flatMap(({ items }) => items)
        .forEach(item => {
          queryClient.setQueryData([QUERY_RECEPTIONS_KEY, item._id], item)
        })
    },
    getNextPageParam: lastPage => lastPage.pageInfo.nextPage || undefined,
    getPreviousPageParam: lastPage => lastPage.pageInfo.previousPage || undefined,
  })

  const { data, ...rest } = useInfiniteQuery(
    [QUERY_RECEPTIONS_KEY, { filters, pageSize }, { isInfiniteQuery: true }],
    async ({ pageParam }) =>
      getPaginatedReceptions(filters, {
        offset: pageParam?.offset || 0,
        limit: pageParam?.limit || pageSize,
      }),
    options
  )

  const receptions: Reception[] = useMemo(
    () => data?.pages.flatMap(({ items }) => items) || [],
    [data?.pages]
  )

  const totalCount = useMemo(
    () => data?.pages[data?.pages.length - 1]?.totalCount || 0,
    [data?.pages]
  )

  return {
    ...rest,
    data,
    receptions,
    totalCount,
  }
}

export const useFetchReceptions = (filters: ReceptionFilters) => {
  const queryClient = useQueryClient()

  const fetchReceptions = useCallback(
    () => queryClient.fetchQuery([QUERY_RECEPTIONS_KEY, filters], () => getAll(filters)),
    [filters, queryClient]
  )

  return {
    fetchReceptions,
  }
}

export const useReceptionsWithFilters = (
  filters: ReceptionFilters,
  options?:
    | Omit<
        UseQueryOptions<Reception[], unknown, Reception[], (string | ReceptionFilters)[]>,
        'queryKey' | 'queryFn'
      >
    | undefined
) => {
  const { data, ...rest } = useQuery(
    [QUERY_RECEPTIONS_KEY, filters],
    () => getAll(filters),
    options
  )

  const receptions = useMemo(() => data || [], [data])

  return {
    ...rest,
    receptions,
  }
}

export const useFetchReceptionById = () => {
  const queryClient = useQueryClient()

  const fetchReception = useCallback(
    (receptionId: string) =>
      queryClient.fetchQuery(
        [QUERY_RECEPTIONS_KEY, receptionId],
        async (): Promise<Reception | undefined> => {
          const [reception] = await getByIds([receptionId])
          return reception
        }
      ),
    [queryClient]
  )

  return {
    fetchReception,
  }
}

export const useCreateReception = () => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()
  const history = useHistory()
  const { t } = useTranslation('nsNotification')
  const flagProductCategory = false

  const { mutate, mutateAsync, ...rest } = useMutation(
    (reception: ReceptionCreation & { productName: string }) =>
      flagProductCategory ? createFromCategory(reception) : create(reception),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(QUERY_RECEPTIONS_KEY)
        analytics.track('CUSTOMER_CREATE_TRU_RECEPTION', {
          TRU: variables.truReference,
          'TRU reference': variables.truReceptionReference,
          Product: variables.productName,
        })
        NotifyService.success({
          title: t('reception.create.success.title'),
          description: t('reception.create.success.description'),
          actionText: t('reception.create.success.action'),
          onActionClick: () => history.push('/receptions/create'),
        })
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_CREATE_TRU_RECEPTION_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    createReception: mutate,
    createReceptionAsync: mutateAsync,
  }
}

export const useUpdateReception = () => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()

  const { mutate, mutateAsync, ...rest } = useMutation(
    ({
      receptionId,
      reception,
    }: {
      receptionId: string
      reception: ReceptionUpdate
      productName: string
    }) => update(receptionId, reception),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(QUERY_RECEPTIONS_KEY)
        analytics.track('CUSTOMER_EDIT_TRU_RECEPTION', {
          TRU: variables.reception.truReference,
          'TRU reference': variables.reception.truReceptionReference,
          Product: variables.productName,
        })
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_EDIT_TRU_RECEPTION_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    updateReception: mutate,
    updateReceptionAsync: mutateAsync,
  }
}

export const useDeleteReception = () => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')

  const { mutate, mutateAsync, ...rest } = useMutation(
    ({ reception }: { reception: Reception; productName: string }) => remove(reception._id),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(QUERY_RECEPTIONS_KEY)
        analytics.track('CUSTOMER_DELETE_TRU_RECEPTION', {
          TRU: variables.reception.tru.reference,
          'TRU reference': variables.reception.reference,
          Product: variables.productName,
        })
        NotifyService.success({
          title: t('reception.delete.success.title'),
          description: t('reception.delete.success.description'),
        })
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_DELETE_TRU_RECEPTION_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    deleteReception: mutate,
    deleteReceptionAsync: mutateAsync,
  }
}

export const useDownloadTemplateReceptionsCSV = () => {
  // https://trazable.atlassian.net/browse/PRD-2269
  // USE CORRECT ENDPOINT BASED ON THE FLAG STATE
  const { value: flagProductCategory } = useFeatureFlag('product_categories')
  const { data } = useQuery(
    [QUERY_RECEPTIONS_KEY, { template: 'csv' }],
    // TODO: PRD-2269
    flagProductCategory ? getCsvTemplateBeta : getCsvTemplate
  )

  const downloadTemplate = (fileName: string) => {
    if (data) downloader(data, 'text/csv', fileName)
  }

  return { downloadTemplate }
}

export const useCreateReceptionsWithCSV = () => {
  // https://trazable.atlassian.net/browse/PRD-2269
  // USE CORRECT ENDPOINT BASED ON THE FLAG STATE
  const { value: flagProductCategory } = useFeatureFlag('product_categories')
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const history = useHistory()

  /**
   * Set as valid processed if is Bulk error and all errors are already exist
   */
  const isAcceptableError = (error: AxiosError) => {
    return (
      error.response?.data.code === ErrorCodes.BULK_ERROR &&
      (error.response?.data as BulkError).errors.every(
        ({ error: customError }) => customError.code === ErrorCodes.ALREADY_EXISTS
      )
    )
  }
  // TODO: PRD-2269
  const { mutate, ...rest } = useMutation(flagProductCategory ? createWithCSVBeta : createWithCSV, {
    onSuccess(data) {
      analytics.track('CUSTOMER_CREATE_RECEPTIONS_CSV')
      NotifyService.success({
        title: t('reception.createBulk.success.title'),
        description: t('reception.createBulk.success.description', {
          count: data.total,
        }),
        actionText: t('reception.createBulk.success.action'),
        onActionClick: () => history.push('/stakeholder-product/create'),
      })
      queryClient.invalidateQueries(QUERY_RECEPTIONS_KEY)
    },
    onError(error: AxiosError) {
      analytics.track(
        'CUSTOMER_CREATE_RECEPTIONS_CSV_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )

      switch (error.response?.data.code) {
        case ErrorCodes.BULK_ERROR:
          {
            const bulkError: BulkError = error.response?.data

            // Set as valid processed if all errors are already exist
            if (
              bulkError.errors.every(
                ({ error: customError }) => customError.code === ErrorCodes.ALREADY_EXISTS
              )
            ) {
              NotifyService.success({
                title: t('reception.createBulk.success.title'),
                description: t('reception.createBulk.success.description', {
                  count: bulkError.total,
                }),
                actionText: t('reception.createBulk.success.action'),
                onActionClick: () => history.push('/stakeholder-product/create'),
              })
            } else {
              NotifyService.error({
                title: t('reception.createBulk.bulkError.title'),
                description: t('reception.createBulk.bulkError.description', {
                  processed: bulkError.processed,
                  total: bulkError.total,
                }),
                actionText: t('reception.createBulk.bulkError.action'),
                onActionClick: () => downloadReceptionBulkErrorReport(bulkError),
              })
            }
          }
          break

        case 10: // InvalidCSVError
          {
            const invalidCSVError: CustomError = error.response?.data

            if (invalidCSVError.entity !== 'file') {
              NotifyService.error({
                title: t('reception.createBulk.entityError.title'),
                description: t('reception.createBulk.entityError.description', {
                  context: invalidCSVError.entity,
                }),
                actionText: t('reception.createBulk.entityError.action'),
              })
            } else {
              NotifyService.error({
                title: t('reception.createBulk.error.title'),
                description: t('reception.createBulk.error.description'),
                actionText: t('reception.createBulk.error.action'),
              })
            }
          }
          break
        default:
          NotifyService.error({
            title: t('reception.createBulk.error.title'),
            description: t('reception.createBulk.error.description'),
            actionText: t('reception.createBulk.error.action'),
          })
          break
      }
    },
  })

  return { ...rest, createReceptionWithCSV: mutate, isAcceptableError }
}
