import { ReactNode, useContext, useMemo } from 'react'
import { entryFieldComponentContext } from './context'
import { TemplateEntryType } from '@/features/templates'
import { EntryFieldProps } from '../interfaces'

interface EntryFieldComponentProviderProps {
  children: ReactNode
  components?: Partial<Record<TemplateEntryType, React.FC<EntryFieldProps>>>
  wrapper?: (props: {
    componentProps: EntryFieldProps
    TargetComponent: React.FC<EntryFieldProps>
  }) => JSX.Element
}

/**
 * This component allows to override the default components used to render the entry fields.
 * It is not needed to use if you use the default components.
 */
export const EntryFieldExtensionProvider = ({
  children,
  components,
  wrapper,
}: EntryFieldComponentProviderProps) => {
  const initialValue = useContext(entryFieldComponentContext)

  const value = useMemo(
    () => ({
      ...initialValue,
      components: {
        ...initialValue.components,
        ...components,
      },
      wrapper,
    }),
    [components, initialValue, wrapper]
  )

  return (
    <entryFieldComponentContext.Provider value={value}>
      {children}
    </entryFieldComponentContext.Provider>
  )
}
