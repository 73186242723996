import { TemplateEntryReferenceType, TemplateEntryType } from '@/features/templates'
import {
  InputField,
  SelectField,
  SelectorGroup,
} from '@blockchain-traceability-sl/tailwind-components'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { PencilAltIcon, SwitchVerticalIcon, TrashIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export interface SortableEntryProps {
  hasEntriesErrors: boolean
  sortable: boolean

  id: string
  name: string
  mandatory: boolean
  type: TemplateEntryType | TemplateEntryReferenceType

  typeOptions: SelectorGroup[]

  onEntryEdit: (id: string) => void
  onEntryDelete: (id: string) => void

  className?: string
}

export const SortableEntry = memo(
  ({
    hasEntriesErrors,
    sortable = true,
    id,
    name,
    type,
    typeOptions,
    onEntryEdit,
    onEntryDelete,
    className,
  }: SortableEntryProps) => {
    const { t } = useTranslation('nsTemplate')
    const { attributes, listeners, setNodeRef, transform, transition, isDragging, isSorting } =
      useSortable({
        id,
        disabled: !sortable,
      })

    const style = useMemo(
      () => ({
        transform: CSS.Transform.toString(transform),
        transition: !isDragging || !isSorting ? 'none' : transition,
      }),
      [transform, transition, isDragging, isSorting]
    )

    return (
      <div ref={setNodeRef} className={classNames('flex flex-row', className)} style={style}>
        {/* Values disabled */}
        <div className='flex-grow grid grid-cols-1 xl:grid-cols-2 gap-6'>
          <div className='col-span-1'>
            <InputField
              id={`key_${id}_key`}
              name={`key_${id}_key`}
              label={t('entries.name.label')}
              value={name}
              disabled
            />
          </div>

          <div className='col-span-1'>
            <SelectField
              key={`key_${id}_type`}
              id={`key_${id}_type`}
              name={`key.${id}.type`}
              options={typeOptions}
              value={typeOptions
                .flatMap(group => group.options)
                .find(selection => selection.value === type)}
              label={t('entries.type.label')}
              isDisabled
            />
          </div>
        </div>

        {/* Actions */}
        <div className='flex-shrink ml-2 flex flex-col space-y-2 xl:block m-auto xl:mb-1 lg:ml-2 xl:space-x-2'>
          <button
            type='button'
            className={classNames(
              'inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
              {
                'cursor-not-allowed': hasEntriesErrors,
              }
            )}
            onClick={() => onEntryEdit(id)}
          >
            <PencilAltIcon className='h-5 w-5' aria-hidden='true' />
          </button>

          <button
            type='button'
            className='inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
            onClick={() => onEntryDelete(id)}
          >
            <TrashIcon className='h-5 w-5' aria-hidden='true' />
          </button>

          <button
            type='button'
            className={classNames(
              'inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500',
              {
                'cursor-not-allowed': !sortable,
                'cursor-move': isDragging || isSorting,
              }
            )}
            {...attributes}
            {...listeners}
          >
            <SwitchVerticalIcon className='h-5 w-5' aria-hidden='true' />
          </button>
        </div>
      </div>
    )
  }
)
