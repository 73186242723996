import { isObject } from '@/utils/is-object'
import { CustomError } from '@blockchain-traceability-sl/custom-error-codes'

export const customErrorToTrack = (customError?: CustomError, status?: number) => {
  // Is not managed customError
  if (
    !customError ||
    !isObject(customError) ||
    !Object.prototype.hasOwnProperty.call(customError, 'entity')
  ) {
    return {
      'Status code': status,
      'Error message': customError,
    }
  }

  return {
    'Status code': status,
    'Error message': customError.message,
    'Error code': customError.code,
    Entity: customError.entity,
    Context: customError.context,
  }
}
