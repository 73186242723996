import { api } from '@/app/api'
import {
  Issue,
  IssueChange,
  IssueStatus,
  IssueUpdateOrCreate,
  IssuesSummaryReportData,
  IssuesSummaryReportFilters,
} from './interfaces'

const TASKS_URL = `/tasks` as const
const TASKS_RESOURCE_URL = `${TASKS_URL}/tasks` as const
const ISSUES_RESOURCE_URL = `${TASKS_URL}/issues` as const

export const create = async (issueData: IssueUpdateOrCreate): Promise<string> => {
  const { data: issueId } = await api.post<string>(ISSUES_RESOURCE_URL, issueData)

  return issueId
}

export const update = async (id: string, issueData: IssueUpdateOrCreate): Promise<void> => {
  await api.patch<void>(`${ISSUES_RESOURCE_URL}/${id}`, issueData)
}

export const remove = async (id: string): Promise<void> => {
  await api.delete<void>(`${ISSUES_RESOURCE_URL}/${id}`)
}

/**
 * Get all issues by ids
 */
export const getAllByIds = async (issuesIds: string[]): Promise<Issue[]> => {
  if (issuesIds.length === 0) return []
  const { data: issues } = await api.post<Issue[] | void>(`${ISSUES_RESOURCE_URL}/ids`, {
    ids: issuesIds,
  })
  return issues || []
}

/**
 * Get an issue by id
 */
export const getById = async (issueId: string): Promise<Issue> => {
  const { data: issue } = await api.get<Issue>(`${ISSUES_RESOURCE_URL}/${issueId}`)
  return issue
}

/**
 * Get all issues by taskId
 */
export const getByTaskId = async (taskId: string): Promise<Issue[]> => {
  const { data: issues } = await api.get<Issue[] | void>(`${TASKS_RESOURCE_URL}/${taskId}/issues`)
  return issues || []
}

export const getCount = async (
  filter: { status?: IssueStatus[]; responder?: string[]; formId?: string[] } = {}
): Promise<{
  count: number
}> => {
  const { data } = await api.post<{ count: number }>(`${ISSUES_RESOURCE_URL}/count`, filter)
  return data
}

export const applyChanges = async (issueChange: IssueChange): Promise<string | void> => {
  switch (issueChange.operation) {
    case 'create': {
      return create(issueChange.value)
    }

    case 'update': {
      const { id, ...issue } = issueChange.value
      if (id) return update(id, issue)
      break
    }
    case 'delete': {
      await remove(issueChange.value)
      break
    }
  }
}
export const changeStatus = async (status: IssueStatus, issueId: string): Promise<void> => {
  await api.patch<void>(`${ISSUES_RESOURCE_URL}/${issueId}/status`, { status })
}

export const comment = async (issueId: string, comment: string): Promise<void> => {
  await api.post<void>(`${ISSUES_RESOURCE_URL}/${issueId}/comments`, { comment })
}

export const getReportIssues = async ({
  startDate,
  endDate,
}: IssuesSummaryReportFilters): Promise<IssuesSummaryReportData> => {
  const searchParams = new URLSearchParams()

  searchParams.append('startDate', startDate.toString())
  searchParams.append('endDate', endDate.toString())
  const response = await api.get<IssuesSummaryReportData>(`${ISSUES_RESOURCE_URL}/REPORT`, {
    params: searchParams,
  })
  return response.data
}
