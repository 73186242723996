export type ObjectToSearchParams = Record<
  string,
  string | number | boolean | Date | undefined | null | (string | number | boolean | Date)[]
>

const itemToString = (item: string | number | boolean | Date): string => {
  if (item instanceof Date) {
    return item.toISOString()
  }

  return item.toString()
}

export const objectToURLSearchParams = <T extends ObjectToSearchParams = ObjectToSearchParams>(
  object?: T,
  defaultURLSearchParams?: URLSearchParams
): URLSearchParams => {
  const urlSearchParams = new URLSearchParams(defaultURLSearchParams)

  if (object) {
    Object.entries(object).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => urlSearchParams.append(`${key}[]`, itemToString(item)))
      } else if (value !== undefined && value !== null) {
        urlSearchParams.append(key, itemToString(value))
      }
    })
  }

  return urlSearchParams
}
