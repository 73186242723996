import { useMemo, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { ButtonWithIcon, Loader, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import './styles.css'
import { ArrowLeftIcon, ArrowRightIcon, DownloadIcon } from '@heroicons/react/outline'
import { useToggle } from '@/hooks/use-toggle'
import classNames from 'classnames'
import useBreakpoint from '@/hooks/use-breakpoints'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export interface PDFViewerProps {
  source: string
  previousText: string
  nextText: string
  downloadText?: string
  width?: number
  pagination?: boolean
  maxPages?: number
  className?: string
  onLoad?: () => void
  onDownload?: () => void
  /**
   * specifies if the pdf should be rendered in landscape mode
   * prevents the pdf from being scaled down on desktop
   * @default false
   */
  landscape?: boolean

  customActions?: React.ReactNode
}

export const PDFViewer = ({
  source,
  previousText,
  nextText,
  downloadText,
  width,
  pagination = true,
  className,
  maxPages,
  onLoad,
  onDownload,
  landscape,
  customActions,
}: PDFViewerProps) => {
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [numberPages, setNumberPages] = useState<number>(1)

  const breakPoint = useBreakpoint()
  const scale = useMemo(() => {
    if (landscape) return 1

    switch (breakPoint) {
      // Full scale to mobile and tablets
      case SIZE.EXTRA_SMALL:
      case SIZE.SMALL:
      case SIZE.MEDIUM:
      case SIZE.LARGE:
        return 1

      // Desktop
      default:
        return 0.6
    }
  }, [breakPoint, landscape])

  const [isDocumentLoad, toggleIsDocumentLoad] = useToggle(false)

  const previousPage = () => {
    setPageNumber(pageNumber - 1)
  }

  const nextPage = () => {
    setPageNumber(pageNumber + 1)
  }

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumberPages(numPages)
    onLoad && onLoad()
  }

  const createPages = useMemo(
    () => () => {
      const pages = []

      for (let index = 0; index < numberPages; index++) {
        pages.push(
          <Page
            key={index}
            pageIndex={index}
            scale={scale}
            width={width}
            loading={<Loader center='full' />}
            onRenderSuccess={() => toggleIsDocumentLoad(true)}
            renderAnnotationLayer={true}
          />
        )
        if (numberPages > 1 && index < (maxPages ? maxPages - 1 : numberPages)) {
          pages.push(<hr key={`${index}-separator`} />)
        } else {
          break
        }
      }

      return pages
    },
    [maxPages, numberPages, scale, toggleIsDocumentLoad, width]
  )

  return (
    <div className='flex flex-col items-center'>
      <div className='mt-4 flex justify-between mb-2 sm:mt-0 w-full'>
        <div></div>
        <div>
          {pagination && (
            <>
              <ButtonWithIcon
                color='secondary'
                IconComponent={ArrowLeftIcon}
                className='mr-3'
                onClick={previousPage}
                disabled={pageNumber <= 1}
              >
                {previousText}
              </ButtonWithIcon>
              <ButtonWithIcon
                color='secondary'
                IconComponent={ArrowRightIcon}
                onClick={nextPage}
                disabled={pageNumber >= numberPages}
              >
                {nextText}
              </ButtonWithIcon>
            </>
          )}
        </div>
        <div className='flex justify-end'>
          {onDownload && (
            <ButtonWithIcon IconComponent={DownloadIcon} onClick={onDownload}>
              {downloadText}
            </ButtonWithIcon>
          )}
          {customActions}
        </div>
      </div>

      <div style={{ width }} className='PDFViewer'>
        {source ? (
          <div className={classNames(className, { hidden: !isDocumentLoad })}>
            <Document
              file={source}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Loader center='full' />}
              externalLinkTarget='_blank'
            >
              {pagination ? (
                <Page
                  pageNumber={pageNumber}
                  width={width}
                  scale={scale}
                  className='m-auto px-0'
                  loading={<Loader center='full' />}
                  onRenderSuccess={() => toggleIsDocumentLoad(true)}
                  renderAnnotationLayer={true}
                />
              ) : (
                createPages()
              )}
            </Document>
          </div>
        ) : (
          <Loader center='full' />
        )}
      </div>
    </div>
  )
}
