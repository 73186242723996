/**
 * Check if a password has a minimum of security
 *
 * @param password password to check
 * @returns If is a secure password
 */
export const passwordIsSecure = (password: string) => {
  const validation = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/
  return validation.test(password)
}
