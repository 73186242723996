import { NotificationAction } from '../interfaces'
import {
  AnnotationIcon,
  BadgeCheckIcon,
  CheckIcon,
  DownloadIcon,
  MailIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline'
import { FlagIcon, TrashIcon, UserAddIcon } from '@heroicons/react/solid'

export const IconByAction = {
  // TASKS
  [NotificationAction.TASK_ASSIGNED]: UserAddIcon,
  [NotificationAction.TASK_CLOSED]: FlagIcon,
  [NotificationAction.TASK_DELETED]: TrashIcon,
  [NotificationAction.TASK_REPORT_SENT]: MailIcon,

  // ISSUES
  [NotificationAction.ISSUE_RESPONDER_COMMUNICATION]: MailIcon,
  [NotificationAction.ISSUE_COMMUNICATED]: AnnotationIcon,
  [NotificationAction.ISSUE_COMMENTED]: AnnotationIcon,
  [NotificationAction.ISSUE_RESOLVED]: CheckIcon,

  // COMPLIANCES
  [NotificationAction.COMPLIANCE_SUBMITTED]: ShieldCheckIcon,
  [NotificationAction.COMPLIANCE_ALMOST_EXPIRED]: ShieldCheckIcon,
  [NotificationAction.COMPLIANCE_EXPIRED]: ShieldCheckIcon,

  // UPLOAD REQUEST
  [NotificationAction.UPLOAD_REQUEST_CONFIRMED]: DownloadIcon,

  // VALIDATIONS
  [NotificationAction.VALIDATION_CREATED]: BadgeCheckIcon,
}

export const IconColorByAction = {
  // TASKS
  [NotificationAction.TASK_ASSIGNED]: { bg: 'bg-yellow-200', text: 'text-yellow-600' },
  [NotificationAction.TASK_CLOSED]: { bg: 'bg-teal-200', text: 'text-teal-600' },
  [NotificationAction.TASK_DELETED]: { bg: 'bg-red-200', text: 'text-red-600' },
  [NotificationAction.TASK_REPORT_SENT]: { bg: 'bg-blue-200', text: 'text-blue-600' },

  // ISSUES
  [NotificationAction.ISSUE_RESPONDER_COMMUNICATION]: {
    bg: 'bg-blue-200',
    text: 'text-blue-600',
  },
  [NotificationAction.ISSUE_COMMUNICATED]: { bg: 'bg-blue-200', text: 'text-blue-600' },
  [NotificationAction.ISSUE_COMMENTED]: { bg: 'bg-orange-200', text: 'text-orange-600' },
  [NotificationAction.ISSUE_RESOLVED]: { bg: 'bg-green-200', text: 'text-green-600' },

  // COMPLIANCES
  [NotificationAction.COMPLIANCE_SUBMITTED]: { bg: 'bg-blue-200', text: 'text-blue-600' },
  [NotificationAction.COMPLIANCE_ALMOST_EXPIRED]: { bg: 'bg-blue-200', text: 'text-blue-600' },
  [NotificationAction.COMPLIANCE_EXPIRED]: { bg: 'bg-blue-200', text: 'text-blue-600' },

  // UPLOAD REQUEST
  [NotificationAction.UPLOAD_REQUEST_CONFIRMED]: { bg: 'bg-blue-200', text: 'text-blue-700' },

  // VALIDATIONS
  [NotificationAction.VALIDATION_CREATED]: { bg: 'bg-blue-200', text: 'text-blue-600' },
}
