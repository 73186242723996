import { useTranslation } from 'react-i18next'
import { SortableContainer } from '@/components/SortableContainer'
import {
  TemplateEntryReferenceType,
  TemplateEntryType,
  TemplateTableEntryColumn,
  useTemplateEntriesSelectorOptions,
} from '@/features/templates'
import { SortableEntry } from './SortableEntry'
import { SortableEntryEditable } from './SortableEntryEditable'
import { memo, useCallback, useMemo } from 'react'
import { nanoid } from 'nanoid'
import {
  ButtonWithIcon,
  CheckboxField,
  CreatableMultiField,
  InputField,
  SIZE,
  SelectField,
  SelectorOption,
  TextAreaField,
} from '@blockchain-traceability-sl/tailwind-components'
import classNames from 'classnames'
import { TableEntryField } from './TableEntryField'
import { PlusIcon } from '@heroicons/react/solid'
import { ConditionalEntryField } from './ConditionalEntryField'
import { SectionEntryField } from './SectionEntryField'
import { EditableEntry } from '../interfaces'
import { validateTemplateEntry } from '../utils'

export type TemplateEntryError = {
  type?: string | string[]
  name?: string | string[]
  values?: string | string[]
  mandatory?: string | string[]
}

export interface TemplateEntriesProps {
  // Values
  entries: EditableEntry[]
  newEntry: Omit<EditableEntry, 'editable'>
  // Errors
  entriesErrors?: TemplateEntryError[]
  newEntryErrors?: TemplateEntryError
  // Modifiers
  setEntriesValue: (
    entries: EditableEntry[] | ((previous: EditableEntry[]) => EditableEntry[])
  ) => void
  setNewEntryValue: (
    entry: Omit<EditableEntry, 'editable'> | ((previous: EditableEntry) => EditableEntry)
  ) => void
  // Flags
  tableEnabled?: boolean
  statementEnabled?: boolean
  referenceEnabled?: boolean
  documentEnabled?: boolean
  fileEnabled?: boolean
  conditionalEnabled?: boolean
  sectionEnabled?: boolean
  mandatoryEnabled?: boolean
}

export const TemplateEntries = memo(
  ({
    entries,
    newEntry,
    entriesErrors,
    newEntryErrors,
    setEntriesValue,
    setNewEntryValue,
    tableEnabled,
    statementEnabled,
    referenceEnabled,
    documentEnabled,
    fileEnabled,
    conditionalEnabled,
    sectionEnabled,
    mandatoryEnabled = true,
  }: TemplateEntriesProps) => {
    const { t } = useTranslation('nsTemplate')

    const entryTypeOptions = useTemplateEntriesSelectorOptions({
      referenceEnabled,
      statementEnabled,
      tableEnabled,
      documentEnabled,
      fileEnabled,
      conditionalEnabled,
      sectionEnabled,
    })

    const hasEntriesErrors = useMemo(
      () => !!entriesErrors && Object.keys(entriesErrors).length !== 0,
      [entriesErrors]
    )

    const isEditionEnabled = useMemo(() => entries.some(({ editable }) => editable), [entries])

    const handleEntryAdd = useCallback(() => {
      setEntriesValue([
        ...entries.map(entry => ({ ...entry, editable: false })),
        { ...newEntry, editable: false },
      ])
      setNewEntryValue({
        id: nanoid(),
        name: '',
        type: TemplateEntryType.SHORT_TEXT,
        mandatory: false,
        values: [],
      })
    }, [entries, newEntry, setEntriesValue, setNewEntryValue])

    const handleEntryDelete = useCallback(
      (filterId: string) => {
        const entryToDelete = entries.find(({ id }) => id === filterId)
        if (!entryToDelete) return
        setEntriesValue(
          entries.filter(entry =>
            entryToDelete.type === TemplateEntryType.CONDITIONAL
              ? entry.blockId !== entryToDelete.id && entry.id !== filterId
              : entry.id !== filterId
          )
        )
      },
      [entries, setEntriesValue]
    )

    const handleEntryEdit = useCallback(
      (id: string) => {
        if (!hasEntriesErrors) {
          setEntriesValue(
            entries.map(entry =>
              entry.id === id ? { ...entry, editable: true } : { ...entry, editable: false }
            )
          )
        }
      },
      [entries, hasEntriesErrors, setEntriesValue]
    )

    const handleEntryEditEnd = useCallback(() => {
      // If no errors
      if (!hasEntriesErrors) {
        setEntriesValue(entries.map(entry => ({ ...entry, editable: false })))
      }
    }, [entries, hasEntriesErrors, setEntriesValue])

    const entriesInFrontline = useMemo(() => entries.filter(({ blockId }) => !blockId), [entries])

    const entriesErrorsByIds = useMemo(() => {
      if (!entriesErrors) return {}
      return entries.reduce<{ [id: string]: TemplateEntryError | undefined }>(
        (accumulator, { id }, index) => {
          accumulator[id] = entriesErrors[index]
          return accumulator
        },
        {}
      )
    }, [entriesErrors, entries])

    return (
      <>
        <SortableContainer
          items={entries}
          onSort={items => {
            setEntriesValue(items as EditableEntry[])
          }}
        >
          {entriesInFrontline.map(({ editable, ...entry }) =>
            !editable ? (
              <SortableEntry
                key={entry.id}
                {...entry}
                typeOptions={entryTypeOptions}
                hasEntriesErrors={hasEntriesErrors}
                sortable={!isEditionEnabled}
                onEntryEdit={handleEntryEdit}
                onEntryDelete={handleEntryDelete}
              />
            ) : (
              <SortableEntryEditable
                key={entry.id}
                {...entry}
                typeOptions={entryTypeOptions}
                hasKeysErrors={hasEntriesErrors}
                hasNameError={!!entriesErrorsByIds[entry.id]?.name}
                hasTypeError={!!entriesErrorsByIds[entry.id]?.type}
                hasSelectorError={!!entriesErrorsByIds[entry.id]?.values}
                hasContentError={!!entriesErrorsByIds[entry.id]?.values}
                onKeyEditEnd={handleEntryEditEnd}
                onKeyDelete={handleEntryDelete}
                onNameChange={value => {
                  setEntriesValue(
                    entries.map(targetEntry =>
                      entry.id === targetEntry.id ? { ...targetEntry, name: value } : targetEntry
                    )
                  )
                }}
                onTypeChange={value => {
                  setEntriesValue(
                    entries.map(targetEntry =>
                      targetEntry.id === entry.id
                        ? {
                            ...targetEntry,
                            type: value,
                            values: [],
                          }
                        : targetEntry
                    )
                  )
                }}
                onSelectorChange={value => {
                  setEntriesValue(
                    entries.map(targetEntry =>
                      entry.id === targetEntry.id ? { ...targetEntry, values: value } : targetEntry
                    )
                  )
                }}
                onContentChange={value => {
                  setEntriesValue(
                    entries.map(targetEntry =>
                      entry.id === targetEntry.id
                        ? { ...targetEntry, values: [value] }
                        : targetEntry
                    )
                  )
                }}
                onContentTableChange={value => {
                  setEntriesValue(
                    entries.map(targetEntry =>
                      entry.id === targetEntry.id ? { ...targetEntry, values: value } : targetEntry
                    )
                  )
                }}
                onMandatoryChange={event => {
                  setEntriesValue(
                    entries.map(targetEntry =>
                      entry.id === targetEntry.id
                        ? { ...targetEntry, mandatory: event }
                        : targetEntry
                    )
                  )
                }}
                conditionalOptions={{
                  entries,
                  entriesErrors,
                  onAddEntryInBlock: (blockEntry, entryToAdd) => {
                    setEntriesValue([
                      ...entries.map(targetEntry =>
                        targetEntry.id === blockEntry.id
                          ? { ...targetEntry, ...blockEntry }
                          : targetEntry
                      ),
                      entryToAdd,
                    ])
                  },
                  onRemoveEntryInBlock: (blockEntry, entryToRemove) => {
                    setEntriesValue(
                      entries
                        .filter(targetEntry => targetEntry.id !== entryToRemove.id)
                        .map(targetEntry =>
                          targetEntry.id === blockEntry.id
                            ? { ...targetEntry, ...blockEntry }
                            : targetEntry
                        )
                    )
                  },
                  onChangeEntryInBlock: entryToChange => {
                    setEntriesValue(
                      entries.map(targetEntry =>
                        targetEntry.id === entryToChange.id
                          ? { ...targetEntry, ...entryToChange }
                          : targetEntry
                      )
                    )
                  },
                  onChange: entryToChange => {
                    setEntriesValue(
                      entries.map(targetEntry =>
                        targetEntry.id === entryToChange.id
                          ? { ...targetEntry, ...entryToChange }
                          : targetEntry
                      )
                    )
                  },
                }}
                sectionOptions={{
                  entries,
                  entriesErrors,
                  onAddEntryInBlock: (entryToAdd, entriesInNestedBlock) => {
                    const newEntries = [...entries, entryToAdd]
                    if (entriesInNestedBlock) {
                      newEntries.push(...entriesInNestedBlock)
                    }
                    setEntriesValue(newEntries)
                  },
                  onRemoveEntryInBlock: entryToRemove => {
                    setEntriesValue(
                      entries.filter(targetEntry => targetEntry.id !== entryToRemove.id)
                    )
                  },
                  onChangeEntryInBlock: (entryToChange, nestedBlockOperation) => {
                    const newEntries = entries.map(targetEntry =>
                      targetEntry.id === entryToChange.id
                        ? { ...targetEntry, ...entryToChange }
                        : targetEntry
                    )
                    if (nestedBlockOperation) {
                      const { entry, operation } = nestedBlockOperation
                      if (operation === 'add') {
                        newEntries.push(entry)
                      } else {
                        newEntries.splice(
                          newEntries.findIndex(innerEntry => innerEntry.id === entry.id),
                          1
                        )
                      }
                    }
                    setEntriesValue(newEntries)
                  },
                  onSort: blockEntries => {
                    const blockEntryIds = blockEntries.map(entry => entry.id)

                    const filteredEntries = entries.filter(
                      entry => !blockEntryIds.includes(entry.id)
                    )

                    setEntriesValue([...filteredEntries, ...blockEntries])
                  },
                }}
                referenceEnabled={referenceEnabled}
                documentEnabled={documentEnabled}
                mandatoryEnabled={mandatoryEnabled}
              />
            )
          )}
        </SortableContainer>

        <div className='grid gap-6'>
          <InputField
            key={`entry.name.${newEntry.id}`}
            id={`entry_name_${newEntry.id}`}
            name={`entry_name_${newEntry.id}`}
            onChange={event => setNewEntryValue({ ...newEntry, name: event.currentTarget.value })}
            autoFocus
            value={newEntry.name}
            label={t('entries.name.label')}
            description={t('entries.name.description')}
            error={!!newEntryErrors?.name}
          />

          <div>
            <div className='grid grid-cols-3 gap-6'>
              <div
                className={classNames({
                  'col-span-1':
                    newEntry.type === TemplateEntryType.MULTIPLE_CHOICE ||
                    newEntry.type === TemplateEntryType.SINGLE_CHOICE,
                  'col-span-3':
                    newEntry.type !== TemplateEntryType.MULTIPLE_CHOICE &&
                    newEntry.type !== TemplateEntryType.SINGLE_CHOICE,
                })}
              >
                <SelectField
                  key={`entry_type_${newEntry.id}`}
                  id={`entry_type_${newEntry.id}`}
                  name={`entry.type.${newEntry.id}`}
                  options={entryTypeOptions}
                  value={entryTypeOptions
                    .flatMap(group => group.options)
                    .find(selection => selection.value === newEntry.type)}
                  onChange={option =>
                    setNewEntryValue({
                      ...newEntry,
                      type: option
                        ? (option.value as TemplateEntryType.SHORT_TEXT)
                        : TemplateEntryType.SHORT_TEXT,
                      values:
                        option &&
                        (option.value === TemplateEntryReferenceType.STAKEHOLDER ||
                          option.value === TemplateEntryReferenceType.PRODUCT ||
                          option.value === TemplateEntryReferenceType.TRU)
                          ? [{ type: option.value }]
                          : [],
                    })
                  }
                  label={t('entries.type.label')}
                />
              </div>

              {(newEntry.type === TemplateEntryType.SINGLE_CHOICE ||
                newEntry.type === TemplateEntryType.MULTIPLE_CHOICE) && (
                <div className='col-span-2'>
                  <CreatableMultiField
                    key={`entry.values.${newEntry.id}`}
                    id={`entry_values_${newEntry.id}`}
                    name={`entry.values.${newEntry.id}`}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    value={((newEntry as { values?: string[] }).values || []).map(
                      (value): SelectorOption => ({ label: value, value })
                    )}
                    onChange={options =>
                      setNewEntryValue({
                        ...newEntry,
                        values: options.map(({ value }) => value),
                      })
                    }
                    label={t('entries.selector.label')}
                    error={!!newEntryErrors?.values}
                  />
                </div>
              )}
            </div>

            <p className='mt-1 text-sm text-gray-500'>{t('entries.type.description')}</p>

            {newEntry.type === TemplateEntryType.STATEMENT && (
              <div className='mt-4'>
                <TextAreaField
                  key={`entry.values.${newEntry.id}`}
                  id={`entry_values_${newEntry.id}`}
                  name={`entry.values.${newEntry.id}`}
                  value={(newEntry.values[0] as string) || ''}
                  onChange={event =>
                    setNewEntryValue({
                      ...newEntry,
                      values: [event.currentTarget.value],
                    })
                  }
                  label={t('entries.content.label')}
                  description={t('entries.content.description')}
                  error={!!newEntryErrors?.values}
                />
              </div>
            )}
          </div>
        </div>

        <div>
          {mandatoryEnabled &&
            ![
              TemplateEntryType.STATEMENT,
              TemplateEntryType.TABLE,
              TemplateEntryType.CONDITIONAL,
              TemplateEntryType.SECTION,
            ].includes(newEntry.type as TemplateEntryType) && (
              <CheckboxField
                key={`entry.mandatory.${newEntry.id}`}
                id={`entry_mandatory_${newEntry.id}`}
                name={`entry.mandatory.${newEntry.id}`}
                label={t('entries.checkbox.mandatory.label')}
                checked={newEntry.mandatory}
                onChange={event =>
                  setNewEntryValue({
                    ...newEntry,
                    mandatory: event.currentTarget.checked,
                  })
                }
              />
            )}
        </div>

        {newEntry.type === TemplateEntryType.CONDITIONAL && (
          <div className='mt-4'>
            <ConditionalEntryField
              targetEntry={newEntry}
              entries={entries}
              entriesErrors={entriesErrors}
              onAddEntryInBlock={(blockEntry, entryToAdd) => {
                setEntriesValue([...entries, entryToAdd])
                setNewEntryValue({
                  ...newEntry,
                  ...blockEntry,
                })
              }}
              onRemoveEntryInBlock={(blockEntry, entryToRemove) => {
                setEntriesValue(entries.filter(entry => entry.id !== entryToRemove.id))
                setNewEntryValue({
                  ...newEntry,
                  ...blockEntry,
                })
              }}
              onChangeEntryInBlock={entryToChange => {
                setEntriesValue(
                  entries.map(entry =>
                    entry.id === entryToChange.id ? { ...entry, ...entryToChange } : entry
                  )
                )
              }}
              onChange={entry => {
                setNewEntryValue({
                  ...newEntry,
                  ...entry,
                })
              }}
            />
          </div>
        )}

        {newEntry.type === TemplateEntryType.SECTION && (
          <div className='mt-4'>
            <SectionEntryField
              targetEntry={newEntry}
              entries={entries}
              entriesErrors={entriesErrors}
              onAddEntryInBlock={(entryToAdd, entriesInNestedBlock) => {
                const newEntries = [...entries, entryToAdd]
                if (entriesInNestedBlock) {
                  newEntries.push(...entriesInNestedBlock)
                }
                setEntriesValue(newEntries)
              }}
              onRemoveEntryInBlock={entryToRemove => {
                setEntriesValue(entries.filter(entry => entry.id !== entryToRemove.id))
              }}
              onChangeEntryInBlock={(entryToChange, nestedBlockOperation) => {
                const newEntries = entries.map(targetEntry =>
                  targetEntry.id === entryToChange.id
                    ? { ...targetEntry, ...entryToChange }
                    : targetEntry
                )
                if (nestedBlockOperation) {
                  const { entry, operation } = nestedBlockOperation
                  if (operation === 'add') {
                    newEntries.push(entry)
                  } else {
                    newEntries.splice(
                      newEntries.findIndex(innerEntry => innerEntry.id === entry.id),
                      1
                    )
                  }
                }
                setEntriesValue(newEntries)
              }}
              onSort={blockEntries => {
                const blockEntryIds = blockEntries.map(entry => entry.id)

                const filteredEntries = entries.filter(entry => !blockEntryIds.includes(entry.id))

                setEntriesValue([...filteredEntries, ...blockEntries])
              }}
            />
          </div>
        )}

        <div className='flex justify-end'>
          <ButtonWithIcon
            color='secondary'
            size={SIZE.EXTRA_SMALL}
            IconComponent={PlusIcon}
            onClick={handleEntryAdd}
            disabled={!validateTemplateEntry(newEntry)}
          >
            {t('entries.addMore')}
          </ButtonWithIcon>
        </div>

        {newEntry.type === TemplateEntryType.TABLE && (
          <div className='mt-4'>
            <TableEntryField
              key={newEntry.id}
              onChange={(columns: TemplateTableEntryColumn[]): void => {
                setNewEntryValue({
                  ...newEntry,
                  values: columns,
                })
              }}
              referenceEnabled={referenceEnabled}
              documentEnabled={documentEnabled}
            />
          </div>
        )}
      </>
    )
  }
)
