import { Badge, COLOR } from '@blockchain-traceability-sl/tailwind-components'
import { TFunction } from 'i18next'
import { ComplianceStatus, ComplianceValidation, ComplianceValidationStatus } from '../interfaces'
import { getLastComplianceValidation } from '../utils'

export const ComplianceStatusBadge = ({
  status,
  validationState = [],
  t,
}: {
  status: ComplianceStatus
  validationState: ComplianceValidation[]
  t: TFunction
}) => {
  const lastValidation = getLastComplianceValidation(validationState)

  switch (status) {
    case ComplianceStatus.ACCEPTED:
      if (
        lastValidation &&
        lastValidation.status === ComplianceValidationStatus.PARTIALLY_APPROVED
      ) {
        return <Badge color={COLOR.YELLOW}>{t('table.statusTypes.partially_approved')}</Badge>
      } else {
        return <Badge color={COLOR.GREEN}>{t('table.statusTypes.approved')}</Badge>
      }
    case ComplianceStatus.SUBMITTED:
      return <Badge color={COLOR.BLUE}>{t('table.statusTypes.pendingToValidate')}</Badge>
    case ComplianceStatus.PENDING: {
      const [lastValidation] = validationState.sort(
        (validationA, validationB) =>
          new Date(validationB.validatedAt).getTime() - new Date(validationA.validatedAt).getTime()
      )
      if (!lastValidation) return <Badge color={COLOR.GRAY}>{t('table.statusTypes.sent')}</Badge>
      return lastValidation.status === ComplianceValidationStatus.REJECTED ? (
        <Badge color={COLOR.RED}>{t('table.statusTypes.rejected')}</Badge>
      ) : (
        <Badge color={COLOR.GREEN}>{t('table.statusTypes.approved')}</Badge>
      )
    }
    default:
      return <>{t('table.statusTypes.unknown')}</>
  }
}
