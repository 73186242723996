import {
  TemplateEntryType,
  TemplateTableEntryColumn,
  TemplateTableEntryRow,
} from '@/features/templates'
import { nanoid } from 'nanoid'
import { useMemo, useReducer } from 'react'
import { TemplateTableEntryRowValues } from '../../interfaces'

export enum TableEntryFieldActionType {
  ADD_COLUMN,
  FIX_COLUMN,
  REMOVE_COLUMN,
  UPDATE_COLUMN,
  ADD_ROW,
  REMOVE_ROW,
  UPDATE_ROW,
}
export type DirtyTemplateTableEntryRow = TemplateTableEntryRow & { id: string }

export interface DirtyTemplateTableEntryColumn extends Partial<TemplateTableEntryColumn> {
  id: string
  rows: DirtyTemplateTableEntryRow[]
}

interface TableEntryFieldAction {
  actionType: TableEntryFieldActionType
  index?: number
  id?: string
  rowId?: string
  column?: {
    title: string
  }
  row?: {
    type: Exclude<TemplateEntryType, TemplateEntryType.TABLE | TemplateEntryType.STATEMENT>
    values: TemplateTableEntryRowValues
  }
  initialValue?: TemplateTableEntryColumn[]
}

function createNewEmptyRow(): DirtyTemplateTableEntryRow {
  return {
    id: nanoid(),
    type: TemplateEntryType.SHORT_TEXT,
    values: [],
  }
}

function createNewEmptyColumn(rowsNumber: number = 1): DirtyTemplateTableEntryColumn {
  return {
    id: nanoid(),
    title: '',
    lock: false,
    rows: Array.from({ length: rowsNumber }, () => createNewEmptyRow()),
  }
}
const initState = (state?: TemplateTableEntryColumn[]) =>
  state && state.length > 0
    ? state.map(column => ({
        ...column,
        id: nanoid(),
        rows: column.rows.map(row => ({
          ...row,
          id: nanoid(),
        })),
      }))
    : [createNewEmptyColumn()]

const addColumn = (state: DirtyTemplateTableEntryColumn[], action: TableEntryFieldAction) => {
  const currentColumn = state[action.index || 0]
  const newColumn = createNewEmptyColumn(currentColumn.rows.length)
  newColumn.rows = newColumn.rows.map((row, index) => ({
    ...row,
    type: currentColumn.rows[index].type,
    values: currentColumn.rows[index].values,
  }))

  state.splice((action.index || 0) + 1, 0, newColumn)
  return [...state]
}

const removeColumn = (state: DirtyTemplateTableEntryColumn[], action: TableEntryFieldAction) => {
  return state.filter(column => column.id !== action.id)
}

const fixColumn = (state: DirtyTemplateTableEntryColumn[], action: TableEntryFieldAction) => {
  const columnToFix = state.find(item => item.id === action.id)
  if (columnToFix) {
    columnToFix.lock = !columnToFix.lock
  }
  return [...state]
}

const updateColumn = (state: DirtyTemplateTableEntryColumn[], action: TableEntryFieldAction) => {
  const columnToUpdate = state.find(item => item.id === action.id)
  if (columnToUpdate) {
    columnToUpdate.title = action.column?.title
  }
  return [...state]
}

const addRow = (state: DirtyTemplateTableEntryColumn[], action: TableEntryFieldAction) => {
  const column = state.find(item => item.id === action.id)
  const rowIndex = column?.rows.findIndex(item => item.id === action.rowId)
  if (rowIndex !== undefined && rowIndex !== -1) {
    state.forEach(column => {
      const row = column.rows[rowIndex]
      column.rows.splice(rowIndex, 0, {
        ...createNewEmptyRow(),
        type: row.type,
        values: row.values,
      })
    })
  }
  return [...state]
}

const removeRow = (state: DirtyTemplateTableEntryColumn[], action: TableEntryFieldAction) => {
  const rowColumn = state.find(item => item.id === action.id)
  const rowIndex = rowColumn?.rows.findIndex(item => item.id === action.rowId)
  if (rowIndex !== undefined && rowIndex !== -1) {
    state.forEach(column => {
      column.rows.splice(rowIndex, 1)
    })
  }
  return [...state]
}

const updateRow = (state: DirtyTemplateTableEntryColumn[], action: TableEntryFieldAction) => {
  const column = state.find(item => item.id === action.id)
  const rowIndex = column?.rows.findIndex(item => item.id === action.rowId)
  if (!column || rowIndex === undefined || rowIndex === -1 || !action.row) return state

  const oldRow = column.rows[rowIndex]

  let newValues = action.row.values || oldRow.values
  const newType = action.row.type || oldRow.type

  if (oldRow.type !== action.row.type) {
    if (
      ![
        TemplateEntryType.MULTIPLE_CHOICE,
        TemplateEntryType.SINGLE_CHOICE,
        TemplateEntryType.REFERENCE,
      ].includes(action.row.type) ||
      ([TemplateEntryType.MULTIPLE_CHOICE, TemplateEntryType.SINGLE_CHOICE].includes(
        action.row.type
      ) &&
        oldRow.type === TemplateEntryType.REFERENCE)
    ) {
      newValues = []
    }
  }

  column.rows[rowIndex] = {
    ...column.rows[rowIndex],
    type: newType,
    values: newValues,
  }

  return [...state]
}

function reducer(state: DirtyTemplateTableEntryColumn[], action: TableEntryFieldAction) {
  switch (action.actionType) {
    case TableEntryFieldActionType.ADD_COLUMN:
      return addColumn(state, action)
    case TableEntryFieldActionType.REMOVE_COLUMN:
      return removeColumn(state, action)
    case TableEntryFieldActionType.FIX_COLUMN:
      return fixColumn(state, action)
    case TableEntryFieldActionType.UPDATE_COLUMN:
      return updateColumn(state, action)
    case TableEntryFieldActionType.ADD_ROW:
      return addRow(state, action)
    case TableEntryFieldActionType.REMOVE_ROW:
      return removeRow(state, action)
    case TableEntryFieldActionType.UPDATE_ROW:
      return updateRow(state, action)
    default:
      return state
  }
}

export interface UseTableEntryFieldReturnValue {
  columns: DirtyTemplateTableEntryColumn[]
  rowsPerColumn: number
  rowsWithOptions: number[]
  addColumn: (index: number) => void
  removeColumn: (id: string) => void
  fixColumn: (id: string) => void
  addRow: (id: string, rowId: string) => void
  removeRow: (id: string, rowId: string) => void
  updateRow: (id: string, rowId: string, row: TableEntryFieldAction['row']) => void
  updateColumn: (id: string, column: TableEntryFieldAction['column']) => void
}
export function useTableEntryField({
  initialValue,
}: {
  initialValue?: TemplateTableEntryColumn[]
} = {}): UseTableEntryFieldReturnValue {
  const [columns, dispatch] = useReducer(reducer, initialValue, initState)
  const rowsPerColumn = useMemo(
    () => Math.max(...columns.map(({ rows }) => rows.length)),
    [columns]
  )

  const rowsWithOptions = useMemo(
    () =>
      Array.from(
        new Set(
          columns
            .map(({ rows }) => rows.map((row, index) => [row.type, index]))
            .flat()
            .filter(
              ([type]) =>
                type === TemplateEntryType.SINGLE_CHOICE ||
                type === TemplateEntryType.MULTIPLE_CHOICE
            )
            .map(([, index]) => index) as number[]
        )
      ),
    [columns]
  )

  return {
    columns,
    rowsPerColumn,
    rowsWithOptions,
    addColumn: (index: number) => {
      dispatch({ actionType: TableEntryFieldActionType.ADD_COLUMN, index })
    },
    removeColumn: (id: string) => {
      dispatch({ actionType: TableEntryFieldActionType.REMOVE_COLUMN, id })
    },
    fixColumn: (id: string) => {
      dispatch({ actionType: TableEntryFieldActionType.FIX_COLUMN, id })
    },
    addRow: (id: string, rowId: string) => {
      dispatch({ actionType: TableEntryFieldActionType.ADD_ROW, id, rowId })
    },
    removeRow: (id: string, rowId: string) => {
      dispatch({ actionType: TableEntryFieldActionType.REMOVE_ROW, id, rowId })
    },

    updateRow: (id: string, rowId: string, row: TableEntryFieldAction['row']) => {
      dispatch({ actionType: TableEntryFieldActionType.UPDATE_ROW, id, rowId, row })
    },
    updateColumn: (id: string, column: TableEntryFieldAction['column']) => {
      dispatch({ actionType: TableEntryFieldActionType.UPDATE_COLUMN, id, column })
    },
  }
}
