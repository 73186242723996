import { Entry } from '@/features/entries'
import { useTaskById } from '@/features/tasks'
import { Timer, getSecondsFromDate, getTimeFromSeconds } from '@/hooks/use-timer'
import { useIssueStatusGroups } from '@/pages/tasks/TasksList/hooks'
import {
  Button,
  Dropdown,
  Loader,
  Modal,
  SIZE,
  Section,
  TextAreaField,
} from '@blockchain-traceability-sl/tailwind-components'
import { AnnotationIcon, ClockIcon, MailIcon } from '@heroicons/react/outline'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCommentIssue, useIssuesByTaskId } from '../hooks'
import { IssueComments } from './IssueComments'
import { IssueEntry } from './IssueEntry'
import { IssueHistory } from './IssueHistory'
import { ManageIssueStatus } from './ManageIssueStatus'

export interface ModalTaskIssuesProps {
  show: boolean
  taskId: string | null
  toggle: () => void
  /**
   * @description This prop is used to know if we are showing an open issue or a closed issue
   */
  issueModalMode?: IssueModalMode
}
export enum IssueModalShowMode {
  COMMENTS = 'COMMENTS',
  ACTIVITY = 'ACTIVITY',
}

export enum IssueModalMode {
  ACTIVE,
  RESOLVED,
}

export const ModalTaskIssues = ({ show, taskId, toggle, issueModalMode }: ModalTaskIssuesProps) => {
  const { t } = useTranslation('nsModalTaskIssues')
  const { task } = useTaskById(taskId || '', { enabled: !!taskId })
  const { issueStatusGroups } = useIssueStatusGroups()
  const { issues, isLoading } = useIssuesByTaskId(taskId || '', {
    enabled: !!taskId,
  })

  const handleDownloadFile = useCallback((url: string, index: number) => {
    if (url) {
      const link = document.createElement('a')
      document.body.appendChild(link)
      link.className = 'd-none'
      link.href = url
      link.download = `issue-media-${index}`
      link.target = '_blank'
      link.click()
      setTimeout(() => document.body.removeChild(link), 200)
    }
  }, [])

  const [issueModalShowOption, setIssueModalShowOption] = useState<IssueModalShowMode>(
    IssueModalShowMode.ACTIVITY
  )

  const [commentValue, setCommentValue] = useState<Record<string, string>>({})

  const { commentIssue } = useCommentIssue()

  const getIssueName = (issueId: string, entries: Entry[]) => {
    const issueFieldEntry = entries.find(entry => entry.values[0] === issueId)
    const issueSection = issueFieldEntry?.blockId
      ? entries.find(entry => entry.id === issueFieldEntry?.blockId)
      : null

    const name = issueSection
      ? `${issueSection.name} - ${issueFieldEntry?.name}`
      : issueFieldEntry?.name

    return name || ''
  }

  const resolvedIssueTimer = (createdAt: Date, resolvedAt: Date) => {
    const { hours, minutes, seconds } = getTimeFromSeconds(
      getSecondsFromDate(createdAt, resolvedAt)
    )
    return (
      <span>
        {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:
        {String(seconds).padStart(2, '0')}
      </span>
    )
  }

  useEffect(() => {
    if (issueModalMode === IssueModalMode.ACTIVE) {
      if (issues.every(issue => issueStatusGroups.CLOSED.includes(issue.status))) {
        toggle()
      }
    }

    if (issueModalMode === IssueModalMode.RESOLVED) {
      if (issues.every(issue => issueStatusGroups.OPEN.includes(issue.status))) {
        toggle()
      }
    }
  }, [issueStatusGroups.CLOSED, issueStatusGroups.OPEN, issues, issueModalMode, toggle])

  const handleCommentChange = (issueId: string, value: string) => {
    setCommentValue(previous => ({ ...previous, [issueId]: value }))
  }

  const handleCommentSubmit = (issueId: string) => {
    if (task && commentValue) {
      commentIssue({
        issueId,
        message: commentValue[issueId],
        taskName: task.formName,
      })
      setCommentValue(previous => ({ ...previous, [issueId]: '' }))
    }
  }

  const handleSectionToggle = () => {
    setIssueModalShowOption(IssueModalShowMode.ACTIVITY)
  }

  // Reset comment value on close the modal
  useEffect(() => {
    if (!show) {
      setCommentValue({})
      setIssueModalShowOption(IssueModalShowMode.ACTIVITY)
    }
  }, [show])

  return (
    <Modal show={show} size={SIZE.LARGE} toggle={toggle} dismissButton>
      <Modal.Title>{t('header')}</Modal.Title>
      <Modal.Body>
        {isLoading ? (
          <Loader center='full' />
        ) : (
          <ul className='py-1 space-y-8'>
            {issues.map(issue => (
              <li key={issue._id}>
                <Section
                  key={issue._id}
                  onToggle={handleSectionToggle}
                  defaultOpen={issues.length === 1}
                  title={
                    <span className='text-lg leading-6 font-medium'>
                      {getIssueName(issue._id, task?.entries || [])}
                    </span>
                  }
                  titleLeading={<ManageIssueStatus issue={issue} task={task} key={issue._id} />}
                >
                  <div className='space-y-3 text-left'>
                    {/* Overview */}
                    <div className='grid lg:grid-cols-3 gap-5'>
                      {/* Total notification attempts */}
                      <div className='relative bg-white px-2 pt-3 pb-2 shadow rounded-lg overflow-hidden text-left'>
                        <dt>
                          <div className='absolute bg-blue-200 rounded-md p-3'>
                            <MailIcon className='h-6 w-6 text-blue-600' aria-hidden='true' />
                          </div>
                          <p className='ml-16 text-sm font-medium text-gray-500 truncate'>
                            {t('overview.notificationAttempts')}
                          </p>
                        </dt>
                        <dd className='ml-16 flex items-baseline'>
                          <p className='text-2xl font-semibold text-gray-900'>
                            {issue.notificationAttempts}
                          </p>
                        </dd>
                      </div>
                      {/* Total Comments */}
                      <div className='relative bg-white px-2 pt-3 pb-2 shadow rounded-lg overflow-hidden text-left'>
                        <dt>
                          <div className='absolute bg-orange-200 rounded-md p-3'>
                            <AnnotationIcon
                              className='h-6 w-6 text-orange-600'
                              aria-hidden='true'
                            />
                          </div>
                          <p className='ml-16 text-sm font-medium text-gray-500 truncate'>
                            {t('overview.comments')}
                          </p>
                        </dt>
                        <dd className='ml-16 flex items-baseline'>
                          <p className='text-2xl font-semibold text-gray-900'>
                            {issue.comments.length}
                          </p>
                        </dd>
                      </div>
                      {/* Incident time */}
                      <div className='relative bg-white px-2 pt-3 pb-2 shadow rounded-lg text-left'>
                        <dt>
                          <div className='absolute bg-green-200 rounded-md p-3'>
                            <ClockIcon className='h-6 w-6 text-green-600' aria-hidden='true' />
                          </div>
                          <p className='ml-16 text-sm font-medium text-gray-500 truncate'>
                            {t('overview.time')}
                          </p>
                        </dt>
                        <dd className='ml-16 flex items-baseline'>
                          <p className='text-lg leading-7 font-semibold text-gray-900'>
                            {issueStatusGroups.CLOSED.includes(issue.status) && issue.resolvedAt ? (
                              resolvedIssueTimer(
                                new Date(issue.createdAt),
                                new Date(issue.resolvedAt)
                              )
                            ) : (
                              <Timer startsAt={new Date(issue.createdAt)}>
                                {({ hours, minutes, seconds }) => (
                                  <span>
                                    {String(hours).padStart(2, '0')}:
                                    {String(minutes).padStart(2, '0')}:
                                    {String(seconds).padStart(2, '0')}
                                  </span>
                                )}
                              </Timer>
                            )}
                          </p>
                        </dd>
                      </div>
                    </div>
                    {/* Issue Images */}
                    {!!issue?.media?.length && (
                      <div className='flex flex-col'>
                        <h2>{t('images')}</h2>
                        <div className='flex flex-row gap-8 '>
                          {issue.media.map((media, index) => (
                            <div className='flex flex-col items-center gap-4 justify-center'>
                              <img
                                className='rounded-md h-48 w-auto object-cover'
                                key={index}
                                src={media.url}
                                alt='issue media'
                              />
                              <Button
                                color='secondary'
                                size={SIZE.EXTRA_SMALL}
                                onClick={() => handleDownloadFile(media.url, index)}
                              >
                                {t('nsEntriesReport:document')}
                              </Button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {/* Entries */}
                    <div className='bg-gray-100 rounded-lg p-4'>
                      <dl className='space-y-2'>
                        {issue.entries
                          // Remove disabled entries
                          .filter(({ enabled }) => !!enabled)
                          // Render each enabled issue entry
                          .map(entry => (
                            <div key={entry.id}>
                              <dt className='text-sm leading-5 font-semibold text-gray-700'>
                                {entry.name}:
                              </dt>
                              <dd className='text-sm leading-5 font-normal text-gray-500'>
                                <IssueEntry entry={entry} />
                              </dd>
                            </div>
                          ))}
                      </dl>
                    </div>

                    <div className='flex items-center flex-row gap-4'>
                      <span>{t('subtitle.show')}</span>
                      <Dropdown
                        color='secondary'
                        items={[
                          {
                            id: IssueModalShowMode.COMMENTS,
                            name: t('show.comments'),
                            onClick: () => setIssueModalShowOption(IssueModalShowMode.COMMENTS),
                          },
                          {
                            id: IssueModalShowMode.ACTIVITY,
                            name: t('show.activity'),
                            onClick: () => setIssueModalShowOption(IssueModalShowMode.ACTIVITY),
                          },
                        ]}
                      >
                        {issueModalShowOption === IssueModalShowMode.COMMENTS
                          ? t('show.comments')
                          : t('show.activity')}
                      </Dropdown>
                    </div>

                    {/* History */}
                    {issueModalShowOption === IssueModalShowMode.ACTIVITY && (
                      <div>
                        <IssueHistory issueId={issue._id} />
                      </div>
                    )}

                    {/* New Comment */}
                    {issueModalShowOption === IssueModalShowMode.COMMENTS && (
                      <div>
                        <div className='space-y-4'>
                          <TextAreaField
                            id='comment'
                            name='comment'
                            label={t('comment.label')}
                            onChange={event =>
                              handleCommentChange(issue._id, event.currentTarget.value)
                            }
                            value={commentValue[issue._id]}
                          />
                          <div className='flex flex-row-reverse'>
                            <Button
                              color='secondary'
                              size={SIZE.EXTRA_SMALL}
                              onClick={() => handleCommentSubmit(issue._id)}
                              disabled={!commentValue[issue._id]}
                            >
                              {t('comment.submit')}
                            </Button>
                          </div>
                        </div>
                        <IssueComments issueId={issue._id} />
                      </div>
                    )}
                  </div>
                </Section>
              </li>
            ))}
          </ul>
        )}
      </Modal.Body>
    </Modal>
  )
}
