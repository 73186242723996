import { PDF_MANUAL_EN_URL, PDF_MANUAL_ES_URL } from '@/app/constants'
import { useAnalytics } from '@/features/analytics'
import { useUserPermissions } from '@/features/auth'
import i18n from '@/i18n'
import { CallToAction } from '@blockchain-traceability-sl/tailwind-components'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export const EmptyToday = () => {
  const { t } = useTranslation('nsTasksListPage')
  const history = useHistory()
  const analytics = useAnalytics()
  const userPermissions = useUserPermissions()
  const language = i18n.language

  const userManualUrl = useMemo(() => {
    if (language === 'es') {
      return PDF_MANUAL_ES_URL
    }
    return PDF_MANUAL_EN_URL
  }, [language])

  return (
    <CallToAction
      title={t('tabPendingComplete.callToAction.title')}
      subtitle={t('tabPendingComplete.callToAction.subtitle')}
      primaryActionText={t('tabPendingComplete.callToAction.action')}
      primaryActionDisabled={!userPermissions.tasks.write}
      onPrimaryActionClick={() => {
        analytics.track('ACTION_CLICK_CREATE_TASK', {
          From: 'CallToAction',
        })
        history.push('/tasks/create')
      }}
      secondaryActionText={t('help')}
      onSecondaryActionClick={() => {
        analytics.track('ACTION_CLICK_HELP', { Source: 'Tasks section' })
        window.open(userManualUrl, '_blank')
      }}
    />
  )
}
