import { useTranslation } from 'react-i18next'
import { TRUCreationOrUpdateOriginInput } from '../../interfaces'
import { TrashIcon } from '@heroicons/react/solid'
import { useToggle } from '@/hooks/use-toggle'
import { Modal, SIZE } from '@blockchain-traceability-sl/tailwind-components'

interface TruOriginsResumeProps {
  // Current origins selected from form context
  origins: TRUCreationOrUpdateOriginInput[]
  // Origin manage handlers
  onRemoveOrigin: (entityId: string) => void
}

export const TruOriginsResume = ({ origins, onRemoveOrigin }: TruOriginsResumeProps) => {
  const { t } = useTranslation('nsTRUCreationManualPage')
  const [showOriginsSeeMore, toggleShowOriginsSeeMore] = useToggle(false)

  if (origins.length === 0) return null

  return (
    <>
      <div className='mt-5 flex flex-row items-top justify-start space-x-4'>
        <div>
          <p className='text-black mt-2 leading-5 font-normal'>{t('form.origins.selected')}:</p>
        </div>
        <div className='flex-grow'>
          {origins.slice(0, 4).map(({ entityId, stakeHolder, product, reference }) => (
            <button
              key={entityId}
              type='button'
              className='mr-2 mb-2 inline-flex text-left items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none'
              onClick={() => onRemoveOrigin(entityId)}
            >
              {stakeHolder?.name} - {product?.name} - {reference}
              <span>
                <TrashIcon className='ml-2 -mr-0.5 h-4 w-4' aria-hidden='true' />
              </span>
            </button>
          ))}
          {origins.length > 4 && (
            <button className='underline text-blue-600' onClick={toggleShowOriginsSeeMore}>
              {t('form.origins.seeMore')}
            </button>
          )}
        </div>
      </div>

      {/* Modal see more origins */}
      <Modal
        show={showOriginsSeeMore}
        toggle={toggleShowOriginsSeeMore}
        size={SIZE.EXTRA_LARGE}
        dismissButton
      >
        <Modal.Title>
          <p className='text-lg leading-6 font-medium'>{t('modalOrigins.title')}</p>
        </Modal.Title>
        <Modal.Body>
          <div>
            {origins.map(({ entityId, stakeHolder, product, reference }) => (
              <button
                key={entityId}
                type='button'
                className='mr-2 mb-2 inline-flex text-left items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none'
                onClick={() => {
                  if (origins.length === 1) {
                    toggleShowOriginsSeeMore()
                  }
                  onRemoveOrigin(entityId)
                }}
              >
                {stakeHolder?.name} - {product?.name} - {reference}
                <span>
                  <TrashIcon className='ml-2 -mr-0.5 h-4 w-4' aria-hidden='true' />
                </span>
              </button>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
