import { api } from '@/app/api'
import { StockRelation } from './entity'

/**
 * Define the url path
 * @constant
 */
export const STOCK_RELATION_URL = '/hydra/stock'

/**
 * Get StockRelations by parent id
 */
export const getByParentId = async (parentId: string): Promise<StockRelation[]> => {
  const { data: stockRelations } = await api.get<StockRelation[] | void>(
    `${STOCK_RELATION_URL}/${parentId}`
  )
  return stockRelations || []
}

/**
 * Get StockRelations by parent ids
 */
export const getByParentIds = async (parentIds: string[]): Promise<StockRelation[]> => {
  const { data: stockRelations } = await api.post<StockRelation[] | void>(
    `${STOCK_RELATION_URL}/ids`,
    {
      ids: parentIds,
    }
  )
  return stockRelations || []
}
