import { useCallback, useMemo, useReducer, useState } from 'react'
import { ProductFiltersState } from '../../interfaces'

/**
 * This is a special value that is used to indicate that all options are selected.
 */
export const ALL_FILTER_OPTION_VALUE = '@ALL' as const

interface ProductFiltersAction extends Partial<ProductFiltersState> {
  type: 'change' | 'reset'
}

const initialState: ProductFiltersState = {
  search: '',
  tags: [],
}

/**
 * This function is used to clean the `tags` array when the `ALL` option is selected.
 * @param previousArray The previous value of the `tags` array.
 * @param array The new value of the `tags` array.
 * @param isAllOption A function that returns `true` if the given item is the `ALL` option.
 * @returns The new value of the `tags` array.
 */
const cleanAllOption = <T>(
  previousArray: T[],
  array: T[],
  { isAllOption }: { isAllOption: (item: T) => boolean }
) => {
  const allPreviouslySelected = previousArray.length === 0
  const allSelected = array.find(isAllOption)

  if (allPreviouslySelected && allSelected) {
    return array.filter(value => !isAllOption(value))
  }

  if (!allPreviouslySelected && allSelected) {
    return []
  }

  return array
}

/**
 * This reducer is used to manage the state of the product filters.
 * @param state The previous state.
 * @param action The action to perform.
 * @returns The new state.
 */
const productFiltersReducer = (state: ProductFiltersState, action: ProductFiltersAction) => {
  const { type, ...filters } = action
  if (type === 'reset') {
    return initialState
  }

  const newState = { ...state }

  if (filters.search !== undefined) {
    newState.search = filters.search
  }

  if (filters.tags) {
    newState.tags = cleanAllOption(state.tags, filters.tags, {
      isAllOption: status => status === ALL_FILTER_OPTION_VALUE,
    })
  }

  return newState
}

/**
 * This hook is used to manage the state of the product filters.
 * @returns The state of the product filters.
 * @example
 * const { filters, isFiltersEmpty, isFiltersTouched, changeFilter, resetFilters } = useProductFilters()
 */
export const useProductFilters = () => {
  const [state, dispatch] = useReducer(productFiltersReducer, initialState)
  const [isFiltersTouched, setIsFiltersTouched] = useState(false)

  const isFiltersEmpty = useMemo(() => Object.values(state).every(value => !value), [state])

  const changeFilter = useCallback((filter: Omit<ProductFiltersAction, 'type'>) => {
    dispatch({ type: 'change', ...filter })
    setIsFiltersTouched(true)
  }, [])

  const resetFilters = useCallback(() => {
    dispatch({ type: 'reset' })
    setIsFiltersTouched(false)
  }, [])

  return {
    filters: state,
    isFiltersEmpty,
    isFiltersTouched,
    changeFilter,
    resetFilters,
  }
}
