import { Product } from '@/features/products'
import {
  Traceability,
  TraceabilityEdge,
  TraceabilityErrorCodes,
  TraceabilityNode,
  TraceabilityTruEdge,
} from './interfaces'
import { TRU } from '@/features/trus'
import { Shipment } from '@/features/shipments'
import { Reception } from '@/features/receptions'
import { Metadata } from '@/features/metadatas'
import { StakeHolderProduct } from '@/features/stakeHolderProducts'
import { Facility } from '@/features/facilities'
import { traceabilityToLevelsOrder } from '@/helpers/traceability-to-levels-order'

export interface GetEntitiesFromTraceabilityResponse {
  nodes: TraceabilityNode[]
  edges: TraceabilityEdge[]
  truEdges: TraceabilityTruEdge[]
  products: (Product & { facility?: Facility })[]
  trus: (TRU & { metadata: Metadata[] | TraceabilityErrorCodes.METADATA_SERVICE_UNAVAILABLE })[]
  shipments: (Shipment & {
    metadata: Metadata[] | TraceabilityErrorCodes.METADATA_SERVICE_UNAVAILABLE
  })[]
  stakeHolderProducts: StakeHolderProduct[]
  receptions: (Reception & {
    metadata: Metadata[] | TraceabilityErrorCodes.METADATA_SERVICE_UNAVAILABLE
  })[]
  metadatas: Metadata[]
}

export const removeNegativeLevelsFromTraceability = (traceability: Traceability): Traceability => {
  const traceabilityLevels = traceabilityToLevelsOrder(traceability)
  const negativeLevels = traceabilityLevels.filter(({ level }) => Number(level) < 0)

  const edges = traceability.edges.filter(({ from, to }) => {
    // remove edges where from and to are on one of the negative levels
    const level = negativeLevels.find(level => level.from === from && level.to === to)
    return !level
  })

  const nodes = traceability.nodes.filter(node => node.level >= 0)

  return {
    ...traceability,
    nodes,
    edges,
  }
}

export const getEntitiesFromTraceability = (
  traceability?: Traceability
): GetEntitiesFromTraceabilityResponse =>
  traceability
    ? {
        nodes: traceability.nodes,
        edges: traceability.edges,
        truEdges: traceability.truEdges,
        products: traceability.nodes.flatMap(({ products }) =>
          Array.isArray(products) ? products : []
        ),
        trus: traceability.nodes.flatMap(({ trus }) => trus),
        shipments: traceability.nodes.flatMap(({ trus }) =>
          trus.flatMap(({ shipments }) => (Array.isArray(shipments) ? shipments : []))
        ),
        stakeHolderProducts: traceability.nodes.flatMap(({ stakeHolderProducts }) =>
          Array.isArray(stakeHolderProducts) ? stakeHolderProducts : []
        ),
        receptions: traceability.nodes.flatMap(({ receptions }) =>
          Array.isArray(receptions) ? receptions : []
        ),
        metadatas: [
          // TRU metadata
          ...traceability.nodes.flatMap(({ trus }) =>
            trus.flatMap(({ metadata }) => (Array.isArray(metadata) ? metadata : []))
          ),
          // TRU shipment
          ...traceability.nodes.flatMap(({ trus }) =>
            trus
              .flatMap(({ shipments }) => (Array.isArray(shipments) ? shipments : []))
              .flatMap(({ metadata }) => (Array.isArray(metadata) ? metadata : []))
          ),
          // TRU reception
          ...traceability.nodes.flatMap(({ receptions }) =>
            (Array.isArray(receptions) ? receptions : []).flatMap(({ metadata }) =>
              Array.isArray(metadata) ? metadata : []
            )
          ),
        ],
      }
    : {
        nodes: [],
        edges: [],
        truEdges: [],
        products: [],
        trus: [],
        shipments: [],
        stakeHolderProducts: [],
        receptions: [],
        metadatas: [],
      }
