import { ErrorBoundary } from '@/app/ErrorBoundary'
import { ButtonWithIcon } from '@blockchain-traceability-sl/tailwind-components'
import { LoginIcon } from '@heroicons/react/outline'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import ReactJoyride, { CallBackProps } from 'react-joyride'
import { useHistory, useLocation } from 'react-router-dom'
import { WAITING_RENDER_PAGE } from '../constants'
import { useOnboarding } from '../hooks'
import { OnboardingStep } from '../steps'

export const OnboardingTourGuide = () => {
  const { t } = useTranslation('nsOnboarding')
  const { steps, run, stepIndex, skipDemo, active } = useOnboarding()
  const history = useHistory()
  const location = useLocation()
  const currentStep = steps[stepIndex]

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, step } = data
    const onboardingStep = step as OnboardingStep
    if (
      ['next', 'prev', 'start'].includes(action) &&
      onboardingStep.path &&
      location.pathname !== onboardingStep.path
    ) {
      if (onboardingStep.path) {
        history.push(onboardingStep.path)
      }
    }
  }

  const firstLoadRef = useRef(false)

  useEffect(() => {
    const currentStepPath = currentStep?.path
    if (
      !firstLoadRef.current &&
      active &&
      currentStepPath &&
      currentStepPath !== location.pathname
    ) {
      firstLoadRef.current = true
      setTimeout(() => {
        history.push(currentStepPath)
      }, WAITING_RENDER_PAGE)
    }
  }, [currentStep?.path, active, location.pathname, history])

  if (steps.length === 0) return null
  return (
    <ErrorBoundary disableErrorPage>
      {run && !currentStep?.disableSkipDemoButton && (
        <div
          style={{
            zIndex: 300,
          }}
        >
          <ButtonWithIcon
            className='fixed top-0 right-0 mt-4 mr-4'
            color='white'
            IconComponent={LoginIcon}
            style={{
              zIndex: 300,
            }}
            onClick={skipDemo}
          >
            {t('steps.skip')}
          </ButtonWithIcon>
        </div>
      )}

      <ReactJoyride
        callback={handleJoyrideCallback}
        hideBackButton
        hideCloseButton
        run={run}
        scrollToFirstStep
        stepIndex={stepIndex}
        showProgress={false}
        showSkipButton={false}
        steps={steps}
        spotlightPadding={5}
        floaterProps={{
          disableFlip: true,
        }}
        styles={{
          options: {
            width: 512,
          },
          tooltip: {
            padding: 0,
          },
          tooltipContent: {
            padding: '24px',
          },
        }}
      />
    </ErrorBoundary>
  )
}
