type ReplacerFunction<T = unknown> = (
  value: T,
  index: number,
  array: T[]
) => string | undefined | null

const defaultReplacerFunction: ReplacerFunction = value => String(value)

/**
 * Print an array of values with a pretty human readable format
 * @param array The array where the data exist
 * @param replacerFunction Replacer function to extract the values to format
 * @returns A prettify format of all values extracted with string format
 */
export const arrayPrettify = <T = unknown>(
  array: T[],
  replacerFunction: ReplacerFunction<T> = defaultReplacerFunction
): string => {
  return (
    array
      // Apply replacer function to extract the value that needs
      .map(replacerFunction)
      // Remove repeated and without value items
      .filter(
        (item, index, self) => item && index === self.findIndex(selfItem => selfItem === item)
      )
      // Join the items with a pretty format
      .join(', ')
  )
}
