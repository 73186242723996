import { EditableEntry, TemplateEntryType } from '@/features/templates'

// Delete the entry and all its child entries
export const deepDeleteEntry = (
  entryId: string,
  entries: Pick<EditableEntry, 'id' | 'blockId' | 'type'>[]
): string[] => {
  const entryToDelete = entries.find(({ id }) => id === entryId)
  if (!entryToDelete) return []

  const childEntriesToDelete: string[] = []
  if (
    entryToDelete.type === TemplateEntryType.SECTION ||
    entryToDelete.type === TemplateEntryType.CONDITIONAL
  ) {
    childEntriesToDelete.push(
      ...entries.filter(entry => entry.blockId === entryToDelete.id).map(({ id }) => id)
    )
  }

  return [entryId, ...childEntriesToDelete.flatMap(childId => deepDeleteEntry(childId, entries))]
}
