import { PencilIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateLocaleField } from '@/components/DateLocaleField'

export const TaskDetailsCardDueDate = ({
  taskId,
  dueDate,
  onDueDateChange,
}: {
  taskId: string
  dueDate: string
  onDueDateChange: (value: string) => void
}) => {
  const { t } = useTranslation('nsCompliancesList')
  const [isDateFieldPopperOpen, setIsDateFieldPopperOpen] = useState(false)

  return (
    <DateLocaleField
      id={`task-duedate-date-field-${taskId}`}
      name={`task-duedate-date-field-${taskId}`}
      popperIsOpen={isDateFieldPopperOpen}
      onPopperChange={setIsDateFieldPopperOpen}
      value={dueDate}
      onChange={value => {
        if (!value) return
        onDueDateChange(value)
      }}
    >
      <button
        className='group flex items-center gap-2 text-sm leading-tight'
        onClick={() => {
          setIsDateFieldPopperOpen(true)
        }}
      >
        <div>{t('table.deadlineAtFormat', { date: new Date(dueDate) })}</div>
        <div>
          <PencilIcon className='h-5 w-5 text-gray-300' />
        </div>
      </button>
    </DateLocaleField>
  )
}
