export enum ACTIVITY_TYPE {
  TASK_CREATION,
  TASK_ASSIGN,
  TASK_CLOSE,
  TASK_OPEN,
  TASK_UPDATED,
  VALIDATION_CREATED,
  VALIDATION_ACCEPT,
  VALIDATION_REJECT,
  TASK_REPORT_SENT,
}
