import { nanoid } from 'nanoid'
import * as Yup from 'yup'
import {
  DirtyTemplateDataInput,
  EditableEntry,
  EditableIssueTemplateInput,
  IssueTemplateInput,
  IssueTemplatesChanges,
  TemplateAssociationType,
  TemplateDataInput,
  TemplateDeprecatedEntryType,
  TemplateEntry,
  TemplateEntryRedesignError,
  TemplateEntryReferenceType,
  TemplateEntryReferenceValue,
  TemplateEntryType,
  TemplateEntryValues,
  TemplateTableEntryColumn,
  TemplateType,
} from './interfaces'

export const TemplateTableColumnSchema = Yup.object().shape({
  title: Yup.string().required(),
  lock: Yup.boolean(),
  rows: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required(),
      values: Yup.array()
        .of(Yup.mixed())
        .test('values', function (values) {
          if (
            this.parent.type === TemplateEntryType.MULTIPLE_CHOICE ||
            this.parent.type === TemplateEntryType.SINGLE_CHOICE
          ) {
            return !!values && Array.isArray(values) && values.length !== 0
          }
          return true
        }),
    })
  ),
})

export const isValidTemplateTableColumns = (columns: TemplateTableEntryColumn[]): boolean =>
  columns.every(column => TemplateTableColumnSchema.isValidSync(column))

export const isMandatoriableField = (
  type: TemplateEntryType | TemplateEntryReferenceType
): boolean => {
  return !(
    [
      TemplateEntryType.STATEMENT,
      TemplateEntryType.TABLE,
      TemplateEntryType.CONDITIONAL,
      TemplateEntryType.SECTION,
      TemplateEntryType.ISSUE_REFERENCE,
    ] as (TemplateEntryType | TemplateEntryReferenceType)[]
  ).includes(type)
}

export const TemplateEntriesSchema = Yup.array().of(
  Yup.object().shape({
    type: Yup.string().required(),
    name: Yup.string().when('isNewEntry', {
      is: true,
      otherwise: schema => schema.required(),
    }),
    values: Yup.array().when('type', {
      is: TemplateEntryType.TABLE,
      then: schema => schema.of(TemplateTableColumnSchema),
      otherwise: schema =>
        schema.of(Yup.mixed()).test('values', function (values) {
          if (this.parent.blockId) return true
          switch (this.parent.type) {
            case TemplateEntryType.MULTIPLE_CHOICE:
            case TemplateEntryType.SINGLE_CHOICE:
              return !!values && Array.isArray(values) && values.length !== 0
            default:
              return true
          }
        }),
    }),
    defaultValues: Yup.array(Yup.string()),
  })
)

export const getDefaultTemplateValue = (
  type: TemplateEntryType | TemplateEntryReferenceType
): TemplateEntryValues => {
  switch (type) {
    case TemplateEntryReferenceType.PRODUCT:
    case TemplateEntryReferenceType.TRU:
    case TemplateEntryReferenceType.STAKEHOLDER:
      return [{ type }]
    case TemplateEntryType.CONDITIONAL:
      return [[], []]
    default:
      return []
  }
}

export const normalizeTemplateType = (
  type: TemplateEntryType | TemplateEntryReferenceType
): TemplateEntryType => {
  switch (type) {
    case TemplateEntryReferenceType.PRODUCT:
    case TemplateEntryReferenceType.TRU:
    case TemplateEntryReferenceType.STAKEHOLDER:
      return TemplateEntryType.REFERENCE
    default:
      return type
  }
}

export const patchDeprecationTypes = (
  type: TemplateDeprecatedEntryType | TemplateEntryType
): TemplateEntryType => {
  switch (type) {
    case TemplateDeprecatedEntryType.SELECTOR:
      return TemplateEntryType.SINGLE_CHOICE

    default:
      return type
  }
}

export const validateTemplateEntry = (entry?: EditableEntry): boolean => {
  return !(
    !!entry &&
    (!entry.name ||
      ((entry.type === TemplateEntryType.SINGLE_CHOICE ||
        entry.type === TemplateEntryType.MULTIPLE_CHOICE) &&
        (entry.values || []).length === 0) ||
      (entry.type === TemplateEntryType.TABLE &&
        !isValidTemplateTableColumns(entry.values as TemplateTableEntryColumn[])))
  )
}

export const getErrorsByEntryIds = (
  errors: TemplateEntryRedesignError[],
  entries: EditableEntry[]
) =>
  entries.reduce<{ [id: string]: TemplateEntryRedesignError | undefined }>(
    (accumulator, targetEntry) => {
      const index = entries.findIndex(entry => entry.id === targetEntry.id)
      if (index === -1) return accumulator
      return {
        ...accumulator,
        [targetEntry.id]: errors[index],
      }
    },
    {}
  )

export const normalizeDirtyTemplateEntries = (dirtyEntries: EditableEntry[]) =>
  dirtyEntries
    .filter(entry => validateTemplateEntry(entry))
    .map(({ editable, isNewEntry, ...entry }) => {
      const entryType = entry.type as TemplateEntryType | TemplateEntryReferenceType
      if (
        entryType === TemplateEntryReferenceType.STAKEHOLDER ||
        entryType === TemplateEntryReferenceType.PRODUCT ||
        entryType === TemplateEntryReferenceType.TRU
      ) {
        return {
          ...entry,
          type: TemplateEntryType.REFERENCE,
          values: [
            {
              type: entry.type,
            },
          ],
        }
      }

      return entry
    }) as TemplateEntry[]

export const normalizeDirtyDepartments = (departments: string[]) =>
  departments.map(departmentId => ({
    id: departmentId,
    type: TemplateAssociationType.DEPARTMENT,
  }))

export const normalizeDirtyIssueTemplate = (
  issueTemplate: EditableIssueTemplateInput,
  formEntries: TemplateEntry[]
) => {
  const issueReferenceEntry = formEntries.find(entry => entry.id === issueTemplate.entryId)
  if (issueReferenceEntry) {
    const normalizedEntries = normalizeDirtyTemplateEntries(issueTemplate.entries)

    const block = issueReferenceEntry.blockId
      ? formEntries.find(entry => entry.id === issueReferenceEntry.blockId)
      : undefined

    return {
      ...issueTemplate,
      name: `${issueReferenceEntry.name}${block ? ` - ${block.name}` : ''}`,
      entries: normalizedEntries,
    }
  }
}

export const normalizeDirtyCreationTemplate = (
  dirty: DirtyTemplateDataInput
): { template: TemplateDataInput; issueTemplates?: IssueTemplateInput[] } => {
  const { issueTemplates, newEntry, entries, departments, ...dirtyTemplate } = dirty

  const normalizedEntries = normalizeDirtyTemplateEntries([...entries, newEntry])

  return {
    template: {
      ...dirtyTemplate,
      associations: normalizeDirtyDepartments(departments),
      entries: normalizedEntries,
      defaults: {
        ...dirtyTemplate.defaults,
        assignedTo: dirtyTemplate.defaults?.assignedTo?.value || null,
        issueResponder: dirtyTemplate.defaults?.issueResponder?.value || null,
        taskValidator: dirtyTemplate.defaults?.taskValidator?.value || null,
      },
      tags: dirtyTemplate.tags || [],
    },
    issueTemplates: issueTemplates.reduce<IssueTemplateInput[]>((accumulator, issueTemplate) => {
      const normalized = normalizeDirtyIssueTemplate(issueTemplate, normalizedEntries)

      if (normalized) {
        accumulator.push(normalized)
      }

      return accumulator
    }, []),
  }
}

export const normalizeDirtyUpdateTemplate = (
  dirty: DirtyTemplateDataInput,
  previousIssueTemplates: EditableIssueTemplateInput[]
): {
  template: TemplateDataInput
  issueTemplatesChanges?: IssueTemplatesChanges
} => {
  const { issueTemplates, newEntry, entries, departments, tags, ...dirtyTemplate } = dirty

  const normalizedEntries = normalizeDirtyTemplateEntries([...entries, newEntry])

  const issueTemplatesChanges = issueTemplates.reduce<IssueTemplatesChanges>(
    (accumulator, issueTemplate) => {
      const normalized = normalizeDirtyIssueTemplate(issueTemplate, normalizedEntries)

      if (normalized) {
        accumulator.push({
          operation: normalized.id ? 'update' : 'create',
          value: normalized,
        })
      }

      return accumulator
    },
    []
  )

  previousIssueTemplates.forEach(previousIssueTemplate => {
    if (!previousIssueTemplate.id) return
    const exists = issueTemplates.find(item => item.id === previousIssueTemplate.id)

    if (!exists) {
      issueTemplatesChanges.push({
        operation: 'delete',
        value: previousIssueTemplate.id,
      })
    }
  })

  return {
    template: {
      ...dirtyTemplate,
      associations: normalizeDirtyDepartments(departments),
      entries: normalizedEntries,
      defaults: {
        ...dirtyTemplate.defaults,
        assignedTo: dirtyTemplate.defaults?.assignedTo?.value || null,
        issueResponder: dirtyTemplate.defaults?.issueResponder?.value || null,
        taskValidator: dirtyTemplate.defaults?.taskValidator?.value || null,
      },
      tags: tags || [],
    },
    issueTemplatesChanges,
  }
}

export const transformTemplateEntryToDirty = (entry: TemplateEntry): EditableEntry => ({
  ...entry,
  type:
    entry.type === TemplateEntryType.REFERENCE
      ? (entry.values as [TemplateEntryReferenceValue])[0].type
      : entry.type,
  id: entry.id || nanoid(),
  editable: false,
})

export const getTemplateTypesByContext = (
  type: 'tasks' | 'compliances' | 'issues' | 'metadata'
): TemplateType[] => {
  let templateTypes: TemplateType[] = []

  switch (type) {
    case 'tasks':
      templateTypes = [TemplateType.INCIDENCE, TemplateType.REGISTRY]
      break
    case 'compliances':
      templateTypes = [TemplateType.COMPLIANCE]
      break
    case 'issues':
      templateTypes = [TemplateType.ISSUE]
      break
    case 'metadata':
      templateTypes = [TemplateType.METADATA]
      break
  }
  return templateTypes
}

export const getTemplateEndpointTypeByContext = (
  type: 'tasks' | 'compliances' | 'issues' | 'metadata'
): TemplateType | 'form' => {
  let templateType: TemplateType | 'form' = 'form'

  switch (type) {
    case 'tasks':
    case 'compliances':
      templateType = 'form'
      break
    case 'metadata':
      templateType = TemplateType.METADATA
      break
    case 'issues':
      templateType = TemplateType.ISSUE
      break
  }
  return templateType
}
