import { StakeHolder } from '@/features/stakeHolders'
import { Trans, useTranslation } from 'react-i18next'
import {
  Compliance,
  ComplianceEntityType,
  ComplianceStatus,
  ComplianceValidationStatus,
} from '../interfaces'

export const ComplianceListDetails = ({
  status,
  compliantEntityType,
  compliantStakeHolder,
  compliantStakeHolderProduct,
  stakeholder,
  lastValidationStatus,
}: Pick<
  Compliance,
  'status' | 'compliantEntityType' | 'compliantStakeHolder' | 'compliantStakeHolderProduct'
> & { stakeholder?: Partial<StakeHolder>; lastValidationStatus?: ComplianceValidationStatus }) => {
  const { t } = useTranslation('nsCompliancesList', { keyPrefix: 'table' })

  return (
    <>
      <Trans
        t={t}
        i18nKey='detailsFormat'
        values={{
          stakeHolderName:
            stakeholder?.company?.name ||
            compliantStakeHolder.name ||
            compliantStakeHolder.contactDetails?.name ||
            compliantStakeHolder.emails[0],
        }}
        components={{ b: <b /> }}
      />
      {/* Show raw material info only in approved stakeholder product compliances */}
      {status === ComplianceStatus.ACCEPTED &&
        compliantEntityType === ComplianceEntityType.STAKEHOLDER_PRODUCT &&
        compliantStakeHolderProduct?.reference &&
        lastValidationStatus === ComplianceValidationStatus.APPROVED && (
          <>
            <br />
            <Trans
              t={t}
              i18nKey='detailsRawMaterial'
              values={{
                rawMaterialName: compliantStakeHolderProduct.reference,
              }}
              components={{ b: <b /> }}
            />
          </>
        )}
    </>
  )
}
