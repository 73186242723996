import { CompanyAuthAddons } from '@/features/company'
import { usePrevious } from '@/hooks/use-previous'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useAnalytics } from '@/features/analytics'
import { useAuthentication, useUserAddons } from '@/features/auth'
import { OnboardingStepModalContent } from './components/OnboardingStepModalContent'
import { OnboardingContext } from './context'
import { Onboarding } from './interfaces'
import {
  getMyOnboarding,
  markOnboardingAsCompleted,
  markOnboardingStepAsCompleted,
} from './service'
import { OnboardingStep, onboardingSteps } from './steps'

const ONBOARDING_KEY = 'onboarding'

export const useOnboarding = () => {
  return useContext(OnboardingContext)
}

export const useCurrentOnboardingStep = (): OnboardingStep | undefined => {
  const { stepIndex, steps } = useOnboarding()
  return steps[stepIndex]
}

/**
 *
 * Use when your step is pointing to a component that doesn't depend on data.
 * If it depends on data, use `useOnboardingStepWithData`
 */
export const useOnboardingStep = (key: string) => {
  const { startStep, active, steps, isLoading: isOnboardingLoading, stepIndex } = useOnboarding()
  const currentStep = steps[stepIndex]

  useEffect(() => {
    if (active && !isOnboardingLoading && currentStep?.key === key) {
      startStep()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, active, isOnboardingLoading, currentStep?.key])
}

/**
 *
 * Use when your step is pointing to a component that depends on data.
 * We need to wait to load all the data that is causing re-renderings,
 * if not we can have inconsistencies and bugs in the onboarding
 */
export const useOnboardingStepWithData = (
  key: string,
  { waitingData }: { waitingData: boolean }
) => {
  const { startStep, active, steps, isLoading: isOnboardingLoading, stepIndex } = useOnboarding()
  const previousWaitingData = usePrevious(waitingData)
  const [waitingDataFinished, setWaitingDataFinished] = useState(false)

  useEffect(() => {
    if (previousWaitingData && waitingData === false) {
      setWaitingDataFinished(true)
    } else if (waitingData && waitingDataFinished) {
      setWaitingDataFinished(false)
    }
  }, [waitingData, previousWaitingData, waitingDataFinished])

  const currentStep = steps[stepIndex]

  useEffect(() => {
    if (active && !isOnboardingLoading && currentStep?.key === key && waitingDataFinished) {
      startStep()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, active, isOnboardingLoading, currentStep?.key, waitingDataFinished])
}

export const useMyOnboarding = () => {
  const { isActivated } = useAuthentication()
  const { data, isLoading } = useQuery([ONBOARDING_KEY], getMyOnboarding, {
    enabled: isActivated,
  })

  return {
    myOnboarding: data,
    isLoading,
  }
}

export const useMarkOnboardingAsCompleted = (onboardingId?: string) => {
  const queryClient = useQueryClient()
  const { mutate } = useMutation(
    () => (onboardingId ? markOnboardingAsCompleted(onboardingId) : Promise.resolve()),
    {
      onSuccess() {
        queryClient.invalidateQueries([ONBOARDING_KEY])
      },
    }
  )

  return mutate
}

export const useMarkOnboardingStepAsCompleted = (onboardingId?: string) => {
  const { mutate } = useMutation(({ stepId }: { stepId: string }) =>
    onboardingId ? markOnboardingStepAsCompleted(onboardingId, stepId) : Promise.resolve()
  )

  return mutate
}

interface UseOnboardingStepsProps {
  myOnboarding?: Onboarding
  isInTutorial?: boolean
  next: (stepId?: string) => void
  nextLast: (stepId?: string) => void
  back: () => void
  skip: () => void
  close: () => void
  complete: () => void
}

export const useOnboardingSteps = ({
  myOnboarding,
  isInTutorial,
  next,
  back,
  skip,
  close,
  complete,
  nextLast,
}: UseOnboardingStepsProps) => {
  const { t } = useTranslation('nsOnboarding')
  const addons = useUserAddons()
  const analytics = useAnalytics()
  return useMemo(
    () => {
      const onboardingStepsWithIdsOrNot = myOnboarding
        ? onboardingSteps.map(step => ({
            ...step,
            ...myOnboarding.steps.find(myStep => myStep.name === step.key),
          }))
        : onboardingSteps

      const pendingOnboardingSteps = onboardingStepsWithIdsOrNot.filter(step => {
        let enabledByAddon = true

        if (step.addon && addons) {
          const addon = addons[step.addon as keyof CompanyAuthAddons]
          enabledByAddon = (addon as { enabled: boolean }).enabled
        }

        // Enable this conditional to protect new steps by feature flag
        // if (!flag && step.experimental) {
        //   return false
        // }

        return enabledByAddon
      })
      return pendingOnboardingSteps.map((step, index, array) => {
        let actionProps = {}
        const isLast = index === array.length - 1
        const isPreviousLast = index === array.length - 2
        if (step.initial) {
          actionProps = {
            skip,
            skipButtonText: t('steps.initial.skip'),
            next: () => {
              analytics.track('ACTION_CLICK_START_DEMO')
              next()
            },
            nextButtonText: t('steps.initial.next'),
            close,
            closeButtonText: t('steps.initial.close'),
          }
        } else if (isLast) {
          actionProps = {
            complete,
            completeButtonText: t('steps.last.complete'),
          }
        } else {
          actionProps = {
            next: isPreviousLast ? () => nextLast(step._id) : () => next(step._id),
            nextButtonText: t('steps.next'),
            back,
            backButtonText: t('steps.back'),
          }
        }
        return {
          ...step,
          content: (
            <OnboardingStepModalContent
              title={t(`step.${step.key}.title`)}
              description={
                <Trans
                  t={t}
                  i18nKey={`step.${step.key}.description`}
                  components={{ b: <b />, p: <p className='mt-4' /> }}
                />
              }
              {...actionProps}
            />
          ),
          disableBeacon: true,
          disableCloseOnEsc: true,
          disableOverlayClose: true,
          hideCloseButton: true,
          hideFooter: true,
          hideBackButton: true,
          floaterProps: {
            disableFlip: true,
          },
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addons, myOnboarding, isInTutorial, analytics]
  )
}
