import { DateRangeLocaleField } from '@/components/DateRangeLocaleField'
import { useMyProfile } from '@/features/auth'
import { useAnalytics } from '@/features/analytics'
import { useTemplateTags, useTemplatesForTasks } from '@/features/templates'
import { useUsers } from '@/features/users'
import {
  Card,
  SelectField,
  SelectMultiField,
  createSelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { TrashIcon } from '@heroicons/react/solid'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TaskFilters } from '../interfaces'
import { useReadLocalStorage } from 'usehooks-ts'
import { CALENDAR_VIEW_TYPE } from './TaskCalendar'
import { PERSISTED_TASK_CALENDAR_VIEW_TYPE_KEY } from '@/pages/tasks/TasksList/constants'

export type TaskFiltersState = Pick<
  TaskFilters,
  'formId' | 'assignedTo' | 'startDate' | 'endDate' | 'tagsIn'
>

export const TaskListFilters = ({
  onFiltersChange,
  isCalendarView,
  filters,
}: {
  isCalendarView: boolean
  filters: TaskFiltersState
  onFiltersChange: (filters: TaskFiltersState) => void
}) => {
  const { t } = useTranslation('nsTasksListPage')
  const analytics = useAnalytics()
  const currentTaskCalendarView = useReadLocalStorage<CALENDAR_VIEW_TYPE>(
    PERSISTED_TASK_CALENDAR_VIEW_TYPE_KEY
  )

  const { templateTags } = useTemplateTags()
  const { users } = useUsers()
  const { templates } = useTemplatesForTasks({ sortBy: 'name' })
  const { profile } = useMyProfile()

  const { startDate, endDate, formId, assignedTo, tagsIn: selectedTags } = filters

  const tagsSelectorOptions = useMemo(
    () => templateTags.map(tag => createSelectorOption(tag)),
    [templateTags]
  )

  const formOptions = useMemo(
    () =>
      templates
        .filter(template => !formId?.includes(template._id))
        .map(template => createSelectorOption(template.name, template._id)),
    [templates, formId]
  )

  const allUsersOptions = useMemo(
    () => [
      createSelectorOption(profile?.owner.email ?? '', profile?.company.id),
      ...users.map(user => user.toSelectorOption()),
    ],
    [profile, users]
  )

  const userOptions = useMemo(
    () => allUsersOptions.filter(option => !assignedTo?.includes(option.value)),
    [allUsersOptions, assignedTo]
  )

  const toggleTagChange = (tags: string[]) => {
    onFiltersChange({
      ...filters,
      tagsIn: tags.length > 0 ? tags : undefined,
    })
  }

  const toggleDateRangeChange = (startDate?: string, endDate?: string) => {
    if (startDate && endDate) {
      onFiltersChange({
        ...filters,
        startDate,
        endDate,
      })
    } else if (!startDate) {
      onFiltersChange({
        ...filters,
        startDate: undefined,
        endDate: undefined,
      })
    }
  }

  const toggleFormIdChange = (value: string) => {
    const { formId } = filters
    const isPresent = formId?.includes(value)

    onFiltersChange({
      ...filters,
      formId: !isPresent ? [...(formId ?? []), value] : formId?.filter(id => id !== value),
    })
  }

  const toggleUserIdChange = (value: string) => {
    const { assignedTo } = filters
    const isPresent = assignedTo?.includes(value)

    onFiltersChange({
      ...filters,
      assignedTo: !isPresent
        ? [...(assignedTo ?? []), value]
        : assignedTo?.filter(id => id !== value),
    })
  }

  const viewType = useMemo(() => {
    if (!isCalendarView) {
      return 'Task list'
    }

    if (!currentTaskCalendarView) {
      return 'Task calendar'
    }
    if (currentTaskCalendarView === CALENDAR_VIEW_TYPE.WEEKLY) {
      return 'Task calendar week'
    }
    if (currentTaskCalendarView === CALENDAR_VIEW_TYPE.MONTHLY) {
      return 'Task calendar month'
    }
  }, [currentTaskCalendarView, isCalendarView])

  return (
    <Card className='overflow-visible'>
      <Card.Body>
        <div className='flex flex-col'>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>{t('filters.title')}</h3>
          <p className='mt-1 text-sm text-gray-400'>{t('filters.description')}</p>

          <div className='mt-5'>
            <div className='flex flex-col w-full md:flex-row space-y-4 md:space-x-4 md:space-y-0'>
              <div className='flex flex-col w-full'>
                <SelectField
                  id='task-list-filters-select-task-name'
                  name='formId'
                  isSearchable
                  options={formOptions}
                  value={null}
                  label={t('filters.form.form.label')}
                  placeholder={t('filters.form.form.placeholder')}
                  menuPortalTarget={document.body}
                  onChange={option => {
                    if (!option) return
                    analytics.track('ACTION_FILTER_RESULTS', {
                      By: 'Form',
                      From: viewType,
                    })
                    toggleFormIdChange(option.value)
                  }}
                />

                {formId && formId.length > 0 && (
                  <ul>
                    {formId.map(id => (
                      <li className='mt-2' key={id}>
                        <button
                          type='button'
                          className='inline-flex text-left items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none'
                          onClick={() => {
                            toggleFormIdChange(id)
                          }}
                        >
                          {t('filters.form.form.value', {
                            form: templates.find(template => template._id === id)?.name,
                          })}
                          <span>
                            <TrashIcon className='ml-2 -mr-0.5 h-4 w-4' aria-hidden='true' />
                          </span>
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className='flex flex-col w-full'>
                <SelectField
                  id='task-list-filters-select-user'
                  name='userId'
                  isSearchable
                  options={userOptions}
                  value={null}
                  label={t('filters.form.user.label')}
                  placeholder={t('filters.form.user.placeholder')}
                  menuPortalTarget={document.body}
                  onChange={option => {
                    if (!option) return
                    analytics.track('ACTION_FILTER_RESULTS', {
                      By: 'User',
                      From: viewType,
                    })
                    toggleUserIdChange(option.value)
                  }}
                />
                {assignedTo && assignedTo.length > 0 && (
                  <ul>
                    {assignedTo.map(userId => (
                      <li className='mt-2' key={userId}>
                        <button
                          type='button'
                          className='inline-flex text-left items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none'
                          onClick={() => {
                            toggleUserIdChange(userId)
                          }}
                        >
                          {t('filters.form.user.value', {
                            user: allUsersOptions.find(option => option.value === userId)?.label,
                          })}
                          <span>
                            <TrashIcon className='ml-2 -mr-0.5 h-4 w-4' aria-hidden='true' />
                          </span>
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className='flex flex-col w-full'>
                <DateRangeLocaleField
                  id='task-list-filters-input-due-date'
                  name='dueDate'
                  isClearable
                  label={t('filters.form.dueDate.label')}
                  initialValue={
                    startDate && endDate
                      ? {
                          startDate: startDate,
                          endDate: endDate,
                        }
                      : undefined
                  }
                  value={{
                    startDate: startDate || null,
                    endDate: endDate || null,
                  }}
                  onChange={value => {
                    if (value.startDate && value.endDate) {
                      analytics.track('ACTION_FILTER_RESULTS', {
                        By: 'Due date',
                        From: viewType,
                      })
                      toggleDateRangeChange(value.startDate, value.endDate)
                    } else {
                      toggleDateRangeChange(undefined, undefined)
                    }
                  }}
                />
              </div>

              <div className='flex flex-col w-full'>
                <SelectMultiField
                  id='tags'
                  name='tags'
                  label={t('filters.form.tag.label')}
                  placeholder={t('filters.form.tag.placeholder')}
                  isSearchable
                  options={tagsSelectorOptions}
                  controlShouldRenderValue={false}
                  value={selectedTags?.map(tag => createSelectorOption(tag)) || []}
                  onChange={value => {
                    if (value.length) {
                      analytics.track('ACTION_FILTER_RESULTS', {
                        By: 'Tag',
                        From: viewType,
                      })

                      toggleTagChange(value.map(tag => tag.value))
                    } else {
                      toggleTagChange([])
                    }
                  }}
                />
                {selectedTags && selectedTags.length > 0 && (
                  <div className='flex flex-row flex-wrap'>
                    {selectedTags.map(tag => (
                      <div key={tag}>
                        <button
                          type='button'
                          className='inline-flex mr-2 mt-2 text-left items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none'
                          onClick={() => {
                            toggleTagChange(selectedTags.filter(t => t !== tag))
                          }}
                        >
                          {tagsSelectorOptions.find(option => option.value === tag)?.label}
                          <span>
                            <TrashIcon className='ml-2 -mr-0.5 h-4 w-4' aria-hidden='true' />
                          </span>
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}
