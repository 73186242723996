import { noop } from '@/utils/noop'
import { Button, CallToAction, Table } from '@blockchain-traceability-sl/tailwind-components'
import { memo, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  DEFAULT_PAGE_SIZE,
  PDF_MANUAL_EN_URL,
  PDF_MANUAL_ES_URL,
  TABLE_SIZE_PRESETS,
} from '@/app/constants'
import { usePaginatedTRUs } from '../hooks'
import { ITRUFilters, TRU } from '../interfaces'
import { useIsUserStockEnabled } from '@/features/auth'
import { useAnalytics } from '@/features/analytics'
import { useLocalStorage } from '@/hooks/use-local-storage'
import { getProductNameWithReference, useProductsByIds } from '@/features/products'
import { getTRUAvailableStock } from '../helpers'
import { useQueryShipmentsByTruIds } from '@/features/shipments'
import { useQueryStockRelationsByParentIds } from '@/features/stockRelations'
import { useHistory } from 'react-router-dom'
import i18n from '@/i18n'

export interface TRUsTableProps {
  filters?: ITRUFilters
  /**
   * Must be memoized to avoid extra renders
   */
  onRowManage?: (tru: TRU) => void
}

/**
 * Page size key for local storage
 * @see useLocalStorage
 * @internal
 * @constant
 * @nanoid length 10
 */
const PAGE_SIZE_KEY = 'y7H94xEo0Z'

export const TrusTable = memo(({ filters, onRowManage = noop }: TRUsTableProps) => {
  const { t } = useTranslation('nsTRUsListPage')
  const history = useHistory()
  const isUserStockEnabled = useIsUserStockEnabled()
  const analytics = useAnalytics()
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [pageSize, setPageSize] = useLocalStorage(PAGE_SIZE_KEY, DEFAULT_PAGE_SIZE)

  const {
    trus,
    totalCount,
    fetchNextPage,
    fetchPreviousPage,
    data,
    isLoading: isTrusLoading,
    isFetchingNextPage,
  } = usePaginatedTRUs({ filters: { ...filters }, pageSize })

  const currentPageItems = useMemo(() => data?.pages[pageIndex]?.items || [], [data, pageIndex])
  const truIds = useMemo(() => currentPageItems.map(({ _id }) => _id), [currentPageItems])

  const { products, isLoading: isProductsLoading } = useProductsByIds(
    Array.from(new Set(currentPageItems.map(({ productId }) => productId))),
    {
      enabled: trus.length > 0,
    }
  )
  const { shipments } = useQueryShipmentsByTruIds(truIds, {
    enabled: truIds.length > 0,
  })
  const { stockRelations } = useQueryStockRelationsByParentIds(truIds, {
    enabled: truIds.length > 0,
  })

  const handlePageChange = useCallback(
    (page: { pageIndex: number; pageSize: number }) => {
      if (pageIndex < page.pageIndex) {
        fetchNextPage()
      } else if (pageIndex > page.pageIndex) {
        fetchPreviousPage()
      }
      setPageIndex(page.pageIndex)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchNextPage, fetchPreviousPage]
  )

  const rows = useMemo(
    () =>
      currentPageItems.map(({ _id, reference, productId, quantity = '0' }) => {
        const product = products.find(({ _id }) => productId === _id)
        const availableStock = getTRUAvailableStock(_id, quantity, shipments, stockRelations)
        return {
          id: _id,
          tru: reference,
          product: product && getProductNameWithReference(product),
          shipments: shipments
            // Filter by tru id
            .filter(({ truId }) => truId === _id)
            // Remove repeated
            .filter(
              ({ _id }, index, self) => self.findIndex(selfItem => selfItem._id === _id) === index
            ).length,
          quantity: `${t('number', { value: availableStock })} ${product?.measurementUnit || ''}`,
          actions: <Button color='link'>{t('table.action.cell')}</Button>,
        }
      }),
    [currentPageItems, products, shipments, stockRelations, t]
  )

  const isLoading = isTrusLoading || isProductsLoading

  const language = i18n.language

  const userManualUrl = useMemo(() => {
    if (language === 'es') {
      return PDF_MANUAL_ES_URL
    }
    return PDF_MANUAL_EN_URL
  }, [language])

  return (
    <Table
      manualPagination
      onPageChange={handlePageChange}
      onPageSizeChange={setPageSize}
      totalCount={totalCount}
      pageSize={pageSize}
      defaultPageIndex={pageIndex}
      className='onboarding-products-list'
      columns={[
        {
          Header: t('table.tru').toString(),
          accessor: 'tru',
        },
        {
          Header: t('table.product').toString(),
          accessor: 'product',
        },
        {
          Header: t('table.shipments').toString(),
          accessor: 'shipments',
          disableGlobalFilter: true,
        },
        {
          Header: t('table.quantity').toString(),
          accessor: 'quantity',
          disableGlobalFilter: true,
          hidden: !isUserStockEnabled,
        },
        {
          Header: t('table.actions').toString(),
          accessor: 'actions',
          disableGlobalFilter: true,
        },
      ]}
      data={rows}
      isLoading={isLoading || isFetchingNextPage}
      onRowClick={row => onRowManage(trus.find(({ _id }) => _id === row.original.id) as TRU)}
      previousText={t('pagination.previous')}
      nextText={t('pagination.next')}
      renderInfoText={(from, to, total) => (
        <Trans
          t={t}
          i18nKey='pagination.show'
          components={{ b: <span className='font-semibold' /> }}
          values={{ from, to, total }}
        />
      )}
      sizePresetsText={t('pagination.items')}
      showSizePresets
      sizePresets={TABLE_SIZE_PRESETS}
      noDataText={
        <tr>
          <td colSpan={isUserStockEnabled ? 5 : 4}>
            <CallToAction
              className='bg-gray-100'
              title={t('table.notFound.title')}
              subtitle={t('table.notFound.subtitle')}
              primaryActionText={t('table.notFound.action')}
              onPrimaryActionClick={() => {
                analytics.track('ACTION_CLICK_CREATE_BATCH')
                history.push('/trus/create')
              }}
              secondaryActionText={t('needHelp')}
              onSecondaryActionClick={() => {
                analytics.track('ACTION_CLICK_HELP', {
                  Source: 'My batches no results section',
                })
                window.open(userManualUrl, '_blank')
              }}
            />
          </td>
        </tr>
      }
    />
  )
})
