import {
  EditableEntry,
  Template,
  TemplateEntry,
  TemplateEntryReferenceType,
  TemplateEntryReferenceValue,
  TemplateEntryType,
  TemplateEntryValues,
  TemplateTableEntryColumn,
  TemplateTableEntryRow,
  TemplateTableEntryRowValues,
} from '@/features/templates'
import { isValidHttpUrl } from '@/utils/is-valid-http-url'
import * as Yup from 'yup'
import { DirtyTableEntryColumn } from '../components/TableInputField'
import {
  Entry,
  EntryCreateInput,
  EntryValues,
  TableEntryColumn,
  TableEntryRow,
} from '../interfaces'
import { MAX_FILE_SIZE } from '@/app/constants'

const TemplateTextEntryTypes = [
  TemplateEntryType.SHORT_TEXT,
  TemplateEntryType.LONG_TEXT,
  TemplateEntryType.LONG_TEXT,
  TemplateEntryType.NUMBER,
  TemplateEntryType.LINK,
  TemplateEntryType.DATE,
  TemplateEntryType.TIME,
  TemplateEntryType.DOCUMENT,
  TemplateEntryType.MULTIPLE_CHOICE,
  TemplateEntryType.SINGLE_CHOICE,
]

const generateEntryValuesFromTemplateEntry = (
  entry: TemplateEntry | TemplateTableEntryRow
): EntryValues => {
  if (entry.type === TemplateEntryType.TABLE) {
    return entry.values.map(value => {
      const column = value as TemplateTableEntryColumn
      return {
        ...column,
        rows: column.rows.map(row => ({
          ...row,
          options: row.values,
          values: generateEntryValuesFromTemplateEntry(row),
        })),
      }
    }) as TemplateTableEntryColumn[]
  }
  if (entry.type === TemplateEntryType.STATEMENT) {
    return entry.values.length === 0 ? [''] : (entry.values as EntryValues)
  }

  if (
    (entry as TemplateEntry).defaultValues &&
    ((entry as TemplateEntry).defaultValues?.length || 0) > 0
  ) {
    return (entry as TemplateEntry).defaultValues as string[]
  }

  if (TemplateTextEntryTypes.includes(entry.type)) {
    return ['']
  }

  if (entry.type === TemplateEntryType.BOOLEAN) {
    return ['false']
  }

  if (entry.type === TemplateEntryType.CONDITIONAL) {
    return ['true']
  }

  if (entry.type === TemplateEntryType.REFERENCE) {
    return entry.values as EntryValues
  }

  return []
}

/**
 * Generate usable entry from template entry
 * @param entry Template entry
 * @returns Entry
 */
export const generateEntriesFromTemplateEntry = (entry: TemplateEntry): Entry => {
  return {
    id: entry.id,
    blockId: entry.blockId,
    name: entry.name,
    mandatory: entry.mandatory,
    type: entry.type,
    options: entry.type !== TemplateEntryType.TABLE ? (entry.values as string[]) : undefined,
    enabled: true,
    templateId: entry.templateId,
    values: generateEntryValuesFromTemplateEntry(entry),
    documentSettings: entry.documentSettings,
  }
}

export const generateEntriesFromTemplate = (template: Template) =>
  template.entries.map((entry): Entry => generateEntriesFromTemplateEntry(entry))

export const EntriesSchema = Yup.array().of(
  Yup.object().shape({
    value: Yup.mixed().test(`size ${MAX_FILE_SIZE.MB}MB`, function () {
      // If entry is disabled, skip validation
      if (this.parent.enabled === false) return true

      // If entry is inside conditional block disabled, skip validation
      if (this.parent.blockId) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const blockParent = (this as any)?.from[1]?.value?.entries?.find(
          (entry: { id: string }) => entry.id === this.parent.blockId
        )

        // If block is disabled, skip validation
        if (blockParent && blockParent.enabled === false) return true

        // If block is conditional check if is in ifelse disabled state
        if (blockParent && blockParent.type === TemplateEntryType.CONDITIONAL) {
          const ifEntries = blockParent.options[0]
          const elseEntries = blockParent.options[1]
          const conditionalValue = blockParent.value

          if (conditionalValue && ifEntries && !ifEntries.includes(this.parent.id)) {
            return true
          }
          if (!conditionalValue && elseEntries && !elseEntries.includes(this.parent.id)) {
            return true
          }
        }
      }

      switch (this.parent.type) {
        case TemplateEntryType.FILE:
          return (
            // Is mandatory and not has value
            !(this.parent.mandatory && !this.parent.value) ||
            // Has file and file size is more than max file size limit
            (this.parent.file && this.parent.file.size > MAX_FILE_SIZE.BYTES)
          )
        case TemplateEntryType.SHORT_TEXT:
        case TemplateEntryType.LONG_TEXT:
        case TemplateEntryType.SINGLE_CHOICE:
        case TemplateEntryType.DATE:
        case TemplateEntryType.TIME:
        case TemplateEntryType.NUMBER:
        case TemplateEntryType.DOCUMENT:
          return (
            (this.parent.mandatory && this.parent.value && this.parent.value[0]) ||
            !this.parent.mandatory ||
            this.createError({
              message: this.parent.id || this.parent.name,
            })
          )
        case TemplateEntryType.MULTIPLE_CHOICE:
        case TemplateEntryType.SIGNATURE:
          return (
            (this.parent.mandatory && this.parent.values && this.parent.values[0]) ||
            !this.parent.mandatory ||
            this.createError({
              message: this.parent.id || this.parent.name,
            })
          )
        case TemplateEntryType.REFERENCE:
          return (
            (this.parent.mandatory &&
              this.parent.values[0] &&
              this.parent.values[0].id &&
              this.parent.values[0].name) ||
            !this.parent.mandatory ||
            this.createError({
              message: this.parent.id || this.parent.name,
            })
          )
        case TemplateEntryType.LINK: {
          return (
            (this.parent.mandatory && this.parent.value && this.parent.value[0]) ||
            (!this.parent.value && !this.parent.mandatory) ||
            (this.parent.value && this.parent.value !== '' && isValidHttpUrl(this.parent.value)) ||
            this.createError({
              message: this.parent.id || this.parent.name,
            })
          )
        }

        case TemplateEntryType.TABLE: {
          return (
            (this.parent.values as DirtyTableEntryColumn[]).every(column => {
              return column.rows.some(row => {
                return (
                  row.type !== TemplateEntryType.LINK ||
                  !row.values[0] ||
                  isValidHttpUrl(row.values[0] as string)
                )
              })
            }) ||
            this.createError({
              message: this.parent.id || this.parent.name,
            })
          )
        }
        default:
          return true
      }
    }),
  })
)

export const parseEntriesFromDirtyEntries = (entries: EntryCreateInput[]): Entry[] => {
  return entries.map((dirtyEntry): Entry => {
    const entry = {
      ...dirtyEntry,
      enabled: dirtyEntry.enabled === undefined ? true : dirtyEntry.enabled,
    }
    switch (entry.type) {
      case TemplateEntryType.BOOLEAN:
        return {
          ...entry,
          values: [String(entry.value)],
        }
      case TemplateEntryType.SHORT_TEXT:
      case TemplateEntryType.LONG_TEXT:
      case TemplateEntryType.SINGLE_CHOICE:
      case TemplateEntryType.DATE:
      case TemplateEntryType.TIME:
      case TemplateEntryType.STATEMENT:
      case TemplateEntryType.NUMBER:
      case TemplateEntryType.LINK:
      case TemplateEntryType.DOCUMENT:
        return {
          ...entry,
          values: [entry.value],
        }
      case TemplateEntryType.REFERENCE:
        return entry
      case TemplateEntryType.MULTIPLE_CHOICE:
        return entry
      case TemplateEntryType.TABLE:
        return {
          ...entry,
          values: entry.values.map(column => ({
            title: column.title,
            lock: column.lock,
            rows: column.rows.map(row => ({
              type: row.type,
              values: row.values,
              options: row.options,
            })),
          })),
        }
      case TemplateEntryType.FILE:
        return {
          ...entry,
          values: entry.value ? [entry.value] : [],
          ...(entry.value && entry.mimeType
            ? {
                mimeType: entry.mimeType,
              }
            : {}),
        }
      case TemplateEntryType.CONDITIONAL:
        return {
          ...entry,
          values: [String(entry.value)],
        }
      case TemplateEntryType.ISSUE_REFERENCE:
        return {
          ...entry,
          values: entry.value ? [entry.value] : [],
        }
      default:
        return entry
    }
  })
}

export const getDefaultEntryValue = (
  type: TemplateEntryType,
  defaultValue: EntryValues = [],
  formDefaultValues?: TemplateEntryValues
) => {
  switch (type) {
    case TemplateEntryType.SHORT_TEXT:
    case TemplateEntryType.LONG_TEXT:
    case TemplateEntryType.SINGLE_CHOICE:
    case TemplateEntryType.DATE:
    case TemplateEntryType.TIME:
    case TemplateEntryType.LINK:
    case TemplateEntryType.NUMBER:
    case TemplateEntryType.STATEMENT:
    case TemplateEntryType.DOCUMENT:
      return { value: defaultValue[0] || '', values: [] }
    case TemplateEntryType.FILE:
      return { value: defaultValue[0] }
    case TemplateEntryType.BOOLEAN:
      return { value: defaultValue[0] ? defaultValue[0] === 'true' : false }
    case TemplateEntryType.CONDITIONAL: {
      if (defaultValue[0]) {
        return { value: defaultValue[0] === 'true' || (defaultValue[0] as unknown) === true }
      }

      if (formDefaultValues && formDefaultValues[0]) {
        return { value: formDefaultValues[0] === 'true' }
      }

      return { value: true }
    }
    case TemplateEntryType.MULTIPLE_CHOICE:
    case TemplateEntryType.TABLE:
    case TemplateEntryType.SIGNATURE:
      return { values: defaultValue }
    case TemplateEntryType.ISSUE_REFERENCE:
      return { value: defaultValue[0], values: [] }
  }
}

const getDefaultEntryValueForTableField = (
  type: TemplateEntryType,
  defaultValue: TemplateTableEntryRowValues = []
) => {
  switch (type) {
    case TemplateEntryType.SHORT_TEXT:
    case TemplateEntryType.LONG_TEXT:
    case TemplateEntryType.DATE:
    case TemplateEntryType.TIME:
    case TemplateEntryType.LINK:
    case TemplateEntryType.NUMBER:
    case TemplateEntryType.SECTION:
      return defaultValue[0] || ''
    case TemplateEntryType.BOOLEAN:
      return defaultValue[0] || 'false'
    default:
      return defaultValue[0]
  }
}

export const getDefaultEntryValuesForTable = (
  columnsFromTemplate: TemplateTableEntryColumn[],
  columnsFromTask?: TableEntryColumn[]
) => {
  const columns = columnsFromTask || columnsFromTemplate
  const values = columns.map((column, columnIndex) => {
    const defaultColumn: TableEntryColumn = {
      title: column.title,
      lock: column.lock,
      rows: [],
    }
    if (!column.rows) return defaultColumn
    defaultColumn.rows = column.rows.map((row, rowIndex) => {
      const defaultRow: TableEntryRow = {
        ...row,
      }
      if (
        defaultRow.type === TemplateEntryType.MULTIPLE_CHOICE ||
        defaultRow.type === TemplateEntryType.SINGLE_CHOICE
      ) {
        if (!columnsFromTask) {
          defaultRow.options = defaultRow.options || (row.values as string[])
          defaultRow.values = row.type === TemplateEntryType.SINGLE_CHOICE ? [''] : []
        } else {
          defaultRow.options =
            defaultRow.options ||
            (columnsFromTemplate[columnIndex].rows[rowIndex].values as string[])
        }
      } else if (defaultRow.type === TemplateEntryType.FILE) {
        const rowDefaultValue = getDefaultEntryValueForTableField(row.type, row.values as string[])
        if (rowDefaultValue) {
          defaultRow.values = [rowDefaultValue] as TemplateTableEntryRowValues
        }
      } else {
        const rowDefaultValue = getDefaultEntryValueForTableField(row.type, row.values)
        if (rowDefaultValue !== undefined) {
          defaultRow.values = [rowDefaultValue] as TemplateTableEntryRowValues
        } else {
          defaultRow.values = []
        }
      }

      return defaultRow
    })
    return defaultColumn
  })

  return { values }
}

export const getDefaultEntry = (entry: Entry, templateEntry?: TemplateEntry): EntryCreateInput => {
  const defaultValues =
    entry.type === TemplateEntryType.TABLE && templateEntry
      ? getDefaultEntryValuesForTable(
          templateEntry.values as TemplateTableEntryColumn[],
          entry.values as TableEntryColumn[]
        )
      : getDefaultEntryValue(entry.type, entry.values || templateEntry?.defaultValues)
  const taskEntry = {
    ...entry,
    ...defaultValues,
    enabled: entry.enabled === false ? entry.enabled : true,
  }

  if (
    [
      TemplateEntryType.MULTIPLE_CHOICE,
      TemplateEntryType.SINGLE_CHOICE,
      TemplateEntryType.CONDITIONAL,
    ].includes(entry.type)
  ) {
    taskEntry.options = entry.options || (templateEntry?.values as string[]) || []
  }

  if (entry.type === TemplateEntryType.REFERENCE && entry.values.length === 0) {
    taskEntry.values = (templateEntry?.values || entry.options) as [TemplateEntryReferenceValue]
  }

  return taskEntry as EntryCreateInput
}

export const getDefaultEntryFromTemplate = (entry: TemplateEntry) => {
  let defaultValues = entry.values as string[] | [TemplateEntryReferenceValue]

  if (entry.defaultValues && !!entry.defaultValues.length) {
    defaultValues = entry.defaultValues as string[] | [TemplateEntryReferenceValue]
  } else if (
    [TemplateEntryType.MULTIPLE_CHOICE, TemplateEntryType.SINGLE_CHOICE].includes(entry.type)
  ) {
    defaultValues = []
  }

  const defaultEntryValues =
    entry.type === TemplateEntryType.TABLE
      ? getDefaultEntryValuesForTable(entry.values as TemplateTableEntryColumn[])
      : getDefaultEntryValue(entry.type, defaultValues)

  const taskEntry = {
    ...entry,
    ...defaultEntryValues,
    enabled: true,
    options: [] as string[],
  }

  if (
    [
      TemplateEntryType.MULTIPLE_CHOICE,
      TemplateEntryType.SINGLE_CHOICE,
      TemplateEntryType.CONDITIONAL,
    ].includes(entry.type)
  ) {
    taskEntry.options = (entry.values as string[]) || taskEntry.options
  }

  return taskEntry
}

export const getEntryType = (
  entry: EditableEntry
): TemplateEntryType | TemplateEntryReferenceType => {
  if (entry.type === TemplateEntryType.REFERENCE) {
    const value = entry.values[0] as TemplateEntryReferenceValue
    return value.type
  } else {
    return entry.type
  }
}
