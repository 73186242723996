import { PDF_MANUAL_EN_URL, PDF_MANUAL_ES_URL } from '@/app/constants'
import { Help } from '@/components/Help'
import { useAnalytics } from '@/features/analytics'
import { Button, ItemCardGroup, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'
import { Facility } from '../interfaces'
import i18n from '@/i18n'

export type FacilitiesListItemClickResult = Row<Facility>

export interface FacilityListProps {
  isLoading?: boolean
  /**
   * Must be memoized to avoid extra renders
   */
  data: Facility[]

  /**
   * Must be memoized to avoid extra renders
   */
  onItemDelete: (itemId: string) => void

  /**
   * Must be memoized to avoid extra renders
   */
  onItemEdit: (itemId: string) => void
  /**
   * Pagination
   */
  onPageChange: (page: { pageIndex: number; pageSize: number }) => void
  totalCount: number
}

export const FacilitiesList = memo(
  ({ data, isLoading, onItemEdit, onItemDelete, onPageChange, totalCount }: FacilityListProps) => {
    const { t } = useTranslation('nsFacilitiesListPage')
    const analytics = useAnalytics()

    const language = i18n.language

    const userManualUrl = useMemo(() => {
      if (language === 'es') {
        return PDF_MANUAL_ES_URL
      }
      return PDF_MANUAL_EN_URL
    }, [language])

    return (
      <>
        {data.length === 0 ? (
          <div className='w-full'>
            <div className='text-center p-8 space-y-6'>
              <p className='text-4xl leading-10 font-extrabold text-center whitespace-pre-wrap'>
                {t('table.noData')}
              </p>
              <Button
                color='secondary'
                size={SIZE.EXTRA_LARGE}
                className='m-auto'
                onClick={() => {
                  analytics.track('ACTION_CLICK_HELP', {
                    Source: 'Facilities section',
                  })
                  window.open(userManualUrl, '_blank')
                }}
              >
                {t('table.needHelp')}
              </Button>
            </div>
          </div>
        ) : (
          <ItemCardGroup
            isLoading={isLoading}
            manualPagination
            initialPageSize={9}
            onPageChange={onPageChange}
            totalCount={totalCount}
            items={data.map(({ _id, name, address }) => ({
              id: _id,
              title: <div className='flex'>{name} </div>,
              description: address,
            }))}
            actions={() => [
              {
                icon: PencilAltIcon,
                onItemClick: onItemEdit,
                text: t('table.edit'),
              },
              {
                icon: TrashIcon,
                onItemClick: onItemDelete,
                text: t('table.delete'),
              },
            ]}
            previousText={t('table.pagination.previous')}
            nextText={t('table.pagination.next')}
            showText={t('table.pagination.show')}
            toText={t('table.pagination.to')}
            ofText={t('table.pagination.of')}
            rowsText={t('table.pagination.rows')}
          />
        )}
        <Help analyticsProperties={{ Source: 'Stakeholders section' }} />
      </>
    )
  }
)
