import { api } from '@/app/api'
import config from '@/config'
import { FileUploadResponse } from './interfaces'

export const uploadFile = async (
  file: File | Blob,
  { withPublicUrl }: { withPublicUrl?: boolean } = {}
): Promise<FileUploadResponse> => {
  const formData = new FormData()

  formData.append('file', file, file instanceof File ? file.name : '')
  if (withPublicUrl) {
    formData.append('public', 'true')
  }
  const { data } = await api.post<Omit<FileUploadResponse, 'fileName'>>(
    config.UPLOADFILE_CF_URL,
    formData
  )
  return {
    ...data,
    fileName: file instanceof File ? file.name : '',
  }
}
