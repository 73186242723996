import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import {
  EditableEntry,
  EditableIssueTemplateInput,
  TemplateEntryRedesignError,
} from '../../interfaces'
import { BlockFields } from '../BlockFields'

export interface IssueReferenceEntryFieldProps {
  issueTemplate?: EditableIssueTemplateInput
  errors: {
    [id: string]: TemplateEntryRedesignError | undefined
  }
  onAddEntry: (entry: EditableEntry) => void
  onChangeEntry: (entry: EditableEntry) => void
  onRemoveEntry: (entry: EditableEntry) => void
  onSort: (entries: EditableEntry[]) => void

  nested?: boolean
}

export const IssueReferenceEntryField = ({
  issueTemplate,
  errors,
  onAddEntry,
  onChangeEntry,
  onRemoveEntry,
  onSort,
  nested,
}: IssueReferenceEntryFieldProps) => {
  const { t } = useTranslation('nsTemplate')

  if (!issueTemplate) return null

  return (
    <div
      className={classNames(' py-6 rounded-lg flex flex-col', {
        'bg-gray-100': !nested,
        'bg-white': nested,
      })}
    >
      <div className='flex flex-col divide-y'>
        <div
          className={classNames('flex flex-col', {
            'px-10': !nested,
            'px-4': nested,
          })}
        >
          <h4 className='text-base leading-6 font-bold'>{t('entries.issueReference.title')}</h4>

          <p className='mt-2 text-sm leading-5 font-normal text-gray-500'>
            {t('entries.issueReference.description')}
          </p>
        </div>

        <div className='mt-4 px-10 py-4'>
          <BlockFields
            title={t('entries.issueReference.optionTitle')}
            entries={issueTemplate.entries}
            errors={errors}
            onAddField={newEntry => {
              onAddEntry(newEntry)
            }}
            onRemoveField={entry => {
              onRemoveEntry(entry)
            }}
            onChangeField={entry => {
              onChangeEntry(entry)
            }}
            onSortFields={(entries: EditableEntry[]) => {
              onSort(entries)
            }}
            collapseDisabled
            mandatoryEnabled
          />
        </div>
      </div>
    </div>
  )
}
