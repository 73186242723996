import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import * as NotifyService from '@/services/notify.service'
import {
  DirtyTemplateDataInput,
  EditableIssueTemplateInput,
  FormTemplateTypes,
  ITemplateDefaults,
  IssueTemplatesChanges,
  MetadataTemplateTypes,
  TaskTemplateTypes,
  Template,
  TemplateAssociationType,
  TemplateComplianceEntityType,
  TemplateDataInput,
  TemplateEntryReferenceType,
  TemplateEntryType,
  TemplateFilters,
  TemplateType,
} from './interfaces'
import {
  createWithIssueTemplates,
  getAll,
  getAllByAssociation,
  getAllTags,
  getByIds,
  getByTypeAndId,
  getPaginated,
  remove,
  updateWithIssueTemplates,
} from './service'
import { AxiosError } from 'axios'
import { customErrorToTrack, useAnalytics } from '@/features/analytics'
import { capitalize } from '@/utils/capitalize'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import {
  SelectorGroup,
  SelectorOption,
  createSelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { patchDeprecationTypes, transformTemplateEntryToDirty } from './utils'
import { useUserById, useUsersWithCompany } from '../users'
import { nanoid } from 'nanoid'
import { DEFAULT_PAGE_SIZE, Paginated, PaginationParams } from '@/hooks/use-pagination'
import { mergeInfiniteQueryOptions, mergeQueryOptions } from '@/utils/merge-query-options'
import { useCompanyUser } from '../auth'

export const QUERY_TEMPLATES_KEY = 'templates'

export const useTemplateTags = (
  options?:
    | Omit<UseQueryOptions<string[], unknown, string[], string[]>, 'queryKey' | 'queryFn'>
    | undefined
) => {
  const { data, ...rest } = useQuery([QUERY_TEMPLATES_KEY, 'tags'], () => getAllTags(), options)

  return {
    ...rest,
    templateTags: useMemo(() => data || [], [data]),
  }
}

const getTrackEventSuffix = (type: TemplateType | 'metadata' | 'form'): string => {
  switch (type) {
    case TemplateType.METADATA:
    case 'metadata':
      return 'DATA_TEMPLATE'

    case TemplateType.INCIDENCE:
    case TemplateType.REGISTRY:
    case TemplateType.COMPLIANCE:
    case 'form':
      return 'FORM'
    case TemplateType.ISSUE:
      return 'ISSUE'
  }
}

const getTrackPayloadByType = (
  type: TemplateType | 'metadata' | 'form',
  name: string,
  { assignedTo, issueResponder, taskValidator, complianceEntityType }: ITemplateDefaults = {}
): Record<string, string> => {
  switch (type) {
    case TemplateType.METADATA:
    case 'metadata':
      return {
        'Data template': name,
      }

    case TemplateType.INCIDENCE:
    case TemplateType.REGISTRY:
    case 'form':
      return {
        'Form type': capitalize(type),
        'Form name': name,
        'Assigned to': assignedTo || 'N/A',
        'Task validator': taskValidator || 'N/A',
        'Incident receiver': issueResponder || 'N/A',
      }
    case TemplateType.COMPLIANCE:
      switch (complianceEntityType) {
        case TemplateComplianceEntityType.STAKEHOLDER_PRODUCT:
          return {
            'Form type': 'Raw material compliance',
            'Form name': name,
          }
        default:
          return {
            'Form type': 'Stakeholder compliance',
            'Form name': name,
          }
      }

    // Issue templates are created with forms
    case TemplateType.ISSUE:
      return {}
  }
}

const getNotifyPrefixByType = (type: TemplateType | 'metadata' | 'form'): string => {
  switch (type) {
    case TemplateType.METADATA:
    case 'metadata':
      return 'metadataTemplate'
    case TemplateType.INCIDENCE:
    case TemplateType.REGISTRY:
    case 'form':
      return 'form'
    case TemplateType.COMPLIANCE:
      return 'compliance-form'
    case TemplateType.ISSUE:
      return 'issue'
  }
}

export const useTemplates = (
  filters?: TemplateFilters,
  options?: Omit<UseQueryOptions<Template[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery([QUERY_TEMPLATES_KEY, filters], () => getAll(filters), options)
  const templates = useMemo(
    () =>
      data?.map(template => ({
        ...template,
        entries: template.entries.map(entry => ({
          ...entry,
          type: patchDeprecationTypes(entry.type),
        })),
      })) || [],
    [data]
  )

  return { ...rest, templates }
}

export const useTemplatesForTasks = (
  filters?: TemplateFilters,
  options?: Omit<UseQueryOptions<Template[], AxiosError>, 'queryKey' | 'queryFn'>
) => useTemplates({ ...filters, types: TaskTemplateTypes }, options)

export const usePaginatedTemplates = (
  filters: TemplateFilters,
  pagination: PaginationParams,
  overrideOptions?: Omit<UseQueryOptions<Paginated<Template>, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const options = mergeQueryOptions(overrideOptions, {
    keepPreviousData: true,
  })
  const { data, ...rest } = useQuery(
    [QUERY_TEMPLATES_KEY, { filters, pagination }],
    () => getPaginated(filters, pagination),
    options
  )

  const templates = useMemo(() => data?.items || [], [data?.items])
  const totalCount = data?.totalCount || 0

  return {
    ...rest,
    templates,
    totalCount,
  }
}

export const useInfiniteTemplates = (
  filter: TemplateFilters,
  overrideOptions?: Omit<
    UseInfiniteQueryOptions<Paginated<Template>, AxiosError>,
    'queryKey' | 'queryFn'
  >
) => {
  const options = mergeInfiniteQueryOptions(
    {
      getNextPageParam: ({ pageInfo }) => pageInfo?.nextPage || undefined,
    },
    overrideOptions
  )

  const { data, ...rest } = useInfiniteQuery(
    [QUERY_TEMPLATES_KEY, filter, { isInfiniteQuery: true }],
    ({ pageParam }) => getPaginated(filter, pageParam || { limit: DEFAULT_PAGE_SIZE, offset: 0 }),
    options
  )
  const templates = useMemo(
    () => data?.pages.flatMap(page => page.items).flat() || [],
    [data?.pages]
  )

  return {
    ...rest,
    templates,
  }
}

export const useTemplatesByAssociations = (
  associationIds: string[],
  associationType: TemplateAssociationType,
  type: TemplateType.METADATA | 'form',
  options?:
    | Omit<
        UseQueryOptions<
          Template[][],
          unknown,
          Template[][],
          (string | { type: TemplateType.METADATA | 'form'; associationIds: string[] })[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined
) => {
  const { data, isLoading } = useQuery(
    [QUERY_TEMPLATES_KEY, { type, associationIds }],
    () =>
      Promise.all(
        associationIds.map(associationId =>
          getAllByAssociation(associationId, associationType, type)
        )
      ),
    options
  )

  const templates = useMemo(() => {
    return data
      ? data
          .flatMap(data => data)
          .filter((template): template is Template => !!template)
          .filter(
            (item, index, self) => index === self.findIndex(selfItem => selfItem._id === item._id)
          )
      : []
  }, [data])

  return {
    isLoading,
    templates,
  }
}

export const useTemplatesByAssociation = (
  associationId: string | undefined,
  associationType: TemplateAssociationType,
  type: TemplateType.METADATA | 'form'
) => {
  const { data, ...rest } = useQuery([QUERY_TEMPLATES_KEY, { type, associationId }], () => {
    if (!associationId) return []
    return getAllByAssociation(associationId, associationType, type)
  })
  const templates = useMemo(
    () =>
      data?.map(template => ({
        ...template,
        entries: template.entries.map(entry => ({
          ...entry,
          type: patchDeprecationTypes(entry.type),
        })),
      })) || [],
    [data]
  )

  return { ...rest, templates }
}

export const useTemplate = (
  templateId: string,
  type: TemplateType | 'form',
  overrideOptions?: Omit<UseQueryOptions<Template | undefined, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const options = mergeQueryOptions(overrideOptions, {
    enabled: overrideOptions?.enabled ?? !!templateId,
  })
  const { data, ...rest } = useQuery(
    [QUERY_TEMPLATES_KEY, { type }, templateId],
    () => getByTypeAndId(type, templateId),
    options
  )

  return { ...rest, template: data }
}

export const useFetchTemplateByTypeAndId = () => {
  const queryClient = useQueryClient()

  const fetchTemplateByTypeAndId = useCallback(
    (type: 'form' | TemplateType, templateId: string) => {
      return queryClient.fetchQuery([QUERY_TEMPLATES_KEY, { type }, templateId], () =>
        getByTypeAndId(type, templateId)
      )
    },
    [queryClient]
  )

  return {
    fetchTemplateByTypeAndId,
  }
}
/**
 *
 * TODO: support other types
 */
export const useTemplateByIds = (
  templateIds: string[],
  type: TemplateType.ISSUE | 'form',
  overrideOptions?: Omit<UseQueryOptions<Template[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const options = mergeQueryOptions(overrideOptions, {
    onSuccess(data: Template[]) {
      overrideOptions?.onSuccess?.(data)
      data.forEach(item => {
        queryClient.setQueryData([QUERY_TEMPLATES_KEY, { type }, item._id], item)
      })
    },
  })
  const queryClient = useQueryClient()
  const { data, ...rest } = useQuery(
    [QUERY_TEMPLATES_KEY, { type, templateIds }],
    () => getByIds(type, templateIds),
    options
  )

  const templates = useMemo(() => data || [], [data])

  return { ...rest, templates }
}

export const useCreateTemplate = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const { users } = useUsersWithCompany()

  const { mutate, ...rest } = useMutation(createWithIssueTemplates, {
    async onSuccess(data, { template }) {
      analytics.track(
        `CUSTOMER_CREATE_${getTrackEventSuffix(template.type)}`,
        getTrackPayloadByType(template.type, template.name, {
          assignedTo: template.defaults?.assignedTo
            ? users.find(({ _id }) => _id === template.defaults?.assignedTo)?.auth.email
            : undefined,

          issueResponder: template.defaults?.issueResponder
            ? users.find(({ _id }) => _id === template.defaults?.issueResponder)?.auth.email
            : undefined,

          taskValidator: template.defaults?.taskValidator
            ? users.find(user => user._id === template.defaults?.taskValidator)?.auth.email
            : 'N/A',
        })
      )
      NotifyService.success({
        title: t(`${getNotifyPrefixByType(template.type)}.create.success.title`),
        description: t(`${getNotifyPrefixByType(template.type)}.create.success.description`),
        actionText: t(`${getNotifyPrefixByType(template.type)}.create.success.action`),
        onActionClick: () => {
          switch (template.type) {
            case TemplateType.INCIDENCE:
            case TemplateType.REGISTRY:
              history.push(
                `/tasks/create?${new URLSearchParams({
                  form: data,
                })}`
              )
              break
            case TemplateType.METADATA:
              history.push('/metadata-templates/create')
              break
            case TemplateType.COMPLIANCE:
              history.push('/stakeholders')
              break
          }
        },
      })
      queryClient.invalidateQueries(QUERY_TEMPLATES_KEY)
    },
    onError(error: AxiosError, { template }) {
      analytics.track(
        `CUSTOMER_CREATE_${getTrackEventSuffix(template.type)}_ERROR`,
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return { ...rest, createTemplate: mutate }
}

export const useUpdateTemplate = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const history = useHistory()
  const analytics = useAnalytics()
  const { users } = useUsersWithCompany()

  const { mutate, ...rest } = useMutation(
    ({
      id,
      ...changes
    }: {
      id: string
      template: TemplateDataInput
      issueTemplatesChanges?: IssueTemplatesChanges
    }) => updateWithIssueTemplates(id, changes),
    {
      onSuccess: (data, { id, template }) => {
        analytics.track(
          `CUSTOMER_EDIT_${getTrackEventSuffix(template.type)}`,
          getTrackPayloadByType(template.type, template.name, {
            assignedTo: template.defaults?.assignedTo
              ? users.find(({ _id }) => _id === template.defaults?.assignedTo)?.auth.email
              : undefined,
            issueResponder: template.defaults?.issueResponder
              ? users.find(({ _id }) => _id === template.defaults?.issueResponder)?.auth.email
              : undefined,
            taskValidator: template.defaults?.taskValidator
              ? users.find(user => user._id === template.defaults?.taskValidator)?.auth.email
              : 'N/A',
          })
        )
        NotifyService.success({
          title: t(`${getNotifyPrefixByType(template.type)}.update.success.title`),
          description: t(`${getNotifyPrefixByType(template.type)}.update.success.description`),
          actionText: t(`${getNotifyPrefixByType(template.type)}.update.success.action`),
          onActionClick: () => {
            switch (template.type) {
              case TemplateType.INCIDENCE:
              case TemplateType.REGISTRY:
                history.push(
                  `/tasks/create?${new URLSearchParams({
                    form: id,
                  })}`
                )
                break
              case TemplateType.METADATA:
                history.push('/metadata-templates/create')
                break
              case TemplateType.COMPLIANCE:
                history.push('/stakeholders')
                break
            }
          },
        })
        queryClient.invalidateQueries(QUERY_TEMPLATES_KEY)
      },
      onError(error: AxiosError, { template }) {
        analytics.track(`CUSTOMER_EDIT_${getTrackEventSuffix(template.type)}_ERROR`, {
          ...getTrackPayloadByType(template.type, template.name),
          ...customErrorToTrack(error.response?.data, error.response?.status),
        })
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return { ...rest, updateTemplate: mutate }
}

export const useDeleteTemplate = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const history = useHistory()
  const analytics = useAnalytics()

  const { mutate, ...rest } = useMutation(
    (template: Template) => remove(template._id, template.type),
    {
      onSuccess: async (data, template) => {
        queryClient.invalidateQueries(QUERY_TEMPLATES_KEY)

        NotifyService.success({
          title: t(`${getNotifyPrefixByType(template.type)}.delete.success.title`),
          description: t(`${getNotifyPrefixByType(template.type)}.delete.success.description`),
          actionText: t(`${getNotifyPrefixByType(template.type)}.delete.success.action`),
          onActionClick: () => {
            if (FormTemplateTypes.includes(template.type)) {
              return history.push('/forms/create')
            }
            if (MetadataTemplateTypes.includes(template.type)) {
              history.push('/metadata-templates/create')
            }
          },
        })

        analytics.track(
          `CUSTOMER_DELETE_${getTrackEventSuffix(template.type)}`,
          getTrackPayloadByType(template.type, template.name)
        )
      },
      async onError(error: AxiosError, template) {
        analytics.track(
          `CUSTOMER_DELETE_${getTrackEventSuffix(template.type)}_ERROR`,
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return { ...rest, deleteTemplate: mutate }
}

interface UseTemplateSelectorOptionsConfig {
  isTable?: boolean
  referenceEnabled?: boolean
  statementEnabled?: boolean
  tableEnabled?: boolean
  fileEnabled?: boolean
  documentEnabled?: boolean
  conditionalEnabled?: boolean
  sectionEnabled?: boolean
  issueReferenceEnabled?: boolean
  signatureEnabled?: boolean
}
export const useTemplateEntriesSelectorOptions = ({
  isTable,
  referenceEnabled,
  statementEnabled,
  tableEnabled,
  fileEnabled,
  documentEnabled,
  conditionalEnabled,
  sectionEnabled,
  issueReferenceEnabled,
  signatureEnabled,
}: UseTemplateSelectorOptionsConfig): SelectorGroup[] => {
  const { t } = useTranslation('nsTemplate')
  const entryTypeOptions: SelectorGroup[] = useMemo(
    () =>
      [
        {
          label: t('entries.groups.formStructure'),
          options: [
            ...(sectionEnabled && !isTable
              ? [{ label: t('entries.types.section'), value: TemplateEntryType.SECTION }]
              : []),
            ...(statementEnabled && !isTable
              ? [{ label: t('entries.types.statement'), value: TemplateEntryType.STATEMENT }]
              : []),
            ...(conditionalEnabled && !isTable
              ? [{ label: t('entries.types.conditional'), value: TemplateEntryType.CONDITIONAL }]
              : []),
            ...(tableEnabled
              ? [{ label: t('entries.types.table'), value: TemplateEntryType.TABLE }]
              : []),
          ],
        },
        {
          label: t('entries.groups.text'),
          options: [
            { label: t('entries.types.shortText'), value: TemplateEntryType.SHORT_TEXT },
            { label: t('entries.types.longText'), value: TemplateEntryType.LONG_TEXT },
            { label: t('entries.types.number'), value: TemplateEntryType.NUMBER },
          ],
        },
        {
          label: t('entries.groups.choices'),
          options: [
            { label: t('entries.types.multipleChoice'), value: TemplateEntryType.MULTIPLE_CHOICE },
            { label: t('entries.types.singleChoice'), value: TemplateEntryType.SINGLE_CHOICE },
            { label: t('entries.types.boolean'), value: TemplateEntryType.BOOLEAN },
          ],
        },
        {
          label: t('entries.groups.date'),
          options: [
            { label: t('entries.types.date'), value: TemplateEntryType.DATE },
            { label: t('entries.types.time'), value: TemplateEntryType.TIME },
          ],
        },
        {
          label: t('entries.groups.documents'),
          options: [
            ...(fileEnabled
              ? [{ label: t('entries.types.file'), value: TemplateEntryType.FILE }]
              : []),
            ...(documentEnabled
              ? [{ label: t('entries.types.document'), value: TemplateEntryType.DOCUMENT }]
              : []),
            { label: t('entries.types.link'), value: TemplateEntryType.LINK },
          ],
        },
        {
          label: t('entries.groups.internalData'),
          options: [
            ...(referenceEnabled
              ? [
                  {
                    label: t('entries.types.stakeHolder'),
                    value: TemplateEntryReferenceType.STAKEHOLDER,
                  },
                  {
                    label: t('entries.types.product'),
                    value: TemplateEntryReferenceType.PRODUCT,
                  },
                  {
                    label: t('entries.types.tru'),
                    value: TemplateEntryReferenceType.TRU,
                  },
                  ...(signatureEnabled
                    ? [
                        {
                          label: t('entries.types.signature'),
                          value: TemplateEntryType.SIGNATURE,
                        },
                      ]
                    : []),
                ]
              : []),
            ...(issueReferenceEnabled && !isTable
              ? [
                  {
                    label: t('entries.types.issueReference'),
                    value: TemplateEntryType.ISSUE_REFERENCE,
                  },
                ]
              : []),
          ],
        },
      ].filter(group => group.options.length > 0),
    [
      t,
      fileEnabled,
      documentEnabled,
      statementEnabled,
      isTable,
      tableEnabled,
      referenceEnabled,
      conditionalEnabled,
      sectionEnabled,
      issueReferenceEnabled,
      signatureEnabled,
    ]
  )
  return entryTypeOptions
}

export const useInitialFormData = (
  formId?: string | null,
  {
    isComplianceReferrer,
    defaultCompliantEntityType,
  }: {
    isComplianceReferrer?: boolean
    defaultCompliantEntityType?: TemplateComplianceEntityType | null
  } = {}
): { isLoading: boolean; initialTemplate: DirtyTemplateDataInput } => {
  const { template, isLoading: isTemplateLoading } = useTemplate(formId || '', 'form', {
    enabled: !!formId,
  })

  const initialEntries = useMemo(
    () => template?.entries?.map(transformTemplateEntryToDirty) || [],
    [template?.entries]
  )

  const issueReferences = useMemo(
    () =>
      initialEntries.filter(
        entry => entry.type === TemplateEntryType.ISSUE_REFERENCE && entry.templateId
      ),
    [initialEntries]
  )

  const issueTemplateIds = useMemo(
    () => issueReferences.map(({ templateId }) => templateId) as string[],
    [issueReferences]
  )

  const { templates: issueTemplates, isLoading: isIssueTemplatesLoading } = useTemplateByIds(
    issueTemplateIds,
    TemplateType.ISSUE,
    {
      enabled: issueTemplateIds.length > 0,
    }
  )

  const initialIssueTemplates = useMemo(
    () =>
      issueTemplates?.reduce<EditableIssueTemplateInput[]>((accumulator, issueTemplate) => {
        const issueReference = issueReferences.find(
          reference => reference.templateId === issueTemplate._id
        )
        if (issueReference) {
          accumulator.push({
            entryId: issueReference?.id,
            id: issueTemplate._id,
            entries: issueTemplate.entries.map(transformTemplateEntryToDirty),
          })
        }

        return accumulator
      }, []) || [],
    [issueTemplates, issueReferences]
  )

  const initialNewEntryId = useMemo(() => nanoid(), [])

  const defaultType = isComplianceReferrer ? TemplateType.COMPLIANCE : TemplateType.REGISTRY

  // Defaults
  const companyUser = useCompanyUser()
  const { user: assignedToUser } = useUserById(template?.defaults?.assignedTo || '', {
    enabled: !!template?.defaults?.assignedTo && template.defaults.assignedTo !== companyUser._id,
  })
  const { user: issueResponderUser } = useUserById(template?.defaults?.issueResponder || '', {
    enabled:
      !!template?.defaults?.issueResponder && template.defaults.issueResponder !== companyUser._id,
  })
  const { user: taskValidatorUser } = useUserById(template?.defaults?.taskValidator || '', {
    enabled:
      !!template?.defaults?.taskValidator && template.defaults.taskValidator !== companyUser._id,
  })

  const tags = template?.tags
  const control = template?.control
  const draftDefaults = useMemo(() => {
    let assignedTo: SelectorOption | null = null
    let issueResponder: SelectorOption | null = null
    let taskValidator: SelectorOption | null = null
    let complianceEntityType: TemplateComplianceEntityType | null =
      defaultCompliantEntityType || null

    if (template?.defaults?.assignedTo) {
      if (template.defaults.assignedTo === companyUser._id) {
        assignedTo = createSelectorOption(companyUser.name, companyUser._id)
      } else if (assignedToUser) {
        assignedTo = createSelectorOption(assignedToUser.publicName, assignedToUser._id)
      }
    }

    if (template?.defaults?.issueResponder) {
      if (template.defaults.issueResponder === companyUser._id) {
        issueResponder = createSelectorOption(companyUser.name, companyUser._id)
      } else if (issueResponderUser) {
        issueResponder = issueResponderUser.toSelectorOption()
      }
    }

    if (template?.defaults?.taskValidator) {
      if (template.defaults.taskValidator === companyUser._id) {
        taskValidator = createSelectorOption(companyUser.name, companyUser._id)
      } else if (taskValidatorUser) {
        taskValidator = taskValidatorUser.toSelectorOption()
      }
    }

    if (template?.defaults?.complianceEntityType) {
      complianceEntityType = template.defaults.complianceEntityType
    } else {
      complianceEntityType = defaultCompliantEntityType || TemplateComplianceEntityType.STAKEHOLDER
    }

    return {
      assignedTo,
      issueResponder,
      taskValidator,
      dueDate: template?.defaults?.dueDate || null,
      complianceEntityType,
    }
  }, [
    template?.defaults?.assignedTo,
    template?.defaults?.issueResponder,
    template?.defaults?.taskValidator,
    template?.defaults?.dueDate,
    template?.defaults?.complianceEntityType,
    companyUser._id,
    companyUser.name,
    assignedToUser,
    issueResponderUser,
    taskValidatorUser,
    defaultCompliantEntityType,
  ])

  return {
    isLoading: isTemplateLoading || isIssueTemplatesLoading,
    initialTemplate: {
      type: template?.type || defaultType,
      name: template?.name || '',
      version: template?.version || 0,
      departments:
        template?.associations
          ?.filter(association => association.type === TemplateAssociationType.DEPARTMENT)
          .map(({ id }) => id) || [],
      entries: initialEntries,
      newEntry: {
        id: initialNewEntryId,
        name: '',
        type: TemplateEntryType.SHORT_TEXT,
        mandatory: false,
        values: [],
      },
      defaults: draftDefaults,
      tags: tags || [],
      control,
      reportUpload: template?.reportUpload ?? false,
      issueTemplates: initialIssueTemplates,
    },
  }
}
