import { ComponentProps } from 'react'

export const PendingToValidateTaskIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.777 3.14c.275-.729 1.016-1.25 1.887-1.25h1.333c.87 0 1.612.521 1.886 1.25h.78c1.105 0 2 .84 2 1.875v7.5c0 1.035-.895 1.875-2 1.875H4.998c-1.105 0-2-.84-2-1.875v-7.5c0-1.036.895-1.875 2-1.875h.78zm0 1.25h-.78c-.368 0-.667.28-.667.625v7.5c0 .345.299.625.667.625h6.667c.368 0 .666-.28.666-.625v-7.5c0-.345-.298-.625-.666-.625h-.78c-.275.728-1.016 1.25-1.887 1.25H7.664c-.871 0-1.612-.522-1.887-1.25zm1.887-1.25c-.369 0-.667.28-.667.625s.298.625.667.625h1.333c.368 0 .667-.28.667-.625s-.299-.625-.667-.625H7.664z'
        fill='#F97316'
      />
      <g clipPath='url(#clip0_522_3706)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.335 7.25a2 2 0 100 4 2 2 0 000-4zm-2.5 2a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zM8.335 8a.25.25 0 01.25.25v.896l.677.676a.25.25 0 01-.354.354l-.75-.75a.25.25 0 01-.073-.177v-1a.25.25 0 01.25-.25z'
          fill='#F97316'
          stroke='#F97316'
          strokeWidth={0.3}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_522_3706'>
          <path fill='#fff' transform='translate(5.335 6.25)' d='M0 0H6V6H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}
