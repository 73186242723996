import { PDF_MANUAL_EN_URL, PDF_MANUAL_ES_URL } from '@/app/constants'
import { Help } from '@/components/Help'
import { useAnalytics } from '@/features/analytics'
import { COMPLIANCE_ALMOST_EXPIRED_DAYS } from '@/features/compliances'
import { StakeHolderType } from '@/features/stakeHolders'
import i18n from '@/i18n'
import {
  Button,
  COLOR,
  ItemCardGroup,
  NEW_COLOR,
  SIZE,
} from '@blockchain-traceability-sl/tailwind-components'
import { CogIcon, ShieldCheckIcon, TrashIcon } from '@heroicons/react/outline'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'

export type StakeHoldersListItem = {
  id: string
  name: string
  email?: string
  phoneNumber?: string
  type: StakeHolderType
  reference: string
  complianceValidityPeriod?: number
}
export type StakeHoldersListItemClickResult = Row<StakeHoldersListItem>

export interface StakeHoldersListProps {
  isLoading?: boolean
  /**
   * Must be memoized to avoid extra renders
   */
  data: StakeHoldersListItem[]
  /**
   * Must be memoized to avoid extra renders
   */
  onItemManage: (itemId: string) => void

  /**
   * Must be memoized to avoid extra renders
   */
  onItemDelete: (itemId: string) => void

  /**
   * Pagination
   */
  onPageChange: (page: { pageIndex: number; pageSize: number }) => void
  totalCount: number
}

export const StakeHoldersList = memo(
  ({
    data,
    isLoading,
    onItemManage,
    onItemDelete,
    onPageChange,
    totalCount,
  }: StakeHoldersListProps) => {
    const { t } = useTranslation('nsStakeHoldersListPage')
    const analytics = useAnalytics()
    const language = i18n.language

    const userManualUrl = useMemo(() => {
      if (language === 'es') {
        return PDF_MANUAL_ES_URL
      }
      return PDF_MANUAL_EN_URL
    }, [language])
    return (
      <>
        {data.length === 0 ? (
          <div className='w-full'>
            <div className='text-center p-8 space-y-6'>
              <p className='text-4xl leading-10 font-extrabold text-center whitespace-pre-wrap'>
                {t('table.noData')}
              </p>
              <Button
                color='secondary'
                size={SIZE.EXTRA_LARGE}
                className='m-auto'
                onClick={() => {
                  analytics.track('ACTION_CLICK_HELP', {
                    Source: 'Stakeholders section',
                  })
                  window.open(userManualUrl, '_blank')
                }}
              >
                {t('table.needHelp')}
              </Button>
            </div>
          </div>
        ) : (
          <ItemCardGroup
            isLoading={isLoading}
            manualPagination
            initialPageSize={9}
            onPageChange={onPageChange}
            totalCount={totalCount}
            items={data.map(
              ({ id, name, email, phoneNumber, type, complianceValidityPeriod = 0 }) => ({
                id,
                title: (
                  <div className='flex'>
                    {name}{' '}
                    {complianceValidityPeriod > 0 && (
                      <ShieldCheckIcon
                        color={
                          complianceValidityPeriod > COMPLIANCE_ALMOST_EXPIRED_DAYS
                            ? COLOR.BLUE
                            : NEW_COLOR.ORANGE
                        }
                        className='w-5 h-5 ml-1'
                      />
                    )}
                  </div>
                ),
                description: email || (phoneNumber ? `+${phoneNumber}` : '-'),
                badgeText: t(`table.stakeHolderType.${type}`),
                badgeColor:
                  type === StakeHolderType.PROVIDER
                    ? COLOR.GREEN
                    : type === StakeHolderType.CLIENT
                    ? COLOR.YELLOW
                    : COLOR.BLUE,
                draft: type === StakeHolderType.CONTACT,
              })
            )}
            actions={item =>
              item.draft
                ? [
                    {
                      icon: TrashIcon,
                      onItemClick: onItemDelete,
                      text: t('table.delete'),
                    },
                  ]
                : [
                    {
                      icon: CogIcon,
                      onItemClick: onItemManage,
                      text: t('table.manage'),
                    },
                  ]
            }
            previousText={t('table.pagination.previous')}
            nextText={t('table.pagination.next')}
            showText={t('table.pagination.show')}
            toText={t('table.pagination.to')}
            ofText={t('table.pagination.of')}
            rowsText={t('table.pagination.rows')}
          />
        )}
        <Help analyticsProperties={{ Source: 'Stakeholders section' }} />
      </>
    )
  }
)
