import { IssueStatus } from '@/features/issues'
import { useIssueStatusGroups } from '@/pages/tasks/TasksList/hooks'
import { Badge, COLOR, SIZE } from '@blockchain-traceability-sl/tailwind-components'

export const TaskIssuesBadges = ({
  issues,
  size,
}: {
  issues: { status: IssueStatus }[]
  size?: SIZE.SMALL | SIZE.MEDIUM | SIZE.LARGE
}) => {
  const { issueStatusGroups } = useIssueStatusGroups()

  return issues.length > 0 ? (
    <div className='flex gap-1'>
      {issues.some(({ status }) => issueStatusGroups.NEW.includes(status)) && (
        <Badge color={COLOR.RED} variant='round' size={size}>
          {issues.filter(({ status }) => issueStatusGroups.NEW.includes(status)).length}
        </Badge>
      )}
      {issues.some(({ status }) => issueStatusGroups.IN_PROGRESS.includes(status)) && (
        <Badge color={COLOR.YELLOW} variant='round' size={size}>
          {issues.filter(({ status }) => issueStatusGroups.IN_PROGRESS.includes(status)).length}
        </Badge>
      )}
      {issues.some(({ status }) => issueStatusGroups.CLOSED.includes(status)) && (
        <Badge color={COLOR.GREEN} variant='round' size={size}>
          {issues.filter(({ status }) => issueStatusGroups.CLOSED.includes(status)).length}
        </Badge>
      )}
    </div>
  ) : null
}
