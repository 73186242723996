import { Help } from '@/components/Help'
import { StepOverview } from '@/components/StepOverview'
import { useUserPermissions } from '@/features/auth'
import { TemplateAssociationType, TemplateEntryType, useTemplate } from '@/features/templates'
import { UsersSelectField } from '@/features/users'
import {
  Button,
  ButtonWithIcon,
  Card,
  SelectorOption,
  Step,
  SwitchField,
  Tooltip,
} from '@blockchain-traceability-sl/tailwind-components'
import { ArrowSmUpIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { useFormikContext } from 'formik'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TaskCreation, TaskStatus } from '../../interfaces'
import { TrusSelectMultiField } from '@/features/trus'
import { ReceptionsSelectMultiField } from '@/features/receptions'

type FormValues = Pick<
  TaskCreation,
  'formId' | 'truIds' | 'receptionIds' | 'productId' | 'status' | 'entries'
> & {
  assignedTo?: SelectorOption | null
  issueResponder?: SelectorOption | null
  isActiveAssociateBatch: boolean
  isActiveAssociateReception: boolean
}

export const StepTaskCreationAssignments = () => {
  const { t } = useTranslation('nsTaskCreationPage')
  const userPermissions = useUserPermissions()

  const { handleBlur, setFieldValue, values, errors, touched, submitForm } =
    useFormikContext<FormValues>()

  const { template: templateSelected } = useTemplate(values.formId, 'form')

  let translationsContext: string | undefined

  if (!userPermissions.tasks.write) {
    if (templateSelected?.defaults?.taskValidator) {
      translationsContext = 'completeWithValidation'
    } else {
      translationsContext = 'complete'
    }
  }

  const hasIssueReferences = useMemo(
    () => values.entries.some(entry => entry.type === TemplateEntryType.ISSUE_REFERENCE),
    [values.entries]
  )

  return (
    <Step
      title={t('steps.step3.stepTitle')}
      subtitle={
        <div className='flex gap-3'>
          <span>{t('steps.step3.stepSubtitle')}</span>
          <Tooltip tip={t('steps.step3.subDescription')} cursor>
            <span>
              <InformationCircleIcon className='w-4 h-4 text-gray-400' />
            </span>
          </Tooltip>
        </div>
      }
      overview={<StepOverview items={[]} />}
    >
      {({ goPrevious }) => (
        <div className='space-y-6 m-1'>
          <Card className='overflow-visible'>
            <Card.Body>
              <div className='mt-5 md:mt-0 space-y-6'>
                <SwitchField
                  id='statusAssociate'
                  name='statusAssociate'
                  reverse
                  checked={values.isActiveAssociateBatch}
                  onChange={checked => setFieldValue('isActiveAssociateBatch', checked)}
                  label={`${t('form.associateTo.label')} ${t('form.associateTo.batch')}`}
                />

                {values.isActiveAssociateBatch && (
                  <TrusSelectMultiField
                    id='truIds'
                    name='truIds'
                    onBlur={handleBlur}
                    truIdsValue={values.truIds}
                    label={t('form.associateTo.labelBatchSelector')}
                    error={!!errors.truIds && touched.truIds}
                    onChange={options =>
                      setFieldValue(
                        'truIds',
                        options.map(({ value }) => value)
                      )
                    }
                    menuPortalTarget={document.body}
                  />
                )}

                <div className='mt-8'>
                  <SwitchField
                    id='statusAssociateReception'
                    name='statusAssociateReception'
                    reverse
                    checked={values.isActiveAssociateReception}
                    onChange={checked => setFieldValue('isActiveAssociateReception', checked)}
                    label={`${t('form.associateTo.label')} ${t('form.associateTo.reception')}`}
                  />
                </div>

                {values.isActiveAssociateReception && (
                  <ReceptionsSelectMultiField
                    id='receptionIds'
                    name='receptionIds'
                    receptionsIdsValue={values.receptionIds}
                    onChange={options =>
                      setFieldValue(
                        'receptionIds',
                        options.map(({ value }) => value)
                      )
                    }
                    onBlur={handleBlur}
                    label={t('form.associateTo.labelReceptionSelector')}
                    error={!!errors.receptionIds && touched.receptionIds}
                    menuPortalTarget={document.body}
                  />
                )}

                <SwitchField
                  id='status'
                  name='status'
                  reverse
                  checked={values.status === TaskStatus.CLOSED}
                  onChange={checked =>
                    setFieldValue('status', checked ? TaskStatus.CLOSED : TaskStatus.OPEN)
                  }
                  label={t('form.status.label')}
                />

                <UsersSelectField
                  id='assignedTo'
                  name='assignedTo'
                  withCompany
                  departmentId={templateSelected?.associations
                    .filter(({ type }) => type === TemplateAssociationType.DEPARTMENT)
                    .map(({ id }) => id)}
                  value={values.assignedTo}
                  onChange={option => setFieldValue('assignedTo', option)}
                  onBlur={handleBlur}
                  label={t('form.assignedTo.label')}
                  error={!!errors.assignedTo && touched.assignedTo}
                  menuPortalTarget={document.body}
                />

                {hasIssueReferences && (
                  <UsersSelectField
                    id='issueResponder'
                    name='issueResponder'
                    withCompany
                    departmentId={templateSelected?.associations
                      .filter(({ type }) => type === TemplateAssociationType.DEPARTMENT)
                      .map(({ id }) => id)}
                    value={values.issueResponder}
                    onChange={option => setFieldValue('issueResponder', option)}
                    onBlur={handleBlur}
                    label={t('form.issueResponder.label')}
                    error={!!errors.issueResponder && touched.issueResponder}
                    menuPortalTarget={document.body}
                  />
                )}
              </div>
            </Card.Body>
          </Card>
          <div className='flex flex-wrap justify-between gap-4'>
            <Help analyticsProperties={{ Source: 'Task creation' }} />
            <div className='flex items-center gap-4'>
              <ButtonWithIcon color='white' IconComponent={ArrowSmUpIcon} onClick={goPrevious}>
                {t('steps.step3.previousText')}
              </ButtonWithIcon>
              <Button onClick={submitForm}>
                {t('steps.step3.finishText', {
                  context: translationsContext,
                })}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Step>
  )
}
