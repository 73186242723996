import { UseQueryOptions, useQueries, useQuery, useQueryClient } from 'react-query'
import {
  getAll,
  getAllAssigned,
  getAllByIds,
  getById,
  getCount,
  getPaginated,
  getReceptionTaskRelationsByTaskId,
  getReportTasks,
  getStakeHolderTaskRelationsByTaskIds,
  getTasksByReceptionId,
  getTasksByStakeHolderId,
  getTasksByTruId,
  getTruTaskRelationsByTaskId,
} from '../service'
import { AxiosError } from 'axios'
import { Issue, useFetchIssuesByTaskId } from '@/features/issues'
import {
  ReceptionTaskRelation,
  StakeHolderTaskRelation,
  Task,
  TaskFilters,
  TaskStatus,
  TasksSummaryReportData,
  TasksSummaryReportFilters,
  TruTaskRelation,
} from '../interfaces'
import { useCallback, useMemo } from 'react'
import { useUsers } from '@/features/users'
import {
  useAccountLogo,
  useCompanyUser,
  useMyProfile,
  useUserId,
  useUserPermissions,
  useUserType,
} from '@/features/auth'
import * as Utils from '../utils'
import {
  TaskTemplateTypes,
  TemplateEntryType,
  useFetchTemplateByTypeAndId,
  useTemplates,
} from '@/features/templates'
import {
  ValidationRequestStatus,
  useFetchValidationsRequestByEntityId,
  useGetLastValidator,
} from '@/features/validationRequests'
import { Paginated, PaginationParams } from '@/hooks/use-pagination'
import { mergeQueryOptions } from '@/utils/merge-query-options'
import { useMyDepartments } from '@/features/departments'
import {
  Signature,
  useFetchSignatureByUserId,
  useFetchSignatureByUserIds,
  useMySignature,
} from '@/features/signatures'
import { Entry } from '@/features/entries'
import { useTranslation } from 'react-i18next'

export const QUERY_TASKS_KEY = 'tasks'
export const QUERY_TASKS_COUNT_KEY = 'tasks-count'
export const QUERY_TRU_TASK_RELATIONS_KEY = 'tru-task-relations'
export const QUERY_RECEPTION_TASK_RELATIONS_KEY = 'reception-task-relations'
export const QUERY_STAKEHOLDER_TASK_RELATIONS_KEY = 'stakeholder-task-relations'

export const useInvalidateTaskQueries = () => {
  const queryClient = useQueryClient()
  return {
    invalidateTaskQueries: () => {
      queryClient.invalidateQueries(QUERY_TASKS_KEY)
      queryClient.invalidateQueries(QUERY_TASKS_COUNT_KEY)
      queryClient.invalidateQueries(QUERY_TRU_TASK_RELATIONS_KEY)
      queryClient.invalidateQueries(QUERY_RECEPTION_TASK_RELATIONS_KEY)
      queryClient.invalidateQueries(QUERY_STAKEHOLDER_TASK_RELATIONS_KEY)
    },
  }
}

export const useIsTaskAdmin = (): { isTaskAdmin: boolean } => {
  const userType = useUserType()
  const userPermissions = useUserPermissions()

  return { isTaskAdmin: userType === 'company' || userPermissions.tasks?.admin }
}

export const usePaginatedTasks = (
  filters: TaskFilters,
  pagination: PaginationParams,
  overrideOptions?: Omit<UseQueryOptions<Paginated<Task>, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const queryClient = useQueryClient()
  const options = mergeQueryOptions(
    {
      keepPreviousData: true,
      onSuccess(data: Paginated<Task>) {
        overrideOptions?.onSuccess?.(data)
        data.items.forEach(item => {
          queryClient.setQueryData([QUERY_TASKS_KEY, item._id], item)
        })
      },
    },
    overrideOptions
  )
  const { data, ...rest } = useQuery(
    [QUERY_TASKS_KEY, filters, pagination],
    () => getPaginated(filters, pagination),
    options
  )

  const tasks = useMemo(() => data?.items || [], [data?.items])
  const totalCount = data?.totalCount || 0

  return {
    ...rest,
    tasks,
    totalCount,
  }
}

export const useMyPaginatedTasks = (
  filters: Omit<TaskFilters, 'assignedToMe'>,
  pagination: PaginationParams,
  overrideOptions?: Omit<UseQueryOptions<Paginated<Task>, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { isTaskAdmin } = useIsTaskAdmin()

  return usePaginatedTasks({ assignedToMe: !isTaskAdmin, ...filters }, pagination, overrideOptions)
}

export const usePaginatedMyDepartmentTasks = (
  filters: TaskFilters,
  pagination: PaginationParams,
  overrideOptions?: Omit<UseQueryOptions<Paginated<Task>, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { isTaskAdmin } = useIsTaskAdmin()

  const { departments, isFetched: isDepartmentFetched } = useMyDepartments({
    enabled: !isTaskAdmin && overrideOptions?.enabled,
  })

  const { templates, isFetched: isTemplatesFetched } = useTemplates(
    { types: TaskTemplateTypes, departmentIds: departments.map(({ _id }) => _id) },
    {
      enabled: isDepartmentFetched,
    }
  )

  const templateIds = templates.map(({ _id }) => _id)

  const normalizedFormIds =
    filters?.formId && filters.formId.length > 0
      ? templateIds.filter(templateId => filters.formId?.includes(templateId))
      : templateIds

  const normalizedFilters = isTaskAdmin
    ? filters
    : {
        ...filters,
        formId: normalizedFormIds,
      }

  return usePaginatedTasks(normalizedFilters, pagination, {
    ...overrideOptions,
    keepPreviousData: isTemplatesFetched && normalizedFormIds.length > 0,
    enabled: isTaskAdmin
      ? overrideOptions?.enabled
      : isTemplatesFetched && normalizedFormIds.length > 0,
  })
}

export const useTasksCount = (
  filters?: TaskFilters,
  options?: Omit<UseQueryOptions<{ count: number }, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_TASKS_COUNT_KEY, filters],
    () => getCount(filters),
    options
  )

  const count = data?.count || 0

  return {
    ...rest,
    count,
  }
}

export const useMyTasksCount = (
  filters?: TaskFilters,
  options?: Omit<UseQueryOptions<{ count: number }, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { isTaskAdmin } = useIsTaskAdmin()

  return useTasksCount({ assignedToMe: !isTaskAdmin, ...filters }, options)
}

export const useFetchReportTasks = () => {
  const queryClient = useQueryClient()
  const fetchReportTasks = useCallback(
    (filters: TasksSummaryReportFilters) => {
      return queryClient.fetchQuery([QUERY_TASKS_KEY, 'report', { filters }], () =>
        getReportTasks(filters)
      )
    },
    [queryClient]
  )

  return { fetchReportTasks }
}

export const useReportTasks = (
  filters: TasksSummaryReportFilters,
  options?: Omit<
    UseQueryOptions<
      TasksSummaryReportData,
      unknown,
      TasksSummaryReportData,
      (string | { filters: TasksSummaryReportFilters })[]
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_TASKS_KEY, 'report', { filters }],
    () => getReportTasks(filters),
    options
  )

  return {
    data: useMemo(() => data, [data]),
    ...rest,
  }
}

export const useMyDepartmentTasksCount = (
  filters?: TaskFilters,
  overrideOptions?: Omit<UseQueryOptions<{ count: number }, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { isTaskAdmin } = useIsTaskAdmin()

  const { departments, isFetched: isDepartmentFetched } = useMyDepartments({
    enabled: !isTaskAdmin && overrideOptions?.enabled,
  })

  const { templates, isFetched: isTemplatesFetched } = useTemplates(
    { types: TaskTemplateTypes, departmentIds: departments.map(({ _id }) => _id) },
    {
      enabled: isDepartmentFetched,
    }
  )

  const templateIds = templates.map(({ _id }) => _id)

  const normalizedFormIds =
    filters?.formId && filters.formId.length > 0
      ? templateIds.filter(templateId => filters.formId?.includes(templateId))
      : templateIds

  const normalizedFilters = isTaskAdmin
    ? filters
    : {
        ...filters,
        formId: normalizedFormIds,
      }

  return useTasksCount(normalizedFilters, {
    ...overrideOptions,
    enabled: isTaskAdmin
      ? overrideOptions?.enabled
      : isTemplatesFetched && normalizedFormIds.length > 0,
  })
}

export const useTaskCountsByFormIds = (formIds: string[]) => {
  const results = useQueries(
    formIds.map(formId => ({
      queryKey: [QUERY_TASKS_COUNT_KEY, { formId }],
      queryFn: async () => {
        const result = await getCount({ formId: [formId] })
        return { formId, count: result.count }
      },
    }))
  )

  const tasksCountByForm = useMemo(
    () =>
      results.map(result => result.data).filter(Boolean) as {
        formId: string
        count: number
      }[],
    [results]
  )
  const isLoading = results.some(result => result.isLoading)

  return {
    tasksCountByForm,
    isLoading,
  }
}

/**
 *  @deprecated prefer usePaginatedTasks, as it is more performant
 */
export const useTasks = (
  filters?: Record<string, string | string[]>,
  options?: Omit<UseQueryOptions<Task[], AxiosError, Task[]>, 'queryKey' | 'queryFn'>
) => {
  const truFilterKey = filters?.truId ? 'truId' : filters?.tru ? 'tru' : undefined
  let truId: string[] = []

  if (truFilterKey && filters) {
    const truFilter = filters[truFilterKey]
    if (Array.isArray(truFilter)) {
      truId = truFilter
    } else {
      truId = [truFilter]
    }
  }

  const sanitizedFilters = filters ? { ...filters } : undefined

  if (sanitizedFilters && truFilterKey) {
    delete sanitizedFilters[truFilterKey]
  }

  const queryClient = useQueryClient()
  const newOptions: Omit<UseQueryOptions<Task[], AxiosError>, 'queryKey' | 'queryFn'> = {
    ...options,
    enabled: !truId.length,
    onSuccess(data: Task[]) {
      options?.onSuccess?.(data)
      data.forEach(item => {
        queryClient.setQueryData([QUERY_TASKS_KEY, item._id], item)
      })
    },
  }
  const { data: getAllTasksData, ...getAllRest } = useQuery(
    [QUERY_TASKS_KEY, { filters }],
    () => getAll(filters),
    newOptions
  )
  const { tasks: tasksByTruIdData, ...tasksByTruIdRest } = useTasksByTruIds(
    truId,
    sanitizedFilters,
    {
      ...options,
      enabled: !!truId.length,
    }
  )

  return useMemo(() => {
    const data = truId.length ? tasksByTruIdData : getAllTasksData
    const rest = truId.length ? tasksByTruIdRest : getAllRest

    return { tasks: data || [], ...rest }
  }, [getAllRest, getAllTasksData, tasksByTruIdData, tasksByTruIdRest, truId.length])
}

export const useAssignedTasks = (
  filters?: Record<string, string | string[]>,
  options?: Omit<UseQueryOptions<Task[], AxiosError, Task[]>, 'queryKey' | 'queryFn'>
) => {
  const userId = useUserId()
  const queryClient = useQueryClient()
  const newOptions: Omit<UseQueryOptions<Task[], AxiosError>, 'queryKey' | 'queryFn'> = {
    ...options,
    onSuccess(data: Task[]) {
      options?.onSuccess?.(data)
      data.forEach(item => {
        queryClient.setQueryData([QUERY_TASKS_KEY, item._id], item)
      })
    },
  }
  const { data, ...rest } = useQuery(
    [QUERY_TASKS_KEY, { filters, assignedTo: userId }],
    () => getAllAssigned(filters),
    newOptions
  )
  return { ...rest, tasks: useMemo(() => data || [], [data]) }
}

export const useMyTasks = (
  filters?: Record<string, string | string[]>,
  options?: Omit<UseQueryOptions<Task[], AxiosError, Task[]>, 'queryKey' | 'queryFn'>
) => {
  const { isTaskAdmin } = useIsTaskAdmin()

  const { tasks: userTasks, ...restOfAssignedTasksQueryResult } = useAssignedTasks(filters, {
    ...options,
    enabled: !isTaskAdmin,
  })

  const { tasks: allTasks, ...restOfAllTasksQueryResult } = useTasks(filters, {
    ...options,
    enabled: isTaskAdmin,
  })

  if (isTaskAdmin) {
    return {
      ...restOfAllTasksQueryResult,
      tasks: allTasks,
    }
  }

  return {
    ...restOfAssignedTasksQueryResult,
    tasks: userTasks,
  }
}
export const useTasksPending = (
  filters?: Record<string, string | string[]>,
  options?: Omit<UseQueryOptions<Task[], AxiosError, Task[]>, 'queryKey' | 'queryFn'>
) => {
  const { tasks, ...rest } = useMyTasks(filters, options)
  return {
    tasks: useMemo(() => {
      return tasks.filter(({ status }) => status === TaskStatus.OPEN)
    }, [tasks]),
    ...rest,
  }
}

export const useFetchTaskByIds = () => {
  const queryClient = useQueryClient()

  const fetchTaskByIds = useCallback(
    (taskIds: string[]) => {
      return queryClient.fetchQuery([QUERY_TASKS_KEY, { ids: taskIds }], () => getAllByIds(taskIds))
    },
    [queryClient]
  )

  return {
    fetchTaskByIds,
  }
}

export const useTasksByIds = (
  taskIds?: string[],
  options?: Omit<UseQueryOptions<Task[] | void, AxiosError, Task[]>, 'queryKey' | 'queryFn'>
) => {
  const queryClient = useQueryClient()
  const newOptions: Omit<
    UseQueryOptions<Task[] | void, AxiosError, Task[]>,
    'queryKey' | 'queryFn'
  > = {
    ...options,
    onSuccess(data: Task[]) {
      options?.onSuccess?.(data)
      data.forEach(item => {
        queryClient.setQueryData([QUERY_TASKS_KEY, item._id], item)
      })
    },
  }
  const { data, ...rest } = useQuery(
    [QUERY_TASKS_KEY, { ids: taskIds }],
    () => getAllByIds(taskIds || []),
    newOptions
  )
  return { ...rest, tasks: useMemo(() => data || [], [data]) }
}

export const useTasksByTruIds = (
  truIds: string[],
  filters?: Record<string, string | string[]>,
  options?: Omit<UseQueryOptions<Task[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_TASKS_KEY, { truIds, filters }],
    async () => {
      const responses = await Promise.all(truIds.map(truId => getTasksByTruId(truId, filters)))
      return (
        responses
          // Unify all responses
          .flat()
          // Remove repeated
          .filter(
            (task, index, self) => self.findIndex(selfItem => selfItem._id === task._id) === index
          )
      )
    },
    options
  )

  return { ...rest, tasks: useMemo(() => data || [], [data]) }
}

export const useTasksByReceptionIds = (
  receptionIds: string[],
  options?: Omit<UseQueryOptions<Task[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_TASKS_KEY, { receptionIds }],
    async () => {
      const responses = await Promise.all(
        receptionIds.map(receptionId => getTasksByReceptionId(receptionId))
      )
      return (
        responses
          // Unify all responses
          .flat()
          // Remove repeated
          .filter(
            (task, index, self) => self.findIndex(selfItem => selfItem._id === task._id) === index
          )
      )
    },
    options
  )

  return { ...rest, tasks: useMemo(() => data?.flat() || [], [data]) }
}

export const useTasksByStakeHolderId = (
  stakeHolderId: string,
  filters?: TaskFilters,
  options?: Omit<UseQueryOptions<Task[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_TASKS_KEY, { stakeHolderId, filters }],
    () => getTasksByStakeHolderId(stakeHolderId, filters),
    options
  )

  return { ...rest, tasks: useMemo(() => data || [], [data]) }
}

export const useTruTaskRelationsByTaskId = (
  taskId: string,
  options?: Omit<
    UseQueryOptions<TruTaskRelation[] | void, AxiosError, TruTaskRelation[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_TRU_TASK_RELATIONS_KEY, { truTaskRelationsByTask: taskId }],
    () => getTruTaskRelationsByTaskId(taskId),
    options
  )

  return { ...rest, truTaskRelations: useMemo(() => data || [], [data]) }
}

export const useReceptionTaskRelationById = (
  taskId?: string,
  options?: Omit<
    UseQueryOptions<ReceptionTaskRelation[] | void, AxiosError, ReceptionTaskRelation[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_RECEPTION_TASK_RELATIONS_KEY, { receptionTaskRelationsByTask: taskId }],
    () => (taskId ? getReceptionTaskRelationsByTaskId(taskId) : []),
    options
  )

  return { ...rest, receptionTaskRelations: useMemo(() => data || [], [data]) }
}

export const useStakeHolderTaskRelationsByIds = (
  taskIds?: string[],
  options?: Omit<
    UseQueryOptions<StakeHolderTaskRelation[] | void, AxiosError, StakeHolderTaskRelation[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_STAKEHOLDER_TASK_RELATIONS_KEY, { stakeHolderTaskRelationsByTasks: taskIds }],
    () => (taskIds ? getStakeHolderTaskRelationsByTaskIds(taskIds) : []),
    options
  )

  return { ...rest, stakeHolderTaskRelations: useMemo(() => data || [], [data]) }
}

export const useTaskById = (
  taskId: string,
  options?: Omit<UseQueryOptions<Task | void, AxiosError, Task>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery([QUERY_TASKS_KEY, taskId], () => getById(taskId), options)

  return { ...rest, task: data }
}

export const useFetchTaskById = () => {
  const queryClient = useQueryClient()

  const fetchTaskById = useCallback(
    (taskId: string) => {
      return queryClient.fetchQuery([QUERY_TASKS_KEY, taskId], () => getById(taskId))
    },
    [queryClient]
  )

  return {
    fetchTaskById,
  }
}

export const useGenerateTaskReport = () => {
  const { fetchTaskById } = useFetchTaskById()
  const { fetchTemplateByTypeAndId } = useFetchTemplateByTypeAndId()
  const { fetchValidationsRequestByEntityId } = useFetchValidationsRequestByEntityId()
  const { fetchSignatureByUserId } = useFetchSignatureByUserId()
  const { users } = useUsers()
  const company = useCompanyUser()
  const { getLastValidator } = useGetLastValidator()
  const { fetchIssuesByTaskId } = useFetchIssuesByTaskId()
  const { profile } = useMyProfile()
  const { fetchSignatureByUserIds } = useFetchSignatureByUserIds()
  const { signature: mySignature } = useMySignature()
  const accountLogo = useAccountLogo()
  const { i18n, t } = useTranslation()

  const generateTaskReport = useCallback(
    async (taskId: string) => {
      const task = await fetchTaskById(taskId)
      if (!task) {
        throw new Error('No task found')
      }
      const template = await fetchTemplateByTypeAndId('form', task.formId)
      if (!template) {
        throw new Error('No template found')
      }
      const creatorName = Utils.getAssignedCreatorTaskName(task.companyId, { company, users })
      const assignedToName = Utils.getAssignedCreatorTaskName(task.assignedTo, { company, users })
      const [taskValidation] = await fetchValidationsRequestByEntityId(task._id)
      let lastValidator:
        | {
            name: string
            message?: string | undefined
            validationDate?: string | undefined
            isValidatedByMe?: boolean | undefined
            signed?: boolean | undefined
            id?: string
          }
        | undefined
      if (taskValidation && taskValidation.status !== ValidationRequestStatus.PENDING) {
        lastValidator = getLastValidator(taskValidation)
      }

      let signature: Signature | undefined
      if (lastValidator?.id && lastValidator.signed === true) {
        if (profile) {
          signature = await fetchSignatureByUserId(
            lastValidator.id === company._id ? profile.owner.id : lastValidator.id
          )
        }
      }

      // Fetch entry signatures
      const signatureEntriesIds = task.entries
        // Extract signature entries
        .filter(
          (entry): entry is Omit<Entry, 'values'> & { values: string[] } =>
            entry.type === TemplateEntryType.SIGNATURE
        )
        // Extract signature entry values
        .flatMap(entry => entry.values)
        // remove duplicates
        .filter((value, index, self) => self.indexOf(value) === index)

      const entrySignatures = await fetchSignatureByUserIds(signatureEntriesIds)
      if (mySignature) {
        entrySignatures.push(mySignature)
      }

      const issueReferencesWithIssues = task.entries.filter(
        entry => entry.type === TemplateEntryType.ISSUE_REFERENCE && entry.values.length > 0
      )

      const issues: Issue[] =
        issueReferencesWithIssues.length > 0 ? await fetchIssuesByTaskId(taskId) : []

      return Utils.generateTaskReport({
        task,
        taskTemplate: template,
        issues,
        creatorName,
        assignedToName,
        validator: lastValidator,
        signature,
        entrySignatures,
        issueMediaName: t('nsTaskReport:issueImage'),
        companyLogo: accountLogo && accountLogo !== 'default' ? accountLogo : undefined,
        options: {
          direction: i18n.dir(),
        },
      })
    },
    [
      fetchTaskById,
      fetchTemplateByTypeAndId,
      company,
      users,
      fetchValidationsRequestByEntityId,
      fetchSignatureByUserIds,
      mySignature,
      fetchIssuesByTaskId,
      t,
      accountLogo,
      i18n,
      getLastValidator,
      profile,
      fetchSignatureByUserId,
    ]
  )

  return {
    generateTaskReport,
  }
}
