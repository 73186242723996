import { Auth } from '@/features/auth'
import { convertPunycodeToUnicode } from '@/utils/convert-punycode-to-unicode'
import {
  SelectorOption,
  createSelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'

export interface IUser {
  _id: string
  name: string
  companyId: string
  auth: Auth
  roleId?: string | null
  language: string
  createdAt: string
  updatedAt: string
}

export class User implements IUser {
  _id: string
  name: string
  companyId: string
  auth: Auth
  roleId?: string | null
  language: string
  createdAt: string
  updatedAt: string

  constructor({ _id, name, companyId, auth, roleId, language, createdAt, updatedAt }: IUser) {
    this._id = _id
    this.name = name
    this.companyId = companyId
    this.auth = {
      ...auth,
      email: convertPunycodeToUnicode(auth.email),
    }
    this.roleId = roleId
    this.language = language
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }

  /**
   * Get user public name (name or email)
   * @returns {string}
   */
  public get publicName(): string {
    return this.name || this.auth.email
  }

  /**
   * Convert user to selector option
   * @returns {SelectorOption}
   */
  public toSelectorOption(): SelectorOption {
    return createSelectorOption(this.publicName, this._id)
  }
}

export interface ReadableUser {
  _id: string
  name: string
  auth: {
    email: string
  }
}

export interface UserCreation {
  name: string
  email: string
  password: string
  roleId: string | null
}

export interface UserUpdate {
  name?: string
  email?: string
  password?: string
  roleId: string | null
}

export interface UserFilters {
  search?: string
  departmentId?: string[]
}
