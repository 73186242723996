import { Fragment, useCallback, useMemo, useState } from 'react'
import { customErrorToTrack, useAnalytics } from '@/features/analytics'
import { useUserAddons, useUserId, useUserPermissions } from '@/features/auth'
import { useToggle } from '@/hooks/use-toggle'
import { AlertConfirm, ButtonGroup, Tooltip } from '@blockchain-traceability-sl/tailwind-components'
import {
  BadgeCheckIcon,
  ClockIcon,
  CogIcon,
  DocumentDuplicateIcon,
  DownloadIcon,
  ExclamationIcon,
  EyeIcon,
  MailIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import { PencilIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDeleteTask, useGenerateTaskReport, useTaskById } from '../../hooks'
import { TaskStatus } from '../../interfaces'
import { ModalTaskEmail } from '../ModalTaskEmail'
import { ModalTaskIssues, useIssuesByTaskId } from '@/features/issues'
import { ModalTaskActivity } from '../ModalTaskActivity'
import {
  ValidationRequest,
  ValidationRequestStatus,
  useValidationsRequestByEntityId,
} from '@/features/validationRequests'
import { useGeneratePdfFromDefinition } from '@/features/pdf'
import { downloader } from '@/helpers/downloader'
import { useIssueStatusGroups } from '@/pages/tasks/TasksList/hooks'
import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { DropdownMenuItem } from '@/components/DropdownMenuItem'

export const TaskManageMenu = ({ taskId }: { taskId?: string }) => {
  const { t } = useTranslation('nsTasksListPage')
  const analytics = useAnalytics()
  const history = useHistory()
  const userAddons = useUserAddons()
  const userPermissions = useUserPermissions()
  const userId = useUserId()

  const [selectedTaskId, setTaskSelected] = useState<string | undefined | null>(taskId)

  const { task } = useTaskById(selectedTaskId || '', {
    enabled: !!selectedTaskId,
  })

  const { validationRequests } = useValidationsRequestByEntityId(selectedTaskId || '', {
    enabled: !!selectedTaskId,
  })

  const lastValidationRequest = useMemo<ValidationRequest | undefined>(
    () =>
      validationRequests.sort(
        (requestA, requestB) =>
          new Date(requestB.createdAt).getTime() - new Date(requestA.createdAt).getTime()
      )[0],
    [validationRequests]
  )

  const hasValidationRequestPending: boolean =
    lastValidationRequest?.status === ValidationRequestStatus.PENDING

  const hasUserValidationRequestPending: boolean =
    hasValidationRequestPending &&
    validationRequests[validationRequests.length - 1].reviewers.some(
      reviewer => reviewer.id === userId
    )
  const { issues } = useIssuesByTaskId(selectedTaskId || '', {
    enabled: !!selectedTaskId,
  })
  const [isModalEmailShow, toggleModalEmail] = useToggle(false)
  const [manageIssuesShow, toggleManageIssuesShow] = useToggle(false)
  const [isModalTaskActivityShow, toggleModalTaskActivity] = useToggle(false)
  const [confirmDeletionOpen, toggleConfirmDeletion] = useToggle(false)

  const { generateTaskReport } = useGenerateTaskReport()
  const { generatePdfFromDefinitionAsync } = useGeneratePdfFromDefinition()
  const { deleteTask } = useDeleteTask()

  const { issueStatusGroups } = useIssueStatusGroups()
  const downloadReport = useCallback(
    async (taskId: string) => {
      try {
        const taskReportDefinition = await generateTaskReport(taskId)
        const taskReportFilename = t('filename', {
          templateName: task?.formName,
          date: new Date(task?.dueDate || ''),
        })

        const taskReport = await generatePdfFromDefinitionAsync({
          pdfDefinition: JSON.stringify(taskReportDefinition),
          filename: taskReportFilename,
        })

        if (!taskReport) return

        downloader(taskReport?.blob, 'application/pdf', taskReportFilename)

        analytics.track('CUSTOMER_GENERATE_TASK_REPORT', {
          From: 'Task',
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        analytics.track('CUSTOMER_GENERATE_TASK_REPORT_ERROR', {
          From: 'Task',
          ...customErrorToTrack(error?.response?.data, error?.response?.status),
        })
      }
    },
    [
      generateTaskReport,
      analytics,
      generatePdfFromDefinitionAsync,
      t,
      task?.formName,
      task?.dueDate,
    ]
  )

  const handleToggleModalTaskActivity = () => {
    toggleModalTaskActivity(false)
  }

  const handleToggleModalTaskIssues = () => {
    toggleManageIssuesShow(false)
  }

  const handleDeleteConfirm = () => {
    toggleConfirmDeletion()
    if (!selectedTaskId || !task) return

    deleteTask(task)
  }
  const handleDeleteCancel = () => {
    setTaskSelected(undefined)
    toggleConfirmDeletion()
  }

  return (
    <>
      <ButtonGroup>
        <ButtonGroup.Button>
          {hasUserValidationRequestPending ? (
            <Tooltip tip={t('calendar.sidebar.tooltip.review')} placement='bottom'>
              <span>
                <EyeIcon
                  className='h-5 w-5 text-gray-400'
                  onClick={() => {
                    analytics.track('ACTION_CLICK_MANAGE_TASK', {
                      Action: 'Review',
                    })
                    history.push(`/tasks/${selectedTaskId}/validate`)
                  }}
                />
              </span>
            </Tooltip>
          ) : (
            <Tooltip tip={t('calendar.sidebar.tooltip.edit')} placement='bottom'>
              <span>
                <PencilIcon
                  className='h-5 w-5 text-gray-400'
                  onClick={() => {
                    analytics.track('ACTION_CLICK_MANAGE_TASK', {
                      Action: 'View and Edit',
                    })
                    history.push(`/tasks/${selectedTaskId}/edit`)
                  }}
                />
              </span>
            </Tooltip>
          )}
        </ButtonGroup.Button>

        <Menu as='div' className='relative text-left'>
          <Menu.Button>
            <ButtonGroup.Button>
              <Tooltip tip={t('calendar.sidebar.tooltip.manage')} placement='bottom'>
                <span>
                  <CogIcon className='h-5 w-5 text-gray-400' />
                </span>
              </Tooltip>
            </ButtonGroup.Button>
          </Menu.Button>

          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute z-10 right-0 top-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow focus:outline-none text-left'>
              <div className='py-1 text-left'>
                {!hasValidationRequestPending && task?.status === TaskStatus.CLOSED && (
                  <DropdownMenuItem
                    id='report'
                    name={<span className='text-left'>{t('manage.report.title')}</span>}
                    icon={DownloadIcon}
                    onClick={() => {
                      if (!selectedTaskId) return
                      analytics.track('ACTION_CLICK_MANAGE_TASK', {
                        Action: 'Generate report',
                      })
                      downloadReport(selectedTaskId)
                    }}
                  />
                )}

                {!hasValidationRequestPending && userPermissions.tasks?.write && (
                  <DropdownMenuItem
                    id='duplicate'
                    name={<span className='text-left'>{t('manage.duplicate.title')}</span>}
                    icon={DocumentDuplicateIcon}
                    onClick={() => {
                      if (!selectedTaskId) return
                      analytics.track('ACTION_CLICK_MANAGE_TASK', {
                        Action: 'Duplicate',
                      })
                      history.push(
                        `/tasks/create?${new URLSearchParams({ presetId: selectedTaskId })}`
                      )
                    }}
                  />
                )}

                {userAddons?.departments.enabled &&
                  !hasValidationRequestPending &&
                  userPermissions.tasks?.write && (
                    <DropdownMenuItem
                      id='validation-request'
                      name={
                        <span className='text-left'>{t('manage.validationRequest.title')}</span>
                      }
                      icon={BadgeCheckIcon}
                      onClick={() => {
                        if (!selectedTaskId) return
                        analytics.track('ACTION_CLICK_MANAGE_TASK', {
                          Action: 'Validation request',
                        })
                        history.push(
                          `/tasks/validation-requests/create?${new URLSearchParams({
                            'taskIds[]': selectedTaskId,
                          }).toString()}`
                        )
                      }}
                    />
                  )}

                {issues.length > 0 && !hasValidationRequestPending && (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        key='DropdownMenuItem-incidents'
                        onClick={() => {
                          analytics.track('ACTION_CLICK_MANAGE_TASK', {
                            Action: 'Manage incidents',
                          })
                          toggleManageIssuesShow()
                        }}
                        className={classNames(
                          'px-4 py-2 text-sm inline-flex w-full cursor-pointer relative',
                          {
                            'bg-gray-100 text-gray-900': active,
                            'text-gray-700': !active,
                          }
                        )}
                      >
                        <ExclamationIcon
                          className='-ml-1 mr-2 h-5 w-5 text-gray-400'
                          aria-hidden='true'
                        />
                        {issues.some(issue => !issueStatusGroups.CLOSED.includes(issue.status)) && (
                          <div className='w-2 h-2 left-6 top-2 absolute bg-red-600 rounded-full' />
                        )}
                        {t('manage.manageIssues.title')}
                      </button>
                    )}
                  </Menu.Item>
                )}

                {task?.status === TaskStatus.CLOSED &&
                  !hasValidationRequestPending &&
                  userPermissions.tasks.write && (
                    <DropdownMenuItem
                      id='send'
                      name={<span className='text-left'>{t('manage.email.title')}</span>}
                      icon={MailIcon}
                      onClick={() => {
                        analytics.track('ACTION_CLICK_MANAGE_TASK', {
                          Action: 'Send report via email',
                        })
                        toggleModalEmail(true)
                      }}
                    />
                  )}

                <DropdownMenuItem
                  id='activity'
                  name={<span className='text-left'>{t('manage.taskActivity.title')}</span>}
                  icon={ClockIcon}
                  onClick={() => {
                    analytics.track('ACTION_CLICK_MANAGE_TASK', {
                      Action: 'Task activity',
                    })
                    toggleModalTaskActivity(true)
                  }}
                />

                {userPermissions.tasks?.write && (
                  <DropdownMenuItem
                    id='delete'
                    name={<span className='text-left'>{t('manage.delete.title')}</span>}
                    icon={TrashIcon}
                    onClick={() => {
                      analytics.track('ACTION_CLICK_MANAGE_TASK', {
                        Action: 'Delete',
                      })
                      toggleConfirmDeletion()
                    }}
                  />
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </ButtonGroup>

      {/* Modal send report via email */}
      <ModalTaskEmail show={isModalEmailShow} toggle={toggleModalEmail} task={task} />

      {/* Modal to manage task issues */}
      <ModalTaskIssues
        show={manageIssuesShow}
        toggle={handleToggleModalTaskIssues}
        taskId={selectedTaskId || null}
      />
      {/* Modal to see task activity */}
      <ModalTaskActivity
        show={isModalTaskActivityShow}
        toggle={handleToggleModalTaskActivity}
        taskId={selectedTaskId || null}
      />

      {/* Modal confirmation deletion */}
      <AlertConfirm
        title={t('deletionConfirm.title')}
        description={t('deletionConfirm.description')}
        confirmText={t('deletionConfirm.confirm')}
        cancelText={t('deletionConfirm.cancel')}
        show={confirmDeletionOpen}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
    </>
  )
}
