import { useState } from 'react'

interface UseLocalStorageOptions {
  /**
   * Whether to use a prefix to store the value in localStorage
   * @default true
   */
  withPrefix?: boolean
  /**
   * Prefix to use to store the value in localStorage
   * @default 'trz:'
   * @see withPrefix
   */
  prefix?: string
}

const defaultOptions: UseLocalStorageOptions = {
  withPrefix: true,
  prefix: 'trz:',
}

/**
 * A hook that returns a stateful value and a function to update it.
 * The value is stored in localStorage and will persist across sessions.
 *
 * @param storageKey key to store the value in localStorage
 * @param defaultValue default value
 * @param options options
 * @returns a tuple containing the stateful value and a function to update it
 * @example
 * const [name, setName] = useLocalStorage('name', 'John Doe')
 */
export const useLocalStorage = <T>(
  key: string,
  defaultValue?: T,
  options: UseLocalStorageOptions = defaultOptions
): [T, (newValue: T) => void] => {
  const overrideOptions = { ...defaultOptions, ...options }
  const storageKey = `${overrideOptions.withPrefix ? overrideOptions.prefix : ''}${key}`

  // Get the previous value from localStorage if it exists
  const [state, setState] = useState(() => {
    const value = localStorage.getItem(storageKey)
    if (!value) return defaultValue
    return JSON.parse(value)
  })

  // Update the localStorage value when the state changes
  const handleSetState = (newValue: T) => {
    setState(newValue)
    localStorage.setItem(storageKey, JSON.stringify(newValue))
  }

  return [state, handleSetState]
}
