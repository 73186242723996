import { api } from '@/app/api'
import { Paginated, PaginationParams } from '@/hooks/use-pagination'
import { Department, DepartmentCreation, DepartmentUpdate } from './interfaces'

const DEPARTMENTS_URL = '/auth/departments'
/**
 * Create new department
 *
 * @param {{ name: string, description: string, users: string[] }}
 *
 * @returns {Promise<void>}
 */
export const createDepartments = async ({
  name,
  description,
  users,
}: DepartmentCreation): Promise<string> => {
  return await api.post(DEPARTMENTS_URL, {
    name,
    description,
    users,
  })
}

//* * Get all departments */

export const getAll = async (): Promise<Department[]> => {
  const { data: departments } = await api.get<Department[] | void>(DEPARTMENTS_URL)
  return departments || []
}

export const getMyDepartments = async (): Promise<Department[]> => {
  const { data: departments } = await api.get<Department[] | void>(`${DEPARTMENTS_URL}/me`)
  return departments || []
}

export const getPaginatedDepartments = async (
  {
    search,
  }: {
    search?: string
  },
  pagination: PaginationParams
): Promise<Paginated<Department>> => {
  const searchParams = new URLSearchParams()
  if (search) {
    searchParams.append('search', search)
  }
  searchParams.append('limit', pagination.limit.toString())
  searchParams.append('offset', pagination.offset.toString())
  const { data } = await api.get<Paginated<Department>>(
    `${DEPARTMENTS_URL}?${searchParams.toString()}`
  )
  return data
}

/**
 * Delete users by id
 *
 * @param {id : string} - the  id to delete
 */
export const deleteDepartment = async (departmentId: string): Promise<void> => {
  await api.delete<void>(`${DEPARTMENTS_URL}/${departmentId}`)
}

//* * Update department */
export const updateDepartment = async ({
  _id: departmentId,
  ...data
}: DepartmentUpdate): Promise<void> => {
  await api.patch<void>(`${DEPARTMENTS_URL}/${departmentId}`, data)
}
