/**
 * Check if number is odd
 * @param amount
 * @returns if is odd
 */
export const isOdd = (amount: number): boolean => !!(amount % 2)

/**
 * Check if number is even
 * @param amount
 * @returns if is even
 */
export const isEven = (amount: number): boolean => !(amount % 2)
