import { openTaskFile } from '@/features/tasks'
import { Button, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

export const TableFileInput = ({
  value,
  valueFile,
  onChange,
}: {
  value?: string
  valueFile?: File
  onChange: (file?: File) => void
}) => {
  const { t } = useTranslation('nsEntries')
  const inputRef = useRef<HTMLInputElement>(null)
  return (
    <div className='flex items-center justify-center'>
      <Button
        color='secondary'
        size={SIZE.EXTRA_SMALL}
        className='mx-1'
        onClick={() => {
          if (value) {
            if (valueFile) {
              window.open(URL.createObjectURL(valueFile), '_blank')
            } else {
              openTaskFile(value)
            }
          } else {
            inputRef.current?.click()
          }
        }}
      >
        {value ? t('form.table.file.download') : t('form.table.file.upload')}
      </Button>
      <Button
        color='secondary'
        size={SIZE.EXTRA_SMALL}
        className='mx-1'
        onClick={() => onChange(undefined)}
      >
        {t('form.table.file.remove')}
      </Button>
      <input
        type='file'
        className='hidden'
        ref={inputRef}
        onChange={event => {
          const files = event.target.files
          if (files && files.length > 0) {
            onChange(files[0])
          }
        }}
      />
    </div>
  )
}
