import {
  Document,
  DocumentFile,
  DocumentFolder,
  DocumentType,
  createTreeFile,
  createTreeFolder,
  treeFilesExtraProps,
} from '@/features/documents'
import { AxiosError } from 'axios'
import { useMemo } from 'react'
import { UseQueryOptions, useQueries, useQuery } from 'react-query'
import { useCompanyUser } from '../auth'
import { ROOT_FOLDER_ID, useAssociatedDocumentsByTruIds, useDocuments } from '../documents'
import { useFetchStakeHolderByIds, useStakeHolders } from '../stakeHolders'
import { useTasksByReceptionIds, useTasksByTruIds } from '../tasks'
import { Traceability } from './interfaces'
import { GetEntitiesFromTraceabilityResponse, getEntitiesFromTraceability } from './utils'
import { getByTruId } from './service'
import { customErrorToTrack, useAnalytics } from '../analytics'

const QUERY_TRACEABILITIES_KEY = 'traceabilities'
export interface UseTraceabilityDocumentsState {
  /**
   * All traceability documents
   */
  documents: Document[]
  /**
   * Files from traceability documents
   */
  files: DocumentFile[]
  /**
   * Folders from traceability documents
   */
  folders: DocumentFolder[]
  isLoading: boolean
}

export const useTraceabilitiesDocuments = (
  traceabilities: Traceability[]
): UseTraceabilityDocumentsState => {
  const traceabilityEntities: GetEntitiesFromTraceabilityResponse = useMemo(() => {
    return (
      traceabilities
        // Extract entities from all traceabilities
        .map(getEntitiesFromTraceability)
        // Merge the results as only one
        .reduce(
          (entities, currentEntities) => {
            return {
              nodes: [...entities.nodes, ...currentEntities.nodes],
              edges: [...entities.edges, ...currentEntities.edges],
              truEdges: [...entities.truEdges, ...currentEntities.truEdges],
              products: [...entities.products, ...currentEntities.products]
                // Remove repeated by _id
                .filter(
                  (item, index, self) =>
                    index === self.findIndex(selfItem => item._id === selfItem._id)
                ),
              trus: [...entities.trus, ...currentEntities.trus]
                // Remove repeated by _id
                .filter(
                  (item, index, self) =>
                    index === self.findIndex(selfItem => item._id === selfItem._id)
                ),
              shipments: [...entities.shipments, ...currentEntities.shipments]
                // Remove repeated by _id
                .filter(
                  (item, index, self) =>
                    index === self.findIndex(selfItem => item._id === selfItem._id)
                ),
              stakeHolderProducts: [
                ...entities.stakeHolderProducts,
                ...currentEntities.stakeHolderProducts,
              ]
                // Remove repeated by _id
                .filter(
                  (item, index, self) =>
                    index === self.findIndex(selfItem => item._id === selfItem._id)
                ),
              receptions: [...entities.receptions, ...currentEntities.receptions]
                // Remove repeated by _id
                .filter(
                  (item, index, self) =>
                    index === self.findIndex(selfItem => item._id === selfItem._id)
                ),
              metadatas: [...entities.metadatas, ...currentEntities.metadatas],
            }
          },
          {
            nodes: [],
            edges: [],
            truEdges: [],
            products: [],
            trus: [],
            shipments: [],
            stakeHolderProducts: [],
            receptions: [],
            metadatas: [],
          }
        )
    )
  }, [traceabilities])

  const company = useCompanyUser()
  const { stakeHolders } = useStakeHolders()
  // const { t } = useTranslation('nsTraceabilityViewPage')

  // Extract all traceability StakeHolders
  const stakeholderIds = useMemo(() => {
    if (traceabilityEntities?.stakeHolderProducts.length) {
      return traceabilityEntities.stakeHolderProducts
        .map(stakeholderProduct => stakeholderProduct.stakeHolder.id)
        .filter((stakeHolderId, index, result) => result.indexOf(stakeHolderId) === index)
    } else {
      return []
    }
  }, [traceabilityEntities?.stakeHolderProducts])

  // Extract all traceability OBS TRUs
  const observerTruIds = useMemo(() => {
    return traceabilityEntities.nodes
      .filter(node => node._id === company._id)
      .flatMap(node => node.trus)
      .map(tru => tru._id)
  }, [company._id, traceabilityEntities.nodes])

  /** GETTERS */

  // Get all documents associated to traceability companies
  const {
    documents: associatedDocumentsByCompany,
    isLoading: associatedDocumentsByCompanyLoading,
  } = useDocuments({
    type: DocumentType.FILE,
    companyIds: [company._id],
  })

  // Get all documents associated to traceability products
  const {
    documents: associatedDocumentsByProducts,
    isLoading: associatedDocumentsByProductsLoading,
  } = useDocuments(
    {
      type: DocumentType.FILE,
      productIds: traceabilityEntities?.products.map(product => product._id) || [],
    },
    { enabled: !!traceabilityEntities?.products.length }
  )

  // Get all documents associated to traceability TRUs
  const { documents: associatedDocumentsByTRUs, isLoading: associatedDocumentsByTRUsLoading } =
    useAssociatedDocumentsByTruIds({
      truIds: traceabilityEntities?.trus.map(tru => tru._id) || [],
      observerTruIds,
    })

  // Get all documents associated to traceability StakeHolders
  const {
    documents: associatedDocumentsByStakeholders,
    isLoading: associatedDocumentsByStakeholdersLoading,
  } = useDocuments(
    {
      type: DocumentType.FILE,
      stakeHolderIds: stakeholderIds,
    },
    { enabled: !!stakeholderIds.length }
  )

  // Get all documents associated to traceability StakeHolderProducts
  const {
    documents: associatedDocumentsByStakeholderProducts,
    isLoading: associatedDocumentsByStakeholderProductsLoading,
  } = useDocuments(
    {
      type: DocumentType.FILE,
      stakeHolderProductIds:
        traceabilityEntities?.stakeHolderProducts.map(
          stakeholderProduct => stakeholderProduct._id
        ) || [],
    },
    { enabled: !!traceabilityEntities?.stakeHolderProducts.length }
  )

  // Get all documents associated to traceability Receptions
  const {
    documents: associatedDocumentsByReceptions,
    isLoading: associatedDocumentsByReceptionsLoading,
  } = useDocuments(
    {
      type: DocumentType.FILE,
      receptionIds: traceabilityEntities?.receptions.map(({ _id }) => _id) || [],
    },
    { enabled: !!traceabilityEntities?.receptions.length }
  )

  // Generate all documents list
  const documents: Document[] = useMemo(() => {
    if (traceabilities.length === 0) return []

    const documentsByAssociation = [
      ...associatedDocumentsByCompany,
      ...associatedDocumentsByProducts,
      ...associatedDocumentsByTRUs,
      ...associatedDocumentsByStakeholders,
      ...associatedDocumentsByStakeholderProducts,
      ...associatedDocumentsByReceptions,
    ] as DocumentFile[]
    const temporaryDocuments: Document[] = []

    // Create own company Folder
    if (associatedDocumentsByCompany) {
      temporaryDocuments.push(
        createTreeFolder(company._id, company.name, ROOT_FOLDER_ID, company._id)
      )
    }

    // Create entities folders
    documentsByAssociation.forEach((documentByAssociation: DocumentFile) => {
      // Create TRU transversal folders
      documentByAssociation.truIds.forEach(truId => {
        // Create file inside folder
        temporaryDocuments.push(
          createTreeFile(
            documentByAssociation._id,
            documentByAssociation.title,
            truId,
            documentByAssociation.companyId,
            documentByAssociation.mimeType,
            documentByAssociation.path,
            treeFilesExtraProps(documentByAssociation)
          )
        )

        const folderTruToCreate = traceabilityEntities?.trus.find(({ _id }) => _id === truId)
        const folderProductToCreate = traceabilityEntities?.products.find(
          ({ _id }) => _id === folderTruToCreate?.productId
        )
        const folderCompanyToCreate = traceabilityEntities?.nodes.find(
          ({ _id }) => _id === folderProductToCreate?.companyId
        )

        if (folderTruToCreate && folderProductToCreate && folderCompanyToCreate) {
          temporaryDocuments.push(
            createTreeFolder(
              folderTruToCreate._id,
              folderTruToCreate.reference,
              folderTruToCreate.productId,
              folderTruToCreate.companyId
            )
          )
          temporaryDocuments.push(
            createTreeFolder(
              folderProductToCreate._id,
              folderProductToCreate.name,
              folderProductToCreate.companyId,
              folderProductToCreate.companyId
            )
          )
          temporaryDocuments.push(
            createTreeFolder(
              folderCompanyToCreate._id,
              folderCompanyToCreate.name,
              ROOT_FOLDER_ID,
              folderCompanyToCreate._id
            )
          )
        }
      })

      // Create Product transversal folders
      documentByAssociation.productIds.forEach(productId => {
        // Create file inside folder
        temporaryDocuments.push(
          createTreeFile(
            documentByAssociation._id,
            documentByAssociation.title,
            productId,
            documentByAssociation.companyId,
            documentByAssociation.mimeType,
            documentByAssociation.path,
            treeFilesExtraProps(documentByAssociation)
          )
        )

        const folderProductToCreate = traceabilityEntities?.products.find(
          ({ _id }) => _id === productId
        )
        const folderCompanyToCreate = traceabilityEntities?.nodes.find(
          ({ _id }) => _id === folderProductToCreate?.companyId
        )

        if (folderProductToCreate && folderCompanyToCreate) {
          temporaryDocuments.push(
            createTreeFolder(
              folderProductToCreate._id,
              folderProductToCreate.name,
              folderProductToCreate.companyId,
              folderProductToCreate.companyId
            )
          )
          temporaryDocuments.push(
            createTreeFolder(
              folderCompanyToCreate._id,
              folderCompanyToCreate.name,
              ROOT_FOLDER_ID,
              folderCompanyToCreate._id
            )
          )
        }
      })

      // Create Company transversal folders
      documentByAssociation.companyIds.forEach(companyId => {
        // Create file inside folder
        temporaryDocuments.push(
          createTreeFile(
            documentByAssociation._id,
            documentByAssociation.title,
            companyId,
            documentByAssociation.companyId,
            documentByAssociation.mimeType,
            documentByAssociation.path,
            treeFilesExtraProps(documentByAssociation)
          )
        )

        const folderCompanyToCreate = traceabilityEntities?.nodes.find(
          ({ _id }) => _id === companyId
        )

        if (folderCompanyToCreate) {
          temporaryDocuments.push(
            createTreeFolder(
              folderCompanyToCreate._id,
              folderCompanyToCreate.name,
              ROOT_FOLDER_ID,
              folderCompanyToCreate._id
            )
          )
        }
      })

      // Create TRU transversal folders
      documentByAssociation.receptionIds.forEach(receptionId => {
        // Create file inside folder
        temporaryDocuments.push(
          createTreeFile(
            documentByAssociation._id,
            documentByAssociation.title,
            receptionId,
            documentByAssociation.companyId,
            documentByAssociation.mimeType,
            documentByAssociation.path,
            treeFilesExtraProps(documentByAssociation)
          )
        )

        const folderReceptionToCreate = traceabilityEntities?.receptions.find(
          ({ _id }) => _id === receptionId
        )
        const folderStakeHolderProductToCreate = traceabilityEntities?.stakeHolderProducts.find(
          ({ _id }) => _id === folderReceptionToCreate?.stakeHolderProductId
        )
        const folderStakeHolderToCreate = traceabilityEntities?.nodes.find(
          ({ _id }) => _id === folderStakeHolderProductToCreate?.stakeHolder.id
        )

        if (
          folderReceptionToCreate &&
          folderStakeHolderProductToCreate &&
          folderStakeHolderToCreate
        ) {
          temporaryDocuments.push(
            createTreeFolder(
              folderReceptionToCreate._id,
              folderReceptionToCreate.reference,
              folderReceptionToCreate.stakeHolderProductId,
              folderReceptionToCreate.companyId
            )
          )
          temporaryDocuments.push(
            createTreeFolder(
              folderStakeHolderProductToCreate._id,
              folderStakeHolderProductToCreate.stakeHolder.product.name,
              folderStakeHolderProductToCreate.stakeHolder.id,
              folderStakeHolderProductToCreate.companyId
            )
          )
          temporaryDocuments.push(
            createTreeFolder(
              folderStakeHolderToCreate._id,
              folderStakeHolderToCreate.name,
              ROOT_FOLDER_ID,
              folderStakeHolderToCreate._id
            )
          )
        }
      })

      // Create StakeHolderProduct transversal folders
      documentByAssociation.stakeHolderProductIds.forEach(stakeHolderProductId => {
        // Create file inside folder
        temporaryDocuments.push(
          createTreeFile(
            documentByAssociation._id,
            documentByAssociation.title,
            stakeHolderProductId,
            documentByAssociation.companyId,
            documentByAssociation.mimeType,
            documentByAssociation.path,
            treeFilesExtraProps(documentByAssociation)
          )
        )

        const folderStakeHolderProductToCreate = traceabilityEntities?.stakeHolderProducts.find(
          ({ _id }) => _id === stakeHolderProductId
        )
        const folderStakeHolderToCreate = traceabilityEntities?.nodes.find(
          ({ _id }) => _id === folderStakeHolderProductToCreate?.stakeHolder.id
        )

        if (folderStakeHolderProductToCreate && folderStakeHolderToCreate) {
          temporaryDocuments.push(
            createTreeFolder(
              folderStakeHolderProductToCreate._id,
              folderStakeHolderProductToCreate.stakeHolder.product.name,
              folderStakeHolderProductToCreate.stakeHolder.id,
              folderStakeHolderProductToCreate.companyId
            )
          )
          temporaryDocuments.push(
            createTreeFolder(
              folderStakeHolderToCreate._id,
              folderStakeHolderToCreate.name,
              ROOT_FOLDER_ID,
              folderStakeHolderToCreate._id
            )
          )
        }
      })

      // Create StakeHolder transversal folders
      documentByAssociation.stakeHolderIds.forEach(stakeHolderId => {
        // Create file inside folder
        temporaryDocuments.push(
          createTreeFile(
            documentByAssociation._id,
            documentByAssociation.title,
            stakeHolderId,
            documentByAssociation.companyId,
            documentByAssociation.mimeType,
            documentByAssociation.path,
            treeFilesExtraProps(documentByAssociation)
          )
        )

        const folderStakeHolderToCreate = stakeHolders.find(({ _id }) => _id === stakeHolderId)

        if (folderStakeHolderToCreate) {
          temporaryDocuments.push(
            createTreeFolder(
              folderStakeHolderToCreate._id,
              folderStakeHolderToCreate.company.name,
              ROOT_FOLDER_ID,
              folderStakeHolderToCreate._id
            )
          )
        }
      })
    })

    return (
      temporaryDocuments
        // Remove repeated by _id and parentId
        .filter(
          (temporaryDocument, index, self) =>
            self.findIndex(
              selfItem =>
                selfItem._id === temporaryDocument._id &&
                selfItem.parentId === temporaryDocument.parentId
            ) === index
        )
    )
  }, [
    traceabilities.length,
    associatedDocumentsByCompany,
    associatedDocumentsByProducts,
    associatedDocumentsByTRUs,
    associatedDocumentsByStakeholders,
    associatedDocumentsByStakeholderProducts,
    associatedDocumentsByReceptions,
    company._id,
    company.name,
    traceabilityEntities?.trus,
    traceabilityEntities?.products,
    traceabilityEntities?.nodes,
    traceabilityEntities?.receptions,
    traceabilityEntities?.stakeHolderProducts,
    stakeHolders,
  ])

  const isLoading = useMemo(() => {
    return (
      associatedDocumentsByCompanyLoading ||
      associatedDocumentsByProductsLoading ||
      associatedDocumentsByStakeholderProductsLoading ||
      associatedDocumentsByReceptionsLoading ||
      associatedDocumentsByStakeholdersLoading ||
      associatedDocumentsByTRUsLoading
    )
  }, [
    associatedDocumentsByCompanyLoading,
    associatedDocumentsByProductsLoading,
    associatedDocumentsByStakeholderProductsLoading,
    associatedDocumentsByReceptionsLoading,
    associatedDocumentsByStakeholdersLoading,
    associatedDocumentsByTRUsLoading,
  ])

  return {
    documents,
    files: documents.filter(
      (document): document is DocumentFile => document.type === DocumentType.FILE
    ),
    folders: documents.filter(
      (document): document is DocumentFolder => document.type === DocumentType.FOLDER
    ),
    isLoading,
  }
}

export const useTraceabilityTasks = (triggerTruId: string, traceability?: Traceability | null) => {
  const traceabilityEntities = useMemo(
    () => getEntitiesFromTraceability(traceability || undefined),
    [traceability]
  )
  const { tasks: tasksByTRUs, isLoading: isLoadingByTrus } = useTasksByTruIds([triggerTruId])
  const { tasks: tasksByReceptions, isLoading: isLoadingByReceptions } = useTasksByReceptionIds(
    traceabilityEntities.receptions.map(({ _id }) => _id)
  )

  const tasks = useMemo(
    () =>
      [...tasksByTRUs, ...tasksByReceptions]
        // Remove repeated
        .filter(
          (task, index, self) => self.findIndex(selfItem => selfItem._id === task._id) === index
        ),
    [tasksByReceptions, tasksByTRUs]
  )

  return {
    isLoading: isLoadingByTrus || isLoadingByReceptions,
    tasks,
    tasksByTRUs,
    tasksByReceptions,
  }
}

export const useTraceability = (
  truId: string,
  options?: Omit<UseQueryOptions<Traceability | void, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { fetchStakeHolderByIds } = useFetchStakeHolderByIds()
  const getSingleTraceability = async (traceabilityTruId: string) => {
    const traceabilities = await getByTruId(traceabilityTruId)

    if (traceabilities && typeof traceabilities !== 'string') {
      return traceabilities[0]
    }
  }

  const { data, ...rest } = useQuery(
    [QUERY_TRACEABILITIES_KEY, { truId }],
    async (): Promise<Traceability | void> => {
      const traceability = await getSingleTraceability(truId)
      if (traceability) {
        const stakeholders = await fetchStakeHolderByIds(traceability.nodes.map(({ _id }) => _id))

        return {
          ...traceability,
          nodes: traceability.nodes.map(node => ({
            ...node,
            name:
              stakeholders.find(({ company: { id } }) => id === node._id)?.company.name ??
              node.name,
          })),
        }
      }
    },
    options
  )

  return {
    ...rest,
    traceability: useMemo(() => data || undefined, [data]),
    hasError: useMemo(
      () => rest.isError || (rest.isFetched && !data),
      [rest.isError, rest.isFetched, data]
    ),
  }
}

export const useTraceabilities = (
  truIds: string[],
  options?: Omit<UseQueryOptions<Record<string, Traceability>, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const analytics = useAnalytics()
  const traceabilityQueries = useQueries(
    truIds.map(truId => ({
      queryKey: [QUERY_TRACEABILITIES_KEY, { truId }],
      queryFn: () => getByTruId(truId),
      onError: (error: AxiosError) => {
        analytics.track('CUSTOMER_GENERATE_REPORT_NOT_FOUND', {
          ...customErrorToTrack(error.response?.data, error.response?.status),
          Type: 'Delivery note',
        })
      },
      options,
    }))
  )
  const traceabilities = useMemo(
    () =>
      traceabilityQueries
        .map(({ data }, index) => [
          truIds[index],
          data && typeof data !== 'string' && !!data.length ? data[0] : null,
        ])
        .filter((t): t is [string, Traceability] => t[1] !== null),
    [traceabilityQueries, truIds]
  )

  const isLoading = traceabilityQueries.some(query => query.isLoading)
  const isError = traceabilityQueries.some(query => query.isError)
  const error = traceabilityQueries.find(query => query.isError)?.error

  return {
    traceabilities: Object.fromEntries(traceabilities),
    isLoading,
    isError,
    error,
  }
}

export const useEntitiesFromTraceability = (
  traceability?: Traceability | null
): GetEntitiesFromTraceabilityResponse => {
  return useMemo(() => getEntitiesFromTraceability(traceability || undefined), [traceability])
}
