import {
  SelectField,
  SelectFieldProps,
  SelectorOption,
  SelectorOptionOrGroup,
} from '@blockchain-traceability-sl/tailwind-components'
import { usePaginatedCustomReportTemplates } from '../hooks'
import { CustomReportTemplateSourceType } from '../interfaces'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useDebounceState } from '@/hooks/use-debounce-state'

export const CustomReportTemplatesSelectField = (props: Omit<SelectFieldProps, 'options'>) => {
  const { t } = useTranslation('nsReportCreatePage')
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const { isLoading, customReportTemplates, fetchNextPage } = usePaginatedCustomReportTemplates({
    search: debouncedSearch,
  })

  const optionsBySourceTypeMap: Record<CustomReportTemplateSourceType, SelectorOption[]> =
    customReportTemplates.reduce(
      (accumulator, template) => {
        accumulator[template.source.type].push({
          label: template.name,
          value: template._id,
        })
        return accumulator
      },
      {
        [CustomReportTemplateSourceType.TASK]: [] as SelectorOption[],
        [CustomReportTemplateSourceType.COMPLIANCE]: [] as SelectorOption[],
      }
    )

  const templatesOptions: SelectorOptionOrGroup[] = [
    {
      label: t('form.form.type.register'),
      options: optionsBySourceTypeMap[CustomReportTemplateSourceType.TASK],
    },
    {
      label: t('form.form.type.compliance'),
      options: optionsBySourceTypeMap[CustomReportTemplateSourceType.COMPLIANCE],
    },
  ]

  return (
    <SelectField
      {...props}
      isLoading={isLoading}
      options={templatesOptions}
      onInputChange={inputValue => {
        setSearch(inputValue)
      }}
      onMenuScrollToBottom={() => {
        fetchNextPage()
      }}
    />
  )
}
