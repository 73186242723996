import {
  Document,
  DocumentFileUpdate,
  DocumentFilters,
  DocumentFolder,
  DocumentFolderUpdate,
  DocumentType,
} from './interfaces'
import { useCallback, useMemo } from 'react'
import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from 'react-query'
import {
  createFile,
  createFolder,
  getAll,
  getAllBySearch,
  getAllSharedWith,
  getAllSharedWithMe,
  getAllSharedWithMeWithTRUAssociationId,
  getAllTags,
  getAllWithTRUAssociationId,
  getById,
  getCount,
  getDocumentTree,
  getDocumentsByIds,
  getFileContent,
  makePublic,
  remove,
  updateDocumentFile as updateDocumentFileService,
} from './service'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { customErrorToTrack, useAnalytics } from '@/features/analytics'
import * as NotifyService from '@/services/notify.service'
import { useHistory } from 'react-router-dom'
import { ROOT_FOLDER_ID } from './constants'
import mime from 'mime'
import { useUserKnownAs } from '../auth'
import { mergeInfiniteQueryOptions } from '@/utils/merge-query-options'
import { Paginated } from '@/hooks/use-pagination'

export const QUERY_DOCUMENTS_KEY = 'documents'
export const QUERY_DOCUMENT_COUNT_KEY = 'document'
export const QUERY_DOCUMENT_TAGS_KEY = 'documents-tags'

export const useDocumentCount = () => {
  const { data, ...rest } = useQuery([QUERY_DOCUMENT_COUNT_KEY, { action: 'count' }], getCount)

  return {
    ...rest,
    documentsCount: useMemo(() => data ?? 0, [data]),
  }
}

export const useDocuments = (
  filters: Partial<DocumentFilters>,
  options?: Omit<UseQueryOptions<Document[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery([QUERY_DOCUMENTS_KEY, filters], () => getAll(filters), options)

  return {
    ...rest,
    documents: useMemo(() => data || [], [data]),
  }
}

export const useFetchDocuments = () => {
  const queryClient = useQueryClient()

  const fetchDocuments = useCallback(
    (filters: Partial<DocumentFilters>) => {
      return queryClient.fetchQuery([QUERY_DOCUMENTS_KEY, filters], () => getAll(filters))
    },
    [queryClient]
  )

  return {
    fetchDocuments,
  }
}

export const useAssociatedDocumentsByTruIds = ({
  truIds,
  observerTruIds,
}: {
  truIds: string[]
  observerTruIds: string[]
}): { documents: Document[]; isLoading: boolean } => {
  const associatedDocumentsByTruResult = useQueries([
    ...observerTruIds.map(truId => ({
      queryKey: [QUERY_DOCUMENTS_KEY, { association: { truId } }],
      queryFn: () => getAllWithTRUAssociationId(truId),
    })),
    ...truIds.map(truId => ({
      queryKey: [QUERY_DOCUMENTS_KEY, { association: { truId }, shared: true }],
      queryFn: () => getAllSharedWithMeWithTRUAssociationId(truId),
    })),
  ])

  const ensureAssociatedPublicDocumentsQueries = useMemo(
    () =>
      (
        associatedDocumentsByTruResult.flatMap(({ data }) => data).filter(Boolean) as Document[]
      ).map(document => ({
        queryKey: [QUERY_DOCUMENTS_KEY, { id: document._id, makePublic: true }],
        queryFn: async () => {
          if (document.isPublic) {
            return document
          }

          return makePublic(document._id)
        },
      })),
    [associatedDocumentsByTruResult]
  )

  const ensureAssociatedPublicDocumentsResult = useQueries(ensureAssociatedPublicDocumentsQueries)

  return useMemo(
    () => ({
      isLoading: [...associatedDocumentsByTruResult, ...ensureAssociatedPublicDocumentsResult].some(
        ({ isLoading }) => isLoading
      ),
      documents: ensureAssociatedPublicDocumentsResult
        .map(({ data }) => data)
        .filter(Boolean) as Document[],
    }),
    [associatedDocumentsByTruResult, ensureAssociatedPublicDocumentsResult]
  )
}

export const useMakeDocumentPublic = () => {
  const queryClient = useQueryClient()

  const { mutate, mutateAsync, ...rest } = useMutation(makePublic, {
    onSuccess(data, variables) {
      queryClient.invalidateQueries(QUERY_DOCUMENTS_KEY)
    },
    onError(error: AxiosError) {
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return {
    ...rest,
    makeDocumentPublic: mutate,
    makeDocumentPublicAsync: mutateAsync,
  }
}

export const useDocumentsByIds = (
  documentIds: string[],
  options?: Omit<UseQueryOptions<Document[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_DOCUMENTS_KEY, { documentIds }],
    () => getDocumentsByIds(documentIds),
    options
  )

  return {
    ...rest,
    documents: useMemo(() => data || [], [data]),
  }
}

export const useFetchDocumentsByIds = () => {
  const queryClient = useQueryClient()

  const fetchDocumentsByIds = useCallback(
    (documentIds: string[]) => {
      return queryClient.fetchQuery([QUERY_DOCUMENTS_KEY, { documentIds }], async () => {
        const documentsSettled = await Promise.allSettled(
          documentIds.map(documentId => getById(documentId))
        )
        return documentsSettled
          .filter(
            (documentSettled): documentSettled is PromiseFulfilledResult<Document> =>
              documentSettled.status === 'fulfilled'
          )
          .map(({ value }) => value)
      })
    },
    [queryClient]
  )

  return {
    fetchDocumentsByIds,
  }
}

export const useDocumentById = (
  documentId: string,
  options?: Omit<UseQueryOptions<Document | void, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_DOCUMENTS_KEY, documentId],
    () => getById(documentId),
    options
  )

  return {
    ...rest,
    document: data,
  }
}

export const useCreateDocumentFolder = () => {
  const analytics = useAnalytics()
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')

  const {
    mutate: createDocumentFolder,
    mutateAsync: createDocumentFolderAsync,
    ...rest
  } = useMutation(createFolder, {
    onSuccess(data, variables) {
      analytics.track('CUSTOMER_CREATE_FOLDER', {
        Folder: variables.title,
      })
      NotifyService.success({
        title: t('document.folder.create.success.title'),
        description: t('document.folder.create.success.description'),
      })

      queryClient.invalidateQueries(QUERY_DOCUMENTS_KEY)
    },
    onError(error: AxiosError) {
      analytics.track(
        'CUSTOMER_CREATE_FOLDER_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return {
    ...rest,
    createDocumentFolder,
    createDocumentFolderAsync,
  }
}

export const useCreateDocumentFile = () => {
  const analytics = useAnalytics()
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')

  const {
    mutate: createDocumentFile,
    mutateAsync: createDocumentFileAsync,
    ...rest
  } = useMutation(createFile, {
    onSuccess(data, variables) {
      analytics.track('CUSTOMER_CREATE_DOCUMENT', {
        Document: variables.title,
      })
      NotifyService.success({
        title: t('document.file.create.success.title'),
        description: t('document.file.create.success.description'),
      })

      queryClient.invalidateQueries(QUERY_DOCUMENTS_KEY)
      queryClient.invalidateQueries(QUERY_DOCUMENT_COUNT_KEY)
    },
    onError(error: AxiosError) {
      analytics.track(
        'CUSTOMER_CREATE_DOCUMENT_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return {
    ...rest,
    createDocumentFile,
    createDocumentFileAsync,
  }
}

export const useUpdateDocumentFolder = () => {
  const analytics = useAnalytics()
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')

  const {
    mutate: updateDocumentFolder,
    mutateAsync: updateDocumentFolderAsync,
    ...rest
  } = useMutation(
    ({ _id, ...data }: DocumentFolderUpdate & { _id: string }) =>
      updateDocumentFileService(_id, data),
    {
      onSuccess(data, variables) {
        analytics.track('CUSTOMER_EDIT_FOLDER', {
          Folder: variables.title,
        })
        NotifyService.success({
          title: t('document.folder.update.success.title'),
          description: t('document.folder.update.success.description'),
        })

        queryClient.invalidateQueries(QUERY_DOCUMENTS_KEY)
      },
      onError(error: AxiosError) {
        analytics.track(
          'CUSTOMER_EDIT_FOLDER_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    updateDocumentFolder,
    updateDocumentFolderAsync,
  }
}

export const useUpdateDocumentFile = () => {
  const analytics = useAnalytics()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')

  const {
    mutate: updateDocumentFile,
    mutateAsync: updateDocumentFileAsync,
    ...rest
  } = useMutation(
    ({ _id, ...data }: DocumentFileUpdate & { _id: string }) =>
      updateDocumentFileService(_id, data),
    {
      onSuccess(data, variables) {
        analytics.track('CUSTOMER_EDIT_DOCUMENT', {
          Document: variables.title,
        })
        NotifyService.success({
          title: t('document.file.update.success.title'),
          description: t('document.file.update.success.description'),
          actionText: t('document.file.update.success.action'),
          onActionClick: () =>
            history.push(
              `/documents/files/create?parentId=${encodeURIComponent(
                variables.parentId || ROOT_FOLDER_ID
              )}`
            ),
        })

        queryClient.invalidateQueries(QUERY_DOCUMENTS_KEY)
      },
      onError(error: AxiosError) {
        analytics.track(
          'CUSTOMER_EDIT_DOCUMENT_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    updateDocumentFile,
    updateDocumentFileAsync,
  }
}

export const useMoveDocument = () => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()
  const { t } = useTranslation('nsDocumentsViewPage')

  const {
    mutate: moveDocument,
    mutateAsync: moveDocumentAsync,
    ...rest
  } = useMutation(
    ({
      _id,
      targetFolderId,
    }: {
      _id: string
      type: DocumentType
      title: string
      targetFolderId: string
      targetFolderTitle: string
      sourceFolderTitle: string
    }) => updateDocumentFileService(_id, { parentId: targetFolderId }),
    {
      onSuccess(data, variables) {
        NotifyService.success({
          title: t('move.success.title'),
          description: t('move.success.description', { folderName: variables.targetFolderTitle }),
        })
        analytics.track(
          variables.type === DocumentType.FILE ? 'CUSTOMER_MOVE_DOCUMENT' : 'CUSTOMER_MOVE_FOLDER',
          {
            From: variables.sourceFolderTitle,
            To: variables.targetFolderTitle,
            Name: variables.title,
          }
        )

        queryClient.invalidateQueries(QUERY_DOCUMENTS_KEY)
      },
      onError(error: AxiosError, variables) {
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
        analytics.track(
          variables.type === DocumentType.FILE
            ? 'CUSTOMER_MOVE_DOCUMENT_ERROR'
            : 'CUSTOMER_MOVE_FOLDER_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
      },
    }
  )

  return {
    ...rest,
    moveDocument,
    moveDocumentAsync,
  }
}

export const useDeleteDocument = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const history = useHistory()
  const queryClient = useQueryClient()

  const { mutate, ...rest } = useMutation((document: Document) => remove(document._id), {
    onSuccess(data, variables, context) {
      if (variables.type === DocumentType.FOLDER) {
        analytics.track('CUSTOMER_DELETE_FOLDER', {
          Folder: variables.title,
        })
        NotifyService.success({
          title: t('document.folder.delete.success.title'),
          description: t('document.folder.delete.success.description'),
          actionText: t('document.folder.delete.success.action'),
          onActionClick: () =>
            history.push(
              `/documents/folders/create?parentId=${encodeURIComponent(variables.parentId)}`
            ),
        })
      } else if (variables.type === DocumentType.FILE) {
        analytics.track('CUSTOMER_DELETE_DOCUMENT', {
          Document: variables.title,
        })
        NotifyService.success({
          title: t('document.file.delete.success.title'),
          description: t('document.file.delete.success.description'),
          actionText: t('document.file.delete.success.action'),
          onActionClick: () =>
            history.push(
              `/documents/files/create?parentId=${encodeURIComponent(variables.parentId)}`
            ),
        })
      }

      queryClient.invalidateQueries(QUERY_DOCUMENTS_KEY)
      queryClient.invalidateQueries(QUERY_DOCUMENT_COUNT_KEY)
    },
    onError(error: AxiosError, variables) {
      if (variables.type === DocumentType.FOLDER) {
        analytics.track(
          'CUSTOMER_DELETE_FOLDER_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
      } else if (variables.type === DocumentType.FILE) {
        analytics.track(
          'CUSTOMER_DELETE_DOCUMENT_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
      }
    },
  })

  return {
    ...rest,
    deleteDocument: mutate,
  }
}

export const useDocumentFileSource = (
  documentFile?: {
    path: string
    mimeType: string
    title: string
  },
  options?: Omit<UseQueryOptions<File | undefined, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_DOCUMENTS_KEY, { documentFile, result: 'file' }],
    async () => {
      if (documentFile) {
        const fileBlob = await getFileContent(documentFile.path)
        if (fileBlob) {
          return new File(
            [fileBlob],
            `${documentFile.title}${
              documentFile.mimeType !== 'application/octet-stream'
                ? `.${mime.getExtension(documentFile.mimeType)}`
                : ''
            }`,
            { type: documentFile.mimeType }
          )
        }
      }
    },
    options
  )

  return {
    ...rest,
    documentFileSource: data,
  }
}

export const useFetchDocumentFileSource = () => {
  const queryClient = useQueryClient()

  const fetchDocumentFileSource = useCallback(
    (documentFile: { path: string; mimeType: string; title: string }) => {
      return queryClient.fetchQuery(
        [QUERY_DOCUMENTS_KEY, { documentFile, result: 'file' }],
        async () => {
          if (documentFile) {
            const fileBlob = await getFileContent(documentFile.path)
            if (fileBlob) {
              return new File(
                [fileBlob],
                `${documentFile.title}${
                  documentFile.mimeType !== 'application/octet-stream'
                    ? `.${mime.getExtension(documentFile.mimeType)}`
                    : ''
                }`,
                { type: documentFile.mimeType }
              )
            }
          }
        }
      )
    },
    [queryClient]
  )

  return { fetchDocumentFileSource }
}

export const useDocumentsSharedWithMe = (
  options?: Omit<UseQueryOptions<Document[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const knownAs = useUserKnownAs()

  const { data, ...rest } = useQuery(
    [QUERY_DOCUMENTS_KEY, { shared: true }],
    async () => {
      const documentsSharedWithMe = getAllSharedWithMe()
      const documentsSharedWithMeAsStakeHolder = getAllSharedWith(knownAs)

      const documents = await Promise.all([
        documentsSharedWithMe,
        documentsSharedWithMeAsStakeHolder,
      ])

      return documents
        .flat()
        .filter(
          (item, index, self) => self.findIndex(selfItem => selfItem._id === item._id) === index
        )
    },
    options
  )

  return {
    ...rest,
    documents: useMemo(() => data || [], [data]),
  }
}

export const useDocumentsBySearch = (
  filters: Partial<DocumentFilters>,
  options?: Omit<UseInfiniteQueryOptions<Paginated<Document>, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const PAGE_SIZE = 15
  const optionsMerged = mergeInfiniteQueryOptions(
    {
      getNextPageParam: ({ pageInfo }) => pageInfo?.nextPage || undefined,
    },
    options
  )

  const { data, ...rest } = useInfiniteQuery(
    [QUERY_DOCUMENTS_KEY, { filters }, { isInfiniteQuery: true }],
    ({ pageParam }) => getAllBySearch(filters, pageParam || { limit: PAGE_SIZE, offset: 0 }),
    optionsMerged
  )

  const documents = useMemo(
    () => data?.pages.flatMap(page => page.items).flat() || [],
    [data?.pages]
  )

  return {
    ...rest,
    documents,
  }
}

export const useDocumentTree = (
  documentId: string,
  options?: Omit<UseQueryOptions<DocumentFolder[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_DOCUMENTS_KEY, { tree: true, documentId }],
    () => getDocumentTree(documentId),
    options
  )

  return {
    ...rest,
    documents: useMemo(() => data || [], [data]),
  }
}

export const useDocumentTags = (
  options?: Omit<UseQueryOptions<string[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery([QUERY_DOCUMENT_TAGS_KEY], () => getAllTags(), options)

  return {
    ...rest,
    documentTags: useMemo(() => data || [], [data]),
  }
}
