import { SelectField } from '@blockchain-traceability-sl/tailwind-components'
import { EntryFieldProps } from '../interfaces'
import { EntryLabel } from '../EntryLabel'
import { useEntryFieldAttributes } from '../utils'
import { useTranslation } from 'react-i18next'
import { TemplateEntryType } from '@/features/templates'
import { EntryCreateInput } from '@/features/entries'
import { useField } from 'formik'

export const SingleChoiceEntryField = ({ index, prefixName, disabled }: EntryFieldProps) => {
  const { t } = useTranslation('nsEntries')

  const { entryFieldName, valueFieldName, enabledFieldName, enabledFieldId, valueFieldId } =
    useEntryFieldAttributes(index, prefixName)

  const [entryInputField] = useField<EntryCreateInput>(entryFieldName)
  const [, , enabledInputFieldHelpers] = useField<boolean>(enabledFieldName)
  const [, { error, touched }, valueInputFieldHelpers] = useField<string | undefined | null>(
    valueFieldName
  )

  const requiredError = !!error && touched

  const entry = entryInputField.value

  if (entry.type !== TemplateEntryType.SINGLE_CHOICE) return null

  const isEntryEnabled = entry.enabled ?? true

  return (
    <SelectField
      id={valueFieldId}
      name={valueFieldName}
      label={
        <EntryLabel
          naCheckboxId={enabledFieldId}
          naCheckboxName={enabledFieldName}
          naUnchecked={isEntryEnabled}
          onNaChange={enabled => {
            enabledInputFieldHelpers.setValue(enabled)
            enabledInputFieldHelpers.setTouched(true)
          }}
          naCheckboxLabel={t('form.na')}
          mandatoryPrefixText={t('form.mandatory.indicator')}
          isMandatory={entry.mandatory}
          disabled={disabled}
        >
          {entry.name}
        </EntryLabel>
      }
      options={entry.options.map(option => ({
        label: option,
        value: option,
      }))}
      menuPortalTarget={document.body}
      placeholder={t('form.placeholders.selection')}
      onChange={option => {
        valueInputFieldHelpers.setValue(option?.value)
        valueInputFieldHelpers.setTouched(true)
      }}
      value={
        entry.value
          ? {
              label: entry.value,
              value: entry.value,
            }
          : null
      }
      error={requiredError}
      isDisabled={!isEntryEnabled || disabled}
    />
  )
}
