/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  FormikConfig,
  FormikErrors,
  FormikState,
  FormikTouched,
} from 'formik'
import { ChangeEvent, FocusEvent, FormEvent, SetStateAction } from 'react'

export interface MemoFormKeys {
  entity: string
  entityId?: string | Record<string, unknown>
  action: string
}

export interface UseMemoFormProps<Values> extends FormikConfig<Values>, MemoFormKeys {}

export enum MemoFormStatus {
  LOADING,
  LOADED,
}

export interface UseFormikInstance<Values> {
  initialValues: Values
  initialErrors: FormikErrors<unknown>
  initialTouched: FormikTouched<unknown>
  initialStatus: any
  handleBlur: {
    (event: FocusEvent<any>): void
    <T = any>(fieldOrEvent: T): T extends string ? (event: any) => void : void
  }
  handleChange: {
    (event: ChangeEvent<any>): void
    <T_1 = string | ChangeEvent<any>>(field: T_1): T_1 extends ChangeEvent<any>
      ? void
      : (event: string | ChangeEvent<any>) => void
  }
  handleReset: (event: any) => void
  handleSubmit: (event?: FormEvent<HTMLFormElement> | undefined) => void
  resetForm: (nextState?: Partial<FormikState<Values>> | undefined) => void
  setErrors: (errors: FormikErrors<Values>) => void
  setFormikState: (
    stateOrCallback: FormikState<Values> | ((state: FormikState<Values>) => FormikState<Values>)
  ) => void
  setFieldTouched: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Values>> | Promise<void>
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Values>> | Promise<void>
  setFieldError: (field: string, value: string | undefined) => void
  setStatus: (status: any) => void
  setSubmitting: (isSubmitting: boolean) => void
  setTouched: (
    touched: FormikTouched<Values>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Values>> | Promise<void>
  setValues: (
    values: SetStateAction<Values>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Values>> | Promise<void>
  submitForm: () => Promise<any>
  validateForm: (values?: Values) => Promise<FormikErrors<Values>>
  validateField: (name: string) => Promise<void> | Promise<string | undefined>
  isValid: boolean
  dirty: boolean
  unregisterField: (name: string) => void
  registerField: (name: string, { validate }: any) => void
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>
  getFieldMeta: (name: string) => FieldMetaProps<any>
  getFieldHelpers: (name: string) => FieldHelperProps<any>
  validateOnBlur: boolean
  validateOnChange: boolean
  validateOnMount: boolean
  values: Values
  errors: FormikErrors<Values>
  touched: FormikTouched<Values>
  isSubmitting: boolean
  isValidating: boolean
  status?: any
  submitCount: number
}

export interface UseMemoFormInstance<Values> extends UseFormikInstance<Values> {
  /**
   * Memoized form is loaded
   */
  isLoaded: boolean
}
