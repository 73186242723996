import SignaturePad from 'signature_pad'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useEventListener } from '@/hooks/use-event-listener'
import { ButtonWithIcon } from '@blockchain-traceability-sl/tailwind-components'
import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { SignatureIcon } from './SignatureIcon'
import { fitSVGWithCanvas, toSVGFile } from './utils'

export interface SignatureFieldProps {
  id: string
  label?: ReactNode
  description?: ReactNode
  helpText?: ReactNode
  deleteButtonText?: ReactNode
  editButtonText?: ReactNode

  initialSignatureUrl?: string

  onChange(svg: File): void
  onDelete(): void
}

export const SignatureField = ({
  id,
  label,
  description,
  helpText,
  onChange,
  initialSignatureUrl,
  deleteButtonText,
  editButtonText,
  onDelete,
}: SignatureFieldProps) => {
  const [editable, setEditable] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [targetCanvas, setTargetCanvas] = useState<HTMLCanvasElement | null>(null)
  const signaturePadRef = useRef<SignaturePad | null>(null)
  const [canvasWidth, setCanvasWidth] = useState<number | undefined>()
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!targetCanvas) return
    signaturePadRef.current = new SignaturePad(targetCanvas)
    signaturePadRef.current.addEventListener('endStroke', () => {
      if (!signaturePadRef.current) return
      const svgValue = fitSVGWithCanvas(targetCanvas, signaturePadRef.current.toSVG())
      onChange(toSVGFile(svgValue))
    })
  }, [onChange, targetCanvas])

  useEffect(() => {
    setCanvasWidth(wrapperRef.current?.clientWidth)
  }, [])

  /**
   * We need to restore the canvas if there are a resize, because we lost the context.
   */
  useEventListener('resize', () => {
    setCanvasWidth(wrapperRef.current?.clientWidth)
    if (signaturePadRef.current) {
      signaturePadRef.current.fromData(signaturePadRef.current.toData())
    }
  })

  const handleDelete = () => {
    onDelete()
    signaturePadRef.current?.clear()
    setEditable(false)
    setDeleted(true)
  }

  const handleInitialCreate = () => {
    if (initialSignatureUrl && !deleted) return
    setEditable(true)
    setDeleted(false)
  }

  const handleUpdate = () => {
    setEditable(true)
    signaturePadRef.current?.clear()
  }

  const shouldDisplayPlaceholder = !editable && (!initialSignatureUrl || deleted)

  const shouldDisplayInitialSignature = !editable && initialSignatureUrl && !deleted

  const shouldDisplayActionButtons = (editable || initialSignatureUrl) && !deleted

  const shouldDisplayEditButton = !!initialSignatureUrl

  return (
    <div>
      <label htmlFor={id} className='block text-sm font-medium text-gray-700 '>
        {label}
      </label>
      <div
        className='border-dashed border-2 rounded h-36'
        onClick={handleInitialCreate}
        ref={wrapperRef}
        id={id}
      >
        {editable && (
          <canvas
            ref={setTargetCanvas}
            width={canvasWidth}
            height={144}
            data-testid='signature-canvas'
          />
        )}
        {shouldDisplayPlaceholder && (
          <div className='flex flex-col items-center justify-center py-9 gap-4'>
            <SignatureIcon />
            <div className='text-xs leading-4 font-normal text-gray-500'>{helpText}</div>
          </div>
        )}
        {shouldDisplayInitialSignature && (
          <div className='h-full w-full'>
            <img src={initialSignatureUrl} alt='signature' className='w-full h-full' />
          </div>
        )}
      </div>
      {shouldDisplayActionButtons && (
        <div className='mt-3 gap-3 flex'>
          {shouldDisplayEditButton && (
            <ButtonWithIcon IconComponent={PencilIcon} color='secondary' onClick={handleUpdate}>
              {editButtonText}
            </ButtonWithIcon>
          )}

          <ButtonWithIcon IconComponent={TrashIcon} color='danger' onClick={handleDelete}>
            {deleteButtonText}
          </ButtonWithIcon>
        </div>
      )}
      <p className='mt-2 text-sm text-gray-500'>{description}</p>
    </div>
  )
}
