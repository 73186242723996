import { useFeatureFlag as useConfigCatFeatureFlag } from 'configcat-react'
import { useMyProfile } from '@/features/auth'
import config from '@/config'

/**
 * This hook is used to get the value of a feature flag when the user is logged in.
 * @param flag feature flag name
 * @returns flag value and loading state
 */
const useFeatureFlagFromConfigCat = (flag: string): { value: boolean; isLoading: boolean } => {
  const { profile } = useMyProfile()
  const userMetadata =
    profile?.user && profile?.company
      ? {
          identifier: profile?.user?.id,
          email: profile?.owner?.email,
          custom: {
            companyId: profile?.company?.id,
            companyName: profile?.company?.name,
            userEmail: profile?.user?.email,
          },
        }
      : undefined

  const { value, loading } = useConfigCatFeatureFlag(flag, false, userMetadata)

  return { value, isLoading: loading }
}

/**
 * This hook is used to get the value of a feature flag when the user is not logged in.
 * @param flag feature flag name
 * @returns flag value and loading state
 */
const usePublicFeatureFlagFromConfigCat = (
  flag: string
): { value: boolean; isLoading: boolean } => {
  const { value, loading } = useConfigCatFeatureFlag(flag, false)

  return { value, isLoading: loading }
}

/**
 * This hook is used to get the value of a feature flag in local development and is always enabled.
 * @param flag feature flag name
 * @returns flag value always enabled and loading state always false
 */
const useLocalFeatureFlag = (flag: string): { value: boolean; isLoading: boolean } => {
  return { value: true, isLoading: false }
}

/**
 * This hook is used to get the value of a feature flag when the user is logged in.
 * @param flag feature flag name
 * @returns flag value and loading state
 */
export const useFeatureFlag = config.CONFIG_CAT_SDK_KEY
  ? useFeatureFlagFromConfigCat
  : useLocalFeatureFlag

/**
 * This hook is used to get the value of a feature flag when the user is not logged in.
 * @param flag feature flag name
 * @returns flag value and loading state
 */
export const usePublicFeatureFlag = config.CONFIG_CAT_SDK_KEY
  ? usePublicFeatureFlagFromConfigCat
  : useLocalFeatureFlag
