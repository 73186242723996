import {
  InlineSelectField,
  createSelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { ProductFiltersState } from '../../interfaces'
import { ALL_FILTER_OPTION_VALUE } from './hooks'
import { useProductTags } from '../../hooks'
import { useAnalytics } from '@/features/analytics'

export const ProductFilters = ({
  className,
  filters,
  onFilterChange,
}: {
  className?: string
  filters: ProductFiltersState
  onFilterChange: (filtersPatch: Partial<ProductFiltersState>) => void
}) => {
  const { t } = useTranslation('nsProductsListPage', { keyPrefix: 'filters.form' })
  const analytics = useAnalytics()

  const allTagsOption = {
    label: t('tag.dropdown'),
    value: ALL_FILTER_OPTION_VALUE,
  }

  const { productTags } = useProductTags()

  return (
    <div className={classNames('flex flex-col', className)}>
      <div className='grid sm:grid-cols-4 grid-rows-2 sm:grid-rows-none gap-2 mb-4 sm:mb-8'>
        <div className='z-10'>
          <InlineSelectField
            multiple
            id='tags'
            name='tags'
            label={`${t('tag.label')} `}
            options={[allTagsOption, ...productTags.map(tag => createSelectorOption(tag))]}
            onChange={options => {
              const tagValues = options?.map(option => option.value) || []
              onFilterChange({ tags: tagValues })
              analytics.track('ACTION_FILTER_RESULTS', {
                From: 'Products',
                By: 'Tag',
              })
            }}
            value={
              filters.tags.length > 0
                ? filters.tags.map(tag => createSelectorOption(tag))
                : [allTagsOption]
            }
          />
        </div>
      </div>
    </div>
  )
}
