import { useField, useFormikContext } from 'formik'
import { DirtyTableEntryColumn, TableInputField } from '../TableInputField'
import { EntryFieldProps } from '../interfaces'
import { TemplateEntryType } from '@/features/templates'
import { EntryCreateInput } from '@/features/entries'
import { useEntryFieldAttributes } from '../utils'

export const TableEntryField = (props: EntryFieldProps) => {
  const { index, prefixName, disabled } = props

  const { setFieldValue } = useFormikContext<{ entries: EntryCreateInput[] }>()

  const { entryFieldName, valuesFieldName, valuesFieldId } = useEntryFieldAttributes(
    index,
    prefixName
  )

  const [{ value: entry }] = useField<EntryCreateInput>(entryFieldName)
  const [, , valuesInputFieldHelpers] = useField<DirtyTableEntryColumn[]>(valuesFieldName)

  if (entry.type !== TemplateEntryType.TABLE) return null

  return (
    <TableInputField
      {...props}
      key={valuesFieldId}
      name={entry.name}
      values={entry.values}
      entry={entry}
      setFieldValue={setFieldValue}
      disableEntry={disabled}
      onChange={newValues => {
        valuesInputFieldHelpers.setValue(newValues)
        valuesInputFieldHelpers.setTouched(true)
      }}
    />
  )
}
