import { useMemo } from 'react'
import { UseQueryOptions, useQuery } from 'react-query'
import { getByParentIds } from './service'
import { StockRelation } from './entity'
import { AxiosError } from 'axios'

export const QUERY_STOCK_RELATIONS_KEY = 'stockRelations'

export const useQueryStockRelationsByParentIds = (
  parentIds: string[],
  options?: Omit<
    UseQueryOptions<StockRelation[], AxiosError, StockRelation[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_STOCK_RELATIONS_KEY, { parentIds }],
    async () => {
      const results = await getByParentIds(parentIds)
      return (
        results
          // Flat the results
          .flat()
          // Filter repeated
          .filter(
            ({ _id }, index, self) => self.findIndex(selfItem => selfItem._id === _id) === index
          )
      )
    },
    options
  )
  const stockRelations = useMemo(() => data || [], [data])

  return { ...rest, stockRelations }
}
