import { Task, useInvalidateTaskQueries } from '@/features/tasks'
import { Issue, IssueStatus, useChangeIssueStatus } from '..'
import { IssueStatusSelector } from './IssueStatusSelector'
interface ManageIssueStatusProps {
  issue: Issue
  task?: Task
}
export const ManageIssueStatus = ({ issue, task }: ManageIssueStatusProps) => {
  const { changeIssueStatus } = useChangeIssueStatus()
  const { invalidateTaskQueries } = useInvalidateTaskQueries()

  const handleIssueStateChange = (issueId: string, status: IssueStatus) => {
    changeIssueStatus(
      { issueId, status, taskName: task?.formName || '' },
      {
        onSuccess() {
          invalidateTaskQueries()
        },
      }
    )
  }
  return (
    <IssueStatusSelector
      key={issue._id}
      onIssueStatusChange={status => handleIssueStateChange(issue._id, status)}
      status={issue.status}
    />
  )
}
