import { DirtyIssue } from '@/features/issues'
import { useToggle } from '@/hooks/use-toggle'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { useFormikContext } from 'formik'
import { ReactNode } from 'react'
import {
  Entry,
  EntryCreateInput,
  EntryField,
  EntryFieldProps,
  useFileEntryHandler,
} from '@/features/entries'

export type CompleteTaskBulkEntryProps = Pick<EntryFieldProps, 'documentOptions'> & {
  prefixEntryName: string
  task: { id: string; formName?: string; entries: EntryCreateInput[]; issues: DirtyIssue[] }
  subtitle?: ReactNode
}

export const CompleteTaskBulkEntry = ({
  task,
  subtitle,
  prefixEntryName,
  ...props
}: CompleteTaskBulkEntryProps) => {
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const fileOptions = useFileEntryHandler({
    id: task.id,
    entries: task.entries as Entry[],
    issues: task.issues as { entries: Entry[] }[],
    setFieldValue,
    setFieldTouched,
  })

  const [collapsed, toggleCollapsed] = useToggle(false)

  return (
    <div>
      <div className='flex justify-between items-center'>
        <div className='flex items flex-nowrap pr-20 cursor-pointer' onClick={toggleCollapsed}>
          <span className='font-bold text-base leading-6 flex-grow' style={{ hyphens: 'auto' }}>
            {task.formName}
          </span>

          {!collapsed ? (
            <ChevronDownIcon className='h-6 w-6 ml-1 flex-shrink-0' aria-hidden='true' />
          ) : (
            <ChevronRightIcon className='h-6 w-6 ml-1 flex-shrink-0' aria-hidden='true' />
          )}
        </div>
      </div>
      {collapsed ? null : (
        <>
          <div className='mt-2'>{subtitle}</div>
          <div className='grid grid-cols-1 gap-6 mt-2 bg-gray-100 p-6 rounded-md'>
            {task.entries
              .map<[EntryCreateInput, number]>((entry, index) => [entry, index])
              .filter(([entry]) => !entry.blockId)
              .map(([entry, index]) => {
                return (
                  <EntryField
                    {...props}
                    key={`${prefixEntryName}.entries.${entry.id}`}
                    prefixName={prefixEntryName}
                    index={index}
                    fileOptions={fileOptions}
                  />
                )
              })}
          </div>
        </>
      )}
    </div>
  )
}
