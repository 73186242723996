import { useTranslation } from 'react-i18next'
import {
  Badge,
  ButtonGroup,
  COLOR,
  CallToAction,
  Loader,
  TabType,
  Table,
  Tabs,
  getButtonClassName,
} from '@blockchain-traceability-sl/tailwind-components'
import { PDF_MANUAL_EN_URL, PDF_MANUAL_ES_URL } from '@/app/constants'
import { useEffect, useMemo, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ClipboardCheckIcon as ClipboardCheckIconSolid, PencilIcon } from '@heroicons/react/solid'
import {
  CALENDAR_VIEW_TYPE,
  TaskCalendar,
  TaskDetails,
  TaskFiltersState,
  TaskListFilters,
  TaskManager,
  TaskStatus,
  useMyDepartmentTasksCount,
  useMyTasksCount,
} from '@/features/tasks'
import {
  ArchiveIcon,
  BadgeCheckIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  ClipboardCheckIcon,
  ExclamationIcon,
  InboxInIcon,
  XCircleIcon,
} from '@heroicons/react/outline'
import { useUserPermissions } from '@/features/auth'
import { useAnalytics } from '@/features/analytics'
import { usePrevious } from '@/hooks/use-previous'
import { useTemplatesForTasks } from '@/features/templates'
import { useOnboardingStep } from '@/features/onboarding'
import {
  ValidationRequestStatus,
  ValidationRequestType,
  usePendingToValidateValidationsRequestCount,
} from '@/features/validationRequests'

// Tabs
import { ValidationTasksTab } from './tabs/ValidationTasksTab'
import { TasksWithActiveIssuesTab, TasksWithResolvedIssuesTab } from './tabs/TasksWithIssuesTab'
import {
  ApprovedTasksTab,
  PendingTasksTab,
  PendingTodayTasksTab,
  RejectedTasksTab,
  TasksTab,
} from './tabs/TasksTab'
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts'
import { useIssueStatusGroups } from './hooks'
import {
  PERSISTED_TASK_CALENDAR_VIEW_TYPE_KEY,
  PERSISTED_TASK_FILTERS,
  PERSISTED_TASK_LIST_VIEW_TAB,
  PERSISTED_TASK_LIST_VIEW_TYPE_KEY,
} from './constants'
import dayjs from 'dayjs'
import { useDebounceState } from '@/hooks/use-debounce-state'
import { EmptyToday } from './tabs/EmptyToday'
import i18n from '@/i18n'

enum VIEW_TYPE {
  CALENDAR = 'calendar',
  LIST = 'list',
}

export enum TAB_INDEX {
  ALL_TASKS,
  PENDING_TASKS,
  PENDING_TODAY_TASKS,
  PENDING_TO_VALIDATE_TASKS,
  VALIDATED_TASKS,
  REJECTED_TASKS,
  TASKS_WITH_ISSUES,
  TASKS_WITH_RESOLVED_ISSUES,
}

export const TasksListPage = () => {
  const { t } = useTranslation('nsTasksListPage')

  useOnboardingStep('tasks')

  const history = useHistory()
  const analytics = useAnalytics()
  const userPermissions = useUserPermissions()

  const [currentTab, setCurrentTab] = useLocalStorage<TAB_INDEX>(
    PERSISTED_TASK_LIST_VIEW_TAB,
    TAB_INDEX.ALL_TASKS
  )
  const [viewType, setViewType] = useLocalStorage(PERSISTED_TASK_LIST_VIEW_TYPE_KEY, VIEW_TYPE.LIST)
  const currentTaskCalendarView = useReadLocalStorage<CALENDAR_VIEW_TYPE>(
    PERSISTED_TASK_CALENDAR_VIEW_TYPE_KEY
  )
  const { issueStatusGroups } = useIssueStatusGroups()

  const [taskFilters, setTaskFilters] = useLocalStorage<TaskFiltersState>(
    PERSISTED_TASK_FILTERS,
    {}
  )

  const [taskFiltersDebounced] = useDebounceState(taskFilters)

  const isFiltersActive = useMemo(
    (): boolean =>
      Object.values(taskFilters).some(filter =>
        Array.isArray(filter) ? filter.length > 0 : !!filter
      ),
    [taskFilters]
  )

  const { templates, isSuccess } = useTemplatesForTasks({ sortBy: 'name' })

  const { count: allTasksCount, isLoading } = useMyTasksCount(taskFilters)

  const { count: pendingTasksCount } = useMyTasksCount({
    ...taskFilters,
    status: [TaskStatus.OPEN],
  })

  const { count: pendingTodayTasksCount, isFetching: isPendingTodayTasksCountFetching } =
    useMyTasksCount({
      assignedToMe: true,
      status: [TaskStatus.OPEN],
      startDate: dayjs().startOf('day').toISOString(),
      endDate: dayjs().endOf('day').toISOString(),
      validationStatus: [
        'null',
        ValidationRequestStatus.APPROVED,
        ValidationRequestStatus.REJECTED,
      ],
    })

  const { count: pendingToValidateTasksCount } = usePendingToValidateValidationsRequestCount({
    types: [ValidationRequestType.TASK],
  })

  const { count: approvedTasksCount } = useMyTasksCount({
    ...taskFilters,
    validationStatus: [ValidationRequestStatus.APPROVED],
  })

  const { count: rejectedTasksCount } = useMyTasksCount({
    ...taskFilters,
    validationStatus: [ValidationRequestStatus.REJECTED],
  })

  const { count: tasksWithActiveIssuesCount } = useMyDepartmentTasksCount({
    ...taskFilters,
    issueStatus: issueStatusGroups.OPEN,
  })

  const { count: tasksWithResolvedIssuesCount } = useMyDepartmentTasksCount({
    ...taskFilters,
    issueStatus: issueStatusGroups.CLOSED,
  })

  const isFormsEmpty = isSuccess && templates.length === 0

  // Analytics
  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    if (!isLoading && isFormsEmpty) {
      analytics.track('NAVIGATION_VIEW_TASKS', { Tab: 'N/A', Status: 'Empty' })
    }
  }, [analytics, isFormsEmpty, isLoading])

  const firstUpdateTab = useRef(false)
  const previousViewType = usePrevious(viewType)
  const previousTab = usePrevious(currentTab)
  const previousCalendar = usePrevious(currentTaskCalendarView)

  useEffect(() => {
    if (isLoading || isPendingTodayTasksCountFetching) return
    if (
      previousViewType !== viewType ||
      previousTab !== currentTab ||
      previousCalendar !== currentTaskCalendarView
    ) {
      firstUpdateTab.current = false
    }

    if (!firstUpdateTab.current) {
      firstUpdateTab.current = true
    } else if (firstUpdateTab.current) {
      return
    }

    if (viewType === VIEW_TYPE.CALENDAR) {
      switch (currentTaskCalendarView) {
        case CALENDAR_VIEW_TYPE.WEEKLY:
          analytics.track('NAVIGATION_VIEW_TASKS', { Status: 'Calendar week' })
          break
        case CALENDAR_VIEW_TYPE.MONTHLY:
          analytics.track('NAVIGATION_VIEW_TASKS', { Status: 'Calendar month' })
          break
      }
    }

    if (viewType === VIEW_TYPE.LIST) {
      switch (currentTab) {
        case TAB_INDEX.ALL_TASKS:
          analytics.track('NAVIGATION_VIEW_TASKS', {
            Tab: 'All',
            Status: allTasksCount === 0 ? 'Empty' : 'List',
          })
          break
        case TAB_INDEX.PENDING_TASKS:
          analytics.track('NAVIGATION_VIEW_TASKS', {
            Tab: 'Pending',
            Status: pendingTasksCount === 0 ? 'Empty' : 'List',
          })
          break
        case TAB_INDEX.PENDING_TODAY_TASKS:
          analytics.track('NAVIGATION_VIEW_TASKS', {
            Tab: 'Pending to complete',
            Status: pendingTodayTasksCount === 0 ? 'Empty' : 'List',
          })
          break
        case TAB_INDEX.PENDING_TO_VALIDATE_TASKS:
          analytics.track('NAVIGATION_VIEW_TASKS', {
            Tab: 'Pending to validate',
            Status: pendingToValidateTasksCount === 0 ? 'Empty' : 'List',
          })
          break

        case TAB_INDEX.VALIDATED_TASKS:
          analytics.track('NAVIGATION_VIEW_TASKS', {
            Tab: 'Validated',
            Status: approvedTasksCount === 0 ? 'Empty' : 'List',
          })
          break

        case TAB_INDEX.TASKS_WITH_ISSUES:
          analytics.track('NAVIGATION_VIEW_TASKS', {
            Tab: 'Task with incident',
            Status: tasksWithActiveIssuesCount === 0 ? 'Empty' : 'List',
          })
          break

        case TAB_INDEX.TASKS_WITH_RESOLVED_ISSUES:
          analytics.track('NAVIGATION_VIEW_TASKS', {
            Tab: 'Resolved incidents',
            Status: tasksWithResolvedIssuesCount === 0 ? 'Empty' : 'List',
          })
          break
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentTab,
    allTasksCount,
    pendingTasksCount,
    pendingToValidateTasksCount,
    pendingTodayTasksCount,
    tasksWithActiveIssuesCount,
    approvedTasksCount,
    tasksWithResolvedIssuesCount,
    isLoading,
    viewType,
    currentTaskCalendarView,
  ])

  const countBadges = (
    <div className='flex gap-4'>
      {pendingTasksCount > 0 && (
        <Badge
          color={COLOR.RED}
          variant='round'
          className='cursor-pointer'
          onClick={() => setCurrentTab(TAB_INDEX.PENDING_TASKS)}
        >
          {t('badges.pending', { count: pendingTasksCount })}
        </Badge>
      )}
      {pendingToValidateTasksCount > 0 && (
        <Badge
          color={COLOR.RED}
          variant='round'
          className='cursor-pointer'
          onClick={() => setCurrentTab(TAB_INDEX.PENDING_TO_VALIDATE_TASKS)}
        >
          {t('badges.validationRequestsPending', { count: pendingToValidateTasksCount })}
        </Badge>
      )}
      {tasksWithActiveIssuesCount > 0 && (
        <Badge
          color={COLOR.RED}
          variant='round'
          className='cursor-pointer'
          onClick={() => setCurrentTab(TAB_INDEX.TASKS_WITH_ISSUES)}
        >
          {t('badges.issues', { count: tasksWithActiveIssuesCount })}
        </Badge>
      )}
    </div>
  )

  const tabs: TabType[] = [
    {
      title: t('tabs.all'),
      IconComponent: ClipboardCheckIconSolid,
      content: (
        <TasksTab
          taskFilters={taskFilters}
          isFiltersActive={isFiltersActive}
          leftMenu={countBadges}
        />
      ),
    },

    {
      title: (
        <span>
          {t('tabs.pending')}{' '}
          {pendingTasksCount > 0 && (
            <Badge color={COLOR.RED} className='ml-1.5'>
              {pendingTasksCount}
            </Badge>
          )}
        </span>
      ),
      IconComponent: PencilIcon,
      content: (
        <PendingTasksTab
          taskFilters={taskFilters}
          isFiltersActive={isFiltersActive}
          leftMenu={countBadges}
        />
      ),
    },

    {
      title: t('tabs.pendingComplete'),
      IconComponent: InboxInIcon,
      content: isPendingTodayTasksCountFetching ? (
        <Loader center='full' />
      ) : pendingTodayTasksCount === 0 ? (
        <EmptyToday />
      ) : (
        <PendingTodayTasksTab taskFilters={taskFilters} isFiltersActive={isFiltersActive} />
      ),
    },

    {
      title: (
        <span>
          {t('tabs.validationRequest')}{' '}
          {pendingToValidateTasksCount > 0 && (
            <Badge color={COLOR.RED} className='ml-1.5'>
              {pendingToValidateTasksCount}
            </Badge>
          )}
        </span>
      ),
      IconComponent: BadgeCheckIcon,
      content: (
        <ValidationTasksTab
          taskFilters={taskFilters}
          isFiltersActive={isFiltersActive}
          leftMenu={countBadges}
        />
      ),
    },

    {
      title: t('tabs.approved'),
      IconComponent: ArchiveIcon,
      content: (
        <ApprovedTasksTab
          taskFilters={taskFilters}
          isFiltersActive={isFiltersActive}
          leftMenu={countBadges}
        />
      ),
    },

    {
      title: (
        <span>
          {t('tabs.rejected')}{' '}
          {rejectedTasksCount > 0 && (
            <Badge color={COLOR.RED} className='ml-1.5'>
              {rejectedTasksCount}
            </Badge>
          )}
        </span>
      ),
      IconComponent: XCircleIcon,
      content: (
        <RejectedTasksTab
          taskFilters={taskFilters}
          isFiltersActive={isFiltersActive}
          leftMenu={countBadges}
        />
      ),
    },

    {
      title: (
        <span>
          {t('tabs.issues')}{' '}
          {tasksWithActiveIssuesCount > 0 && (
            <Badge color={COLOR.RED} className='ml-1.5'>
              {tasksWithActiveIssuesCount}
            </Badge>
          )}
        </span>
      ),
      IconComponent: ExclamationIcon,
      content: <TasksWithActiveIssuesTab taskFilters={taskFilters} leftMenu={countBadges} />,
    },

    {
      title: (
        <span>
          {t('tabs.issuesResolved')}{' '}
          {tasksWithResolvedIssuesCount > 0 && (
            <Badge color={COLOR.RED} className='ml-1.5'>
              {tasksWithResolvedIssuesCount}
            </Badge>
          )}
        </span>
      ),
      IconComponent: CheckIcon,
      content: <TasksWithResolvedIssuesTab taskFilters={taskFilters} leftMenu={countBadges} />,
    },
  ]

  useEffect(() => {
    if (allTasksCount === 0 && isFiltersActive) {
      analytics.track('NAVIGATION_VIEW_FILTER_NO_RESULTS')
    }
  }, [analytics, isFiltersActive, allTasksCount])

  // Manage taskPendingToday tab selection with selected filters
  useEffect(() => {
    if (currentTab === TAB_INDEX.PENDING_TODAY_TASKS) {
      const todayFilters = {
        startDate: dayjs().startOf('day').toISOString(),
        endDate: dayjs().endOf('day').toISOString(),
      }

      setTaskFilters({
        ...taskFilters,
        ...todayFilters,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  useEffect(() => {
    // If filters are not the same as the ones in the tab, change tab to all tasks
    if (
      currentTab === TAB_INDEX.PENDING_TODAY_TASKS &&
      (taskFiltersDebounced.startDate !== dayjs().startOf('day').toISOString() ||
        taskFiltersDebounced.endDate !== dayjs().endOf('day').toISOString())
    ) {
      setCurrentTab(TAB_INDEX.ALL_TASKS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskFiltersDebounced])

  // Reset due date filters on change tab from pendingTodayTasks
  useEffect(() => {
    if (
      previousTab === TAB_INDEX.PENDING_TODAY_TASKS &&
      currentTab !== TAB_INDEX.PENDING_TODAY_TASKS &&
      taskFilters.startDate === dayjs().startOf('day').toISOString() &&
      taskFilters.endDate === dayjs().endOf('day').toISOString()
    ) {
      setTaskFilters({
        ...taskFilters,
        startDate: undefined,
        endDate: undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  const language = i18n.language

  const userManualUrl = useMemo(() => {
    if (language === 'es') {
      return PDF_MANUAL_ES_URL
    }
    return PDF_MANUAL_EN_URL
  }, [language])

  const writeActionsDisabled = !userPermissions.tasks.write

  const viewSelectField = (
    <div className='mt-1'>
      <ButtonGroup data-testid='task-list-view-select'>
        <ButtonGroup.Button
          active={viewType === VIEW_TYPE.LIST}
          onClick={() => setViewType(VIEW_TYPE.LIST)}
        >
          <span>
            <ClipboardCheckIcon className='h-5 w-5' />
          </span>
        </ButtonGroup.Button>
        <ButtonGroup.Button
          active={viewType === VIEW_TYPE.CALENDAR}
          onClick={() => setViewType(VIEW_TYPE.CALENDAR)}
        >
          <span>
            <CalendarIcon className='h-5 w-5' />
          </span>
        </ButtonGroup.Button>
      </ButtonGroup>
    </div>
  )

  return (
    <TaskManager activeTab={currentTab}>
      <div className='py-6'>
        <div className='max-w-9xl mx-auto px-4 sm:px-6 md:px-8 flex md:justify-between flex-col md:flex-row space-y-2 md:space-y-0'>
          <h1 className='text-2xl font-semibold text-gray-900'>{t('header')}</h1>
          <Link
            to='/tasks/create'
            className={getButtonClassName({
              disabled: writeActionsDisabled,
            })}
            onClick={event => {
              if (writeActionsDisabled) return event.preventDefault()
              analytics.track('ACTION_CLICK_CREATE_TASK', {
                From: 'Top bar',
              })
            }}
          >
            {t('createNew')}
          </Link>
        </div>

        <div className='px-4 sm:px-6 md:px-8 mt-12'>
          <TaskListFilters
            filters={taskFilters}
            isCalendarView={viewType === VIEW_TYPE.CALENDAR}
            onFiltersChange={setTaskFilters}
          />
        </div>

        <div className='max-w-9xl mx-auto px-4 sm:px-6 md:px-8 mt-6'>
          {isFormsEmpty ? (
            <CallToAction
              title={t('callToAction.title')}
              subtitle={t('callToAction.subtitle')}
              subtitleClassName='text-lg font-normal'
              primaryActionText={t('callToAction.action')}
              primaryActionDisabled={!userPermissions.forms.write}
              onPrimaryActionClick={() => {
                analytics.track('ACTION_CLICK_CREATE_FORM')
                history.push('/forms/create')
              }}
              secondaryActionText={t('callToAction.needHelp')}
              onSecondaryActionClick={() => {
                analytics.track('ACTION_CLICK_HELP', { Source: 'Tasks section' })
                window.open(userManualUrl, '_blank')
              }}
              example={
                <Table
                  className='mt-10 text-left'
                  pagination={false}
                  columns={[
                    {
                      Header: t(`table.name`).toString(),
                      accessor: 'name',
                    },
                    {
                      Header: t(`table.details`).toString(),
                      accessor: 'details',
                    },
                    {
                      Header: t(`table.status`).toString(),
                      accessor: 'status',
                    },
                    {
                      Header: t(`table.dueDate`).toString(),
                      accessor: 'dueDate',
                    },
                  ]}
                  data={[...Array(3)].map((_, index) => ({
                    id: index.toString(),
                    name: t(`callToAction.examples.${index}.name`),
                    details: (
                      <TaskDetails assignedTo={t(`callToAction.examples.${index}.assignedTo`)} />
                    ),
                    status:
                      index !== 2 ? (
                        <Badge color={COLOR.GREEN}>
                          {t(`callToAction.examples.${index}.status`)}
                        </Badge>
                      ) : (
                        <Badge color={COLOR.RED}>
                          {t(`callToAction.examples.${index}.status`)}
                        </Badge>
                      ),
                    dueDate: t(`callToAction.examples.${index}.dueDate`),
                  }))}
                />
              }
            />
          ) : (
            <div className='flex flex-col'>
              {viewType === VIEW_TYPE.LIST && (
                <Tabs
                  tabs={tabs}
                  onTab={setCurrentTab}
                  defaultIndex={currentTab}
                  currentTab={currentTab}
                >
                  <div className='gap-7 flex' data-testid='task-filter-selector'>
                    <Tabs.SelectorMenu
                      className='flex-grow'
                      SelectorIconComponent={ChevronDownIcon}
                    />
                    <div>{viewSelectField}</div>
                  </div>

                  <Tabs.Content className='mt-6' />
                </Tabs>
              )}
              {viewType === VIEW_TYPE.CALENDAR && (
                <>
                  <div className='mb-4 flex flex-row-reverse'>{viewSelectField}</div>
                  <TaskCalendar taskFilters={taskFilters} />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </TaskManager>
  )
}
