import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  SelectField,
  SelectFieldProps,
  SelectorOption,
  createSelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { useState } from 'react'
import { usePaginatedReceptions, useReceptionById } from '../hooks'
import { ReceptionFilters } from '../interfaces'

export const ReceptionsSelectField = ({
  stakeHolderId,
  stakeHolderProductId,
  receptionValueId,
  ...props
}: Omit<SelectFieldProps, 'options'> &
  Omit<ReceptionFilters, 'search'> &
  Pick<{ receptionValueId?: string }, 'receptionValueId'>) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const {
    isLoading: receptionsIsLoading,
    receptions,
    fetchNextPage,
  } = usePaginatedReceptions({
    filters: {
      search: debouncedSearch,
      stakeHolderId,
      stakeHolderProductId,
    },
  })

  const { reception: receptionValue, isLoading: receptionValueIsLoading } = useReceptionById(
    receptionValueId || '',
    {
      enabled: !!receptionValueId,
    }
  )

  const isLoading = receptionsIsLoading || receptionValueIsLoading

  const value = receptionValue
    ? createSelectorOption(receptionValue.reference, receptionValue._id)
    : props.value

  const receptionsOptions: SelectorOption[] = receptions.map(reception => ({
    label: reception.reference,
    value: reception._id,
  }))

  return (
    <SelectField
      {...props}
      value={value}
      isLoading={isLoading}
      options={receptionsOptions}
      onInputChange={inputValue => {
        setSearch(inputValue)
      }}
      onMenuScrollToBottom={() => {
        fetchNextPage()
      }}
    />
  )
}
