import { Traceability } from '@/features/traceabilities'

export interface TraceabilityWithLevel {
  _id: string
  level: string
  truId: string | null
  from: string | undefined
  to: string | undefined
}

export const traceabilityToLevelsOrder = (traceability: Traceability): TraceabilityWithLevel[] => {
  return (
    [
      // Leveles by truEdges data
      ...traceability.truEdges.map(truEdge => ({
        _id:
          traceability.nodes
            .flatMap(({ trus }) =>
              trus.flatMap(({ shipments }) => (Array.isArray(shipments) ? shipments : []))
            )
            .find(
              shipment =>
                shipment.truId === truEdge.from &&
                shipment.to ===
                  traceability.nodes.find(node => node.trus.some(({ _id }) => _id === truEdge.to))
                    ?._id
            )?._id || truEdge.from,
        level: traceability.nodes
          .find(node => node.trus.some(({ _id }) => _id === truEdge.from))
          ?.level.toString(),
        truId: truEdge.from || null,
        from: traceability.nodes.find(node => node.trus.some(({ _id }) => _id === truEdge.from))
          ?._id,
        to: traceability.nodes.find(node => node.trus.some(({ _id }) => _id === truEdge.to))?._id,
      })),
      // Levels by traceability nodes data
      ...traceability.nodes.flatMap(({ _id, level, trus, receptions }) => [
        // Levels by traceability nodes tru shipments
        ...trus.flatMap(tru =>
          (Array.isArray(tru.shipments) ? tru.shipments : [])
            .filter(
              shipment => !traceability.truEdges.some(truEdge => truEdge.from === shipment.truId)
            )
            .map(shipment => ({
              _id: shipment._id,
              level: String(level),
              truId: tru._id,
              from: shipment.from,
              to: shipment.to,
            }))
        ),
        // Levels by traceability nodes receptions
        ...(Array.isArray(receptions) ? receptions : []).map(reception => ({
          _id: reception._id,
          level: String(level),
          truId: null,
          from: _id,
          to: traceability.edges.find(({ from }) => from === _id)?.to,
        })),
      ]),
    ]
      // Remove uncompleted items
      .filter(
        (traceabilityWithLevel): traceabilityWithLevel is TraceabilityWithLevel =>
          traceabilityWithLevel.level !== undefined
      )
      // Sort by level
      .sort((a, b) => Number(a.level) - Number(b.level))
  )
}
