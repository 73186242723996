export enum StakeHolderType {
  PROVIDER = 'provider',
  CLIENT = 'client',
  /**
   *  `both` is the union of provider and client. An stakeholder can be client and provider at the same time.
   *  Next time, we could migrate `both` to `provider_client`
   * */
  BOTH = 'both',

  CONTACT = 'contact',
}

export interface StakeHolder {
  _id: string

  createdBy: {
    _id: string
    reference: string
    email: string
  }
  company: {
    id: string | null
    countryCode: string
    reference: string
    name: string
    website?: string
    /**
     * Phone number in formatted style
     */
    phoneNumber?: string
    localization: {
      address?: string
    }
    email?: string
    secondaryEmails?: string[]
    tags: string[]
  }

  type: StakeHolderType
  createdAt: string
  updatedAt: string
}

export interface StakeHolderCreation {
  countryCode?: string
  reference?: string
  name: string
  website?: string
  address?: string
  email?: string
  phoneNumber?: string
  tags?: string[]
  secondaryEmails?: string[]
  type: StakeHolderType
}

export interface StakeHolderUpdate {
  countryCode?: string
  reference?: string
  name?: string
  companyId?: string
  website?: string
  address?: string
  email?: string
  phoneNumber?: string
  secondaryEmails?: string[]
  tags?: string[]
  type?: StakeHolderType
}

export interface StakeholderRequirementEmail {
  to: string[]
  subject: string
  contentRichText?: string
  content: string
  metadata: {
    stakeHolderProductName: string
    companyName: string
  }
  attachments: { fileName: string; path: string; mimeType: string }[]
}
