import { PDF_MANUAL_EN_URL, PDF_MANUAL_ES_URL } from '@/app/constants'
import { useAnalytics } from '@/features/analytics'
import i18n from '@/i18n'
import { Button, CallToAction, Table } from '@blockchain-traceability-sl/tailwind-components'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export const ShipmentCallToAction = () => {
  const { t } = useTranslation('nsPageShipmentsList')
  const analytics = useAnalytics()
  const history = useHistory()

  const language = i18n.language

  const userManualUrl = useMemo(() => {
    if (language === 'es') {
      return PDF_MANUAL_ES_URL
    }
    return PDF_MANUAL_EN_URL
  }, [language])

  return (
    <CallToAction
      title={t('callToAction.title')}
      subtitle={t('callToAction.subtitle')}
      subtitleClassName='text-lg leading-7 font-normal'
      primaryActionText={t('callToAction.action')}
      onPrimaryActionClick={() => {
        analytics.track('ACTION_CLICK_CREATE_SHIPMENT')
        history.push('/trus/shipment')
      }}
      secondaryActionText={t('callToAction.needHelp')}
      onSecondaryActionClick={() => {
        analytics.track('ACTION_CLICK_HELP', {
          Source: 'Shipment section',
        })
        window.open(userManualUrl, '_blank')
      }}
      example={
        <Table
          className='min-w-full text-left'
          pagination={false}
          columns={[
            {
              Header: t(`table.tru`).toString(),
              accessor: 'tru',
            },
            {
              Header: t(`table.product`).toString(),
              accessor: 'product',
            },
            {
              Header: t(`table.customer`).toString(),
              accessor: 'customer',
            },
            {
              Header: t(`table.date`).toString(),
              accessor: 'date',
            },
            {
              Header: t(`table.quantity`).toString(),
              accessor: 'quantity',
            },
            {
              Header: t(`table.actions`).toString(),
              accessor: 'actions',
            },
          ]}
          data={[...Array(3)].map((_, index) => ({
            id: index.toString(),
            tru: t(`callToAction.examples.${index}.tru`),
            product: t(`callToAction.examples.${index}.product`),
            customer: t(`callToAction.examples.${index}.customer`),
            date: t(`callToAction.examples.${index}.date`),
            quantity: t(`callToAction.examples.${index}.quantity`),
            actions: (
              <Button color='link' disabled>
                {t('table.action.cell')}
              </Button>
            ),
          }))}
        />
      }
    />
  )
}
