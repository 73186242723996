import {
  DEFAULT_PAGE_SIZE,
  PDF_MANUAL_EN_URL,
  PDF_MANUAL_ES_URL,
  TABLE_SIZE_PRESETS,
} from '@/app/constants'
import { useAnalytics } from '@/features/analytics'
import { useIsUserStockEnabled } from '@/features/auth'
import { getProductNameWithReference, useProductsByIds } from '@/features/products'
import { useStakeHolderByIds } from '@/features/stakeHolders'
import { TRU, useTRUsByIds } from '@/features/trus'
import { useDebounceState } from '@/hooks/use-debounce-state'
import { noop } from '@/utils/noop'
import { Button, RowType, SIZE, Table } from '@blockchain-traceability-sl/tailwind-components'
import { memo, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'react-table'
import { usePaginatedShipments } from '../hooks'
import { Shipment, ShipmentFilters } from '../interfaces'
import { useLocalStorage } from '@/hooks/use-local-storage'
import i18n from '@/i18n'

export interface ShipmentsTableProps {
  filters: ShipmentFilters
  /**
   * Must be memoized to avoid extra renders
   */
  onRowClick?: (
    shipment: Shipment & {
      truReference: string
    }
  ) => void
  rowActionsDisabled?: boolean
}

/**
 * Page size key for local storage
 * @see useLocalStorage
 * @internal
 * @constant
 * @nanoid length 10
 */
const PAGE_SIZE_KEY = 'oz20ezLxJx'

export const ShipmentsTable = memo(
  ({ onRowClick = noop, rowActionsDisabled, filters }: ShipmentsTableProps) => {
    const { t } = useTranslation('nsPageShipmentsList')
    const isUserStockEnabled = useIsUserStockEnabled()
    const analytics = useAnalytics()

    const [pageIndex, setPageIndex] = useState<number>(0)
    const [pageSize, setPageSize] = useLocalStorage(PAGE_SIZE_KEY, DEFAULT_PAGE_SIZE)

    const columns = useMemo(
      () => [
        {
          Header: t(`table.tru`).toString(),
          accessor: 'tru',
        },
        {
          Header: t(`table.product`).toString(),
          accessor: 'product',
        },
        {
          Header: t(`table.customer`).toString(),
          accessor: 'customer',
        },
        {
          Header: t(`table.date`).toString(),
          accessor: 'date',
        },
        ...(isUserStockEnabled
          ? [
              {
                Header: t(`table.quantity`).toString(),
                accessor: 'quantity',
              },
            ]
          : []),
        {
          Header: t('table.actions').toString(),
          accessor: 'actions',
          disableGlobalFilter: true,
        },
      ],
      [t, isUserStockEnabled]
    )

    const {
      shipments,
      totalCount,
      fetchNextPage,
      fetchPreviousPage,
      data,
      isLoading: isShipmentsLoading,
      isFetchingNextPage,
    } = usePaginatedShipments({ filters, pageSize })

    const { stakeHolders, isLoading: isStakeHoldersLoading } = useStakeHolderByIds(
      (data?.pages[pageIndex]?.items || [])
        // Extract paginated shipment stakeholder targets
        .map(({ to }) => to)
        // Remove repeated
        .filter((to, index, self) => index === self.findIndex(selfItem => selfItem === to))
    )

    const { trus, isLoading: isTrusLoading } = useTRUsByIds(
      (data?.pages[pageIndex]?.items || [])
        // Extract paginated shipment truIds
        .map(({ truId }) => truId)
        // Remove repeated
        .filter((truId, index, self) => index === self.findIndex(selfItem => selfItem === truId))
    )

    const { products, isLoading: isProductsLoading } = useProductsByIds(
      trus
        // Extract paginated shipment tru products
        .map(({ productId }) => productId)
        // Remove repeated
        .filter(
          (productId, index, self) => index === self.findIndex(selfItem => selfItem === productId)
        )
    )

    const language = i18n.language

    const userManualUrl = useMemo(() => {
      if (language === 'es') {
        return PDF_MANUAL_ES_URL
      }
      return PDF_MANUAL_EN_URL
    }, [language])

    const handlePageChange = useCallback(
      (page: { pageIndex: number; pageSize: number }) => {
        if (pageIndex < page.pageIndex) {
          fetchNextPage()
        } else if (pageIndex > page.pageIndex) {
          fetchPreviousPage()
        }
        setPageIndex(page.pageIndex)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [fetchNextPage, fetchPreviousPage]
    )

    const rows = useMemo(
      () =>
        (data?.pages[pageIndex]?.items || []).map(shipment => {
          const shipmentTarget = stakeHolders.find(({ _id }) => _id === shipment.to)
          const shipmentTru = trus.find(({ _id }) => _id === shipment.truId)
          const shipmentProduct = products.find(({ _id }) => _id === shipmentTru?.productId)

          return {
            ...shipment,
            id: shipment._id,
            tru: shipmentTru?.reference || '',
            product: (shipmentProduct && getProductNameWithReference(shipmentProduct)) || '',
            customer: shipmentTarget?.company.name || '',
            date: t('table.dateValue', { date: new Date(shipment.shipmentDate) }),
            quantity: shipment.quantity
              ? `${t('number', {
                  value: shipment.quantity,
                })} ${shipmentProduct?.measurementUnit || ''}`.trim()
              : '',
            actions: (
              <Button color='link' disabled={rowActionsDisabled}>
                {t('table.action.cell')}
              </Button>
            ),
          }
        }),
      [data?.pages, pageIndex, products, rowActionsDisabled, stakeHolders, t, trus]
    )

    const [isLoadingDebounced] = useDebounceState(
      isShipmentsLoading ||
        isFetchingNextPage ||
        isStakeHoldersLoading ||
        isTrusLoading ||
        isProductsLoading
    )

    const handleRowClick = (row: Row<RowType>) => {
      const shipmentSelected = shipments.find(({ _id }) => _id === row.original.id) as Shipment
      onRowClick({
        ...shipmentSelected,
        truReference: (trus.find(({ _id }) => _id === shipmentSelected.truId) as TRU)?.reference,
      })
    }

    const isLoading =
      isShipmentsLoading ||
      isFetchingNextPage ||
      isStakeHoldersLoading ||
      isTrusLoading ||
      isProductsLoading ||
      isLoadingDebounced

    return (
      <Table
        manualPagination
        onPageChange={handlePageChange}
        onPageSizeChange={setPageSize}
        totalCount={totalCount}
        pageSize={pageSize}
        defaultPageIndex={pageIndex}
        isLoading={isLoading}
        columns={columns}
        data={rows}
        onRowClick={handleRowClick}
        previousText={t('pagination.previous')}
        nextText={t('pagination.next')}
        renderInfoText={(from, to, total) => (
          <Trans
            t={t}
            i18nKey='pagination.show'
            components={{ b: <span className='font-semibold' /> }}
            values={{ from, to, total }}
          />
        )}
        sizePresetsText={t('pagination.items')}
        showSizePresets
        sizePresets={TABLE_SIZE_PRESETS}
        noDataText={
          <tr>
            <td colSpan={isUserStockEnabled ? 6 : 5}>
              <div className='max-w-7xl m-auto text-center p-4 sm:p-6 lg:p-8'>
                <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
                  <span className='block'>{t('table.notFound.title')}</span>
                  <span className='block'>{t('table.notFound.subTitle')}</span>
                </h2>
                <div className='mt-8 flex justify-center space-x-3'>
                  <Button
                    color='secondary'
                    size={SIZE.EXTRA_LARGE}
                    className='m-auto'
                    onClick={() => {
                      analytics.track('ACTION_CLICK_HELP', {
                        Source: 'Shipments section',
                      })
                      window.open(userManualUrl, '_blank')
                    }}
                  >
                    {t('needHelp')}
                  </Button>
                </div>
              </div>
            </td>
          </tr>
        }
      />
    )
  }
)
