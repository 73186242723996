import { getPublicUrlTaskFile } from '../service'
import { Company } from '@/features/company'
import { Automation, useAutomations } from '@/features/automations'
import { ReadableUser, useUsersWithOwner } from '@/features/users'
import { useCompanyUser } from '@/features/auth'
import { FormikErrors } from 'formik'
import { EntryCreateInput } from '@/features/entries'
import { DirtyIssue } from '@/features/issues'

export const getAssignedCreatorTaskName = (
  idToCompare: string,
  {
    company,
    users,
    automations,
    defaultValue = 'N/A',
  }: {
    company: Company
    users: ReadableUser[]
    automations?: Automation[]
    defaultValue?: string
  }
) => {
  if (idToCompare === company._id) {
    return company.name
  }

  if (automations) {
    const automation = automations.find(({ _id }) => _id === idToCompare)
    if (automation) {
      return automation.name
    }
  }

  const user = users.find(({ _id }) => _id === idToCompare)

  if (!user) {
    return defaultValue
  }

  return user.name || user.auth.email
}

export const useGetAssignedCreatorTaskName = (options?: {
  /**
   * Enable search creator name in automations
   * @default false
   */
  enableAutomations?: boolean
}) => {
  const { users } = useUsersWithOwner()
  const company = useCompanyUser()
  const { automations } = useAutomations({ enabled: !!options?.enableAutomations })

  const getAssignedCreatorTaskName = (idToCompare: string, defaultValue = 'N/A') => {
    if (idToCompare === company._id) {
      return company.name
    }

    if (options?.enableAutomations) {
      const automation = automations.find(({ _id }) => _id === idToCompare)
      if (automation) {
        return automation.name
      }
    }

    const user = users.find(({ _id }) => _id === idToCompare)

    if (!user) {
      return defaultValue
    }

    return user.name || user.auth.email
  }

  return { getAssignedCreatorTaskName }
}

export const openTaskFile = async (filename: string) => {
  const publicUrl = await getPublicUrlTaskFile(filename)
  if (publicUrl) {
    window.open(publicUrl, '_blank')
  }
}

/**
 * Scroll to first entry error or first issue entry error
 * @param entryErrors Entry errors
 * @param issueErrors Issue errors
 * @param prefix Prefix of form key
 * @param wrapper Wrapper id
 * @returns True if scroll to error success
 */
export const scrollToEntryError = (
  entryErrors?: FormikErrors<EntryCreateInput>[],
  issueErrors?: FormikErrors<DirtyIssue>[],
  { prefix, wrapper }: { prefix?: string; wrapper?: string } = {}
): boolean => {
  const scrolled = false
  const prefixName = prefix ? `${prefix}_` : ''
  const wrapperName = wrapper ?? ''

  try {
    if (entryErrors) {
      // Find any entry has error
      const entryFailIndexes: string[] = Object.entries(entryErrors)
        // Filter only entry with error
        .filter(([, entryError]) => typeof entryError === 'object')
        // Get entry index
        .map(([index]) => index)

      // Check if any entry has error
      const someEntryBeScrolled = entryFailIndexes.some(entryFailIndex => {
        // Scroll in to entry
        const querySelector = `
        ${wrapperName} [id$=${`${prefixName}entries_${entryFailIndex}_value`}],
        ${wrapperName} [id$=${`${prefixName}entries_${entryFailIndex}_values_0`}],
        ${wrapperName} [id$=${`${prefixName}entries_${entryFailIndex}_values`}],
        ${wrapperName} label[for$=${`${prefixName}entries_${entryFailIndex}_value`}]
      `

        const entryElement = document.querySelector(querySelector)

        // Scroll in to entry if found
        if (entryElement) {
          entryElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
          return true
        }

        return false
      })

      if (someEntryBeScrolled) {
        return true
      }
    }

    if (issueErrors) {
      // Find any issue has error
      const issueFailIndexes: string[] = Object.entries(issueErrors)
        // Filter only issue with error
        .filter(([, issueError]) => typeof issueError === 'object')
        // Get issue index
        .map(([index]) => index)

      // Check if any issue has error
      const someIssueBeScrolled = issueFailIndexes.some(issueFailIndex => {
        const issueEntryErrors = issueErrors[Number(issueFailIndex)]
          .entries as FormikErrors<EntryCreateInput>[]

        if (issueErrors[Number(issueFailIndex)].entries) {
          // Find any entry has error
          const entryFailIndexes: string[] = Object.entries(issueEntryErrors)
            // Filter only entry with error
            .filter(([, entryError]) => typeof entryError === 'object')
            // Get entry index
            .map(([index]) => index)

          // Check if any entry has error
          const someEntryBeScrolled = entryFailIndexes.some(entryFailIndex => {
            // Scroll in to entry
            const querySelector = `
            ${wrapperName} [id$=${`${prefixName}entries_${entryFailIndex}_value`}],
            ${wrapperName} [id$=${`${prefixName}entries_${entryFailIndex}_values_0`}],
            ${wrapperName} label[for$=${`${prefixName}entries_${entryFailIndex}_value`}]
          `

            const entryElement = document.querySelector(querySelector)

            // Scroll in to entry if found
            if (entryElement) {
              entryElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
              return true
            }

            return false
          })

          if (someEntryBeScrolled) {
            return true
          }
        }

        return false
      })

      if (someIssueBeScrolled) {
        return true
      }
    }
  } catch {
    return false
  }

  return scrolled
}

export const scrollToTasksEntryError = (
  taskErrors?: FormikErrors<{
    entries: EntryCreateInput[]
    issues: DirtyIssue[]
  }>[]
) => {
  try {
    // Find first task has error
    const taskFailIndex = taskErrors?.findIndex(value => typeof value === 'object')

    if (!!taskErrors && taskFailIndex !== undefined && taskFailIndex !== -1) {
      // Find any entry has error
      const entryFailIndex = (
        taskErrors[taskFailIndex]?.entries as FormikErrors<EntryCreateInput>[]
      )?.findIndex(value => typeof value === 'object')
      // Check if any entry has error
      if (entryFailIndex !== undefined && entryFailIndex !== -1) {
        // Scroll in to entry
        const querySelector = `[id$=tasks_${taskFailIndex}_entries_${entryFailIndex}_value]`
        document
          .querySelector(querySelector)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      } else {
        // Find any issue has error
        const issueFailIndex = (
          taskErrors[taskFailIndex]?.issues as FormikErrors<DirtyIssue>[]
        )?.findIndex(value => typeof value === 'object')
        if (
          taskErrors &&
          taskErrors[taskFailIndex]?.issues &&
          issueFailIndex !== undefined &&
          issueFailIndex !== -1
        ) {
          // Find any issue entry has error
          const issueEntryFailIndex = (
            (taskErrors[taskFailIndex].issues as FormikErrors<DirtyIssue>[])[issueFailIndex]
              .entries as FormikErrors<EntryCreateInput>[]
          )?.findIndex(value => typeof value === 'object')
          // Check if any issue entry has error
          if (issueEntryFailIndex !== undefined && issueEntryFailIndex !== -1) {
            // Scroll in to issue entry
            const querySelector = `[id$=tasks_${taskFailIndex}_issues_${issueFailIndex}_entries_${issueEntryFailIndex}_value]`
            document
              .querySelector(querySelector)
              ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        }
      }
    }
  } catch {}
}
