import { Task, TaskReportSent, TaskStatus } from '../../../interfaces'
import { Audit, AuditOperation, AuditTaskRelated } from '@/features/audits'
import { ValidationRequest, ValidationRequestStatus } from '@/features/validationRequests'
import { ACTIVITY_TYPE } from '../../../components/ModalTaskActivity/entity'

export interface TaskActivity {
  previousAudit?: AuditTaskRelated | undefined
  audit: AuditTaskRelated
  type: ACTIVITY_TYPE
}

export const formatAuditToActivities = (
  audit: Audit<Task | ValidationRequest | TaskReportSent>,
  index: number,
  self: Audit<Task | ValidationRequest | TaskReportSent>[]
): TaskActivity[] => {
  const activities: TaskActivity[] = []

  const previousAudit = self.filter(({ microserviceTrigger }) => microserviceTrigger === 'tasks')[
    index - 1
  ] as Audit<Task, AuditOperation> | undefined

  if (audit.collection === 'tasks') {
    const auditData: Task = audit.data as Task

    // On Create
    if (audit.operation === AuditOperation.CREATE) {
      // Create event
      activities.push({
        audit,
        previousAudit,
        type: ACTIVITY_TYPE.TASK_CREATION,
      })

      // Assign event
      if (auditData.assignedTo !== auditData.companyId) {
        activities.push({
          audit,
          previousAudit,
          type: ACTIVITY_TYPE.TASK_ASSIGN,
        })
      }

      // Close event
      if (auditData.status === TaskStatus.CLOSED) {
        activities.push({
          audit,
          previousAudit,
          type: ACTIVITY_TYPE.TASK_CLOSE,
        })
      }
    }

    // On Update
    if (audit.operation === AuditOperation.UPDATE) {
      const selfTaskAudits = self.filter(
        ({ microserviceTrigger }) => microserviceTrigger === 'tasks'
      )
      const previousAudit = selfTaskAudits[
        selfTaskAudits.findIndex(({ _id }) => _id === audit._id) - 1
      ] as Audit<Task, AuditOperation> | undefined

      if (previousAudit) {
        // Assign event
        if (previousAudit.data.assignedTo !== auditData.assignedTo) {
          activities.push({
            audit,
            previousAudit,
            type: ACTIVITY_TYPE.TASK_ASSIGN,
          })
        }

        // Close event
        if (
          previousAudit.data.status === TaskStatus.OPEN &&
          auditData.status === TaskStatus.CLOSED
        ) {
          activities.push({
            audit,
            previousAudit,
            type: ACTIVITY_TYPE.TASK_CLOSE,
          })
        }

        // Open event
        if (
          previousAudit.data.status === TaskStatus.CLOSED &&
          auditData.status === TaskStatus.OPEN
        ) {
          activities.push({
            audit,
            previousAudit,
            type: ACTIVITY_TYPE.TASK_OPEN,
          })
        }

        if (JSON.stringify(previousAudit.data.entries) !== JSON.stringify(auditData.entries)) {
          activities.push({
            audit,
            previousAudit,
            type: ACTIVITY_TYPE.TASK_UPDATED,
          })
        }
      }
    }
  }

  if (audit.collection === 'validations') {
    const auditData = audit.data as ValidationRequest

    if (auditData.status === ValidationRequestStatus.PENDING) {
      activities.push({
        audit,
        previousAudit,
        type: ACTIVITY_TYPE.VALIDATION_CREATED,
      })
    }

    if (auditData.status === ValidationRequestStatus.APPROVED) {
      activities.push({
        audit,
        previousAudit,
        type: ACTIVITY_TYPE.VALIDATION_ACCEPT,
      })
    }

    if (auditData.status === ValidationRequestStatus.REJECTED) {
      activities.push({
        audit,
        previousAudit,
        type: ACTIVITY_TYPE.VALIDATION_REJECT,
      })
    }
  }

  if (audit.collection === 'taskReportShipment') {
    // On Create
    if (audit.operation === AuditOperation.CREATE) {
      // Create event
      activities.push({
        audit,
        previousAudit,
        type: ACTIVITY_TYPE.TASK_REPORT_SENT,
      })
    }
  }

  return activities
}
