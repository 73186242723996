import { DateRangeLocaleField } from '@/components/DateRangeLocaleField'
import { getProductNameWithReference, useProducts } from '@/features/products'
import { StakeHolderType, useStakeHoldersByTypes } from '@/features/stakeHolders'
import { useQueryTRUs } from '@/features/trus'
import { usePrevious } from '@/hooks/use-previous'
import { Card, SelectField } from '@blockchain-traceability-sl/tailwind-components'
import { TrashIcon } from '@heroicons/react/solid'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ShipmentFilters } from '../interfaces'

type ShipmentFiltersState = Required<
  Pick<ShipmentFilters, 'truIds' | 'tos' | 'startDate' | 'endDate' | 'productIds'>
>

export const ShipmentListFilters = ({
  onFiltersChange,
}: {
  onFiltersChange: (filters: ShipmentFiltersState) => void
}) => {
  const { t } = useTranslation('nsPageShipmentsList')

  const { trus } = useQueryTRUs()
  const { stakeHolders } = useStakeHoldersByTypes([StakeHolderType.CLIENT, StakeHolderType.BOTH])
  const { products } = useProducts()

  const [filters, setFilters] = useState<ShipmentFiltersState>({
    truIds: [],
    tos: [],
    startDate: '',
    endDate: '',
    productIds: [],
  })

  const handleFilterToggle = (filterKey: keyof ShipmentFiltersState, value?: string) => {
    setFilters(previousState => {
      const filter = previousState[filterKey]
      if (Array.isArray(filter) && value) {
        const isPresent = filter.includes(value)
        return {
          ...previousState,
          [filterKey]: !isPresent
            ? [...filter, value]
            : filter.filter(filterValue => filterValue !== value),
        }
      } else if (!Array.isArray(filter)) {
        return {
          ...previousState,
          [filterKey]: value,
        }
      }
      return previousState
    })
  }

  const previousFilters = usePrevious(filters)

  useEffect(() => {
    if (previousFilters && previousFilters !== filters) {
      onFiltersChange(filters)
    }
  }, [filters, onFiltersChange, previousFilters])

  const truOptions = useMemo(
    () => trus.map(({ _id, reference }) => ({ label: reference, value: _id })),
    [trus]
  )

  const customerOptions = useMemo(
    () =>
      stakeHolders.map(stakeHolder => ({
        label: stakeHolder.company.name,
        value: stakeHolder._id,
      })),
    [stakeHolders]
  )

  const productOptions = useMemo(
    () =>
      products.map(product => ({
        label: getProductNameWithReference(product),
        value: product._id,
      })),
    [products]
  )

  return (
    <Card className='overflow-visible'>
      <Card.Body>
        <div className='flex flex-col'>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>{t('filters.title')}</h3>
          <p className='mt-1 text-sm text-gray-400'>{t('filters.description')}</p>

          <div className='mt-5'>
            <div className='flex flex-col lg:flex-row w-full'>
              {/* Filter tru */}
              <div className='flex flex-col w-full'>
                <SelectField
                  id='shipment-list-filters-select-tru'
                  name='truId'
                  isSearchable
                  options={truOptions}
                  value={null}
                  label={t('filters.form.tru.label')}
                  placeholder={t('filters.form.tru.placeholder')}
                  menuPortalTarget={document.body}
                  onChange={option => {
                    if (!option) return
                    handleFilterToggle('truIds', option.value)
                  }}
                />
                {filters.truIds.length > 0 && (
                  <ul>
                    {filters.truIds.map(truId => (
                      <li className='mt-2' key={truId}>
                        <button
                          type='button'
                          className='inline-flex text-left items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none'
                          onClick={() => {
                            handleFilterToggle('truIds', truId)
                          }}
                        >
                          {t('filters.form.tru.value', {
                            tru: truOptions.find(({ value }) => value === truId)?.label,
                          })}
                          <span>
                            <TrashIcon className='ml-2 -mr-0.5 h-4 w-4' aria-hidden='true' />
                          </span>
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Filter customer */}
              <div className='flex flex-col lg:mx-4 mt-4 lg:mt-0 w-full'>
                <SelectField
                  id='shipment-list-filters-select-customer'
                  name='customer'
                  isSearchable
                  options={customerOptions}
                  value={null}
                  label={t('filters.form.to.label')}
                  placeholder={t('filters.form.to.placeholder')}
                  menuPortalTarget={document.body}
                  onChange={option => {
                    if (!option) return
                    handleFilterToggle('tos', option.value)
                  }}
                />
                {filters.tos.length > 0 && (
                  <ul>
                    {filters.tos.map(to => (
                      <li className='mt-2' key={to}>
                        <button
                          type='button'
                          className='inline-flex text-left items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none'
                          onClick={() => {
                            handleFilterToggle('tos', to)
                          }}
                        >
                          {t('filters.form.to.value', {
                            customer: customerOptions.find(({ value }) => value === to)?.label,
                          })}
                          <span>
                            <TrashIcon className='ml-2 -mr-0.5 h-4 w-4' aria-hidden='true' />
                          </span>
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Filter date */}
              <div className='flex flex-col mt-4 lg:mt-0 w-full'>
                <DateRangeLocaleField
                  id='shipment-list-filters-input-date'
                  name='date'
                  label={t('filters.form.date.label')}
                  isClearable
                  onChange={value => {
                    if (value.startDate && value.endDate) {
                      handleFilterToggle('startDate', value.startDate)
                      handleFilterToggle('endDate', value.endDate)
                    } else if (!value.startDate) {
                      handleFilterToggle('startDate', undefined)
                      handleFilterToggle('endDate', undefined)
                    }
                  }}
                />
              </div>

              {/* Filter product */}
              <div className='flex flex-col lg:mx-4 mt-4 lg:mt-0 w-full'>
                <SelectField
                  id='shipment-list-filters-select-product'
                  name='product'
                  isSearchable
                  options={productOptions}
                  value={null}
                  label={t('filters.form.product.label')}
                  placeholder={t('filters.form.product.placeholder')}
                  menuPortalTarget={document.body}
                  onChange={option => {
                    if (!option) return
                    handleFilterToggle('productIds', option.value)
                  }}
                />
                {filters.productIds.length > 0 && (
                  <ul>
                    {filters.productIds.map(productId => (
                      <li className='mt-2' key={productId}>
                        <button
                          type='button'
                          className='inline-flex text-left items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none'
                          onClick={() => {
                            handleFilterToggle('productIds', productId)
                          }}
                        >
                          {t('filters.form.product.value', {
                            product: productOptions.find(({ value }) => value === productId)?.label,
                          })}
                          <span>
                            <TrashIcon className='ml-2 -mr-0.5 h-4 w-4' aria-hidden='true' />
                          </span>
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}
