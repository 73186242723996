export enum AutomationTriggerType {
  SCHEDULE = 'schedule',
  ENTITY_EVENT = 'entity_event',
}

export const AutomationTriggerTypes = Object.values(AutomationTriggerType)

export interface AutomationTriggerBase {
  type: AutomationTriggerType
}

export enum ScheduleType {
  WEEKDAY_MONDAY_TO_FRIDAY = 'weekday_monday_to_friday',
  WEEKLY_ON_MONDAY = 'weekly_on_monday',
  WEEKLY_ON_TUESDAY = 'weekly_on_tuesday',
  WEEKLY_ON_WEDNESDAY = 'weekly_on_wednesday',
  WEEKLY_ON_THURSDAY = 'weekly_on_thursday',
  WEEKLY_ON_FRIDAY = 'weekly_on_friday',
  MONTHLY_ON_FIRST_DAY_AND_FIFTEENTH_DAY = 'monthly_on_first_day_and_fifteenth_day',
  MONTHLY_ON_FIRST_DAY = 'monthly_on_first_day',
  MONTHLY_ON_THIRD_FRIDAY = 'monthly_on_third_friday',
  BIMONTHLY_ON_FIRST_DAY = 'bimonthly_on_first_day',
  QUARTERLY_ON_FIRST_DAY = 'quarterly_on_first_day',
  SEMIANNUAL_ON_FIRST_DAY = 'semiannual_on_first_day',
  YEARLY_ON_FIRST_DAY = 'yearly_on_first_day',
}
export const ScheduleTypes = Object.values(ScheduleType)

export interface AutomationScheduleTrigger extends AutomationTriggerBase {
  type: AutomationTriggerType.SCHEDULE
  schedule: {
    type: ScheduleType
    timezone: string
    time: string
  }
}

export enum EntityEventType {
  TRU = 'tru',
  COMPLIANCE = 'compliance',
}

export const EntityEventTypes = Object.values(EntityEventType)

export enum EntityEventOperation {
  CREATE = 'create',
  VALIDATE = 'validate',
}

export const EntityEventOperations = Object.values(EntityEventOperation)

export interface AutomationEntityEventTrigger extends AutomationTriggerBase {
  type: AutomationTriggerType.ENTITY_EVENT
  entityEvent: {
    entity: EntityEventType.TRU
    operation: EntityEventOperation
    params: {
      productId: string
      productName?: string
    }
    deleted?: boolean
  }
}

export enum TimeIncrement {
  TODAY = 'today',
  INC_24H = '24h',
  INC_48H = '48h',
  INC_1WEEK = '1week',
  INC_1MONTH = '1month',
}

export const TimeIncrements = Object.values(TimeIncrement)

export enum AutomationTargetEntity {
  TASK = 'task',
}

export const AutomationTargetEntities = Object.values(AutomationTargetEntity)

export enum AutomationTargetOperation {
  CREATE = 'create',
  UPDATE = 'update',
}

export const AutomationTargetOperations = Object.values(AutomationTargetOperation)

export interface AutomationTarget {
  entity: AutomationTargetEntity.TASK
  operation: AutomationTargetOperation
  params: {
    templates?: {
      templateId: string
      templateName: string
    }[]
    templateId?: string
    templateIds?: string[]
    isExpired?: boolean
    templateName?: string
    assignedTo: string
    dueDateIncrementType: TimeIncrement
  }

  deleted?: boolean
}

export type AutomationTrigger = AutomationScheduleTrigger | AutomationEntityEventTrigger

export interface Automation {
  readonly _id: string
  readonly companyId: string
  name: string

  trigger: AutomationTrigger
  target: AutomationTarget

  readonly createdAt: Date
  updatedAt: Date
}
export interface AutomationInput {
  name: string

  trigger: AutomationTrigger
  target: AutomationTarget
}
