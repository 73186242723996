import { Badge, COLOR, CheckboxField } from '@blockchain-traceability-sl/tailwind-components'
import classNames from 'classnames'

export const PlanItemBox = ({
  plan,
  checked,
  title,
  subtitle,
  onCheck,
  amountSuffixText,
  seatsDescriptionText,
  children,
  isComingSoon,
  comingSoonText,
}: {
  plan: { name: string; amount: number; seatsCount?: number }
  checked?: boolean
  onCheck: (checked: boolean) => void
  title: string
  subtitle: string
  amountSuffixText: string
  seatsDescriptionText?: string
  children?: React.ReactNode
  isComingSoon?: boolean
  comingSoonText?: string
}) => {
  return (
    <div
      className={classNames('shadow-lg rounded-xl cursor-pointer', {
        'border-4 border-blue-600': checked,
        'border border-gray-300': !checked,
      })}
      onClick={() => !isComingSoon && onCheck(!checked)}
    >
      <div className='p-4 divide-y divide-gray-200'>
        <div className='flex flex-col pb-6'>
          <div className='flex justify-between items-center'>
            <h3 className='text-2xl leading-8 font-extrabold text-gray-900'>{title}</h3>
            {!isComingSoon && (
              <div className='-mr-3'>
                <CheckboxField
                  id={`primary-plan-checkbox-${plan.name}`}
                  name={`primary-plan-checkbox-${plan.name}`}
                  checked={checked}
                  onChange={event => onCheck(event.target.checked)}
                />
              </div>
            )}
          </div>
          <p className='mt-6 text-base text-gray-900 leading-5 text-center'>{subtitle}</p>
          <div className='flex items-center justify-center mt-6'>
            {isComingSoon ? (
              <Badge color={COLOR.BLUE} className='text-sm leading-5 font-medium'>
                {comingSoonText}
              </Badge>
            ) : (
              <>
                <span className='text-blue-600 text-2xl leading-8 font-extrabold mr-1'>
                  {plan.amount / 100} €
                </span>
                <span className='text-base leading-5 font-normal text-gray-900'>
                  {amountSuffixText}
                </span>
              </>
            )}
          </div>
        </div>
        <div className='flex flex-col py-6'>
          {seatsDescriptionText && <p className='mb-4'>{seatsDescriptionText}</p>}

          {children}
        </div>
      </div>
    </div>
  )
}
