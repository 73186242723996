import { usePagination } from '@/hooks/use-pagination'
import { usePaginatedDepartments } from '../../hooks'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DEFAULT_PAGE_SIZE } from '@/app/constants'
import { useLocalStorage } from '@/hooks/use-local-storage'

/**
 * Page size key for local storage
 * @see useLocalStorage
 * @internal
 * @constant
 * @nanoid length 10
 */
const PAGE_SIZE_KEY = 'HteoPQJitQ'

export const useDepartmentsList = ({
  searchValue,
}: {
  searchValue?: string
  complianceEnabled?: boolean
}) => {
  const { t } = useTranslation()
  const [storagePageSize, setStoragePageSize] = useLocalStorage(PAGE_SIZE_KEY, DEFAULT_PAGE_SIZE)
  const { onPageChange, pageOffset, pageSize, pageIndex } = usePagination({
    pageSize: storagePageSize,
  })
  const { departments, isLoading, isFetching, isFetched, totalCount } = usePaginatedDepartments(
    { search: searchValue },
    { offset: pageOffset, limit: pageSize }
  )

  const departmentsListItems = useMemo(
    () =>
      departments.map(({ _id, name, description, users }) => ({
        id: _id,
        name,
        description,
        totalUsers: t('number', { value: users.length }),
      })),
    [departments, t]
  )

  return {
    isLoading,
    isEmpty: !isLoading && totalCount === 0 && (!searchValue || searchValue === ''),
    isFetching,
    isFetched,
    departmentsListItems,
    totalCount,
    pageSize,
    pageIndex,
    onPageChange: (pageInfo: { pageIndex: number; pageSize: number }) => {
      onPageChange(pageInfo)
      setStoragePageSize(pageInfo.pageSize)
    },
  }
}
