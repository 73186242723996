import { PlusIcon } from '@heroicons/react/solid'
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import { useTranslation } from 'react-i18next'

type CalendarCreateNewProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export const CalendarCreateNew = (props: CalendarCreateNewProps) => {
  const { t } = useTranslation('nsTasksListPage')

  return (
    <button
      {...props}
      className='mx-auto flex justify-center items-center gap-1 text-gray-500 pt-5 pb-3 hover:underline'
      aria-label='Create new'
      data-testid='task-calendar-create-new'
    >
      <span>
        <PlusIcon className='w-4 h-4' />
      </span>
      <span className='text-xs leading-5 font-normal'>{t('calendar.createNew')}</span>
    </button>
  )
}
