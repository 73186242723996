import { capitalize } from '@/utils'
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query'
import { customErrorToTrack, useAnalytics } from '../analytics'
import {
  createValidationRequest,
  getByEntityIds,
  getById,
  getLatestValidations,
  getPendingToValidate,
  getPendingToValidateCount,
  validate,
} from './service'
import * as NotifyService from '@/services/notify.service'
import {
  ValidationRequest,
  ValidationRequestCreation,
  ValidationRequestStatus,
  ValidationRequestType,
  ValidationRequestValidation,
} from './entity'
import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { arrayPrettify } from '@/utils/array-prettify'

export const QUERY_VALIDATION_REQUEST_KEY = 'validation-request'
export const QUERY_VALIDATION_COUNT_REQUEST_KEY = 'validation-request-count'

export const useInvalidateValidationsQueries = () => {
  const queryClient = useQueryClient()

  return {
    invalidateValidationsQueries() {
      queryClient.invalidateQueries(QUERY_VALIDATION_REQUEST_KEY)
      queryClient.invalidateQueries(QUERY_VALIDATION_COUNT_REQUEST_KEY)
    },
  }
}

export const useValidateValidationRequest = () => {
  const analytics = useAnalytics()
  const { invalidateValidationsQueries } = useInvalidateValidationsQueries()
  const { t } = useTranslation('nsNotification')

  const { mutate, mutateAsync, ...rest } = useMutation({
    mutationFn: validate,
    onSuccess: (data, variables) => {
      invalidateValidationsQueries()
      NotifyService.success({
        title: t(`validationRequest.${variables.status}.success.title`),
        description: t(`validationRequest.${variables.status}.success.description`),
      })
      analytics.track('CUSTOMER_VALIDATE_REQUEST', {
        Status: capitalize(variables.status),
        Type: capitalize(variables.type),
        Name: variables.entityName,
        Message: variables.message,
      })
    },
    onError: ({ response }, variables) => {
      if (response) {
        NotifyService.error(NotifyService.customErrorToNotify(response?.data))
        analytics.track('CUSTOMER_VALIDATE_REQUEST_ERROR', {
          ...customErrorToTrack(response?.data, response?.status),
          Status: capitalize(variables.status),
          Type: capitalize(variables.type),
        })
      }
    },
  })

  return { ...rest, validateValidationRequest: mutate, validateValidationRequestAsync: mutateAsync }
}

export const useValidateValidationRequests = () => {
  const analytics = useAnalytics()
  const { invalidateValidationsQueries } = useInvalidateValidationsQueries()
  const { t } = useTranslation('nsNotification')

  const { mutate, mutateAsync, ...rest } = useMutation({
    mutationFn: (validations: ValidationRequestValidation[]) => {
      return Promise.all(validations.map(validate))
    },
    onSuccess: (data, variables) => {
      invalidateValidationsQueries()

      NotifyService.success({
        title: t(`validationRequest.${variables[0].status}.success.title`),
        description: t(`validationRequest.${variables[0].status}.success.description`),
      })
      analytics.track('CUSTOMER_VALIDATE_REQUEST', {
        Status: arrayPrettify(variables, ({ status }) => capitalize(status)),
        Type: arrayPrettify(variables, ({ type }) => capitalize(type)),
        Name: arrayPrettify(variables, ({ entityName }) => entityName),
        Message: arrayPrettify(variables, ({ message }) => message),
      })
    },
    onError: ({ response }, variables) => {
      if (response) {
        NotifyService.error(NotifyService.customErrorToNotify(response?.data))
        analytics.track('CUSTOMER_VALIDATE_REQUEST_ERROR', {
          ...customErrorToTrack(response?.data, response?.status),
          Status: arrayPrettify(variables, ({ status }) => capitalize(status)),
          Type: arrayPrettify(variables, ({ type }) => capitalize(type)),
        })
      }
    },
  })

  return {
    ...rest,
    validateValidationRequests: mutate,
    validateValidationRequestsAsync: mutateAsync,
  }
}

export const useCreateValidationRequest = ({ silently }: { silently?: boolean } = {}) => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const { invalidateValidationsQueries } = useInvalidateValidationsQueries()
  const { mutate, mutateAsync, ...rest } = useMutation({
    mutationFn: createValidationRequest,
    onSuccess: (data, variables) => {
      invalidateValidationsQueries()
      if (!silently) {
        NotifyService.success({
          title: t('validationRequest.create.success.title'),
          description: t('validationRequest.create.success.description'),
          closeText: t('close'),
        })
      }
      analytics.track('CUSTOMER_REQUEST_VALIDATION', {
        Type: capitalize(variables.type),
        Name: variables.entity.name,
      })
    },
    onError: ({ response }) => {
      if (response) {
        NotifyService.error(NotifyService.customErrorToNotify(response?.data))
        analytics.track(
          'CUSTOMER_REQUEST_VALIDATION_ERROR',
          customErrorToTrack(response?.data, response?.status)
        )
      }
    },
  })
  return { ...rest, createValidationRequest: mutate, createValidationRequestAsync: mutateAsync }
}

export const useCreateValidationRequests = (
  type: ValidationRequestType,
  options?: {
    withoutEmail?: boolean
  }
) => {
  const analytics = useAnalytics()
  const { invalidateValidationsQueries } = useInvalidateValidationsQueries()
  const { t } = useTranslation('nsNotification')

  const { mutate, mutateAsync, ...rest } = useMutation({
    mutationFn: (validationRequests: ValidationRequestCreation[]) =>
      Promise.all(
        validationRequests.map(validationRequest =>
          createValidationRequest(
            validationRequest,
            new URLSearchParams({ silently: String(!!options?.withoutEmail) })
          )
        )
      ),
    onSuccess: (data, variables) => {
      invalidateValidationsQueries()
      NotifyService.success({
        title: t('validationRequest.create.success.title'),
        description: t('validationRequest.create.success.description'),
      })
      analytics.track('CUSTOMER_REQUEST_VALIDATION', {
        Type: capitalize(type),
        Name: variables
          .map(({ entity: { name } }) => name)
          .filter((item, index, self) => index === self.findIndex(selfItem => selfItem === item))
          .join(', '),
      })
    },
    onError: ({ response }) => {
      if (response) {
        NotifyService.error(NotifyService.customErrorToNotify(response?.data))
        analytics.track(
          'CUSTOMER_REQUEST_VALIDATION_ERROR',
          customErrorToTrack(response?.data, response?.status)
        )
      }
    },
  })
  return { ...rest, createValidationRequests: mutate, createValidationRequestsAsync: mutateAsync }
}

export const useValidationRequestById = (
  validationRequestId: string,
  options:
    | Omit<
        UseQueryOptions<
          ValidationRequest | undefined,
          unknown,
          ValidationRequest | undefined,
          string[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined
) => {
  const { data, ...rest } = useQuery(
    [QUERY_VALIDATION_REQUEST_KEY, validationRequestId],
    () => getById(validationRequestId),
    options
  )
  return { ...rest, validationRequest: useMemo(() => data, [data]) }
}

export const useValidationsRequestByEntityIds = (
  entityIds: string[],
  options?: Omit<
    UseQueryOptions<ValidationRequest[], AxiosError, ValidationRequest[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const queryClient = useQueryClient()
  const newOptions: Omit<
    UseQueryOptions<ValidationRequest[], AxiosError>,
    'queryKey' | 'queryFn'
  > = {
    ...options,
    onSuccess(data: ValidationRequest[]) {
      options?.onSuccess?.(data)
      data.forEach(item => {
        const currentValidationRequests =
          queryClient.getQueryData<ValidationRequest[]>([
            QUERY_VALIDATION_REQUEST_KEY,
            { entityId: item.entity.id },
          ]) || []
        const newValidationRequests = [...currentValidationRequests]
        const requestIndex = newValidationRequests.findIndex(request => request._id === item._id)
        if (requestIndex > -1) {
          newValidationRequests[requestIndex] = item
        } else {
          newValidationRequests.push(item)
        }

        queryClient.setQueryData(
          [QUERY_VALIDATION_REQUEST_KEY, { entityId: item.entity.id }],
          newValidationRequests
        )
      })
    },
  }

  const { data, ...rest } = useQuery(
    [QUERY_VALIDATION_REQUEST_KEY, { entityIds }],
    () => getByEntityIds(entityIds),
    newOptions
  )
  return { ...rest, validationRequests: useMemo(() => data || [], [data]) }
}

export const useLatestValidationRequests = (
  filter?: {
    types?: ValidationRequestType[]
    status?: ValidationRequestStatus[]
  },
  options?: Omit<
    UseQueryOptions<ValidationRequest[], AxiosError, ValidationRequest[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_VALIDATION_REQUEST_KEY, filter],
    () => getLatestValidations(filter),
    options
  )
  return { ...rest, validationRequests: useMemo(() => data || [], [data]) }
}

export const useValidationsRequestByEntityId = (
  entityId: string,
  options?: Omit<
    UseQueryOptions<ValidationRequest[], AxiosError, ValidationRequest[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_VALIDATION_REQUEST_KEY, { entityId }],
    () => getByEntityIds([entityId]),
    options
  )
  return { ...rest, validationRequests: useMemo(() => data || [], [data]) }
}

export const useFetchValidationsRequestByEntityId = () => {
  const queryClient = useQueryClient()

  const fetchValidationsRequestByEntityId = useCallback(
    (entityId: string) => {
      return queryClient.fetchQuery([QUERY_VALIDATION_REQUEST_KEY, { entityId }], () =>
        getByEntityIds([entityId])
      )
    },
    [queryClient]
  )

  return {
    fetchValidationsRequestByEntityId,
  }
}

export const useValidationsRequestPendingToValidate = (
  options?: Omit<
    UseQueryOptions<ValidationRequest[], AxiosError, ValidationRequest[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_VALIDATION_REQUEST_KEY, ValidationRequestStatus.PENDING],
    () => getPendingToValidate(),
    options
  )
  return { ...rest, validationRequests: useMemo(() => data || [], [data]) }
}

export const usePendingToValidateValidationsRequestCount = (
  filters?: {
    types?: ValidationRequestType[]
  },
  options?: Omit<UseQueryOptions<number, AxiosError, number>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_VALIDATION_COUNT_REQUEST_KEY, { ...filters, status: [ValidationRequestStatus.PENDING] }],
    () => getPendingToValidateCount(filters),
    options
  )
  return { ...rest, count: data || 0 }
}
