import { TemplateEntryType, TemplateType, useTemplate } from '@/features/templates'
import { SwitchField } from '@blockchain-traceability-sl/tailwind-components'
import { Trans, useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { DirtyIssue } from '@/features/issues'
import {
  EntryCreateInput,
  EntryField,
  EntryFieldProps,
  getDefaultEntryFromTemplate,
  useEntryFieldAttributes,
} from '@/features/entries'
import { EntryLabel } from '../EntryLabel'
import { FileDropField } from '@/features/files'

export const IssueReferenceEntryField = (props: EntryFieldProps) => {
  const { index, prefixName } = props

  const { t } = useTranslation('nsEntries')

  const { entryFieldName, valueFieldName, valueFieldId, enabledFieldId, enabledFieldName } =
    useEntryFieldAttributes(index, prefixName)

  const [{ value: entry }] = useField<EntryCreateInput>(entryFieldName)
  const [, , enabledInputFieldHelpers] = useField<boolean>(enabledFieldName)
  const [, , valueInputFieldHelpers] = useField<string | null>(valueFieldName)

  const templateId = entry.type === TemplateEntryType.ISSUE_REFERENCE ? entry.templateId : undefined

  const { template: issueTemplate } = useTemplate(templateId || '', TemplateType.ISSUE, {
    enabled: !!templateId,
  })

  const issuesFieldName = `${prefixName ? `${prefixName}.` : ''}issues`

  const [{ value: issues }, , issuesHelpers] = useField<DirtyIssue[]>(issuesFieldName)

  const issueIndex = issues.findIndex(item => item.taskEntryId === entry.id)
  const issue = issues[issueIndex]

  if (entry.type !== TemplateEntryType.ISSUE_REFERENCE) return null

  const enabled = entry.enabled ?? true
  const handleCreateIssue = () => {
    if (!issueTemplate) return
    issuesHelpers.setValue([
      ...issues,
      {
        templateId: entry.templateId,
        templateVersion: issueTemplate.version,
        taskEntryId: entry.id,
        entries: issueTemplate.entries.map(
          item => getDefaultEntryFromTemplate(item) as EntryCreateInput
        ),
      },
    ])
  }

  const handleDeleteIssue = () => {
    issuesHelpers.setValue(issues.filter(item => item.taskEntryId !== entry.id))

    valueInputFieldHelpers.setValue(null)
  }
  return (
    <>
      <SwitchField
        id={valueFieldId}
        name={valueFieldName}
        reverse
        label={
          <EntryLabel
            naCheckboxId={enabledFieldId}
            naCheckboxName={enabledFieldName}
            naUnchecked={enabled}
            onNaChange={enabled => {
              enabledInputFieldHelpers.setValue(enabled)
              enabledInputFieldHelpers.setTouched(true)
            }}
            naCheckboxLabel={t('form.na')}
            lightTextOnNAChecked
            withNa
          >
            {entry.name}
          </EntryLabel>
        }
        onChange={checked => {
          if (!checked) {
            handleCreateIssue()
          } else {
            handleDeleteIssue()
          }
        }}
        checked={!issue}
        disabled={!enabled}
      />

      {issue && issueTemplate && enabled && (
        <div className='grid grid-cols-1 gap-6 ml-8'>
          <FileDropField
            dragAndDropText={t('form.file.dragAndDropText')}
            id={`file-drop-${index}`}
            name='file-drop'
            preview
            maxFiles={5 - (issue.media?.length || 0)}
            previewItems={issue.media?.map(mediaItem => ({
              id: mediaItem.url,
              path: mediaItem.preview,
              mimeType: mediaItem.mimeType,
            }))}
            onUpload={uploads => {
              if (uploads.length) {
                issuesHelpers.setValue(
                  issues.map(item => {
                    if (item.taskEntryId === entry.id) {
                      return {
                        ...item,
                        media: [
                          ...(item.media || []),
                          ...uploads.map(upload => ({
                            url: upload.path,
                            mimeType: upload.mimeType,
                            preview: upload.url,
                          })),
                        ],
                      }
                    }
                    return item
                  })
                )
              }
            }}
            onSortPreviewItems={sortedItems => {
              issuesHelpers.setValue(
                issues.map(issue => {
                  if (issue.taskEntryId === entry.id) {
                    return {
                      ...issue,
                      media: sortedItems.map(sortedItem => {
                        const item = issue.media?.find(mediaItem => mediaItem.url === sortedItem.id)
                        return {
                          url: sortedItem.id,
                          mimeType: item?.mimeType || '',
                          preview: sortedItem.path,
                        }
                      }),
                    }
                  }
                  return issue
                })
              )
            }}
            onDeletePreviewItem={itemId => {
              issuesHelpers.setValue(
                issues.map(issue => {
                  if (issue.taskEntryId === entry.id) {
                    return {
                      ...issue,
                      media: issue.media?.filter(mediaItem => mediaItem.url !== itemId),
                    }
                  }
                  return issue
                })
              )
            }}
            label={
              <Trans
                t={t}
                i18nKey='form.issue.images'
                components={[<span className='font-light text-gray-400 italic' />]}
              />
            }
            uploadText={t('form.file.uploadText')}
            acceptText={t('form.file.acceptText')}
            addMoreText={`${t('form.file.addMoreText')} ${issue.media?.length || 0}/5`}
            accept={['image/*', 'video/*']}
            disabled={issue.media?.length === 5}
          />
          {issue.entries.map((issueEntry, issueEntryIndex) => {
            return (
              <div key={`entries.${index}.issue.entry.${issueEntryIndex}`}>
                <EntryField
                  {...props}
                  prefixName={`${issuesFieldName}.${issueIndex}`}
                  index={issueEntryIndex}
                />
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}
