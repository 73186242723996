import { api } from '@/app/api'
import { ITRUFilters, TRU, TRUBulkCreation, TRUCreation, TRUUpdate } from '@/features/trus'
import { Paginated, PaginationParams } from '@/hooks/use-pagination'

/**
 * Define the url path
 * @constant
 */
export const TRUS_URL = '/hydra/trus'

/**
 * Create a new TRU
 *
 *
 * @param truRaw Raw data to create a new tru
 */
export const create = async (truRaw: TRUCreation): Promise<string> => {
  const { data: truId } = await api.post<string>(TRUS_URL, truRaw)
  return truId
}

/**
 * Create new TRUs in Bulk mode
 *
 * @param trusRaw Raw data to create a new tru
 * @throws CustomBulkError
 */
export const createBulk = async (trusRaw: TRUBulkCreation[]): Promise<string> => {
  const { data: truId } = await api.post<string>(`${TRUS_URL}/bulk`, trusRaw)
  return truId
}

/**
 * Create TRUs with CSV file
 */
export const createWithCSV = async (file: File): Promise<{ total: number }> => {
  const formData = new FormData()
  formData.append('file', file)
  const { data } = await api.post<{ total: number }>(`${TRUS_URL}/csv`, formData)
  return data
}

/**
 * Get the Company logged TRUs
 *
 *
 * @returns {TRU[]} All the Company logged TRUs
 */
export const getAll = async (filters?: ITRUFilters): Promise<TRU[]> => {
  const searchParams = new URLSearchParams()

  if (filters?.search) {
    searchParams.append('search', filters.search)
  }

  if (filters?.productId) {
    filters.productId.forEach(id => searchParams.append('productId[]', id))
  }

  if (filters?.originId) {
    filters.originId.forEach(id => searchParams.append('originId[]', id))
  }

  const { data: trus } = await api.get<TRU[] | void>(TRUS_URL, { params: searchParams })
  return trus || []
}

/**
 * Get the Company logged TRUs by ids
 *
 * @returns Company logged TRUs by ids
 */
export const getByIds = async (truIds: string[]): Promise<TRU[]> => {
  if (truIds.length === 0) return []
  const { data: trus } = await api.post<TRU[] | void>(`${TRUS_URL}/ids`, {
    ids: truIds,
  })
  return trus || []
}

/**
 * Get the Company logged TRUs
 *
 * @param query - query parameter to filter
 * @returns All the Company logged TRUs that match with the filters
 */
export const getAllWithFilter = async ({
  metadata,
  limit,
  date,
}: {
  limit?: number
  date: string
  metadata?: {
    data: [Record<string, string>]
  }
}): Promise<{ data: TRU[]; results: number }> => {
  const queryParameters = []
  if (limit) queryParameters.push(`limit=${limit}`)
  if (date) queryParameters.push(`date=${date}`)
  if (metadata) queryParameters.push(`metadata[data]=${JSON.stringify(metadata.data)}`)

  const { data: response } = await api.get<{ data: TRU[]; results: number } | void>(
    `${TRUS_URL}/filter?${queryParameters.join('&')}`
  )
  return (
    response || {
      data: [],
      results: 0,
    }
  )
}

/**
 * Get all trus by product id
 *
 * @param productId the product id
 * @returns All comapny logged product's trus
 */
export const getByProduct = async (productId: string): Promise<TRU[]> => {
  const { data: tru } = await api.get<TRU[] | void>(
    `${TRUS_URL}/product/${encodeURIComponent(productId)}`
  )
  return tru || []
}

export const getByProductIds = async (productIds: string[]): Promise<TRU[]> => {
  const { data: trus } = await api.post<TRU[] | void>(`${TRUS_URL}/product/ids`, {
    ids: productIds,
  })
  return trus || []
}

/**
 * Get a tru identifier suggested by product *
 *
 * @param productIdentifier - The product identifier
 * @returns The tru identifier suggested, could be empty
 */
export const getSuggestedIdentifier = async (productIdentifier: string): Promise<string | void> => {
  const { data: truIdentifierSuggested } = await api.get<string | void>(
    `${TRUS_URL}/advice/${encodeURIComponent(productIdentifier)}`
  )
  return truIdentifierSuggested
}

/**
 * Get all trus by product identifier
 *
 *
 * @param {string} companyReference the company reference
 * @param {string} productIdentifier the product identifier
 *
 * @returns {Promise<number>} the TRU fetched couint
 */
export const getCountByProduct = async (productId: string): Promise<number> => {
  const { data: count } = await api.get<number>(`${TRUS_URL}/count/${productId}`)
  return count
}

/**
 * Get the Company logged TRUs amount
 * @returns All the Company logged TRUs count
 */
export const getCount = async (): Promise<number> => {
  const { data: trusCount } = await api.get<number>(`${TRUS_URL}/count`)
  return trusCount
}

/**
 * Get TRUs CSV template
 */
export const getCsvTemplate = async (): Promise<ArrayBuffer> => {
  const { data } = await api.get<ArrayBuffer>(`${TRUS_URL}/csv`, {
    responseType: 'arraybuffer',
  })
  return data
}

/**
 * Update a tru by _id
 *
 * @param truId the TRU id to update
 * @param tru new TRU data
 */
export const update = async ({ truId, tru }: { truId: string; tru: TRUUpdate }): Promise<void> => {
  await api.patch<void>(`${TRUS_URL}/${encodeURIComponent(truId)}`, tru)
}

/**
 * Delete a tru by _id
 *
 * @param truId the TRU id to delete
 */
export const remove = async (truId: string): Promise<void> => {
  await api.delete<void>(`${TRUS_URL}/${encodeURIComponent(truId)}`)
}

export const getPaginatedTRUs = async (
  { search, productId, originId }: ITRUFilters,
  pagination: PaginationParams
): Promise<Paginated<TRU>> => {
  const searchParams = new URLSearchParams()

  if (search) {
    searchParams.append('search', search)
  }

  if (productId) {
    productId.forEach(id => searchParams.append('productId[]', id))
  }

  if (originId) {
    originId.forEach(id => searchParams.append('originId[]', id))
  }

  searchParams.append('limit', pagination.limit.toString())
  searchParams.append('offset', pagination.offset.toString())
  const { data } = await api.get<Paginated<TRU>>(`${TRUS_URL}?${searchParams.toString()}`)
  return data
}
