import { useTranslation } from 'react-i18next'
import { EditableEntry } from '../../interfaces'
import { BlockFields } from '../BlockFields'
import { TemplateEntryError } from '../TemplateEntries'

export interface SectionEntryFieldProps {
  targetEntry: EditableEntry
  entries: EditableEntry[]
  entriesErrors?: TemplateEntryError[]
  onAddEntryInBlock: (entryToAdd: EditableEntry, entriesInNestedBlock?: EditableEntry[]) => void
  onRemoveEntryInBlock: (entryToAdd: EditableEntry) => void
  onChangeEntryInBlock: (
    entry: EditableEntry,
    nestedBlockOperation?: { entry: EditableEntry; operation: 'add' | 'remove' }
  ) => void
  onSort: (entries: EditableEntry[]) => void
}

const getErrorsByEntryIds = (errors: TemplateEntryError[], entries: EditableEntry[]) =>
  entries.reduce<{ [id: string]: TemplateEntryError | undefined }>((accumulator, targetEntry) => {
    const index = entries.findIndex(entry => entry.id === targetEntry.id)
    if (index === -1) return accumulator
    return {
      ...accumulator,
      [targetEntry.id]: errors[index],
    }
  }, {})

export const SectionEntryField = ({
  targetEntry,
  entries,
  entriesErrors,
  onAddEntryInBlock,
  onRemoveEntryInBlock,
  onChangeEntryInBlock,
  onSort,
}: SectionEntryFieldProps) => {
  const { t } = useTranslation('nsTemplate')

  const childEntries = entries.filter(entry => entry.blockId === targetEntry.id)

  const childEntriesErrors = entriesErrors ? getErrorsByEntryIds(entriesErrors, childEntries) : {}

  return (
    <div className='bg-gray-100 py-6  rounded-lg flex flex-col'>
      <div className='flex flex-col px-10'>
        <h4 className='text-base leading-6 font-bold'>{t('entries.section.title')}</h4>

        <p className='mt-2 text-sm leading-5 font-normal text-gray-500'>
          {t('entries.section.description')}
        </p>

        <div className='mt-4'>
          <BlockFields
            entries={childEntries}
            errors={childEntriesErrors}
            onAddField={(newEntry, entriesInNestedBlock) => {
              onAddEntryInBlock(
                {
                  ...newEntry,
                  blockId: targetEntry.id,
                },
                entriesInNestedBlock
              )
            }}
            onRemoveField={entry => {
              onRemoveEntryInBlock(entry)
            }}
            onChangeField={(entry, nestedBlockOperation) => {
              onChangeEntryInBlock(entry, nestedBlockOperation)
            }}
            onSortFields={(entries: EditableEntry[]) => {
              onSort(entries)
            }}
            mandatoryEnabled
            conditionalEnabled
            tableEnabled
            statementEnabled
            entriesInNestedBlock={entries}
            errorsInNestedBlock={entriesErrors}
          />
        </div>
      </div>
    </div>
  )
}
