import { SelectorOption } from '@blockchain-traceability-sl/tailwind-components'
import { DocumentSettingsExpirationDuration } from '@/features/documents'

export enum TemplateType {
  REGISTRY = 'registry',
  INCIDENCE = 'incidence',
  METADATA = 'metadata',
  ISSUE = 'issue',
  COMPLIANCE = 'compliance',
}

export const FormTemplateTypes = [
  TemplateType.REGISTRY,
  TemplateType.INCIDENCE,
  TemplateType.COMPLIANCE,
]

export const TaskTemplateTypes = [TemplateType.REGISTRY, TemplateType.INCIDENCE]
export const MetadataTemplateTypes = [TemplateType.METADATA]

export enum TemplateComplianceEntityType {
  STAKEHOLDER = 'stakeholder',
  STAKEHOLDER_PRODUCT = 'stakeholder_product',
}

export enum TemplateAssociationType {
  DEPARTMENT = 'department',
  PRODUCT = 'product',
  STAKEHOLDER_PRODUCT = 'stakeHolderProduct',
}

export enum TemplateEntryType {
  // Texts
  SHORT_TEXT = 'text',
  LONG_TEXT = 'long_text',
  // Selectors
  SINGLE_CHOICE = 'single_choice',
  MULTIPLE_CHOICE = 'multiple_choice',
  // Files
  FILE = 'file',
  DOCUMENT = 'document',
  // Dates
  DATE = 'date',
  TIME = 'time',
  // Others
  BOOLEAN = 'boolean_answer',
  NUMBER = 'number',
  LINK = 'link',
  STATEMENT = 'statement',
  TABLE = 'table',
  REFERENCE = 'reference',
  CONDITIONAL = 'conditional',
  SECTION = 'section',
  SIGNATURE = 'signature',

  ISSUE_REFERENCE = 'issue_reference',
}

export const TemplateBlockEntryTypes = [
  TemplateEntryType.SECTION,
  TemplateEntryType.CONDITIONAL,
  TemplateEntryType.ISSUE_REFERENCE,
]

/**
 * Deprecated types, we need add support to old created but NOT ceate a new one
 */
export enum TemplateDeprecatedEntryType {
  SELECTOR = 'selector',
}

export enum TemplateEntryReferenceType {
  STAKEHOLDER = 'stakeHolder',
  PRODUCT = 'product',
  TRU = 'tru',
}

export const TemplateEntryReferenceTypes = Object.values(TemplateEntryReferenceType)

export interface TemplateEntryReferenceValue {
  id?: string
  name?: string
  type: TemplateEntryReferenceType
}

export type TemplateTableEntryRowValues = string[] | TemplateEntryReferenceValue[]
export interface TemplateTableEntryRow {
  type: Exclude<TemplateEntryType, TemplateEntryType.TABLE | TemplateEntryType.STATEMENT>
  values: TemplateTableEntryRowValues
}
export interface TemplateTableEntryColumn {
  title: string
  lock: boolean
  rows: TemplateTableEntryRow[]
}

export type TemplateEntryConditionalValues = [string[], string[]]

export type TemplateEntryValues =
  | string[]
  | TemplateTableEntryColumn[]
  | TemplateEntryReferenceValue[]
  | TemplateEntryConditionalValues

export interface TemplateEntry {
  id: string
  blockId?: string | null
  type: TemplateEntryType
  name: string
  values: TemplateEntryValues
  mandatory: boolean
  defaultValues?: TemplateEntryValues
  templateId?: string | null

  /**
   * This field is only for file type
   */
  documentSettings?: {
    expirationDuration?: DocumentSettingsExpirationDuration
  }
}

export interface TemplateAssociation {
  id: string
  type: TemplateAssociationType
}

export interface ITemplateDefaults {
  assignedTo?: string | null
  issueResponder?: string | null
  dueDate?: string | null
  taskValidator?: string | null
  complianceEntityType?: TemplateComplianceEntityType | null
}

export interface ITemplateControl {
  code?: string | null
  isoStandardReference?: string | null
}

export interface Template {
  _id: string
  companyId: string
  version: number
  type: TemplateType
  name: string
  entries: TemplateEntry[]
  associations: TemplateAssociation[]
  defaults?: ITemplateDefaults
  control?: ITemplateControl
  tags?: string[]
  /**
   * Upload to document manager a report on close the entity that use this template
   */
  reportUpload?: boolean
  createdAt: string
  updatedAt: string
}

export interface TemplateDataInput {
  type: TemplateType
  name: string
  version?: number
  entries: TemplateEntry[]
  associations?: TemplateAssociation[]
  reportUpload?: boolean
  defaults?: ITemplateDefaults
  control?: ITemplateControl
  tags?: string[]
}

export interface IssueTemplateInput {
  /**
   * Entry id from the form entry containing the issue reference
   */
  entryId: string
  name: string
  id?: string
  entries: TemplateEntry[]
}

export type EditableEntry = Omit<TemplateEntry, 'type'> & {
  type: TemplateEntryType | TemplateEntryReferenceType
  editable?: boolean
  /**
   * A way to identify new entries in blocks (sections, conditionals and issue templates).
   * When it's true, we don't validate the name, because we filter new entries with no name when we create/edit a template
   */
  isNewEntry?: boolean
  id: string
}

export interface EditableIssueTemplateInput {
  /**
   * Entry id from the form entry containing the issue reference
   */
  entryId: string
  id?: string
  entries: EditableEntry[]
}

export type TemplateEntryRedesignError = {
  type?: string | string[]
  name?: string | string[]
  values?: string | string[]
  mandatory?: string | string[]
}

export type IssueTemplateInputError = {
  entryId: string
  entries: TemplateEntryRedesignError[]
}

export type DirtyTemplateDataInput = Omit<
  TemplateDataInput,
  'entries' | 'associations' | 'defaults'
> & {
  entries: (Omit<TemplateEntry, 'type'> & {
    type: TemplateEntryType | TemplateEntryReferenceType
    editable?: boolean
    isNewString?: boolean
    id: string
  })[]
  newEntry: TemplateEntry & { id: string }
  // Associations
  departments: string[]
  issueTemplates: EditableIssueTemplateInput[]

  // Defaults
  defaults?: {
    assignedTo?: SelectorOption | null
    issueResponder?: SelectorOption | null
    taskValidator?: SelectorOption | null
    dueDate?: string | null
    complianceEntityType?: TemplateComplianceEntityType | null
  }
  control?: ITemplateControl
  tags?: string[]
}

export type IssueTemplatesChanges = (
  | {
      operation: 'create' | 'update'
      value: IssueTemplateInput
    }
  | { operation: 'delete'; value: string }
)[]

export interface TemplateFilters {
  search?: string
  types?: TemplateType[]
  departmentIds?: string[]
  sortBy?: 'name'
  /**
   * @default 'asc'
   */
  sortOrder?: 'asc' | 'desc'
}
