import { CheckboxField, Tooltip } from '@blockchain-traceability-sl/tailwind-components'
import { BadgeCheckIcon, PencilAltIcon } from '@heroicons/react/outline'
import { FlagIcon } from '@heroicons/react/solid'
import { useEffect, useRef } from 'react'

export interface BulkActionsMenuProps {
  checkAllId: string

  allChecked: boolean
  someChecked?: boolean

  selectAllText: string
  disabled: boolean

  toggleAllChecked: (value: boolean) => void

  completeEnabled?: boolean
  completeTooltipText?: string
  onComplete?: () => void

  validationRequestEnabled?: boolean
  validationRequestTooltipText?: string
  onValidationRequest?: () => void

  validationReviewEnabled?: boolean
  validationReviewTooltipText?: string
  onValidationReview?: () => void

  closeEnabled?: boolean
  closeTooltipText?: string
  onClose?: () => void
}

export const BulkActionsMenu = ({
  checkAllId,
  allChecked,
  someChecked,
  disabled,
  toggleAllChecked,
  selectAllText,

  completeEnabled,
  completeTooltipText,
  onComplete,

  validationRequestEnabled,
  validationRequestTooltipText,
  onValidationRequest,

  validationReviewEnabled,
  validationReviewTooltipText,
  onValidationReview,

  closeEnabled,
  closeTooltipText,
  onClose,
}: BulkActionsMenuProps) => {
  const allCheckedCheckboxRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (!allCheckedCheckboxRef.current) return
    allCheckedCheckboxRef.current.indeterminate = !!someChecked && !allChecked
  }, [allChecked, someChecked])

  return (
    <div className='flex items-center'>
      <CheckboxField
        innerRef={allCheckedCheckboxRef}
        id={checkAllId}
        name={checkAllId}
        label={<span className='text-sm leading-5 font-medium text-gray-500'>{selectAllText}</span>}
        checked={!disabled && allChecked}
        onChange={event => {
          toggleAllChecked(event.target.checked)
        }}
        disabled={disabled}
      />
      {someChecked && !disabled && (
        <div className='flex gap-2 ml-7'>
          {completeEnabled && (
            <Tooltip tip={completeTooltipText} placement='bottom'>
              <div
                className='hover:bg-blue-600 p-2 flex items-center justify-center rounded text-gray-400 hover:text-white cursor-pointer'
                data-testid='bulk-actions-menu-complete'
                onClick={onComplete}
              >
                <PencilAltIcon className='h-5 w-5' aria-hidden='true' />
              </div>
            </Tooltip>
          )}

          {validationRequestEnabled && (
            <Tooltip tip={validationRequestTooltipText} placement='bottom'>
              <div
                className='hover:bg-blue-600 p-2 flex items-center justify-center rounded text-gray-400 hover:text-white cursor-pointer'
                data-testid='bulk-actions-menu-validation-request'
                onClick={onValidationRequest}
              >
                <BadgeCheckIcon className='h-5 w-5' aria-hidden='true' />
              </div>
            </Tooltip>
          )}

          {validationReviewEnabled && (
            <Tooltip tip={validationReviewTooltipText} placement='bottom'>
              <div
                className='hover:bg-blue-600 p-2 flex items-center justify-center rounded text-gray-400 hover:text-white cursor-pointer'
                data-testid='bulk-actions-menu-validation-request'
                onClick={onValidationReview}
              >
                <BadgeCheckIcon className='h-5 w-5' aria-hidden='true' />
              </div>
            </Tooltip>
          )}

          {closeEnabled && (
            <Tooltip tip={closeTooltipText} placement='bottom'>
              <div
                className='hover:bg-blue-600 p-2 flex items-center justify-center rounded text-gray-400 hover:text-white cursor-pointer'
                data-testid='bulk-actions-menu-close'
                onClick={onClose}
              >
                <FlagIcon className='h-5 w-5' aria-hidden='true' />
              </div>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  )
}
