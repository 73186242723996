import { useMemo, useState } from 'react'
import { Badge, COLOR, Card, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { ReadableTask, TaskDetailsStatus, TaskStatus } from '../../interfaces'
import {
  CalendarIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ClipboardIcon,
  CollectionIcon,
  ExclamationIcon,
  UserIcon,
} from '@heroicons/react/outline'
import { ValidationRequestStatus, useGetLastValidator } from '@/features/validationRequests'
import { IssueStatus } from '@/features/issues'
import { TaskIssuesBadges } from '../TaskIssuesBadges'
import { Trans, useTranslation } from 'react-i18next'
import { useGetAssignedCreatorTaskName } from '../../utils'
import { useUserId } from '@/features/auth'
import { TemplateEntryType } from '@/features/templates'
import { TaskValidationIconBadge } from '../TaskValidationIconBadge'
import { TaskManageMenu } from '../TaskManageMenu/index'
import { TaskDetailsCardDueDate } from './TaskDetailsCardDueDate'
import { useRescheduleTask } from '../../hooks'

export const TaskDetailsCard = ({ task }: { task: ReadableTask }) => {
  const [collapsed, setCollapsed] = useState(false)
  const { t } = useTranslation('nsTasksListPage')
  const { getLastValidatorName } = useGetLastValidator()
  const userId = useUserId()
  const { getAssignedCreatorTaskName } = useGetAssignedCreatorTaskName()

  const { rescheduleTask } = useRescheduleTask()

  const handleRescheduleTask = (rescheduledDueDate: string) => {
    rescheduleTask({
      taskId: task._id,
      task: {
        formName: task.formName,
        dueDate: rescheduledDueDate,
      },
    })
  }

  const handleCollapse = () => setCollapsed(previous => !previous)
  const IconComponent = collapsed ? ChevronUpIcon : ChevronRightIcon

  const taskCompletionStatus = useMemo(() => {
    const isTaskPending =
      task.status === TaskStatus.OPEN ||
      task.lastValidation?.status === ValidationRequestStatus.PENDING ||
      task.lastValidation?.status === ValidationRequestStatus.REJECTED ||
      (task.entries.some(entry => entry.type === TemplateEntryType.ISSUE_REFERENCE) &&
        task.issues.some(issue => issue.status !== IssueStatus.CLOSED))
    return isTaskPending ? TaskDetailsStatus.OPEN : TaskDetailsStatus.CLOSED
  }, [task])

  const naCount = useMemo(
    () =>
      [...task.entries, ...task.issues.flatMap(issue => issue.entries)].reduce(
        (accumulator, entry) => {
          if (!entry.enabled) {
            return accumulator + 1
          }
          return accumulator
        },
        0
      ),
    [task.entries, task.issues]
  )

  return (
    <>
      <Card>
        <div className={'flex justify-between rounded-lg'}>
          <div className='flex'>
            <div
              className={`${
                taskCompletionStatus === TaskDetailsStatus.OPEN ? 'bg-yellow-100' : 'bg-gray-200'
              } w-3 rounded-l-lg`}
            ></div>
            <div className='flex flex-col gap-2 p-4'>
              <Badge
                className='w-fit capitalize'
                color={task.status === 'open' ? COLOR.YELLOW : COLOR.GRAY}
                size={SIZE.MEDIUM}
                variant='normal'
              >
                {t(`table.statusTypes.${task.status}`)}
              </Badge>
              <div className='flex flex-col gap-2 p-1'>
                <div className='flex gap-1  text-gray-800 text-sm font-medium leading-tight'>
                  <ClipboardIcon className=' text-blue-600 font-normal h-4 w-4' />
                  <span>{task.formName}</span>
                </div>
                {collapsed ? (
                  <>
                    <div className='flex gap-1 items-center text-gray-500 text-sm font-medium leading-tight'>
                      <CollectionIcon className=' text-blue-600 font-normal h-4 w-4' />
                      <span>{t('table.version')}:</span>
                      <span>{task.formVersion}</span>
                    </div>
                    <div className='flex gap-1 items-center text-gray-900 text-sm leading-tight'>
                      <CalendarIcon className=' text-blue-600 font-normal h-4 w-4' />
                      <span className='font-medium'>{t('table.dueDate')}:</span>
                      <TaskDetailsCardDueDate
                        taskId={task._id}
                        dueDate={task.dueDate}
                        onDueDateChange={handleRescheduleTask}
                      />
                    </div>
                  </>
                ) : null}
                <div className='flex items-start gap-2'>
                  <div>
                    <UserIcon className='h-4 w-4 text-blue-600' />
                  </div>
                  <div className='text-gray-500 text-sm font-normal leading-tight'>
                    {task.assignedTo === userId
                      ? t('table.assignedToMe')
                      : getAssignedCreatorTaskName(task.assignedTo)}
                  </div>
                </div>

                {collapsed ? (
                  <>
                    {naCount > 0 && (
                      <div className='flex gap-2 items-center'>
                        <div className='text-xs leading-4 font-medium text-blue-600'>N/A</div>
                        <span className='inline-flex items-center justify-center font-medium bg-gray-300 text-gray-800 px-2 py-0.5 text-xs rounded-full'>
                          {naCount}
                        </span>
                      </div>
                    )}
                    {task.issues.length > 0 && (
                      <div className='flex gap-2 items-center'>
                        <div className='flex gap-2 items-center'>
                          <ExclamationIcon className='h-4 w-4 text-blue-600' />
                          <span className='text-gray-900 text-sm font-medium leading-tight'>
                            {t('table.issues')}:
                          </span>
                        </div>
                        <TaskIssuesBadges issues={task.issues} size={SIZE.SMALL} />
                      </div>
                    )}
                    {task.lastValidationRequest && (
                      <div className='flex gap-1 items-center text-gray-900 text-sm font-medium'>
                        <TaskValidationIconBadge validation={task.lastValidationRequest} />
                        <div className='text-gray-900 text-sm font-medium leading-tight'>
                          <Trans
                            t={t}
                            i18nKey={`table.detail.validator.${task.lastValidationRequest?.status}`}
                            components={{
                              b: <b />,
                            }}
                            values={{
                              context: getLastValidatorName(task.lastValidationRequest),
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </>
                ) : null}
              </div>

              <button
                className='inline-flex items-center text-sm font-semibold  cursor-pointer text-blue-600 hover:text-blue-600 hover:underline ml-1'
                onClick={handleCollapse}
              >
                <span>{collapsed ? t('calendar.seeLess') : t('calendar.seeMore')}</span>
                <span>
                  <IconComponent className='ml-2 h-4 w-4' aria-hidden='true' />
                </span>
              </button>
            </div>
          </div>
          <div className={`pr-6 pt-6`}>
            <TaskManageMenu taskId={task._id} />
          </div>
        </div>
      </Card>
    </>
  )
}
