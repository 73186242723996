import { useToggle } from '@/hooks/use-toggle'
import { useTranslation } from 'react-i18next'
import { EditableEntry, TemplateEntryConditionalValues } from '../../interfaces'
import { TemplateEntryError } from '../TemplateEntries'
import { BlockFields } from '../BlockFields'
import classNames from 'classnames'
import { CheckboxField, SwitchField } from '@blockchain-traceability-sl/tailwind-components'

const getErrorsByConditionEntries = (
  entryIds: string[],
  entries: EditableEntry[],
  entriesErrors?: TemplateEntryError[]
) =>
  entriesErrors
    ? entryIds.reduce<{ [id: string]: TemplateEntryError | undefined }>((accumulator, id) => {
        const index = entries.findIndex(entry => entry.id === id)
        if (index === -1) return accumulator
        return {
          ...accumulator,
          [id]: entriesErrors[index],
        }
      }, {})
    : {}

export interface ConditionalEntryFieldProps {
  targetEntry: EditableEntry
  entries: EditableEntry[]
  entriesErrors?: TemplateEntryError[]
  onAddEntryInBlock: (blockEntry: EditableEntry, entryToAdd: EditableEntry) => void
  onRemoveEntryInBlock: (blockEntry: EditableEntry, entryToAdd: EditableEntry) => void
  onChangeEntryInBlock: (entry: EditableEntry) => void
  onChange: (entry: EditableEntry) => void

  nested?: boolean
}

export const ConditionalEntryField = ({
  targetEntry,
  entries,
  entriesErrors,
  onAddEntryInBlock,
  onRemoveEntryInBlock,
  onChangeEntryInBlock,
  onChange,

  nested,
}: ConditionalEntryFieldProps) => {
  const { t } = useTranslation('nsTemplate')
  const [collapsedIf, toggleIf] = useToggle(false)
  const [collapsedElse, toggleElse] = useToggle(true)

  const [ifEntriesIds = [], elseEntriesIds = []] =
    targetEntry.values as TemplateEntryConditionalValues

  const ifEntriesErrors = getErrorsByConditionEntries(ifEntriesIds, entries, entriesErrors)
  const elseEntriesErrors = getErrorsByConditionEntries(elseEntriesIds, entries, entriesErrors)

  const hasDefaultValues = targetEntry.defaultValues && targetEntry.defaultValues.length > 0

  const normalizedDefaultValue = targetEntry.defaultValues
    ? targetEntry.defaultValues[0] === 'true'
    : false

  return (
    <div className='flex flex-col'>
      <div
        className={classNames(' py-6 rounded-lg flex flex-col', {
          'bg-gray-100': !nested,
          'bg-white': nested,
        })}
      >
        <div
          className={classNames('flex flex-col ', {
            'px-10': !nested,
            'px-4': nested,
          })}
        >
          <h4 className='text-base leading-6 font-bold'>{t('entries.conditional.title')}</h4>

          <p className='mt-2 text-sm leading-5 font-normal text-gray-500'>
            {t('entries.conditional.description')}
          </p>
        </div>

        <div className='flex flex-col divide-y'>
          <div className='mt-4 px-10'>
            <BlockFields
              collapsed={collapsedIf}
              toggleCollapsed={() => {
                if (collapsedIf && !collapsedElse) {
                  toggleElse(true)
                }
                toggleIf()
              }}
              title={t('entries.conditional.condition.if')}
              entries={
                ifEntriesIds
                  .map(id => entries.find(entry => entry.id === id))
                  .filter(Boolean) as EditableEntry[]
              }
              errors={ifEntriesErrors}
              onAddField={newEntry => {
                onAddEntryInBlock(
                  {
                    ...targetEntry,
                    values: [[...ifEntriesIds, newEntry.id], elseEntriesIds],
                  },
                  {
                    ...newEntry,
                    blockId: targetEntry.id,
                  }
                )
              }}
              onRemoveField={entry => {
                onRemoveEntryInBlock(
                  {
                    ...targetEntry,
                    values: [ifEntriesIds.filter(id => id !== entry.id), elseEntriesIds],
                  },
                  entry
                )
              }}
              onChangeField={entry => {
                onChangeEntryInBlock(entry)
              }}
              onSortFields={(entries: EditableEntry[]) => {
                onChange({
                  ...targetEntry,
                  values: [entries.map(entry => entry.id), elseEntriesIds],
                })
              }}
            />
          </div>

          <div className='mt-6 px-10 pt-2'>
            <BlockFields
              collapsed={collapsedElse}
              toggleCollapsed={() => {
                if (!collapsedIf && collapsedElse) {
                  toggleIf(true)
                }
                toggleElse()
              }}
              title={t('entries.conditional.condition.else')}
              entries={
                elseEntriesIds
                  .map(id => entries.find(entry => entry.id === id))
                  .filter(Boolean) as EditableEntry[]
              }
              errors={elseEntriesErrors}
              onAddField={newEntry => {
                onAddEntryInBlock(
                  {
                    ...targetEntry,
                    values: [ifEntriesIds, [...elseEntriesIds, newEntry.id]],
                  },
                  {
                    ...newEntry,
                    blockId: targetEntry.id,
                  }
                )
              }}
              onRemoveField={entry => {
                onRemoveEntryInBlock(
                  {
                    ...targetEntry,
                    values: [ifEntriesIds.filter(id => id !== entry.id), elseEntriesIds],
                  },
                  entry
                )
              }}
              onChangeField={entry => {
                onChangeEntryInBlock(entry)
              }}
              onSortFields={(entries: EditableEntry[]) => {
                onChange({
                  ...targetEntry,
                  values: [ifEntriesIds, entries.map(entry => entry.id)],
                })
              }}
            />
          </div>
        </div>
      </div>
      <div className='flex mt-4 gap-2'>
        <CheckboxField
          id={`conditional-enable-default-value-${targetEntry.id}`}
          name={`conditional-enable-default-value-${targetEntry.id}`}
          checked={hasDefaultValues}
          label={t('entries.conditional.enableDefaultValues')}
          onChange={event => {
            if (event.target.checked) {
              onChange({
                ...targetEntry,
                defaultValues: ['false'],
              })
            } else {
              onChange({
                ...targetEntry,
                defaultValues: [],
              })
            }
          }}
        />
        {hasDefaultValues && (
          <SwitchField
            id={`conditional-default-value-${targetEntry.id}`}
            name={`conditional-default-value-${targetEntry.id}`}
            checked={normalizedDefaultValue}
            onChange={checked => {
              onChange({
                ...targetEntry,
                defaultValues: [checked.toString()],
              })
            }}
          />
        )}
      </div>
    </div>
  )
}
