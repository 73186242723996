import { randomBytes } from 'crypto'
import { useRef } from 'react'

/**
 * Generate a unique id
 * @param prefix - Prefix for the id (default: 'id')
 * @returns A unique id
 * @example
 * const id = useId('my-id')
 * // id = 'my-id-1'
 * const id = useId()
 * // id = 'id-2'
 *
 * // Multiple calls in the same component will return the same id
 * const id = useId()
 * <div id={`${id}-wrapper`}>
 *  <label htmlFor={`${id}-input`}>Label</label>
 *  <input id={`${id}-input`} />
 * </div>
 */
export const useId = (prefix: string = 'id'): string => {
  const id = useRef<string>(`${prefix}-${randomBytes(10).toString('hex')}`)

  return id.current
}
