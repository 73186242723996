import { FileDropField, FileUploadResponse, useUploadFile } from '@/features/files'
import { useStakeHolders } from '@/features/stakeHolders'
import {
  Button,
  FullLoader,
  InputField,
  Modal,
  RichTextField,
  RichTextFieldMode,
  SIZE,
  SelectMultiField,
} from '@blockchain-traceability-sl/tailwind-components'
import { TrashIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Task, useGenerateTaskReport, useTaskReportEmail } from '@/features/tasks'
import { useFormik } from 'formik'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Template, useTemplate } from '@/features/templates'
import { useCompanyUser } from '@/features/auth'
import { useAnalytics } from '@/features/analytics'
import dayjs from 'dayjs'
import { useGeneratePdfFromDefinition } from '@/features/pdf'

export interface ModalTaskEmailProps {
  show: boolean
  task: Task | undefined
  toggle: () => void
}

export const ModalTaskEmail = ({ show, task, toggle }: ModalTaskEmailProps) => {
  const { t } = useTranslation('nsModalTaskEmail')
  const { stakeHolders } = useStakeHolders()
  const { sendTaskReportEmail } = useTaskReportEmail()
  const { uploadFileAsync } = useUploadFile()
  const { template } = useTemplate(task?.formId || '', 'form')
  const companyUser = useCompanyUser()
  const analytics = useAnalytics()
  const initialFocusRef = useRef(null)

  const { generateTaskReport } = useGenerateTaskReport()
  const { generatePdfFromDefinitionAsync } = useGeneratePdfFromDefinition()

  const targetOptions = useMemo(
    () =>
      stakeHolders
        .filter(
          ({ company: { email, secondaryEmails = [] } }) => email || secondaryEmails?.length > 0
        )
        .map(({ _id, company: { name, email, secondaryEmails = [] } }) => ({
          label: name,
          options: [
            ...(email
              ? [
                  {
                    label: email,
                    value: `${_id} / ${email}`,
                  },
                ]
              : []),
            ...secondaryEmails.map(secondaryEmail => ({
              label: secondaryEmail,
              value: `${_id} / ${secondaryEmail}`,
            })),
          ],
        })),
    [stakeHolders]
  )

  useEffect(() => {
    if (show) {
      analytics.track('NAVIGATION_VIEW_SEND_DOCUMENT', {
        Type: 'Task report',
      })
    }
  }, [show, analytics])

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    resetForm,
  } = useFormik<{
    taskId: string
    to: string[]
    subject: string
    content: string
    contentRichText: string
    attachments: FileUploadResponse[]
  }>({
    initialValues: {
      taskId: task?._id || '',
      to: [],
      subject: '',
      content: '',
      contentRichText: '',
      attachments: [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      to: Yup.array().of(Yup.string()).min(1),
      subject: Yup.string().required(),
      content: Yup.string().required(),
      contentRichText: Yup.string().required(),
      attachments: Yup.array()
        .of(
          Yup.object().shape({
            fileName: Yup.string(),
            path: Yup.string(),
          })
        )
        .min(1),
    }),
    onSubmit: data => {
      if (template) {
        toggle()
        sendTaskReportEmail(
          {
            ...data,
            to: data.to.map(to => to.split(' / ')[1]),
            attachments: data.attachments.map(({ fileName, path }) => ({ fileName, path })),
            metadata: {
              userEmail: companyUser.email,
              companyName: companyUser.name,
              formName: template.name,
            },
          },
          {
            onSettled: () => {
              setSubmitting(false)
            },
          }
        )
        resetForm()
      }
    },
  })

  const handleCloseModal = useCallback(() => {
    toggle()
    resetForm()
  }, [resetForm, toggle])

  const addTaskReportToAttachments = useCallback(
    async (taskToReport: Task, templateToReport: Template) => {
      try {
        const taskReport = await generateTaskReport(taskToReport._id)
        const reportName = `report_${templateToReport.name}_${dayjs().format('YYYY-MM-DD')}.pdf`
        const pdfReport = await generatePdfFromDefinitionAsync({
          pdfDefinition: JSON.stringify(taskReport),
          filename: reportName,
        })

        if (!pdfReport) throw new Error('Error generating PDF')

        const taskReportFileResponse = await uploadFileAsync(new File([pdfReport.blob], reportName))

        setFieldValue('attachments.0', taskReportFileResponse)
      } catch {
        handleCloseModal()
      }
    },
    [
      generateTaskReport,
      generatePdfFromDefinitionAsync,
      uploadFileAsync,
      setFieldValue,
      handleCloseModal,
    ]
  )

  useEffect(() => {
    if (task && template && show) {
      addTaskReportToAttachments(task, template)
    }
  }, [addTaskReportToAttachments, show, task, template, uploadFileAsync])

  const memoRichTextField = useMemo(
    () => (
      <RichTextField
        mode={RichTextFieldMode.EDITOR}
        id='contentRichText'
        key='contentRichText'
        name='contentRichText'
        label={t('content.label')}
        value={values.contentRichText}
        onChange={(richTextValue: string, plainTextValue: string) => {
          setFieldValue('content', plainTextValue)
          setFieldValue('contentRichText', richTextValue)
        }}
        error={touched.content && !!errors.content}
      />
    ),
    [errors.content, setFieldValue, t, touched.content, values.contentRichText]
  )

  return (
    <>
      <Modal
        size={SIZE.LARGE}
        show={show}
        toggle={handleCloseModal}
        dismissButton
        initialFocus={initialFocusRef}
      >
        <Modal.Title>{t('title', { name: template?.name })}</Modal.Title>
        <Modal.Body>
          <form className='text-left space-y-4' onSubmit={handleSubmit} ref={initialFocusRef}>
            <SelectMultiField
              id='to'
              name='to'
              options={targetOptions}
              label={t('to.label')}
              value={targetOptions
                .flatMap(({ options }) => options)
                .filter(option => values.to.includes(option.value))}
              onChange={options => {
                setFieldValue(
                  'to',
                  options.map(({ value }) => value)
                )
              }}
              error={touched.to && !!errors.to}
            />
            <InputField
              id='subject'
              name='subject'
              label={t('subject.label')}
              value={values.subject}
              onChange={handleChange}
              error={touched.subject && !!errors.subject}
            />
            {memoRichTextField}

            <FileDropField
              id='attachments'
              name='attachments'
              label={t('attachments.label')}
              description={t('attachments.description')}
              dragAndDropText={t('attachments.dragAndDropText')}
              uploadText={t('attachments.uploadText')}
              onUpload={files => {
                setFieldValue('attachments', [...values.attachments, ...files])
              }}
              disabled={!values.attachments[0]}
            />
            <ul className='flex flex-wrap'>
              {values.attachments.map((attachment, index) => (
                <li className='mb-3' key={attachment.path}>
                  <button
                    type='button'
                    className='mr-4 inline-flex text-left items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none'
                    onClick={() => {
                      if (index !== 0) {
                        setFieldValue(
                          'attachments',
                          values.attachments.filter(({ path }) => path !== attachment.path)
                        )
                      }
                    }}
                  >
                    {attachment.fileName}
                    {index !== 0 && (
                      <TrashIcon className='ml-2 -mr-0.5 h-4 w-4' aria-hidden='true' />
                    )}
                  </button>
                </li>
              ))}
            </ul>

            <div className='flex flex-row-reverse'>
              <Button type='submit' disabled={!values.attachments[0]}>
                {t('submit')}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {isSubmitting && <FullLoader className='z-50' />}
    </>
  )
}
