import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query'
import { customErrorToTrack, useAnalytics } from '../analytics'
import { useUploadFile } from '@/features/files'
import { Company, CompanyExtension, CompanyUpdate, CompanyUpdateMediaRaw } from './interfaces'
import {
  getMyCompany,
  getknownAs,
  requestExportData,
  updateCompanyProfile,
  updateExtension,
} from './service'
import * as NotifyService from '@/services/notify.service'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import { QUERY_MY_PROFILE_KEY, useAuthentication } from '../auth'
import { mergeQueryOptions } from '@/utils/merge-query-options'
import { useSearchParam } from '@/hooks/use-search-params'
import { useHistory } from 'react-router-dom'

export const QUERY_MY_COMPANY_KEY = 'my-company'
const QUERY_MY_COMPANY_KNOWN_AS_KEY = 'my-company-known-as'

export const useMyCompany = (
  overrideOptions?: Omit<UseQueryOptions<Company, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { isAuthenticated } = useAuthentication()
  const options = mergeQueryOptions(overrideOptions, {
    refetchOnMount: false,
    enabled: overrideOptions?.enabled === undefined ? isAuthenticated : overrideOptions?.enabled,
  })
  const { data, ...rest } = useQuery([QUERY_MY_COMPANY_KEY], getMyCompany, options)

  return {
    ...rest,
    company: data,
  }
}

export const useMyCompanyKnownAs = (
  options?: Omit<UseQueryOptions<string[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery([QUERY_MY_COMPANY_KNOWN_AS_KEY], getknownAs, options)

  return {
    ...rest,
    knownAs: data,
  }
}

export const useUpdateCompanyProfile = () => {
  const analytics = useAnalytics()
  const queryClient = useQueryClient()
  const { uploadFileAsync } = useUploadFile()
  const { t } = useTranslation('nsNotification')

  const { mutate, mutateAsync, ...rest } = useMutation(
    async ({
      media,
      ...data
    }: Omit<CompanyUpdate, 'media'> & { media?: CompanyUpdateMediaRaw }) => {
      const dataToUpdate: CompanyUpdate = { ...data }

      if (media?.logo) {
        const fileUploadResponse = await uploadFileAsync(media.logo)

        dataToUpdate.media = {
          logo: {
            mimeType: fileUploadResponse.mimeType,
            path: fileUploadResponse.path,
          },
        }
      } else if (media?.logo === null) {
        dataToUpdate.media = {
          logo: null,
        }
      }

      return updateCompanyProfile(dataToUpdate)
    },
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_MY_COMPANY_KEY)
        if (variables.visibility !== undefined) {
          if (variables.visibility) {
            analytics.track('CUSTOMER_ENABLE_EXTENSION', {
              Extension: 'Visibility',
            })
          } else {
            analytics.track('CUSTOMER_DISABLE_EXTENSION', {
              Extension: 'Visibility',
            })
          }
        }

        if (variables.media?.logo || variables.media?.logo === null) {
          analytics.track('CUSTOMER_CUSTOMIZE_DASHBOARD')
          NotifyService.success({
            title: t('profile.logo.change.success.title'),
            description: t('profile.logo.change.success.description'),
          })
        }
      },
      onError: ({ response }, variables) => {
        if (response) {
          // Visibility has been modified
          if (variables.visibility !== undefined) {
            if (variables.visibility) {
              analytics.track(
                'CUSTOMER_ENABLE_EXTENSION_ERROR',
                customErrorToTrack(response?.data, response?.status)
              )
            } else {
              analytics.track(
                'CUSTOMER_DISABLE_EXTENSION_ERROR',
                customErrorToTrack(response?.data, response?.status)
              )
            }
          }

          if (variables.media?.logo || variables.media?.logo === null) {
            analytics.track(
              'CUSTOMER_CUSTOMIZE_DASHBOARD_ERROR',
              customErrorToTrack(response?.data, response?.status)
            )
          }
        }
      },
    }
  )
  return { ...rest, updateCompanyProfile: mutate, uploadCompanyProfileAsync: mutateAsync }
}

export const useUpdateExtensionStock = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const referrer = useSearchParam('referrer')
  const history = useHistory()
  const queryClient = useQueryClient()
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({ stockEnable }: { stockEnable: boolean }) =>
      updateExtension(CompanyExtension.STOCK, stockEnable),
    {
      onSuccess(data, variables) {
        queryClient.invalidateQueries(QUERY_MY_COMPANY_KEY)
        if (variables.stockEnable && referrer === 'stakeholder-products') {
          NotifyService.success({
            title: t('profile.stock.enable.success.title'),
            description: t('profile.stock.enable.success.description'),
            actionText: t('profile.stock.enable.success.goBack'),
            onActionClick: () => history.push('/stakeholder-products'),
          })
          analytics.track('CUSTOMER_ENABLE_EXTENSION', {
            Extension: 'Stock management',
          })
        } else if (variables.stockEnable && !referrer) {
          NotifyService.success({
            title: t('profile.stock.enable.success.title'),
            description: t('profile.stock.enable.success.description'),
            actionText: t('profile.stock.enable.success.action'),
            onActionClick: () => history.push('/products'),
          })
          analytics.track('CUSTOMER_ENABLE_EXTENSION', {
            Extension: 'Stock management',
          })
        } else {
          NotifyService.success({
            title: t('profile.stock.disable.success.title'),
            description: t('profile.stock.disable.success.description'),
          })
          analytics.track('CUSTOMER_DISABLE_EXTENSION', {
            Extension: 'Stock management',
          })
        }
      },
      onError(error, variables) {
        const errorResponse = (error as AxiosError).response
        if (variables.stockEnable) {
          analytics.track(
            'CUSTOMER_ENABLE_EXTENSION_ERROR',
            customErrorToTrack(errorResponse?.data, errorResponse?.status)
          )
        } else {
          analytics.track(
            'CUSTOMER_DISABLE_EXTENSION_ERROR',
            customErrorToTrack(errorResponse?.data, errorResponse?.status)
          )
        }
      },
    }
  )

  return {
    ...rest,
    updateExtensionStock: mutate,
    updateExtensionStockAsync: mutateAsync,
  }
}

export const useUpdateExtensionFacilities = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const referrer = useSearchParam('referrer')
  const history = useHistory()
  const queryClient = useQueryClient()
  const { mutate, mutateAsync, ...rest } = useMutation(
    (status: boolean) => updateExtension(CompanyExtension.FACILITIES, status),
    {
      onSuccess(data, status) {
        queryClient.invalidateQueries(QUERY_MY_COMPANY_KEY)
        if (status && referrer === 'products') {
          NotifyService.success({
            title: t('profile.productionFacilities.enabled.success.title'),
            description: t('profile.productionFacilities.enabled.success.description'),
            actionText: t('profile.productionFacilities.enabled.success.goBack'),
            onActionClick: () => history.push('/products'),
          })
          analytics.track('CUSTOMER_ENABLE_EXTENSION', {
            Extension: 'Product facilities',
          })
        } else if (status && !referrer) {
          NotifyService.success({
            title: t('profile.productionFacilities.enabled.success.title'),
            description: t('profile.productionFacilities.enabled.success.description'),
            actionText: t('profile.productionFacilities.enabled.success.action'),
            onActionClick: () => history.push('/facilities'),
          })
          analytics.track('CUSTOMER_ENABLE_EXTENSION', {
            Extension: 'Product facilities',
          })
        } else {
          NotifyService.success({
            title: t('profile.productionFacilities.disabled.success.title'),
            description: t('profile.productionFacilities.disabled.success.description'),
          })
          analytics.track('CUSTOMER_DISABLE_EXTENSION', {
            Extension: 'Product facilities',
          })
        }
      },
      onError(error, status) {
        const errorResponse = (error as AxiosError).response
        if (status) {
          analytics.track(
            'CUSTOMER_ENABLE_EXTENSION_ERROR',
            customErrorToTrack(errorResponse?.data, errorResponse?.status)
          )
        } else {
          analytics.track(
            'CUSTOMER_DISABLE_EXTENSION_ERROR',
            customErrorToTrack(errorResponse?.data, errorResponse?.status)
          )
        }
      },
    }
  )

  return {
    ...rest,
    updateExtensionFacilities: mutate,
    updateExtensionFacilitiesAsync: mutateAsync,
  }
}

export const useRequestExportData = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const queryClient = useQueryClient()
  const { mutate, mutateAsync, ...rest } = useMutation(requestExportData, {
    onSuccess() {
      queryClient.invalidateQueries(QUERY_MY_COMPANY_KEY)
      queryClient.invalidateQueries(QUERY_MY_PROFILE_KEY)
      NotifyService.success({
        title: t('profilePage.personalInfoTab.request.title'),
        description: t('profilePage.personalInfoTab.request.description'),
      })
      analytics.track('CUSTOMER_EXPORTS_DATA')
    },
    onError(error: AxiosError) {
      analytics.track(
        'CUSTOMER_EXPORTS_DATA_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
    },
  })

  return {
    ...rest,
    requestExportData: mutate,
    requestExportDataAsync: mutateAsync,
  }
}
