import { Content, Margins, TDocumentDefinitions } from 'pdfmake/interfaces'
import trazableLogoBase64 from '@/assets/images/brand/logo-black-base64'
import i18n from '@/i18n'
import { Reception } from '@/features/receptions'
import { TRU } from '@/features/trus'

/**
 * PDF default footer content
 * @param {number} currentPage
 * @param {number} pageCount
 * @returns {Content} footer content
 * @memberof PDFService
 */
const defaultFooter: Content = {
  columns: [
    '',
    {
      image: 'trazableLogo',
      width: 100,
      alignment: 'center',
    },
    {
      text: i18n.t('nsPDF:pageOf', { currentPage: '$currentPage', totalPages: '$pageCount' }),
      alignment: 'right',
      margin: [15, 0],
    },
  ],
  margin: [0, 20, 0, 0],
}

/**
 * Create a PDF document
 *
 * @param {Content} content
 * @param {Content} header
 * @param {Content} footer Set null to remove default footer
 * @returns {TDocumentDefinitions} document created
 * @memberof PDFService
 */
export const createPdfDefinition = (
  content: Content,
  {
    header,
    footer = defaultFooter,
    images,
    pageMargins = [40, 60],
  }: {
    /**
     * All pages header
     */
    header?: Content
    /**
     * Set null to remove default footer
     * @default defaultFooter
     */
    footer?: Content | null

    /**
     * Page margins
     * @default [40, 60]
     * @see https://pdfmake.github.io/docs/0.1/document-definition-object/margins/
     * @example
     * // margin: [left, top, right, bottom]
     * [5, 2, 10, 20]
     *
     * // margin: [horizontal, vertical]
     * [5, 2]
     *
     * // margin: equalLeftTopRightBottom
     * 5
     */
    pageMargins?: Margins

    /**
     * Images dictionary to be used in the document
     * @see https://pdfmake.github.io/docs/document-definition-object/images/
     * @example
     * {
     *  trazableLogo: trazableLogoBase64,
     * }
     */
    images?: Record<string, string>
  } = {}
): TDocumentDefinitions => {
  const documentDefinition: TDocumentDefinitions = {
    info: {
      author: 'Trazable',
      creator: 'Trazable',
      producer: 'Trazable',
    },
    defaultStyle: {
      font: 'ArialUnicode',
    },
    header,
    footer: footer ?? undefined,
    pageMargins,
    styles: {
      header: {
        margin: [0, 0, 0, 10],
        fontSize: 18,
        bold: true,
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
        alignment: 'justify',
      },
      title: {
        fontSize: 12,
        bold: true,
        margin: [0, 0, 0, 8],
        alignment: 'justify',
      },
      subtitle: {
        fontSize: 12,
        margin: [0, 0, 0, 13],
        color: '#4B5563',
        alignment: 'justify',
      },
      table: {
        fontSize: 10,
        margin: [0, 5, 0, 10],
      },
      tableHeader: {
        bold: true,
        fontSize: 12,
        color: 'black',
        margin: [0, 10, 0, 5],
      },
      tableFooter: {
        bold: true,
        fontSize: 11,
        color: 'black',
        fillColor: '#f9fafb',
      },
      link: {
        decoration: 'underline',
      },
      underline: {
        decoration: 'underline',
      },
    },
    images: {
      trazableLogo: trazableLogoBase64,
      ...images,
    },
    content,
  }

  return documentDefinition
}

/**
 * PDF Components
 */
export const PDF_COMPONENTS = {
  /**
   * @param space Default 1
   */
  spacer: (space: number = 1): Content => ({
    text: '',
    margin: [0, space * 10],
  }),

  header: (text: string): Content => ({
    table: {
      widths: '*',
      body: [
        [
          {
            text,
            fillColor: '#1976d2',
            color: '#fff',
            styles: 'header',
            margin: [15, 0],
            bold: true,
          },
        ],
      ],
    },
    margin: [0, 0, 0, 15],
    layout: 'noBorders',
  }),

  subHeader: (text: string): Content => ({
    text,
    style: 'subheader',
  }),

  associateInfo: (trus: TRU[], receptions: Reception[], hasAssociations: boolean): Content => {
    const receptionsList = receptions.length
      ? {
          ul: [
            [
              i18n.t('nsTaskReport:associations:receptions'),
              receptions.map(r => {
                return {
                  ul: [r.reference],
                }
              }),
            ],
          ],
        }
      : {
          text: '',
        }

    const truList = trus.length
      ? {
          ul: [
            [
              i18n.t('nsTaskReport:associations:trus'),
              trus.map(t => {
                return {
                  ul: [t.reference],
                }
              }),
            ],
          ],
        }
      : {
          text: '',
        }
    if (hasAssociations) {
      return [
        {
          text: i18n.t('nsTaskReport:associations:title'),
          style: 'subheader',
        },
        receptionsList,
        truList,
      ]
    }

    return { text: '' }
  },

  /**
   * Page breaker
   */
  pageBreak: (): Content => ({
    pageBreak: 'after',
    text: '',
    fontSize: 0,
  }),
}
