import { ComponentProps } from 'react'

export const FacilitiesIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      {' '}
      <path
        d='M2 20.5C1.45 20.5 0.979333 20.3043 0.588 19.913C0.196 19.521 0 19.05 0 18.5V9.825C0 9.425 0.108333 9.05833 0.325 8.725C0.541667 8.39167 0.833333 8.14167 1.2 7.975L5.6 6.1C5.93333 5.96667 6.25 5.99567 6.55 6.187C6.85 6.379 7 6.65833 7 7.025V7.5L10.625 6.05C10.9583 5.91667 11.2707 5.95 11.562 6.15C11.854 6.35 12 6.625 12 6.975V8.5H20V18.5C20 19.05 19.8043 19.521 19.413 19.913C19.021 20.3043 18.55 20.5 18 20.5H2ZM18 10.5H10V8.45L5 10.45V8.5L2 9.825V18.5H18V10.5ZM10 16.5C10.2833 16.5 10.521 16.404 10.713 16.212C10.9043 16.0207 11 15.7833 11 15.5V13.5C11 13.2167 10.9043 12.979 10.713 12.787C10.521 12.5957 10.2833 12.5 10 12.5C9.71667 12.5 9.47933 12.5957 9.288 12.787C9.096 12.979 9 13.2167 9 13.5V15.5C9 15.7833 9.096 16.0207 9.288 16.212C9.47933 16.404 9.71667 16.5 10 16.5ZM6 16.5C6.28333 16.5 6.521 16.404 6.713 16.212C6.90433 16.0207 7 15.7833 7 15.5V13.5C7 13.2167 6.90433 12.979 6.713 12.787C6.521 12.5957 6.28333 12.5 6 12.5C5.71667 12.5 5.479 12.5957 5.287 12.787C5.09567 12.979 5 13.2167 5 13.5V15.5C5 15.7833 5.09567 16.0207 5.287 16.212C5.479 16.404 5.71667 16.5 6 16.5ZM14 16.5C14.2833 16.5 14.5207 16.404 14.712 16.212C14.904 16.0207 15 15.7833 15 15.5V13.5C15 13.2167 14.904 12.979 14.712 12.787C14.5207 12.5957 14.2833 12.5 14 12.5C13.7167 12.5 13.4793 12.5957 13.288 12.787C13.096 12.979 13 13.2167 13 13.5V15.5C13 15.7833 13.096 16.0207 13.288 16.212C13.4793 16.404 13.7167 16.5 14 16.5ZM20 8.5H15L15.9 1.375C15.9333 1.125 16.0417 0.916667 16.225 0.75C16.4083 0.583333 16.625 0.5 16.875 0.5H18.125C18.375 0.5 18.5917 0.583333 18.775 0.75C18.9583 0.916667 19.0667 1.125 19.1 1.375L20 8.5ZM18 18.5H2H18Z'
        fill='#93C5FD'
      />
    </svg>
  )
}
