import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  SelectField,
  SelectFieldProps,
  SelectorOption,
  createSelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { useState } from 'react'
import { usePaginatedTRUs, useTRUsByIds } from '../hooks'
import { ITRUFilters } from '../interfaces'

type TrusSelectFieldProps = Omit<
  SelectFieldProps,
  'options' | 'selectAll' | 'selectAllText' | 'onSelectAll'
> &
  Omit<ITRUFilters, 'search'> & {
    truIdValue?: string
  }

export const TruSelectField = ({
  productId,
  originId,
  truIdValue,
  ...props
}: TrusSelectFieldProps) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const {
    isLoading: paginatedTrusLoading,
    trus,
    fetchNextPage,
  } = usePaginatedTRUs({
    filters: { search: debouncedSearch, productId, originId },
  })

  const { trus: truValue, isLoading: truValueLoading } = useTRUsByIds([truIdValue || ''], {
    enabled: !!truIdValue,
  })

  const truOptions: SelectorOption[] = trus.map(tru => ({
    label: tru.reference,
    value: tru._id,
  }))

  const isLoading = paginatedTrusLoading || (!!truIdValue && truValueLoading)

  const value = truValue.length
    ? createSelectorOption(truValue[0].reference, truValue[0]._id)
    : props.value

  return (
    <SelectField
      {...props}
      value={value}
      isLoading={isLoading}
      options={truOptions}
      onInputChange={inputValue => {
        setSearch(inputValue)
      }}
      onMenuScrollToBottom={() => {
        fetchNextPage()
      }}
    />
  )
}
