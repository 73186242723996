import { getProductNameWithReference, useProductById } from '@/features/products'
import { useTemplate } from '@/features/templates'
import { useQueryTRUById } from '@/features/trus'
import { AutomationTriggerType } from '../../interfaces'
import {
  AuditAutomatedTask,
  AuditAutomation,
  AuditAutomationAndAutomatedTaskRequest,
} from './interfaces'

export const ActivityItemSubtitle = ({
  audit,
  previousAudit,
}: {
  audit: AuditAutomationAndAutomatedTaskRequest
  previousAudit?: AuditAutomation
}) => {
  let type = 'form'
  let id
  let fallback

  if (audit.collection === 'automations') {
    const automationAudit = audit as AuditAutomation

    if (automationAudit.data.trigger.type === AutomationTriggerType.ENTITY_EVENT) {
      type = 'product'
      id = automationAudit.data.trigger.entityEvent.params.productId
      fallback = automationAudit.data.trigger.entityEvent.params.productName
    } else {
      id = automationAudit.data.target.params.templateId
      fallback = automationAudit.data.target.params.templateName
    }
  } else if (audit.collection === 'tasks') {
    const taskAudit = audit as AuditAutomatedTask
    if (previousAudit?.data.trigger.type === AutomationTriggerType.ENTITY_EVENT) {
      type = 'tru'
      id = taskAudit.data.truIds[0]
    } else {
      id = taskAudit.data.formId
    }
  }
  const { template } = useTemplate(id || '', 'form', {
    enabled: type === 'form' && !!id,
  })

  const { product } = useProductById(id || '', {
    enabled: type === 'product' && !!id,
  })

  const { tru } = useQueryTRUById(id || '', {
    enabled: type === 'tru' && !!id,
  })

  return (
    <div className='text-xs leading-4 font-normal text-gray-400 '>
      {template?.name ||
        (product && getProductNameWithReference(product)) ||
        tru?.reference ||
        fallback}
    </div>
  )
}
