import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  SelectMultiField,
  SelectMultiFieldProps,
  SelectorOption,
  createSelectorOption,
  noop,
} from '@blockchain-traceability-sl/tailwind-components'
import { useEffect, useState } from 'react'
import { useFetchReceptions, usePaginatedReceptions, useReceptionsByIds } from '../hooks'
import { ReceptionFilters } from '../interfaces'

export type ReceptionsSelectMultiFieldProps = Omit<SelectMultiFieldProps, 'options'> &
  Omit<ReceptionFilters, 'search'> & { receptionsIdsValue?: string[] }

export const ReceptionsSelectMultiField = ({
  stakeHolderId,
  stakeHolderProductId,
  receptionsIdsValue,
  ...props
}: ReceptionsSelectMultiFieldProps) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const [receptionsOptions, setReceptionsOptions] = useState<SelectorOption[]>([])

  const {
    isLoading: isPaginatedReceptionsLoading,
    receptions,
    fetchNextPage,
  } = usePaginatedReceptions({
    filters: {
      search: debouncedSearch,
      stakeHolderId,
      stakeHolderProductId,
    },
  })

  const { fetchReceptions } = useFetchReceptions({
    search: debouncedSearch,
    stakeHolderId,
    stakeHolderProductId,
  })

  const { receptions: receptionsValues, isLoading: receptionsValuesIsLoading } = useReceptionsByIds(
    receptionsIdsValue || [],
    {
      enabled: !!receptionsIdsValue,
    }
  )

  const [isLoading, setIsLoading] = useState(isPaginatedReceptionsLoading)

  useEffect(() => {
    setReceptionsOptions(
      receptions.map(reception => createSelectorOption(reception.reference, reception._id))
    )
  }, [receptions])

  useEffect(() => {
    setIsLoading(isPaginatedReceptionsLoading || receptionsValuesIsLoading)
  }, [isPaginatedReceptionsLoading, receptionsValuesIsLoading])

  const value = receptionsValues.length
    ? receptionsValues.map(reception => createSelectorOption(reception.reference, reception._id))
    : props.value

  const handleSelectAll = async () => {
    setIsLoading(true)
    fetchReceptions()
      .then(receptions => {
        const newOptions = receptions.map(reception =>
          createSelectorOption(reception.reference, reception._id)
        )
        setReceptionsOptions(newOptions)
        if (props.onChange) {
          props.onChange(newOptions)
        }
      })
      .catch(noop)
      .finally(() => setIsLoading(false))
  }

  return (
    <SelectMultiField
      {...props}
      value={value}
      isLoading={isLoading}
      options={receptionsOptions}
      onInputChange={setSearch}
      onMenuScrollToBottom={() => fetchNextPage()}
      onSelectAll={handleSelectAll}
    />
  )
}
