import { api } from '@/app/api'
import { CustomerStripe } from './interfaces'

/**
 * Define the url path
 * @constant
 */
export const STRIPE_URL = '/stripe'

/**
 * Get Customer stripe data
 */
export const getCustomer = async (): Promise<CustomerStripe> => {
  const { data: customerStripe } = await api.get<CustomerStripe>(`${STRIPE_URL}/customers`)
  return customerStripe
}

/**
 * Get Customer stripe data
 */
export const getCustomerPortalUrl = async (
  customerId: string,
  currentUrl: string
): Promise<string> => {
  const { data: customerStripe } = await api.get<string>(
    `${STRIPE_URL}/customer-portal?${new URLSearchParams({
      customerId,
      url: currentUrl,
    })}`
  )
  return customerStripe
}

export const updateCustomerPreferences = async (params: { language: string }) => {
  await api.patch<void>(`${STRIPE_URL}/customers/update-preferences`, params)
}
