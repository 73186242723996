import { useToggle } from '@/hooks/use-toggle'
import { NotifyErrorPayload, onError } from '@/services/notify.service'
import { Alert, AlertType } from '@blockchain-traceability-sl/tailwind-components'
import { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'

export const NotificationContainer = () => {
  const [show, toggleShow] = useToggle(false)
  const [currentNotifyPayload, setCurrentNotifyPayload] = useState<NotifyErrorPayload>()

  useEffect(() => {
    onError(alert => {
      setCurrentNotifyPayload(alert)
      toggleShow(true)
    })
  }, [toggleShow])

  return (
    <>
      <Toaster position='top-right' />
      {currentNotifyPayload && (
        <Alert
          title={currentNotifyPayload.title}
          description={currentNotifyPayload.description}
          confirmText={currentNotifyPayload.actionText}
          type={currentNotifyPayload.severity ?? AlertType.ERROR}
          show={show}
          onConfirm={() => {
            toggleShow(false)
            currentNotifyPayload.onActionClick?.()
            setCurrentNotifyPayload(undefined)
          }}
        />
      )}
    </>
  )
}
