import { Help } from '@/components/Help'

import { StepOverview } from '@/components/StepOverview'
import { useStakeHolders } from '@/features/stakeHolders'
import {
  ButtonWithIcon,
  Card,
  InputField,
  SelectField,
  Step,
  TextAreaField,
} from '@blockchain-traceability-sl/tailwind-components'
import { ArrowSmDownIcon } from '@heroicons/react/solid'
import { FormikErrors, useField, useFormikContext } from 'formik'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { UploadRequestCreate } from '../interfaces'

type StepFields = Pick<UploadRequestCreate, 'subject' | 'content'> & { to: string }

export const StepOneCreateUploadRequest = () => {
  const { t } = useTranslation('nsStepOneCreateUploadRequest')

  const { setTouched } = useFormikContext<StepFields>()

  const [toField, toMeta, toHelper] = useField<string>('to')
  const [subjectField, subjectMeta] = useField<string>('subject')
  const [contentField, contentMeta] = useField<string>('content')

  const handleNext = async (callbackFunction: Function) => {
    const errors = (await setTouched({
      to: true,
      subject: true,
      content: true,
    })) as unknown as FormikErrors<StepFields>

    if (!errors?.to && !errors?.subject && !errors?.content) {
      callbackFunction()
    }
  }

  const { stakeHolders } = useStakeHolders()

  const targetOptions = useMemo(
    () =>
      stakeHolders
        .filter(
          ({ company: { email, secondaryEmails = [] } }) => email || secondaryEmails?.length > 0
        )
        .map(({ _id, company: { name, email, secondaryEmails = [] } }) => ({
          label: name,
          options: [
            ...(email
              ? [
                  {
                    label: email,
                    value: `${_id} / ${email}`,
                  },
                ]
              : []),
            ...secondaryEmails.map(secondaryEmail => ({
              label: secondaryEmail,
              value: `${_id} / ${secondaryEmail}`,
            })),
          ],
        })),
    [stakeHolders]
  )

  return (
    <Step
      title={t('title')}
      subtitle={t('subtitle')}
      overview={
        <StepOverview
          items={[toField.value.slice(toField.value.lastIndexOf('/') + 2), subjectField.value]}
        />
      }
    >
      {({ goNext }) => (
        <div className='space-y-6'>
          <Card className='overflow-visible'>
            <Card.Body>
              <div className='space-y-6'>
                <SelectField
                  id='to'
                  name='to'
                  options={targetOptions}
                  label={t('form.to.label')}
                  value={targetOptions
                    .flatMap(({ options }) => options)
                    .find(option => toField.value.includes(option.value))}
                  onChange={options => {
                    toHelper.setValue(options?.value || '')
                  }}
                  error={toMeta.touched && !!toMeta.error}
                />
                <InputField
                  id='subject'
                  name='subject'
                  label={t('form.subject.label')}
                  value={subjectField.value}
                  onChange={subjectField.onChange}
                  error={subjectMeta.touched && !!subjectMeta.error}
                />
                <TextAreaField
                  id='content'
                  name='content'
                  value={contentField.value}
                  onChange={contentField.onChange}
                  label={t('form.content.label')}
                  error={contentMeta.touched && !!contentMeta.error}
                />
              </div>
            </Card.Body>
          </Card>
          <div className='flex flex-wrap justify-between gap-4'>
            <Help analyticsProperties={{ Source: 'Request documents' }} />
            <div className='flex items-center gap-4'>
              <ButtonWithIcon IconComponent={ArrowSmDownIcon} onClick={() => handleNext(goNext)}>
                {t('nextText')}
              </ButtonWithIcon>
            </div>
          </div>
        </div>
      )}
    </Step>
  )
}
