import { useTranslation } from 'react-i18next'
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query'
import * as NotifyService from '@/services/notify.service'
import { customErrorToTrack, useAnalytics } from '@/features/analytics'
import {
  createChildCompliance,
  createCompliance,
  deleteChildCompliance,
  deleteCompliance,
  downloadComplianceFile,
  getChildCompliancesByPublicId,
  getComplianceById,
  getComplianceCount,
  getCompliances,
  getCompliancesByStakeHolderIds,
  getPaginatedCompliances,
  getPublicCompliance,
  getPublicUrlComplianceFile,
  submitPublicCompliance,
  updateCompliance,
  updatePublicCompliance,
  uploadPublicComplianceFile,
  validateCompliance,
} from './service'
import { AxiosError } from 'axios'
import { Paginated, PaginationParams } from '@/hooks/use-pagination'
import {
  AddChildToCompliance,
  ChildCompliance,
  Compliance,
  ComplianceCreateInput,
  ComplianceEntityType,
  ComplianceStatus,
  ComplianceUpdateInput,
  ComplianceValidationStatus,
  Conflict,
  PublicComplianceContactDetails,
  PublicComplianceWithChildren,
} from './interfaces'
import { mergeQueryOptions } from '@/utils/merge-query-options'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Entry,
  EntryCreateInput,
  UseFileEntryHandlerProps,
  parseEntriesFromDirtyEntries,
  useFileEntryHandler,
} from '@/features/entries'
import { DownloaderMIMEType, downloader } from '@/helpers/downloader'
import { useDebounceState } from '@/hooks/use-debounce-state'
import { TDocumentDefinitions } from 'pdfmake/interfaces'
import { StakeHolderType, useCreateStakeHolder } from '../stakeHolders'
import {
  generateComplianceReport,
  generateCompliancesReport,
  getLastComplianceValidation,
} from './utils'
import { capitalize } from '@/utils'
import { getComplianceReportName } from './utils/generate-report-name'
import { useGeneratePdfFromDefinition } from '../pdf'

const QUERY_COMPLIANCES_KEY = 'compliances'
const QUERY_COMPLIANCES_COUNT_KEY = 'compliances-count'
const QUERY_PUBLIC_COMPLIANCES_KEY = 'public-compliances'

export const useCreateCompliance = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()

  const { mutate, mutateAsync, ...rest } = useMutation(createCompliance, {
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries(QUERY_COMPLIANCES_KEY)
      NotifyService.success({
        title: t('compliance.create.success.title'),
        description: t('compliance.create.success.description'),
      })
      analytics.track('CUSTOMER_SEND_COMPLIANCE_REQUEST', {
        Form: variables.templateName,
        To: variables.compliantStakeHolder.emails,
      })
    },
    onError: (error: AxiosError) => {
      analytics.track(
        'CUSTOMER_SEND_COMPLIANCE_REQUEST_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return {
    ...rest,
    createCompliance: mutate,
    createComplianceAsync: mutateAsync,
  }
}

export const useUpdateCompliance = ({ silently }: { silently?: boolean } = {}) => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')

  const { mutate, mutateAsync, ...rest } = useMutation(
    ({ complianceId, ...inputData }: ComplianceUpdateInput & { complianceId: string }) =>
      updateCompliance(complianceId, inputData),
    {
      onMutate: ({ complianceId, ...inputData }) => {
        queryClient
          .getQueryCache()
          .findAll(QUERY_COMPLIANCES_KEY)
          .filter(query => query.isActive() && typeof query.queryKey[1] === 'object')
          .forEach(query => {
            queryClient.setQueryData<Compliance[] | Paginated<Compliance> | undefined>(
              query.queryKey,
              oldData => {
                if (!oldData) return []
                if (Array.isArray(oldData)) {
                  return oldData.map(dataItem =>
                    dataItem._id === complianceId ? { ...dataItem, ...inputData } : dataItem
                  )
                } else {
                  return {
                    ...oldData,
                    items: oldData?.items.map(dataItem =>
                      dataItem._id === complianceId ? { ...dataItem, ...inputData } : dataItem
                    ),
                  }
                }
              }
            )
          })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_COMPLIANCES_KEY)

        if (!silently) {
          NotifyService.success({
            title: t('compliance.edit.success.title'),
            description: t('compliance.edit.success.description'),
          })
        }
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_EDIT_COMPLIANCE_REQUEST_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    updateCompliance: mutate,
    updateComplianceAsync: mutateAsync,
  }
}

export const useDeleteCompliance = () => {
  const queryClient = useQueryClient()

  const { mutate, mutateAsync, ...rest } = useMutation(
    (complianceId: string) => deleteCompliance(complianceId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_COMPLIANCES_KEY)
        queryClient.invalidateQueries(QUERY_PUBLIC_COMPLIANCES_KEY)
      },
    }
  )

  return {
    ...rest,
    deleteCompliance: mutate,
    deleteComplianceAsync: mutateAsync,
  }
}

export const usePaginatedCompliances = (
  filter: {
    search?: string
    status?: (ComplianceStatus | ComplianceValidationStatus)[]
    hasParentCompliance?: boolean
    excludeChildsNotApproved?: true
  },
  pagination: PaginationParams,
  overrideOptions?: Omit<UseQueryOptions<Paginated<Compliance>, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const queryClient = useQueryClient()
  const options = mergeQueryOptions(overrideOptions, {
    keepPreviousData: true,
    onSuccess(data: Paginated<Compliance>) {
      overrideOptions?.onSuccess?.(data)
      data.items.forEach(item => {
        queryClient.setQueryData([QUERY_COMPLIANCES_KEY, item._id], item)
      })
    },
  })
  const { data, ...rest } = useQuery(
    [QUERY_COMPLIANCES_KEY, { filter, pagination }],
    () => getPaginatedCompliances(filter, pagination),
    options
  )

  const compliances = useMemo(() => data?.items || [], [data?.items])
  const totalCount = data?.totalCount || 0

  return {
    ...rest,
    compliances,
    totalCount,
  }
}

export const useFetchComplianceById = () => {
  const queryClient = useQueryClient()

  const fetchComplianceById = useCallback(
    (complianceId: string) => {
      return queryClient.fetchQuery([QUERY_COMPLIANCES_KEY, complianceId], () =>
        getComplianceById(complianceId)
      )
    },
    [queryClient]
  )

  return {
    fetchComplianceById,
  }
}

export const useComplianceById = (
  complianceId: string,
  options?: Omit<UseQueryOptions<Compliance | undefined, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_COMPLIANCES_KEY, complianceId],
    () => getComplianceById(complianceId),
    options
  )

  return { ...rest, compliance: data }
}

export const useCompliances = (
  filter: {
    search?: string
    status?: (ComplianceStatus | ComplianceValidationStatus)[]
    templateIds?: string[]
    stakeHolderIds?: string[]
    hasParentCompliance?: boolean
    parentComplianceIds?: string[]
  },
  options?: Omit<UseQueryOptions<Compliance[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_COMPLIANCES_KEY, { filter }],
    () => getCompliances(filter),
    options
  )

  const compliances = useMemo(() => data || [], [data])

  return {
    ...rest,
    compliances,
  }
}

export const useCompliancesCount = (
  filter: {
    search?: string
    status?: (ComplianceStatus | ComplianceValidationStatus)[]
    hasParentCompliance?: boolean
  },
  options?: Omit<UseQueryOptions<number, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_COMPLIANCES_COUNT_KEY, { filter }],
    () => getComplianceCount(filter),
    options
  )

  const totalCount = data || 0

  return {
    ...rest,
    totalCount,
  }
}

export const useCompliancesByStakeHolderIds = (
  {
    stakeHolderIds,
    status,
    active,
  }: { stakeHolderIds: string[]; status?: ComplianceStatus; active?: boolean },
  options?: Omit<UseQueryOptions<Compliance[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_COMPLIANCES_KEY, { stakeHolderIds, status }],
    () => getCompliancesByStakeHolderIds(stakeHolderIds, { status, active }),
    options
  )

  const compliances = useMemo(() => data || [], [data])

  return {
    ...rest,
    compliances,
  }
}

export const usePublicCompliance = (
  publicId: string,
  options?: Omit<
    UseQueryOptions<PublicComplianceWithChildren | undefined, AxiosError>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_PUBLIC_COMPLIANCES_KEY, publicId],
    () => getPublicCompliance(publicId),
    options
  )

  return {
    ...rest,
    compliance: data,
  }
}

export const usePublicComplianceAutoSave = (
  publicId: string,
  {
    values,
    enabled,
  }: {
    values: {
      entries: EntryCreateInput[]
      contactDetails: PublicComplianceContactDetails
      compliantStakeHolderProduct: {
        reference?: string
      }
      childCompliances: {
        entries: EntryCreateInput[]
        contactDetails?: PublicComplianceContactDetails
        compliantStakeHolderProduct: {
          reference?: string
        }
        publicId: string
        compliantEntityType: ComplianceEntityType
      }[]
    }
    enabled: boolean
  }
) => {
  const { mutate: savePublicCompliance } = useMutation(
    (update: {
      publicId?: string
      entries: Entry[]
      contactDetails?: PublicComplianceContactDetails
      compliantStakeHolderProduct: {
        reference?: string
      }
    }) =>
      updatePublicCompliance({
        publicId: update.publicId || publicId,
        body: update,
      })
  )

  const changedValues = useMemo(
    () => ({
      entries: values.entries,
      contactDetails: {
        name: values.contactDetails.name,
        countryCode: values.contactDetails.countryCode,
        reference: values.contactDetails.reference,
        localization: {
          address: values.contactDetails.localization?.address,
        },
        type: values.contactDetails.type,
      },
      compliantStakeHolderProduct: {
        reference: values.compliantStakeHolderProduct?.reference,
      },
      childCompliances: values.childCompliances.map(childCompliance => ({
        entries: childCompliance.entries,
        compliantStakeHolderProduct: {
          reference: childCompliance.compliantStakeHolderProduct?.reference,
        },
        publicId: childCompliance.publicId,
      })),
    }),
    [
      values.entries,
      values.contactDetails.name,
      values.contactDetails.countryCode,
      values.contactDetails.reference,
      values.contactDetails.localization?.address,
      values.contactDetails.type,
      values.compliantStakeHolderProduct?.reference,
      values.childCompliances,
    ]
  )

  const [debouncedValues] = useDebounceState(changedValues)

  useEffect(() => {
    if (!enabled) return
    // Save root compliance
    savePublicCompliance({
      entries: parseEntriesFromDirtyEntries(debouncedValues.entries),
      contactDetails: debouncedValues.contactDetails,
      compliantStakeHolderProduct: debouncedValues.compliantStakeHolderProduct,
    })

    // Save child compliances
    if (debouncedValues.childCompliances.length > 0) {
      debouncedValues.childCompliances.forEach(childCompliance => {
        savePublicCompliance({
          publicId: childCompliance.publicId,
          entries: parseEntriesFromDirtyEntries(childCompliance.entries),
          compliantStakeHolderProduct: childCompliance.compliantStakeHolderProduct,
        })
      })
    }
  }, [debouncedValues, enabled, savePublicCompliance])
}

export const useSubmitPublicCompliance = () => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({
      publicId,
      templateName,
      ...rest
    }: {
      publicId: string
      templateName: string
      entries: Entry[]
      contactDetails: PublicComplianceContactDetails
      compliantStakeHolderProduct?: {
        reference?: string
      }
      childCompliances?: {
        entries: Entry[]
        contactDetails?: PublicComplianceContactDetails
        compliantStakeHolderProduct?: {
          reference?: string
        }
        publicId: string
        compliantEntityType: ComplianceEntityType
      }[]
    }) => submitPublicCompliance(publicId, rest),
    {
      onSuccess: async (data, { templateName }) => {
        queryClient.invalidateQueries(QUERY_PUBLIC_COMPLIANCES_KEY)

        analytics.track('PROVIDER_REQUEST_VALIDATION', {
          Type: 'Compliance',
          Name: templateName,
        })
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'PROVIDER_REQUEST_VALIDATION_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    submitPublicCompliance: mutate,
    submitPublicComplianceAsync: mutateAsync,
  }
}

export const useValidateCompliance = () => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({
      id,
      status,
      message,
      conflicts,
    }: {
      id: string
      templateName: string
      status: ComplianceValidationStatus
      message?: string
      conflicts?: Conflict[]
      options?: { silently?: boolean }
    }) =>
      validateCompliance(id, {
        status,
        message,
        conflicts,
      }),
    {
      onSuccess: async (data, { status, templateName, message, options }) => {
        queryClient.invalidateQueries(QUERY_COMPLIANCES_KEY)
        queryClient.invalidateQueries(QUERY_COMPLIANCES_COUNT_KEY)

        if (!options?.silently) {
          NotifyService.success({
            title: t(`compliance.validation.${status.toLowerCase()}.title`),
            description: t(`compliance.validation.${status.toLowerCase()}.description`),
          })

          analytics.track('CUSTOMER_VALIDATE_REQUEST', {
            Status: capitalize(status),
            Type: 'Compliance',
            Name: templateName,
            Message: message,
          })
        }
      },
      onError: ({ response }: AxiosError, variables) => {
        NotifyService.error(NotifyService.customErrorToNotify(response?.data))
        analytics.track('CUSTOMER_VALIDATE_REQUEST_ERROR', {
          ...customErrorToTrack(response?.data, response?.status),
          Status: capitalize(variables.status),
          Type: 'Compliance',
        })
      },
    }
  )

  return {
    ...rest,
    validateCompliance: mutate,
    validateComplianceAsync: mutateAsync,
  }
}

export const useUploadPublicComplianceFile = (publicId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation((file: File) =>
    uploadPublicComplianceFile(publicId, file)
  )

  return {
    ...rest,
    uploadFile: mutate,
    uploadFileAsync: mutateAsync,
  }
}

export const useComplianceFileEntryHandler = (
  publicId: string,
  props: Omit<UseFileEntryHandlerProps, 'getPublicUrl' | 'downloadFile' | 'uploadFile'>
) => {
  const { uploadFileAsync } = useUploadPublicComplianceFile(publicId)
  const getPublicUrl = useCallback(
    (filename: string) => getPublicUrlComplianceFile(publicId, filename),
    [publicId]
  )
  const downloadFile = useCallback(
    (filename: string, mimeType: DownloaderMIMEType) =>
      downloadComplianceFile(publicId, filename, mimeType),
    [publicId]
  )
  return useFileEntryHandler({
    ...props,
    getPublicUrl,
    downloadFile,
    uploadFile: uploadFileAsync,
  })
}

export enum ComplianceReportType {
  PENDING_TO_VALIDATE,
  APPROVED_COMPLIANCE,
}
export interface UseComplianceReportProps {
  compliance?: Compliance
  childCompliances?: Compliance[]
  reportType: ComplianceReportType
}

export const useGenerateComplianceReport = () => {
  const { t, i18n } = useTranslation('nsComplianceReport')

  const { generatePdfFromDefinitionAsync } = useGeneratePdfFromDefinition()

  const generateReportData = useCallback(
    async function (compliance: Compliance, reportType: ComplianceReportType) {
      const report = await generateComplianceReport(compliance, {
        t,
        withTitle: reportType === ComplianceReportType.APPROVED_COMPLIANCE,
        direction: i18n.dir(),
      })

      const result = await generatePdfFromDefinitionAsync({
        pdfDefinition: JSON.stringify(report),
        filename: getComplianceReportName(compliance, reportType, t),
      })
      if (!result) throw new Error('Error generating report')

      return {
        report,
        ...result,
      }
    },
    [t, i18n, generatePdfFromDefinitionAsync]
  )

  return {
    generateReportData,
  }
}

export const useComplianceReport = (
  { compliance, childCompliances, reportType }: UseComplianceReportProps,
  options?: { enabled?: boolean }
) => {
  const { t, i18n } = useTranslation('nsComplianceReport')
  const dataEnabled = !!compliance
  const enabled = options ? dataEnabled && options.enabled : dataEnabled
  const [reportData, setReportData] = useState<
    { report: TDocumentDefinitions; url: string; blob?: Blob } | undefined
  >()

  const { generatePdfFromDefinitionAsync } = useGeneratePdfFromDefinition()

  const generateReportData = useCallback(
    async function (): Promise<
      | {
          report: TDocumentDefinitions
          url: string
          blob?: Blob
        }
      | undefined
    > {
      if (!compliance) return
      const report = await generateCompliancesReport([compliance, ...(childCompliances ?? [])], {
        t,
        withTitle: reportType === ComplianceReportType.APPROVED_COMPLIANCE,
        direction: i18n.dir(),
      })

      const result = await generatePdfFromDefinitionAsync({
        pdfDefinition: JSON.stringify(report),
        filename: getComplianceReportName(compliance, reportType, t),
      })

      if (result) {
        return {
          report,
          url: result.dataUrl,
          blob: result.blob,
        }
      }
    },
    [compliance, childCompliances, t, reportType, i18n, generatePdfFromDefinitionAsync]
  )

  useEffect(() => {
    if (!enabled) {
      if (reportData) {
        setReportData(undefined)
      }
      return
    }

    if (reportData) return

    generateReportData().then(setReportData)
  }, [compliance, enabled, generateReportData, reportData, reportType, t])

  const downloadComplianceReport = async () => {
    if (!compliance) return
    let report = reportData
    if (!report) {
      const generatedReportData = await generateReportData()
      report = generatedReportData
    }

    if (!report?.blob) return
    downloader(report?.blob, 'application/pdf', getComplianceReportName(compliance, reportType, t))
  }

  return {
    complianceReportUrl: reportData?.url,
    downloadComplianceReport,
    generateReportData,
  }
}

export const useComplianceReportDownloader = () => {
  const { t, i18n } = useTranslation('nsComplianceReport')

  const { generatePdfFromDefinitionAsync } = useGeneratePdfFromDefinition()

  const generateComplianceReportData = async function (
    compliance: Compliance,
    childCompliances: Compliance[] = []
  ): Promise<
    | {
        report: TDocumentDefinitions
        url: string
        blob?: Blob
        filename: string
      }
    | undefined
  > {
    const report = await generateCompliancesReport([compliance, ...childCompliances], {
      t,
      withTitle: true,
      direction: i18n.dir(),
    })

    const filename = getComplianceReportName(
      compliance,
      ComplianceReportType.APPROVED_COMPLIANCE,
      t
    )

    const result = await generatePdfFromDefinitionAsync({
      pdfDefinition: JSON.stringify(report),
      filename,
    })

    if (result) {
      return {
        report,
        url: result.dataUrl,
        blob: result.blob,
        filename,
      }
    }
  }

  const downloadComplianceReport = async (complianceId: string) => {
    const compliance = await getComplianceById(complianceId)
    if (!compliance) return

    let childCompliances
    if (
      getLastComplianceValidation(compliance.validationState)?.status !==
      ComplianceValidationStatus.APPROVED
    ) {
      childCompliances = await getCompliances({ parentComplianceIds: [complianceId] })
    }

    const generatedReport = await generateComplianceReportData(compliance, childCompliances)

    if (!generatedReport?.blob) return
    downloader(generatedReport?.blob, 'application/pdf', generatedReport.filename)
  }

  return {
    downloadComplianceReport,
  }
}

export const useCreateContactStakeholderAndComplianceByEmails = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const { createStakeHolderAsync: createContactStakeHolderAsync } = useCreateStakeHolder({
    silently: true,
  })

  const { mutate, mutateAsync, ...rest } = useMutation(
    ({
      stakeHolderEmails,
      ...complianceData
    }: Omit<ComplianceCreateInput, 'compliantStakeHolder'> & {
      stakeHolderEmails: string[]
    }) => {
      const promises = stakeHolderEmails.map(async email => {
        const stakeHolderId = await createContactStakeHolderAsync({
          name: email,
          email,
          type: StakeHolderType.CONTACT,
        })

        return createCompliance({
          ...complianceData,
          compliantStakeHolder: {
            id: stakeHolderId,
            name: email,
            emails: [email],
            isContact: true,
          },
        })
      })

      return Promise.all(promises)
    },
    {
      onSuccess: async (data, variables) => {
        queryClient.invalidateQueries(QUERY_COMPLIANCES_KEY)
        NotifyService.success({
          title: t('compliance.create.success.title'),
          description: t('compliance.create.success.description'),
        })
        analytics.track('CUSTOMER_SEND_COMPLIANCE_REQUEST', {
          Form: variables.templateName,
          To: variables.stakeHolderEmails,
        })
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_SEND_COMPLIANCE_REQUEST_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    createContactStakeholderAndComplianceByEmails: mutate,
    createContactStakeholderAndComplianceByEmailsAsync: mutateAsync,
  }
}

export const usePublicCreateChildCompliance = () => {
  const queryClient = useQueryClient()
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({ publicId, ...childCompliance }: AddChildToCompliance & { publicId: string }) =>
      createChildCompliance(publicId, childCompliance),
    {
      onSuccess() {
        queryClient.invalidateQueries(QUERY_PUBLIC_COMPLIANCES_KEY)
        queryClient.invalidateQueries(QUERY_COMPLIANCES_KEY)
      },
    }
  )

  return {
    ...rest,
    publicCreateChildCompliance: mutate,
    publicCreateChildComplianceAsync: mutateAsync,
  }
}

export const usePublicDeleteChildCompliance = () => {
  const queryClient = useQueryClient()
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({
      publicId,
      childCompliancePublicId,
    }: {
      publicId: string
      childCompliancePublicId: string
    }) => deleteChildCompliance(publicId, childCompliancePublicId),
    {
      onSuccess() {
        queryClient.invalidateQueries(QUERY_PUBLIC_COMPLIANCES_KEY)
        queryClient.invalidateQueries(QUERY_COMPLIANCES_KEY)
      },
    }
  )

  return {
    ...rest,
    publicDeleteChildCompliance: mutate,
    publicDdeleteChildComplianceAsync: mutateAsync,
  }
}

export const useChildCompliancesByPublicId = (
  publicId: string,
  options?:
    | Omit<
        UseQueryOptions<ChildCompliance[], unknown, ChildCompliance[], string[]>,
        'queryKey' | 'queryFn'
      >
    | undefined
) => {
  const { data, ...rest } = useQuery(
    [QUERY_PUBLIC_COMPLIANCES_KEY, 'childCompliances', publicId],
    () => getChildCompliancesByPublicId(publicId),
    options
  )

  return {
    ...rest,
    childCompliances: data,
  }
}

export const useUpdatePublicCompliance = () => {
  const queryClient = useQueryClient()
  const { mutate, mutateAsync, ...rest } = useMutation(updatePublicCompliance, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_PUBLIC_COMPLIANCES_KEY)
      queryClient.invalidateQueries(QUERY_COMPLIANCES_KEY)
    },
  })

  return {
    ...rest,
    updatePublicCompliance: mutate,
    updatePublicComplianceAsync: mutateAsync,
  }
}
