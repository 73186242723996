import { useCallback, useState } from 'react'

export const DEFAULT_PAGE_SIZE = 10

export interface PaginationParams {
  offset: number
  limit: number
}

export interface Paginated<TEntity> {
  items: TEntity[]
  totalCount: number
  pageInfo: {
    currentPage: PaginationParams
    nextPage: PaginationParams | null
    previousPage: PaginationParams | null
  }
}

export const usePagination = ({ pageSize = DEFAULT_PAGE_SIZE }: { pageSize?: number } = {}) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageOffset: 0,
    pageSize,
  })

  const onPageChange = useCallback(
    ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => {
      setPagination({
        pageIndex,
        pageSize,
        pageOffset: pageIndex * pageSize,
      })
    },
    []
  )

  return {
    ...pagination,
    onPageChange,
  }
}
