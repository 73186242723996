import { api } from '@/app/api'
import {
  PublicPricingPlans,
  SubscriptionCreation,
  SubscriptionCreationResult,
  SubscriptionInfo,
  SubscriptionPaymentInfo,
  SubscriptionPlanUpdate,
} from './interfaces'

const PRICING_URL = '/stripe'

export const getPublicPricingPlans = async () => {
  const { data } = await api.get<PublicPricingPlans>(`${PRICING_URL}/public/pricing-plans`)
  return data
}

export const createSubscription = async (creationData: SubscriptionCreation) => {
  const { data } = await api.post<SubscriptionCreationResult>(
    `${PRICING_URL}/subscriptions`,
    creationData
  )
  return data
}

export const getSubscriptionPaymentInfo = async () => {
  const { data } = await api.get<SubscriptionPaymentInfo>(
    `${PRICING_URL}/subscriptions/payment-info`
  )
  return data
}

export const getSubscriptionInfo = async () => {
  const { data } = await api.get<SubscriptionInfo>(`${PRICING_URL}/subscriptions/subscription-info`)
  return data
}

export const updateSubscriptionPlan = async (updateData: SubscriptionPlanUpdate) => {
  await api.patch<void>(`${PRICING_URL}/subscriptions/plan`, updateData)
}

export const getSubscriptionCustomerPortal = async () => {
  const { data } = await api.get<{ url: string }>(`${PRICING_URL}/subscriptions/portal`)

  return data
}
