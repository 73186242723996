import { useAnalytics } from '@/features/analytics'
import { useTemplatesForTasks } from '@/features/templates'
import { useFilterAlgorithm } from '@/hooks/use-filter-algorithm'
import {
  Button,
  CheckboxField,
  InputField,
  Loader,
  Modal,
  SIZE,
} from '@blockchain-traceability-sl/tailwind-components'
import { TrashIcon } from '@heroicons/react/outline'
import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ModalAddTasksProps {
  show: boolean
  daySelected: Dayjs | null
  toggle: () => void
  onSubmit: (templateIds: string[]) => void
}

export const ModalAddTasks = ({ show, daySelected, toggle, onSubmit }: ModalAddTasksProps) => {
  const { t } = useTranslation('nsTasksListPage')
  const analytics = useAnalytics()
  const { templates, isLoading } = useTemplatesForTasks({ sortBy: 'name' })

  const { data, handleInputFilterChange, filterValue, setFilterValue } = useFilterAlgorithm(
    templates,
    ({ name }) => ({
      name,
    })
  )

  const [templatesSelected, setTemplatesSelected] = useState<string[]>([])

  // Reinitialize selections on close the modal
  useEffect(() => {
    if (show) {
      analytics.track('NAVIGATION_VIEW_CREATE_TASK', { Step: 'Bulk' })
    }
  }, [analytics, show])

  const handleAddTemplate = (templateId: string) => {
    setTemplatesSelected(currentValues => [...currentValues, templateId])
  }
  const handleRemoveTemplate = (templateId: string) => {
    setTemplatesSelected(currentValues =>
      currentValues.filter(currentValue => currentValue !== templateId)
    )
  }
  const handleTemplateClick = (templateId: string) => {
    if (templatesSelected.includes(templateId)) {
      handleRemoveTemplate(templateId)
    } else {
      handleAddTemplate(templateId)
    }
  }
  const handleSelectAll = () => {
    if (data.every(item => templatesSelected.includes(item._id))) {
      const dataIds = data.map(item => item._id)
      setTemplatesSelected(previous => previous.filter(id => !dataIds.includes(id)))
    } else {
      setTemplatesSelected(previous =>
        Array.from(new Set([...previous, ...data.map(({ _id }) => _id)]))
      )
    }
  }
  const handleSubmit = () => {
    onSubmit(templatesSelected)
    toggle()
  }

  // Reinitialize selections on close the modal
  useEffect(() => {
    if (!show) {
      setTemplatesSelected([])
      setFilterValue('')
    }
  }, [analytics, setFilterValue, show])

  return (
    <Modal
      show={show}
      size={SIZE.LARGE}
      className='overflow-y-visible'
      toggle={toggle}
      dismissButton
    >
      <Modal.Title>{t('calendar.modalAddTasks.title')}</Modal.Title>
      <Modal.Body className='overflow-y-visible'>
        {isLoading || !daySelected ? (
          <Loader center='full' />
        ) : (
          <div className='md:grid grid-cols-2 divide-x'>
            {/* Selector zone */}
            <div className='pr-4'>
              <InputField
                id='templates-search'
                name='templates-search'
                type='search'
                value={filterValue}
                onChange={handleInputFilterChange}
              />
              <div className='py-2'>
                <CheckboxField
                  id='templates-select-all'
                  name='templates-select-all'
                  className='cursor-pointer'
                  label={t('calendar.modalAddTasks.selectAll')}
                  checked={data.every(item => templatesSelected.includes(item._id))}
                  onChange={handleSelectAll}
                />
              </div>

              {/* Templates list */}
              <div className='gap-3 flex flex-col flex-nowrap overflow-y-scroll max-h-112 p-1'>
                {data.map(({ _id, name }) => (
                  <div
                    key={_id}
                    className='text-xs leading-5 font-medium text-gray-500 p-3 rounded-lg shadow flex cursor-pointer'
                    onClick={() => handleTemplateClick(_id)}
                  >
                    <CheckboxField
                      id={_id}
                      name={_id}
                      checked={templatesSelected.includes(_id)}
                      className='cursor-pointer'
                      readOnly
                    />
                    <span className='text-left'>{name}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Selected zone */}
            <div className='pl-4 pt-1'>
              <div className='rounded-b-lg border-gray-200 border divide-y divide-gray-200 h-full overflow-y-hidden'>
                <div className='py-1'>
                  <span className='text-lg leading-7 font-semibold text-gray-700'>
                    {daySelected.format('D')}
                  </span>{' '}
                  <span className='text-lg leading-7 font-normal text-gray-700 capitalize'>
                    {daySelected.format('dddd')}
                  </span>
                </div>
                <div className='bg-gray-50'>
                  <div className='h-10 w-full' />
                  <div className='space-y-3 overflow-y-scroll h-112 px-3 pb-3'>
                    {templatesSelected.map(templateSelected => (
                      <div
                        key={templateSelected}
                        className='text-xs leading-5 font-medium text-gray-500 p-3 rounded-lg shadow flex gap-2 cursor-pointer group bg-white'
                        onClick={() => handleTemplateClick(templateSelected)}
                      >
                        <span>
                          <TrashIcon className='w-5 h-5 text-gray-400 group-hover:text-gray-500' />
                        </span>
                        <span className='text-xs leading-5 font-medium text-gray-500 text-left'>
                          {templates.find(({ _id }) => _id === templateSelected)?.name}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit} disabled={templatesSelected.length === 0}>
          {t('calendar.modalAddTasks.submit')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
