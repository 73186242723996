import { Metadata } from '@/features/metadatas'
import { Task, TaskReportSent } from '@/features/tasks'
import { ValidationRequest } from '@/features/validationRequests'

export enum AuditOperation {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export interface Audit<DataType = unknown, OperationType = AuditOperation> {
  _id: string
  companyId: string
  operation: OperationType
  collection: string
  microserviceTrigger: string
  data: {
    _id: string
  } & DataType
  createdAt: string
  updatedAt: string
}

export interface AuditMetadata extends Audit<Metadata> {
  data: Metadata
}

export type AuditTaskRelated = Audit<Task | ValidationRequest | TaskReportSent>
