import { Formik, FormikProps, FormikValues } from 'formik'
import { ReactNode, useMemo } from 'react'
import { MemoFormStatus, UseMemoFormProps } from '../interfaces'
import { MemoFormMemorizer } from './MemoFormMemorizer'
import { generateStoreKey } from '../utils'
import { useRemoveState } from '../hooks'

export interface MemoFormProps<Values extends FormikValues = FormikValues>
  extends UseMemoFormProps<Values> {
  children?: ((props: FormikProps<Values>) => ReactNode) | ReactNode
  /***
   * Enable or disable the memorization until the form dynamic data is loaded, to prevent unexpected behaviors
   * @default true
   * The default value is true to support current MemoForm behavior
   */
  enabled?: boolean
}

export const MemoForm = <Values extends FormikValues = FormikValues>({
  entity,
  entityId,
  action,
  children,
  onSubmit,
  enabled = true,
  ...props
}: MemoFormProps<Values>) => {
  /**
   * Dinamic store key based on entity and action to be unique
   */

  const storeKey = useMemo(
    () => generateStoreKey({ entity, entityId, action }),
    [action, entity, entityId]
  )
  const { removeStateIDB } = useRemoveState(entity, storeKey)

  return (
    <Formik
      {...props}
      initialStatus={MemoFormStatus.LOADING}
      onSubmit={(values, formikHelpers) => {
        onSubmit(values, formikHelpers)
        removeStateIDB()
      }}
      onReset={() => removeStateIDB()}
    >
      {(...context) => (
        <>
          <MemoFormMemorizer storeKey={storeKey} enabled={enabled} entity={entity} />
          {typeof children === 'function' ? children(...context) : children}
        </>
      )}
    </Formik>
  )
}
