import { useToggle } from '@/hooks/use-toggle'
import { DropAreaField, DropAreaFieldProps } from '@blockchain-traceability-sl/tailwind-components'
import { useUploadFile } from '../hooks'
import { FileUploadResponse } from '../interfaces'

/**
 * Max file size 32MB
 */
const MAX_FILE_SIZE = 32000000

interface FileDropFieldProps
  extends Omit<DropAreaFieldProps, 'onDrop' | 'onDropAccepted' | 'getFilesFromEvent'> {
  onUpload: (files: (FileUploadResponse & { file: File })[]) => void
}

export const FileDropField = ({ onUpload, ...props }: FileDropFieldProps) => {
  const [isUploading, toggleIsUploading] = useToggle(false)
  const { uploadFileAsync } = useUploadFile({
    withPublicUrl: props.preview,
  })

  const handleDropAccepted = async (files: File[]) => {
    toggleIsUploading(true)
    const results = await Promise.all(
      files.map(async file => ({
        ...(await uploadFileAsync(file)),
        file,
      }))
    )
    toggleIsUploading(false)
    onUpload(results)
  }

  return (
    <DropAreaField
      {...props}
      onDropAccepted={handleDropAccepted}
      maxSize={MAX_FILE_SIZE}
      isLoading={isUploading}
    />
  )
}
