import { InputField } from '@blockchain-traceability-sl/tailwind-components'
import { EntryFieldProps } from '../interfaces'
import { EntryLabel } from '../EntryLabel'
import { useEntryFieldAttributes } from '../utils'
import { useTranslation } from 'react-i18next'
import { TemplateEntryType } from '@/features/templates'
import { useField } from 'formik'
import { EntryCreateInput } from '@/features/entries'

export const LinkEntryField = ({ index, prefixName, disabled }: EntryFieldProps) => {
  const { t } = useTranslation('nsEntries')

  const { entryFieldName, valueFieldName, enabledFieldName, enabledFieldId, valueFieldId } =
    useEntryFieldAttributes(index, prefixName)

  const [entryInputField] = useField<EntryCreateInput>(entryFieldName)
  const [, , enabledInputFieldHelpers] = useField<boolean>(enabledFieldName)
  const [valueInputField, { error, touched }] = useField<string>(valueFieldName)

  const requiredError = !!error && touched

  const entry = entryInputField.value

  if (entry.type !== TemplateEntryType.LINK) return null

  const isEntryEnabled = entry.enabled ?? true

  return (
    <InputField
      type='url'
      id={valueFieldId}
      name={valueFieldName}
      label={
        <EntryLabel
          naCheckboxId={enabledFieldId}
          naCheckboxName={enabledFieldName}
          naUnchecked={isEntryEnabled}
          onNaChange={enabled => {
            enabledInputFieldHelpers.setValue(enabled)
            enabledInputFieldHelpers.setTouched(true)
          }}
          naCheckboxLabel={t('form.na')}
          mandatoryPrefixText={t('form.mandatory.indicator')}
          isMandatory={entry.mandatory}
          disabled={disabled}
        >
          {entry.name}
        </EntryLabel>
      }
      placeholder={t('form.placeholders.link')}
      onBlur={valueInputField.onBlur}
      onChange={valueInputField.onChange}
      value={entry.value}
      error={requiredError}
      errorText={t('form.validation.link')}
      disabled={!isEntryEnabled || disabled}
    />
  )
}
