import { Paginated, PaginationParams } from '@/hooks/use-pagination'
import {
  CustomReportSendByEmailRequest,
  CustomReportTemplate,
  StockReportInput,
} from './interfaces'
import { api } from '@/app/api'

export const CUSTOM_REPORT_TEMPLATES_URL = `/charts/custom-report-templates`
export const CUSTOM_REPORTS_URL = `/charts/custom-reports`
export const PLATFORM_REPORTS_URL = `/charts/platform-reports`

export const getPaginatedCustomReportTemplates = async ({
  search,
  pagination,
}: {
  search?: string
  pagination: PaginationParams
}): Promise<Paginated<CustomReportTemplate>> => {
  const searchParams = new URLSearchParams()
  if (pagination.limit) searchParams.append('limit', pagination.limit.toString())
  if (pagination.offset !== undefined) searchParams.append('offset', pagination.offset.toString())

  if (search) searchParams.append('search', search)

  const { data } = await api.get<Paginated<CustomReportTemplate>>(
    `${CUSTOM_REPORT_TEMPLATES_URL}?${searchParams.toString()}`
  )
  return data
}

export const getCustomReportTemplateById = async (
  id: string
): Promise<CustomReportTemplate | undefined> => {
  const { data } = await api.get<CustomReportTemplate | void>(
    `${CUSTOM_REPORT_TEMPLATES_URL}/${id}`
  )
  return data || undefined
}

export const generateCustomReport = async (params: {
  customReportTemplateId: string
  sourceId: string
}): Promise<Blob | undefined> => {
  const { data } = await api.post<Blob>(CUSTOM_REPORTS_URL, params, {
    responseType: 'blob',
  })
  return data
}

export const sendCustomReportByEmail = async (
  params: CustomReportSendByEmailRequest
): Promise<void> => {
  await api.post(`${CUSTOM_REPORTS_URL}/sendByEmail`, params)
}

export const generateTasksSummaryReport = async ({
  startDate,
  endDate,
  filename,
}: {
  startDate: Date
  endDate: Date
  filename: string
}): Promise<Blob> => {
  const { data } = await api.post<Blob>(
    `${PLATFORM_REPORTS_URL}/tasks-summary`,
    {
      startDate,
      endDate,
      filename,
    },
    {
      responseType: 'blob',
    }
  )

  return data
}
export const generateStockReport = async ({
  reportProducts,
  reportStakeHolderProducts,
}: StockReportInput) => {
  const { data } = await api.post<Blob>(
    `${PLATFORM_REPORTS_URL}/stock-report`,
    {
      reportProducts,
      reportStakeHolderProducts,
    },
    {
      responseType: 'blob',
    }
  )

  return data
}

export const generateIssuesSummaryReport = async ({
  startDate,
  endDate,
  filename,
}: {
  startDate: Date
  endDate: Date
  filename: string
}): Promise<Blob> => {
  const { data } = await api.post<Blob>(
    `${PLATFORM_REPORTS_URL}/issues-summary`,
    {
      startDate,
      endDate,
      filename,
    },
    {
      responseType: 'blob',
    }
  )

  return data
}

export const generateDeliveryNoteReport = async ({
  addRawMaterial,
  stakeHolderId,
  truIds,
}: {
  truIds: string[]
  addRawMaterial: boolean
  stakeHolderId: string
}): Promise<Blob> => {
  const { data } = await api.post<Blob>(
    `${PLATFORM_REPORTS_URL}/delivery-note`,
    {
      truIds,
      addRawMaterial,
      stakeHolderId,
    },
    {
      responseType: 'blob',
    }
  )

  return data
}

export const generateTraceabilityReport = async ({
  truId,
  addRawMaterial,
}: {
  truId: string
  addRawMaterial: boolean
}): Promise<Blob> => {
  const { data } = await api.post<Blob>(
    `${PLATFORM_REPORTS_URL}/traceability-report`,
    {
      truId,
      addRawMaterial,
    },
    {
      responseType: 'blob',
    }
  )

  return data
}
