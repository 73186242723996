import { Badge, COLOR, NEW_COLOR, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import { useMemo } from 'react'
import { COMPLIANCE_ALMOST_EXPIRED_DAYS } from '../constants'

export const ComplianceExpirationBadge = ({ dueDate, t }: { dueDate: Date; t: TFunction }) => {
  const { distanceInDays, distanceInHours } = useMemo(() => {
    const distanceInHours = dayjs(dueDate).diff(dayjs(), 'hour')
    const distanceInDays = dayjs
      .duration({
        hours: distanceInHours,
      })
      .asDays()
    return {
      distanceInDays,
      distanceInHours,
    }
  }, [dueDate])

  if (distanceInHours <= 0) {
    return (
      <Badge color={COLOR.RED} variant='round' size={SIZE.LARGE}>
        {t('table.expired')}
      </Badge>
    )
  }

  if (distanceInDays <= COMPLIANCE_ALMOST_EXPIRED_DAYS) {
    return (
      <Badge color={NEW_COLOR.ORANGE} variant='round'>
        {t('table.almostExpired')}
      </Badge>
    )
  }

  return null
}
