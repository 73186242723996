import { UseInfiniteQueryOptions, UseQueryOptions, useInfiniteQuery, useQuery } from 'react-query'
import { Origin } from './interfaces'
import { Paginated } from '@/hooks/use-pagination'
import { AxiosError } from 'axios'
import { mergeInfiniteQueryOptions, mergeQueryOptions } from '@/utils/merge-query-options'
import {
  getPaginatedAvailableOriginsByProduct,
  getPaginatedAvailableOriginsByTRU,
  getUsedOriginsByTRU,
} from './service'
import { TABLE_SIZE_PRESETS } from '@/app/constants'
import { useMemo } from 'react'

export const QUERY_AVAILABLE_ORIGINS_KEY = 'available-origins'
export const QUERY_USED_ORIGINS_KEY = 'used-origins'

export const usePaginatedAvailableOriginsByProduct = ({
  productId,
  filters,
  ...overrideOptions
}: Omit<UseInfiniteQueryOptions<Paginated<Origin>, AxiosError>, 'queryKey' | 'queryFn'> & {
  productId: string
  filters: { search?: string; productOriginIds?: string[] }
}) => {
  const options = mergeInfiniteQueryOptions(overrideOptions, {
    getNextPageParam: lastPage => lastPage.pageInfo.nextPage || undefined,
    getPreviousPageParam: lastPage => lastPage.pageInfo.previousPage || undefined,
  })

  const { data, ...rest } = useInfiniteQuery(
    [QUERY_AVAILABLE_ORIGINS_KEY, { filters, productId }, { isInfiniteQuery: true }],
    async ({ pageParam }) => {
      return getPaginatedAvailableOriginsByProduct(productId, filters, {
        offset: pageParam?.offset || 0,
        limit: pageParam?.limit || TABLE_SIZE_PRESETS[2],
      })
    },
    options
  )

  const availableOrigins: Origin[] = useMemo(
    () => data?.pages.flatMap(({ items }) => items) || [],
    [data?.pages]
  )

  const totalCount = useMemo(
    () => data?.pages[data?.pages.length - 1]?.totalCount || 0,
    [data?.pages]
  )

  return {
    ...rest,
    availableOrigins,
    totalCount,
  }
}

export const useAvailableOriginsByProduct = ({
  productId,
  filters,
  ...overrideOptions
}: Omit<UseQueryOptions<Origin[], AxiosError>, 'queryKey' | 'queryFn'> & {
  productId: string
  filters: { search?: string; productOriginIds?: string[] }
}) => {
  const options = mergeQueryOptions(overrideOptions)

  const { data, ...rest } = useQuery(
    [QUERY_AVAILABLE_ORIGINS_KEY, { filters, productId }],
    async () => {
      const result = await getPaginatedAvailableOriginsByProduct(productId, filters, {
        offset: 0,
        limit: 0,
      })

      return result.items
    },
    options
  )

  return {
    ...rest,
    availableOrigins: useMemo(() => data || [], [data]),
  }
}

export const usePaginatedAvailableOriginsByTru = ({
  truId,
  filters,
  ...overrideOptions
}: Omit<UseInfiniteQueryOptions<Paginated<Origin>, AxiosError>, 'queryKey' | 'queryFn'> & {
  truId: string
  filters: { search?: string; productOriginIds?: string[] }
}) => {
  const options = mergeInfiniteQueryOptions(overrideOptions, {
    getNextPageParam: lastPage => lastPage.pageInfo.nextPage || undefined,
    getPreviousPageParam: lastPage => lastPage.pageInfo.previousPage || undefined,
  })

  const { data, ...rest } = useInfiniteQuery(
    [QUERY_AVAILABLE_ORIGINS_KEY, { filters, truId }, { isInfiniteQuery: true }],
    async ({ pageParam }) => {
      return getPaginatedAvailableOriginsByTRU(truId, filters, {
        offset: pageParam?.offset || 0,
        limit: pageParam?.limit || TABLE_SIZE_PRESETS[2],
      })
    },
    options
  )

  const availableOrigins: Origin[] = useMemo(
    () => data?.pages.flatMap(({ items }) => items) || [],
    [data?.pages]
  )

  const totalCount = useMemo(
    () => data?.pages[data?.pages.length - 1]?.totalCount || 0,
    [data?.pages]
  )

  return {
    ...rest,
    availableOrigins,
    totalCount,
  }
}

export const useAvailableOriginsByTru = ({
  truId,
  filters,
  ...overrideOptions
}: Omit<UseQueryOptions<Origin[], AxiosError>, 'queryKey' | 'queryFn'> & {
  truId: string
  filters: { search?: string; productOriginIds?: string[] }
}) => {
  const options = mergeQueryOptions(overrideOptions)

  const { data, ...rest } = useQuery(
    [QUERY_AVAILABLE_ORIGINS_KEY, { filters, truId }],
    async () => {
      const result = await getPaginatedAvailableOriginsByTRU(truId, filters, {
        offset: 0,
        limit: 0,
      })

      return result.items
    },
    options
  )

  return {
    ...rest,
    availableOrigins: useMemo(() => data || [], [data]),
  }
}

export const useUsedOriginsByTru = (
  truId: string,
  options?: Omit<UseQueryOptions<Origin[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_USED_ORIGINS_KEY, truId],
    () => getUsedOriginsByTRU(truId),
    options
  )

  const usedOrigins: Origin[] = useMemo(() => data || [], [data])

  return {
    ...rest,
    usedOrigins,
  }
}
