import { AxiosError } from 'axios'
import { useMemo } from 'react'
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query'
import { Metadata, MetadataType, MetadataUpdate } from './interfaces'
import { create, getByBelongsTos, remove, update } from './service'
import { customErrorToTrack, useAnalytics } from '@/features/analytics'
import { Product, useFetchProductById } from '@/features/products'
import { TRU, useFetchTRUById } from '@/features/trus'
import { useFetchReceptionById } from '@/features/receptions'
import { StakeHolderProduct, useFetchStakeHolderProductById } from '@/features/stakeHolderProducts'
import { useFetchShipmentById } from '@/features/shipments'

const QUERY_METADATAS_KEY = 'metadatas'

export const useMetadataByBelongsTos = (
  belongsTos: string[],
  options?: Omit<UseQueryOptions<Metadata[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_METADATAS_KEY, { belongsTos }],
    () => getByBelongsTos(belongsTos),
    options
  )

  return { ...rest, metadatas: useMemo(() => data || [], [data]) }
}

export const useCreateMetadata = () => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()

  const { mutate, ...rest } = useMutation(create, {
    onSuccess() {
      queryClient.invalidateQueries(QUERY_METADATAS_KEY)
    },
    onError(error: AxiosError) {
      analytics.track(
        'CUSTOMER_CREATE_METADATA_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
    },
  })

  return { ...rest, createMetadata: mutate }
}

export const useUpdateMetadata = () => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()

  const { fetchTRU } = useFetchTRUById()
  const { fetchProduct } = useFetchProductById()
  const { fetchShipment } = useFetchShipmentById()
  const { fetchReception } = useFetchReceptionById()
  const { fetchStakeHolderProduct } = useFetchStakeHolderProductById()

  const { mutate, ...rest } = useMutation(
    ({
      metadataId,
      metadata,
    }: {
      metadataId: string
      metadata: MetadataUpdate
      metadataType: MetadataType
      belongsTo: string
    }) => update(metadataId, metadata),
    {
      async onSuccess(data, variables) {
        queryClient.invalidateQueries(QUERY_METADATAS_KEY)

        switch (variables?.metadataType) {
          case MetadataType.TRU:
            {
              const tru = await fetchTRU(variables.belongsTo)
              let product: Product | undefined
              if (tru?.productId) {
                product = await fetchProduct(tru?.productId)
              }

              analytics.track('CUSTOMER_EDIT_TRU', {
                TRU: tru?.reference,
                Product: product?.name,
                Type: 'Creation',
                Source: 'Metadatas',
              })
            }
            break
          case MetadataType.SHIPMENT:
            {
              const shipment = await fetchShipment(variables.belongsTo)
              let tru: TRU | undefined
              if (shipment?.truId) {
                tru = await fetchTRU(shipment?.truId)
              }
              let product: Product | undefined
              if (tru?.productId) {
                product = await fetchProduct(tru?.productId)
              }

              analytics.track('CUSTOMER_EDIT_TRU', {
                TRU: tru?.reference,
                Product: product?.name,
                Type: 'Shipment',
                Source: 'Metadatas',
              })
            }
            break
          case MetadataType.RECEPTION:
            {
              const reception = await fetchReception(variables.belongsTo)
              let stakeHolderProduct: StakeHolderProduct | void
              if (reception?.stakeHolderProductId) {
                stakeHolderProduct = await fetchStakeHolderProduct(reception?.stakeHolderProductId)
              }

              analytics.track('CUSTOMER_EDIT_TRU_RECEPTION', {
                TRU: reception?.reference,
                Product: stakeHolderProduct?.stakeHolder.product.name,
                Source: 'Metadatas',
              })
            }
            break
        }
      },
      onError(error: AxiosError) {
        analytics.track(
          'CUSTOMER_CREATE_METADATA_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
      },
    }
  )

  return { ...rest, updateMetadata: mutate }
}

export const useDeleteMetadata = () => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()

  const { fetchTRU } = useFetchTRUById()
  const { fetchProduct } = useFetchProductById()
  const { fetchShipment } = useFetchShipmentById()
  const { fetchReception } = useFetchReceptionById()
  const { fetchStakeHolderProduct } = useFetchStakeHolderProductById()

  const { mutate, ...rest } = useMutation(
    ({ metadataId }: { metadataId: string; metadataType: MetadataType; belongsTo: string }) =>
      remove(metadataId),
    {
      async onSuccess(data, variables) {
        queryClient.invalidateQueries(QUERY_METADATAS_KEY)

        switch (variables?.metadataType) {
          case MetadataType.TRU:
            {
              const tru = await fetchTRU(variables.belongsTo)
              let product: Product | undefined
              if (tru?.productId) {
                product = await fetchProduct(tru?.productId)
              }

              analytics.track('CUSTOMER_EDIT_TRU', {
                TRU: tru?.reference,
                Product: product?.name,
                Type: 'Creation',
                Source: 'Metadatas',
              })
            }
            break
          case MetadataType.SHIPMENT:
            {
              const shipment = await fetchShipment(variables.belongsTo)
              let tru: TRU | undefined
              if (shipment?.truId) {
                tru = await fetchTRU(shipment?.truId)
              }
              let product: Product | undefined
              if (tru?.productId) {
                product = await fetchProduct(tru?.productId)
              }

              analytics.track('CUSTOMER_EDIT_TRU', {
                TRU: tru?.reference,
                Product: product?.name,
                Type: 'Shipment',
                Source: 'Metadatas',
              })
            }
            break
          case MetadataType.RECEPTION:
            {
              const reception = await fetchReception(variables.belongsTo)
              let stakeHolderProduct: StakeHolderProduct | void
              if (reception?.stakeHolderProductId) {
                stakeHolderProduct = await fetchStakeHolderProduct(reception?.stakeHolderProductId)
              }

              analytics.track('CUSTOMER_EDIT_TRU_RECEPTION', {
                TRU: reception?.reference,
                Product: stakeHolderProduct?.stakeHolder.product.name,
                Source: 'Metadatas',
              })
            }
            break
        }
      },
      onError(error: AxiosError) {
        analytics.track(
          'CUSTOMER_DELETE_METADATA_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
      },
    }
  )

  return { ...rest, deleteMetadata: mutate }
}
