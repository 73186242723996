import { api } from '@/app/api'
import { AcknowlegeNotificationParams, Notification, NotificationFilters } from './interfaces'

const NOTIFICATIONS_URL = '/auth/notifications'

/**
 * Get the Company logged Notifications
 *
 * @returns {Notification[]} All the Company logged Notifications
 */
export const getAll = async (): Promise<Notification[]> => {
  const { data: notifications } = await api.get<Notification[] | void>(NOTIFICATIONS_URL)
  return notifications || []
}

export const getByFilters = async (filters: NotificationFilters): Promise<Notification[]> => {
  const params = new URLSearchParams()

  if (filters.allUsers) {
    params.append('allUsers', '')
  }

  if (filters.unacknowledged) {
    params.append('unacknowledged', '')
  }

  if (filters.categoryTypes) {
    filters.categoryTypes.forEach(categoryType => {
      params.append('categoryTypes[]', categoryType)
    })
  }

  const { data: notifications } = await api.get<Notification[] | void>(
    `${NOTIFICATIONS_URL}?${params.toString().replace(/=(?=&|$)/gm, '')}`
  )
  return notifications || []
}

export const acknowNotification = async ({
  notificationId,
}: AcknowlegeNotificationParams): Promise<void> => {
  await api.patch<Notification[] | void>(`${NOTIFICATIONS_URL}/${notificationId}/acknowledge`)
}
