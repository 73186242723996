import mime from 'mime'

/**
 * In devices where the pixel ratio is more than 1
 * The signature_pad library is using this ratio to generate the SVG, but this is not working for us.
 * So, we need to fit the svg size with the canvas
 * https://github.com/szimek/signature_pad/blob/master/src/signature_pad.ts#L605
 */
export const fitSVGWithCanvas = (canvas: HTMLCanvasElement, svgValue: string) => {
  const parser = new DOMParser()
  const { documentElement } = parser.parseFromString(svgValue, 'image/svg+xml')
  documentElement.setAttribute('width', `${canvas.offsetWidth}`)
  documentElement.setAttribute('height', `${canvas.offsetHeight}`)
  documentElement.setAttribute('viewBox', `0 0 ${canvas.offsetWidth} ${canvas.offsetHeight}`)
  return documentElement.outerHTML
}

export const toSVGFile = (svgValue: string) =>
  new File([svgValue], 'signature.svg', {
    type: mime.getType('svg') || undefined,
  })
