import punycode from 'punycode/'

/**
 * Convert punycode to unicode
 * @param text
 * @returns {string}
 * @description if a text includes 'xn--', it means that the text contains punycode. So we convert the text to unicode.
 */
export const convertPunycodeToUnicode = (text: string): string => {
  return text.includes('xn--') ? punycode.toUnicode(text) : text
}
