import { ReactNode } from 'react'
import { useElementSize } from '@/hooks/use-element-size'

export interface AutoSizerProps {
  children: (size: { height: number; width: number }) => ReactNode
}

/**
 * Helper component to know the current element size
 */
export const AutoSizer = ({ children }: AutoSizerProps) => {
  const [ref, size] = useElementSize()

  return (
    <div ref={ref} className='h-full'>
      {children(size)}
    </div>
  )
}
