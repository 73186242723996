import classNames from 'classnames'
import dayjs from 'dayjs'

export const HeaderDay = ({ date }: { date: Date }) => {
  const isToday: boolean = dayjs(date).isSame(new Date(), 'day')

  return (
    <div className='w-full min-w-5 h-10 flex items-center justify-center'>
      <div
        className={classNames(
          'w-5 h-5 text-center rounded-full text-xs font-semibold flex items-center justify-center',
          {
            'text-white bg-blue-500 ': isToday,
            'text-gray-500': !isToday,
          }
        )}
      >
        <span>{date.getDate()}</span>
      </div>
    </div>
  )
}
