import {
  Document,
  DocumentFiltersExpirationStatus,
  isDocumentAlmostExpired,
  isDocumentExpired,
  useDocumentsBySearch,
} from '@/features/documents'
import { StakeHolder, useStakeHolderByIds } from '@/features/stakeHolders'
import {
  UploadRequestCreate,
  UploadRequestUploadStatus,
  useCreateUploadRequest,
  useCreateUploadRequests,
  useUploadRequests,
} from '@/features/upload-requests'
import { Badge, Button, COLOR, Card } from '@blockchain-traceability-sl/tailwind-components'
import { DocumentIcon, FolderOpenIcon, PaperAirplaneIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

export const DocumentsExpiredRequester = () => {
  const { t } = useTranslation('nsHomePage', {
    keyPrefix: 'quickActions.section.documentRequest',
  })

  const { documents, isFetched: documentsIsFetched } = useDocumentsBySearch({
    expirationStatus: [
      DocumentFiltersExpirationStatus.ALMOST_EXPIRED,
      DocumentFiltersExpirationStatus.EXPIRED,
    ],
  })

  const { stakeHolders, isFetched: stakeHoldersIsFetched } = useStakeHolderByIds(
    documents
      .flatMap(document => document.stakeHolderIds)
      // remove repeated stakeholder ids
      .filter((stakeHolderId, index, self) => self.indexOf(stakeHolderId) === index)
  )

  const { createUploadRequest } = useCreateUploadRequest({ From: 'Home' })
  const { createUploadRequests } = useCreateUploadRequests()

  const { uploadRequests, isFetched: uploadRequestsIsFetched } = useUploadRequests(
    {
      status: UploadRequestUploadStatus.PENDING,
      startCreatedAt: dayjs().subtract(1, 'week').startOf('day').toISOString(),
    },
    { select: data => data.reverse() }
  )

  /**
   * Filter documents
   *
   * - Remove documents with stakeholders without email
   * - Remove documents without stakeholder in stakeHolders
   * - Remove documents expired with upload request created before expiration
   * - Remove documents with upload request created this week
   *
   */

  const documentsFiltered = useMemo(
    () =>
      documents
        // Remove documents without stakeholder in stakeHolders
        .filter(document =>
          document.stakeHolderIds.some(id =>
            stakeHolders.some(stakeHolder => stakeHolder._id === id)
          )
        )
        // Remove documents with uploadRequest expired with upload request created before expiration
        .filter(document => {
          const documentUploadRequests = uploadRequests.filter(
            ur =>
              ur.replaceDocumentId === document._id &&
              dayjs(ur.createdAt).isBefore(dayjs(document.expireAt))
          )

          return documentUploadRequests.length === 0
        })
        // Remove documents with upload request created this week
        .filter(document => {
          const documentUploadRequests = uploadRequests.filter(
            ur =>
              ur.replaceDocumentId === document._id &&
              dayjs(ur.createdAt).isAfter(dayjs().subtract(1, 'week').startOf('day'))
          )

          return documentUploadRequests.length === 0
        }),
    [documents, stakeHolders, uploadRequests]
  )

  const countExpired = documentsFiltered.filter(document =>
    isDocumentExpired(document.expireAt)
  ).length
  const countAlmostExpired = documentsFiltered.filter(document =>
    isDocumentAlmostExpired(document.expireAt)
  ).length

  const isEmpty =
    documentsIsFetched &&
    stakeHoldersIsFetched &&
    uploadRequestsIsFetched &&
    documentsFiltered.length === 0

  /**
   * Get stakeholder of a document
   * @param document
   * @returns StakeHolder | null
   */
  const getDocumentStakeHolder = (document: Document): StakeHolder | null => {
    const stakeHolderId = document.stakeHolderIds.find(stakeHolderId =>
      stakeHolders.some(stakeHolder => stakeHolder._id === stakeHolderId)
    )

    if (!stakeHolderId) {
      return null
    }

    return stakeHolders.find(stakeHolder => stakeHolder._id === stakeHolderId) ?? null
  }

  const generateUploadRequestToCreate = (document: Document): UploadRequestCreate | undefined => {
    const stakeHolder = getDocumentStakeHolder(document)
    if (stakeHolder && stakeHolder.company.email) {
      return {
        requestee: { id: stakeHolder._id, email: stakeHolder.company.email },
        subject: t('subject').toString(),
        content: t('contentEmail', { documentName: document.title }).toString(),
        targetFolderId: document.parentId,
        replaceDocumentId: document._id,
        timezone: dayjs.tz.guess(),
        documentsLimit: 1,
      }
    }
  }

  const handleRequestDocumentUpdate = (document: Document) => {
    const documentRequestToCreate = generateUploadRequestToCreate(document)
    if (documentRequestToCreate) {
      createUploadRequest(documentRequestToCreate)
    }
  }

  const handleRequestAllDocumentsUpdate = async () => {
    const uploadRequestsToCreate: UploadRequestCreate[] = []
    documentsFiltered.forEach(document => {
      const documentRequestToCreate = generateUploadRequestToCreate(document)
      if (documentRequestToCreate) {
        uploadRequestsToCreate.push(documentRequestToCreate)
      }
    })

    createUploadRequests(uploadRequestsToCreate)
  }

  return (
    <Card className='w-full h-80 overflow-y-auto'>
      <Card.Body>
        {/* Header */}
        <div className='flex justify-between content-center'>
          <div className='text-sm leading-5 font-semibold text-blue-600 flex gap-2'>
            <span className='m-auto'>
              <FolderOpenIcon className='w-5 h-5' />
            </span>
            <span className='flex-shrink shrink m-auto'>{t('title')}</span>
          </div>
          <div className='hidden md:block' onClick={handleRequestAllDocumentsUpdate}>
            <Button color='secondary' disabled={isEmpty}>
              {t('callToAction')}
            </Button>
          </div>
        </div>

        <div className='flex gap-4 mt-4 md:mt-1'>
          <Badge color={COLOR.YELLOW}>
            {t('tagAlmostExpired', { almostExpiredDocuments: countAlmostExpired })}
          </Badge>
          <Badge color={COLOR.RED}>{t('tagExpired', { expiredDocuments: countExpired })}</Badge>
        </div>

        <div className='md:hidden mt-4'>
          <Button
            color='secondary'
            className='w-full'
            onClick={handleRequestAllDocumentsUpdate}
            disabled={isEmpty}
          >
            {t('callToAction')}
          </Button>
        </div>

        {/* Documents */}
        <div className='flex flex-col mt-8 gap-5 overflow-y-auto overflow-x-hidden'>
          {isEmpty ? (
            <div className='text-2xl leading-8 font-extrabold text-gray-600 text-center pt-16 px-8'>
              {t('empty')}
            </div>
          ) : (
            documentsFiltered.map(document => (
              <div key={document._id} className='flex gap-2 justify-between'>
                <div className='flex shrink gap-2'>
                  {/* Icon */}
                  <span
                    className={classNames('flex items-center justify-center h-8 w-8 rounded-full', {
                      // Colors
                      'bg-red-100': isDocumentExpired(document.expireAt),
                      'bg-yellow-100': isDocumentAlmostExpired(document.expireAt),
                    })}
                  >
                    <DocumentIcon
                      className={classNames('h-5 w-5', {
                        // Colors
                        'text-red-700': isDocumentExpired(document.expireAt),
                        'text-yellow-700': isDocumentAlmostExpired(document.expireAt),
                      })}
                      aria-hidden='true'
                    />
                  </span>
                  {/* Content */}
                  <div className='flex flex-col'>
                    <span className='text-sm leading-5 font-normal text-gray-900'>
                      <Trans
                        t={t}
                        i18nKey='content.title'
                        components={{ b: <span className='font-semibold' /> }}
                        values={{
                          documentName: document.title,
                          user: getDocumentStakeHolder(document)?.company.name ?? 'N/A',
                        }}
                      />
                    </span>

                    <span className='text-xs leading-5 font-normal text-gray-400'>
                      {isDocumentExpired(document.expireAt) && t('content.expired')}
                      {isDocumentAlmostExpired(document.expireAt) &&
                        document.expireAt &&
                        t('content.timeToExpire', { date: new Date(document.expireAt) })}
                    </span>
                  </div>
                </div>
                {/* Action */}
                <div>
                  <button onClick={() => handleRequestDocumentUpdate(document)}>
                    <span>
                      <PaperAirplaneIcon className='w-6 h-6 transform rotate-45 text-blue-600 hover:text-blue-700' />
                    </span>
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </Card.Body>
    </Card>
  )
}
