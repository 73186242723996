import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { create, remove, sendReportEmail, update } from '../service'
import * as NotifyService from '@/services/notify.service'
import { customErrorToTrack, useAnalytics } from '@/features/analytics'
import { AxiosError } from 'axios'
import { IssueChanges, IssueUpdateOrCreateWithTaskEntryId } from '@/features/issues'
import { Task, TaskCreation, TaskStatus, TaskUpdate } from '../interfaces'
import { Entry } from '@/features/entries'
import {
  DocumentCreationTriggerType,
  DocumentFolder,
  DocumentType,
  ROOT_FOLDER_ID,
  createFile,
  createFolder,
  getAll,
} from '@/features/documents'
import dayjs from 'dayjs'
import { useFetchTemplateByTypeAndId, useTemplatesForTasks } from '@/features/templates'
import {
  QUERY_TASKS_KEY,
  useFetchTaskById,
  useGenerateTaskReport,
  useInvalidateTaskQueries,
} from './queries'
import { Paginated } from '@/hooks/use-pagination'
import { useUploadFile } from '@/features/files'
import { useGeneratePdfFromDefinition } from '@/features/pdf'

export const useTaskReportEmail = () => {
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()

  const { isLoading: loading, mutate } = useMutation(sendReportEmail, {
    onSuccess: () => {
      NotifyService.success({
        title: t('task.sendEmail.success.title'),
        description: t('task.sendEmail.success.description'),
      })

      analytics.track('CUSTOMER_SEND_DOCUMENT', {
        Type: 'Task report',
        Via: 'Email',
      })
    },
    onError: (error: AxiosError) => {
      analytics.track(
        'CUSTOMER_SEND_DOCUMENT_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error({
        title: t('task.sendEmail.error.title'),
        description: t('task.sendEmail.error.description'),
        actionText: t('task.sendEmail.error.action'),
      })
    },
  })

  return { loading, sendTaskReportEmail: mutate }
}

export const useTaskReportUploadToDocumentManager = () => {
  const { t } = useTranslation()
  const { uploadFileAsync: uploadDocumentFile } = useUploadFile()
  const { fetchTaskById } = useFetchTaskById()
  const { fetchTemplateByTypeAndId } = useFetchTemplateByTypeAndId()
  const { generateTaskReport } = useGenerateTaskReport()
  const { generatePdfFromDefinitionAsync } = useGeneratePdfFromDefinition()

  // If has a file with the same name add +1 and repeat, once name is unique upload it

  return {
    uploadTaskReportToDocumentManager: async (taskId: string): Promise<void> => {
      const task = await fetchTaskById(taskId)
      if (!task) {
        throw new Error('No task found')
      }
      const taskTemplate = await fetchTemplateByTypeAndId('form', task.formId)
      if (!taskTemplate) {
        throw new Error('No template found')
      }
      const TASK_REPORTS_NAME = t('nsDocumentsViewPage:roots.taskReports').toString()
      let rootDocuments = await getAll({ parentId: ROOT_FOLDER_ID })

      // Check root > taskReports folder exist or create it
      if (!rootDocuments.some(document => document.title === TASK_REPORTS_NAME)) {
        await createFolder({
          title: TASK_REPORTS_NAME,
          parentId: ROOT_FOLDER_ID,
          type: DocumentType.FOLDER,
        })
        rootDocuments = await getAll({ parentId: ROOT_FOLDER_ID })
      }

      const taskReportsFolder = rootDocuments.find(
        document => document.title === TASK_REPORTS_NAME
      ) as DocumentFolder

      // Check root > taskReports > templateName exist
      let taskReportsDocuments = await getAll({ parentId: taskReportsFolder._id })
      // Check root > taskReports folder exist or create it
      if (!taskReportsDocuments.some(document => document.title === taskTemplate.name)) {
        await createFolder({
          title: taskTemplate.name,
          parentId: taskReportsFolder._id,
          type: DocumentType.FOLDER,
        })
        taskReportsDocuments = await getAll({ parentId: taskReportsFolder._id })
      }

      const taskReportsFormFolder = taskReportsDocuments.find(
        document => document.title === taskTemplate.name
      ) as DocumentFolder

      // Get root > taskReports > templateName files to know if is empty or has reports
      const taskReportsFormDocuments = await getAll({
        parentId: taskReportsFormFolder._id,
      })
      let taskReportName = `${taskTemplate.name} ${dayjs(task.dueDate).format('L')}`

      if (taskReportsFormDocuments.some(({ title }) => title === taskReportName)) {
        let index = 0
        do {
          index++
          taskReportName = `${taskTemplate.name} ${dayjs(task.dueDate).format('L')} (${index})`
          // eslint-disable-next-line no-loop-func
        } while (taskReportsFormDocuments.some(({ title }) => title === taskReportName))
      }

      const taskReport = await generateTaskReport(taskId)

      const pdfReport = await generatePdfFromDefinitionAsync({
        pdfDefinition: JSON.stringify(taskReport),
        filename: taskReportName,
      })

      if (!pdfReport) throw new Error('No pdf report generated')

      const taskReportUploadResponse = await uploadDocumentFile(
        new File([pdfReport?.blob], taskReportName)
      )

      await createFile({
        title: taskReportName,
        parentId: taskReportsFormFolder._id,
        type: DocumentType.FILE,
        tags: [],
        companyIds: [],
        productIds: [],
        truIds: [],
        stakeHolderIds: [],
        stakeHolderProductIds: [],
        receptionIds: [],
        file: {
          mimetype: 'application/pdf',
          path: taskReportUploadResponse.path,
        },
        sharedWith: [],
        creationTrigger: {
          entityType: DocumentCreationTriggerType.TASK,
          entityId: taskId,
        },
      })
    },
  }
}

export const useCreateTask = (options?: {
  silently?: boolean
  ignoreMandatoryEntries?: boolean
  trackPayload?: Record<string, string>
}) => {
  const { invalidateTaskQueries } = useInvalidateTaskQueries()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()

  const { mutate, mutateAsync, ...rest } = useMutation(
    (inputData: { task: TaskCreation; issues?: IssueUpdateOrCreateWithTaskEntryId[] }) =>
      create(inputData.task, inputData.issues, {
        ignoreMandatoryEntries: options?.ignoreMandatoryEntries,
      }),
    {
      onSuccess: async (data, variables) => {
        invalidateTaskQueries()
        analytics.track('CUSTOMER_CREATE_TASK', {
          ...options?.trackPayload,
          Form: variables.task.formName,
        })
        if (!options?.silently) {
          NotifyService.success({
            title: t('task.create.success.title'),
            description: t('task.create.success.description'),
          })
        }
      },
      onError: (error: AxiosError) => {
        analytics.track('CUSTOMER_CREATE_TASK_ERROR', {
          ...options?.trackPayload,
          ...customErrorToTrack(error.response?.data, error.response?.status),
        })
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    createTask: mutate,
    createTaskAsync: mutateAsync,
  }
}

export const useUpdateTask = ({ silently }: { silently?: boolean } = {}) => {
  const { invalidateTaskQueries } = useInvalidateTaskQueries()
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()

  const { mutate, mutateAsync, ...rest } = useMutation(
    ({
      taskId,
      task,
      issueChanges,
    }: {
      taskId: string
      task: TaskUpdate
      issueChanges?: IssueChanges
    }) => update(taskId, { task, issueChanges }),
    {
      onMutate: ({ taskId, task }) => {
        queryClient
          .getQueryCache()
          .findAll(QUERY_TASKS_KEY)
          .filter(
            query =>
              query.isActive() &&
              (query.queryKey[1] === undefined || typeof query.queryKey[1] === 'object')
          )
          .forEach(query => {
            queryClient.setQueryData<Task[] | Paginated<Task> | undefined>(
              query.queryKey,
              oldData => {
                if (!oldData) return []
                if (Array.isArray(oldData)) {
                  return oldData.map(dataItem =>
                    dataItem._id === taskId ? { ...dataItem, ...task } : dataItem
                  )
                } else {
                  return {
                    ...oldData,
                    items: oldData?.items.map(dataItem =>
                      dataItem._id === taskId ? { ...dataItem, ...task } : dataItem
                    ),
                  }
                }
              }
            )
          })
      },
      onSuccess: async (data, variables) => {
        invalidateTaskQueries()
        analytics.track('CUSTOMER_EDIT_TASK', {
          Form: variables.task.formName,
        })
        if (!silently) {
          NotifyService.success({
            title: t('task.update.success.title'),
            description: t('task.update.success.description'),
          })
        }
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_EDIT_TASK_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
        queryClient.invalidateQueries(QUERY_TASKS_KEY)
      },
    }
  )

  return {
    ...rest,
    updateTask: mutate,
    updateTaskAsync: mutateAsync,
  }
}

export const useRescheduleTask = () => {
  const { invalidateTaskQueries } = useInvalidateTaskQueries()
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()

  const { mutate, mutateAsync, ...rest } = useMutation(
    ({
      taskId,
      task,
    }: {
      taskId: string
      task: Required<Pick<TaskUpdate, 'formName' | 'dueDate'>>
    }) => update(taskId, { task }),
    {
      onMutate: ({ taskId, task }) => {
        queryClient
          .getQueryCache()
          .findAll(QUERY_TASKS_KEY)
          .filter(
            query =>
              query.isActive() &&
              (query.queryKey[1] === undefined || typeof query.queryKey[1] === 'object')
          )
          .forEach(query => {
            queryClient.setQueryData<Task[] | Paginated<Task> | undefined>(
              query.queryKey,
              oldData => {
                if (!oldData) return []
                if (Array.isArray(oldData)) {
                  return oldData.map(dataItem =>
                    dataItem._id === taskId ? { ...dataItem, ...task } : dataItem
                  )
                } else {
                  return {
                    ...oldData,
                    items: oldData?.items.map(dataItem =>
                      dataItem._id === taskId ? { ...dataItem, ...task } : dataItem
                    ),
                  }
                }
              }
            )
          })
      },
      onSuccess: async (data, variables) => {
        invalidateTaskQueries()
        analytics.track('CUSTOMER_EDIT_TASK', {
          Form: variables.task.formName,
        })
        NotifyService.success({
          title: t('task.dueDate.success.title'),
          description: t('task.dueDate.success.description', {
            nameTask: variables.task.formName,
            date: new Date(variables.task.dueDate),
          }),
        })
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_EDIT_TASK_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
        queryClient.invalidateQueries(QUERY_TASKS_KEY)
      },
    }
  )

  return {
    ...rest,
    rescheduleTask: mutate,
    rescheduleTaskAsync: mutateAsync,
  }
}

export const useDeleteTask = () => {
  const { invalidateTaskQueries } = useInvalidateTaskQueries()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const { templates } = useTemplatesForTasks()

  const { mutate, mutateAsync, ...rest } = useMutation(
    (task: Task) => {
      const template = templates.find(item => item._id === task.formId)
      return remove(task, template?.name)
    },
    {
      onSuccess: async () => {
        invalidateTaskQueries()
        NotifyService.success({
          title: t('task.delete.success.title'),
          description: t('task.delete.success.description'),
        })
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_DELETE_TASK_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    deleteTask: mutate,
    deleteTaskAsync: mutateAsync,
  }
}

export const useUpdateAllTasks = ({ silently }: { silently?: boolean } = {}) => {
  const { invalidateTaskQueries } = useInvalidateTaskQueries()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()

  const { mutate, mutateAsync, ...rest } = useMutation(
    ({
      tasks,
      assignedTo,
      status,
    }: {
      tasks: { id: string; entries?: Entry[]; issueChanges?: IssueChanges }[]
      assignedTo?: string
      status?: TaskStatus
    }) =>
      Promise.all(
        tasks.map(({ id, issueChanges, ...rest }) =>
          update(id, {
            task: { ...rest, assignedTo, status },
            issueChanges,
          })
        )
      ),
    {
      onSuccess: () => {
        invalidateTaskQueries()
        if (!silently) {
          NotifyService.success({
            title: t('task.update.success.title'),
            description: t('task.update.success.description'),
          })
        }
        analytics.track('CUSTOMER_EDIT_TASK')
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_EDIT_TASK_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    updateAllTasks: mutate,
    updateAllTasksAsync: mutateAsync,
  }
}
