import { generateEntriesReportRows, prepareEntriesReportingData } from '@/features/entries'
import { PDF_COMPONENTS, createPdfDefinition } from '@/features/pdf'
import { TFunction } from 'i18next'
import {
  Compliance,
  ComplianceEntityType,
  ComplianceStatus,
  ComplianceValidationStatus,
} from '../interfaces'
import { Content } from 'pdfmake/interfaces'
import { getLastComplianceValidation } from './utils'

const generateComplianceReportContent = async (
  compliance: Compliance,
  {
    t,
    withTitle,
    direction,
  }: {
    t: TFunction
    withTitle?: boolean
    direction: 'rtl' | 'ltr'
  }
): Promise<Content> => {
  const { entries, tables } = await prepareEntriesReportingData({
    entries: compliance.entries,
  })

  const content = [
    {
      style: 'table',
      color: '#444',
      table: {
        widths: [150, '*'],
        body: [
          [
            { text: t('nsComplianceReport:basicInfo.header.key'), style: 'tableHeader' },
            { text: t('nsComplianceReport:basicInfo.header.value'), style: 'tableHeader' },
          ],
          [{ text: t('nsComplianceReport:basicInfo.name'), bold: true }, compliance.templateName],
          [
            { text: t('nsComplianceReport:basicInfo.deadlineAt'), bold: true },
            t('nsComplianceReport:basicInfo.deadlineAtValue', {
              date: new Date(compliance.deadlineAt),
            }),
          ],
          [
            { text: t('nsComplianceReport:basicInfo.stakeHolderName'), bold: true },
            compliance.compliantStakeHolder.name ||
              compliance.compliantStakeHolder.contactDetails?.name ||
              compliance.compliantStakeHolder.emails[0],
          ],
          ...(compliance.compliantEntityType === ComplianceEntityType.STAKEHOLDER_PRODUCT
            ? [
                [
                  { text: t('nsComplianceReport:basicInfo.rawMaterialReference'), bold: true },
                  compliance.compliantStakeHolderProduct?.reference || '-',
                ],
              ]
            : []),
          ...(compliance.status === ComplianceStatus.ACCEPTED
            ? [
                [
                  { text: t('nsComplianceReport:basicInfo.complianceStatus'), bold: true },
                  getLastComplianceValidation(compliance.validationState)?.status ===
                  ComplianceValidationStatus.APPROVED
                    ? t('nsComplianceReport:basicInfo.complianceStatusApprovedValue')
                    : t('nsComplianceReport:basicInfo.complianceStatusPartiallyApprovedValue'),
                ],
              ]
            : []),
        ],
      },
      layout: 'tableVerticalLayout',
    },

    PDF_COMPONENTS.spacer(),
    ...generateEntriesReportRows({ entries, tables, direction }),
  ]

  if (withTitle) {
    content.unshift(
      {
        text: t('nsComplianceReport:title'),
        bold: true,
        alignment: 'center',
        style: {
          fontSize: 24,
        },
      },
      PDF_COMPONENTS.spacer(),
      PDF_COMPONENTS.spacer()
    )
  }

  return content
}

export const generateComplianceReport = async (
  compliance: Compliance,
  options: {
    t: TFunction
    withTitle?: boolean
    direction: 'rtl' | 'ltr'
  }
) => {
  const content = await generateComplianceReportContent(compliance, options)

  return createPdfDefinition(content)
}

export const generateCompliancesReport = async (
  compliances: Compliance[],
  options: {
    t: TFunction
    withTitle?: boolean
    direction: 'rtl' | 'ltr'
  }
) => {
  const contents = await Promise.all(
    compliances.map(compliance => generateComplianceReportContent(compliance, options))
  )

  return createPdfDefinition(
    contents.flatMap((content, index) => [
      ...(index > 0 ? [PDF_COMPONENTS.pageBreak()] : []),
      content,
    ])
  )
}
