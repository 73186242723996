import { TRUCreationOrUpdateOriginInput } from '../../interfaces'
import { useTranslation } from 'react-i18next'
import { useIsUserStockEnabled } from '@/features/auth'
import {
  InlineSelectField,
  InputField,
  SelectorOption,
  createSelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import classNames from 'classnames'
import { useTruOriginRowData } from './hooks'
import { Link } from 'react-router-dom'
import {
  usePaginatedAvailableOriginsByProduct,
  usePaginatedAvailableOriginsByTru,
} from '@/features/origins'
import { useState } from 'react'
import { PlusCircleIcon } from '@heroicons/react/solid'

interface AvailableTruOriginsTableRowProps {
  // To know available origins
  productId: string
  truId?: string
  // To filter available origins
  productOriginId: string
  // Current origins selected from form context
  origins: TRUCreationOrUpdateOriginInput[]
  // Origin manage handlers
  onAddOrigin: (origin: TRUCreationOrUpdateOriginInput) => void
}

export const AvailableTruOriginsTableRow = ({
  productId,
  truId,
  productOriginId,
  origins,
  onAddOrigin,
}: AvailableTruOriginsTableRowProps) => {
  const { t } = useTranslation('nsTRUCreationManualPage', { keyPrefix: 'form.origins' })
  const isUserStockEnabled = useIsUserStockEnabled()

  const { product, stakeHolder, type } = useTruOriginRowData({
    entityId: productOriginId,
  })

  const { availableOrigins: availableProductOrigins } = usePaginatedAvailableOriginsByProduct({
    productId,
    filters: { productOriginIds: [productOriginId] },
    enabled: !truId,
  })

  const { availableOrigins: availableTruOrigins } = usePaginatedAvailableOriginsByTru({
    truId: truId || '',
    filters: { productOriginIds: [productOriginId] },
    enabled: !!truId,
  })

  const availableOrigins = truId ? availableTruOrigins : availableProductOrigins

  const availableOriginOptions = availableOrigins
    // Format to SelectorOption
    .map(origin => createSelectorOption(origin.reference, origin._id))
    // Remove origins already selected
    .filter(origin => !origins.some(selectedOrigin => selectedOrigin.entityId === origin.value))

  const nullSelectorOption: SelectorOption = {
    label: '',
    value: '',
  }

  const [originSelectedOption, setOriginSelectedOption] = useState<SelectorOption | null>(
    nullSelectorOption
  )
  const [originQuantity, setOriginQuantity] = useState<number | null>(null)

  // This is the raw origin selected
  const originSelected = availableOrigins.find(origin => origin._id === originSelectedOption?.value)

  // This is the condition to show the quantity input error
  const quantityHasError: boolean =
    originQuantity !== null &&
    (originQuantity <= 0 || originQuantity > (originSelected?.stock ?? 0))

  // This is the condition to enable the submit button
  const isAllowedToSubmit = !isUserStockEnabled || (!quantityHasError && originQuantity)

  const handleAddOrigin = () => {
    if (originSelected && isAllowedToSubmit) {
      onAddOrigin({
        entityId: originSelected._id,
        reference: originSelected.reference,
        type: originSelected.originType,
        stock: originSelected.stock,
        quantity: isUserStockEnabled ? String(originQuantity) : undefined,

        stakeHolder,
        product,
      })

      setOriginSelectedOption(nullSelectorOption)
      setOriginQuantity(null)
    }
  }

  if (availableOriginOptions.length === 0) {
    return null
  }

  return (
    <tr className='hover:bg-gray-100 even:bg-gray-50'>
      <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap md:whitespace-normal'>
        {product.name}
      </td>
      <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap md:whitespace-normal'>
        {stakeHolder.name}
      </td>
      <td className='px-6 py-4 text-sm text-gray-500'>
        <div className='w-48'>
          <InlineSelectField
            id={`origin-${productOriginId}`}
            name={`origin-${productOriginId}`}
            placeholder={t('table.form.reception.placeholder')}
            options={availableOriginOptions}
            value={originSelectedOption}
            multiple={false}
            menuClassName='z-10'
            onChange={setOriginSelectedOption}
          />
        </div>
      </td>

      {isUserStockEnabled && product.measurementUnit && (
        <>
          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap md:whitespace-normal'>
            <InputField
              disabled={!originSelected}
              id={`quantity_${productOriginId}`}
              name={`quantity_${productOriginId}`}
              type='number'
              min={0}
              max={originSelected?.stock?.toString()}
              value={originQuantity ?? ''}
              onChange={event => {
                setOriginQuantity(Number(event.currentTarget.value))
              }}
              error={quantityHasError}
            />
          </td>
          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap md:whitespace-normal'>
            {originSelected?.stock ?? '-'} {product.measurementUnit}
          </td>
        </>
      )}

      {isUserStockEnabled && !product.measurementUnit && (
        <>
          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap md:whitespace-normal'>
            <Link
              to={`/${type === 'stakeholderProduct' ? `/stakeholder-products` : `/products`}/${
                product.id
              }/edit`}
              className='underline'
            >
              {t('measurementUnitRequired')}
            </Link>
          </td>
          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap md:whitespace-normal'>
            -
          </td>
        </>
      )}

      <td className='px-6 py-4 text-sm'>
        {!!originSelected && (
          <button
            className={classNames({
              'text-blue-600 hover:text-blue-700': isAllowedToSubmit,
              'cursor-not-allowed text-blue-300': !isAllowedToSubmit,
            })}
            disabled={!isAllowedToSubmit}
            onClick={handleAddOrigin}
          >
            <PlusCircleIcon className='h-5 w-5' />
          </button>
        )}
      </td>
    </tr>
  )
}
