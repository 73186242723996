import {
  Avatar,
  COLOR,
  FeedType,
  Feeds,
  Loader,
  Modal,
  SIZE,
} from '@blockchain-traceability-sl/tailwind-components'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { MetadataTable } from './MetadataTable'
import { MetadataEntry } from '@/features/metadatas'
import { noop } from '@/utils/noop'
import { ArrowLeftIcon, CheckIcon, PencilIcon } from '@heroicons/react/outline'
import { TRU } from '@/features/trus'
import { Reception } from '@/features/receptions'
import { StakeHolderProduct } from '@/features/stakeHolderProducts'
import { StakeHolder } from '@/features/stakeHolders'
import { useCompanyUser, useIsUserStockEnabled } from '@/features/auth'
import { Shipment } from '@/features/shipments'
import { Product } from '@/features/products'
import classNames from 'classnames'
import { TraceabilityMode } from '@/pages/traceability/TraceabilityView/constants'
import { useAnalytics } from '@/features/analytics'

export enum ModalMetadataItemTypes {
  TRU_CREATED = 'TRU_CREATED',
  TRU_UPDATED = 'TRU_UPDATED',
  RECEPTION_CREATED = 'RECEPTION_CREATED',
  RECEPTION_UPDATED = 'RECEPTION_UPDATED',
  SHIPMENT_CREATED = 'SHIPMENT_CREATED',
  SHIPMENT_UPDATED = 'SHIPMENT_UPDATED',
}

export type ModalMetadataHistoryItem =
  | {
      id: string
      notVerified?: boolean
      title: {
        tru?: string
        stock?: string | null
        measurementUnit?: string | null
      }
      date: Date
      type: ModalMetadataItemTypes.TRU_CREATED | ModalMetadataItemTypes.RECEPTION_CREATED
      data: MetadataEntry[] | null
    }
  | {
      id: string
      notVerified?: boolean
      title: { tru?: string; updatedBy?: string }
      date: Date
      type:
        | ModalMetadataItemTypes.TRU_UPDATED
        | ModalMetadataItemTypes.RECEPTION_UPDATED
        | ModalMetadataItemTypes.SHIPMENT_UPDATED
      data: MetadataEntry[] | null
    }
  | {
      id: string
      notVerified?: boolean
      title: {
        tru: string
        target?: string
        stock?: string | null
        measurementUnit?: string | null
      }
      date: Date
      type: ModalMetadataItemTypes.SHIPMENT_CREATED
      data: MetadataEntry[] | null
    }

export type ModalMetadataItem = {
  id: string
  title: {
    tru: string
    target?: string
    stock?: string | null
    measurementUnit?: string | null
  }
  date: Date
  type:
    | ModalMetadataItemTypes.TRU_CREATED
    | ModalMetadataItemTypes.RECEPTION_CREATED
    | ModalMetadataItemTypes.SHIPMENT_CREATED
  data: MetadataEntry[] | null
  history: ModalMetadataHistoryItem[]
}

export interface ModalMetadataProps {
  show: boolean
  toggle?: () => void
  header: {
    image?: string
    title: string
    subtitle?: string
  }
  items: ModalMetadataItem[]
  isLoading?: boolean
  viewMode?: TraceabilityMode

  // Entities
  shipments?: Pick<Shipment, '_id' | 'truId'>[]
  trus?: Pick<TRU, '_id' | 'productId' | 'reference'>[]
  products?: Pick<Product, '_id' | 'companyId' | 'name'>[]
  receptions?: Pick<Reception, '_id' | 'stakeHolderProductId' | 'companyId' | 'reference'>[]
  stakeHolderProducts?: Pick<StakeHolderProduct, '_id' | 'stakeHolder' | 'companyId'>[]
  stakeHolders?: Pick<StakeHolder, '_id' | 'company'>[]
}

export const ModalMetadata = ({
  show,
  toggle = noop,
  header,
  items,
  isLoading,
  viewMode,

  // Entities
  shipments = [],
  trus = [],
  products = [],
  receptions = [],
  stakeHolderProducts = [],
  stakeHolders = [],
}: ModalMetadataProps) => {
  const { t } = useTranslation('nsModalMetadata')
  const [historySelected, setHistorySelected] = useState<null | string>(null)
  const isUserStockEnabled = useIsUserStockEnabled()
  const analytics = useAnalytics()

  const user = useCompanyUser()

  const makeTitle = useCallback(
    (
      {
        tru,
        updatedBy,
        target,
        stock,
        measurementUnit,
      }: {
        tru?: string
        updatedBy?: string
        target?: string
        stock?: string | null
        measurementUnit?: string | null
      },
      type: ModalMetadataItemTypes
    ): ReactNode => {
      switch (type) {
        case ModalMetadataItemTypes.TRU_CREATED:
        case ModalMetadataItemTypes.RECEPTION_CREATED:
          return (
            <>
              <Trans
                t={t}
                i18nKey='truCreated'
                values={{ name: tru }}
                components={{ bold: <b /> }}
              />{' '}
              {isUserStockEnabled && !!stock && stock !== '0' && !!measurementUnit && (
                <b>
                  (
                  {`${t('number', {
                    value: stock,
                  })} ${measurementUnit}`}
                  )
                </b>
              )}
            </>
          )
        case ModalMetadataItemTypes.TRU_UPDATED:
        case ModalMetadataItemTypes.RECEPTION_UPDATED:
          return (
            <Trans
              t={t}
              i18nKey='truUpdated'
              values={{ name: tru, updatedBy }}
              components={{ bold: <b /> }}
            />
          )
        case ModalMetadataItemTypes.SHIPMENT_CREATED:
          return (
            <>
              <Trans
                t={t}
                i18nKey='shipmentCreated'
                values={{ name: tru, target }}
                components={{ bold: <b /> }}
              />{' '}
              {isUserStockEnabled && !!stock && stock !== '0' && !!measurementUnit && (
                <b>
                  (
                  {`${t('number', {
                    value: stock,
                  })} ${measurementUnit}`}
                  )
                </b>
              )}
            </>
          )
        case ModalMetadataItemTypes.SHIPMENT_UPDATED:
          return (
            <Trans
              t={t}
              i18nKey='shipmentUpdated'
              values={{ name: tru, updatedBy }}
              components={{ bold: <b /> }}
            />
          )

        default:
          return ''
      }
    },
    [isUserStockEnabled, t]
  )

  const handleViewHistory = useCallback(
    (itemId: string) => {
      setHistorySelected(itemId)

      const itemSelected = items.find(({ id }) => id === itemId) as ModalMetadataItem

      if (itemSelected.type === ModalMetadataItemTypes.TRU_CREATED) {
        const truSelected = trus.find(({ _id }) => _id === itemId)
        const productSelected = products.find(({ _id }) => _id === truSelected?.productId)
        const companySelected =
          stakeHolders.find(({ _id }) => _id === productSelected?.companyId)?.company.name ||
          user._id === productSelected?.companyId
            ? user.name
            : undefined

        analytics.track('ACTION_CLICK_TRU_AUDITS', {
          TRU: truSelected?.reference,
          Product: productSelected?.name,
          Company: companySelected,
          'Company TRU': companySelected,
        })
      } else if (itemSelected.type === ModalMetadataItemTypes.SHIPMENT_CREATED) {
        const shipmentSelected = shipments.find(({ _id }) => _id === itemId)
        const truSelected = trus.find(({ _id }) => _id === shipmentSelected?.truId)
        const productSelected = products.find(({ _id }) => _id === truSelected?.productId)
        const companySelected =
          stakeHolders.find(({ _id }) => _id === productSelected?.companyId)?.company.name ||
          user._id === productSelected?.companyId
            ? user.name
            : undefined

        analytics.track('ACTION_CLICK_TRU_AUDITS', {
          TRU: truSelected?.reference,
          Product: productSelected?.name,
          Company: companySelected,
          'Company TRU': companySelected,
        })
      } else if (itemSelected.type === ModalMetadataItemTypes.RECEPTION_CREATED) {
        const receptionSelected = receptions.find(({ _id }) => _id === itemId)
        const stakeHolderProductSelected = stakeHolderProducts.find(
          ({ _id }) => _id === receptionSelected?.stakeHolderProductId
        )
        const stakeHolderSelected = stakeHolders.find(
          ({ _id }) => _id === stakeHolderProductSelected?.stakeHolder.id
        )?.company.name
        const ownerSelected =
          stakeHolders.find(({ _id }) => _id === receptionSelected?.companyId)?.company.name ||
          user._id === receptionSelected?.companyId
            ? user.name
            : undefined

        analytics.track('ACTION_CLICK_TRU_AUDITS', {
          TRU: receptionSelected?.reference,
          Product: stakeHolderProductSelected?.stakeHolder.product.name,
          Company: stakeHolderSelected,
          'Company TRU': ownerSelected,
        })
      }
    },
    [
      analytics,
      items,
      products,
      receptions,
      shipments,
      stakeHolderProducts,
      stakeHolders,
      trus,
      user._id,
      user.name,
    ]
  )

  useEffect(() => {
    setHistorySelected(null)
  }, [show])

  const feedItems: FeedType[] = useMemo(
    () =>
      historySelected
        ? items
            .find(({ id }) => id === historySelected)
            ?.history.map(({ title, date, data, type }): FeedType => {
              const titleParsed = makeTitle(title, type)
              return {
                title: titleParsed,
                time: t('title.date', {
                  date: new Date(date),
                }),
                icon: PencilIcon,
                color: COLOR.GREEN,
                content: (
                  <>
                    {data ? (
                      <MetadataTable data={data} onDownloadFail={toggle} />
                    ) : (
                      <div className='text-center'>
                        {t(
                          type === ModalMetadataItemTypes.TRU_CREATED
                            ? 'emptyBase'
                            : 'emptyShipment'
                        )}
                      </div>
                    )}
                  </>
                ),
              }
            }) || []
        : items.map(({ id, title, date, data, type, history }): FeedType => {
            const titleParsed = makeTitle(title, type)
            return {
              title: titleParsed,
              time: t('title.date', {
                date: new Date(date),
              }),
              icon: CheckIcon,
              color: COLOR.GREEN,
              content: (
                <>
                  {data ? (
                    <MetadataTable data={data} onDownloadFail={toggle} />
                  ) : (
                    <div className='text-center'>
                      {t(
                        type === ModalMetadataItemTypes.TRU_CREATED ? 'emptyBase' : 'emptyShipment'
                      )}
                    </div>
                  )}
                  {history.length > 1 && (
                    <span
                      className='float-right text-xs underline cursor-pointer mt-2 text-gray-500'
                      onClick={() => handleViewHistory(id)}
                    >
                      {t('viewHistory')}
                    </span>
                  )}
                </>
              ),
            }
          }),
    [items, makeTitle, t, toggle, historySelected, handleViewHistory]
  )

  return (
    <Modal show={show} toggle={toggle} size={SIZE.LARGE} dismissButton>
      {historySelected !== null && (
        <div className='absolute top-0 left-0 pt-4 pl-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-transparent'
            onClick={() => setHistorySelected(null)}
          >
            <span className='sr-only'>Previous</span>
            <ArrowLeftIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
      )}
      <Modal.Title>
        <Avatar size={SIZE.EXTRA_LARGE} src={header.image} />
        <p className='text-gray-500 font-normal text-base'>{header.title}</p>
        <p
          data-testid='modal.description'
          className={classNames(' font-medium leading-6', {
            'text-gray-500 text-base': viewMode === TraceabilityMode.PRODUCT,
            'text-gray-900 text-lg': !viewMode || viewMode !== TraceabilityMode.PRODUCT,
          })}
        >
          {header.subtitle}
        </p>
      </Modal.Title>
      <Modal.Body>
        <div className='text-left'>
          {isLoading ? <Loader center='full' /> : <Feeds feeds={feedItems} />}
        </div>
      </Modal.Body>
    </Modal>
  )
}
