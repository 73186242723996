import {
  Task,
  TaskStatus,
  getReceptionTaskRelationsByTaskId,
  getTruTaskRelationsByTaskId,
} from '@/features/tasks'
import {
  Entry,
  TableEntryRow,
  generateEntriesReportRows,
  prepareEntriesReportingData,
} from '@/features/entries'
import { Template } from '@/features/templates'
import i18n from '@/i18n'
import { PDF_COMPONENTS, createPdfDefinition } from '@/features/pdf'
import { Reception, getAll as getAllReceptions } from '@/features/receptions'
import { TRU, getAll as getAllTrus } from '@/features/trus'
import { Issue } from '@/features/issues'
import { Signature } from '@/features/signatures'
import { Content, TableCell } from 'pdfmake/interfaces'

export const getTaskRelations = async (
  taskId: string
): Promise<{ relatedTrus: string[]; relatedReceptions: string[] }> => {
  const truTaskRelations = await getTruTaskRelationsByTaskId(taskId)
  const receptionTaskRelations = await getReceptionTaskRelationsByTaskId(taskId)

  const object = {
    relatedTrus: truTaskRelations.map(t => t.truId),
    relatedReceptions: receptionTaskRelations.map(r => r.receptionId),
  }

  return object
}

interface TaskReportData {
  hasAssociations: boolean
  associations: { trus: TRU[]; receptions: Reception[] }
  createdAt: string
  closedAt?: string
  updatedAt: string
  formName: string
  formControl?: {
    code?: string
    isoStandardReference?: string
  }
  version: string
  createdBy: string
  assignedTo: string
  status: TaskStatus
  validator: { name: string; validationDate?: string } | undefined
  signature?: string
  entries: (Entry & { issuesEntries?: Entry[] })[]
  tables: {
    name: string
    columns: string[]
    rows: TableEntryRow[][]
  }[]
  companyLogo?: string

  options: {
    direction: 'rtl' | 'ltr'
  }
}

/**
 * Generate a PDF document with the task report signature
 * @param signature The signature to be added to the report
 * @returns The PDF document
 */
const reportSignature = (signature?: string): Content => {
  if (signature)
    return [
      PDF_COMPONENTS.spacer(),
      {
        columns: [
          { width: '*', text: '' },
          { width: 150, text: i18n.t('nsTaskReport:basicInfo.validationUser'), margin: [10, 0] },
        ],
      },
      {
        columns: [
          { width: '*', text: '' },
          { width: 150, svg: signature, margin: [10, 0] },
        ],
      },
    ]
  else return []
}

/**
 * Generate a PDF document with the task report
 * @param report The task report data
 * @returns The PDF document
 */
const generateTaskReportPdfDocument = (report: TaskReportData) => {
  let headerHeight = 200
  const infoColumnWidths = ['*', '*']

  let basicInfo: TableCell[][] = [
    [
      {
        text: [
          {
            text: `${i18n.t('nsTaskReport:basicInfo.createdBy')} `,
            bold: true,
          },
          {
            text: report.createdBy,
          },
        ],
        margin: [10, 0],
      },
      {
        text: [
          {
            text: `${i18n.t('nsTaskReport:basicInfo.creationDate')} `,
            bold: true,
          },
          {
            text: i18n.t('nsTaskReport:basicInfo.creationDateValue', {
              date: new Date(report.createdAt),
            }),
          },
        ],
        margin: [10, 0],
      },
    ],
    [
      {
        text: [
          {
            text: `${i18n.t('nsTaskReport:basicInfo.assignedTo')} `,
            bold: true,
          },
          {
            text: report.assignedTo,
          },
        ],
        margin: [10, 0],
      },
      {
        text: [
          {
            text: `${i18n.t('nsTaskReport:basicInfo.closedDate')} `,
            bold: true,
          },
          {
            text: i18n.t('nsTaskReport:basicInfo.closedDateValue', {
              date: new Date(report.closedAt || report.updatedAt),
            }),
          },
        ],
        margin: [10, 0],
      },
    ],
  ]

  if (report.validator) {
    basicInfo = [
      ...basicInfo,
      [
        {
          text: [
            {
              text: `${i18n.t('nsTaskReport:basicInfo.validationUser')} `,
              bold: true,
            },
            {
              text: report.validator.name,
            },
          ],
          margin: [10, 0],
        },
        {
          text: [
            {
              text: `${i18n.t('nsTaskReport:basicInfo.validationDate')} `,
              bold: true,
            },
            {
              text: report.validator.validationDate
                ? i18n.t('nsTaskReport:basicInfo.validationDateValue', {
                    date: new Date(report.validator.validationDate),
                  })
                : '-',
            },
          ],
          margin: [10, 0],
        },
      ],
    ]
    headerHeight += 20
  }

  basicInfo = [
    ...basicInfo,
    [
      {
        text: [
          {
            text: `${i18n.t('nsTaskReport:basicInfo.status')} `,
            bold: true,
          },
          {
            text: i18n.t(`nsTaskReport:basicInfo.statusTypes.${report.status}`),
          },
        ],
        margin: [10, 0],
      },
      {
        text: [
          {
            text: `${i18n.t('nsTaskReport:basicInfo.version')} `,
            bold: true,
          },
          {
            text: i18n.t('number', { value: report.version }),
          },
        ],
        margin: [10, 0],
      },
    ],
  ]

  if (report.formControl?.code && !report.formControl?.isoStandardReference) {
    basicInfo = [
      ...basicInfo,
      [
        {
          colSpan: 2,
          text: [
            {
              text: `${i18n.t('nsTaskReport:basicInfo.code')} `,
              bold: true,
            },
            {
              text: report.formControl.code,
            },
          ],
          margin: [10, 0],
        },
      ],
    ]
    headerHeight += 10
  } else if (report.formControl?.code && report.formControl?.isoStandardReference) {
    basicInfo = [
      ...basicInfo,
      [
        {
          text: [
            {
              text: `${i18n.t('nsTaskReport:basicInfo.iso')} `,
              bold: true,
            },
            {
              text: report.formControl.isoStandardReference,
            },
          ],
          margin: [10, 0],
        },
        {
          text: [
            {
              text: `${i18n.t('nsTaskReport:basicInfo.code')} `,
              bold: true,
            },
            {
              text: report.formControl.code,
            },
          ],
          margin: [10, 0],
        },
      ],
    ]
    headerHeight += 20
  }

  if (report.companyLogo) {
    infoColumnWidths.unshift('auto')
    basicInfo = basicInfo.map(row => [
      // Space to logo position
      '',
      ...row,
    ])

    headerHeight += 50
  }

  const headerInfoSection = (): Content => {
    let infoHeader: TableCell[] = [
      {
        text: report.formName,
        bold: true,
        margin: [10, 5],
      },
      {
        text: [
          {
            text: `${i18n.t('nsTaskReport:basicInfo.page')} `,
            bold: true,
          },
          {
            text: i18n.t('nsTaskReport:basicInfo.pageValue', {
              currentPage: '$currentPage',
              pageCount: '$pageCount',
            }),
          },
        ],
        margin: [10, 5],
      },
    ]

    if (report.companyLogo) {
      infoHeader = [
        {
          image: 'companyLogo',
          fit: [100, 100],
          margin: 5,
          rowSpan: basicInfo.length + 1,
        },
        ...infoHeader,
      ]
    }

    return [
      PDF_COMPONENTS.spacer(2),
      {
        text: `${i18n.t('nsTaskReport:title')} ${report.formName}`,
        style: 'header',
        alignment: 'center',
        margin: [40, 0],
      },
      PDF_COMPONENTS.spacer(),
      {
        layout: 'tableVerticalLightWithoutHeaderLayout',
        style: 'table',
        margin: [40, 0],
        table: {
          widths: infoColumnWidths,
          body: [infoHeader, ...basicInfo],
        },
      },
    ]
  }

  const pdfDocument = createPdfDefinition(
    [
      ...generateEntriesReportRows({ ...report, direction: report.options.direction }),

      // Association info
      [
        PDF_COMPONENTS.associateInfo(
          report.associations.trus,
          report.associations.receptions,
          report.hasAssociations
        ),
      ],
      reportSignature(report.signature),
    ],
    {
      header: headerInfoSection(),
      footer: null,
      pageMargins: [40, headerHeight, 40, 60],
      images: report.companyLogo ? { companyLogo: report.companyLogo } : undefined,
    }
  )

  return pdfDocument
}

export const generateTaskReport = async ({
  task,
  taskTemplate,
  issues,
  creatorName,
  assignedToName,
  validator,
  signature,
  entrySignatures,
  issueMediaName,
  companyLogo,
  options,
}: {
  task: Task
  taskTemplate: Template
  creatorName: string
  assignedToName: string
  issues: Issue[]
  signature?: Signature
  validator?: { name: string; validationDate?: string }
  entrySignatures: Signature[]
  companyLogo?: string
  issueMediaName: string
  options: {
    direction: 'rtl' | 'ltr'
  }
}) => {
  const relations = await getTaskRelations(task._id)

  let trus: TRU[] = []
  if (relations.relatedTrus.length > 0) {
    trus = (await getAllTrus()).filter(t => relations.relatedTrus?.indexOf(t._id) > -1)
  }

  let receptions: Reception[] = []
  if (relations.relatedReceptions.length > 0) {
    receptions = (await getAllReceptions()).filter(
      r => relations.relatedReceptions.indexOf(r._id) > -1
    )
  }

  let signatureSVG: string | undefined
  if (signature) {
    const response = await fetch(signature.assetLocationUrl)
    signatureSVG = await response.text()
  }

  const { entries, tables } = await prepareEntriesReportingData({
    entries: task.entries,
    issues,
    issueMediaName,
    signatures: entrySignatures,
  })

  const report: TaskReportData = {
    hasAssociations: !!(trus.length || receptions.length),
    associations: {
      trus,
      receptions,
    },
    createdAt: task.createdAt,
    formName: taskTemplate.name,
    formControl: task.formControl,
    version: String(task.formVersion),
    createdBy: creatorName,
    assignedTo: assignedToName,
    status: task.status,
    updatedAt: task.updatedAt,
    closedAt: task.closedAt,
    signature: signatureSVG,
    validator,
    entries,
    tables,
    companyLogo: companyLogo,
    options,
  }

  return generateTaskReportPdfDocument(report)
}
