import { DateLocaleField } from '@/components/DateLocaleField'
import { Document, DocumentPicker } from '@/features/documents'
import { getProductNameWithReference, useProducts } from '@/features/products'
import { useStakeHolders } from '@/features/stakeHolders'
import {
  TemplateEntryReferenceType,
  TemplateEntryReferenceValue,
  TemplateEntryType,
} from '@/features/templates'
import { useQueryTRUs } from '@/features/trus'
import { useToggle } from '@/hooks/use-toggle'
import {
  Button,
  InputField,
  SelectField,
  SelectMultiField,
  SelectorOption,
  SwitchField,
  TextAreaField,
} from '@blockchain-traceability-sl/tailwind-components'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { ChangeEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface MetadataEntryError {
  key?: string | string[]
  type?: string | string[]
  value?: string | string[]
}
interface MetadataEntryTouched {
  key?: boolean
  type?: boolean
  value?: boolean
}

interface Entry {
  key: string
  type: TemplateEntryType
  value: string
  values: string[] | TemplateEntryReferenceValue[]
  mandatory?: boolean
  options: SelectorOption[]
}

interface MetadataEntriesFieldProps {
  // Values
  keys: Entry[]
  documents: Document[]
  // Errors
  keysError?: MetadataEntryError[]
  keysTouched?: MetadataEntryTouched[]
  // Modifiers
  setFieldValue: (field: string, value: string | string[] | TemplateEntryReferenceValue[]) => void
  setFieldTouched: (field: string, value: boolean) => void
}

export const MetadataEntriesField = ({
  keys,
  documents,
  keysError,
  keysTouched,
  setFieldValue,
  setFieldTouched,
}: MetadataEntriesFieldProps) => {
  const { t } = useTranslation('nsMetadataKeys')
  const [showDocumentPicker, toggleShowDocumentPicker] = useToggle(false)
  const [documentPickerTrigger, setDocumentPickerTrigger] = useState<number | null>(null)
  const [documentsPicked, setDocumentsPicked] = useState<Document[]>(documents)

  const stakeHolders = useStakeHolders().stakeHolders.map(
    (item): SelectorOption => ({ label: item.company.name, value: item._id })
  )

  const products = useProducts().products.map(
    (item): SelectorOption => ({ label: getProductNameWithReference(item), value: item._id })
  )

  const trus = useQueryTRUs().trus.map(
    (item): SelectorOption => ({ label: item.reference, value: item._id })
  )

  const handleKeyChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldValue(event.currentTarget.name, event.currentTarget.value)
    },
    [setFieldValue]
  )

  const handleKeyBlur = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldTouched(event.currentTarget.name, true)
    },
    [setFieldTouched]
  )

  const getPlaceholder = useCallback(
    (inputType: string) => {
      if (inputType === TemplateEntryType.NUMBER) {
        return t('entries.placeholders.number')
      }

      if (
        inputType === TemplateEntryType.SINGLE_CHOICE ||
        inputType === TemplateEntryType.MULTIPLE_CHOICE
      ) {
        return t('entries.placeholders.selection')
      }

      if (inputType === TemplateEntryType.LINK) {
        return t('entries.placeholders.link')
      }

      if (inputType === TemplateEntryType.SHORT_TEXT || inputType === TemplateEntryType.LONG_TEXT) {
        return t('entries.placeholders.text')
      }

      return ''
    },
    [t]
  )

  const entryField = useCallback(
    ({ type, value, values, options }: Entry, index: number) => {
      switch (type) {
        case TemplateEntryType.DATE:
          return (
            <DateLocaleField
              /**
               * Add value to key in order to rerender the field
               * @see https://reactjs.org/docs/lists-and-keys.html#keys
               */
              key={`metadata_${index}${value || null}_value`}
              id={`metadata_${index}_value`}
              name={`metadata.${index}.value`}
              value={value || null}
              onChange={value => value && setFieldValue(`metadata.${index}.value`, value)}
              label={t('entries.value')}
              error={
                !!(
                  keysError &&
                  keysError[index] &&
                  keysTouched &&
                  keysTouched[index] &&
                  keysTouched[index].value
                )
              }
            />
          )
        case TemplateEntryType.LINK:
        case TemplateEntryType.NUMBER:
        case TemplateEntryType.SHORT_TEXT:
        case TemplateEntryType.TIME:
          return (
            <InputField
              type={type === TemplateEntryType.LINK ? 'url' : type}
              id={`metadata_${index}_value`}
              name={`metadata.${index}.value`}
              value={value}
              onChange={handleKeyChange}
              onBlur={handleKeyBlur}
              label={t('entries.value')}
              error={
                !!(
                  keysError &&
                  keysError[index] &&
                  keysTouched &&
                  keysTouched[index] &&
                  keysTouched[index].value
                )
              }
              description={
                !!(
                  keysError &&
                  keysError[index] &&
                  keysTouched &&
                  keysTouched[index] &&
                  keysTouched[index].value
                ) && type === TemplateEntryType.LINK
                  ? t('validation.link')
                  : undefined
              }
              placeholder={getPlaceholder(type)}
              errorText={type === TemplateEntryType.LINK ? t('validation.link') : undefined}
            />
          )

        case TemplateEntryType.LONG_TEXT:
          return (
            <TextAreaField
              id={`metadata_${index}_value`}
              name={`metadata.${index}.value`}
              value={value}
              onChange={handleKeyChange}
              onBlur={handleKeyBlur}
              label={t('entries.value')}
              placeholder={getPlaceholder(type)}
              error={
                !!(
                  keysError &&
                  keysError[index] &&
                  keysTouched &&
                  keysTouched[index] &&
                  keysTouched[index].value
                )
              }
            />
          )

        case TemplateEntryType.SINGLE_CHOICE:
          return (
            <SelectField
              key={`metadata_${index}_value`}
              id={`metadata_${index}_value`}
              name={`metadata.${index}.value`}
              options={options}
              value={options.find(option => option.value === value) || null}
              onChange={option => setFieldValue(`metadata.${index}.value`, option?.value || '')}
              onBlur={handleKeyBlur}
              label={t('entries.value')}
              placeholder={getPlaceholder(type)}
              error={
                !!(
                  keysError &&
                  keysError[index] &&
                  keysTouched &&
                  keysTouched[index] &&
                  keysTouched[index].value
                )
              }
            />
          )

        case TemplateEntryType.MULTIPLE_CHOICE:
          return (
            <SelectMultiField
              key={`metadata_${index}_values`}
              id={`metadata_${index}_values`}
              name={`metadata.${index}.values`}
              options={options}
              value={options.filter(option => (values as string[]).includes(option.value))}
              onChange={options =>
                setFieldValue(
                  `metadata.${index}.values`,
                  options.map(({ value }) => value)
                )
              }
              label={t('entries.value')}
              placeholder={getPlaceholder(type)}
              error={
                !!(
                  keysError &&
                  keysError[index] &&
                  keysTouched &&
                  keysTouched[index] &&
                  keysTouched[index].value
                )
              }
            />
          )

        case TemplateEntryType.BOOLEAN:
          return (
            <div className='flex justify-center content-end -mb-14'>
              <SwitchField
                key={`metadata_${index}_value`}
                id={`metadata_${index}_value`}
                name={`metadata.${index}.value`}
                checked={value === 'true'}
                onChange={checked => {
                  setFieldValue(`metadata.${index}.value`, checked ? 'true' : 'false')
                }}
              />
            </div>
          )

        case TemplateEntryType.DOCUMENT:
          return (
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1 '>
                {t('entries.value')}
              </label>
              {!value ? (
                <Button
                  color='secondary'
                  className={classNames({
                    'border-red-500': !!(
                      keysError &&
                      keysError[index] &&
                      keysTouched &&
                      keysTouched[index] &&
                      keysTouched[index].value
                    ),
                  })}
                  onClick={() => {
                    setDocumentPickerTrigger(index)
                    toggleShowDocumentPicker()
                  }}
                >
                  {t('entries.document.choose')}
                  {!!(
                    keysError &&
                    keysError[index] &&
                    keysTouched &&
                    keysTouched[index] &&
                    keysTouched[index].value
                  ) && (
                    <ExclamationCircleIcon
                      className='ml-1 -mr-1 h-5 w-5 text-red-500'
                      aria-hidden='true'
                    />
                  )}
                </Button>
              ) : (
                <>
                  <div className='space-x-3'>
                    <Button
                      color='secondary'
                      onClick={() => {
                        setDocumentPickerTrigger(index)
                        toggleShowDocumentPicker()
                      }}
                    >
                      {t('entries.document.change')}
                    </Button>
                    <Button
                      color='danger'
                      onClick={() => {
                        setFieldValue(`metadata.${index}.value`, '')
                        setFieldTouched(`metadata.${index}.value`, false)
                      }}
                    >
                      {t('entries.document.remove')}
                    </Button>
                  </div>
                  <p className='mt-2 text-sm text-gray-500'>
                    {t('entries.document.fileSelected', {
                      name: documentsPicked.find(({ _id }) => _id === value)?.title,
                    })}
                  </p>
                </>
              )}
            </div>
          )

        case TemplateEntryType.REFERENCE: {
          let options: SelectorOption[] = []

          switch ((values[0] as TemplateEntryReferenceValue).type) {
            case TemplateEntryReferenceType.STAKEHOLDER:
              options = stakeHolders
              break
            case TemplateEntryReferenceType.PRODUCT:
              options = products
              break
            case TemplateEntryReferenceType.TRU:
              options = trus
              break
          }

          return (
            <SelectField
              id={`metadata_${index}_values`}
              key={`metadata.${index}.values`}
              name={`metadata.${index}.values`}
              label={t('entries.value')}
              options={options}
              onChange={option =>
                setFieldValue(`metadata.${index}.values`, [
                  {
                    ...(values[0] as TemplateEntryReferenceValue),
                    id: option?.value || '',
                    name: option?.label || '',
                  },
                ])
              }
              value={
                options.find(
                  ({ value }) => value === (values[0] as TemplateEntryReferenceValue).id
                ) || null
              }
              isClearable
              error={
                !!(
                  keysError &&
                  keysError[index] &&
                  keysTouched &&
                  keysTouched[index] &&
                  keysTouched[index].value
                )
              }
            />
          )
        }
      }
    },
    [
      documentsPicked,
      getPlaceholder,
      handleKeyBlur,
      handleKeyChange,
      keysError,
      keysTouched,
      products,
      setFieldTouched,
      setFieldValue,
      stakeHolders,
      t,
      toggleShowDocumentPicker,
      trus,
    ]
  )

  return (
    <>
      {keys.map((key, index) => (
        <div key={index} className='grid grid-cols-2 gap-6'>
          <InputField
            id={`metadata_${index}_key`}
            name={`metadata.${index}.key`}
            onChange={handleKeyChange}
            onBlur={handleKeyBlur}
            value={key.key}
            label={t(key.mandatory ? 'entries.mandatoryKey' : 'entries.key')}
            disabled
          />
          {entryField(key, index)}
        </div>
      ))}

      <DocumentPicker
        show={showDocumentPicker}
        toggle={toggleShowDocumentPicker}
        onPick={documentFile => {
          setFieldValue(`metadata.${documentPickerTrigger}.value`, documentFile._id)
          setDocumentsPicked([...documentsPicked, documentFile])
          setDocumentPickerTrigger(null)
          toggleShowDocumentPicker()
        }}
      />
    </>
  )
}
