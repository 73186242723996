import { useCallback, useMemo } from 'react'
import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { IProductFilters, Product } from './interfaces'
import { AxiosError } from 'axios'
import {
  createProduct,
  createWithCSV,
  getAll,
  getById,
  getByIds,
  getCount,
  getCsvTemplate,
  getPaginatedProducts,
  getTags,
  remove,
  updateProduct,
} from './service'
import * as NotifyService from '@/services/notify.service'
import { customErrorToTrack, useAnalytics } from '../analytics'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { BulkError, CustomError, ErrorCodes } from '@blockchain-traceability-sl/custom-error-codes'
import { downloader } from '@/helpers/downloader'
import { downloadProductBulkErrorReport } from './helpers'
import { mergeInfiniteQueryOptions } from '@/utils/merge-query-options'
import { Paginated } from '@/hooks/use-pagination'
import { DEFAULT_PAGE_SIZE } from '@/app/constants'

export const QUERY_PRODUCTS_KEY = 'products'
export const QUERY_PRODUCTS_COUNT_KEY = 'products-count'

export const useUpdateProduct = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const queryClient = useQueryClient()
  const { mutate, ...rest } = useMutation(updateProduct, {
    onSuccess: () => {
      NotifyService.success({
        title: t('product.update.success.title'),
        description: t('product.update.success.description'),
      })
      queryClient.invalidateQueries(QUERY_PRODUCTS_KEY)
    },
    onError: (error: AxiosError) => {
      analytics.track(
        'CUSTOMER_EDIT_PRODUCT_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return { ...rest, updateProduct: mutate }
}

export const useCreateProduct = () => {
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const queryClient = useQueryClient()
  const { mutate, ...rest } = useMutation(createProduct, {
    onSuccess: (data, variables) => {
      analytics.track('CUSTOMER_CREATE_PRODUCT', {
        Product: variables.name,
      })
      NotifyService.success({
        title: t('product.create.success.title'),
        description: t('product.create.success.description'),
      })
      queryClient.invalidateQueries(QUERY_PRODUCTS_KEY)
    },
    onError: (error: AxiosError) => {
      analytics.track(
        'CUSTOMER_CREATE_PRODUCT_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return { ...rest, createProduct: mutate }
}

/**
 * @deprecated use hooks with pagination or filters instead
 */
export const useProducts = (
  filters?: IProductFilters,
  options?: Omit<UseQueryOptions<Product[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_PRODUCTS_KEY, { filters }],
    () => getAll(filters),
    options
  )

  return {
    ...rest,
    products: useMemo(() => data || [], [data]),
  }
}

export const useProductById = (
  productId: string,
  options?: Omit<UseQueryOptions<Product | undefined, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_PRODUCTS_KEY, productId],
    () => getById(productId),
    options
  )

  return {
    ...rest,
    product: data,
  }
}

export const useProductsByIds = (
  ids: string[],
  options?: Omit<UseQueryOptions<Product[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery([QUERY_PRODUCTS_KEY, { ids }], () => getByIds(ids), options)
  const products = useMemo(() => data || [], [data])

  return {
    ...rest,
    products,
  }
}

export const useProductsCount = (
  options?: Omit<UseQueryOptions<number, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data = 0, ...rest } = useQuery(QUERY_PRODUCTS_COUNT_KEY, getCount, options)

  return { ...rest, productsCount: data }
}

export const usePaginatedProducts = ({
  filters,
  pageSize = DEFAULT_PAGE_SIZE,
  ...overrideOptions
}: Omit<UseInfiniteQueryOptions<Paginated<Product>, AxiosError>, 'queryKey' | 'queryFn'> & {
  pageSize?: number
  filters: IProductFilters
}) => {
  const queryClient = useQueryClient()

  const options = mergeInfiniteQueryOptions(overrideOptions, {
    keepPreviousData: true,
    // Cache each product as individual cached item by id
    onSuccess(data) {
      overrideOptions?.onSuccess?.(data)
      data.pages
        .flatMap(({ items }) => items)
        .forEach(item => {
          queryClient.setQueryData([QUERY_PRODUCTS_KEY, item._id], item)
        })
    },
    getNextPageParam: lastPage => lastPage.pageInfo.nextPage || undefined,
    getPreviousPageParam: lastPage => lastPage.pageInfo.previousPage || undefined,
  })

  const { data, ...rest } = useInfiniteQuery(
    [QUERY_PRODUCTS_KEY, { filters, pageSize }, { isInfiniteQuery: true }],
    async ({ pageParam }) => {
      return getPaginatedProducts(filters, {
        offset: pageParam?.offset || 0,
        limit: pageParam?.limit || pageSize,
      })
    },
    options
  )

  const products: Product[] = useMemo(
    () => data?.pages.flatMap(({ items }) => items) || [],
    [data?.pages]
  )

  const totalCount = useMemo(
    () => data?.pages[data?.pages.length - 1]?.totalCount || 0,
    [data?.pages]
  )

  return {
    ...rest,
    data,
    products,
    totalCount,
  }
}

export const useFetchProducts = (filters?: IProductFilters) => {
  const queryClient = useQueryClient()

  const fetchProducts = useCallback(
    () => queryClient.fetchQuery([QUERY_PRODUCTS_KEY, filters], () => getAll(filters)),
    [filters, queryClient]
  )

  return {
    fetchProducts,
  }
}

export const useFetchProductById = () => {
  const queryClient = useQueryClient()

  const fetchProduct = useCallback(
    (productId: string) =>
      queryClient.fetchQuery([QUERY_PRODUCTS_KEY, productId], () => getById(productId)),
    [queryClient]
  )

  return {
    fetchProduct,
  }
}

export const useFetchProductsByIds = () => {
  const queryClient = useQueryClient()

  const fetchProductsByIds = useCallback(
    (ids: string[]) => queryClient.fetchQuery([QUERY_PRODUCTS_KEY, { ids }], () => getByIds(ids)),
    [queryClient]
  )

  return {
    fetchProductsByIds,
  }
}

export const useDeleteProduct = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const queryClient = useQueryClient()
  const history = useHistory()

  const { mutate, ...rest } = useMutation((product: Product) => remove(product._id), {
    onSuccess: (data, product) => {
      analytics.track('CUSTOMER_DELETE_PRODUCT', {
        Product: product.name,
      })
      NotifyService.success({
        title: t('product.delete.success.title'),
        description: t('product.delete.success.description'),
        actionText: t('product.delete.success.action'),
        onActionClick: () => history.push('/products/create'),
      })
      queryClient.invalidateQueries([QUERY_PRODUCTS_KEY])
    },
    onError: (error: AxiosError) => {
      analytics.track(
        'CUSTOMER_DELETE_PRODUCT_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return { ...rest, deleteProduct: mutate }
}

export const useCreateProductsWithCSV = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const history = useHistory()

  /**
   * Set as valid processed if is Bulk error and all errors are already exist
   */
  const isAcceptableError = (error: AxiosError) => {
    return (
      error.response?.data.code === ErrorCodes.BULK_ERROR &&
      (error.response?.data as BulkError).errors.every(
        ({ error: customError }) => customError.code === ErrorCodes.ALREADY_EXISTS
      )
    )
  }

  const { mutate, ...rest } = useMutation(createWithCSV, {
    onSuccess(data) {
      analytics.track('CUSTOMER_CREATE_PRODUCTS_CSV')
      NotifyService.success({
        title: t('product.createBulk.success.title'),
        description: t('product.createBulk.success.description', {
          count: data.total,
        }),
        actionText: t('product.createBulk.success.action'),
        onActionClick: () => history.push('/products/create'),
      })
      queryClient.invalidateQueries(QUERY_PRODUCTS_KEY)
    },
    onError(error: AxiosError) {
      analytics.track(
        'CUSTOMER_CREATE_PRODUCTS_CSV_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )

      switch (error.response?.data.code) {
        case ErrorCodes.BULK_ERROR:
          {
            const bulkError: BulkError = error.response?.data

            // Set as valid processed if all errors are already exist
            if (isAcceptableError(error)) {
              NotifyService.success({
                title: t('product.createBulk.success.title'),
                description: t('product.createBulk.success.description', {
                  count: bulkError.total,
                }),
                actionText: t('product.createBulk.success.action'),
                onActionClick: () => history.push('/products/create'),
              })
            } else {
              NotifyService.error({
                title: t('product.createBulk.bulkError.title'),
                description: t('product.createBulk.bulkError.description', {
                  processed: bulkError.processed,
                  total: bulkError.total,
                }),
                actionText: t('product.createBulk.bulkError.action'),
                onActionClick: () => downloadProductBulkErrorReport(bulkError),
              })
            }
          }
          break

        case 10: // InvalidCSVError
          {
            const invalidCSVError: CustomError = error.response?.data

            if (invalidCSVError.entity !== 'file') {
              NotifyService.error({
                title: t('product.createBulk.entityError.title'),
                description: t('product.createBulk.entityError.description', {
                  context: invalidCSVError.entity,
                }),
                actionText: t('product.createBulk.entityError.action'),
              })
            } else {
              NotifyService.error({
                title: t('product.createBulk.error.title'),
                description: t('product.createBulk.error.description'),
                actionText: t('product.createBulk.error.action'),
              })
            }
          }
          break
        default:
          NotifyService.error({
            title: t('product.createBulk.error.title'),
            description: t('product.createBulk.error.description'),
            actionText: t('product.createBulk.error.action'),
          })
          break
      }
    },
  })

  return { ...rest, createProductsWithCSV: mutate, isAcceptableError }
}

export const useDownloadProductsTemplateCSV = () => {
  const { data } = useQuery([QUERY_PRODUCTS_KEY, { template: 'csv' }], getCsvTemplate)

  const downloadTemplate = (fileName: string) => {
    if (data) downloader(data, 'text/csv', fileName)
  }

  return { downloadTemplate }
}

export const useProductTags = (
  options?: Omit<UseQueryOptions<string[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery([QUERY_PRODUCTS_KEY, { tags: true }], getTags, options)

  return { ...rest, productTags: useMemo(() => data || [], [data]) }
}
