import { useMemo } from 'react'
import { UseQueryOptions, useQueries, useQuery } from 'react-query'
import {
  getAutomationActivityById,
  getByCreationEntityId,
  getByEntityId,
  getComplianceAudits,
  getLatestAuditByIds,
  getMetadatasByBelongsTo,
  getTaskActivityById,
} from './service'
import { Audit, AuditMetadata, AuditOperation, AuditTaskRelated } from './interfaces'
import { AxiosError } from 'axios'
import { Task, TaskReportSent } from '@/features/tasks'
import { ValidationRequest } from '@/features/validationRequests'
import { Automation } from '@/features/automations'
import { Compliance } from '../compliances'

export const QUERY_AUDITS_KEY = 'audits'
export const QUERY_AUDIT_METADATA = 'audit-metadata'
export const QUERY_AUDIT_AUTOMATION = 'audits-automation'
export const QUERY_AUDIT_COMPLIANCE = 'audits-compliance'

export const useAuditsById = <Entity = unknown>(
  entityId: string,
  options?: Omit<
    UseQueryOptions<Audit<Entity, AuditOperation>[], AxiosError, Audit<Entity, AuditOperation>[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_AUDITS_KEY, { entityId }],
    () => getByEntityId<Entity>(entityId),
    options
  )

  const audits = useMemo(() => data || [], [data])

  return { ...rest, audits }
}

export const useAuditsTaskById = (
  taskId: string,
  options?: Omit<UseQueryOptions<AuditTaskRelated[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_AUDITS_KEY, { taskId }],
    () => getTaskActivityById<Task | ValidationRequest | TaskReportSent>(taskId),
    options
  )

  return { ...rest, audits: useMemo(() => data || [], [data]) }
}

export const useQueryAuditMetadataByBelongTos = (
  belongsTos?: string[],
  options?: Omit<
    UseQueryOptions<AuditMetadata[] | undefined, AxiosError, AuditMetadata[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_AUDIT_METADATA, { belongsTos }],
    () => {
      if (belongsTos && belongsTos.length) {
        return Promise.allSettled(
          belongsTos.map(belongsTo => getMetadatasByBelongsTo(belongsTo))
        ).then(data => {
          return data.flatMap(auditChunk =>
            auditChunk.status === 'fulfilled' ? auditChunk.value : []
          )
        })
      }
    },
    options
  )

  const auditMetadata = useMemo(() => data || [], [data])

  return { ...rest, auditMetadata }
}

export const useComplianceAuditsById = (
  complianceId: string,
  options?: Omit<
    UseQueryOptions<Audit<Compliance, AuditOperation>[], AxiosError>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_AUDIT_COMPLIANCE, complianceId],
    () => getComplianceAudits(complianceId),
    options
  )
  return { ...rest, audits: useMemo(() => data || [], [data]) }
}

export const useAutomationAuditsById = (
  automationId: string,
  options?: Omit<
    UseQueryOptions<
      Audit<Task | Automation, AuditOperation>[],
      AxiosError,
      Audit<Task | Automation, AuditOperation>[]
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_AUDIT_AUTOMATION, automationId],
    () => getAutomationActivityById(automationId),
    options
  )

  return { ...rest, audits: useMemo(() => data || [], [data]) }
}

export const useAuditCreationByEntityIds = <Entity = unknown>(
  entityIds: string[],
  options?: Omit<
    UseQueryOptions<Audit<Entity, AuditOperation>[], AxiosError, Audit<Entity, AuditOperation>[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const auditsQueries = useQueries(
    entityIds.map(entityId => ({
      queryKey: [QUERY_AUDITS_KEY, { entityId, operation: AuditOperation.CREATE }],
      queryFn: () => getByCreationEntityId(entityId),
      options,
    }))
  )

  const audits = auditsQueries.flatMap(({ data }) => data || [])

  return {
    audits,
    isLoading: auditsQueries.some(query => query.isLoading),
  }
}

export const useLatestAuditsByEntityIds = <Entity = unknown>(
  entityIds: string[],
  options?: Omit<
    UseQueryOptions<Audit<Entity, AuditOperation>[], AxiosError, Audit<Entity, AuditOperation>[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_AUDITS_KEY, { entityIds }],
    () => getLatestAuditByIds<Entity>(entityIds),
    options
  )

  const audits = useMemo(() => data || [], [data])

  return { ...rest, audits }
}
