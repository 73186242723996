import { useStakeHolderByIds } from '@/features/stakeHolders'
import { SelectField, SelectFieldProps } from '@blockchain-traceability-sl/tailwind-components'
import { useTranslation } from 'react-i18next'
import { usePaginatedShipments } from '../hooks'

export const ShipmentsSelectField = ({
  truId,
  ...props
}: Omit<SelectFieldProps, 'options'> & { truId?: string }) => {
  const { isLoading, shipments, fetchNextPage } = usePaginatedShipments({
    filters: { truIds: truId ? [truId] : [] },
  })
  const { t } = useTranslation('nsShipmentsSelectField')

  const { stakeHolders } = useStakeHolderByIds(
    shipments.map(({ to }) => to).filter((item, index, self) => index === self.indexOf(item))
  )

  const shipmentOptions = shipments
    // Filter only shipments to stakeHolders that exist
    .filter(({ to }) => !!stakeHolders.find(({ _id }) => _id === to))
    .map(({ _id, to, createdAt }) => ({
      label: t('value', {
        name: stakeHolders.find(({ _id }) => _id === to)?.company.name,
        date: new Date(createdAt),
      }),
      value: _id,
    }))

  return (
    <SelectField
      {...props}
      isLoading={isLoading}
      options={shipmentOptions}
      onMenuScrollToBottom={() => {
        fetchNextPage()
      }}
    />
  )
}
