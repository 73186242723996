import { EntryFieldSetItem } from './interfaces'
import { useMemo } from 'react'
import { EntryField } from '../EntryField'
import { EntryFieldProps } from '../interfaces'

interface EntryFieldSetProps<I> extends Pick<EntryFieldProps, 'fileOptions' | 'documentOptions'> {
  items: I[]
}

export const NormalEntryFieldSet = ({
  items,
  ...entryFieldProps
}: EntryFieldSetProps<EntryFieldSetItem>) => {
  const itemsWithIndexes = useMemo(() => {
    return items.map<[EntryFieldSetItem, number]>((item, index) => [item, index])
  }, [items])

  return (
    <div className='space-y-6'>
      {itemsWithIndexes
        .filter(([entry]) => !entry.blockId)
        .map(([, index]) => (
          <EntryField {...entryFieldProps} key={`entry_${index}`} index={index} />
        ))}
    </div>
  )
}
