import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  SelectField,
  SelectFieldProps,
  SelectorOption,
  createSelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { useState } from 'react'
import { useInfiniteStakeholders, useStakeHolderById } from '../hooks'
import { StakeHolderType } from '../interfaces'

export const StakeHoldersSelectField = ({
  types,
  stakeHolderIdValue,
  ...props
}: Omit<SelectFieldProps, 'options'> & {
  types?: StakeHolderType[]
  stakeHolderIdValue?: string
}) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const {
    isLoading: isLoadingStakeholders,
    stakeholders,
    fetchNextPage,
  } = useInfiniteStakeholders({
    search: debouncedSearch,
    types,
  })

  const { stakeHolder: stakeHolderValue, isLoading: isLoadingStakeholderValue } =
    useStakeHolderById(stakeHolderIdValue || '', {
      enabled: !!stakeHolderIdValue,
    })

  const stakeholdersOptions: SelectorOption[] = stakeholders.map(stakeholder => ({
    label: stakeholder.company.name,
    value: stakeholder._id,
  }))

  const isLoading = isLoadingStakeholders || isLoadingStakeholderValue

  const value = stakeHolderValue
    ? createSelectorOption(stakeHolderValue.company.name, stakeHolderValue._id)
    : props.value

  return (
    <SelectField
      {...props}
      value={value}
      isLoading={isLoading}
      options={stakeholdersOptions}
      onInputChange={inputValue => {
        setSearch(inputValue)
      }}
      onMenuScrollToBottom={() => {
        fetchNextPage()
      }}
    />
  )
}
