import { Help } from '@/components/Help'
import {
  Button,
  ButtonWithIcon,
  Card,
  SelectField,
  SelectMultiField,
  createSelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { ArrowSmUpIcon } from '@heroicons/react/solid'
import { FormikErrors, useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { Automation, AutomationScheduleTrigger, TimeIncrement } from '../interfaces'
import { MouseEventHandler, useEffect, useMemo } from 'react'
import { useTemplatesForTasks } from '@/features/templates'

enum ScheduleType {
  WEEKDAY_MONDAY_TO_FRIDAY = 'weekday_monday_to_friday',
}

const ScheduleTypes = Object.values(ScheduleType)

export const RescheduleForm = ({
  onPreviousClick,
  isUpdate,
}: {
  onPreviousClick: MouseEventHandler<HTMLButtonElement>
  isUpdate?: boolean
}) => {
  const { t } = useTranslation('nsSetupAutomationStep')

  const { setTouched, handleSubmit } = useFormikContext<Pick<Automation, 'target' | 'trigger'>>()

  const { templates, isLoading: isTemplatesLoading } = useTemplatesForTasks()

  const [
    templateIdsTargetParamsField,
    templateIdsTargetParamsMeta,
    templateIdsTargetParamsHelpers,
  ] = useField('target.params.templateIds')

  const templateOptions = useMemo(() => {
    return templates.map(template => createSelectorOption(template.name, template._id))
  }, [templates])

  const templateValues = useMemo(() => {
    if (templateIdsTargetParamsField.value) {
      return templateIdsTargetParamsField.value.map((templateId: string) =>
        templateOptions.find(option => option.value === templateId)
      )
    }

    return undefined
  }, [templateOptions, templateIdsTargetParamsField.value])

  const [scheduleTypeField, scheduleTypeMeta, scheduleTypeHelpers] =
    useField('trigger.schedule.type')

  const scheduleTypeOptions = useMemo(() => {
    return ScheduleTypes.map(type =>
      createSelectorOption(t(`form.scheduleType.options.${type}`), type)
    )
  }, [t])

  // Set default value for Reschedule trigger
  useEffect(() => {
    scheduleTypeHelpers.setValue(ScheduleType.WEEKDAY_MONDAY_TO_FRIDAY)
    isExpiredTargetParamsHelpers.setValue(true)
    dueDateTargetParamsHelpers.setValue(TimeIncrement.TODAY)

    // Retrieve default value for Schedule trigger
    return () => {
      scheduleTypeHelpers.setValue(undefined)
      isExpiredTargetParamsHelpers.setValue(undefined)
      dueDateTargetParamsHelpers.setValue(TimeIncrement.INC_24H)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [dueDateTargetParamsField, dueDateTargetParamsMeta, dueDateTargetParamsHelpers] = useField(
    'target.params.dueDateIncrementType'
  )

  const dueDateOptions = useMemo(() => {
    return [createSelectorOption(t('form.dueDate.options.24h', TimeIncrement.TODAY))]
  }, [t])

  const [, , isExpiredTargetParamsHelpers] = useField('target.params.isExpired')

  const handleFinish = async () => {
    const errors = (await setTouched({
      trigger: {
        schedule: {
          type: true,
        },
      },
      target: {
        params: {
          dueDateIncrementType: true,
          templateIds: true,
          isExpired: true,
        },
      },
    })) as unknown as FormikErrors<Pick<Automation, 'target' | 'trigger'>>

    if (
      !errors?.target?.params?.dueDateIncrementType &&
      !errors?.target?.params?.templateIds &&
      !errors?.target?.params?.isExpired &&
      !(errors?.trigger as FormikErrors<AutomationScheduleTrigger>)?.schedule?.type
    ) {
      handleSubmit()
    }
  }

  return (
    <>
      <Card className='overflow-visible'>
        <Card.Body>
          <div className='space-y-6'>
            <div>
              <SelectMultiField
                id={templateIdsTargetParamsField.name.replaceAll('.', '_')}
                name={templateIdsTargetParamsField.name}
                label={t('form.templateId.label')}
                description={t('form.templateId.description')}
                value={templateValues}
                selectAll
                onChange={options =>
                  templateIdsTargetParamsHelpers.setValue(options.map(option => option.value))
                }
                selectAllText={t('nsCommon:selector.action')}
                options={templateOptions}
                isLoading={isTemplatesLoading}
                error={!!templateIdsTargetParamsMeta.error && templateIdsTargetParamsMeta.touched}
              />
            </div>
            <div className='grid grid-cols-2 gap-2'>
              <SelectField
                id={scheduleTypeField.name.replaceAll('.', '_')}
                {...scheduleTypeField}
                label={t('form.scheduleType.label')}
                description={t('form.scheduleType.description')}
                placeholder={t('form.scheduleType.placeholder')}
                menuPortalTarget={document.body}
                options={scheduleTypeOptions}
                defaultValue={scheduleTypeOptions[0]}
                value={
                  scheduleTypeField.value
                    ? scheduleTypeOptions.find(option => option.value === scheduleTypeField.value)
                    : null
                }
                onChange={option => {
                  scheduleTypeHelpers.setValue(option?.value)
                }}
                error={!!scheduleTypeMeta.error && scheduleTypeMeta.touched}
              />
              <SelectField
                id={dueDateTargetParamsField.name.replaceAll('.', '_')}
                {...dueDateTargetParamsField}
                label={t('form.dueDate.label')}
                description={t('form.dueDate.description')}
                placeholder={t('form.dueDate.placeholder')}
                menuPortalTarget={document.body}
                options={dueDateOptions}
                defaultValue={dueDateOptions[0]}
                value={
                  dueDateTargetParamsField.value
                    ? dueDateOptions.find(option => option.value === dueDateTargetParamsField.value)
                    : null
                }
                onChange={option => {
                  dueDateTargetParamsHelpers.setValue(option?.value)
                }}
                error={!!dueDateTargetParamsMeta.error && dueDateTargetParamsMeta.touched}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
      <div className='flex flex-wrap justify-between gap-4'>
        <Help analyticsProperties={{ Source: 'Automations creation' }} />
        <div className='flex items-center gap-4'>
          <ButtonWithIcon color='white' IconComponent={ArrowSmUpIcon} onClick={onPreviousClick}>
            {t('previousText')}
          </ButtonWithIcon>
          <Button onClick={handleFinish}>
            {t(isUpdate ? 'update.finishText' : 'create.finishText')}
          </Button>
        </div>
      </div>
    </>
  )
}
