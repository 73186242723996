import { DocumentFolder, DocumentType } from '@/features/documents'
import { DocumentListItemIcon } from '@/pages/documents/DocumentsView/components/DocumentsList/DocumentListItemIcon'
import { isEven, isOdd } from '@/utils/even-odd'
import { Breadcrumb, Loader, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import classNames from 'classnames'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { ROOT_FOLDER_ID } from '../../constants'
import { useDocumentFolderPicker } from './hooks'

export interface DocumentFolderPickerProps {
  /**
   * @default ROOT_FOLDER_ID
   */
  defaultFolderId?: string
  hideIds?: string[]
  onPick: (documentFolder: Pick<DocumentFolder, '_id' | 'title'>) => void
}

export const DocumentFolderPicker = memo(
  ({ defaultFolderId = ROOT_FOLDER_ID, hideIds, onPick }: DocumentFolderPickerProps) => {
    const { t } = useTranslation('nsDocumentFolderPicker')

    const { isLoading, documents, breadcrumb, handleFolderSelect, handleBreadcrumbClick } =
      useDocumentFolderPicker({ defaultFolderId, onPick, hideIds })

    return (
      <div className='mt-6 space-y-2'>
        {/* Breadcrumb */}
        <Breadcrumb
          pages={breadcrumb}
          onClick={handleBreadcrumbClick}
          className='border select-none'
        />

        {/* Documents list */}
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200 select-none'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      {t('table.name')}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={4}>
                        <Loader center='horizontal' className='my-3' size={SIZE.SMALL} />
                      </td>
                    </tr>
                  ) : documents.length === 0 ? (
                    <tr>
                      <td
                        className={classNames(
                          'px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center'
                        )}
                      >
                        {t('nsDocumentsViewPage:callToAction.title')}
                      </td>
                    </tr>
                  ) : (
                    documents.map(({ _id, title, mimeType, type }, index) => (
                      <tr
                        key={_id}
                        className={classNames('group', {
                          'bg-white': isEven(index),
                          'bg-gray-50': isOdd(index),
                          ' cursor-pointer hover:bg-gray-100': type === DocumentType.FOLDER,
                        })}
                        onDoubleClick={() =>
                          type === DocumentType.FOLDER ? handleFolderSelect(_id) : undefined
                        }
                      >
                        <td
                          className={classNames(
                            'px-6 py-4 whitespace-nowrap text-sm font-medium  flex flex-row items-center',
                            {
                              'text-gray-900': type === DocumentType.FOLDER,
                              'text-gray-300': type === DocumentType.FILE,
                            }
                          )}
                        >
                          <DocumentListItemIcon mimeType={mimeType} />
                          {title}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
)
