// Date config for dayjs
import DayJsPluginLocalizedFormat from 'dayjs/plugin/localizedFormat'
import DayJsPluginRelativeTime from 'dayjs/plugin/relativeTime'
import DayJsPluginCustomParseFormat from 'dayjs/plugin/customParseFormat'
import DayJsPluginIsToday from 'dayjs/plugin/isToday'
import DayJsPluginUTC from 'dayjs/plugin/utc'
import DayJsPluginTimezone from 'dayjs/plugin/timezone'
import DayJsPluginWeekOfYear from 'dayjs/plugin/weekOfYear'
import DayJsPluginWeekday from 'dayjs/plugin/weekday'
import DayJsPluginDuration from 'dayjs/plugin/duration'
import DayJsPluginIsoWeek from 'dayjs/plugin/isoWeek'
import dayjs from 'dayjs'

// Set dayjs plugins
dayjs.extend(DayJsPluginLocalizedFormat)
dayjs.extend(DayJsPluginRelativeTime)
dayjs.extend(DayJsPluginCustomParseFormat)
dayjs.extend(DayJsPluginIsToday)
dayjs.extend(DayJsPluginUTC)
dayjs.extend(DayJsPluginTimezone)
dayjs.extend(DayJsPluginWeekOfYear)
dayjs.extend(DayJsPluginWeekday)
dayjs.extend(DayJsPluginDuration)
dayjs.extend(DayJsPluginIsoWeek)
