import { useEffect } from 'react'
import { usePrevious } from './use-previous'

export const useOnChangeEvent = <T>({
  value,
  onChange,
}: {
  value: T
  onChange: (value: T) => void
}) => {
  const previousValues = usePrevious(value)

  useEffect(() => {
    if (previousValues !== value) {
      onChange(value)
    }
  }, [value, previousValues, onChange])
}
