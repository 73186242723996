/**
 * Check if a text is a valid http url
 *
 * @param text
 * @param withProtocol Must have a valid http protocol
 * @returns If the text provided is a valid http url
 */
export const isValidHttpUrl = (text: string): boolean => {
  const pattern = new RegExp(
    `^(https?:\\/\\/)` + // protocol
      `((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,})` + // domain name
      `(\\:\\d+)?(\\/[-a-z\\d%_.=,!-@~+]*)*` + // port and path
      `(\\?[;&a-z\\d%_.,~@+=-]*)?` + // query string
      `(\\#[-a-z\\d_]*)?$`, // fragment locator
    'i'
  )
  return !!pattern.test(text)
}
