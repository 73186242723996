import { useTranslation } from 'react-i18next'
import {
  TemplateEntryReferenceType,
  TemplateEntryReferenceValue,
  TemplateEntryType,
} from '@/features/templates'
import { EntryFieldProps } from '../interfaces'
import { useEntryFieldAttributes } from '../utils'
import { useField } from 'formik'
import { EntryCreateInput } from '@/features/entries'
import { EntryLabel } from '../EntryLabel'
import { StakeHoldersSelectField } from '@/features/stakeHolders'
import { ProductsSelectField } from '@/features/products'
import { TruSelectField } from '@/features/trus'

export const ReferenceEntryField = ({ index, prefixName, disabled }: EntryFieldProps) => {
  const { t } = useTranslation('nsEntries')

  const { entryFieldName, valuesFieldId, valuesFieldName, enabledFieldId, enabledFieldName } =
    useEntryFieldAttributes(index, prefixName)

  const [{ value: entry }, { error, touched }] = useField<EntryCreateInput>(entryFieldName)
  const [, , enabledInputFieldHelpers] = useField<boolean>(enabledFieldName)
  const [, , valuesInputFieldHelpers] = useField<[TemplateEntryReferenceValue]>(valuesFieldName)

  const requiredError = !!error && touched

  if (entry.type !== TemplateEntryType.REFERENCE) return null

  const isEntryEnabled = entry.enabled ?? true

  const targetValue = entry.values[0]

  switch (targetValue.type) {
    case TemplateEntryReferenceType.STAKEHOLDER:
      return (
        <StakeHoldersSelectField
          id={valuesFieldId}
          key={valuesFieldId}
          name={valuesFieldName}
          label={
            <EntryLabel
              naCheckboxId={enabledFieldId}
              naCheckboxName={enabledFieldName}
              naUnchecked={isEntryEnabled}
              onNaChange={enabled => {
                enabledInputFieldHelpers.setValue(enabled)
                enabledInputFieldHelpers.setTouched(true)
              }}
              naCheckboxLabel={t('form.na')}
              mandatoryPrefixText={t('form.mandatory.indicator')}
              isMandatory={entry.mandatory}
              disabled={disabled}
            >
              {entry.name}
            </EntryLabel>
          }
          placeholder={t('form.placeholders.stakeholder')}
          menuPortalTarget={document.body}
          onChange={option => {
            valuesInputFieldHelpers.setValue([
              {
                ...targetValue,
                id: option?.value || '',
                name: option?.label || '',
              },
            ])
            valuesInputFieldHelpers.setTouched(true)
          }}
          stakeHolderIdValue={targetValue.id}
          error={requiredError}
          isClearable
          isDisabled={!isEntryEnabled || disabled}
        />
      )
    case TemplateEntryReferenceType.PRODUCT:
      return (
        <ProductsSelectField
          id={valuesFieldId}
          key={valuesFieldId}
          name={valuesFieldName}
          label={
            <EntryLabel
              naCheckboxId={enabledFieldId}
              naCheckboxName={enabledFieldName}
              naUnchecked={isEntryEnabled}
              onNaChange={enabled => {
                enabledInputFieldHelpers.setValue(enabled)
                enabledInputFieldHelpers.setTouched(true)
              }}
              naCheckboxLabel={t('form.na')}
              mandatoryPrefixText={t('form.mandatory.indicator')}
              isMandatory={entry.mandatory}
              disabled={disabled}
            >
              {entry.name}
            </EntryLabel>
          }
          placeholder={t('form.placeholders.product')}
          menuPortalTarget={document.body}
          onChange={option => {
            valuesInputFieldHelpers.setValue([
              {
                ...targetValue,
                id: option?.value || '',
                name: option?.label || '',
              },
            ])
            valuesInputFieldHelpers.setTouched(true)
          }}
          productIdValue={targetValue.id}
          error={requiredError}
          isClearable
          isDisabled={!isEntryEnabled || disabled}
        />
      )
    case TemplateEntryReferenceType.TRU:
      return (
        <TruSelectField
          id={valuesFieldId}
          key={valuesFieldId}
          name={valuesFieldName}
          label={
            <EntryLabel
              naCheckboxId={enabledFieldId}
              naCheckboxName={enabledFieldName}
              naUnchecked={isEntryEnabled}
              onNaChange={enabled => {
                enabledInputFieldHelpers.setValue(enabled)
                enabledInputFieldHelpers.setTouched(true)
              }}
              naCheckboxLabel={t('form.na')}
              mandatoryPrefixText={t('form.mandatory.indicator')}
              isMandatory={entry.mandatory}
              disabled={disabled}
            >
              {entry.name}
            </EntryLabel>
          }
          placeholder={t('form.placeholders.batch')}
          menuPortalTarget={document.body}
          onChange={option => {
            valuesInputFieldHelpers.setValue([
              {
                ...targetValue,
                id: option?.value || '',
                name: option?.label || '',
              },
            ])
            valuesInputFieldHelpers.setTouched(true)
          }}
          truIdValue={targetValue.id}
          error={requiredError}
          isClearable
          isDisabled={!isEntryEnabled || disabled}
        />
      )
  }
}
