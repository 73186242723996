import { noop } from '@/utils/noop'
import { Badge, Button, COLOR, SIZE, Table } from '@blockchain-traceability-sl/tailwind-components'
import { memo, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Big } from 'big.js'
import {
  DEFAULT_PAGE_SIZE,
  PDF_MANUAL_EN_URL,
  PDF_MANUAL_ES_URL,
  TABLE_SIZE_PRESETS,
} from '@/app/constants'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { usePaginatedProducts } from '../hooks'
import { IProductFilters, Product } from '../interfaces'
import { useIsUserStockEnabled } from '@/features/auth'
import { useAnalytics } from '@/features/analytics'
import classNames from 'classnames'
import { useLocalStorage } from '@/hooks/use-local-storage'
import i18n from '@/i18n'

export interface ProductsTableProps {
  filters?: IProductFilters
  /**
   * Must be memoized to avoid extra renders
   */
  onRowManage?: (product: Product) => void
  /**
   * Must be memoized to avoid extra renders
   */
  onStockClick?: (product: Product) => void
}

/**
 * Page size key for local storage
 * @see useLocalStorage
 * @internal
 * @constant
 * @nanoid length 10
 */
const PAGE_SIZE_KEY = 'Ayr54cFVlC'

const ProductTableName = ({
  reference,
  name,
  metadata,
}: Pick<Product, 'reference' | 'name' | 'metadata'>) => {
  return (
    <div>
      <p
        className={classNames('text-sm leading-5 font-normal text-gray-500', {
          'mb-1': !!metadata && metadata.length !== 0,
        })}
      >
        {reference} - {name}
      </p>
      {metadata?.map((entry, index) => (
        <p key={`${index}-${entry.key}`} className='text-xs leading-4 font-normal text-gray-400'>
          <span className='font-semibold'>{entry.key}:</span> <span>{entry.value}</span>
        </p>
      ))}
    </div>
  )
}

const ProductTableStock = ({
  quantity,
  onStockClick,
}: Pick<Product, 'quantity'> & { onStockClick: () => void }) => {
  const { t } = useTranslation('nsProductsListPage')

  if (!quantity || new Big(quantity).lte(0)) {
    return (
      <Badge color={COLOR.RED} style={{ minWidth: 110 }}>
        {t('table.outOfStock')}
      </Badge>
    )
  } else {
    return (
      <Badge color={COLOR.GREEN} style={{ minWidth: 70 }} onClick={onStockClick}>
        <span>{t('table.inStock')}</span>
        <span className='ml-1 -mr-1'>
          <ChevronDownIcon className='h-3 w-3' />
        </span>
      </Badge>
    )
  }
}

export const ProductsTable = memo(
  ({ filters, onRowManage = noop, onStockClick = noop }: ProductsTableProps) => {
    const { t } = useTranslation('nsProductsListPage')
    const isUserStockEnabled = useIsUserStockEnabled()
    const analytics = useAnalytics()
    const [pageIndex, setPageIndex] = useState<number>(0)
    const [pageSize, setPageSize] = useLocalStorage(PAGE_SIZE_KEY, DEFAULT_PAGE_SIZE)

    const {
      products,
      totalCount,
      fetchNextPage,
      fetchPreviousPage,
      data,
      isLoading,
      isFetchingNextPage,
    } = usePaginatedProducts({ filters: { ...filters }, pageSize })

    const handlePageChange = useCallback(
      (page: { pageIndex: number; pageSize: number }) => {
        if (pageIndex < page.pageIndex) {
          fetchNextPage()
        } else if (pageIndex > page.pageIndex) {
          fetchPreviousPage()
        }
        setPageIndex(page.pageIndex)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [fetchNextPage, fetchPreviousPage]
    )

    const language = i18n.language

    const userManualUrl = useMemo(() => {
      if (language === 'es') {
        return PDF_MANUAL_ES_URL
      }
      return PDF_MANUAL_EN_URL
    }, [language])

    const rows = useMemo(
      () =>
        (data?.pages[pageIndex]?.items || []).map(product => ({
          ...product,
          id: product._id,
          name: <ProductTableName {...product} />,
          stockQuantity: `${t('number', {
            value: product.quantity,
          })} ${product.measurementUnit || ''}`,
          stock: <ProductTableStock {...product} onStockClick={() => onStockClick(product)} />,
          actions: (
            <Button
              color='link'
              onClick={isUserStockEnabled ? () => onRowManage(product) : undefined}
            >
              {t('table.action.cell')}
            </Button>
          ),
        })),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [data?.pages, isUserStockEnabled, pageIndex, t]
    )

    return (
      <Table
        manualPagination
        onPageChange={handlePageChange}
        onPageSizeChange={setPageSize}
        totalCount={totalCount}
        pageSize={pageSize}
        defaultPageIndex={pageIndex}
        className='onboarding-products-list'
        columns={[
          {
            Header: t('table.name').toString(),
            accessor: 'name',
          },
          ...(isUserStockEnabled
            ? [
                {
                  Header: t('table.stock').toString(),
                  accessor: 'stock',
                },
                {
                  Header: t('table.stockQuantity').toString(),
                  accessor: 'stockQuantity',
                },
              ]
            : []),
          {
            Header: t('table.totalTRUs').toString(),
            accessor: 'trus',
            disableGlobalFilter: true,
          },
          {
            Header: t('table.actions').toString(),
            accessor: 'actions',
            disableGlobalFilter: true,
          },
        ]}
        data={rows}
        isLoading={isLoading || isFetchingNextPage}
        onRowClick={
          isUserStockEnabled
            ? undefined
            : row => onRowManage(products.find(({ _id }) => _id === row.original.id) as Product)
        }
        previousText={t('pagination.previous')}
        nextText={t('pagination.next')}
        renderInfoText={(from, to, total) => (
          <Trans
            t={t}
            i18nKey='pagination.show'
            components={{ b: <span className='font-semibold' /> }}
            values={{ from, to, total }}
          />
        )}
        sizePresetsText={t('pagination.items')}
        showSizePresets
        sizePresets={TABLE_SIZE_PRESETS}
        noDataText={
          <tr>
            <td colSpan={isUserStockEnabled ? 5 : 3} className='text-center p-8 space-y-6'>
              <p className='text-4xl leading-10 font-extrabold text-center whitespace-pre-wrap'>
                {t('table.noData')}
              </p>
              <Button
                color='secondary'
                size={SIZE.EXTRA_LARGE}
                className='m-auto'
                onClick={() => {
                  analytics.track('ACTION_CLICK_HELP', {
                    Source: 'Products section',
                  })
                  window.open(userManualUrl, '_blank')
                }}
              >
                {t('table.needHelp')}
              </Button>
            </td>
          </tr>
        }
      />
    )
  }
)
