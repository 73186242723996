import { useMemo } from 'react'
import classNames from 'classnames'
import { Trans, useTranslation } from 'react-i18next'
import { CheckCircleIcon, ClockIcon, XCircleIcon } from '@heroicons/react/solid'
import { ValidationRequestStatus } from '@/features/validationRequests'
import { StakeHolder } from '@/features/stakeHolders'

interface ITaskDetails {
  assignedTo: string
  validation?: {
    status: ValidationRequestStatus
    validator?: string
  }
  associatedStakeHolders?: StakeHolder[]
}

export const TaskDetails = ({ assignedTo, validation, associatedStakeHolders }: ITaskDetails) => {
  const { t } = useTranslation('nsTasksListPage')
  const icon = useMemo(() => {
    if (validation?.status === ValidationRequestStatus.PENDING) {
      return <ClockIcon className='text-yellow-600 h-4 mr-1' />
    }

    if (validation?.status === ValidationRequestStatus.REJECTED) {
      return <XCircleIcon className='text-red-500 h-4 mr-1' />
    }

    if (validation?.status === ValidationRequestStatus.APPROVED) {
      return <CheckCircleIcon className='text-green-600 h-4 mr-1' />
    }

    return null
  }, [validation])

  return (
    <>
      <p className='text-xs'>
        <strong>{t('table.detail.assignedTo')}:</strong> {assignedTo}
      </p>

      {associatedStakeHolders && associatedStakeHolders.length > 0 && (
        <p className='text-xs mt-2 flex items-center gap-1'>
          <Trans
            t={t}
            i18nKey={`table.detail.stakeholder`}
            components={{
              b: <span className='font-bold' />,
            }}
          />
          <span>
            {associatedStakeHolders.map(stakeHolder => stakeHolder.company.name).join(', ')}
          </span>
        </p>
      )}

      {validation && (
        <p className='text-xs mt-2 flex items-center'>
          <div>{icon}</div>

          <span
            className={classNames([
              'italic',
              {
                'text-red-800': validation.status === ValidationRequestStatus.REJECTED,
              },
            ])}
          >
            <Trans
              t={t}
              i18nKey={`table.detail.validator.${validation.status}`}
              values={{ context: validation.validator }}
              components={{
                b: <span className='font-medium' />,
              }}
            />
          </span>
        </p>
      )}
    </>
  )
}
