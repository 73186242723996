import { InputField } from '@blockchain-traceability-sl/tailwind-components'
import { useTranslation } from 'react-i18next'

interface StakeholderHeaderProps {
  referenceValue?: string
  referenceError: boolean
  referenceErrorText?: string
  onReferenceChange: (value: string) => void

  onInputBlur: (event: React.FocusEvent<HTMLInputElement>) => void

  prefix?: string
}

export const StakeholderProductHeader = ({
  referenceValue,
  referenceError,
  referenceErrorText,
  onReferenceChange,

  onInputBlur,

  prefix,
}: StakeholderHeaderProps) => {
  const { t } = useTranslation('nsComplianceValidationPage')

  return (
    <div className='flex flex-col gap-6'>
      <InputField
        id={`${prefix}compliantStakeHolderProduct.reference`}
        name={`${prefix}compliantStakeHolderProduct.reference}`}
        onChange={event => {
          onReferenceChange(event.currentTarget.value)
        }}
        onBlur={onInputBlur}
        value={referenceValue}
        label={t('form.rawMaterial.label')}
        error={referenceError}
        errorText={referenceErrorText}
      />
    </div>
  )
}
