import { SortableContainer } from '@/components/SortableContainer'
import { noop } from '@/utils/noop'
import {
  Button,
  CheckboxField,
  CreatableMultiField,
  InputField,
  SIZE,
  SelectField,
  SelectorOption,
  TextAreaField,
} from '@blockchain-traceability-sl/tailwind-components'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { nanoid } from 'nanoid'
import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TemplateEntryError } from '../..'
import { useTemplateEntriesSelectorOptions } from '../../hooks'
import {
  EditableEntry,
  TemplateEntryReferenceType,
  TemplateEntryType,
  TemplateTableEntryColumn,
} from '../../interfaces'
import {
  getDefaultTemplateValue,
  isMandatoriableField,
  normalizeTemplateType,
  validateTemplateEntry,
} from '../../utils'
import { ConditionalEntryField } from '../ConditionalEntryField'
import { IssueReferenceHelpersOptions, useIssueReferenceHelpers } from '../hooks'
import { IssueReferenceEntryField } from '../IssueReferenceEntryField'
import { SortableEntry } from '../SortableEntry'
import { SortableEntryEditable } from '../SortableEntryEditable'
import { TableEntryField } from '../TableEntryField'
import { FileExpirationSelectionField } from '../FileExpirationSelectionField'
import { DocumentSettingsExpirationDuration } from '@/features/documents'
import { getEntryType } from '@/features/entries'

const generateNewEntry = () => ({
  id: nanoid(),
  editable: false,
  name: '',
  type: TemplateEntryType.SHORT_TEXT,
  mandatory: false,
  values: [],
})

interface BlockFieldsProps {
  collapsed?: boolean
  toggleCollapsed?: () => void
  title?: string
  entries: EditableEntry[]
  errors: { [id: string]: TemplateEntryError | undefined }
  onAddField: (newEntry: EditableEntry, entriesInNestedBlock?: EditableEntry[]) => void
  onRemoveField: (entry: EditableEntry) => void
  onChangeField: (
    entry: EditableEntry,
    nestedBlockOperation?: {
      entry: EditableEntry
      operation: 'add' | 'remove'
      valuesIndex?: number
    }
  ) => void
  onSortFields: (entries: EditableEntry[]) => void
  mandatoryEnabled?: boolean
  entriesInNestedBlock?: EditableEntry[]
  errorsInNestedBlock?: TemplateEntryError[]
  collapseDisabled?: boolean

  issueHelpersOptions?: Omit<IssueReferenceHelpersOptions, 'targetEntry'>

  tableEnabled?: boolean
  issueReferenceEnabled?: boolean
  statementEnabled?: boolean
  conditionalEnabled?: boolean
  documentEnabled?: boolean
  referenceEnabled?: boolean
  signatureEnabled?: boolean
  fileDocumentSettingsEnabled?: boolean
}

export const BlockFields = ({
  collapsed,
  title,
  entries: allEntries,
  errors,
  toggleCollapsed = noop,
  onAddField,
  onRemoveField,
  onChangeField,
  onSortFields,
  mandatoryEnabled = false,
  conditionalEnabled,
  tableEnabled,
  statementEnabled,
  documentEnabled = true,
  referenceEnabled = true,
  signatureEnabled,
  issueReferenceEnabled,
  fileDocumentSettingsEnabled,
  entriesInNestedBlock,
  errorsInNestedBlock,
  collapseDisabled,
  issueHelpersOptions,
}: BlockFieldsProps) => {
  const { t } = useTranslation('nsTemplate')
  const entryTypeOptions = useTemplateEntriesSelectorOptions({
    documentEnabled,
    fileEnabled: true,
    referenceEnabled,
    conditionalEnabled,
    tableEnabled,
    statementEnabled,
    issueReferenceEnabled,
    signatureEnabled,
  })

  const [touched, setTouched] = useState(
    () =>
      allEntries[allEntries.length - 1] && !validateTemplateEntry(allEntries[allEntries.length - 1])
  )

  const entries = touched ? allEntries.slice(0, allEntries.length - 1) : allEntries
  const newEntry = touched ? allEntries[allEntries.length - 1] : undefined

  const newEntriesInNestedBlock =
    entriesInNestedBlock && newEntry && newEntry.type === TemplateEntryType.CONDITIONAL
      ? entriesInNestedBlock.filter(entry => entry.blockId === newEntry.id)
      : []

  const isEditionEnabled = useMemo(() => entries.some(({ editable }) => editable), [entries])
  const hasEntriesErrors = entries.some(({ id }) => errors[id])

  const addFieldsDisabled = !(
    validateTemplateEntry(newEntry) &&
    newEntriesInNestedBlock
      .filter(entry => !entry.isNewEntry)
      .every(entry => validateTemplateEntry(entry))
  )

  const issueReferenceHelpers = useIssueReferenceHelpers({
    ...issueHelpersOptions,
    targetEntry: newEntry,
  })

  return (
    <div className='flex flex-col'>
      {title && (
        <div
          className={classNames('flex items-center text-sm leading-8 font-medium text-gray-600', {
            'cursor-pointer': !collapseDisabled,
          })}
          onClick={toggleCollapsed}
        >
          {title}
          {!collapseDisabled && (
            <>
              {!collapsed ? (
                <ChevronDownIcon className='h-6 w-6 ml-1' aria-hidden='true' />
              ) : (
                <ChevronRightIcon className='h-6 w-6 ml-1' aria-hidden='true' />
              )}
            </>
          )}
        </div>
      )}

      <p className='text-sm leading-5 font-normal text-gray-500'>
        <Trans
          t={t}
          i18nKey='entries.block.numberOfFields'
          values={{ count: allEntries.length }}
          components={{ strong: <span className='font-semibold' /> }}
        />
      </p>
      {!collapsed && (
        <>
          {entries.length > 0 && (
            <div className='my-4'>
              <SortableContainer
                items={entries}
                onSort={items => {
                  const newOrderedItems = [...items]
                  if (newEntry) {
                    newOrderedItems.push(newEntry)
                  }
                  onSortFields(newOrderedItems as EditableEntry[])
                }}
              >
                {entries.map(({ editable, ...entry }, index) =>
                  !editable ? (
                    <SortableEntry
                      key={entry.id}
                      {...entry}
                      className={classNames({ 'mt-4': index !== 0 })}
                      typeOptions={entryTypeOptions}
                      hasEntriesErrors={hasEntriesErrors}
                      sortable={!isEditionEnabled}
                      type={getEntryType(entry)}
                      onEntryEdit={() => {
                        if (hasEntriesErrors) return
                        onChangeField({
                          ...entry,
                          editable: !editable,
                        })
                      }}
                      onEntryDelete={() => {
                        onRemoveField(entry)
                      }}
                    />
                  ) : (
                    <SortableEntryEditable
                      key={entry.id}
                      {...entry}
                      className={classNames({ 'mt-4 mb-6': index !== 0 })}
                      typeOptions={entryTypeOptions}
                      hasKeysErrors={hasEntriesErrors}
                      hasNameError={!!errors[entry.id]?.name}
                      hasTypeError={!!errors[entry.id]?.type}
                      hasSelectorError={!!errors[entry.id]?.values}
                      type={getEntryType(entry)}
                      onKeyEditEnd={() => {
                        if (hasEntriesErrors) return
                        onChangeField({
                          ...entry,
                          editable: !editable,
                        })
                      }}
                      onKeyDelete={() => {
                        onRemoveField(entry)
                      }}
                      onNameChange={value => {
                        onChangeField({
                          ...entry,
                          name: value,
                        })
                      }}
                      onTypeChange={value => {
                        const type = value as TemplateEntryType | TemplateEntryReferenceType
                        onChangeField({
                          ...entry,
                          type: normalizeTemplateType(type),
                          mandatory: false,
                          values: getDefaultTemplateValue(type),
                        })
                      }}
                      onSelectorChange={values => {
                        onChangeField({
                          ...entry,
                          values,
                        })
                      }}
                      onContentChange={value => {
                        onChangeField({
                          ...entry,
                          values: [value],
                        })
                      }}
                      onContentTableChange={values => {
                        onChangeField({
                          ...entry,
                          values,
                        })
                      }}
                      onMandatoryChange={value => {
                        onChangeField({
                          ...entry,
                          mandatory: value,
                        })
                      }}
                      onDocumentSettingsExpirationChange={value => {
                        onChangeField({
                          ...entry,
                          documentSettings: {
                            ...entry.documentSettings,
                            expirationDuration: value as DocumentSettingsExpirationDuration,
                          },
                        })
                      }}
                      conditionalOptions={{
                        nested: true,
                        entries: entriesInNestedBlock || entries,
                        entriesErrors: errorsInNestedBlock,
                        onAddEntryInBlock: (blockEntry, entryToAdd) => {
                          onChangeField(blockEntry, {
                            entry: entryToAdd,
                            operation: 'add',
                          })
                        },
                        onRemoveEntryInBlock: (blockEntry, entryToRemove) => {
                          onChangeField(blockEntry, {
                            entry: entryToRemove,
                            operation: 'remove',
                          })
                        },
                        onChangeEntryInBlock: entryToChange => {
                          onChangeField(entryToChange)
                        },
                        onChange: entryToChange => {
                          onChangeField(entryToChange)
                        },
                      }}
                      mandatoryEnabled={mandatoryEnabled}
                      documentEnabled
                      referenceEnabled
                      signatureEnabled={signatureEnabled}
                      fileDocumentSettingsEnabled={fileDocumentSettingsEnabled}
                      issueReferenceOptions={{
                        nested: true,
                        helpersOptions: issueHelpersOptions,
                      }}
                    />
                  )
                )}
              </SortableContainer>
            </div>
          )}

          {newEntry && (
            <>
              <div className='grid gap-6 mt-4'>
                <div className='grid md:grid-cols-3 gap-6 md:gap-4'>
                  <div className='md:col-span-2'>
                    <InputField
                      key={`entry.name.${newEntry.id}`}
                      id={`entry_name_${newEntry.id}`}
                      name={`entry_name_${newEntry.id}`}
                      onChange={event => {
                        onChangeField({
                          ...newEntry,
                          name: event.target.value,
                        })
                      }}
                      autoFocus
                      value={newEntry.name}
                      label={t('entries.name.label')}
                      description={t('entries.name.description')}
                    />
                    {mandatoryEnabled &&
                      isMandatoriableField(newEntry.type) &&
                      newEntry.type !== TemplateEntryType.SINGLE_CHOICE &&
                      newEntry.type !== TemplateEntryType.MULTIPLE_CHOICE && (
                        <div className='mt-4'>
                          <CheckboxField
                            key={`entry.mandatory.${newEntry.id}`}
                            id={`entry_mandatory_${newEntry.id}`}
                            name={`entry.mandatory.${newEntry.id}`}
                            label={t('entries.checkbox.mandatory.label')}
                            checked={newEntry.mandatory}
                            onChange={event =>
                              onChangeField({
                                ...newEntry,
                                mandatory: event.currentTarget.checked,
                              })
                            }
                          />
                        </div>
                      )}
                  </div>
                  <div className='md:col-span-1'>
                    <SelectField
                      key={`entry_type_${newEntry.id}`}
                      id={`entry_type_${newEntry.id}`}
                      name={`entry.type.${newEntry.id}`}
                      options={entryTypeOptions}
                      menuSize={SIZE.SMALL}
                      value={entryTypeOptions
                        .flatMap(group => group.options)
                        .find(selection => selection.value === newEntry.type)}
                      onChange={option => {
                        if (!option) return
                        const type = option.value as TemplateEntryType | TemplateEntryReferenceType
                        onChangeField({
                          ...newEntry,
                          type: normalizeTemplateType(type),
                          values: getDefaultTemplateValue(type),
                        })
                      }}
                      label={t('entries.type.label')}
                      description={t('entries.type.description')}
                    />
                    {newEntry.type === TemplateEntryType.FILE && fileDocumentSettingsEnabled && (
                      <div className='mt-4'>
                        <FileExpirationSelectionField
                          key={`entry_document_settings_expiration_${newEntry.id}`}
                          id={`entry_document_settings_expiration_${newEntry.id}`}
                          name={`entry.documentSettings.expirationDuration.${newEntry.id}`}
                          value={newEntry.documentSettings?.expirationDuration}
                          onChange={option =>
                            onChangeField({
                              ...newEntry,
                              documentSettings: {
                                ...newEntry.documentSettings,
                                expirationDuration:
                                  option?.value as DocumentSettingsExpirationDuration,
                              },
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  {(newEntry.type === TemplateEntryType.SINGLE_CHOICE ||
                    newEntry.type === TemplateEntryType.MULTIPLE_CHOICE) && (
                    <>
                      <div className='flex-grow sm:self-end'>
                        <CreatableMultiField
                          key={`entry.values.${newEntry.id}`}
                          id={`entry_values_${newEntry.id}`}
                          name={`entry.values.${newEntry.id}`}
                          value={((newEntry as { values?: string[] }).values || []).map(
                            (value): SelectorOption => ({ label: value, value })
                          )}
                          onChange={options => {
                            onChangeField({
                              ...newEntry,
                              values: options.map(({ value }) => value),
                            })
                          }}
                          label={t('entries.selector.label')}
                        />
                      </div>
                      <div className='my-4'>
                        <CheckboxField
                          key={`entry.mandatory.${newEntry.id}`}
                          id={`entry_mandatory_${newEntry.id}`}
                          name={`entry.mandatory.${newEntry.id}`}
                          label={t('entries.checkbox.mandatory.label')}
                          checked={newEntry.mandatory}
                          onChange={event =>
                            onChangeField({
                              ...newEntry,
                              mandatory: event.currentTarget.checked,
                            })
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>

              {newEntry.type === TemplateEntryType.STATEMENT && (
                <div className='mt-4'>
                  <TextAreaField
                    key={`entry.values.${newEntry.id}`}
                    id={`key_values_${newEntry.id}`}
                    name={`entry.values.${newEntry.id}`}
                    value={(newEntry.values[0] as string) || ''}
                    onChange={event =>
                      onChangeField({
                        ...newEntry,
                        values: [event.currentTarget.value],
                      })
                    }
                    label={t('entries.content.label')}
                    description={t('entries.content.description')}
                  />
                </div>
              )}

              {conditionalEnabled &&
                newEntry.type === TemplateEntryType.CONDITIONAL &&
                entriesInNestedBlock && (
                  <div className='my-4'>
                    <ConditionalEntryField
                      nested
                      targetEntry={newEntry}
                      entries={entriesInNestedBlock}
                      entriesErrors={errorsInNestedBlock}
                      onAddEntryInBlock={(blockEntry, entryToAdd, valuesIndex) => {
                        onChangeField(
                          {
                            ...newEntry,
                            ...blockEntry,
                          },
                          {
                            entry: entryToAdd,
                            operation: 'add',
                            valuesIndex,
                          }
                        )
                      }}
                      onRemoveEntryInBlock={(blockEntry, entryToRemove) => {
                        onChangeField(
                          {
                            ...newEntry,
                            ...blockEntry,
                          },
                          {
                            entry: entryToRemove,
                            operation: 'remove',
                          }
                        )
                      }}
                      onChangeEntryInBlock={entryToChange => {
                        onChangeField(entryToChange)
                      }}
                      onChange={entry => {
                        onChangeField({
                          ...newEntry,
                          ...entry,
                        })
                      }}
                      documentEnabled={documentEnabled}
                      referenceEnabled={referenceEnabled}
                      signatureEnabled={signatureEnabled}
                      fileDocumentSettingsEnabled={fileDocumentSettingsEnabled}
                    />
                  </div>
                )}

              {newEntry.type === TemplateEntryType.TABLE && (
                <div className='mt-4'>
                  <TableEntryField
                    key={newEntry.id}
                    onChange={(columns: TemplateTableEntryColumn[]): void => {
                      onChangeField({
                        ...newEntry,
                        values: columns,
                      })
                    }}
                    referenceEnabled
                    documentEnabled
                    signatureEnabled={signatureEnabled}
                  />
                </div>
              )}

              {issueReferenceEnabled && newEntry.type === TemplateEntryType.ISSUE_REFERENCE && (
                <div className='my-4'>
                  <IssueReferenceEntryField nested {...issueReferenceHelpers} />
                </div>
              )}
            </>
          )}

          <div className='mt-2'>
            <Button
              color='secondary'
              onClick={() => {
                onAddField(generateNewEntry())
                setTouched(true)
              }}
              disabled={addFieldsDisabled}
            >
              {t('entries.block.addField', { count: entries.length })}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
