import { UniqueIdentifier, useDroppable } from '@dnd-kit/core'
import classNames from 'classnames'
import { ReactNode } from 'react'

interface CalendarColumnProps {
  children: ReactNode
  id: UniqueIdentifier
  endOfWeek?: boolean
}

export const CalendarColumn = ({ children, id, endOfWeek }: CalendarColumnProps) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
  })

  return (
    <div
      ref={setNodeRef}
      className={classNames(' border-gray-100 grid-col-4 bg-gray-50 min-h-148', {
        'border-r': !endOfWeek,
        'bg-gray-100': isOver,
      })}
      aria-label='Droppable region'
    >
      {children}
    </div>
  )
}
