import { Route, Router, Switch } from 'react-router-dom'
import { NotificationContainer } from '@/features/notify'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { PrivateRoute } from '@/routes/PrivateRoute'
import { Suspense } from 'react'
import { rootRoutes } from '@/routes/routes'
import { appHistory } from '@/routes/router'
import { ErrorBoundary } from './ErrorBoundary'
import { queryClient } from './query'
import { FeatureFlagsProvider } from '@/features/flags'
import { Onboarding } from '@/features/onboarding'
import { LayoutProvider } from '@/features/layout'
import { AuthenticationProvider } from '@/features/auth'
import { Helmet } from 'react-helmet'

export interface AppProps {
  accessURL: string
}
export const App = ({ accessURL }: AppProps) => {
  return (
    <ErrorBoundary>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Helmet>

      <QueryClientProvider client={queryClient}>
        <FeatureFlagsProvider>
          <ReactQueryDevtools />
          <AuthenticationProvider>
            <Onboarding>
              <LayoutProvider>
                <Router history={appHistory}>
                  <Suspense fallback={null}>
                    <Switch>
                      {rootRoutes.map(route =>
                        route.private ? (
                          <PrivateRoute
                            key={route.path}
                            path={route.path}
                            component={route.component}
                            redirectPath={accessURL}
                          />
                        ) : (
                          <Route key={route.path} path={route.path} component={route.component} />
                        )
                      )}
                    </Switch>
                  </Suspense>
                </Router>
              </LayoutProvider>
            </Onboarding>
            <NotificationContainer />
          </AuthenticationProvider>
        </FeatureFlagsProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  )
}
