import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DEFAULT_PAGE_SIZE, PDF_MANUAL_EN_URL, PDF_MANUAL_ES_URL } from '@/app/constants'
import {
  TaskFilters,
  TaskTable,
  TaskTableMode,
  usePaginatedTasks,
  useTaskManager,
} from '@/features/tasks'
import {
  ValidationRequestStatus,
  useValidationsRequestByEntityIds,
} from '@/features/validationRequests'
import { CallToAction, Loader } from '@blockchain-traceability-sl/tailwind-components'
import { useAnalytics } from '@/features/analytics'
import { usePagination } from '@/hooks/use-pagination'
import { useUserId, useUserPermissions } from '@/features/auth'
import { useAssociatedStakeholdersByTasks, useFormatTaskToTab } from '../hooks'
import { PAGE_SIZE_KEY } from '../constants'
import { useLocalStorage } from '@/hooks/use-local-storage'
import i18n from '@/i18n'

interface ValidationTasksTabProps {
  taskFilters: TaskFilters
  isFiltersActive?: boolean
  leftMenu?: ReactNode
}

export const ValidationTasksTab = ({
  taskFilters,
  isFiltersActive,
  leftMenu,
}: ValidationTasksTabProps) => {
  const { t } = useTranslation('nsTasksListPage')
  const history = useHistory()
  const analytics = useAnalytics()

  const userId = useUserId()
  const userPermissions = useUserPermissions()

  const [pageSize, setPageSize] = useLocalStorage(PAGE_SIZE_KEY, DEFAULT_PAGE_SIZE)
  const { onPageChange, pageIndex, pageOffset } = usePagination({ pageSize })

  const {
    isLoading: isTasksLoading,
    isFetching: isTasksFetching,
    isFetched: isTasksFetched,
    totalCount,
    tasks,
  } = usePaginatedTasks(
    {
      ...taskFilters,
      validationStatus: [ValidationRequestStatus.PENDING],
      validationReviewerId: [userId],
    },
    { offset: pageOffset, limit: pageSize }
  )

  const taskIds = useMemo(() => tasks.map(task => task._id), [tasks])

  const { validationRequests } = useValidationsRequestByEntityIds(taskIds, {
    enabled: isTasksFetched && taskIds.length > 0,
  })

  const { associatedStakeholders } = useAssociatedStakeholdersByTasks(taskIds, {
    enabled: isTasksFetched && taskIds.length > 0,
  })

  const { formatTaskToTab } = useFormatTaskToTab({ validationRequests, associatedStakeholders })

  const { reviewTask, reviewTaskBulk } = useTaskManager()

  const tasksToTable = useMemo(() => tasks.map(formatTaskToTab), [tasks, formatTaskToTab])

  const isTableLoading = isTasksFetching && !isTasksFetched
  const language = i18n.language

  const userManualUrl = useMemo(() => {
    if (language === 'es') {
      return PDF_MANUAL_ES_URL
    }
    return PDF_MANUAL_EN_URL
  }, [language])

  return (
    <>
      {isTasksLoading ? (
        <div className='h-80'>
          <Loader center='full' />
        </div>
      ) : totalCount === 0 && !isFiltersActive ? (
        <CallToAction
          title={t('tabValidationRequest.callToAction.title')}
          subtitle={t('tabValidationRequest.callToAction.subtitle')}
          primaryActionText={t('tabValidationRequest.callToAction.action')}
          primaryActionDisabled={!userPermissions.tasks.write}
          onPrimaryActionClick={() => {
            analytics.track('ACTION_CLICK_CREATE_TASK', {
              From: 'CallToAction',
            })
            history.push('/tasks/create')
          }}
          secondaryActionText={t('tabValidationRequest.callToAction.needHelp')}
          onSecondaryActionClick={() => {
            analytics.track('ACTION_CLICK_HELP', { Source: 'Tasks section' })
            window.open(userManualUrl, '_blank')
          }}
        />
      ) : (
        <TaskTable
          mode={TaskTableMode.REVIEW}
          isLoading={isTableLoading}
          tasks={tasksToTable}
          onRowClick={reviewTask}
          onPageChange={page => {
            onPageChange(page)
            setPageSize(page.pageSize)
          }}
          pagination={{ pageIndex, pageSize }}
          totalCount={totalCount}
          leftMenu={leftMenu}
          bulkActionsEnabled
          onReviewTaskBulk={reviewTaskBulk}
        />
      )}
    </>
  )
}
