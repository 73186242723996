import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  InlineSelectField,
  InlineSelectFieldProps,
  SelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { useMemo, useState } from 'react'
import { useInfiniteTemplates } from '../hooks'
import { TemplateType } from '../interfaces'

export const TemplatesInlineSelectField = <isMulti extends boolean>({
  all,
  types,
  ...props
}: Omit<InlineSelectFieldProps<isMulti>, 'options'> & {
  all?: { label: string; value: string }
  types?: TemplateType[]
}) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const { templates, fetchNextPage } = useInfiniteTemplates({
    search: debouncedSearch,
    types,
  })

  const templatesOptions: SelectorOption[] = useMemo(() => {
    const options = templates.map(template => ({
      label: template.name,
      value: template._id,
    }))
    return all?.label && all?.value ? [{ label: all.label, value: all.value }, ...options] : options
  }, [templates, all?.label, all?.value])

  return (
    <InlineSelectField
      {...props}
      searchable
      options={templatesOptions}
      onSearch={inputValue => {
        setSearch(inputValue)
      }}
      onMenuScrollToBottom={() => {
        fetchNextPage()
      }}
    />
  )
}
