import { EntryFieldProps } from '../interfaces'
import { EntryLabel } from '../EntryLabel'
import { useEntryFieldAttributes } from '../utils'
import { EntryCreateInput } from '../../interfaces'
import { useTranslation } from 'react-i18next'
import { TemplateEntryType } from '@/features/templates'
import { SignInputField } from '@/features/signatures'
import { useField } from 'formik'

export const SignatureEntryField = ({ index, prefixName, disabled }: EntryFieldProps) => {
  const { t } = useTranslation('nsEntries')

  const { entryFieldName, valuesFieldName, enabledFieldName, enabledFieldId, valuesFieldId } =
    useEntryFieldAttributes(index, prefixName)

  const [{ value: entry }, { error, touched }] = useField<EntryCreateInput>(entryFieldName)
  const [, , enabledInputFieldHelpers] = useField<boolean>(enabledFieldName)
  const [, , valuesInputFieldHelpers] = useField<string[]>(valuesFieldName)

  const requiredError = !!error && touched

  if (entry.type !== TemplateEntryType.SIGNATURE) return null

  const isEntryEnabled = entry.enabled ?? true

  return (
    <SignInputField
      id={valuesFieldId}
      label={
        <EntryLabel
          naCheckboxId={enabledFieldId}
          naCheckboxName={enabledFieldName}
          naUnchecked={isEntryEnabled}
          onNaChange={enabled => {
            enabledInputFieldHelpers.setValue(enabled)
            enabledInputFieldHelpers.setTouched(true)
          }}
          naCheckboxLabel={t('form.na')}
          mandatoryPrefixText={t('form.mandatory.indicator')}
          isMandatory={entry.mandatory}
          disabled={disabled}
        >
          {entry.name}
        </EntryLabel>
      }
      onChange={signatures => {
        valuesInputFieldHelpers.setValue(signatures)

        setImmediate(() => {
          valuesInputFieldHelpers.setTouched(true)
          valuesInputFieldHelpers.setError(undefined)
        })
      }}
      values={entry.values}
      error={requiredError}
      disabled={!isEntryEnabled || disabled}
      required={entry.mandatory}
    />
  )
}
