export enum CustomReportTemplateType {
  COMPLIANCE_REPORT = 'compliance_report',
}

export enum CustomReportTemplateSourceType {
  COMPLIANCE = 'compliance',
  TASK = 'task',
}

export interface CustomReportTemplateSourceReference {
  type: CustomReportTemplateSourceType
  templateId: string
}

export interface CustomReportTemplateSource {
  templateId: string
  type: CustomReportTemplateSourceType
  references?: CustomReportTemplateSourceReference[]
}

export interface CustomReportTemplate {
  readonly _id: string
  readonly companyId: string
  name: string

  htmlTemplate: string

  type: CustomReportTemplateType

  source: CustomReportTemplateSource

  createdAt: string
  updatedAt: string
}

export interface CustomReportSendByEmailRequest {
  to: string[]
  bcc?: string[]
  subject: string
  content: string
  contentRichText: string
  metadata: {
    companyName: string
    userEmail: string
  }
  attachments: { fileName: string; path: string }[]
}

export interface StockReportInput {
  reportProducts: {
    ids: string[]
    all: boolean
  }
  reportStakeHolderProducts: {
    ids: string[]
    all: boolean
  }
}
