import {
  Button,
  COLOR,
  IconCircle,
  Modal,
  SIZE,
} from '@blockchain-traceability-sl/tailwind-components'
import { CreditCardIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'

export interface ModalPaymentChooserProps {
  show: boolean

  onPayNow: () => void

  onPayByInvoice: () => void
}

export const ModalPaymentChooser = ({
  show,
  onPayNow,
  onPayByInvoice,
}: ModalPaymentChooserProps) => {
  const { t } = useTranslation('nsRegisterPage')
  return (
    <Modal role='alert' show={show} dismissOnBlur={false} size={SIZE.EXTRA_SMALL}>
      <Modal.Title className='whitespace-pre-wrap flex flex-col space-y-4'>
        <IconCircle color={COLOR.GREEN} IconComponent={CreditCardIcon} className='mx-auto' />
        <div> {t('notification.create.title')}</div>
      </Modal.Title>
      <Modal.Body>
        <p className='text-center text-sm text-gray-500 whitespace-pre-wrap'>
          {t('notification.create.description')}
        </p>
      </Modal.Body>

      <div className='flex items-center gap-4 px-6 -mt-8 pb-8 pt-6 bg-white'>
        <Button type='button' onClick={onPayNow}>
          {t('notification.create.action.now')}
        </Button>
        <Button type='button' className='flex-grow' onClick={onPayByInvoice} color='secondary'>
          {t('notification.create.action.invoice')}
        </Button>
      </div>
    </Modal>
  )
}
