import { TRUCreationOrUpdateOriginInput } from '../../interfaces'
import { Trans, useTranslation } from 'react-i18next'
import { useIsUserStockEnabled } from '@/features/auth'
import { Loader, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { AvailableTruOriginsTableRow } from './AvailableTruOriginsTableRow'
import { AvailableTruOriginsEmpty } from './AvailableTruOriginsEmpty'
import {
  OriginType,
  useAvailableOriginsByProduct,
  useAvailableOriginsByTru,
} from '@/features/origins'
import { Link } from 'react-router-dom'
import { useReferrer } from '@/hooks/use-referrer'

interface AvailableTruOriginsTableProps {
  // Mandatory to know the possible product origins
  productId: string
  // To know available origins, this property only works in updates. To know available origins in create use productId
  truId?: string
  // Current origins selected from form context
  origins: TRUCreationOrUpdateOriginInput[]
  // Origin manage handlers
  onAddOrigin: (origin: TRUCreationOrUpdateOriginInput) => void
}

export const AvailableTruOriginsTable = ({
  productId,
  truId,
  origins,
  onAddOrigin,
}: AvailableTruOriginsTableProps) => {
  const { t } = useTranslation('nsTRUCreationManualPage')
  const isUserStockEnabled = useIsUserStockEnabled()
  const { generateReferrerUrl } = useReferrer()

  const {
    availableOrigins: availableOriginsByProduct,
    isLoading: isLoadingAvailableOriginsByProduct,
  } = useAvailableOriginsByProduct({
    productId,
    filters: {},
    enabled: !truId,
  })

  const { availableOrigins: availableOriginsByTru, isLoading: isLoadingAvailableOriginsByTru } =
    useAvailableOriginsByTru({
      truId: truId || '',
      filters: {},
      enabled: !!truId,
    })

  const availableOrigins = truId ? availableOriginsByTru : availableOriginsByProduct

  const colSpan = isUserStockEnabled ? 6 : 4

  const isLoading = isLoadingAvailableOriginsByProduct || isLoadingAvailableOriginsByTru

  const isEmpty = !isLoading && availableOrigins.length === 0

  // Extract non repeated product list from available origins
  const productOrigins: string[] = availableOrigins.reduce((accumulator, origin) => {
    let productId: string | undefined

    // Extract product id from origin
    if (origin.originType === OriginType.TRU) {
      productId = origin.productId
    } else if (origin.originType === OriginType.RECEPTION) {
      productId = origin.stakeHolderProductId
    }

    if (!productId) return accumulator

    // Check if product is already in the list
    const productExists = accumulator.some(id => id === productId)

    // If not, add it
    if (!productExists) accumulator.push(productId)

    return accumulator
  }, [] as string[])

  if (isEmpty) {
    return <AvailableTruOriginsEmpty productId={productId} />
  }

  return (
    <div className='space-y-4'>
      <div className='shadow border-b border-gray-200 sm:rounded-lg'>
        <table className='w-full divide-y divide-gray-200 table-auto'>
          <thead className='bg-gray-50'>
            <tr>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                {t('form.origins.table.product')}
              </th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                {t('form.origins.table.stakeHolder')}
              </th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                {t('form.origins.table.receipt')}
              </th>
              {isUserStockEnabled && (
                <>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    {t('form.origins.table.quantity')}
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    {t('form.origins.table.stock')}
                  </th>
                </>
              )}
              <th />
            </tr>
          </thead>

          {isLoading && (
            <tr className='h-52'>
              <td colSpan={colSpan}>
                <Loader center='full' size={SIZE.SMALL} />
              </td>
            </tr>
          )}

          <tbody>
            {productOrigins.map(productOrigin => (
              <AvailableTruOriginsTableRow
                key={productOrigin}
                productId={productId}
                truId={truId}
                productOriginId={productOrigin}
                origins={origins}
                onAddOrigin={onAddOrigin}
              />
            ))}
          </tbody>
        </table>
      </div>

      <p className='text-gray-500 text-sm font-normal leading-tight'>
        <Trans
          i18nKey='table.help'
          t={t}
          components={{
            a: (
              <Link
                to={generateReferrerUrl({
                  url: `/products/${productId}/edit`,
                  fieldName: null,
                })}
                className='underline'
              />
            ),
          }}
        />
      </p>
    </div>
  )
}
