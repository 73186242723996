import { DateLocaleField } from '@/components/DateLocaleField'
import { Help } from '@/components/Help'
import { StepOverview } from '@/components/StepOverview'
import { useCompanyUser, useUserId, useUserPermissions } from '@/features/auth'
import {
  EntryCreateInput,
  getDefaultEntryFromTemplate,
  prepareEntryBlocksMapsFromTemplateEntries,
} from '@/features/entries'
import { getProductNameWithReference, useProducts } from '@/features/products'
import { StakeHolderType, useStakeHoldersByTypes } from '@/features/stakeHolders'
import { TemplateType, useTemplatesForTasks } from '@/features/templates'
import { useCreateTRU, useQueryTRUsByProduct } from '@/features/trus'
import { useUserById } from '@/features/users'
import { useReferrer } from '@/hooks/use-referrer'
import { useToggle } from '@/hooks/use-toggle'
import {
  AlertConfirm,
  ButtonWithIcon,
  Card,
  CreatableSelectField,
  SelectField,
  SelectorOption,
  Step,
  Tooltip,
  createSelectorOption,
  useStep,
} from '@blockchain-traceability-sl/tailwind-components'
import { ArrowSmDownIcon, InformationCircleIcon } from '@heroicons/react/solid'
import dayjs from 'dayjs'
import { FormikErrors, useFormikContext } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { TaskCreation } from '../../interfaces'

type FormValues = Pick<TaskCreation, 'formId' | 'dueDate' | 'stakeHolderId' | 'productId' | 'truId'>

export const StepTaskCreationSetup = ({
  isCompletingTask,
  currentTemplateType,
  setCurrentTemplateType,
}: {
  isCompletingTask: boolean
  currentTemplateType: TemplateType
  setCurrentTemplateType: (type: TemplateType) => void
}) => {
  const { t } = useTranslation('nsTaskCreationPage')
  const userPermissions = useUserPermissions()
  const userId = useUserId()
  const companyUser = useCompanyUser()

  const { goNext } = useStep()

  const [truCreationConfirmShow, toggleTRUCreationConfirmShow] = useToggle(false)

  const { createTRU } = useCreateTRU()

  const { generateReferrerUrl } = useReferrer()

  const { setTouched, handleBlur, setFieldValue, values, errors, touched } =
    useFormikContext<FormValues>()

  const { templates, isLoading: isTemplatesLoading } = useTemplatesForTasks()
  const { stakeHolders, isLoading: isStakeHoldersLoading } = useStakeHoldersByTypes([
    StakeHolderType.CLIENT,
    StakeHolderType.BOTH,
  ])
  const { products } = useProducts()
  const { trus: trusByProduct } = useQueryTRUsByProduct(values.productId || '', {
    enabled: !!values.productId,
  })

  const templateOptions: SelectorOption[] = useMemo(
    () =>
      templates.map(({ _id, name, type }) => ({
        label: name,
        value: _id,
        type,
      })) || [],
    [templates]
  )

  const stakeHolderClientOptions: SelectorOption[] = useMemo(
    () =>
      stakeHolders.map(({ _id, company: { name } }) => ({
        label: name,
        value: _id,
      })),
    [stakeHolders]
  )

  const productOptions: SelectorOption[] = useMemo(
    () =>
      products.map(product => ({
        label: getProductNameWithReference(product),
        value: product._id,
      })),
    [products]
  )

  const [truOptions, setTruOptions] = useState<(SelectorOption & { created: boolean })[]>([])

  useEffect(() => {
    setTruOptions(
      trusByProduct.map(({ _id, reference }) => ({
        label: reference,
        value: _id,
        created: false,
      }))
    )
  }, [trusByProduct])

  const templateSelected = useMemo(
    () => templates.find(({ _id }) => _id === values.formId),
    [templates, values.formId]
  )
  const {
    user: templateSelectedDefaultAssignedToUser,
    isLoading: templateSelectedDefaultAssignedToUserIsLoading,
  } = useUserById(templateSelected?.defaults?.assignedTo || userId)
  const {
    user: templateSelectedDefaultIssueResponderUser,
    isLoading: templateSelectedDefaultIssueResponderUserIsLoading,
  } = useUserById(templateSelected?.defaults?.issueResponder || userId)

  const isDefaultsLoading =
    templateSelectedDefaultAssignedToUserIsLoading ||
    templateSelectedDefaultIssueResponderUserIsLoading
  const [defaultsAssigned, setDefaultsAssigned] = useState(false)

  const handleNext = async (callbackFunction: Function) => {
    const stepErrors: FormikErrors<FormValues> = (await setTouched({
      formId: true,
      dueDate: true,
      stakeHolderId: true,
      productId: true,
      truId: true,
    })) as unknown as FormikErrors<FormValues>

    if (
      !stepErrors?.formId &&
      !stepErrors?.dueDate &&
      !stepErrors?.stakeHolderId &&
      !stepErrors?.productId &&
      !stepErrors?.truId
    ) {
      if (
        currentTemplateType === TemplateType.INCIDENCE &&
        values.truId &&
        truOptions.find(option => option.value === values.truId)?.created
      ) {
        toggleTRUCreationConfirmShow()
      } else {
        callbackFunction()
      }
    }
  }

  const handleTRUCreationConfirm = useCallback(() => {
    const productContext = products.find(({ _id }) => _id === values.productId)
    if (values.truId && productContext) {
      createTRU(
        {
          productReference: productContext.reference,
          truReference: values.truId,
        },
        {
          onSuccess: resultId => {
            setFieldValue('truId', resultId)
            goNext()
          },
        }
      )
      toggleTRUCreationConfirmShow()
    }
  }, [
    createTRU,
    goNext,
    products,
    setFieldValue,
    toggleTRUCreationConfirmShow,
    values.productId,
    values.truId,
  ])

  useEffect(() => {
    if (defaultsAssigned || isDefaultsLoading) return
    setFieldValue(
      'assignedTo',
      templateSelectedDefaultAssignedToUser
        ? templateSelectedDefaultAssignedToUser.toSelectorOption()
        : createSelectorOption(companyUser.name, companyUser._id)
    )
    setFieldValue(
      'issueResponder',
      templateSelectedDefaultIssueResponderUser
        ? templateSelectedDefaultIssueResponderUser.toSelectorOption()
        : createSelectorOption(companyUser.name, companyUser._id)
    )
    setDefaultsAssigned(true)
  }, [
    templateSelectedDefaultIssueResponderUser,
    templateSelectedDefaultAssignedToUser,
    setFieldValue,
    companyUser.name,
    companyUser._id,
    defaultsAssigned,
    isDefaultsLoading,
  ])

  const handleFormIdChange = (templateId?: string) => {
    setFieldValue('formId', templateId)
    const templateSelected = templates.find(({ _id }) => _id === templateId)

    if (templateSelected) {
      const { entries, type } = templateSelected
      if (!entries || !type) return
      setFieldValue(
        'entries',
        entries.map(entry => getDefaultEntryFromTemplate(entry) as EntryCreateInput)
      )
      setCurrentTemplateType(type)
      setFieldValue('blocks', prepareEntryBlocksMapsFromTemplateEntries(entries))
    }

    setFieldValue('issues', [])
    setFieldValue('stakeHolderId', undefined)
    setFieldValue('productId', undefined)
    setFieldValue('truId', undefined)
  }

  return (
    <>
      <Step
        title={t('steps.step1.stepTitle')}
        subtitle={
          <div className='flex gap-3'>
            <span>{t('steps.step1.stepSubtitle')}</span>
            <Tooltip tip={t('steps.step1.subDescription')} cursor>
              <span>
                <InformationCircleIcon className='w-4 h-4 text-gray-400' />
              </span>
            </Tooltip>
          </div>
        }
        overview={
          <StepOverview
            items={[
              templateOptions.find(option => option.value === values.formId)?.label,
              dayjs(values.dueDate).format('L'),
            ]}
          />
        }
      >
        {({ goNext }) => (
          <div className='space-y-6 m-1'>
            <Card className='overflow-visible'>
              <Card.Body>
                <div className='mt-5 md:mt-0 md:col-span-2 space-y-6'>
                  <SelectField
                    id='formId'
                    name='formId'
                    options={templateOptions}
                    isLoading={isTemplatesLoading}
                    value={templateOptions.find(option => option.value === values.formId)}
                    onChange={option => {
                      handleFormIdChange(option?.value)
                    }}
                    onBlur={handleBlur}
                    label={t('form.formId.label')}
                    description={
                      <Trans
                        t={t}
                        i18nKey={`form.formId.${
                          userPermissions.forms?.write && isCompletingTask
                            ? 'description'
                            : 'descriptionWithoutLink'
                        }`}
                        components={{
                          a: (
                            <Link
                              to={generateReferrerUrl({
                                url: '/forms/create',
                                fieldName: 'formId',
                              })}
                              className='underline'
                            />
                          ),
                        }}
                      />
                    }
                    isDisabled={isCompletingTask}
                    error={!!errors.formId && touched.formId}
                  />

                  <DateLocaleField
                    id='dueDate'
                    name='dueDate'
                    onChange={value => setFieldValue('dueDate', value)}
                    value={values.dueDate}
                    label={t('form.dueDate.label')}
                    description={t('form.dueDate.description')}
                    error={!!errors.dueDate && touched.dueDate}
                  />

                  {currentTemplateType === TemplateType.INCIDENCE && (
                    <>
                      <SelectField
                        id='stakeHolderId'
                        name='stakeHolderId'
                        options={stakeHolderClientOptions}
                        value={stakeHolderClientOptions.find(
                          option => option.value === values.stakeHolderId
                        )}
                        isLoading={isStakeHoldersLoading}
                        onChange={option => {
                          setFieldValue('stakeHolderId', option?.value)
                        }}
                        onBlur={handleBlur}
                        label={t('form.stakeHolderId.label')}
                        description={
                          <Trans
                            t={t}
                            i18nKey='form.stakeHolderId.description'
                            components={{
                              a: <Link to='/stakeholders/create' className='underline' />,
                            }}
                          />
                        }
                        error={!!errors.stakeHolderId && touched.stakeHolderId}
                      />

                      <SelectField
                        id='productId'
                        name='productId'
                        options={productOptions}
                        value={productOptions.find(option => option.value === values.productId)}
                        onChange={option => {
                          setFieldValue('productId', option?.value)
                          setFieldValue('truId', undefined)
                        }}
                        onBlur={handleBlur}
                        label={t('form.productId.label')}
                        description={
                          <Trans
                            t={t}
                            i18nKey='form.productId.description'
                            components={{
                              a: <Link to='/products/create' className='underline' />,
                            }}
                          />
                        }
                        error={!!errors.productId && touched.productId}
                      />

                      <CreatableSelectField
                        id='truId'
                        name='truId'
                        options={truOptions}
                        value={truOptions.find(option => option.value === values.truId)}
                        onChange={option => {
                          setFieldValue('truId', option?.value)
                        }}
                        onCreateOption={option => {
                          setTruOptions(currentOptions => [
                            ...currentOptions,
                            { ...option, created: true },
                          ])
                          setFieldValue('truId', option?.value)
                        }}
                        onBlur={handleBlur}
                        label={t('form.truId.label')}
                        description={t('form.truId.description')}
                        createLabel={t('form.truId.createLabel').toString()}
                        error={!!errors.truId && touched.truId}
                      />
                    </>
                  )}
                </div>
              </Card.Body>
            </Card>
            <div className='flex flex-wrap justify-between gap-4'>
              <Help analyticsProperties={{ Source: 'Task creation' }} />
              <div className='flex items-center gap-4'>
                <ButtonWithIcon IconComponent={ArrowSmDownIcon} onClick={() => handleNext(goNext)}>
                  {t('steps.step1.nextText')}
                </ButtonWithIcon>
              </div>
            </div>
          </div>
        )}
      </Step>

      <AlertConfirm
        show={truCreationConfirmShow}
        title={t('form.truId.confirmation.title')}
        description={t('form.truId.confirmation.description')}
        cancelText={t('form.truId.confirmation.cancel')}
        confirmText={t('form.truId.confirmation.confirm')}
        confirmColor='primary'
        onCancel={toggleTRUCreationConfirmShow}
        onConfirm={handleTRUCreationConfirm}
      />
    </>
  )
}
