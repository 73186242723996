import { useMemo, useState } from 'react'

/**
 * Custom hook to manage toggles
 *
 * @param initialValue
 * @returns Returns a stateful boolean, and a function to toggle it.
 */
export const useToggle = (initialValue: boolean): [boolean, (nextValue?: unknown) => void] => {
  const [state, setState] = useState<boolean>(initialValue)

  const toggle = useMemo(
    () => (nextValue?: unknown) => {
      setState(currentState =>
        nextValue !== undefined && typeof nextValue === 'boolean' ? nextValue : !currentState
      )
    },
    []
  )

  return [state, toggle]
}
