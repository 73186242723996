import { useTranslation } from 'react-i18next'
import { EditableEntry, TemplateEntryRedesignError } from '../../interfaces'
import { getErrorsByEntryIds } from '../../utils'
import { BlockFields } from '../BlockFields'
import { IssueReferenceHelpersOptions } from '../hooks'

export interface SectionEntryFieldProps {
  targetEntry: EditableEntry
  entries: EditableEntry[]
  entriesErrors?: TemplateEntryRedesignError[]
  onAddEntryInBlock: (entryToAdd: EditableEntry, entriesInNestedBlock?: EditableEntry[]) => void
  onRemoveEntryInBlock: (entryToAdd: EditableEntry) => void
  onChangeEntryInBlock: (
    entry: EditableEntry,
    nestedBlockOperation?: {
      entry: EditableEntry
      operation: 'add' | 'remove'
      /**
       * When we want to clean up the field `isNewEntry`, we need to know the entries that below to a condition in a conditional
       * We only need this for nested conditionals
       */
      valuesIndex?: number
    }
  ) => void
  onSort: (entries: EditableEntry[]) => void
  issueReferenceEnabled?: boolean
  documentEnabled?: boolean
  referenceEnabled?: boolean
  signatureEnabled?: boolean
  fileDocumentSettingsEnabled?: boolean

  issueHelpersOptions?: Omit<IssueReferenceHelpersOptions, 'targetEntry'>
}

export const SectionEntryField = ({
  targetEntry,
  entries,
  entriesErrors,
  onAddEntryInBlock,
  onRemoveEntryInBlock,
  onChangeEntryInBlock,
  onSort,
  issueReferenceEnabled,
  documentEnabled,
  referenceEnabled,
  issueHelpersOptions,
  signatureEnabled,
  fileDocumentSettingsEnabled,
}: SectionEntryFieldProps) => {
  const { t } = useTranslation('nsTemplate')

  const childEntries = entries.filter(entry => entry.blockId === targetEntry.id)

  const childEntriesErrors = entriesErrors ? getErrorsByEntryIds(entriesErrors, childEntries) : {}

  return (
    <div className='bg-gray-100 py-6  rounded-lg flex flex-col'>
      <div className='flex flex-col px-10'>
        <h4 className='text-base leading-6 font-bold'>{t('entries.section.title')}</h4>

        <p className='mt-2 text-sm leading-5 font-normal text-gray-500'>
          {t('entries.section.description')}
        </p>

        <div className='mt-4'>
          <BlockFields
            entries={childEntries}
            errors={childEntriesErrors}
            onAddField={(newEntry, entriesInNestedBlock) => {
              onAddEntryInBlock(
                {
                  ...newEntry,
                  blockId: targetEntry.id,
                  isNewEntry: childEntries.length > 0,
                },
                entriesInNestedBlock
              )
            }}
            onRemoveField={entry => {
              onRemoveEntryInBlock(entry)
            }}
            onChangeField={(entry, nestedBlockOperation) => {
              onChangeEntryInBlock(entry, nestedBlockOperation)
            }}
            onSortFields={(entries: EditableEntry[]) => {
              onSort(entries)
            }}
            mandatoryEnabled
            conditionalEnabled
            tableEnabled
            statementEnabled
            issueReferenceEnabled={issueReferenceEnabled}
            documentEnabled={documentEnabled}
            referenceEnabled={referenceEnabled}
            signatureEnabled={signatureEnabled}
            entriesInNestedBlock={entries}
            errorsInNestedBlock={entriesErrors}
            issueHelpersOptions={issueHelpersOptions}
            fileDocumentSettingsEnabled={fileDocumentSettingsEnabled}
          />
        </div>
      </div>
    </div>
  )
}
