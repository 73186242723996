import { api } from '@/app/api'
import { DownloaderMIMEType, downloader } from '@/helpers/downloader'
import qs from 'querystring'
import { IssueChanges, IssueUpdateOrCreateWithTaskEntryId } from '@/features/issues'
import {
  ReceptionTaskRelation,
  StakeHolderTaskRelation,
  Task,
  TaskCreation,
  TaskFilters,
  TaskUpdate,
  TasksSummaryReportData,
  TasksSummaryReportFilters,
  TruTaskRelation,
} from './interfaces'
import { appendFiltersToParams, createSearchParamsFromTaskFilters } from './utils/search-params'
import { Paginated, PaginationParams } from '@/hooks/use-pagination'

export const TASKS_URL = `/tasks`
export const TASKS_RESOURCE_URL = `${TASKS_URL}/tasks` as const

const getTasksPath = ({
  assignedToMe,
  respondedByMe,
}: Pick<TaskFilters, 'assignedToMe' | 'respondedByMe'> = {}) => {
  if (assignedToMe) return `${TASKS_RESOURCE_URL}/assigned`
  if (respondedByMe) return `${TASKS_RESOURCE_URL}/issueResponder`
  return TASKS_RESOURCE_URL
}

/**
 * Create a new Task
 * @param taskData Task data to create
 * @param ignoreMandatoryEntries Optional query param to ignore mandatory fields check
 * @returns The created task id
 */
export const create = async (
  taskData: TaskCreation,
  issues?: IssueUpdateOrCreateWithTaskEntryId[],
  options: { ignoreMandatoryEntries?: boolean } = {}
): Promise<string> => {
  const searchParams = new URLSearchParams()

  if (options.ignoreMandatoryEntries) {
    searchParams.append('ignoreMandatoryEntries', 'true')
  }

  const { data: taskId } = await api.post<string>(
    `${TASKS_RESOURCE_URL}?${searchParams.toString()}`,
    {
      ...taskData,
      issues,
    }
  )
  return taskId
}

/**
 * Get all Tasks
 */
export const getAll = async (filters?: Record<string, string | string[]>): Promise<Task[]> => {
  const params = new URLSearchParams()

  const { data: tasks } = await api.get<Task[] | void>(TASKS_RESOURCE_URL, {
    params: appendFiltersToParams(params, filters),
  })
  return tasks || []
}

export const getPaginated = async (
  { assignedToMe, respondedByMe, ...filters }: TaskFilters = {},
  pagination: PaginationParams
): Promise<Paginated<Task>> => {
  const { data } = await api.post<Paginated<Task>>(
    `${getTasksPath({ assignedToMe, respondedByMe })}/search`,
    {
      ...filters,
      ...pagination,
    }
  )
  return data
}

export const getCount = async ({
  assignedToMe,
  respondedByMe,
  ...filters
}: TaskFilters = {}): Promise<{
  count: number
}> => {
  const { data } = await api.post<{ count: number }>(
    `${getTasksPath({ assignedToMe, respondedByMe })}/count`,
    filters
  )
  return data
}

/**
 * Get all assigned tasks
 */
export const getAllAssigned = async (
  filters?: Record<string, string | string[]>
): Promise<Task[]> => {
  const params = new URLSearchParams()

  const { data: tasks } = await api.get<Task[] | void>(`${TASKS_RESOURCE_URL}/assigned`, {
    params: appendFiltersToParams(params, filters),
  })
  return tasks || []
}

/**
 * Get all Tasks by ids
 */
export const getAllByIds = async (taskIds: string[]): Promise<Task[]> => {
  if (taskIds.length === 0) return []
  const { data: tasks } = await api.post<Task[] | void>(`${TASKS_RESOURCE_URL}/ids`, {
    ids: taskIds,
  })
  return tasks || []
}

/**
 * Get a Task by id
 */
export const getById = async (taskId: string): Promise<Task | void> => {
  const { data: task } = await api.get<Task | void>(`${TASKS_RESOURCE_URL}/${taskId}`)
  return task
}

/**
 * Get tasks associated with TRUS id
 */
export const getTasksByTruId = async (
  truId: string,
  filters?: Record<string, string | string[]>
): Promise<Task[]> => {
  const params = new URLSearchParams()

  const { data: tasks } = await api.get<Task[] | void>(`${TASKS_RESOURCE_URL}/tru/${truId}`, {
    params: appendFiltersToParams(params, filters),
  })
  return tasks || []
}

/**
 * Get tasks associated with Reception ids
 */

export const getTasksByReceptionId = async (receptionId: string): Promise<Task[]> => {
  const { data: tasks } = await api.get<Task[] | void>(
    `${TASKS_RESOURCE_URL}/reception/${receptionId}`
  )
  return tasks || []
}

/**
 * Get tasks associated with stakeholder id
 */
export const getTasksByStakeHolderId = async (
  stakeHolderId: string,
  filters?: TaskFilters
): Promise<Task[]> => {
  const params = createSearchParamsFromTaskFilters(filters)

  const { data: tasks } = await api.get<Task[] | void>(
    `${TASKS_RESOURCE_URL}/stakeHolder/${stakeHolderId}?${params.toString()}`
  )
  return tasks || []
}

/**
 * Get truTaskRelations by task id
 */
export const getTruTaskRelationsByTaskId = async (taskId: string): Promise<TruTaskRelation[]> => {
  const { data: truTaskRelations } = await api.get<TruTaskRelation[] | void>(
    `${TASKS_RESOURCE_URL}/${taskId}/relations/trus`
  )
  return truTaskRelations || []
}

/**
 * Get stakeHolderTaskRelations by task ids
 */
export const getStakeHolderTaskRelationsByTaskIds = async (
  taskIds: string[]
): Promise<StakeHolderTaskRelation[]> => {
  const { data: relations } = await api.post<StakeHolderTaskRelation[] | void>(
    `${TASKS_RESOURCE_URL}/relations/stakeHolders`,
    {
      ids: taskIds,
    }
  )
  return relations || []
}

/**
 * Get receptionTaskRelations by task id
 */

export const getReceptionTaskRelationsByTaskId = async (
  taskId: string
): Promise<ReceptionTaskRelation[]> => {
  const { data: receptionTaskRelations } = await api.get<ReceptionTaskRelation[] | void>(
    `${TASKS_RESOURCE_URL}/${taskId}/relations/receptions`
  )

  return receptionTaskRelations || []
}
export const getPublicUrlTaskFile = async (filename: string): Promise<string | void> => {
  const { data: publicUrl } = await api.get<string | void>(
    `${TASKS_RESOURCE_URL}/file/public?${qs.stringify({ name: filename })}`
  )
  return publicUrl
}

export const downloadTaskFile = async (
  filename: string,
  mimeType: DownloaderMIMEType
): Promise<void> => {
  const response = await api.get<string | void>(`${TASKS_RESOURCE_URL}/file?name=${filename}`, {
    responseType: 'blob',
  })
  const blob = response.data as unknown
  downloader(blob as Blob, mimeType, filename)
}

export const update = async (
  taskId: string,
  {
    task,
    issueChanges,
  }: {
    task: TaskUpdate
    issueChanges?: IssueChanges
  }
): Promise<void> => {
  await api.patch<void>(`${TASKS_RESOURCE_URL}/${encodeURIComponent(taskId)}`, {
    ...task,
    issues: issueChanges,
  })
}

export const sendReportEmail = async (params: {
  taskId: string
  to: string[]
  subject: string
  content: string
  metadata: {
    userEmail: string
    companyName: string
    formName: string
  }
  attachments: { fileName: string; path: string }[]
}): Promise<void> => {
  await api.post<void>(`${TASKS_RESOURCE_URL}/reports/sendByEmail`, params)
}

export const getReportTasks = async ({
  startDate,
  endDate,
}: TasksSummaryReportFilters): Promise<TasksSummaryReportData> => {
  const searchParams = new URLSearchParams()

  searchParams.append('startDate', startDate.toString())
  searchParams.append('endDate', endDate.toString())
  const response = await api.get<TasksSummaryReportData>(`${TASKS_RESOURCE_URL}/REPORT`, {
    params: searchParams,
  })
  return response.data
}

/**
 * We add formName to include it in the delete task notification for task where formName is not saved.
 */
export const remove = async (task: Task, formName?: string): Promise<void> => {
  await api.delete<void>(
    `${TASKS_RESOURCE_URL}/${encodeURIComponent(task._id)}${
      formName ? `?formName=${encodeURIComponent(formName)}` : ''
    }`
  )
}
