import {
  TemplateDeprecatedEntryType,
  TemplateEntryReferenceValue,
  TemplateEntryType,
} from '@/features/templates'
import { SelectorOption } from '@blockchain-traceability-sl/tailwind-components'

export type MetadataOption = {
  key: string
  type: TemplateEntryType
  options: SelectorOption[]
  value: string
  values: string[] | TemplateEntryReferenceValue[]
  mandatory?: boolean
}

export enum MetadataType {
  TRU = 'tru',
  SHIPMENT = 'shipment',
  RECEPTION = 'reception',
}

export type MetadataEntry = {
  key: string
  value: string
  values: string[] | TemplateEntryReferenceValue[]
  type: TemplateEntryType | TemplateDeprecatedEntryType
  options?: string[]
  mandatory?: boolean
}

export interface Metadata {
  _id: string
  belongsTo: string
  type: MetadataType
  data: MetadataEntry[]
  updatedAt: string
  createdAt: string
}

export type MetadataCreationKeys = Record<
  string,
  {
    value: string
    values: string[] | TemplateEntryReferenceValue[]
    type: TemplateEntryType
    options?: string[]
  }
>

export interface MetadataCreation {
  productId: string
  belongsTo: string
  metadata: MetadataCreationKeys
  type: MetadataType
}

export interface MetadataUpdate {
  productId: string
  metadata: MetadataCreationKeys
}
