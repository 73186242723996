import { useCallback, useMemo, useReducer, useState } from 'react'
import { TRUFiltersState } from '../../interfaces'

/**
 * This is a special value that is used to indicate that all options are selected.
 */
export const ALL_FILTER_OPTION_VALUE = '@ALL' as const

interface TRUFiltersAction extends Partial<TRUFiltersState> {
  type: 'change' | 'reset'
}

const initialState: TRUFiltersState = {
  search: '',
  originId: [],
  productId: [],
}

/**
 * This function is used to clean the `tags` array when the `ALL` option is selected.
 * @param previousArray The previous value of the `tags` array.
 * @param array The new value of the `tags` array.
 * @param isAllOption A function that returns `true` if the given item is the `ALL` option.
 * @returns The new value of the `tags` array.
 */
const cleanAllOption = <T>(
  previousArray: T[],
  array: T[],
  { isAllOption }: { isAllOption: (item: T) => boolean }
) => {
  const allPreviouslySelected = previousArray.length === 0
  const allSelected = array.find(isAllOption)

  if (allPreviouslySelected && allSelected) {
    return array.filter(value => !isAllOption(value))
  }

  if (!allPreviouslySelected && allSelected) {
    return []
  }

  return array
}

/**
 * This reducer is used to manage the state of the tru filters.
 * @param state The previous state.
 * @param action The action to perform.
 * @returns The new state.
 */
const truFiltersReducer = (state: TRUFiltersState, action: TRUFiltersAction) => {
  const { type, ...filters } = action
  if (type === 'reset') {
    return initialState
  }

  const newState = { ...state }

  if (filters.search !== undefined) {
    newState.search = filters.search
  }

  if (filters.originId) {
    newState.originId = cleanAllOption(state.originId, filters.originId, {
      isAllOption: status => status.id === ALL_FILTER_OPTION_VALUE,
    })
  }

  return newState
}

/**
 * This hook is used to manage the state of the tru filters.
 * @returns The state of the tru filters.
 * @example
 * const { filters, isFiltersEmpty, isFiltersTouched, changeFilter, resetFilters } = useTRUFilters()
 */
export const useTRUFilters = () => {
  const [state, dispatch] = useReducer(truFiltersReducer, initialState)
  const [isFiltersTouched, setIsFiltersTouched] = useState(false)

  const isFiltersEmpty = useMemo(() => Object.values(state).every(value => !value), [state])

  const changeFilter = useCallback((filter: Omit<TRUFiltersAction, 'type'>) => {
    dispatch({ type: 'change', ...filter })
    setIsFiltersTouched(true)
  }, [])

  const resetFilters = useCallback(() => {
    dispatch({ type: 'reset' })
    setIsFiltersTouched(false)
  }, [])

  return {
    filters: state,
    isFiltersEmpty,
    isFiltersTouched,
    changeFilter,
    resetFilters,
  }
}
