import { Paginated, PaginationParams } from '@/hooks/use-pagination'
import { Origin } from './interfaces'
import { api } from '@/app/api'

const PRODUCTS_URL = '/hydra/products'

export const getPaginatedAvailableOriginsByProduct = async (
  productId: string,
  filters: { search?: string; productOriginIds?: string[] },
  pagination: PaginationParams
): Promise<Paginated<Origin>> => {
  const { data } = await api.get<Paginated<Origin>>(
    `${PRODUCTS_URL}/${productId}/available-origins`,
    {
      params: {
        ...filters,
        ...pagination,
      },
    }
  )
  return data
}

const TRUS_URL = '/hydra/trus'

export const getPaginatedAvailableOriginsByTRU = async (
  truId: string,
  filters: { search?: string; productOriginIds?: string[] },
  pagination: PaginationParams
): Promise<Paginated<Origin>> => {
  const { data } = await api.get<Paginated<Origin>>(`${TRUS_URL}/${truId}/available-origins`, {
    params: {
      ...filters,
      ...pagination,
    },
  })
  return data
}

export const getUsedOriginsByTRU = async (truId: string): Promise<Origin[]> => {
  const { data } = await api.get<Paginated<Origin>>(`${TRUS_URL}/${truId}/used-origins`)
  return data.items || []
}
