import { api } from '@/app/api'
import { Company, CompanyExtension, CompanyUpdate } from './interfaces'

const COMPANY_URL = '/hydra/companies'

export const updateExtension = async (
  extension: CompanyExtension,
  status: boolean
): Promise<void> => {
  await api.patch<void>(`${COMPANY_URL}/extension`, { name: extension, status })
}

/**
 * Fetch company logged data
 *
 *
 * @param {string | undefined} accessToken
 * @returns {Promise<Company>}
 */
export const getMyCompany = async (): Promise<Company> => {
  const { data: companyLogged } = await api.get<Company>(`${COMPANY_URL}/me`)
  return companyLogged
}

/**
 * Fetch company logged stakeHolder Ids
 *
 * @returns stakeHolderIds
 */
export const getknownAs = async (): Promise<string[]> => {
  const { data: knownAs } = await api.get<string[] | void>(`${COMPANY_URL}/stakeholders/reference`)
  return knownAs || []
}

export const updateCompanyProfile = async (data: CompanyUpdate): Promise<void> => {
  return await api.patch(`${COMPANY_URL}`, data)
}

export const requestExportData = async (emails: string[]): Promise<void> => {
  await api.post('/auth/export-data', { emails })
}
