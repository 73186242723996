import { SidebarMobile } from './SidebarMobile'
import { SidebarDesktop } from './SidebarDesktop'

export const Sidebar = () => {
  return (
    <>
      {/* Static sidebar for mobile */}
      <SidebarMobile />

      {/* Static sidebar for desktop */}
      <SidebarDesktop />
    </>
  )
}
