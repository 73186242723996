import { AutoSizer } from '@/components/AutoSizer'
import { PDFViewer } from '@/components/PDFViewer'
import { useDebounceState } from '@/hooks/use-debounce-state'
import { Button, Loader, Modal, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGenerateTaskReport, useTaskById } from '@/features/tasks'
import { useTemplate } from '@/features/templates'
import { useGeneratePdfFromDefinition } from '@/features/pdf'
import { downloader } from '@/helpers/downloader'

export interface ModalTaskReportPreviewProps {
  show: boolean
  taskId: string
  toggle: () => void
}

export const ModalTaskReportPreview = ({ show, taskId, toggle }: ModalTaskReportPreviewProps) => {
  const { t } = useTranslation('nsDocumentsViewPage')
  const buttonRef = useRef<HTMLButtonElement>(null)

  const { task, isLoading } = useTaskById(taskId)
  const { template } = useTemplate(task?.formId || '', 'form')

  const { generateTaskReport } = useGenerateTaskReport()
  const { generatePdfFromDefinitionAsync } = useGeneratePdfFromDefinition()

  const [reportSource, setReportSource] = useState<string | null>(null)
  const [reportBlob, setReportBlob] = useState<Blob | null>(null)
  const [sourceUrlDebounce, isDebounced] = useDebounceState(reportSource)

  useEffect(() => {
    if (taskId && template) {
      generateTaskReport(taskId)
        .then(pdfDefinition =>
          generatePdfFromDefinitionAsync({
            pdfDefinition: JSON.stringify(pdfDefinition),
            filename: template.name,
          })
        )
        .then(result => {
          if (result) {
            setReportSource(result.dataUrl)
            setReportBlob(result.blob)
          }
        })
        .catch(console.error)
    }
  }, [generatePdfFromDefinitionAsync, generateTaskReport, taskId, template])

  const renderReport = useMemo(
    () => () => {
      if (isLoading || !sourceUrlDebounce || isDebounced) {
        return <Loader center='full' />
      }
      return (
        <div style={{ minHeight: 128 }}>
          <AutoSizer>
            {({ width }) => (
              <PDFViewer
                source={sourceUrlDebounce}
                downloadText={t('preview.download')}
                previousText={t('preview.previous')}
                nextText={t('preview.next')}
                pagination={false}
                width={width}
              />
            )}
          </AutoSizer>
        </div>
      )
    },
    [isLoading, sourceUrlDebounce, t, isDebounced]
  )

  const handleDownload = () => {
    if (reportBlob && template) {
      downloader(reportBlob, 'application/pdf', template.name)
    }
    toggle()
  }

  return (
    <Modal size={SIZE.LARGE} show={show} toggle={toggle} initialFocus={buttonRef} dismissButton>
      <Modal.Title>
        {template?.name}
        {template && (
          <Button
            size={SIZE.EXTRA_SMALL}
            onClick={handleDownload}
            className='ml-4'
            innerRef={buttonRef}
          >
            {t('preview.download')}
          </Button>
        )}
      </Modal.Title>
      <Modal.Body>
        <div>{show && renderReport()}</div>
      </Modal.Body>
    </Modal>
  )
}
