import { TaskFilters } from '../interfaces'

export const appendFiltersToParams = (
  params: URLSearchParams,
  filters?: Record<string, string | string[] | number | number[] | boolean | boolean[]>
): URLSearchParams => {
  const newParams = params
  if (filters) {
    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => newParams.append(`${key}[]`, String(item)))
      } else {
        if (value !== undefined) {
          newParams.append(key, String(value))
        }
      }
    })
  }
  return newParams
}

export const createSearchParamsFromTaskFilters = ({
  startDate,
  endDate,
  dueDate = [],
  assignedTo = [],
  formId = [],
  status = [],
  validationStatus = [],
  validationReviewerId = [],
  hasIssues,
  issueStatus = [],
  issueResponder = [],
}: TaskFilters = {}) => {
  const searchParams = new URLSearchParams()
  if (startDate) {
    searchParams.append('startDate', startDate)
  }
  if (endDate) {
    searchParams.append('endDate', endDate)
  }
  dueDate.forEach(value => {
    searchParams.append('dueDate[]', value)
  })
  assignedTo.forEach(value => {
    searchParams.append('assignedTo[]', value)
  })
  formId.forEach(value => {
    searchParams.append('formId[]', value)
  })
  status.forEach(value => {
    searchParams.append('status[]', value)
  })
  validationStatus.forEach(value => {
    searchParams.append('validationStatus[]', value)
  })
  validationReviewerId.forEach(value => {
    searchParams.append('validationReviewerId[]', value)
  })
  if (hasIssues) {
    searchParams.append('hasIssues', hasIssues.toString())
  }
  issueStatus.forEach(value => {
    searchParams.append('issueStatus[]', value)
  })
  issueResponder.forEach(value => {
    searchParams.append('issueResponder[]', value)
  })
  return searchParams
}
