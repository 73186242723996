import { MetadataEntry } from '../interfaces'
import { isMetadataEntryEmpty } from '../utils'
import { downloadPublicDocument } from '@/features/documents'
import { isValidHttpUrl } from '@/utils/is-valid-http-url'
import { Button, noop } from '@blockchain-traceability-sl/tailwind-components'
import { useTranslation } from 'react-i18next'
import { TemplateEntryReferenceValue, TemplateEntryType } from '@/features/templates'

interface MetadataEntryRenderProps extends MetadataEntry {
  onDownloadFail?: () => void
}

export const MetadataEntryRender = ({
  value,
  type,
  values,
  onDownloadFail = noop,
}: MetadataEntryRenderProps): JSX.Element => {
  const { t } = useTranslation('nsModalMetadata')

  const handleClickDocument = async (documentId: string) => {
    try {
      downloadPublicDocument(documentId)
    } catch {
      onDownloadFail()
    }
  }

  if (isMetadataEntryEmpty({ type, value, values })) {
    return <>-</>
  }

  switch (type) {
    case TemplateEntryType.LINK:
      return value === '' ? (
        <></>
      ) : (
        <a href={value} target='_blank' rel='noreferrer noopener' className='underline'>
          <u>{t('link')}</u>
        </a>
      )
    case TemplateEntryType.MULTIPLE_CHOICE:
      return <>{values.join(', ')}</>
    case TemplateEntryType.BOOLEAN:
      return <>{t(`boolean.${value}`)}</>
    case TemplateEntryType.NUMBER:
      return (
        <>
          {t('number', {
            value,
          })}
        </>
      )
    case TemplateEntryType.DATE:
      return <>{t('date', { date: new Date(value) })}</>
    case TemplateEntryType.REFERENCE:
      return <>{(values as TemplateEntryReferenceValue[]).map(({ name }) => name).join(', ')}</>
    case TemplateEntryType.DOCUMENT:
      return value === '' ? (
        <></>
      ) : (
        <Button color='link' className='underline' onClick={() => handleClickDocument(value)}>
          <u>{t('document')}</u>
        </Button>
      )

    default:
      return value === '' ? (
        <></>
      ) : isValidHttpUrl(value) ? (
        <a href={value} target='_blank' rel='noreferrer noopener' className='underline'>
          <u>{t('link')}</u>
        </a>
      ) : (
        <>{value}</>
      )
  }
}
