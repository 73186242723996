import { api } from '@/app/api'
import {
  StakeHolder,
  StakeHolderCreation,
  StakeHolderType,
  StakeHolderUpdate,
  StakeholderRequirementEmail,
} from '@/features/stakeHolders'
import { Paginated, PaginationParams } from '@/hooks/use-pagination'

/**
 * Define the url path
 * @constant
 */
export const STAKEHOLDERS_URL = '/hydra/companies/stakeholders'

/**
 * Create a new StakeHolder
 *
 * @param stakeholderRaw  - Raw data to create a new stakeholder
 */
export function create(stakeholderRaw: StakeHolderCreation[]): Promise<void>
export function create(stakeholderRaw: StakeHolderCreation): Promise<string>
export async function create(
  stakeholderRaw: StakeHolderCreation | StakeHolderCreation[]
): Promise<string | void> {
  if (Array.isArray(stakeholderRaw)) {
    await Promise.allSettled(stakeholderRaw.map(raw => api.post<string>(STAKEHOLDERS_URL, raw)))
    return
  }
  const { data } = await api.post<string>(STAKEHOLDERS_URL, stakeholderRaw)
  return data
}

/**
 * Create StakeHolders with CSV file
 */
export const createWithCSV = async (file: File): Promise<{ total: number }> => {
  const formData = new FormData()
  formData.append('file', file)
  const { data } = await api.post<{ total: number }>(`${STAKEHOLDERS_URL}/csv`, formData)
  return data
}

/**
 * Get the Company logged StakeHolders
 *
 * @returns All the Company logged StakeHolders
 */
export const getAll = async (filter?: {
  types?: StakeHolderType[]
  search?: string
}): Promise<StakeHolder[]> => {
  const searchParams = new URLSearchParams()
  if (filter?.types) {
    filter.types.forEach(type => {
      searchParams.append('type[]', type)
    })
  }
  if (filter?.search) {
    searchParams.append('search', filter.search)
  }
  const { data: stakeholders } = await api.get<StakeHolder[] | void>(
    `${STAKEHOLDERS_URL}?${searchParams.toString()}`
  )
  return stakeholders || []
}

export const getTags = async (): Promise<string[]> => {
  const { data: stakeHolderTags } = await api.get<string[] | void>(`${STAKEHOLDERS_URL}/tags`)
  return stakeHolderTags || []
}

export const getPaginated = async (
  {
    types,
    search,
  }: {
    types?: StakeHolderType[]
    search?: string
  },
  pagination: PaginationParams
): Promise<Paginated<StakeHolder>> => {
  const searchParams = new URLSearchParams()
  if (types) {
    types.forEach(type => {
      searchParams.append('type[]', type)
    })
  }
  if (search) {
    searchParams.append('search', search)
  }
  searchParams.append('limit', pagination.limit.toString())
  searchParams.append('offset', pagination.offset.toString())
  const { data } = await api.get<Paginated<StakeHolder>>(
    `${STAKEHOLDERS_URL}?${searchParams.toString()}`
  )
  return data
}

/**
 * Get the Company logged StakeHolders by ids
 *
 * @returns All the Company logged StakeHolders by ids
 */
export const getByIds = async (ids: string[]): Promise<StakeHolder[]> => {
  const { data: stakeholders } = await api.post<StakeHolder[] | void>(`${STAKEHOLDERS_URL}/ids`, {
    ids,
  })
  return stakeholders || []
}

/**
 * Get the Company logged StakeHolders by type
 *
 * @returns All the Company logged StakeHolders by type
 */
export const getByType = async (type: StakeHolderType): Promise<StakeHolder[]> => {
  const { data: stakeholders } = await api.get<StakeHolder[] | void>(`${STAKEHOLDERS_URL}/${type}`)
  return stakeholders || []
}

/**
 * Get StakeHolder CSV template
 */
export const getCsvTemplate = async (): Promise<ArrayBuffer> => {
  const { data } = await api.get<ArrayBuffer>(`${STAKEHOLDERS_URL}/csv`, {
    responseType: 'arraybuffer',
  })
  return data
}

/**
 * Update a StakeHolder by id
 *
 * @param stakeholderId - the stakeholder id
 */
export const update = async (
  stakeholderId: string,
  stakeholderRaw: StakeHolderUpdate
): Promise<void> => {
  await api.patch<void>(`${STAKEHOLDERS_URL}/${encodeURIComponent(stakeholderId)}`, stakeholderRaw)
}

/**
 * Delete a StakeHolder
 *
 * @param stakeholderId - the StakeHolder id
 */
export const remove = async (stakeHolderId: string): Promise<void> => {
  await api.delete<void>(`${STAKEHOLDERS_URL}/${encodeURIComponent(stakeHolderId)}`)
}

export const sendRequirementsEmail = async ({
  attachments,
  content,
  metadata,
  subject,
  to,
  contentRichText,
}: StakeholderRequirementEmail): Promise<void> => {
  await api.post<void>(`${STAKEHOLDERS_URL}/requirements/send-email`, {
    attachments,
    content,
    metadata,
    subject,
    to,
    contentRichText,
  })
}
