import { Help } from '@/components/Help'
import { StepOverview } from '@/components/StepOverview'
import { useUserPermissions } from '@/features/auth'
import { DocumentPicker, useDocumentPicker } from '@/features/documents'
import {
  EntryCreateInput,
  EntryFieldSetFromTemplateEntries,
  useFileEntryHandler,
} from '@/features/entries'
import { DirtyIssue } from '@/features/issues'
import { useTemplate } from '@/features/templates'
import useOnScreen from '@/hooks/use-on-screen'
import { useSearchParam } from '@/hooks/use-search-params'
import {
  Button,
  ButtonWithIcon,
  Card,
  Step,
  Tooltip,
} from '@blockchain-traceability-sl/tailwind-components'
import { ArrowSmDownIcon, ArrowSmUpIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { FormikErrors, useField, useFormikContext } from 'formik'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useInitialTaskData } from '../../hooks'
import { TaskCreation } from '../../interfaces'
import { scrollToEntryError } from '../../utils'
import { useFeatureFlag } from '@/features/flags'
import { useToggle } from '@/hooks/use-toggle'

type FormValues = Pick<TaskCreation, 'formId'> & {
  entries: Array<EntryCreateInput>
  issues: DirtyIssue[]
}

export const StepTaskCreationEntries = () => {
  const { value: flagPaginateTasksEntries } = useFeatureFlag(
    'improve_the_performance_of_tasks_with_more_than_100_fields'
  )

  const { t } = useTranslation('nsTaskCreationPage')
  const userPermissions = useUserPermissions()
  const presetId = useSearchParam('presetId')
  const defaultTaskId = useSearchParam('task')
  const defaultForm = useSearchParam('form')

  const [isActionsBoxOnScreen, setActionsBoxRef] = useOnScreen()
  const { task, documentIds, issues } = useInitialTaskData(defaultTaskId || presetId, {
    defaultFormId: defaultForm,
    isPreset: !!presetId,
    associationsEnabled: true,
  })

  const [allowNext, toggleAllowNext] = useToggle(true)

  const {
    showDocumentPicker,
    toggleShowDocumentPicker,
    documentsPicked,
    setDocumentsPicked,
    documentPickerTrigger,
    setDocumentPickerTrigger,
  } = useDocumentPicker<string>(documentIds)

  const { setTouched, setFieldValue, setFieldTouched, submitForm, values } =
    useFormikContext<FormValues>()

  const [{ value: formId }] = useField<string>('formId')

  const { template: templateSelected } = useTemplate(formId, 'form')

  let translationsContext: string | undefined

  if (!userPermissions.tasks.write) {
    if (templateSelected?.defaults?.taskValidator) {
      translationsContext = 'completeWithValidation'
    } else {
      translationsContext = 'complete'
    }
  }
  const fileOptions = useFileEntryHandler({
    id: task?._id,
    entries: task?.entries,
    issues,
    setFieldValue,
    setFieldTouched,
  })

  const handleNext = async (callbackFunction: Function) => {
    const stepErrors: FormikErrors<FormValues> = (await setTouched({
      entries: values.entries.map(() => ({
        value: true,
        values: true,
      })),
      issues: values.issues.map(issue => ({
        entries: issue.entries.map(() => ({
          value: true,
          values: true,
        })),
      })),
    })) as unknown as FormikErrors<FormValues>

    if (!stepErrors?.entries && !stepErrors?.issues) {
      callbackFunction()
    }

    // Scroll to first failed entry or issue entry
    scrollToEntryError(
      stepErrors.entries as FormikErrors<EntryCreateInput>[],
      stepErrors.issues as FormikErrors<DirtyIssue>[]
    )
  }

  const documentOptions = useMemo(
    () => ({
      documentsPicked,
      setDocumentPickerTrigger,
      toggleShowDocumentPicker,
    }),
    [documentsPicked, setDocumentPickerTrigger, toggleShowDocumentPicker]
  )

  const handleChangePage = (pageNumber: number, total: number) => {
    // Allow next if last page
    toggleAllowNext(pageNumber === total - 1)
  }

  return (
    <>
      <Step
        title={t('steps.step2.stepTitle', {
          context: translationsContext,
        })}
        subtitle={
          <div className='flex gap-3'>
            <span>{t('steps.step2.stepSubtitle')}</span>
            <Tooltip tip={t('steps.step2.description')} cursor>
              <span>
                <InformationCircleIcon className='w-4 h-4 text-gray-400' />
              </span>
            </Tooltip>
          </div>
        }
        overview={<StepOverview items={[t('steps.step2.overview')]} />}
      >
        {({ goNext, goPrevious }) => (
          <div className='space-y-6 m-1'>
            <Card className='overflow-visible' divideY={!flagPaginateTasksEntries}>
              <Card.Body>
                <div className='mt-5 md:mt-0'>
                  <EntryFieldSetFromTemplateEntries
                    items={templateSelected?.entries || []}
                    fileOptions={fileOptions}
                    documentOptions={documentOptions}
                    paginationEnabled={flagPaginateTasksEntries}
                    onPageChange={handleChangePage}
                  />
                </div>
              </Card.Body>
            </Card>

            <div className='flex flex-wrap justify-between gap-4'>
              <Help analyticsProperties={{ Source: 'Task creation' }} />
              {!isActionsBoxOnScreen && (
                <div className='fixed lg:hidden bottom-0 flex items-center justify-center bg-gray-100 inset-x-0 gap-4 p-6 py-6'>
                  {/* Go Previous */}
                  {userPermissions.tasks.write && (
                    <ButtonWithIcon
                      color='white'
                      IconComponent={ArrowSmUpIcon}
                      onClick={goPrevious}
                    >
                      {t('steps.step2.previousText')}
                    </ButtonWithIcon>
                  )}

                  {/* Go Next */}
                  {allowNext &&
                    (userPermissions.tasks.write ? (
                      <ButtonWithIcon
                        IconComponent={ArrowSmDownIcon}
                        onClick={() => handleNext(goNext)}
                      >
                        {t('steps.step2.nextText', {
                          context: translationsContext,
                        })}
                      </ButtonWithIcon>
                    ) : (
                      <Button onClick={submitForm} className='w-full'>
                        {t('steps.step2.nextText', {
                          context: translationsContext,
                        })}
                      </Button>
                    ))}
                </div>
              )}
              <div className='flex items-center gap-4' ref={setActionsBoxRef}>
                {/* Go Previous */}
                {userPermissions.tasks.write && (
                  <ButtonWithIcon color='white' IconComponent={ArrowSmUpIcon} onClick={goPrevious}>
                    {t('steps.step2.previousText')}
                  </ButtonWithIcon>
                )}

                {/* Go Next */}
                {allowNext &&
                  (userPermissions.tasks.write ? (
                    <ButtonWithIcon
                      IconComponent={ArrowSmDownIcon}
                      onClick={() => handleNext(goNext)}
                    >
                      {t('steps.step2.nextText', {
                        context: translationsContext,
                      })}
                    </ButtonWithIcon>
                  ) : (
                    <Button onClick={submitForm}>
                      {t('steps.step2.nextText', {
                        context: translationsContext,
                      })}
                    </Button>
                  ))}
              </div>
            </div>
          </div>
        )}
      </Step>

      <DocumentPicker
        show={showDocumentPicker}
        toggle={toggleShowDocumentPicker}
        onPick={documentFile => {
          setFieldValue(`${documentPickerTrigger}`, documentFile._id)
          setDocumentsPicked([...documentsPicked, documentFile])
          setDocumentPickerTrigger(null)
          toggleShowDocumentPicker()
        }}
      />
    </>
  )
}
