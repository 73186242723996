import { PDF_MANUAL_EN_URL, PDF_MANUAL_ES_URL, TABLE_SIZE_PRESETS } from '@/app/constants'
import { Help } from '@/components/Help'
import { useAnalytics } from '@/features/analytics'
import { StakeHolder } from '@/features/stakeHolders'
import { usePrevious } from '@/hooks/use-previous'
import { useToggle } from '@/hooks/use-toggle'
import {
  Button,
  COLOR,
  HorizontalLinkCard,
  Loader,
  Modal,
  RowType,
  SIZE,
  Table,
} from '@blockchain-traceability-sl/tailwind-components'
import {
  ClockIcon,
  DocumentDownloadIcon,
  PencilAltIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useComplianceById, useComplianceReportDownloader, useUpdateCompliance } from '../hooks'
import { Compliance, ComplianceStatus, ComplianceValidationStatus } from '../interfaces'
import { ComplianceExpirationBadge } from './ComplianceExpirationBadge'
import { ComplianceListDeadlineCell } from './ComplianceListDeadlineCell'
import { ComplianceStatusBadge } from './ComplianceStatusBadge'
import { ModalComplianceActivity } from './ComplianceActivity/ModalComplianceActivity'
import { getLastComplianceValidation } from '../utils'
import { ComplianceListDetails } from './ComplianceListDetails'
import i18n from '@/i18n'

export const ComplianceList = ({
  isLoading,
  isFetching,
  onPageChange,
  pageIndex,
  pageSize,
  totalCount,
  compliances,
}: {
  isLoading: boolean
  isFetching: boolean
  onPageChange: (page: { pageIndex: number; pageSize: number }) => void
  pageIndex: number
  pageSize: number
  totalCount: number
  compliances: (Compliance & { stakeholder?: Partial<StakeHolder> })[]
}) => {
  const { t } = useTranslation('nsCompliancesList')
  const analytics = useAnalytics()
  const history = useHistory()
  const [complianceSelected, setComplianceSelected] = useState<string | null>()
  const [complianceManageOpen, toggleComplianceManageOpen] = useToggle(false)
  const [complianceActivityModalOpen, toggleComplianceActivityModalOpen] = useToggle(false)
  const { downloadComplianceReport } = useComplianceReportDownloader()

  const { compliance } = useComplianceById(complianceSelected || '', {
    enabled: !!complianceSelected,
  })

  const { updateCompliance } = useUpdateCompliance()

  const handleComplianceManage = useCallback(
    (id: string) => {
      setComplianceSelected(id)
      toggleComplianceManageOpen()
    },
    [toggleComplianceManageOpen]
  )

  const previousComplianceId = usePrevious(compliance?._id)

  useEffect(() => {
    if (compliance && compliance._id !== previousComplianceId) {
      analytics.track('ACTION_CLICK_COMPLIANCE_MANAGEMENT', {
        Form: compliance?.templateName,
      })
    }
  }, [analytics, compliance, previousComplianceId])

  const handleComplianceValidation = (complianceId: string) => {
    toggleComplianceManageOpen()

    history.push(`/compliances/${complianceId}/validate`)

    analytics.track('ACTION_CLICK_MANAGE_COMPLIANCE', {
      Action: 'Validation request',
    })
  }

  const language = i18n.language

  const userManualUrl = useMemo(() => {
    if (language === 'es') {
      return PDF_MANUAL_ES_URL
    }
    return PDF_MANUAL_EN_URL
  }, [language])

  const rows: RowType[] = useMemo(
    () =>
      compliances.map(
        ({
          _id,
          dueDate,
          deadlineAt,
          status,
          templateName,
          validationState,
          compliantEntityType,
          compliantStakeHolder,
          compliantStakeHolderProduct,
          stakeholder,
          ...rest
        }) => {
          return {
            ...rest,
            id: _id,
            templateName: (
              <div className='flex gap-2 items-center'>
                {templateName} <ComplianceExpirationBadge t={t} dueDate={new Date(dueDate)} />
              </div>
            ),
            dueDate: t('table.dueDateFormat', { date: new Date(dueDate) }),
            deadlineAt: (
              <ComplianceListDeadlineCell
                complianceId={_id}
                deadlineAt={deadlineAt}
                status={status}
                onDeadlineChange={value => {
                  updateCompliance({ complianceId: _id, deadlineAt: value })
                }}
              />
            ),
            status: (
              <ComplianceStatusBadge status={status} validationState={validationState} t={t} />
            ),
            details: (
              <ComplianceListDetails
                status={status}
                compliantEntityType={compliantEntityType}
                compliantStakeHolder={compliantStakeHolder}
                compliantStakeHolderProduct={compliantStakeHolderProduct}
                stakeholder={stakeholder}
                lastValidationStatus={getLastComplianceValidation(validationState)?.status}
              />
            ),
            actions: (
              <Button color='link' onClick={() => handleComplianceManage(_id)}>
                {t('table.action.cell')}
              </Button>
            ),
          }
        }
      ),
    [compliances, t, updateCompliance, handleComplianceManage]
  )

  return isLoading ? (
    <div className='h-80'>
      <Loader center='full' />
    </div>
  ) : (
    <>
      <Table
        manualPagination
        onPageChange={onPageChange}
        onPageSizeChange={newPageSize => {
          onPageChange({ pageIndex, pageSize: newPageSize })
        }}
        totalCount={totalCount}
        pageSize={pageSize}
        defaultPageIndex={pageIndex}
        columns={[
          {
            Header: t('table.templateName').toString(),
            accessor: 'templateName',
          },
          { Header: t('table.details').toString(), accessor: 'details' },
          {
            Header: t('table.status').toString(),
            accessor: 'status',
          },
          {
            Header: t('table.deadlineAt').toString(),
            accessor: 'deadlineAt',
          },
          {
            Header: t('table.dueDate').toString(),
            accessor: 'dueDate',
          },
          {
            Header: t('table.action.cell').toString(),
            accessor: 'actions',
          },
        ]}
        data={rows}
        isLoading={isFetching}
        previousText={t('pagination.previous')}
        nextText={t('pagination.next')}
        renderInfoText={(from, to, total) => (
          <Trans
            t={t}
            i18nKey='pagination.show'
            components={{ b: <span className='font-semibold' /> }}
            values={{ from, to, total }}
          />
        )}
        sizePresetsText={t('pagination.items')}
        showSizePresets
        sizePresets={TABLE_SIZE_PRESETS}
        noDataText={
          <tr>
            <td colSpan={5} className='text-center p-8 space-y-6'>
              <p className='text-4xl leading-10 font-extrabold text-center whitespace-pre-wrap'>
                {t('table.noData')}
              </p>
              <Button
                color='secondary'
                size={SIZE.EXTRA_LARGE}
                className='m-auto'
                onClick={() => {
                  analytics.track('ACTION_CLICK_HELP', { Source: 'Forms section' })
                  window.open(userManualUrl, '_blank')
                }}
              >
                {t('table.needHelp')}
              </Button>
            </td>
          </tr>
        }
      />
      <Help analyticsProperties={{ Source: 'Compliance section' }} />

      {/* Modal Manage Compliance */}
      <Modal
        show={complianceManageOpen}
        toggle={toggleComplianceManageOpen}
        size={SIZE.LARGE}
        dismissButton
      >
        <Modal.Title>{t('manage.title')}</Modal.Title>
        <Modal.Body>
          <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 mt-1 mb-1'>
            {/* Go to validation page */}
            {compliance?.status === ComplianceStatus.SUBMITTED && (
              <HorizontalLinkCard
                title={t('manage.validation.title').toString()}
                description={t('manage.validation.description').toString()}
                color={COLOR.GREEN}
                IconComponent={ShieldCheckIcon}
                onClick={() => handleComplianceValidation(compliance._id)}
              />
            )}

            {/* Go to view and edit page */}
            {compliance &&
              getLastComplianceValidation(compliance.validationState)?.status ===
                ComplianceValidationStatus.APPROVED && (
                <HorizontalLinkCard
                  title={t('manage.edit.title').toString()}
                  description={t('manage.edit.description').toString()}
                  color={COLOR.YELLOW}
                  IconComponent={PencilAltIcon}
                  onClick={() => {
                    analytics.track('ACTION_CLICK_MANAGE_COMPLIANCE', {
                      Action: 'View and Edit',
                    })
                    history.push(`/compliances/${compliance._id}/edit`)
                  }}
                />
              )}

            {/* Download compliance report */}
            {compliance?.status === ComplianceStatus.ACCEPTED && (
              <HorizontalLinkCard
                title={t('manage.view.title').toString()}
                description={t('manage.view.description').toString()}
                color={COLOR.GREEN}
                IconComponent={DocumentDownloadIcon}
                onClick={() => {
                  analytics.track('ACTION_CLICK_MANAGE_COMPLIANCE', {
                    Action: 'Generate report',
                  })
                  downloadComplianceReport(compliance._id)
                  toggleComplianceManageOpen(false)
                }}
              />
            )}
            <HorizontalLinkCard
              title={t('manage.activity.title').toString()}
              description={t('manage.activity.description').toString()}
              color={COLOR.PURPLE}
              IconComponent={ClockIcon}
              onClick={() => {
                analytics.track('ACTION_CLICK_MANAGE_COMPLIANCE', {
                  Action: 'Compliance activity',
                })
                toggleComplianceActivityModalOpen()
                toggleComplianceManageOpen()
              }}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal Compliance Activity */}
      <ModalComplianceActivity
        complianceId={complianceSelected}
        onHide={() => {
          toggleComplianceManageOpen()
          toggleComplianceActivityModalOpen()
        }}
        show={complianceActivityModalOpen}
      />
    </>
  )
}
