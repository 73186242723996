import { UseInfiniteQueryOptions, UseQueryOptions } from 'react-query'

export const mergeQueryOptions = <T, E>(
  options?: Omit<UseQueryOptions<T, E>, 'queryKey' | 'queryFn'>,
  overrideOptions?: Omit<UseQueryOptions<T, E>, 'queryKey' | 'queryFn'>
): Omit<UseQueryOptions<T, E>, 'queryKey' | 'queryFn'> => {
  return {
    ...options,
    ...overrideOptions,
  }
}

export const mergeInfiniteQueryOptions = <T, E>(
  options?: Omit<UseInfiniteQueryOptions<T, E>, 'queryKey' | 'queryFn'>,
  overrideOptions?: Omit<UseInfiniteQueryOptions<T, E>, 'queryKey' | 'queryFn'>
): Omit<UseInfiniteQueryOptions<T, E>, 'queryKey' | 'queryFn'> => {
  return {
    ...options,
    ...overrideOptions,
  }
}
