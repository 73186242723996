// 32MB
export const MAX_FILE_SIZE = {
  BYTES: 3.2e7,
  MB: 32,
}

export const TAX_ID_EXPLANATION =
  'https://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers/'

export const ISSUE_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSd_BUWRXlr8UeI9YnqmDC_7mFcMHdA4CtGvqRly5R84JuKCNw/viewform?embedded=true'

export const PDF_MANUAL_ES_URL =
  'https://storage.googleapis.com/traceability-production-support-manual/01%20-%20Gui%CC%81a%20de%20uso%20plataforma%20Trazable%20-%20ES.pdf'

export const PDF_MANUAL_EN_URL =
  'https://storage.googleapis.com/traceability-production-support-manual/02%20-%20Gui%CC%81a%20de%20uso%20plataforma%20Trazable%20-%20EN.pdf'
/**
 * @description Default table size presets for the table component in the platform
 * @type {[10, 30, 50, 100]}
 * @constant
 */
export const TABLE_SIZE_PRESETS = [10, 30, 50, 100]

export const DEFAULT_PAGE_SIZE = 10

/**
 * @description Validates a string for company name or user name
 * String must contain only lowercase and uppercase english letters, spaces, numbers and diacritics
 * @type {RegExp}
 * @constant
 */
export const COMPANY_OR_USER_NAME_REGEX = /^[a-zA-ZÀ-ÖØ-öø-ÿ0-9 ]+$/
