import { IProductFilters, Product, ProductCreation, ProductUpdate } from './interfaces'
import { api } from '@/app/api'
import { Paginated, PaginationParams } from '@/hooks/use-pagination'

/**
 * Define the url path
 * @constant
 */
export const PRODUCTS_URL = '/hydra/products'

export const createProduct = async (product: ProductCreation): Promise<string> => {
  const { data } = await api.post<string>(PRODUCTS_URL, product)
  return data
}

/**
 * Create Products with CSV file
 */
export const createWithCSV = async (file: File): Promise<{ total: number }> => {
  const formData = new FormData()
  formData.append('file', file)
  const { data } = await api.post<{ total: number }>(`${PRODUCTS_URL}/csv`, formData)
  return data
}

export const updateProduct = async (productUpdate: ProductUpdate): Promise<void> => {
  await api.patch<void>(`${PRODUCTS_URL}/${productUpdate._id}`, productUpdate)
}

/**
 * Get the Company logged Products
 * @returns {Product[]} All the Company logged Products
 */
export const getAll = async (filters?: IProductFilters): Promise<Product[]> => {
  const searchParams = new URLSearchParams()

  if (filters?.search) {
    searchParams.append('search', filters.search)
  }
  filters?.tags?.forEach(tag => {
    searchParams.append('tags[]', tag)
  })

  const { data: products } = await api.get<Product[] | void>(PRODUCTS_URL, { params: searchParams })
  return products || []
}

export const getById = async (id: string): Promise<Product | undefined> => {
  const { data: product } = await api.get<Product | undefined>(`${PRODUCTS_URL}/${id}`)
  return product
}

export const getByIds = async (ids: string[]): Promise<Product[]> => {
  const { data: products } = await api.post<Product[]>(`${PRODUCTS_URL}/ids`, { ids })
  return products || []
}

export const getTags = async (): Promise<string[]> => {
  const { data: productTags } = await api.get<string[] | void>(`${PRODUCTS_URL}/tags`)
  return productTags || []
}

/**
 * Get the Company logged Products
 * @returns All the Company logged Products count
 */
export const getCount = async (): Promise<number> => {
  const { data: productsCount } = await api.get<number>(`${PRODUCTS_URL}/count`)
  return productsCount
}

export const remove = async (productId: string): Promise<void> => {
  await api.delete<void>(`${PRODUCTS_URL}/${encodeURIComponent(productId)}`)
}

/**
 * Get Products CSV template
 */
export const getCsvTemplate = async (): Promise<ArrayBuffer> => {
  const { data } = await api.get<ArrayBuffer>(`${PRODUCTS_URL}/csv`, {
    responseType: 'arraybuffer',
  })
  return data
}
/**
 * Get all company Products with pagination and filter support
 * @returns All the Products that match
 */
export const getPaginatedProducts = async (
  { search, tags }: IProductFilters,
  pagination: PaginationParams
): Promise<Paginated<Product>> => {
  const searchParams = new URLSearchParams()

  if (search) {
    searchParams.append('search', search)
  }

  tags?.forEach(tag => {
    searchParams.append('tags[]', tag)
  })

  searchParams.append('limit', pagination.limit.toString())
  searchParams.append('offset', pagination.offset.toString())
  const { data } = await api.get<Paginated<Product>>(PRODUCTS_URL, { params: searchParams })
  return data
}
