/* eslint-disable no-console */
import { ACTIVITY_TYPE } from '../entity'
import { TaskActivity } from './format-audits-to-activity'
import { differenceInMinutes } from 'date-fns'

export const sortTaskActivities = (activities: TaskActivity[]) => {
  return activities.sort((a, b) => {
    const dataCreatedAtA = new Date(a.audit.createdAt)
    const dataCreatedAtB = new Date(b.audit.createdAt)
    // TASK_CREATED should be the first activity
    if (a.type === ACTIVITY_TYPE.TASK_CREATION) {
      return 1
    }
    if (b.type === ACTIVITY_TYPE.TASK_CREATION) {
      return -1
    }

    // If there is an activity type of TASK_UPDATED or VALIDATION_CREATED within the same minute, add first the TASK_UPDATED
    if (
      (a.type === ACTIVITY_TYPE.TASK_UPDATED && b.type === ACTIVITY_TYPE.VALIDATION_CREATED) ||
      (a.type === ACTIVITY_TYPE.VALIDATION_CREATED && b.type === ACTIVITY_TYPE.TASK_UPDATED)
    ) {
      if (differenceInMinutes(dataCreatedAtA, dataCreatedAtB) === 0) {
        if (a.type === ACTIVITY_TYPE.TASK_UPDATED) {
          return 1
        }
        if (b.type === ACTIVITY_TYPE.TASK_UPDATED) {
          return -1
        }
      }
    }

    // Sort by date
    if (dataCreatedAtA > dataCreatedAtB) {
      return -1
    }
    if (dataCreatedAtA < dataCreatedAtB) {
      return 1
    }

    return 0
  })
}
