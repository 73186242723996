import { getById, makePublic } from '@/features/documents'
import { Issue } from '@/features/issues'
import { getPublicUrlTaskFile as getPublicUrlFile } from '@/features/tasks'
import { TemplateEntryReferenceValue, TemplateEntryType } from '@/features/templates'
import { Entry, TableEntryColumn, TableEntryRow } from '../../interfaces'
import { Signature } from '@/features/signatures'

const prepareEntryReportingData = async (entry: Entry, signatures: Signature[] = []) => {
  if (entry.type === TemplateEntryType.REFERENCE) {
    return {
      ...entry,
      values: (entry.values as TemplateEntryReferenceValue[]).map(value => value.name || ''),
    }
  }
  if (entry.type === TemplateEntryType.SIGNATURE) {
    const newValues = await Promise.all(
      (entry.values as string[]).map(async value => {
        const signature = signatures.find(
          signature => signature.userId === value || signature.companyId === value
        )
        if (!signature) return null

        const response = await fetch(signature.assetLocationUrl)
        const responseSvg = await response.text()
        return responseSvg
      })
    )

    return {
      ...entry,
      values: newValues.filter((value): value is string => !!value),
    }
  }
  if (
    (entry.type !== TemplateEntryType.FILE && entry.type !== TemplateEntryType.DOCUMENT) ||
    !entry.values[0] ||
    entry.values[0] === ''
  ) {
    return entry
  }

  if (entry.type === TemplateEntryType.FILE) {
    const publicUrl = await getPublicUrlFile(entry.values[0] as string)
    return {
      ...entry,
      values: [publicUrl || ''],
    }
  }

  let document = await getById(entry.values[0] as string)
  if (!document) return entry
  if (!document.isPublic) {
    document = await makePublic(entry.values[0] as string)
  }

  return {
    ...entry,
    values: [document.publicUrl || '', document.title || ''],
  }
}

const prepareTableEntriesReportingData = ({
  entries,
  signatures = [],
}: {
  entries: Entry[]
  signatures: Signature[]
}) => {
  const promises = entries
    // Filter table entries
    .filter(({ type, blockId, enabled }) => {
      const filtered = type === TemplateEntryType.TABLE && enabled !== false
      if (!filtered) return false

      if (blockId) {
        const block = entries.find(block => block.id === blockId)

        if (block?.enabled === false) return false
      }

      return filtered
    })
    .map(
      async ({
        name,
        values,
      }): Promise<{ name: string; columns: string[]; rows: TableEntryRow[][] }> => {
        const columns: string[] = (values as TableEntryColumn[]).map(({ title }) => title)
        const rows = await Promise.all(
          (values as TableEntryColumn[]).map(
            async ({ rows }) =>
              await Promise.all(
                rows.map(async cell => {
                  if (cell.type === TemplateEntryType.SIGNATURE) {
                    const newValues = await Promise.all(
                      (cell.values as string[]).map(async value => {
                        const signature = signatures.find(
                          signature => signature.userId === value || signature.companyId === value
                        )
                        if (!signature) return null

                        const response = await fetch(signature.assetLocationUrl)
                        const responseSvg = await response.text()
                        return responseSvg
                      })
                    )

                    return {
                      ...cell,
                      values: newValues.filter((value): value is string => !!value),
                    }
                  }
                  if (
                    (cell.type !== TemplateEntryType.FILE &&
                      cell.type !== TemplateEntryType.DOCUMENT) ||
                    !cell.values[0] ||
                    cell.values[0] === ''
                  ) {
                    return cell
                  }

                  if (cell.type === TemplateEntryType.FILE) {
                    const publicUrl = await getPublicUrlFile(cell.values[0] as string)
                    return {
                      ...cell,
                      values: [publicUrl || ''],
                    }
                  }
                  let document = await getById(cell.values[0] as string)
                  if (!document) return cell
                  if (!document.isPublic) {
                    document = await makePublic(cell.values[0] as string)
                  }

                  return {
                    ...cell,
                    values: [document.publicUrl || '', document.title || ''],
                  }
                })
              )
          )
        )
        return {
          name,
          columns,
          rows,
        }
      }
    )

  return Promise.all(promises)
}

export const prepareEntriesReportingData = async ({
  entries,
  issues = [],
  issueMediaName,
  signatures = [],
}: {
  entries: Entry[]
  issueMediaName?: string
  issues?: Issue[]
  signatures?: Signature[]
}): Promise<{
  entries: (Entry & {
    issuesEntries?: Entry[]
  })[]
  tables: {
    name: string
    columns: string[]
    rows: TableEntryRow[][]
  }[]
  // /**
  //  * User signatures that appear in entries \
  //  * Key: user id \
  //  * Value: user signature
  //  */
  // userSignatures: Record<string, string>
}> => {
  const entriesDataPromises = entries
    // Filter to remove table entries
    .filter(({ type, enabled }) => type !== TemplateEntryType.TABLE && enabled !== false)
    .map(async entry => {
      if (entry.type === TemplateEntryType.ISSUE_REFERENCE && entry.values.length > 0) {
        const issue = issues.filter(issue => (entry.values as string[]).includes(issue._id))
        let media: {
          url: string
          mimeType: string
        }[] = []

        if (issue.length > 0 && issue[0].media) {
          media = issue[0].media
        }

        const issuesEntries = await Promise.all(
          issues
            .filter(issue => (entry.values as string[]).includes(issue._id))
            .flatMap(issue => issue.entries)
            .filter(({ type, enabled }) => type !== TemplateEntryType.TABLE && enabled !== false)
            .map(value => prepareEntryReportingData(value))
        )

        const mediaEntries = media.map(({ url, mimeType }) => ({
          id: 'media',
          type: TemplateEntryType.FILE,
          blockId: null,
          enabled: true,
          name: issueMediaName || 'Media',
          mandatory: false,
          templateId: null,
          mimeType,
          documentSettings: {},
          values: [url],
          options: [],
        }))

        const entriesCombined = [...issuesEntries, ...mediaEntries]

        return {
          ...entry,
          issuesEntries: entriesCombined,
        }
      }
      return prepareEntryReportingData(entry, signatures)
    })

  const entriesData = await Promise.all(entriesDataPromises)
  const tableEntriesData = await prepareTableEntriesReportingData({
    entries: [...entries, ...issues.flatMap(issue => issue.entries)],
    signatures,
  })

  return {
    entries: entriesData,
    tables: tableEntriesData,
  }
}
