import { DayCell } from './DayCell'

const PAD_ROWS = 7

export const PadRows = ({
  currentRows,
  monthDays,
  year,
  month,
}: {
  currentRows: number
  year: number
  month: number
  monthDays: number
}) => {
  const padRows = currentRows >= PAD_ROWS ? 0 : PAD_ROWS - currentRows

  return (
    <>
      {Array.from({ length: padRows }, (_, index) => (
        <div key={`pad-${index}`} className='flex gap-1/2'>
          <div>
            <div className='w-64 h-12 mt-1 mr-4' />
          </div>
          <div
            className='flex-grow w-full grid gap-1/2'
            style={{ gridTemplateColumns: `repeat(${monthDays}, minmax(0, 1fr))` }}
          >
            {Array.from({ length: monthDays }, (_, dayIndex) => (
              <DayCell
                key={`cellPad-${index}-${dayIndex}`}
                tasks={[]}
                date={new Date(year, month, dayIndex + 1)}
              />
            ))}
          </div>
        </div>
      ))}
    </>
  )
}
