import useBreakpoint from '@/hooks/use-breakpoints'
import { SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useAnalytics } from '../analytics'
import { useIsUserCompany } from '../auth'
import { WAITING_RENDER_PAGE } from './constants'
import { OnboardingContext, OnboardingContextStateValue } from './context'
import {
  useMarkOnboardingAsCompleted,
  useMarkOnboardingStepAsCompleted,
  useMyOnboarding,
  useOnboardingSteps,
} from './hooks'

export const Onboarding = ({ children }: { children: ReactNode }) => {
  const analytics = useAnalytics()
  const userIsCompany = useIsUserCompany()

  const [onboardingState, setOnboardingState] = useState<
    Pick<OnboardingContextStateValue, 'stepIndex' | 'run' | 'active' | 'inTutorial'>
  >({
    stepIndex: 0,
    run: false,
    active: false,
    inTutorial: false,
  })

  const { myOnboarding, isLoading } = useMyOnboarding()
  const markOnboardingAsCompleted = useMarkOnboardingAsCompleted(myOnboarding?._id)
  const markOnboardingStepAsCompleted = useMarkOnboardingStepAsCompleted(myOnboarding?._id)
  const steps = useOnboardingSteps({
    myOnboarding,
    isInTutorial: onboardingState.inTutorial,
    next(stepId?: string) {
      setOnboardingState(previous => ({
        ...previous,
        run: false,
        stepIndex: previous.stepIndex + 1,
      }))
      if (stepId) {
        markOnboardingStepAsCompleted({ stepId })
      }
    },
    nextLast(stepId?: string) {
      setOnboardingState(previous => ({
        ...previous,
        stepIndex: previous.stepIndex + 1,
      }))
      if (stepId) {
        markOnboardingStepAsCompleted({ stepId })
      }
    },
    back() {
      setOnboardingState(previous => ({
        ...previous,
        run: false,
        stepIndex: previous.stepIndex - 1,
      }))
    },
    close() {
      setOnboardingState(previous => ({
        ...previous,
        run: false,
        active: false,
      }))
      markOnboardingAsCompleted()
    },
    skip() {
      setOnboardingState(previous => ({
        ...previous,
        stepIndex: 0,
        run: false,
        active: false,
      }))
    },
    complete() {
      setOnboardingState(previous => ({
        ...previous,
        run: false,
        active: false,
        inTutorial: false,
      }))
      markOnboardingAsCompleted()
    },
  })

  const breakpoint = useBreakpoint()
  const firstLoad = useRef(false)

  useEffect(() => {
    if (!myOnboarding) return

    const allAcceptedStepKeys = steps.map(({ key }) => key)
    const myOnboardingAcceptedSteps = myOnboarding.steps.filter(step =>
      allAcceptedStepKeys.includes(step.name)
    )

    const shouldBeActivated =
      firstLoad.current === false &&
      userIsCompany &&
      breakpoint === SIZE.EXTRA_LARGE &&
      myOnboardingAcceptedSteps.length > 0

    if (shouldBeActivated) {
      let lastVisitedStepIndex = 0

      if (myOnboardingAcceptedSteps.length > 0 && myOnboardingAcceptedSteps.length < steps.length) {
        const lastMyOnboardingStepIndex = myOnboardingAcceptedSteps[0].name === steps[0].key ? 1 : 0 // prevent select home
        const lastMyOnboardingStep = myOnboardingAcceptedSteps[lastMyOnboardingStepIndex]
        lastVisitedStepIndex = steps.findIndex(step => lastMyOnboardingStep?.name === step.key)
      }
      setOnboardingState(previous => ({
        ...previous,
        active: true,
        stepIndex: lastVisitedStepIndex,
      }))
      firstLoad.current = true
    } else if (breakpoint !== SIZE.EXTRA_LARGE) {
      setOnboardingState(previous => ({
        ...previous,
        active: false,
        run: false,
      }))
    }
  }, [breakpoint, myOnboarding, steps, userIsCompany])

  const value = useMemo(() => {
    return {
      ...onboardingState,
      isLoading,
      steps,
      startStep() {
        setTimeout(() => {
          setOnboardingState(previous => {
            return {
              ...previous,
              run: true,
            }
          })
        }, WAITING_RENDER_PAGE)
      },
      skipDemo() {
        setOnboardingState(previous => ({
          ...previous,
          run: false,
          active: false,
          inTutorial: false,
        }))
        analytics.track('ACTION_CLICK_CANCEL_DEMO')
      },

      startTutorial() {
        setOnboardingState(previous => ({
          ...previous,
          stepIndex: 0,
          active: true,
          inTutorial: true,
        }))
      },
    }
  }, [steps, onboardingState, isLoading, analytics])

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>
}
