/* eslint-disable @typescript-eslint/no-explicit-any */
import { handleCatchLazyImportPage } from '@/utils/lazy-import'
import { lazy } from 'react'

const LoginPage = lazy(() =>
  import('@/pages/Login/LoginPage')
    .then(module => ({ default: module.LoginPage }))
    .catch(() => {
      window.location.reload()
      return { default: () => null as unknown as JSX.Element }
    })
)

const RegisterPage = lazy(() =>
  import('@/pages/Register/RegisterPage')
    .then(module => ({ default: module.RegisterPage }))
    .catch(() => {
      window.location.reload()
      return { default: () => null as unknown as JSX.Element }
    })
)
const PasswordRecoveryPage = lazy(() =>
  import('@/pages/PasswordRecovery/PasswordRecoveryPage')
    .then(module => ({
      default: module.PasswordRecoveryPage,
    }))
    .catch(() => {
      window.location.reload()
      return { default: () => null as unknown as JSX.Element }
    })
)
const PasswordResetPage = lazy(() =>
  import('@/pages/PasswordRecovery/PasswordResetPage')
    .then(module => ({
      default: module.PasswordResetPage,
    }))
    .catch(() => {
      window.location.reload()
      return { default: () => null as unknown as JSX.Element }
    })
)

const Dashboard = lazy(() =>
  import('@/features/layout')
    .then(({ Layout }) => ({ default: Layout }))
    .catch(() => {
      window.location.reload()
      return { default: () => null as unknown as JSX.Element }
    })
)

const ComplianceFillingOutPage = lazy(() =>
  import('@/pages/compliances/ComplianceFillingPage').catch(() => {
    window.location.reload()
    return { default: () => null as unknown as JSX.Element }
  })
)

const UploadRequestPage = lazy(() =>
  import('@/pages/documents/UploadRequestPage')
    .then(module => ({ default: module.UploadRequestPage } as any))
    .catch(handleCatchLazyImportPage)
)

const DocumentSharingPage = lazy(() =>
  import('@/pages/documents/DocumentSharingPage')
    .then(module => ({ default: module.DocumentSharingPage } as any))
    .catch(handleCatchLazyImportPage)
)

const ExportDataDownloadPage = lazy(() =>
  import('@/pages/Public/ExportDataDownload')
    .then(module => ({ default: module.ExportDataDownloadPage } as any))
    .catch(handleCatchLazyImportPage)
)

const PublicPricingPlansPage = lazy(() =>
  import('@/pages/plans/PricingPlansPage')
    .then(module => ({ default: module.PricingPlansPage } as any))
    .catch(handleCatchLazyImportPage)
)

/**
 * Define all the root routes
 */
export const rootRoutes = [
  {
    path: '/password/recovery',
    component: PasswordRecoveryPage,
  },
  {
    path: '/password/reset',
    component: PasswordResetPage,
  },
  {
    path: '/register',
    component: RegisterPage,
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/public/compliances/:publicId',
    component: ComplianceFillingOutPage,
  },
  {
    path: '/upload-request/:publicId',
    component: UploadRequestPage,
  },
  {
    path: '/document-sharing/:publicId',
    component: DocumentSharingPage,
  },
  {
    path: '/export-data-download',
    component: ExportDataDownloadPage,
  },
  {
    path: '/pricing-plans',
    component: PublicPricingPlansPage,
  },
  {
    path: '/',
    component: Dashboard,
    private: true,
  },
]
