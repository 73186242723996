import { useField } from 'formik'
import { EntryFieldProps } from './interfaces'
import { generateEntryFieldName } from './utils'
import { useEntryFieldComponentByType, useEntryFieldWrapper } from './EntryFieldExtensionProvider'
import { memo } from 'react'

export const EntryField = memo((props: EntryFieldProps) => {
  const { index, prefixName } = props
  const [{ value: type }] = useField(`${generateEntryFieldName(index, prefixName)}.type`)

  const EntryComponent = useEntryFieldComponentByType(type)

  const WrapperComponent = useEntryFieldWrapper()

  if (WrapperComponent && EntryComponent) {
    return <WrapperComponent componentProps={props} TargetComponent={EntryComponent} />
  }

  return EntryComponent ? <EntryComponent {...props} /> : null
})
