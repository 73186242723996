import { Step } from 'react-joyride'

export interface OnboardingStep extends Step {
  _id?: string
  key: string
  path?: string
  disableSkipDemoButton?: boolean
  initial?: boolean
  /**
   * Important for steps that point to a menu item in the sidebar, if your step is in page, you can avoid it.
   */
  section?: string
  /**
   * Important to filter the steps by the user addons.
   */
  addon?: string
  experimental?: boolean
}

export const onboardingSteps: Omit<OnboardingStep, 'content'>[] = [
  {
    key: 'home',
    path: '/home',
    placement: 'center',
    target: 'body',
    disableSkipDemoButton: true,
    initial: true,
  },
  {
    key: 'stakeholders',
    path: '/stakeholders',
    target: '.onboarding-stakeholders',
    placement: 'right',
    section: 'production',
  },
  {
    key: 'stakeholder-products',
    path: '/stakeholder-products',
    target: '.onboarding-stakeholder-products',
    placement: 'right',
    section: 'production',
  },

  {
    key: 'stakeholder-products-list',
    path: '/stakeholder-products',
    target: '.onboarding-stakeholder-products-list',
    placement: 'bottom',
    section: 'production',
  },
  {
    key: 'stakeholder-products-manage',
    path: '/stakeholder-products',
    target: '.onboarding-stakeholder-products-manage',
    placement: 'top',
    section: 'production',
  },
  {
    key: 'products',
    path: '/products',
    target: '.onboarding-products',
    placement: 'right',
    section: 'production',
  },
  {
    key: 'products-list',
    path: '/products',
    target: '.onboarding-products-list',
    placement: 'bottom',
    section: 'production',
  },
  {
    key: 'products-manage',
    path: '/products',
    target: '.onboarding-products-manage',
    placement: 'top',
    section: 'production',
  },
  {
    key: 'metadata-templates',
    path: '/metadata-templates',
    target: '.onboarding-metadata-templates',
    placement: 'right',
    section: 'production',
  },
  {
    key: 'trus',
    path: '/trus',
    target: '.onboarding-trus',
    placement: 'right',
    section: 'production',
  },

  {
    key: 'shipments',
    path: '/shipments',
    target: '.onboarding-shipments',
    placement: 'right',
    section: 'production',
  },

  {
    key: 'traceability',
    path: '/traceability',
    target: '.onboarding-traceability',
    placement: 'right',
    section: 'transparency',
    addon: 'traceability',
  },

  {
    key: 'qr-codes',
    path: '/transparency/qr-codes',
    target: '.onboarding-qr-codes',
    placement: 'right',
    section: 'transparency',
    addon: 'transparency',
  },
  {
    key: 'reports',
    path: '/reports/create',
    target: '.onboarding-reports',
    placement: 'right',
    section: 'digitalization',
  },
  {
    key: 'reports-traceability',
    path: '/reports/create',
    target: '.onboarding-reports-selector',
    placement: 'left',
    section: 'digitalization',
  },
  {
    key: 'reports-delivery-note',
    path: '/reports/create',
    target: '.onboarding-reports-selector',
    placement: 'left',
    section: 'digitalization',
  },

  {
    key: 'documents',
    path: '/documents',
    target: '.onboarding-documents',
    placement: 'right',
    section: 'digitalization',
  },

  {
    key: 'profile',
    path: '/profile',
    target: '.onboarding-profile',
    placement: 'top',
  },
  {
    key: 'profile-extensions',
    path: '/profile?tab=extensions',
    target: '.onboarding-profile-extensions',
    placement: 'bottom',
  },

  {
    key: 'profile-users',
    path: '/profile?tab=users',
    target: '.onboarding-profile-users',
    placement: 'bottom',
    addon: 'users',
  },

  {
    key: 'departments',
    path: '/departments',
    target: '.onboarding-departments',
    placement: 'right',
    section: 'digitalization',
    addon: 'departments',
  },

  {
    key: 'forms',
    path: '/forms',
    target: '.onboarding-forms',
    placement: 'right',
    section: 'digitalization',
    addon: 'registries',
  },
  {
    key: 'tasks',
    path: '/tasks',
    target: '.onboarding-tasks',
    placement: 'right',
    section: 'digitalization',
    addon: 'registries',
  },

  {
    key: 'my-dashboards',
    path: '/dashboards',
    target: '.onboarding-my-dashboards',
    placement: 'right',
    section: 'digitalization',
    addon: 'registries',
  },

  {
    key: 'automations',
    path: '/automations',
    target: '.onboarding-automations',
    placement: 'right',
    section: 'digitalization',
    addon: 'registries',
  },

  // finish onboarding
  {
    key: 'last',
    target: 'body',
    placement: 'center',
  },
]
