import { downloadCSV, tableToCSV } from '@/helpers/csv'
import { BulkError, ErrorCodes } from '@blockchain-traceability-sl/custom-error-codes'
import Big from 'big.js'
import { StockRelation } from '../stockRelations'

export const getReceptionAvailableStock = (
  receptionId: string,
  receptionQuantity: string,
  stockRelations: StockRelation[]
): string => {
  return stockRelations
    .filter(stockRelation => stockRelation.parent === receptionId)
    .map(({ quantity }) => quantity)
    .reduce((previous, current) => {
      return previous.minus(current || 0)
    }, new Big(receptionQuantity))
    .toString()
}

export const downloadReceptionBulkErrorReport = (bulkError: BulkError) => {
  downloadCSV(
    tableToCSV(
      ['index', 'status', 'entity', 'reason'],
      bulkError.errors.map(({ index, error }) => [
        String(index),
        error.code === ErrorCodes.ALREADY_EXISTS ? 'OK' : 'FAIL',
        error.entity,
        error.message,
      ])
    ),
    'error-report'
  )
}
