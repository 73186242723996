import { useToggle } from '@/hooks/use-toggle'
import { ReactNode, createContext } from 'react'

export interface NotificationPanelContextState {
  showPanel: boolean
  toggleShowPanel: (nextValue?: unknown) => void
}

export const NotificationPanelContext = createContext<NotificationPanelContextState | null>(null)
NotificationPanelContext.displayName = 'NotificationPanelContext'

export const NotificationPanelProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [showPanel, toggleShowPanel] = useToggle(false)

  return (
    <NotificationPanelContext.Provider value={{ showPanel, toggleShowPanel }}>
      {children}
    </NotificationPanelContext.Provider>
  )
}
