import { TRU } from '@/features/trus'
import { Reception } from '@/features/receptions'

export enum OriginType {
  TRU = 'tru',
  RECEPTION = 'reception',
}

export type Origin =
  | ({ originType: OriginType.TRU; stock?: number } & TRU)
  | ({ originType: OriginType.RECEPTION; stock?: number } & Reception)
