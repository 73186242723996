import { Stat } from '@blockchain-traceability-sl/tailwind-components'
import { useTranslation } from 'react-i18next'
import { useProductsCount } from '../hooks'
import { OfficeBuildingIcon } from '@heroicons/react/outline'
import { useAnalytics } from '@/features/analytics'
import { useHistory } from 'react-router-dom'

export const StatProductCount = () => {
  const { t } = useTranslation('nsHomePage', { keyPrefix: 'stats.products' })
  const { productsCount } = useProductsCount()
  const analytics = useAnalytics()
  const history = useHistory()

  return (
    <Stat
      name={t('name').toString()}
      stat={productsCount}
      IconComponent={OfficeBuildingIcon}
      actionText={t('action').toString()}
      onActionClick={() => {
        analytics.track('ACTION_CLICK_TOP_ACTIVITY', {
          Source: 'Products',
        })
        history.push('/products')
      }}
    />
  )
}
