import { GiftIcon } from '@heroicons/react/outline'
import { useGoToSubscriptionCustomerPortal, useSubscriptionInfo } from '../hooks'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

export const SubscriptionTrialInfo = () => {
  const { t } = useTranslation('nsPricingBanner')
  const { subscriptionInfo } = useSubscriptionInfo()
  const { goToSubscriptionCustomerPortal } = useGoToSubscriptionCustomerPortal()

  if (subscriptionInfo?.isTrialPeriod) {
    return (
      <div className='cursor-pointer' onClick={() => goToSubscriptionCustomerPortal()}>
        <div className='bg-white p-2 rounded-lg shadow-lg sm:p-3'>
          <div className='flex items-center justify-between flex-wrap'>
            <div className='w-0 flex-1 flex items-center'>
              <span className='flex p-2 rounded-lg bg-blue-600'>
                <GiftIcon className='h-5 w-5 text-white' />
              </span>
              <p className='ml-3 font-semibold text-base leading-normal space-x-1'>
                <span className='text-gray-800'>{t('billing.trial')}</span>
                <span className='text-blue-600'>
                  {dayjs(subscriptionInfo.renewalDate).fromNow(true)}
                </span>
              </p>
            </div>
            <div className='order-2 flex-shrink-0 sm:order-3'>
              <div className='h-6 w-6 sm:h-0 sm:w-0 text-white' aria-hidden='true' />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null
}
