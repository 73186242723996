import { useMyProfile } from '@/features/auth'
import { useReferrer } from '@/hooks/use-referrer'
import classNames from 'classnames'
import { ReactNode, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useMySignature, useSignatureByUserIds } from '../hooks'

export interface SignInputFieldProps {
  id: string
  label?: ReactNode
  onChange: (signatureUserIds: string[]) => void
  values: string[]
  error?: boolean
  disabled?: boolean
  required?: boolean
  isLittlePreview?: boolean
}

export const SignInputField = ({
  id,
  label,
  disabled,
  values,
  required,
  error,
  onChange,
  isLittlePreview,
}: SignInputFieldProps) => {
  const { t } = useTranslation('nsTemplate', { keyPrefix: 'entries.signature' })
  const { profile } = useMyProfile()

  const location = useLocation()

  const shouldFill = values.length === 0 && required

  const { signature, isFetched } = useMySignature({ enabled: !disabled })
  const { signatures } = useSignatureByUserIds(values, { enabled: !disabled })

  const { generateReferrerUrl } = useReferrer()

  const Label = () =>
    label === undefined ? null : (
      <span
        className={classNames('block text-sm font-medium mb-4', {
          'text-gray-400': disabled,
          'text-gray-700': !disabled && !error,
          'text-red-500': error,
        })}
      >
        {label}
      </span>
    )

  useEffect(() => {
    // Add user signature if has on start
    if (isFetched && signature && profile && !values.includes(profile.user.id) && shouldFill) {
      onChange([...values, profile.user.id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature, isFetched])

  const handleDelete = () => {
    onChange(values.filter(item => item !== profile?.user.id))
  }

  const handleAdd = () => {
    if (profile) onChange([profile.user.id])
  }

  if (disabled) {
    return <Label />
  }

  const isCurrentUserSignature =
    signatures.length !== 0 && profile && values.includes(profile.user.id)
  const userHasSignature = !!signature

  return (
    <div id={id}>
      <Label />

      <div className='space-y-4'>
        {signatures.map(signature => (
          <img
            key={signature._id}
            src={signature.assetLocationUrl}
            alt='signature'
            className={classNames({ 'w-24': isLittlePreview })}
          />
        ))}

        {/* If has signature and is in values */}
        {isCurrentUserSignature ? (
          <button className='text-sm font-medium text-red-600 underline' onClick={handleDelete}>
            {t('delete')}
          </button>
        ) : // If has signature but not in values
        userHasSignature ? (
          <>
            <button className='text-sm font-medium text-blue-600 underline' onClick={handleAdd}>
              {t(values.length !== 0 ? 'replace' : 'add')}
            </button>
          </>
        ) : (
          // If has no signature
          <p className='text-sm font-normal text-gray-700'>
            <Trans
              t={t}
              i18nKey='description'
              // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
              components={{
                a: (
                  <Link
                    to={generateReferrerUrl({
                      url: '/profile',
                      fieldName: 'signature',
                      redirectUrl: `${location.pathname}?${new URLSearchParams(
                        `${location.search}&step=1`
                      )}`,
                    })}
                    className='underline cursor-pointer'
                  />
                ),
              }}
            />
          </p>
        )}
      </div>
    </div>
  )
}
