import { AuditOperation, useComplianceAuditsById } from '@/features/audits'
import { useComplianceById } from '../../hooks'
import { useMemo } from 'react'
import { getAuditValidationState } from './utils'
import { ComplianceValidationStatus } from '../../interfaces'

export const useComplianceActivityAudits = (complianceId: string) => {
  const { compliance, isLoading: isComplianceLoading } = useComplianceById(complianceId, {
    enabled: !!complianceId,
  })

  const { audits, isLoading: isAuditsLoading } = useComplianceAuditsById(complianceId, {
    enabled: !!complianceId,
  })

  const { audits: parentComplianceAudits, isLoading: isParentComplianceAuditsLoading } =
    useComplianceAuditsById(compliance?.parentComplianceId || '', {
      enabled: !!compliance?.parentComplianceId,
      // Select only creation audits and audits with non-approved validation state
      select: audits =>
        audits.filter(
          audit =>
            audit.operation === AuditOperation.CREATE ||
            getAuditValidationState(audit)?.status !== ComplianceValidationStatus.APPROVED
        ),
    })

  const isLoading = isComplianceLoading || isAuditsLoading || isParentComplianceAuditsLoading

  return {
    audits: useMemo(() => [...parentComplianceAudits, ...audits], [parentComplianceAudits, audits]),
    isLoading,
  }
}
