export const getSeatPriceAmount = (
  planName: string,
  seatPlans?: { name: string; amount: number }[]
) => {
  const seatPlan = seatPlans?.find(seatPlan => seatPlan.name === planName)
  return seatPlan ? seatPlan.amount / 100 : 0
}

export const calculateTotalAmount = ({
  primaryPlanAmount = 0,
  seatsAmount = 0,
  seatsCount,
  modulesAmount,
}: {
  primaryPlanAmount?: number
  seatsAmount?: number
  seatsCount?: number
  modulesAmount: number[]
}) => {
  const seatsTotalAmount = seatsAmount ? seatsAmount * (seatsCount || 0) : 0

  const modulesTotalAmount = modulesAmount.reduce((total, amount) => total + amount, 0)

  return (primaryPlanAmount + seatsTotalAmount + modulesTotalAmount) / 100
}
