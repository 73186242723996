import { api } from '@/app/api'
import mime from 'mime'

/**
 * Allowed MIME types
 */
export type DownloaderMIMEType =
  | 'application/pdf'
  | 'text/csv'
  | 'image/png'
  | 'image/jpeg'
  | 'image/svg+xml'
  | 'text/json'
  | 'application/octet-stream'
  | 'application/zip'

/**
 *
 */
export const downloader = (
  content: BlobPart | BlobPart[],
  mimeType: DownloaderMIMEType,
  filename: string,
  options?: {
    raw?: boolean
  }
) => {
  // Create the file
  const dataURL = options?.raw
    ? content.toString()
    : typeof content === 'string'
    ? `data:${mimeType};charset=utf-8,${encodeURIComponent(content)}`
    : window.URL.createObjectURL(
        new Blob(Array.isArray(content) ? content : [content], { type: mimeType })
      )

  // Create Download link
  const downloadLink = document.createElement('a')

  const extension = mime.getExtension(mimeType)

  // File name
  downloadLink.download = `${filename}${
    mimeType !== 'application/octet-stream' && extension ? `.${extension}` : ''
  }`

  // Create a link to the file
  downloadLink.href = dataURL

  // Hide download link
  downloadLink.style.display = 'none'

  // Add the link to DOM
  document.body.appendChild(downloadLink)

  // Click download link
  downloadLink.click()

  // Remove link
  downloadLink.remove()
}

export const downloaderByUrl = async (url: string, filename: string, mimeType?: string) => {
  // Create Download link
  const downloadLink = document.createElement('a')

  let downloadName = filename

  if (mimeType) {
    const extension = mime.getExtension(mimeType)
    downloadName = `${filename}${
      mimeType !== 'application/octet-stream' && extension ? `.${extension}` : ''
    }`
  }

  // File name
  downloadLink.download = downloadName

  const response = await api.get<Blob>(url, {
    responseType: 'blob',
  })

  // Create a link to the file
  downloadLink.href = window.URL.createObjectURL(response.data)

  downloadLink.target = '_blank'

  // Hide download link
  downloadLink.style.display = 'none'

  // Add the link to DOM
  document.body.appendChild(downloadLink)

  // Click download link
  downloadLink.click()

  // Remove link
  downloadLink.remove()
}
