import { DEFAULT_PAGE_SIZE, Paginated } from '@/hooks/use-pagination'
import {
  InfiniteData,
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import * as NotifyService from '@/services/notify.service'
import {
  CustomReportSendByEmailRequest,
  CustomReportTemplate,
  CustomReportTemplateSourceType,
  CustomReportTemplateType,
} from './interfaces'
import { AxiosError } from 'axios'
import { mergeInfiniteQueryOptions } from '@/utils/merge-query-options'
import {
  generateCustomReport,
  generateDeliveryNoteReport,
  generateIssuesSummaryReport,
  generateStockReport,
  generateTasksSummaryReport,
  generateTraceabilityReport,
  getCustomReportTemplateById,
  getPaginatedCustomReportTemplates,
  sendCustomReportByEmail,
} from './service'
import { useEffect, useMemo, useState } from 'react'
import {
  ComplianceEntityType,
  ComplianceReportType,
  ComplianceStatus,
  useComplianceById,
  useCompliances,
  useFetchComplianceById,
  useGenerateComplianceReport,
} from '@/features/compliances'
import {
  useStakeHolderTaskRelationsByIds,
  useTaskById,
  useTasksByStakeHolderId,
} from '@/features/tasks'
import { StakeHolder, useFetchStakeHolderById, useStakeHolderById } from '@/features/stakeHolders'
import {
  DocumentCreationTriggerType,
  DocumentType,
  ROOT_FOLDER_ID,
  SpecialFolderType,
  createFile,
  getByFilters as getDocumentsByFilters,
  updateDocumentFile,
  upsertFolder,
  useMakeDocumentPublic,
} from '@/features/documents'
import { uploadFile, useUploadFile } from '@/features/files'
import { getCustomReportFilename } from './utils'
import { DOCUMENT_FOLDER_COMPLIANCE_SPECIAL_TYPE_TITLE } from '@/pages/documents/DocumentsView/constants'
import { useTranslation } from 'react-i18next'
import { customErrorToTrack, useAnalytics } from '../analytics'
import { useMyProfile } from '../auth'
import { getComplianceAutomaticReportName } from '../compliances/utils/generate-report-name'
import { useGeneratePdfFromDefinition } from '../pdf'
import { downloader } from '@/helpers/downloader'
import {
  removeNegativeLevelsFromTraceability,
  useTraceabilities,
  useTraceabilitiesDocuments,
} from '../traceabilities'
import { useDebounceState } from '@/hooks/use-debounce-state'
export const QUERY_CUSTOM_REPORT_TEMPLATES_KEY = 'custom-report-templates'

export const usePaginatedCustomReportTemplates = (
  filters?: { search?: string },
  overrideOptions?: Omit<
    UseInfiniteQueryOptions<Paginated<CustomReportTemplate>, AxiosError>,
    'queryKey' | 'queryFn'
  >
) => {
  const queryClient = useQueryClient()
  const options = mergeInfiniteQueryOptions(
    {
      getNextPageParam: ({ pageInfo }) => pageInfo?.nextPage || undefined,
      onSuccess(data: InfiniteData<Paginated<CustomReportTemplate>>) {
        overrideOptions?.onSuccess?.(data)
        /**
         * We need to update the cache for each item in the page
         */
        data.pages.forEach(page => {
          page.items.forEach(customReportTemplate => {
            queryClient.setQueryData(
              [QUERY_CUSTOM_REPORT_TEMPLATES_KEY, customReportTemplate._id],
              customReportTemplate
            )
          })
        })
      },
    },
    overrideOptions
  )

  const { data, ...rest } = useInfiniteQuery(
    [QUERY_CUSTOM_REPORT_TEMPLATES_KEY, { isInfiniteQuery: true }],
    ({ pageParam }) =>
      getPaginatedCustomReportTemplates({
        ...filters,
        pagination: pageParam || { limit: DEFAULT_PAGE_SIZE, offset: 0 },
      }),
    options
  )
  const customReportTemplates = useMemo(
    () => data?.pages.flatMap(page => page.items).flat() || [],
    [data?.pages]
  )

  const totalCount = data?.pages[data?.pages.length - 1]?.totalCount || 0

  return {
    ...rest,
    customReportTemplates,
    totalCount,
  }
}

export const useCustomReportTemplateById = (
  id: string,
  options?: Omit<
    UseQueryOptions<CustomReportTemplate | undefined, AxiosError>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_CUSTOM_REPORT_TEMPLATES_KEY, id],
    () => getCustomReportTemplateById(id),
    options
  )

  return { ...rest, customReportTemplate: data }
}
export const useCustomReportSources = (
  customReportTemplate?: CustomReportTemplate,
  { stakeHolderId }: { stakeHolderId?: string } = {},
  { enabled }: { enabled: boolean } = { enabled: true }
) => {
  const { compliances, isLoading: isCompliancesLoading } = useCompliances(
    {
      templateIds: customReportTemplate ? [customReportTemplate.source.templateId] : [],
      stakeHolderIds: stakeHolderId ? [stakeHolderId] : [],
      status: [ComplianceStatus.ACCEPTED],
    },
    {
      enabled:
        enabled && customReportTemplate?.source.type === CustomReportTemplateSourceType.COMPLIANCE,
    }
  )

  const { tasks, isLoading: isTaskLoading } = useTasksByStakeHolderId(
    stakeHolderId || '',
    {
      formId: customReportTemplate ? [customReportTemplate.source.templateId] : [],
    },
    {
      enabled: enabled && customReportTemplate?.source.type === CustomReportTemplateSourceType.TASK,
    }
  )

  const isLoading = isCompliancesLoading || isTaskLoading

  const sources = useMemo(() => {
    if (!customReportTemplate) {
      return []
    }
    switch (customReportTemplate.source.type) {
      case CustomReportTemplateSourceType.COMPLIANCE:
        return compliances.map(compliance => ({
          id: compliance._id,
          name: compliance.templateName,
          createdAt: compliance.createdAt,
        }))
      case CustomReportTemplateSourceType.TASK:
        return tasks.map(task => ({
          id: task._id,
          name: task.formName,
          createdAt: task.createdAt,
        }))
      default:
        return []
    }
  }, [customReportTemplate, compliances, tasks])

  return {
    isLoading,
    sources,
  }
}

export const useCustomReportSource = (
  customReportTemplate?: CustomReportTemplate,
  sourceId?: string | null,
  { enabled }: { enabled: boolean } = { enabled: true }
) => {
  const { compliance, isLoading: isComplianceLoading } = useComplianceById(sourceId || '', {
    enabled:
      enabled && customReportTemplate?.source.type === CustomReportTemplateSourceType.COMPLIANCE,
  })
  const { task, isLoading: isTaskLoading } = useTaskById(sourceId || '', {
    enabled: enabled && customReportTemplate?.source.type === CustomReportTemplateSourceType.TASK,
  })
  const { stakeHolderTaskRelations, isLoading: stakeholderTaskRelationsIsLoading } =
    useStakeHolderTaskRelationsByIds([task?._id || ''], {
      enabled: enabled && customReportTemplate?.source.type === CustomReportTemplateSourceType.TASK,
    })
  const source = useMemo(() => {
    if (!customReportTemplate) {
      return undefined
    }

    if (
      customReportTemplate.source.type === CustomReportTemplateSourceType.COMPLIANCE &&
      !!compliance
    ) {
      return {
        id: compliance._id,
        name: compliance.templateName,
        relations: {
          stakeHolderId: compliance.compliantStakeHolder.id,
        },
        createdAt: compliance.createdAt,
      }
    }

    if (customReportTemplate.source.type === CustomReportTemplateSourceType.TASK && !!task) {
      return {
        id: task._id,
        name: task.formName,
        createdAt: task.createdAt,
        relations: {
          stakeHolderId: stakeHolderTaskRelations[0]?.stakeHolderId,
        },
      }
    }
    return undefined
  }, [customReportTemplate, compliance, task, stakeHolderTaskRelations])

  const { stakeHolder, isLoading: isStakeHolderLoading } = useStakeHolderById(
    source?.relations?.stakeHolderId || '',
    {
      enabled: enabled && !!source?.relations?.stakeHolderId,
    }
  )

  const isLoading =
    isComplianceLoading ||
    isTaskLoading ||
    isStakeHolderLoading ||
    stakeholderTaskRelationsIsLoading

  return {
    isLoading,
    source,
    relations: {
      stakeHolder,
    },
  }
}

export const useGenerateCustomReport = (
  customReportTemplateId?: string | null,
  sourceId?: string | null,
  { enabled }: { enabled: boolean } = { enabled: true }
) => {
  const { data, mutate, ...rest } = useMutation(
    async (params: { customReportTemplateId: string; sourceId: string }) => {
      const blob = await generateCustomReport(params)
      if (!blob) return undefined

      const dataUrl = await new Promise<string | undefined | null>(resolve => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result as string)
        reader.readAsDataURL(blob)
      })

      return {
        dataUrl,
        blob,
      }
    }
  )

  useEffect(() => {
    if (!customReportTemplateId || !sourceId || !enabled) {
      return
    }

    mutate({ customReportTemplateId, sourceId })
  }, [customReportTemplateId, mutate, sourceId, enabled])

  return {
    ...rest,
    customReport: data,
  }
}

interface UploadComplianceReportToDocumentManagerParams {
  complianceId: string
  reportType: ComplianceReportType
}

export const useUploadComplianceReportToDocumentManager = () => {
  const { uploadFileAsync: uploadDocumentFile } = useUploadFile()
  const { generateReportData } = useGenerateComplianceReport()
  const { generatePdfFromDefinitionAsync } = useGeneratePdfFromDefinition()
  const { t } = useTranslation('nsNotification')
  const { fetchComplianceById } = useFetchComplianceById()
  const { fetchStakeHolderById } = useFetchStakeHolderById()
  const { generateComplianceFoldersAsync } = useGenerateComplianceFolders()

  const { mutate, mutateAsync, ...rest } = useMutation(
    async ({ complianceId, reportType }: UploadComplianceReportToDocumentManagerParams) => {
      const compliance = await fetchComplianceById(complianceId)
      if (!compliance) return
      const stakeHolder = await fetchStakeHolderById(compliance.compliantStakeHolder.id)
      if (!stakeHolder) return

      // Generate compliance folders if they don't exist for the specific compliance
      const complianceFolders = await generateComplianceFoldersAsync({ complianceId })

      if (!complianceFolders) return

      const complianceReportName = getComplianceAutomaticReportName(compliance, t)

      const stakeholderName =
        compliance.compliantStakeHolder.contactDetails?.name || compliance.compliantStakeHolder.name

      if (!stakeholderName) return

      const { report } = await generateReportData(compliance, reportType)
      const reportBlob = await generatePdfFromDefinitionAsync({
        pdfDefinition: JSON.stringify(report),
        filename: complianceReportName,
      })

      if (!reportBlob) throw new Error('Failed to generate report')

      const taskReportUploadResponse = await uploadDocumentFile(
        new File([reportBlob.blob], complianceReportName)
      )
      const occurrences = await getDocumentsByFilters({
        title: complianceReportName,
        parentId: complianceFolders.complianceFormFolderId,
      })

      if (occurrences.length > 0) {
        // Update existing document
        return updateDocumentFile(occurrences[0]._id, {
          file: {
            path: taskReportUploadResponse.path,
            mimetype: 'application/pdf',
          },
          expireAt: compliance?.dueDate,
        })
      } else {
        // Create new document
        return createFile({
          parentId: complianceFolders.complianceFormFolderId,
          title: complianceReportName,
          file: {
            path: taskReportUploadResponse.path,
            mimetype: 'application/pdf',
          },
          stakeHolderIds: [stakeHolder._id],
          companyIds: [],
          productIds: [],
          receptionIds: [],
          sharedWith: [],
          stakeHolderProductIds: [],
          tags: stakeHolder.company.tags || [],
          truIds: [],
          expireAt: compliance?.dueDate,
          type: DocumentType.FILE,
          creationTrigger: {
            entityId: compliance._id,
            entityType: DocumentCreationTriggerType.COMPLIANCE,
          },
        })
      }
    }
  )

  return {
    ...rest,
    uploadComplianceReportToDocumentManager: mutate,
    uploadComplianceReportToDocumentManagerAsync: mutateAsync,
  }
}

interface UploadCustomReportToDocumentManagerParams {
  customReportTemplate: CustomReportTemplate
  templateTags?: string[]
  blob: Blob
  source: { id: string; name: string; createdAt: string }
  relations: { stakeHolder?: StakeHolder }
}

export const useUploadCustomComplianceReportToDocumentManager = () => {
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const { mutate, ...rest } = useMutation(
    async ({
      customReportTemplate,
      blob,
      relations,
      source,
      templateTags,
    }: UploadCustomReportToDocumentManagerParams) => {
      if (
        customReportTemplate.type !== CustomReportTemplateType.COMPLIANCE_REPORT ||
        !relations.stakeHolder
      ) {
        throw new Error('Invalid custom report template')
      }

      const compliancesFolderId = await upsertFolder({
        parentId: ROOT_FOLDER_ID,
        specialFolderType: SpecialFolderType.COMPLIANCES,
        title: DOCUMENT_FOLDER_COMPLIANCE_SPECIAL_TYPE_TITLE,
      })

      const stakeHolderFolderId = await upsertFolder({
        parentId: compliancesFolderId,
        stakeHolderIds: [relations.stakeHolder._id],
        title: relations.stakeHolder.company.name,
      })

      const complianceFormFolderId = await upsertFolder({
        parentId: stakeHolderFolderId,
        creationTrigger: {
          entityId: source.id,
          entityType: DocumentCreationTriggerType.COMPLIANCE,
          templateId: customReportTemplate.source.templateId,
        },
        title: source.name,
      })
      let documentTitle = getCustomReportFilename(customReportTemplate, { source, relations })

      const occurrences = await getDocumentsByFilters({
        search: documentTitle,
        parentId: complianceFormFolderId,
      })
      if (occurrences.length > 0) {
        documentTitle += ` (${occurrences.length})`
      }

      const fileResponse = await uploadFile(new File([blob], `${documentTitle}.pdf`))
      return createFile({
        parentId: complianceFormFolderId,
        title: documentTitle,
        file: {
          path: fileResponse.path,
          mimetype: blob.type,
        },
        stakeHolderIds: [relations.stakeHolder._id],
        companyIds: [],
        productIds: [],
        receptionIds: [],
        sharedWith: [],
        stakeHolderProductIds: [],
        tags: templateTags || [],
        truIds: [],
        type: DocumentType.FILE,
        creationTrigger: {
          entityId: source.id,
          entityType: DocumentCreationTriggerType.COMPLIANCE,
          templateId: customReportTemplate.source.templateId,
        },
      })
    },
    {
      onSuccess: (data, variables) => {
        NotifyService.success({
          title: t('customReport.upload.success.title'),
          description: t('customReport.upload.success.description', {
            providerName: variables.relations.stakeHolder?.company.name,
          }),
        })

        analytics.track('CUSTOMER_CREATE_DOCUMENT', {
          From: 'Custom Report',
        })
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_CREATE_DOCUMENT_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )
  return {
    ...rest,
    uploadCustomReportToDocumentManager: mutate,
  }
}

export interface GenerateComplianceFolders {
  complianceId: string
}

export const useGenerateComplianceFolders = () => {
  const { fetchComplianceById } = useFetchComplianceById()
  const { fetchStakeHolderById } = useFetchStakeHolderById()

  const { mutate, mutateAsync, ...rest } = useMutation(
    async ({ complianceId }: GenerateComplianceFolders) => {
      const compliance = await fetchComplianceById(complianceId)
      if (!compliance) return
      const stakeHolder = await fetchStakeHolderById(compliance.compliantStakeHolder.id)
      if (!stakeHolder) return

      const stakeholderName =
        compliance.compliantStakeHolder.contactDetails?.name || compliance.compliantStakeHolder.name

      if (!stakeholderName) return

      const compliancesFolderId = await upsertFolder({
        parentId: ROOT_FOLDER_ID,
        specialFolderType: SpecialFolderType.COMPLIANCES,
        title: DOCUMENT_FOLDER_COMPLIANCE_SPECIAL_TYPE_TITLE,
      })

      const stakeHolderFolderId = await upsertFolder({
        parentId: compliancesFolderId,
        title: stakeholderName,
        stakeHolderIds: [stakeHolder._id],
      })

      // Parent folder for the compliance form folder
      let complianceParentFolderId = stakeHolderFolderId
      if (compliance.compliantEntityType === ComplianceEntityType.STAKEHOLDER_PRODUCT) {
        const stakeHolderProductFolderId = await upsertFolder({
          parentId: stakeHolderFolderId,
          title: compliance.compliantStakeHolderProduct?.reference || stakeholderName,
        })
        complianceParentFolderId = stakeHolderProductFolderId
      }

      const complianceFormFolderId = await upsertFolder({
        parentId: complianceParentFolderId,
        creationTrigger: {
          entityId: compliance._id,
          entityType: DocumentCreationTriggerType.COMPLIANCE,
          templateId: compliance.templateId,
        },
        title: compliance.templateName,
      })

      return {
        complianceFormFolderId,
        complianceParentFolderId,
      }
    }
  )

  return {
    ...rest,
    generateComplianceFolders: mutate,
    generateComplianceFoldersAsync: mutateAsync,
  }
}

export const useSendCustomReportByEmail = () => {
  const { profile } = useMyProfile()
  const { t } = useTranslation('nsNotification')
  const { mutate, mutateAsync, ...rest } = useMutation(
    (
      params: Pick<
        CustomReportSendByEmailRequest,
        'to' | 'subject' | 'attachments' | 'contentRichText' | 'content'
      > & { stakeHolderName?: string }
    ) => {
      if (!profile) {
        throw new Error('Invalid profile')
      }
      return sendCustomReportByEmail({
        ...params,
        bcc: profile?.user.email ? [profile.user.email] : undefined,
        metadata: {
          companyName: profile?.company.name,
          userEmail: profile?.user.email,
        },
      })
    },
    {
      onSuccess(data, variables) {
        NotifyService.success({
          title: t('customReport.send.success.title'),
          description: t('customReport.send.success.description', {
            providerName: variables.stakeHolderName,
          }),
        })
      },
    }
  )

  return {
    ...rest,
    sendCustomReportByEmail: mutate,
    sendCustomReportByEmailAsync: mutateAsync,
  }
}

export const useDownloadReportTasksSummary = () => {
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()

  const { mutate, mutateAsync, ...rest } = useMutation(
    async (params: { startDate: Date; endDate: Date; filename: string }) => {
      const result = await generateTasksSummaryReport(params)
      downloader(result, 'application/pdf', params.filename)
    },
    {
      onSuccess: () => {
        analytics.track('CUSTOMER_GENERATE_TASK_REPORT', {
          From: 'Home',
        })
      },
      onError: () => {
        analytics.track('CUSTOMER_GENERATE_TASK_REPORT_ERROR', {
          From: 'Home',
        })
        NotifyService.error({
          title: t('taskSummaryReport.download.error.title'),
          description: t('taskSummaryReport.download.error.description'),
          actionText: t('taskSummaryReport.download.error.actionText'),
        })
      },
    }
  )

  return {
    ...rest,
    downloadTasksSummaryReport: mutate,
    downloadTasksSummaryReportAsync: mutateAsync,
  }
}

const useDeliveryNoteReport = (
  truIds: string[],
  addRawMaterial: boolean,
  stakeHolderId: string
) => {
  const [privateDocumentsAvailable, setPrivateDocumentsAvailable] = useState(false)
  const { makeDocumentPublicAsync } = useMakeDocumentPublic()
  const { traceabilities: bulkTraceabilities, isLoading: isLoadingTraceabilityData } =
    useTraceabilities(truIds)
  const traceabilities = useMemo(
    () =>
      Object.entries(bulkTraceabilities).map(([truId, bulkTraceability]) => ({
        ...bulkTraceability,
        trigger: { truId },
      })),
    [bulkTraceabilities]
  )
  const { documents: associatedDocuments, isLoading: isLoadingAssociatedDocuments } =
    useTraceabilitiesDocuments(
      traceabilities.map(traceability =>
        addRawMaterial ? traceability : removeNegativeLevelsFromTraceability(traceability)
      )
    )

  const documentToPublish = useMemo(() => {
    if (associatedDocuments.length === 0) {
      return []
    }
    return associatedDocuments.filter(
      document => document.type === DocumentType.FILE && !document.publicUrl
    )
  }, [associatedDocuments])

  const [documentsToPublishDebounced] = useDebounceState(documentToPublish)

  useEffect(() => {
    const publishDocuments = async () => {
      if (documentsToPublishDebounced.length > 0) {
        documentsToPublishDebounced.forEach(async document => {
          await makeDocumentPublicAsync(document._id)
        })
      } else {
        setPrivateDocumentsAvailable(true)
      }
    }

    publishDocuments()
  }, [documentsToPublishDebounced, makeDocumentPublicAsync])

  const { data, ...rest } = useQuery(
    ['deliverynotereport', truIds, addRawMaterial, stakeHolderId],
    async () => generateDeliveryNoteReport({ truIds, addRawMaterial, stakeHolderId }),
    { enabled: privateDocumentsAvailable }
  )

  const dataUrl = useMemo(() => {
    if (data) {
      return URL.createObjectURL(data)
    }
  }, [data])

  return {
    ...rest,
    isLoading:
      rest.isLoading ||
      isLoadingTraceabilityData ||
      isLoadingAssociatedDocuments ||
      !privateDocumentsAvailable,
    deliveryNoteReport: data,
    dataUrl,
  }
}

export const useDownloadReportDeliveryNote = (
  truIds: string[],
  addRawMaterial: boolean,
  stakeHolderId: string
) => {
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()

  const { deliveryNoteReport, dataUrl, isLoading } = useDeliveryNoteReport(
    truIds,
    addRawMaterial,
    stakeHolderId
  )

  const { mutate, mutateAsync, ...rest } = useMutation(
    async () => {
      if (!deliveryNoteReport) {
        return
      }
      downloader(deliveryNoteReport, 'application/pdf', 'delivery-note')
    },
    {
      onSuccess: () => {
        analytics.track('CUSTOMER_GENERATE_DELIVERY_NOTE', {
          From: 'Home',
        })
      },
      onError: () => {
        analytics.track('CUSTOMER_GENERATE_DELIVERY_NOTE_ERROR', {
          From: 'Home',
        })
        NotifyService.error({
          title: t('deliveryNoteReport.download.error.title'),
          description: t('deliveryNoteReport.download.error.description'),
          actionText: t('deliveryNoteReport.download.error.actionText'),
        })
      },
    }
  )

  return {
    ...rest,

    isLoading: rest.isLoading || isLoading,
    downloadDeliveryNoteReport: mutate,
    downloadDeliveryNoteReportAsync: mutateAsync,
    blob: deliveryNoteReport,
    dataUrl,
  }
}

const useTraceabilityReport = (truId: string, addRawMaterial: boolean) => {
  const [privateDocumentsAvailable, setPrivateDocumentsAvailable] = useState(false)
  const { makeDocumentPublicAsync } = useMakeDocumentPublic()
  const { traceabilities: bulkTraceabilities, isLoading: isLoadingTraceabilityData } =
    useTraceabilities([truId])
  const traceabilities = useMemo(
    () =>
      Object.entries(bulkTraceabilities).map(([truId, bulkTraceability]) => ({
        ...bulkTraceability,
        trigger: { truId },
      })),
    [bulkTraceabilities]
  )
  const { documents: associatedDocuments, isLoading: isLoadingAssociatedDocuments } =
    useTraceabilitiesDocuments(
      traceabilities.map(traceability =>
        addRawMaterial ? traceability : removeNegativeLevelsFromTraceability(traceability)
      )
    )

  const documentToPublish = useMemo(() => {
    if (associatedDocuments.length === 0) {
      return []
    }
    return associatedDocuments.filter(
      document => document.type === DocumentType.FILE && !document.publicUrl
    )
  }, [associatedDocuments])

  const [documentsToPublishDebounced] = useDebounceState(documentToPublish)

  useEffect(() => {
    const publishDocuments = async () => {
      if (documentsToPublishDebounced.length > 0) {
        documentsToPublishDebounced.forEach(async document => {
          await makeDocumentPublicAsync(document._id)
        })
      } else {
        setPrivateDocumentsAvailable(true)
      }
    }

    publishDocuments()
  }, [documentsToPublishDebounced, makeDocumentPublicAsync])
  const { data, ...rest } = useQuery(
    ['traceabilityreport', truId, addRawMaterial],
    async () => generateTraceabilityReport({ truId, addRawMaterial }),
    { enabled: privateDocumentsAvailable }
  )

  const dataUrl = useMemo(() => {
    if (data) {
      return URL.createObjectURL(data)
    }
  }, [data])

  return {
    ...rest,
    isLoading:
      rest.isLoading ||
      isLoadingTraceabilityData ||
      isLoadingAssociatedDocuments ||
      !privateDocumentsAvailable,
    traceabilityReport: data,
    dataUrl,
  }
}

export const useDownloadReportTraceability = (truId: string, addRawMaterial: boolean) => {
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()

  const { traceabilityReport, dataUrl, isLoading } = useTraceabilityReport(truId, addRawMaterial)

  const { mutate, mutateAsync, ...rest } = useMutation(
    async () => {
      if (!traceabilityReport) {
        return
      }
      downloader(traceabilityReport, 'application/pdf', 'traceability-report')
    },
    {
      onSuccess: () => {
        analytics.track('CUSTOMER_GENERATE_TRACEABILITY_REPORT', {
          From: 'Home',
        })
      },
      onError: () => {
        analytics.track('CUSTOMER_GENERATE_TRACEABILITY_REPORT_ERROR', {
          From: 'Home',
        })
        NotifyService.error({
          title: t('traceabilityReport.download.error.title'),
          description: t('traceabilityReport.download.error.description'),
          actionText: t('traceabilityReport.download.error.actionText'),
        })
      },
    }
  )

  return {
    ...rest,

    isLoading: rest.isLoading || isLoading,
    downloadTraceabilityReport: mutate,
    downloadTraceabilityReportAsync: mutateAsync,
    blob: traceabilityReport,
    dataUrl,
  }
}

const useStockReport = (
  reportProducts: { ids: string[]; all: boolean },
  reportStakeHolderProducts: {
    ids: string[]
    all: boolean
  },
  filename: string
) => {
  const { data, ...rest } = useQuery(
    ['stockreport', reportProducts, reportStakeHolderProducts],
    async () => generateStockReport({ reportProducts, reportStakeHolderProducts })
  )

  const dataUrl = useMemo(() => {
    if (data) {
      return URL.createObjectURL(data)
    }
  }, [data])

  return {
    ...rest,
    stockReport: data,
    dataUrl,
  }
}

export const useDownloadReportStock = (
  reportProducts: { ids: string[]; all: boolean },
  reportStakeHolderProducts: {
    ids: string[]
    all: boolean
  },
  filename: string
) => {
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()

  const { stockReport, dataUrl, isLoading } = useStockReport(
    reportProducts,
    reportStakeHolderProducts,
    filename
  )

  const { mutate, mutateAsync, ...rest } = useMutation(
    async () => {
      if (!stockReport) {
        return
      }
      downloader(stockReport, 'application/pdf', filename)
    },
    {
      onSuccess: () => {
        analytics.track('CUSTOMER_GENERATE_STOCK_REPORT', {
          From: 'Home',
        })
      },
      onError: () => {
        analytics.track('CUSTOMER_GENERATE_STOCK_REPORT_ERROR', {
          From: 'Home',
        })
        NotifyService.error({
          title: t('stockReport.download.error.title'),
          description: t('stockReport.download.error.description'),
          actionText: t('stockReport.download.error.actionText'),
        })
      },
    }
  )

  return {
    ...rest,

    isLoading: rest.isLoading || isLoading,
    downloadStockReport: mutate,
    downloadStockReportAsync: mutateAsync,
    blob: stockReport,
    dataUrl,
  }
}

export const useDownloadReportIssuesSummary = () => {
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()

  const { mutate, mutateAsync, ...rest } = useMutation(
    async (params: { startDate: Date; endDate: Date; filename: string }) => {
      const result = await generateIssuesSummaryReport(params)
      downloader(result, 'application/pdf', params.filename)
    },
    {
      onSuccess: () => {
        analytics.track('CUSTOMER_GENERATE_INCIDENT_REPORT', {
          From: 'Home',
        })
      },
      onError: () => {
        analytics.track('CUSTOMER_GENERATE_INCIDENT_REPORT_ERROR', {
          From: 'Home',
        })
        NotifyService.error({
          title: t('taskSummaryReport.download.error.title'),
          description: t('taskSummaryReport.download.error.description'),
          actionText: t('taskSummaryReport.download.error.actionText'),
        })
      },
    }
  )

  return {
    ...rest,
    downloadIssuesSummaryReport: mutate,
    downloadIssuesSummaryReportAsync: mutateAsync,
  }
}
