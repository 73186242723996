import { useCallback, useContext, useMemo } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '@/app/firebase'
import * as NotifyService from '@/services/notify.service'
import {
  Company,
  CompanyAuthAddons,
  CompanyCreation,
  CompanyExtensions,
  useMyCompany,
  useMyCompanyKnownAs,
} from '@/features/company'
import * as LocalStorageService from '@/services/local-storage.service'
import i18n from '@/i18n'
import { UseQueryOptions, useMutation, useQuery } from 'react-query'
import { customErrorToTrack, useAnalytics } from '@/features/analytics'
import { useUpdateCustomerPreferences } from '@/features/payments'
import {
  changeLanguage,
  detectSSOProvider,
  ensureSSOUser,
  passwordReset,
  register,
  requestPasswordRecovery,
  updatePassword,
  verifyToken,
} from './service'
import { AuthenticationContext } from './context'
import { AxiosError } from 'axios'
import { Account, SSODetectionResult, VerifyTokenResponse } from './interfaces'
import { mergeQueryOptions } from '@/utils/merge-query-options'
import { Permissions } from '@/features/roles'
import config from '@/config'
import { useTranslation } from 'react-i18next'
import { FirebaseError } from 'firebase/app'

export const QUERY_MY_PROFILE_KEY = 'my-profile'

export const useAuthentication = () => useContext(AuthenticationContext)

export const useRegister = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsRegisterPage')
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({ company, reCaptchaToken }: { company: CompanyCreation; reCaptchaToken: string }) =>
      register(company, {
        [config.RECAPTCHA_HEADER]: reCaptchaToken,
      }),
    {
      onSuccess(data, { company }) {
        analytics.track('CUSTOMER_REGISTER_PLATFORM', {
          'Company name': company.name,
          CCNIF: `${company.countryCode}${company.reference}`,
          Email: company.email,
        })

        NotifyService.success({
          title: t('notification.create.title'),
          description: t('notification.create.description'),
        })
      },
      onError(error) {
        const errorResponse = (error as AxiosError).response
        analytics.track(
          'CUSTOMER_REGISTER_ERROR',
          customErrorToTrack(errorResponse?.data, errorResponse?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(errorResponse?.data))
      },
    }
  )

  return {
    ...rest,
    register: mutate,
    registerAsync: mutateAsync,
  }
}

export const useMyProfile = (
  overrideOptions?: Omit<UseQueryOptions<VerifyTokenResponse, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { isAuthenticated } = useAuthentication()
  const options = mergeQueryOptions(overrideOptions, {
    refetchOnMount: false,
    enabled: overrideOptions?.enabled === undefined ? isAuthenticated : overrideOptions?.enabled,
  })
  const { data, ...rest } = useQuery(
    [QUERY_MY_PROFILE_KEY],
    () => verifyToken({ ignoreActivation: true }),
    options
  )

  return {
    ...rest,
    profile: data,
  }
}

/**
 *
 * @deprecated use `useMyProfile` instead
 */
export const useCompanyUser = () => {
  const { company } = useMyCompany()
  const { profile } = useMyProfile()

  return useMemo(
    () =>
      ({
        ...company,
        email: profile?.email,
      } as unknown as Company & { email: string }),
    [company, profile]
  )
}

export const useUserExtensions = () => {
  const { company } = useMyCompany()

  return company?.extensions as unknown as CompanyExtensions
}

export const useUserAddons = () => {
  const { profile } = useMyProfile()

  return profile?.addons as unknown as CompanyAuthAddons
}

export const useUserPermissions = () => {
  const { profile } = useMyProfile()

  return profile?.permissions as unknown as Permissions
}

export const useIsUserStockEnabled = () => {
  const { company } = useMyCompany()
  const { profile } = useMyProfile()

  const isUserAddonsStockEnabled = profile?.addons?.stockManagement.enabled
  const isUserExtensionStockEnabled = company?.extensions.stock

  return !!(isUserAddonsStockEnabled && isUserExtensionStockEnabled)
}

export const useIsUserFacilitiesEnabled = () => {
  const { company } = useMyCompany()
  const { profile } = useMyProfile()

  const isUserAddonsFacilitiesEnabled = profile?.addons?.facilities?.enabled
  const isUserExtensionFacilitiesEnabled = company?.extensions.facilities

  return !!(isUserAddonsFacilitiesEnabled && isUserExtensionFacilitiesEnabled)
}

/**
 *
 * @deprecated use `useIsMe` instead
 */
export const useUserId = () => {
  const { company } = useMyCompany()
  const { profile } = useMyProfile()

  return (profile?.userId || company?._id) as unknown as string
}

export const useIsMe = () => {
  const { company } = useMyCompany()
  const { profile } = useMyProfile()

  const isMe = useCallback(
    (id: string) => {
      if (profile?.companyOwner) {
        return id === company?._id || id === profile?.user.id
      }

      return id === profile?.user.id
    },
    [company?._id, profile?.companyOwner, profile?.user.id]
  )

  return {
    isMe,
  }
}

export const useUserType = () => {
  const { profile } = useMyProfile()

  return profile?.type as unknown as 'company' | 'user'
}

export const useIsUserCompany = () => {
  const { profile } = useMyProfile()

  return profile?.type === 'company'
}

export const useAccount = () => {
  const { profile } = useMyProfile()

  return profile?.account as unknown as Account
}

export const useUserKnownAs = () => {
  const { knownAs } = useMyCompanyKnownAs()

  return knownAs as unknown as string[]
}

export const useUserVisibility = () => {
  const { company } = useMyCompany()

  return !!company?.visibility
}

export const useAccountLogo = () => {
  const { company } = useMyCompany()

  return company?.media.logo
}

export const useUserLanguage = () => {
  const { profile } = useMyProfile()

  return (
    profile?.language || LocalStorageService.get(LocalStorageService.KEYS.TRANSLATION_LNG) || 'en'
  )
}

export const useLogin = () => {
  const analytics = useAnalytics()
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({ email, password }: { email: string; password: string }) =>
      signInWithEmailAndPassword(auth, email, password),
    {
      onError(error) {
        if (error instanceof FirebaseError) {
          analytics.track('CUSTOMER_LOGIN_ERROR', {
            'Status code': error.code,
            'Error message': error.message,
          })
          if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
            NotifyService.error(
              NotifyService.customErrorToNotify({
                entity: 'company',
                code: '2000',
              })
            )
          } else {
            NotifyService.error(NotifyService.customErrorToNotify(error))
          }
        }
      },
    }
  )

  return {
    ...rest,
    login: mutate,
    loginAsync: mutateAsync,
  }
}

export const useChangeLanguage = () => {
  const { refreshToken } = useAuthentication()
  const isUserCompany = useIsUserCompany()
  const { updateCustomerPreferences } = useUpdateCustomerPreferences()
  const { mutate, mutateAsync, ...rest } = useMutation(changeLanguage, {
    onSuccess(data, language) {
      i18n.changeLanguage(language)
      refreshToken()
      if (isUserCompany) {
        updateCustomerPreferences({
          language,
        })
      }
    },
  })

  return {
    ...rest,
    changeLanguage: mutate,
    changeLanguageAsync: mutateAsync,
  }
}

export const useChangePassword = () => {
  const analytics = useAnalytics()
  const { mutate, mutateAsync, ...rest } = useMutation(updatePassword, {
    onSuccess() {
      analytics.track('CUSTOMER_EDIT_PASSWORD')
    },
    onError(error) {
      const errorResponse = (error as AxiosError).response
      analytics.track(
        'CUSTOMER_EDIT_PASSWORD_ERROR',
        customErrorToTrack(errorResponse?.data, errorResponse?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(errorResponse?.data))
    },
  })

  return {
    ...rest,
    changePassword: mutate,
    changePasswordAsync: mutateAsync,
  }
}

export const usePasswordRecovery = () => {
  const analytics = useAnalytics()
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({ email, reCaptchaToken }: { email: string; reCaptchaToken: string }) =>
      requestPasswordRecovery(email, `${window.document.location.origin}/password/reset`, {
        [config.RECAPTCHA_HEADER]: reCaptchaToken,
      }),
    {
      onSuccess(data, variables) {
        analytics.track('CUSTOMER_RECOVER_PASSWORD', { Email: variables.email })
      },
      onError(error, variables) {
        const errorResponse = (error as AxiosError).response
        analytics.track('CUSTOMER_RECOVER_PASSWORD_ERROR', {
          ...customErrorToTrack(errorResponse?.data, errorResponse?.status),
          Email: variables.email,
        })
      },
    }
  )

  return {
    ...rest,
    recoverPassword: mutate,
    recoverPasswordAsync: mutateAsync,
  }
}

export const usePasswordReset = () => {
  const { isAuthenticated, logout } = useAuthentication()
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({
      token,
      password,
      reCaptchaToken,
    }: {
      token: string
      password: string
      reCaptchaToken: string
    }) =>
      passwordReset(token, password, {
        [config.RECAPTCHA_HEADER]: reCaptchaToken,
      }),
    {
      onSuccess() {
        if (isAuthenticated) {
          logout()
        }
      },
    }
  )

  return {
    ...rest,
    resetPassword: mutate,
    resetPasswordAsync: mutateAsync,
  }
}

export const useSSOProviderDetection = ({
  onSuccess,
}: {
  onSuccess: (result: SSODetectionResult, variables: string) => void
}) => {
  const { data, mutate, ...rest } = useMutation(detectSSOProvider, {
    onSuccess,
  })

  return {
    ...rest,
    ssoDetectionResult: data,
    detectSSOProvider: mutate,
  }
}

export const useEnsureSSOUser = () => {
  const { mutate, ...rest } = useMutation(ensureSSOUser)

  return {
    ...rest,
    ensureSSOUser: mutate,
  }
}
