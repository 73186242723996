import { api } from '@/app/api'
import { FileUploadResponse } from '../files'
import {
  PublicUploadRequest,
  UploadRequest,
  UploadRequestCreate,
  UploadRequestFilters,
} from './interfaces'

/**
 * Define the url path
 * @constant
 */
export const UPLOAD_REQUEST_URL = '/documents/upload-requests'

/**
 * Create a new UploadRequest
 *
 *
 * @param uploadRequest Raw data to create a new tru
 */
export const create = async (uploadRequest: UploadRequestCreate): Promise<string> => {
  const { data: uploadRequestId } = await api.post<string>(UPLOAD_REQUEST_URL, uploadRequest)
  return uploadRequestId
}

/**
 * Get a public UploadRequest
 *
 *
 * @param publicId
 */
export const getByPublicId = async (publicId: string): Promise<PublicUploadRequest | void> => {
  const { data: publicuploadRequest } = await api.get<PublicUploadRequest | void>(
    `${UPLOAD_REQUEST_URL}/public/${publicId}`
  )
  return publicuploadRequest
}

/**
 * Get all UploadRequests with filters support
 */
export const getAll = async (filters?: UploadRequestFilters): Promise<UploadRequest[]> => {
  const searchParams = new URLSearchParams()
  if (filters?.status) {
    searchParams.append('status', filters.status)
  }
  if (filters?.startCreatedAt) {
    searchParams.append('startCreatedAt', filters.startCreatedAt)
  }

  const { data: uploadRequests } = await api.get<UploadRequest[] | void>(UPLOAD_REQUEST_URL, {
    params: searchParams,
  })

  return uploadRequests || []
}

export const uploadPublicFile = async (
  publicId: string,
  file: File,
  { isPublic }: { isPublic?: boolean } = {}
): Promise<FileUploadResponse> => {
  const formData = new FormData()
  formData.append('file', file, file.name)

  const { data } = await api.post<Omit<FileUploadResponse, 'fileName'>>(
    `${UPLOAD_REQUEST_URL}/public/${publicId}/file`,
    formData
  )
  return {
    ...data,
    fileName: file.name,
  }
}

export const confirmRequest = async (
  publicId: string,
  files: {
    fileName: string // Used as document title
    mimeType: string
    path: string
    expireAt?: Date
  }[]
): Promise<void> => {
  await api.post<void>(`${UPLOAD_REQUEST_URL}/public/${publicId}/confirm`, { files })
}
