import { Compliance } from '../interfaces'
import { ComplianceReportType } from '../hooks'
import { TFunction } from 'i18next'

/**
 * @method getComplianceReportName
 * @description Generate the report name of a compliance, its used to generate a compliance report on demand.
 */
export const getComplianceReportName = (
  compliance: Compliance,
  reportType: ComplianceReportType,
  t: TFunction
) => {
  return reportType === ComplianceReportType.PENDING_TO_VALIDATE
    ? t('nsComplianceReport:pendingToValidateFilename', {
        templateName: compliance?.templateName,
        date: new Date(compliance?.dueDate),
      })
    : t('nsComplianceReport:viewReportFilename', {
        templateName: compliance.templateName,
        companyName:
          compliance.compliantStakeHolder.name ||
          compliance.compliantStakeHolder.contactDetails?.name ||
          compliance.compliantStakeHolder.emails[0],
      })
}

/**
 * @method getComplianceAutomaticReportName
 * @description Generate the report name of a compliance, its used to upload a report automatically to the document manager.
 */
export const getComplianceAutomaticReportName = (compliance: Compliance, t: TFunction) => {
  return t('nsComplianceReport:viewReportFilename', {
    templateName: compliance.templateName,
    companyName:
      compliance.compliantStakeHolder.contactDetails?.name || compliance.compliantStakeHolder.name,
  })
}
