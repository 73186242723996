import { useAutomationAuditsById } from '@/features/audits'
import { useProductById } from '@/features/products'
import { useTemplate } from '@/features/templates'
import {
  Badge,
  COLOR,
  Feeds,
  Loader,
  Modal,
  NEW_COLOR,
  SIZE,
} from '@blockchain-traceability-sl/tailwind-components'
import { LightningBoltIcon, PencilAltIcon, PlusIcon, XIcon } from '@heroicons/react/outline'
import dayjs from 'dayjs'
import { useMemo } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { AutomationEntityEventTrigger, AutomationTriggerType, useAutomation } from '../..'
import { ActivityItemSubtitle } from './ActivityItemSubtitle'
import { ACTIVITY_TYPE, AuditAutomatedTask, AuditAutomation } from './interfaces'
import { formatAuditToActivities } from './utils'

export interface AutomationActivityModalProps {
  show: boolean
  automationId?: string | null
  toggle: () => void
}

export const ModalAutomationActivity = ({
  show,
  toggle,
  automationId,
}: AutomationActivityModalProps) => {
  const { t } = useTranslation('nsAutomationActivityModal')

  const { automation } = useAutomation(automationId || '', {
    enabled: !!automationId,
  })

  const { template } = useTemplate(automation?.target.params.templateId || '', 'form', {
    enabled: !!automation?.target.params.templateId,
  })

  const entityEventTrigger = automation?.trigger as AutomationEntityEventTrigger | undefined
  const productId = entityEventTrigger?.entityEvent?.params?.productId

  const { product } = useProductById(productId || '', {
    enabled: !!productId,
  })

  const { audits, isLoading } = useAutomationAuditsById(automationId || '', {
    enabled: !!automationId,
  })

  const ACTIVITY_ELEMENTS = useMemo(
    () => ({
      [ACTIVITY_TYPE.AUTOMATION_CREATED]: {
        icon: PlusIcon,
        color: COLOR.GREEN,
        title: (audit: AuditAutomation) => (
          <>
            <div>
              <Trans t={t} i18nKey='activity.creation' components={{ b: <b /> }} />
            </div>
            <ActivityItemSubtitle audit={audit} />
          </>
        ),
      },
      [ACTIVITY_TYPE.AUTOMATION_EDITED]: {
        icon: PencilAltIcon,
        color: NEW_COLOR.ORANGE,
        title: (audit: AuditAutomation) => (
          <>
            <div>
              <Trans t={t} i18nKey='activity.edition' components={{ b: <b /> }} />
            </div>
            <ActivityItemSubtitle audit={audit} />
          </>
        ),
      },
      [ACTIVITY_TYPE.AUTOMATED_TASK_CREATED]: {
        icon: LightningBoltIcon,
        color: COLOR.YELLOW,
        title: (audit: AuditAutomatedTask, previousAudit?: AuditAutomation) => (
          <>
            <div>
              <Trans
                t={t}
                i18nKey={`activity.${
                  previousAudit?.data?.trigger?.type === AutomationTriggerType.ENTITY_EVENT
                    ? 'batchAssociatedTaskCreated'
                    : 'recurrentTaskCreated'
                }`}
                components={{ b: <b /> }}
              />
            </div>
            <ActivityItemSubtitle audit={audit} previousAudit={previousAudit} />
          </>
        ),
      },
      [ACTIVITY_TYPE.FORM_TARGET_DELETED]: {
        icon: XIcon,
        color: COLOR.RED,
        title: (audit?: AuditAutomation) => (
          <>
            <div>
              <Trans t={t} i18nKey='activity.error' components={{ b: <b /> }} />
            </div>
            <div>
              <Trans
                t={t}
                i18nKey={`activity.${
                  audit?.data.target.params.templateName
                    ? 'formTargetDeleted'
                    : 'noNameFormTargetDeleted'
                }`}
                components={{ b: <b /> }}
                values={{
                  formName: audit?.data.target.params.templateName,
                }}
              />
            </div>
          </>
        ),
      },
      [ACTIVITY_TYPE.PRODUCT_TRIGGER_DELETED]: {
        icon: XIcon,
        color: COLOR.RED,
        title: (audit?: AuditAutomation) => (
          <>
            <div>
              <Trans t={t} i18nKey='activity.error' components={{ b: <b /> }} />
            </div>
            {audit?.data.trigger.type === AutomationTriggerType.ENTITY_EVENT && (
              <div>
                <Trans
                  t={t}
                  i18nKey={`activity.${
                    audit?.data.trigger.entityEvent.params.productName
                      ? 'productTriggerDeleted'
                      : 'noNameProductTriggerDeleted'
                  }`}
                  components={{ b: <b /> }}
                  values={{
                    productName: audit?.data.trigger.entityEvent.params.productName,
                  }}
                />
              </div>
            )}
          </>
        ),
      },
    }),
    [t]
  )

  const events = useMemo(
    () =>
      audits
        // To prevent duplicates because Heimdal issue
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              sibling =>
                sibling.data.updatedAt === value.data.updatedAt &&
                sibling.data._id === value.data._id
            )
        )
        // Format audit to activities
        .flatMap(formatAuditToActivities)
        .reverse(),
    [audits]
  )

  const feeds = useMemo(
    () =>
      events
        // Format activities to feeds
        .map(({ type, audit, previousAudit }) => {
          const title =
            type === ACTIVITY_TYPE.AUTOMATED_TASK_CREATED
              ? ACTIVITY_ELEMENTS[type].title(
                  audit as AuditAutomatedTask,
                  previousAudit as AuditAutomation
                )
              : ACTIVITY_ELEMENTS[type].title(audit as AuditAutomation)

          return {
            title: <div className='text-left'>{title}</div>,
            time: dayjs(audit.createdAt).format('DD/MM/YYYY, HH:mm'),
            color: ACTIVITY_ELEMENTS[type].color,
            iconColor: ACTIVITY_ELEMENTS[type].color,
            icon: ACTIVITY_ELEMENTS[type].icon,
            isIconLight: true,
            content: null,
          }
        }),

    [events, ACTIVITY_ELEMENTS]
  )
  let badgeTitle =
    automation?.trigger.type === AutomationTriggerType.ENTITY_EVENT ? product?.name : template?.name

  if (!badgeTitle) {
    const lastErrorEvent = events.find(
      ({ type }) =>
        type === ACTIVITY_TYPE.FORM_TARGET_DELETED || type === ACTIVITY_TYPE.PRODUCT_TRIGGER_DELETED
    )

    if (lastErrorEvent?.type === ACTIVITY_TYPE.FORM_TARGET_DELETED) {
      badgeTitle = (lastErrorEvent.audit as AuditAutomation).data.target.params.templateName
    }

    if (lastErrorEvent?.type === ACTIVITY_TYPE.PRODUCT_TRIGGER_DELETED) {
      badgeTitle = (
        (lastErrorEvent.audit as AuditAutomation).data.trigger as AutomationEntityEventTrigger
      ).entityEvent?.params?.productName
    }
  }

  return (
    <Modal size={SIZE.LARGE} show={show} toggle={toggle} dismissButton>
      <Modal.Title className='flex items-center justify-center'>
        <div>{t('title')}</div>
        {badgeTitle && (
          <Badge color={COLOR.GREEN} className='ml-5'>
            {badgeTitle}
          </Badge>
        )}
      </Modal.Title>
      <Modal.Body>
        {' '}
        <div>{isLoading ? <Loader center='full' /> : <Feeds feeds={feeds} />}</div>
      </Modal.Body>
    </Modal>
  )
}
