import axios, { AxiosError } from 'axios'
import axiosRetry from 'axios-retry'
import Config from '@/config'
import { addTraceInterceptors } from './trace'

/**
 *
 */
export function isTooManyRequestsError(error: AxiosError): boolean {
  return (
    error.code !== 'ECONNABORTED' && // Prevents retrying cancelled requests and timed out requests
    error.response?.status === 429
  )
}

const externalApiInstance = axios.create()

/**
 * Axios instance with our gateway configuration
 */
const apiInstance = axios.create({
  baseURL: Config.GATEWAY_URL,
})

addTraceInterceptors(apiInstance)

axiosRetry(apiInstance, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: error =>
    axiosRetry.isNetworkOrIdempotentRequestError(error) || isTooManyRequestsError(error),
})

axiosRetry(externalApiInstance, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: error =>
    axiosRetry.isNetworkOrIdempotentRequestError(error) ||
    isTooManyRequestsError(error) ||
    error.code === 'ECONNREFUSED' ||
    error.code === 'ECONNABORTED',
})

// #############
// ## EXPORTS ##
// #############

export const api = apiInstance

export const externalApi = externalApiInstance
