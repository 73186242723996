import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  SelectMultiField,
  SelectMultiFieldProps,
  SelectorOption,
  createSelectorOption,
  noop,
} from '@blockchain-traceability-sl/tailwind-components'
import { useEffect, useState } from 'react'
import { useFetchStakeHolderProducts, usePaginatedStakeHolderProducts } from '../hooks'
import { StakeHolderProductFilters } from '../interfaces'
import { getStakeHolderProductNameWithReference } from '../utils'

export type StakeHolderProductsSelectMultiFieldProps = Omit<SelectMultiFieldProps, 'options'> &
  Omit<StakeHolderProductFilters, 'search'>

export const StakeHolderProductsSelectMultiField = (
  props: StakeHolderProductsSelectMultiFieldProps
) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const [stakeHolderProductsOptions, setStakeHolderProductsOptions] = useState<SelectorOption[]>([])

  const {
    isLoading: isInfinityStakeHolderProductsLoading,
    stakeHolderProducts,
    fetchNextPage,
  } = usePaginatedStakeHolderProducts({
    filters: {
      search: debouncedSearch,
    },
  })

  const { fetchStakeHolderProducts } = useFetchStakeHolderProducts({
    search: debouncedSearch,
  })

  const [isLoading, setIsLoading] = useState(isInfinityStakeHolderProductsLoading)

  useEffect(() => {
    setStakeHolderProductsOptions(
      stakeHolderProducts.map(stakeHolderProduct =>
        createSelectorOption(
          getStakeHolderProductNameWithReference(stakeHolderProduct),
          stakeHolderProduct._id
        )
      )
    )
  }, [debouncedSearch, stakeHolderProducts])

  useEffect(() => {
    setIsLoading(isInfinityStakeHolderProductsLoading)
  }, [isInfinityStakeHolderProductsLoading])

  const handleSelectAll = async () => {
    setIsLoading(true)
    fetchStakeHolderProducts()
      .then(stakeHolderProducts => {
        const newOptions = stakeHolderProducts.map(stakeHolderProduct =>
          createSelectorOption(
            getStakeHolderProductNameWithReference(stakeHolderProduct),
            stakeHolderProduct._id
          )
        )
        setStakeHolderProductsOptions(newOptions)
        if (props.onChange) {
          props.onChange(newOptions)
        }
      })
      .catch(noop)
      .finally(() => setIsLoading(false))
  }

  return (
    <SelectMultiField
      {...props}
      isLoading={isLoading}
      options={stakeHolderProductsOptions}
      onInputChange={setSearch}
      onMenuScrollToBottom={() => fetchNextPage()}
      onSelectAll={handleSelectAll}
    />
  )
}
