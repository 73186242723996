import { CompanyAuthAddons } from '@/features/company'
import { Permissions } from '../roles'
import { Company } from '../company/interfaces'

export enum AuthType {
  EMAIL_PASSWORD = 'email_password',
  SAML = 'saml',
  OIDC = 'oidc',
}

export type SSOAuthType = AuthType.OIDC | AuthType.SAML

export interface Auth {
  id: string
  type?: AuthType
  email: string
  passwordRecoveryToken: string
}

/**
 * @deprecated
 */
export interface Account {
  name: string
  email: string
  language: string
}

export interface VerifyTokenResponse {
  active?: boolean
  auth: {
    id: string
    type: AuthType
  }
  companyOwner: boolean
  user: {
    id: string
    email: string
    language: string
    name: string
  }
  owner: {
    id: string
    email: string
    language: string
    name: string
  }
  company: {
    id: string
    name: string
    lastDataExportedAt?: string
  }
  addons: CompanyAuthAddons
  permissions: Permissions

  /**
   * @deprecated use company.id
   */
  _id: string
  /**
   * @deprecated use user.name
   */
  name: string
  /**
   * @deprecated use user.email
   */
  email: string
  /**
   * @deprecated use user.id
   */
  userId?: string
  /**
   * @deprecated use companyOwner
   */
  type: 'company' | 'user'
  /**
   * @deprecated use company
   */
  account: Account
  /**
   * @deprecated use user.language
   */
  language: string
}

export interface IAuthenticationContext {
  isAuthenticating: boolean
  isAuthenticated: boolean
  isActivationLoading: boolean
  isActivated: boolean
  loginWithEmailAndPassword: (
    email: string,
    password: string,
    options?: { onSuccess?: () => void }
  ) => Promise<unknown> | void
  loginWithSSO: (
    authType: SSOAuthType,
    providerId: string,
    parameters: { loginHint: string }
  ) => void
  logout: () => void
  refreshToken: () => void
}

export interface SSODetectionResult {
  type: AuthType
  providerId?: string
}

/**
 * @deprecated
 */
export interface CompanyUser extends Company {
  isAuthenticated: boolean
  email: string
}
