import { useCallback, useMemo } from 'react'
import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import {
  create,
  createWithCSV,
  getAll,
  getByIds,
  getByType,
  getCsvTemplate,
  getPaginated,
  getTags,
  remove,
  sendRequirementsEmail,
  update,
} from './service'
import {
  StakeHolder,
  StakeHolderCreation,
  StakeHolderType,
  StakeHolderUpdate,
  StakeholderRequirementEmail,
} from './interfaces'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { customErrorToTrack, useAnalytics } from '../analytics'
import { useHistory } from 'react-router-dom'
import * as NotifyService from '@/services/notify.service'
import { BulkError, CustomError, ErrorCodes } from '@blockchain-traceability-sl/custom-error-codes'
import { downloader } from '@/helpers/downloader'
import { downloadStakeHolderBulkErrorReport } from './helpers'
import { DEFAULT_PAGE_SIZE, Paginated, PaginationParams } from '@/hooks/use-pagination'
import { mergeInfiniteQueryOptions, mergeQueryOptions } from '@/utils/merge-query-options'

export const QUERY_STAKEHOLDERS_KEY = 'stakeHolders'

export const useStakeHolders = (
  options?: Omit<UseQueryOptions<StakeHolder[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(QUERY_STAKEHOLDERS_KEY, () => getAll(), options)
  const stakeHolders = useMemo(() => data || [], [data])

  return { ...rest, stakeHolders }
}

export const usePaginatedStakeHolders = (
  filter: { search?: string },
  pagination: PaginationParams,
  overrideOptions?: Omit<
    UseQueryOptions<Paginated<StakeHolder>, AxiosError>,
    'queryKey' | 'queryFn'
  >
) => {
  const options = mergeQueryOptions(overrideOptions, {
    keepPreviousData: true,
  })
  const { data, ...rest } = useQuery(
    [QUERY_STAKEHOLDERS_KEY, filter, pagination],
    () => getPaginated(filter, pagination),
    options
  )

  const stakeHolders = useMemo(() => data?.items || [], [data?.items])
  const totalCount = data?.totalCount || 0

  return {
    ...rest,
    stakeHolders,
    totalCount,
  }
}

export const useFetchStakeHolderById = () => {
  const queryClient = useQueryClient()

  const fetchStakeHolderById = useCallback(
    (stakeHolderId: string) => {
      return queryClient.fetchQuery([QUERY_STAKEHOLDERS_KEY, stakeHolderId], () =>
        getByIds([stakeHolderId]).then(stakeHolders =>
          stakeHolders.length ? stakeHolders[0] : undefined
        )
      )
    },
    [queryClient]
  )

  return {
    fetchStakeHolderById,
  }
}

export const useFetchStakeHolderByIds = () => {
  const queryClient = useQueryClient()

  const fetchStakeHolderByIds = useCallback(
    (stakeHolderIds: string[]): Promise<StakeHolder[]> => {
      return queryClient.fetchQuery([QUERY_STAKEHOLDERS_KEY, { stakeHolderIds }], () =>
        getByIds(stakeHolderIds)
      )
    },
    [queryClient]
  )

  return {
    fetchStakeHolderByIds,
  }
}

export const useStakeHolderById = (
  stakeHolderId: string,
  options?: Omit<UseQueryOptions<StakeHolder | undefined, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_STAKEHOLDERS_KEY, stakeHolderId],
    async () => {
      const stakeHolders = await getByIds([stakeHolderId])
      return stakeHolders[0]
    },
    options
  )

  return { ...rest, stakeHolder: data }
}

export const useStakeHolderByIds = (
  stakeHolderIds: string[],
  options?: Omit<UseQueryOptions<StakeHolder[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_STAKEHOLDERS_KEY, { stakeHolderIds }],
    async () => getByIds(stakeHolderIds),
    options
  )

  const stakeHolders = useMemo(() => data || [], [data])

  return { ...rest, stakeHolders }
}

export const useStakeHoldersByType = (
  type: StakeHolderType,
  options?: Omit<UseQueryOptions<StakeHolder[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_STAKEHOLDERS_KEY, { type }],
    () => getByType(type),
    options
  )
  const stakeHolders = useMemo(() => data || [], [data])

  return { ...rest, stakeHolders }
}

export const useStakeHolderTags = (
  options?: Omit<UseQueryOptions<string[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery([QUERY_STAKEHOLDERS_KEY, { tags: true }], getTags, options)

  return { ...rest, stakeHolderTags: useMemo(() => data || [], [data]) }
}

export const useCreateStakeHolder = ({ silently }: { silently?: boolean } = {}) => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()
  const history = useHistory()
  const { t } = useTranslation('nsNotification')
  const { mutate, mutateAsync, ...rest } = useMutation(
    (input: StakeHolderCreation) => create(input),
    {
      onSuccess(data, variables) {
        queryClient.invalidateQueries(QUERY_STAKEHOLDERS_KEY)
        analytics.track('CUSTOMER_CREATE_STAKEHOLDER', {
          Stakeholder: variables.name,
          'Country code': variables.countryCode,
          NIF: variables.reference,
        })
        if (silently) return
        NotifyService.success({
          title: t('stakeHolder.create.success.title'),
          description: t('stakeHolder.create.success.description'),
          actionText: t('stakeHolder.create.success.action'),
          closeText: t('close'),
          onActionClick: () => history.push('/stakeholders/create'),
        })
      },
      onError(error: AxiosError) {
        analytics.track(
          'CUSTOMER_CREATE_STAKEHOLDER_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    createStakeHolder: mutate,
    createStakeHolderAsync: mutateAsync,
  }
}

export const useUpdateStakeHolder = ({ silently }: { silently?: boolean } = {}) => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({ id, stakeHolder }: { id: string; stakeHolder: StakeHolderUpdate }) =>
      update(id, stakeHolder),
    {
      onSuccess(data, variables) {
        queryClient.invalidateQueries(QUERY_STAKEHOLDERS_KEY)
        analytics.track('CUSTOMER_EDIT_STAKEHOLDER', {
          Stakeholder: variables.stakeHolder.name,
        })
        if (silently) return
        NotifyService.success({
          title: t('stakeHolder.update.success.title'),
          description: t('stakeHolder.update.success.description'),
        })
      },
      onError(error: AxiosError) {
        analytics.track(
          'CUSTOMER_EDIT_STAKEHOLDER_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    updateStakeHolder: mutate,
    updateStakeHolderAsync: mutateAsync,
  }
}

export const useStakeHoldersByTypes = (
  types: StakeHolderType[],
  options?: Omit<UseQueryOptions<StakeHolder[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_STAKEHOLDERS_KEY, { types }],
    async () => {
      const results = await Promise.all(types.map(getByType))
      return results.flat()
    },
    options
  )
  const stakeHolders = useMemo(() => data || [], [data])

  return { ...rest, stakeHolders }
}

export const useCreateStakeHoldersWithCSV = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const history = useHistory()

  /**
   * Set as valid processed if is Bulk error and all errors are already exist
   */
  const isAcceptableError = (error: AxiosError) => {
    return (
      error.response?.data.code === ErrorCodes.BULK_ERROR &&
      (error.response?.data as BulkError).errors.every(
        ({ error: customError }) => customError.code === ErrorCodes.ALREADY_EXISTS
      )
    )
  }

  const { mutate, ...rest } = useMutation(createWithCSV, {
    onSuccess(data) {
      analytics.track('CUSTOMER_CREATE_STAKEHOLDERS_CSV')
      NotifyService.success({
        title: t('stakeHolder.createBulk.success.title'),
        description: t('stakeHolder.createBulk.success.description', {
          count: data.total,
        }),
        actionText: t('stakeHolder.createBulk.success.action'),
        onActionClick: () => history.push('/stakeholders/create'),
      })
      queryClient.invalidateQueries(QUERY_STAKEHOLDERS_KEY)
    },
    onError(error: AxiosError) {
      analytics.track(
        'CUSTOMER_CREATE_STAKEHOLDERS_CSV_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )

      switch (error.response?.data.code) {
        case ErrorCodes.BULK_ERROR:
          {
            const bulkError: BulkError = error.response?.data

            // Set as valid processed if all errors are already exist
            if (isAcceptableError(error)) {
              NotifyService.success({
                title: t('stakeHolder.createBulk.success.title'),
                description: t('stakeHolder.createBulk.success.description', {
                  count: bulkError.total,
                }),
                actionText: t('stakeHolder.createBulk.success.action'),
                onActionClick: () => history.push('/stakeholders/create'),
              })
            } else {
              NotifyService.error({
                title: t('stakeHolder.createBulk.bulkError.title'),
                description: t('stakeHolder.createBulk.bulkError.description', {
                  processed: bulkError.processed,
                  total: bulkError.total,
                }),
                actionText: t('stakeHolder.createBulk.bulkError.action'),
                onActionClick: () => downloadStakeHolderBulkErrorReport(bulkError),
              })
            }
          }
          break

        case 10: // InvalidCSVError
          {
            const invalidCSVError: CustomError = error.response?.data

            if (invalidCSVError.entity !== 'file') {
              NotifyService.error({
                title: t('stakeHolder.createBulk.entityError.title'),
                description: t('stakeHolder.createBulk.entityError.description', {
                  context: invalidCSVError.entity,
                }),
                actionText: t('stakeHolder.createBulk.entityError.action'),
              })
            } else {
              NotifyService.error({
                title: t('stakeHolder.createBulk.error.title'),
                description: t('stakeHolder.createBulk.error.description'),
                actionText: t('stakeHolder.createBulk.error.action'),
              })
            }
          }
          break
        default:
          NotifyService.error({
            title: t('stakeHolder.createBulk.error.title'),
            description: t('stakeHolder.createBulk.error.description'),
            actionText: t('stakeHolder.createBulk.error.action'),
          })
          break
      }
    },
  })

  return { ...rest, createStakeHoldersWithCSV: mutate, isAcceptableError }
}

export const useDownloadTemplateStakeHolderCSV = () => {
  const { data } = useQuery([QUERY_STAKEHOLDERS_KEY, { template: 'csv' }], getCsvTemplate)

  const downloadTemplate = (fileName: string) => {
    if (data) downloader(data, 'text/csv', fileName)
  }

  return { downloadTemplate }
}

export const useDeleteStakeholder = () => {
  const analytics = useAnalytics()
  const queryClient = useQueryClient()
  const history = useHistory()
  const { t } = useTranslation('nsNotification')
  const { mutate, ...rest } = useMutation((stakeHolder: StakeHolder) => remove(stakeHolder._id), {
    onSuccess: (data, variables) => {
      analytics.track('CUSTOMER_DELETE_STAKEHOLDER', {
        Stakeholder: variables.company.name,
      })
      NotifyService.success({
        title: t('stakeHolder.delete.success.title'),
        description: t('stakeHolder.delete.success.description'),
        actionText: t('stakeHolder.delete.success.action'),
        onActionClick: () => history.push('/stakeholders/create'),
      })
      queryClient.invalidateQueries(QUERY_STAKEHOLDERS_KEY)
    },
    onError: (error: AxiosError) => {
      analytics.track(
        'CUSTOMER_DELETE_STAKEHOLDER_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return {
    ...rest,
    deleteStakeHolder: mutate,
  }
}

export const useInfiniteStakeholders = (
  filter: { search?: string; types?: StakeHolderType[] },
  overrideOptions?: Omit<
    UseInfiniteQueryOptions<Paginated<StakeHolder>, AxiosError>,
    'queryKey' | 'queryFn'
  >
) => {
  const options = mergeInfiniteQueryOptions(
    {
      getNextPageParam: ({ pageInfo }) => pageInfo?.nextPage || undefined,
    },
    overrideOptions
  )

  const { data, ...rest } = useInfiniteQuery(
    [QUERY_STAKEHOLDERS_KEY, filter, { isInfiniteQuery: true }],
    ({ pageParam }) => getPaginated(filter, pageParam || { limit: DEFAULT_PAGE_SIZE, offset: 0 }),
    options
  )
  const stakeholders = useMemo(
    () => data?.pages.flatMap(page => page.items).flat() || [],
    [data?.pages]
  )

  return {
    ...rest,
    stakeholders,
  }
}

export const useFetchStakeHolders = (filter: { search?: string; types?: StakeHolderType[] }) => {
  const queryClient = useQueryClient()

  const fetchStakeHolders = useCallback(
    () => queryClient.fetchQuery([QUERY_STAKEHOLDERS_KEY, filter], () => getAll(filter)),
    [filter, queryClient]
  )

  return {
    fetchStakeHolders,
  }
}

export const useSendRequirementsEmail = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification', {
    keyPrefix: 'stakeholderProduct.success.securityStock',
  })
  const { mutate, ...rest } = useMutation(
    (email: StakeholderRequirementEmail) => sendRequirementsEmail(email),
    {
      onSuccess: () => {
        analytics.track('CUSTOMER_REQUEST_STOCK')
        NotifyService.success({
          title: t('title'),
          description: t('description'),
        })
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_REQUEST_STOCK_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    sendRequirementsEmail: mutate,
  }
}
