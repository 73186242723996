import { useMutation, useQueryClient } from 'react-query'
import { uploadFile } from './services'

const QUERY_FILE_KEY = 'file'

export const useUploadFile = (options?: { withPublicUrl?: boolean }) => {
  const queryClient = useQueryClient()

  const { mutate, mutateAsync, ...rest } = useMutation((file: File) => uploadFile(file, options), {
    async onSuccess() {
      queryClient.invalidateQueries([QUERY_FILE_KEY])
    },
  })

  return { ...rest, uploadFile: mutate, uploadFileAsync: mutateAsync }
}
