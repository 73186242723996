import { useMutation } from 'react-query'
import { generatePdfFromDefinition } from './service'
import { asyncBlobToDataURL } from '@/utils/blob-to-dataurl'

export const pdfFromDefinitionGenerator = async (params: {
  pdfDefinition: string
  filename: string
}): Promise<
  | {
      dataUrl: string
      blob: Blob
    }
  | undefined
> => {
  const blob = await generatePdfFromDefinition(params.pdfDefinition, params.filename)
  if (!blob) return undefined

  const dataUrl = await asyncBlobToDataURL(blob)

  return {
    dataUrl,
    blob,
  }
}

export const useGeneratePdfFromDefinition = () => {
  const { mutate, mutateAsync, ...rest } = useMutation(pdfFromDefinitionGenerator)

  return {
    ...rest,
    generatePdfFromDefinition: mutate,
    generatePdfFromDefinitionAsync: mutateAsync,
  }
}
