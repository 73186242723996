import { Help } from '@/components/Help'
import { useAccount, useCompanyUser } from '@/features/auth'
import { getProductNameWithReference, useProducts } from '@/features/products'
import { useTemplatesForTasks } from '@/features/templates'
import { useUsers } from '@/features/users'
import {
  Button,
  ButtonWithIcon,
  Card,
  SelectField,
  SelectMultiField,
  SelectorOption,
  Step,
  Tooltip,
} from '@blockchain-traceability-sl/tailwind-components'
import { ArrowSmUpIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { FormikErrors, useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  Automation,
  AutomationEntityEventTrigger,
  AutomationScheduleTrigger,
  ScheduleTypes,
  TimeIncrement,
  TimeIncrements,
} from '../interfaces'
import { ExtendedAutomationTriggerType } from './CreateAutomationStep'
import { RescheduleForm } from './RescheduleForm'

export const SetupAutomationStep = ({ isUpdate }: { isUpdate?: boolean }) => {
  const { t } = useTranslation('nsSetupAutomationStep')
  const account = useAccount()

  const { setTouched, handleSubmit } = useFormikContext<Pick<Automation, 'target' | 'trigger'>>()

  const [triggerTypeField] = useField<ExtendedAutomationTriggerType>('trigger.type')

  const [templatesTargetParamsField, templatesTargetParamsMeta, templatesTargetParamsHelpers] =
    useField('target.params.templates')

  const [templateIdTargetParamsField, templateIdTargetParamsMeta, templateIdTargetParamsHelpers] =
    useField('target.params.templateId')

  const [, , templateNameTargetParamsHelpers] = useField('target.params.templateName')

  const [assignedToTargetParamsField, assignedToTargetParamsMeta, assignedToTargetParamsHelpers] =
    useField('target.params.assignedTo')

  const [scheduleTypeField, scheduleTypeMeta, scheduleTypeHelpers] =
    useField('trigger.schedule.type')

  const [
    triggerParameterProductIdField,
    triggerParameterProductIdMeta,
    triggerParameterProductIdHelpers,
  ] = useField('trigger.entityEvent.params.productId')
  const [, , triggerParameterProductNameHelpers] = useField(
    'trigger.entityEvent.params.productName'
  )

  const [dueDateTargetParamsField, dueDateTargetParamsMeta, dueDateTargetParamsHelpers] = useField(
    'target.params.dueDateIncrementType'
  )

  const { templates, isLoading: isTemplatesLoading } = useTemplatesForTasks()

  const templateOptions = templates.map(template => ({
    label: template.name,
    value: template._id,
  }))

  const { users, isLoading: isUsersLoading } = useUsers()

  const company = useCompanyUser()

  const assignedToOptions = [
    ...users.map(item => ({
      label: item.name ? `${item.name} - ${item.auth.email}` : item.auth.email,
      value: item._id,
    })),
    {
      label: `${company.name} - ${account.email}`,
      value: company._id,
    },
  ]

  const scheduleTypeOptions = ScheduleTypes.map(type => ({
    label: t(`form.scheduleType.options.${type}`),
    value: type,
  }))

  const dueDateOptions = TimeIncrements.filter(type => type !== TimeIncrement.TODAY).map(type => ({
    label: t(`form.dueDate.options.${type}`),
    value: type,
  }))

  const productOptions = useProducts(undefined, {
    enabled: triggerTypeField.value === ExtendedAutomationTriggerType.ENTITY_EVENT,
  }).products.map(
    (product): SelectorOption => ({
      label: getProductNameWithReference(product),
      value: product._id,
    })
  )

  const handleFinish = async () => {
    const errors = (await setTouched({
      trigger: {
        schedule: {
          type: true,
        },
        entityEvent: {
          params: {
            productId: true,
          },
        },
      },
      target: {
        params: {
          assignedTo: true,
          dueDateIncrementType: true,
          templateId: true,
        },
      },
    })) as unknown as FormikErrors<Pick<Automation, 'target' | 'trigger'>>
    if (
      !errors?.target?.params?.assignedTo &&
      !errors?.target?.params?.dueDateIncrementType &&
      !errors?.target?.params?.templates &&
      !(errors?.trigger as FormikErrors<AutomationScheduleTrigger>)?.schedule?.type &&
      !(errors?.trigger as FormikErrors<AutomationEntityEventTrigger>)?.entityEvent?.params
        ?.productId
    ) {
      handleSubmit()
    }
  }

  return (
    <Step
      title={t('title')}
      subtitle={
        <div className='flex gap-3'>
          <span>{t('subtitle')}</span>
          <Tooltip tip={t('description')} cursor>
            <span>
              <InformationCircleIcon className='w-4 h-4 text-gray-400' />
            </span>
          </Tooltip>
        </div>
      }
      overview={null}
    >
      {({ goPrevious }) => (
        <div className='space-y-6 m-1'>
          {triggerTypeField.value === ExtendedAutomationTriggerType.RESCHEDULE ? (
            <RescheduleForm onPreviousClick={goPrevious} />
          ) : (
            <>
              <Card className='overflow-visible'>
                <Card.Body>
                  <div className='space-y-6'>
                    <div className='grid grid-cols-2 gap-2'>
                      {isUpdate ? (
                        <SelectField
                          id={templateIdTargetParamsField.name.replaceAll('.', '_')}
                          {...templateIdTargetParamsField}
                          label={t('form.templateId.label')}
                          description={t('form.templateId.description')}
                          placeholder={t('form.templateId.placeholder')}
                          menuPortalTarget={document.body}
                          options={templateOptions}
                          value={
                            templateIdTargetParamsField.value
                              ? templateOptions.find(
                                  option => option.value === templateIdTargetParamsField.value
                                )
                              : null
                          }
                          isLoading={isTemplatesLoading}
                          onChange={option => {
                            templateIdTargetParamsHelpers.setValue(option?.value)
                            templateNameTargetParamsHelpers.setValue(option?.label)

                            const templateSelected = templates.find(
                              ({ _id }) => _id === option?.value
                            )
                            if (templateSelected && templateSelected.defaults?.assignedTo) {
                              assignedToTargetParamsHelpers.setValue(
                                templateSelected.defaults.assignedTo
                              )
                            }
                          }}
                          error={
                            !!templateIdTargetParamsMeta.error && templateIdTargetParamsMeta.touched
                          }
                        />
                      ) : (
                        <SelectMultiField
                          id={templatesTargetParamsField.name.replaceAll('.', '_')}
                          {...templatesTargetParamsField}
                          label={t('form.templateId.label')}
                          description={t('form.templateId.description')}
                          placeholder={t('form.templateId.placeholder')}
                          menuPortalTarget={document.body}
                          options={templateOptions}
                          value={templatesTargetParamsField.value?.map(
                            (value: { templateId: string; templateName: string }) => {
                              return templateOptions.find(
                                option => option.value === value.templateId
                              )
                            }
                          )}
                          isLoading={isTemplatesLoading}
                          onChange={options => {
                            templatesTargetParamsHelpers.setValue(
                              options.map(option => {
                                return {
                                  templateId: option.value,
                                  templateName: option.label,
                                }
                              })
                            )
                          }}
                          error={
                            !!templatesTargetParamsMeta.error && templatesTargetParamsMeta.touched
                          }
                        />
                      )}

                      <SelectField
                        id={assignedToTargetParamsField.name.replaceAll('.', '_')}
                        {...assignedToTargetParamsField}
                        label={t('form.assignedTo.label')}
                        description={t('form.assignedTo.description')}
                        placeholder={t('form.assignedTo.placeholder')}
                        menuPortalTarget={document.body}
                        options={assignedToOptions}
                        value={
                          assignedToTargetParamsField.value
                            ? assignedToOptions.find(
                                option => option.value === assignedToTargetParamsField.value
                              )
                            : null
                        }
                        isLoading={isUsersLoading}
                        onChange={option => {
                          assignedToTargetParamsHelpers.setValue(option?.value)
                        }}
                        error={
                          !!assignedToTargetParamsMeta.error && assignedToTargetParamsMeta.touched
                        }
                      />
                    </div>

                    {triggerTypeField.value === ExtendedAutomationTriggerType.SCHEDULE && (
                      <div className='grid grid-cols-2 gap-2'>
                        <SelectField
                          id={scheduleTypeField.name.replaceAll('.', '_')}
                          {...scheduleTypeField}
                          label={t('form.scheduleType.label')}
                          description={t('form.scheduleType.description')}
                          placeholder={t('form.scheduleType.placeholder')}
                          menuPortalTarget={document.body}
                          options={scheduleTypeOptions}
                          value={
                            scheduleTypeField.value
                              ? scheduleTypeOptions.find(
                                  option => option.value === scheduleTypeField.value
                                )
                              : null
                          }
                          onChange={option => {
                            scheduleTypeHelpers.setValue(option?.value)
                          }}
                          error={!!scheduleTypeMeta.error && scheduleTypeMeta.touched}
                        />
                        <SelectField
                          id={dueDateTargetParamsField.name.replaceAll('.', '_')}
                          {...dueDateTargetParamsField}
                          label={t('form.dueDate.label')}
                          description={t('form.dueDate.description')}
                          placeholder={t('form.dueDate.placeholder')}
                          menuPortalTarget={document.body}
                          options={dueDateOptions}
                          value={
                            dueDateTargetParamsField.value
                              ? dueDateOptions.find(
                                  option => option.value === dueDateTargetParamsField.value
                                )
                              : null
                          }
                          onChange={option => {
                            dueDateTargetParamsHelpers.setValue(option?.value)
                          }}
                          error={!!dueDateTargetParamsMeta.error && dueDateTargetParamsMeta.touched}
                        />
                      </div>
                    )}

                    {triggerTypeField.value === ExtendedAutomationTriggerType.ENTITY_EVENT && (
                      <div className='grid grid-cols-2 gap-2'>
                        <SelectField
                          id={triggerParameterProductIdField.name.replaceAll('.', '_')}
                          {...triggerParameterProductIdField}
                          label={t('form.triggerParamProductId.label')}
                          description={t('form.triggerParamProductId.description')}
                          placeholder={t('form.triggerParamProductId.placeholder')}
                          menuPortalTarget={document.body}
                          options={productOptions}
                          value={
                            triggerParameterProductIdField.value
                              ? productOptions.find(
                                  option => option.value === triggerParameterProductIdField.value
                                )
                              : null
                          }
                          onChange={option => {
                            triggerParameterProductIdHelpers.setValue(option?.value)
                            triggerParameterProductNameHelpers.setValue(option?.label)
                          }}
                          error={
                            !!triggerParameterProductIdMeta.error &&
                            triggerParameterProductIdMeta.touched
                          }
                        />
                        <SelectField
                          id={dueDateTargetParamsField.name.replaceAll('.', '_')}
                          {...dueDateTargetParamsField}
                          label={t('form.dueDate.label')}
                          description={t('form.dueDate.description')}
                          placeholder={t('form.dueDate.placeholder')}
                          menuPortalTarget={document.body}
                          options={dueDateOptions}
                          value={
                            dueDateTargetParamsField.value
                              ? dueDateOptions.find(
                                  option => option.value === dueDateTargetParamsField.value
                                )
                              : null
                          }
                          onChange={option => {
                            dueDateTargetParamsHelpers.setValue(option?.value)
                          }}
                          error={!!dueDateTargetParamsMeta.error && dueDateTargetParamsMeta.touched}
                        />
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>
              <div className='flex flex-wrap justify-between gap-4'>
                <Help analyticsProperties={{ Source: 'Automations creation' }} />
                <div className='flex items-center gap-4'>
                  <ButtonWithIcon color='white' IconComponent={ArrowSmUpIcon} onClick={goPrevious}>
                    {t('previousText')}
                  </ButtonWithIcon>
                  <Button onClick={handleFinish}>
                    {t(isUpdate ? 'update.finishText' : 'create.finishText')}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </Step>
  )
}
