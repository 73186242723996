import { StakeHolderType } from '@/features/stakeHolders'
import {
  InlineSelectField,
  InlineSelectFieldProps,
  SelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { useDocumentTags } from '../../hooks'
import { useMemo, useState } from 'react'
import { useDebounceState } from '@/hooks/use-debounce-state'

export const DocumentTagsInlineSelectField = <isMulti extends boolean>({
  types,
  all,
  ...props
}: Omit<InlineSelectFieldProps<isMulti>, 'options'> & {
  types?: StakeHolderType[]
  all?: { label: string; value: string }
}) => {
  const { documentTags } = useDocumentTags()
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)

  const tagsOptions: SelectorOption[] = useMemo(() => {
    const filteredTags = debouncedSearch
      ? documentTags.filter(tag => tag.toLowerCase().includes(debouncedSearch?.toLowerCase() ?? ''))
      : documentTags
    const options = filteredTags.map(tag => ({
      label: tag,
      value: tag,
    }))
    return all?.label && all?.value ? [{ label: all.label, value: all.value }, ...options] : options
  }, [documentTags, all?.label, all?.value, debouncedSearch])

  return (
    <InlineSelectField
      {...props}
      options={tagsOptions}
      searchable
      onSearch={inputValue => {
        setSearch(inputValue)
      }}
    />
  )
}
