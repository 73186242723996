import { ComponentProps } from 'react'

export const SignatureIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg
      width={39}
      height={25}
      viewBox='0 0 39 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1.5 23.586c6.666-6.666 10-12 10-16 0-6-2-6-4-6s-4.064 2.17-4 6c.068 4.096 3.316 9.754 5 12 3 4 5 5 7 2l4-6c.666 5.334 2.666 8 6 8 1.06 0 5.278-4 6-4 1.034 0 3.034 1.334 6 4'
        stroke='#D1D5DB'
        strokeWidth={2.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
