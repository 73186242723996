import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  SelectField,
  SelectFieldProps,
  SelectorOption,
  createSelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { useState } from 'react'
import { usePaginatedProducts, useProductById } from '../hooks'
import { getProductNameWithReference } from '../utils'

export const ProductsSelectField = (
  props: Omit<SelectFieldProps, 'options' | 'selectAll' | 'selectAllText' | 'onSelectAll'> &
    Pick<{ productIdValue?: string }, 'productIdValue'>
) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const {
    isLoading: isLoadingOptionsProducts,
    products,
    fetchNextPage,
  } = usePaginatedProducts({
    filters: { search: debouncedSearch },
  })

  const { product: productValue, isLoading: isLoadingProductValue } = useProductById(
    props.productIdValue || '',
    {
      enabled: !!props.productIdValue,
    }
  )

  const productsOptions: SelectorOption[] = products.map(product => ({
    label: getProductNameWithReference(product),
    value: product._id,
  }))

  const value = productValue
    ? createSelectorOption(getProductNameWithReference(productValue), productValue._id)
    : props.value

  const isLoading = isLoadingOptionsProducts || isLoadingProductValue

  return (
    <SelectField
      {...props}
      value={value}
      isLoading={isLoading}
      options={productsOptions}
      onInputChange={inputValue => {
        setSearch(inputValue)
      }}
      onMenuScrollToBottom={() => {
        fetchNextPage()
      }}
    />
  )
}
