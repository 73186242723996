import {
  DEFAULT_PAGE_SIZE,
  PDF_MANUAL_EN_URL,
  PDF_MANUAL_ES_URL,
  TABLE_SIZE_PRESETS,
} from '@/app/constants'
import { useAnalytics } from '@/features/analytics'
import { useIsUserStockEnabled } from '@/features/auth'
import { useStakeHolderByIds } from '@/features/stakeHolders'
import { useDebounceState } from '@/hooks/use-debounce-state'
import { noop } from '@/utils/noop'
import { Badge, Button, COLOR, SIZE, Table } from '@blockchain-traceability-sl/tailwind-components'
import { ChevronDownIcon } from '@heroicons/react/outline'
import Big from 'big.js'
import { memo, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { usePaginatedStakeHolderProducts } from '../hooks'
import { StakeHolderProduct } from '../interfaces'
import { getStakeHolderProductNameWithReference } from '../utils'
import { useLocalStorage } from '@/hooks/use-local-storage'
import i18n from '@/i18n'

export interface StakeHolderProductsTableProps {
  globalFilter?: string
  /**
   * Must be memoized to avoid extra renders
   */
  onRowManage?: (row: StakeHolderProduct) => void
  /**
   * Must be memoized to avoid extra renders
   */
  onStockClick?: (row: StakeHolderProduct) => void
}

/**
 * Page size key for local storage
 * @see useLocalStorage
 * @internal
 * @constant
 * @nanoid length 10
 */
const PAGE_SIZE_KEY = 'RNwIsB5elu'

export const StakeHolderProductsTable = memo(
  ({ globalFilter, onRowManage = noop, onStockClick = noop }: StakeHolderProductsTableProps) => {
    const { t } = useTranslation('nsStakeHolderProductsListPage')
    const isUserStockEnabled = useIsUserStockEnabled()
    const analytics = useAnalytics()
    const [pageIndex, setPageIndex] = useState<number>(0)
    const [pageSize, setPageSize] = useLocalStorage(PAGE_SIZE_KEY, DEFAULT_PAGE_SIZE)

    const {
      stakeHolderProducts,
      totalCount,
      fetchNextPage,
      fetchPreviousPage,
      data,
      isLoading,
      isFetchingNextPage,
    } = usePaginatedStakeHolderProducts({ filters: { search: globalFilter }, pageSize })

    const { stakeHolders, isLoading: isStakeHoldersLoading } = useStakeHolderByIds(
      stakeHolderProducts
        .map(stakeHolderProduct => stakeHolderProduct.stakeHolder.id)
        .filter((item, index, self) => self.findIndex(selfItem => selfItem === item) === index)
    )

    const [isLoadingDebounced] = useDebounceState(
      isLoading || isFetchingNextPage || isStakeHoldersLoading
    )

    const getStockBadge = useCallback(
      (stakeHolderProduct: StakeHolderProduct) => {
        if (!stakeHolderProduct.quantity || new Big(stakeHolderProduct.quantity).lte(0)) {
          return (
            <Badge color={COLOR.RED} style={{ minWidth: 110 }}>
              {t('table.outOfStock')}
            </Badge>
          )
        } else {
          return (
            <Badge
              color={COLOR.GREEN}
              style={{ minWidth: 70 }}
              onClick={() => onStockClick(stakeHolderProduct)}
            >
              <span>{t('table.inStock')}</span>
              <span className='ml-1 -mr-1'>
                <ChevronDownIcon className='h-3 w-3' />
              </span>
            </Badge>
          )
        }
      },
      [onStockClick, t]
    )

    const handlePageChange = useCallback(
      (page: { pageIndex: number }) => {
        if (pageIndex < page.pageIndex) {
          fetchNextPage()
        } else if (pageIndex > page.pageIndex) {
          fetchPreviousPage()
        }
        setPageIndex(page.pageIndex)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [fetchNextPage, fetchPreviousPage]
    )

    const language = i18n.language

    const userManualUrl = useMemo(() => {
      if (language === 'es') {
        return PDF_MANUAL_ES_URL
      }
      return PDF_MANUAL_EN_URL
    }, [language])

    const rows = useMemo(
      () =>
        (data?.pages[pageIndex]?.items || []).map(stakeHolderProduct => ({
          ...stakeHolderProduct,
          id: stakeHolderProduct._id,
          name: getStakeHolderProductNameWithReference(stakeHolderProduct),
          provider: stakeHolders.find(
            stakeHolder => stakeHolder._id === stakeHolderProduct.stakeHolder.id
          )?.company.name,
          stockQuantity: `${t('number', { value: stakeHolderProduct.quantity })} ${
            stakeHolderProduct.measurementUnit || ''
          }`,
          stock: getStockBadge(stakeHolderProduct),
          actions: (
            <Button
              color='link'
              onClick={isUserStockEnabled ? () => onRowManage(stakeHolderProduct) : undefined}
            >
              {t('table.action.cell')}
            </Button>
          ),
        })),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [data?.pages, isUserStockEnabled, pageIndex, t, stakeHolders]
    )

    return (
      <Table
        manualPagination
        onPageChange={handlePageChange}
        onPageSizeChange={setPageSize}
        totalCount={totalCount}
        pageSize={pageSize}
        defaultPageIndex={pageIndex}
        className='onboarding-stakeholder-products-list'
        globalFilter={globalFilter}
        columns={[
          {
            Header: t(`table.name`).toString(),
            accessor: 'name',
          },
          {
            Header: t(`table.provider`).toString(),
            accessor: 'provider',
          },
          ...(isUserStockEnabled
            ? [
                {
                  Header: t('table.stock').toString(),
                  accessor: 'stock',
                },
                {
                  Header: t('table.stockQuantity').toString(),
                  accessor: 'stockQuantity',
                },
              ]
            : []),
          {
            Header: t(`table.totalReceptions`).toString(),
            accessor: 'receptions',
          },
          {
            Header: t('table.actions').toString(),
            accessor: 'actions',
            disableGlobalFilter: true,
          },
        ]}
        data={rows}
        onRowClick={
          isUserStockEnabled
            ? undefined
            : row =>
                onRowManage(
                  stakeHolderProducts.find(
                    ({ _id }) => _id === row.original.id
                  ) as StakeHolderProduct
                )
        }
        isLoading={isLoading || isFetchingNextPage || isStakeHoldersLoading || isLoadingDebounced}
        previousText={t('pagination.previous')}
        nextText={t('pagination.next')}
        renderInfoText={(from, to, total) => (
          <Trans
            t={t}
            i18nKey='pagination.show'
            components={{ b: <span className='font-semibold' /> }}
            values={{ from, to, total }}
          />
        )}
        sizePresetsText={t('pagination.items')}
        showSizePresets
        sizePresets={TABLE_SIZE_PRESETS}
        noDataText={
          <tr>
            <td colSpan={isUserStockEnabled ? 6 : 4} className='text-center p-8 space-y-6'>
              <p className='text-4xl leading-10 font-extrabold text-center whitespace-pre-wrap'>
                {t('table.noData')}
              </p>
              <Button
                color='secondary'
                size={SIZE.EXTRA_LARGE}
                className='m-auto'
                onClick={() => {
                  analytics.track('ACTION_CLICK_HELP', {
                    Source: 'Stakeholder products section',
                  })
                  window.open(userManualUrl, '_blank')
                }}
              >
                {t('table.needHelp')}
              </Button>
            </td>
          </tr>
        }
      />
    )
  }
)
