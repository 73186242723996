import { FormatFunction } from 'i18next'
import dayjs from 'dayjs'

import 'dayjs/locale/es'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/ar'

export const formater: FormatFunction = (value, rawFormat = '', lng) => {
  // Date formater
  if (value instanceof Date) {
    switch (rawFormat) {
      case 'relative':
        // https://day.js.org/docs/en/display/from-now
        return dayjs(value).fromNow()

      default:
        // Format Date instances following a format https://day.js.org/docs/en/display/format
        return dayjs(value).format(rawFormat)
    }
  }

  const [format, ...additionalFormatProperties] = rawFormat.split(',').map(v => v.trim())

  switch (format) {
    // Format strings replacing white spaces to underscore
    case 'snakecase':
      return value.replace(/ /g, '_').toLowerCase()

    // Format currency amounts replacing white spaces to underscore
    case 'currency':
      return Intl.NumberFormat(lng, {
        style: 'currency',
        currency: additionalFormatProperties[0],
      }).format(Number(value))

    /**
     * Format numbers
     * Legacy format function i18next<21.3.0
     * @see https://www.i18next.com/translation-function/formatting#number
     */
    case 'number':
      return Intl.NumberFormat(lng, {
        style: 'decimal',
        maximumFractionDigits: Number(additionalFormatProperties[0]) || 20,
      }).format(Number(value))

    default:
      return value
  }
}
