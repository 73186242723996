import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export const useSearchParams = <Params extends { [K in keyof Params]?: string }>(): {
  [p in keyof Params]?: string
} => {
  const searchParameters = new URLSearchParams(useLocation().search)

  return Object.fromEntries(searchParameters) as {
    [p in keyof Params]?: string
  }
}

// eslint-disable-next-line unicorn/prevent-abbreviations
export const useSearchParam = <T extends string = string>(name: string): T | null => {
  const searchParameters = new URLSearchParams(useLocation().search)

  return searchParameters.get(name) as T | null
}

// eslint-disable-next-line unicorn/prevent-abbreviations
export const useSearchArrayParam = (name: string): string[] => {
  const { search } = useLocation()
  const searchParameters = new URLSearchParams(search)

  return (
    searchParameters
      .getAll(name)
      // Remove posible repeated values
      .filter((item, index, self) => index === self.findIndex(selfItem => selfItem === item))
  )
}

export const useUpdateSearchParameter = (): ((
  name: string,
  value: string,
  options?: { replace?: boolean }
) => void) => {
  const history = useHistory()
  return useCallback(
    (name: string, value: string) => {
      const search = new URLSearchParams(window.location.search)
      search.set(name, value)

      history.push(`${window.location.pathname}?${search}`)
    },
    [history]
  )
}
