import { api } from '@/app/api'
import { Paginated, PaginationParams } from '@/hooks/use-pagination'
import { Facility, FacilityCreation, FacilityUpdate } from './interfaces'
export const FACILITIES_URL = '/hydra/facilities'

export const getPaginated = async (
  {
    search,
  }: {
    search?: string
  } = {},
  pagination: PaginationParams
): Promise<Paginated<Facility>> => {
  const searchParams = new URLSearchParams()

  if (search) {
    searchParams.append('search', search)
  }
  searchParams.append('limit', pagination.limit.toString())
  searchParams.append('offset', pagination.offset.toString())
  const { data } = await api.get<Paginated<Facility>>(
    `${FACILITIES_URL}?${searchParams.toString()}`
  )
  return data
}

export const remove = async (facility: Facility) => {
  await api.delete(`${FACILITIES_URL}/${encodeURIComponent(facility._id)}`)
}

export const createFacility = async (facility: FacilityCreation): Promise<string> => {
  const { data } = await api.post<string>(`${FACILITIES_URL}`, facility)
  return data
}

/**
 * Create Facilities with CSV file
 */
export const createWithCSV = async (file: File): Promise<{ total: number }> => {
  const formData = new FormData()
  formData.append('file', file)
  const { data } = await api.post<{ total: number }>(`${FACILITIES_URL}/csv`, formData)
  return data
}

export const updateFacility = async (id: string, facilityUpdate: FacilityUpdate): Promise<void> => {
  await api.patch<void>(`${FACILITIES_URL}/${id}`, facilityUpdate)
}

export const getById = async (id: string): Promise<Facility> => {
  const { data: facility } = await api.get<Facility>(`${FACILITIES_URL}/${id}`)
  return facility
}

/**
 * Get Facilities CSV template
 */
export const getCsvTemplate = async (): Promise<ArrayBuffer> => {
  const { data } = await api.get<ArrayBuffer>(`${FACILITIES_URL}/csv`, {
    responseType: 'arraybuffer',
  })
  return data
}
