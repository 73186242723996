import { useSearchParam as useSearchParameter } from './use-search-params'
import { useHistory, useLocation } from 'react-router-dom'
import { useCallback } from 'react'

// Constants params
const REFERRER = 'referrer'
const REFERRER_FIELD_NAME = 'referrerFieldName'
const REFERRER_RESULT_NAME = 'referrerResultName'
const REFERRER_RESULT_VALUE = 'referrerResultValue'

interface ReferrerConfig {
  /**
   * Url where referrer should navigate
   */
  url: string
  /**
   * Field name of referrer result
   * Set this value if you want to update a field with referrer result
   * Set null if you don't want to update any field
   */
  fieldName: string | null
  /**
   * Redirect url with referrer result
   * @default currentUrl
   */
  redirectUrl?: string
  /**
   * Redirect url with referrer result
   * @default currentUrl
   */
  searchParams?: Record<string, string>
}

interface UseReferrerResponse {
  // Current referrer
  referrer: string | null
  // Field context
  referrerFieldName: string | null
  // Current referrer response field
  referrerResultName: string | null
  // Current referrer response value
  referrerResultValue: string | null
  // Navigation function with referrer feature
  navigateWithReferrer: (config: ReferrerConfig) => void
  // Url with referrer feature to redirect
  generateReferrerUrl: (config: ReferrerConfig) => string
  // Redirects to referrer url
  backToReferrer: (defaultRedirect: string, fieldValue?: string) => void
  // Update form values with referrer results
  updateFormWithReferrerResult: <Values = Record<string, unknown>>(
    setFieldValueFunction: (field: string, value: unknown) => void,
    initialValues: Values
  ) => void
}

export const useReferrer = (): UseReferrerResponse => {
  const referrer = useSearchParameter(REFERRER)
  const referrerFieldName = useSearchParameter(REFERRER_FIELD_NAME)
  const referrerResultName = useSearchParameter(REFERRER_RESULT_NAME)
  const referrerResultValue = useSearchParameter(REFERRER_RESULT_VALUE)
  const history = useHistory()
  const location = useLocation()

  const generateReferrerUrl = useCallback(
    ({
      url,
      fieldName,
      searchParams,
      redirectUrl = `${location.pathname}${location.search}`,
    }: ReferrerConfig) => {
      const urlSearchParams = new URLSearchParams({
        ...searchParams,
        [REFERRER]: redirectUrl,
      })

      if (fieldName) {
        urlSearchParams.set(REFERRER_FIELD_NAME, fieldName)
      }

      return `${url}?${urlSearchParams.toString()}`
    },
    [location.pathname, location.search]
  )

  const navigateWithReferrer = useCallback(
    (config: ReferrerConfig) => {
      history.push(generateReferrerUrl(config))
    },
    [history, generateReferrerUrl]
  )

  const backToReferrer = useCallback(
    (defaultRedirect: string, referrerResult?: string) => {
      if (referrer) {
        const fieldName = new URLSearchParams(location.search).get(REFERRER_FIELD_NAME)
        const referrerUrl = new URL(referrer, window.location.origin)

        history.replace(
          `${referrerUrl.pathname}?${new URLSearchParams({
            ...Object.fromEntries(referrerUrl.searchParams.entries()),
            ...(fieldName && referrerResult
              ? {
                  [REFERRER_RESULT_NAME]: fieldName,
                  [REFERRER_RESULT_VALUE]: referrerResult,
                }
              : {}),
          })}`
        )
      } else {
        history.replace(defaultRedirect)
      }
    },
    [history, location.search, referrer]
  )

  const updateFormWithReferrerResult = useCallback(
    <Values = Record<string, unknown>>(
      setFieldValueFunction: (field: string, value: unknown) => void,
      initialValues: Values
    ): void => {
      if (referrerResultName && referrerResultValue) {
        const valueToUpdate = initialValues[referrerResultName as keyof typeof initialValues]
        if (Array.isArray(valueToUpdate)) {
          setFieldValueFunction(
            referrerResultName,
            [...valueToUpdate, referrerResultValue]
              // Remove repeated values
              .filter(
                (item, index, self) => self.findIndex(selfItem => selfItem === item) === index
              )
          )
        } else {
          setFieldValueFunction(referrerResultName, referrerResultValue)
        }
      }
    },
    [referrerResultName, referrerResultValue]
  )

  return {
    referrer,
    referrerFieldName,
    referrerResultName,
    referrerResultValue,
    navigateWithReferrer,
    generateReferrerUrl,
    backToReferrer,
    updateFormWithReferrerResult,
  }
}
