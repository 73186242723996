import {
  ButtonWithIcon,
  COLOR,
  SidebarItem,
  SidebarItemProps,
} from '@blockchain-traceability-sl/tailwind-components'
import { Dialog, Transition } from '@headlessui/react'
import { LockClosedIcon, PlusIcon, XIcon } from '@heroicons/react/outline'
import { ComponentType, Fragment, useMemo } from 'react'
import trazableLogoWhite from '@/assets/images/brand/logo-white-400-100.png'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { useSidebarNavigation } from '@/routes/sidebar-navigation'
import { useTranslation } from 'react-i18next'
import { useCompanyUser, useMyProfile, useUserPermissions } from '@/features/auth'
import { useAnalytics } from '@/features/analytics'
import { CreateNewModalPermissionScopes } from './ModalCreateNew'
import { NotificationBellButton } from '@/features/notifications'
import { useLayout } from '../context'
import { useImageMeta } from '@/hooks/use-image-meta'
import classNames from 'classnames'
import { SubscriptionTrialInfo } from '@/features/pricing'

/* Static sidebar for mobile */
export const SidebarMobile = () => {
  const { t } = useTranslation('nsSidebar')
  const location = useLocation()
  const history = useHistory()
  const { toggleSidebar, sidebar } = useLayout()
  const company = useCompanyUser()
  const { profile } = useMyProfile()
  const sidebarNavigation = useSidebarNavigation()
  const analytics = useAnalytics()
  const userPermissions = useUserPermissions()

  const shouldDisplayCreateButton = useMemo(
    () => CreateNewModalPermissionScopes.some(scopeKey => userPermissions[scopeKey].write),
    [userPermissions]
  )

  // Account logo image meta
  const [logoImageMeta] = useImageMeta(
    company.media.logo && company.media.logo !== 'default' ? company.media.logo : trazableLogoWhite
  )

  return (
    <div>
      <Transition.Root show={sidebar.isOpen} as={Fragment}>
        <Dialog
          as='div'
          static
          className='fixed inset-0 flex z-40 lg:hidden'
          open={sidebar.isOpen}
          onClose={() => toggleSidebar(false)}
          data-testid='sidebar-mobile'
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <div className='relative flex-1 flex flex-col max-w-xs w-full bg-blue-700'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute top-0 right-0 -mr-12 pt-2'>
                  <button
                    className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                    onClick={() => toggleSidebar(false)}
                  >
                    <span className='sr-only'>Close sidebar</span>
                    <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
                  </button>
                </div>
              </Transition.Child>

              <div className='flex-1 flex flex-col h-0 pt-5 pb-4 overflow-y-auto'>
                <div className='flex-shrink-0 flex items-center px-4'>
                  <img
                    className={classNames('m-auto rounded-sm', {
                      // The aspect ratio of the logo could be 1:1 or 1:4 for default and legacy logos
                      'h-8': logoImageMeta && logoImageMeta.aspectRatio > 1,
                      'w-1/2': logoImageMeta && logoImageMeta.aspectRatio <= 1,
                    })}
                    src={
                      company.media.logo && company.media.logo !== 'default'
                        ? company.media.logo
                        : trazableLogoWhite
                    }
                    alt='Trazable'
                  />
                </div>

                {shouldDisplayCreateButton && (
                  <div className='mt-2 px-2 space-y-1'>
                    <ButtonWithIcon
                      IconComponent={PlusIcon}
                      iconPosition='trailing'
                      color='white'
                      className='w-full'
                      onClick={() => {
                        analytics.track('ACTION_CLICK_CREATE_GLOBAL')
                        toggleSidebar(false)
                        toggleSidebar(true)
                      }}
                    >
                      {t('createNew')}
                    </ButtonWithIcon>
                  </div>
                )}

                <nav className='mt-5 flex-1 px-2 space-y-1'>
                  {sidebarNavigation
                    .filter(item => !item.isExternal)
                    .map(item => (
                      <SidebarItem
                        key={item.name}
                        title={item.name}
                        IconComponent={item.icon}
                        as={NavLink as ComponentType}
                        to={item.path}
                        active={item.path ? location.pathname.startsWith(item.path) : undefined}
                        {...(item.lock
                          ? {
                              badge: <LockClosedIcon className='h-4 w-4' />,
                              badgeColor: COLOR.RED,
                              badgeClassname: 'pr-1 pl-1 pt-1 pb-1',
                            }
                          : { badge: item.badge })}
                        onClick={() => {
                          if (item.section) {
                            analytics.track('ACTION_CLICK_MENU_SECTION', {
                              Section: item.section,
                            })
                            toggleSidebar(false)
                          }
                        }}
                        className={item.testId}
                        subItems={item.subItems?.map(
                          (subItem): SidebarItemProps => ({
                            key: subItem.name,
                            IconComponent: subItem.icon,
                            title: subItem.name,
                            active: subItem.path
                              ? location.pathname.startsWith(subItem.path)
                              : undefined,
                            as: NavLink as ComponentType,
                            to: subItem.path,
                            'data-testid': subItem.testId,
                            ...(subItem.lock
                              ? {
                                  badge: <LockClosedIcon className='h-4 w-4' />,
                                  badgeColor: COLOR.RED,
                                  badgeClassname: 'pr-1 pl-1 pt-1 pb-1',
                                }
                              : { badge: subItem.badge }),
                            onClick: () => {
                              if (subItem.section) {
                                analytics.track('ACTION_CLICK_MENU_SECTION', {
                                  Section: subItem.section,
                                })
                                toggleSidebar(false)
                              }
                            },
                          })
                        )}
                      />
                    ))}
                </nav>

                <div className='px-3'>
                  <SubscriptionTrialInfo />
                </div>
              </div>

              <div className='flex-shrink-0 flex justify-between border-t border-white p-4'>
                {/* Profile button */}
                <div className='onboarding-profile flex-shrink-0'>
                  <button
                    className='group block focus:outline-none'
                    onClick={() => {
                      analytics.track('ACTION_CLICK_MENU_SECTION', { Section: 'Profile' })
                      history.push('/profile')
                      toggleSidebar(false)
                    }}
                  >
                    <div className='flex'>
                      <div className='text-left'>
                        <p className='text-sm font-medium text-white  truncate w-44'>
                          {profile?.companyOwner ? profile.company.name : profile?.user.name}
                        </p>
                        <p className='text-xs font-medium text-blue-200 group-hover:text-white'>
                          {t('viewProfile')}
                        </p>
                      </div>
                    </div>
                  </button>
                </div>

                {/* Notification button */}
                <NotificationBellButton
                  className='flex-shrink'
                  data-testid='notification-button-mobile'
                  onClick={() => toggleSidebar(false)}
                />
              </div>
            </div>
          </Transition.Child>

          <div className='flex-shrink-0 w-14' aria-hidden='true'>
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}
