import { DateRangeLocaleField } from '@/components/DateRangeLocaleField'
import { ButtonWithIcon, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { Popover } from '@headlessui/react'
import { FilterIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePopper } from 'react-popper'
import { DocumentTagsInlineSelectField } from './DocumentTagsInlineSelectField'
import { TemplateType, TemplatesInlineSelectField } from '@/features/templates'
import { DocumentFiltersState } from './interfaces'

export const AdvancedFiltersMenu = ({
  filters,
  onFilterChange,
  all,
}: {
  filters: DocumentFiltersState
  onFilterChange: (key: string, filtersPatch: Partial<DocumentFiltersState>) => void
  all: { label: string; value: string }
}) => {
  const { t } = useTranslation('nsDocumentsViewPage')

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const popper = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-80, 10],
        },
      },
    ],
  })

  /**
   * This state is used to prevent the popover from closing when the user clicks on the date picker
   */
  const [isStatic, setIsStatic] = useState<boolean>(false)

  return (
    <Popover className='relative'>
      <Popover.Button
        as='div'
        ref={element => {
          setReferenceElement(element)
        }}
      >
        <ButtonWithIcon IconComponent={FilterIcon} color='white' size={SIZE.SMALL}>
          {t('filters.action')}
        </ButtonWithIcon>
      </Popover.Button>

      <Popover.Panel static={isStatic} focus={false}>
        <div
          ref={setPopperElement}
          style={popper.styles.popper}
          {...popper.attributes.popper}
          className='z-30 bg-white shadow-2xl rounded-lg p-6 w-80 flex flex-col gap-6'
        >
          <DocumentTagsInlineSelectField
            id='advancedFilterMenuTags'
            name='advancedFilterMenuTags'
            multiple
            all={all}
            onChange={options => {
              const value = options?.map(option => option.value)
              onFilterChange('tags', { tags: value })
            }}
            value={
              filters.tags.length > 0
                ? filters.tags.map(tag => ({
                    label: tag,
                    value: tag,
                  }))
                : [all]
            }
            label={`${t('advancedFilters.list.tag.title')} `}
            searchPlaceholderText={t('filters.dropdown.search')}
            menuClassName='z-10'
          />

          <div className='max-h-80 flex flex-col gap-2'>
            <DateRangeLocaleField
              id='advancedFilterMenuExpiredAtRange'
              name='advancedFilterMenuExpiredAtRange'
              value={{
                startDate: filters.startExpiredAt,
                endDate: filters.endExpiredAt,
              }}
              onChange={value => {
                onFilterChange('expiredAt', {
                  startExpiredAt: value.startDate,
                  endExpiredAt: value.endDate,
                })
              }}
              leadingAddon={`${t('advancedFilters.list.date.title')} `}
              onPopperChange={isOpen => {
                setIsStatic(isOpen)
              }}
              isClearable
              leadingOffset={20}
              inputClassName='truncate text-sm'
              popperPlacement='bottom-end'
            />
          </div>

          <TemplatesInlineSelectField
            id='advancedFilterMenuTemplates'
            name='advancedFilterMenuTemplates'
            multiple
            all={all}
            onChange={options => {
              const value = options?.map(option => ({
                id: option.value,
                name: option.label,
              }))
              onFilterChange('creationTriggerTemplates', { creationTriggerTemplates: value })
            }}
            value={
              filters.creationTriggerTemplates.length > 0
                ? filters.creationTriggerTemplates.map(item => ({
                    label: item.name,
                    value: item.id,
                  }))
                : [all]
            }
            label={`${t('advancedFilters.list.form.title')} `}
            searchPlaceholderText={t('filters.dropdown.search')}
            menuClassName='z-10'
            types={[TemplateType.COMPLIANCE]}
          />
        </div>
      </Popover.Panel>
    </Popover>
  )
}
