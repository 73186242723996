import '@blockchain-traceability-sl/tailwind-components/dist/styles.css'
import '@/index.css'
import '@/i18n'
import { App } from '@/app/App'
import { StrictMode } from 'react'
import { render } from 'react-dom'
import reportWebVitals from '@/reportWebVitals'
import config from './config'
import { analyticsInitialize } from '@/features/analytics'
import { sentryInitialize } from '@/app/sentry'

analyticsInitialize(config.SEGMENT_WRITE_KEY)
sentryInitialize(config.SENTRY_DSN)

render(
  <StrictMode>
    <App
      accessURL={`${window.location.pathname}${window.location.search}`
        .replace(/paymentStatus=success/g, '')
        .replace(/paymentStatus=error/g, '')}
    />
  </StrictMode>,
  document.getElementById('root')
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
