import { DotsVerticalIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
import { Dropdown } from './Dropdown'

interface TableRowDropdownProps {
  onAddRowClick: () => void
  onRemoveRowClick: () => void
  disableRemove?: boolean
}

export const TableRowDropdown = ({
  onAddRowClick,
  onRemoveRowClick,
  disableRemove,
}: TableRowDropdownProps) => {
  const { t } = useTranslation('nsTemplate')

  return (
    <Dropdown
      button={
        <Dropdown.IconButton>
          <span className='sr-only'>Open options</span>
          <DotsVerticalIcon className='h-5 w-5' aria-hidden='true' />
        </Dropdown.IconButton>
      }
    >
      <Dropdown.Item onClick={onAddRowClick}>{t('entries.table.menuItems.addRow')}</Dropdown.Item>

      <Dropdown.Item
        onClick={!disableRemove ? onRemoveRowClick : undefined}
        disabled={disableRemove}
      >
        {t('entries.table.menuItems.removeRow')}
      </Dropdown.Item>
    </Dropdown>
  )
}
