import {
  SidebarItem,
  SidebarItemProps,
  SidebarItemRef,
} from '@blockchain-traceability-sl/tailwind-components'
import { useEffect, useRef } from 'react'
import { useCurrentOnboardingStep } from '../hooks'

export const SidebarWithOnboarding = ({
  onboardingSection,
  ...props
}: SidebarItemProps & { onboardingSection?: string }) => {
  const sidebarItemRef = useRef<SidebarItemRef>(null)
  const step = useCurrentOnboardingStep()

  useEffect(() => {
    if (step && step.section && sidebarItemRef.current && step.section !== onboardingSection) {
      sidebarItemRef.current.closeSubMenu()
    }
  }, [step, onboardingSection])
  return <SidebarItem {...props} ref={sidebarItemRef} />
}
