import { useTranslation } from 'react-i18next'
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query'
import { customErrorToTrack, useAnalytics } from '../analytics'
import * as NotifyService from '@/services/notify.service'
import { create, getAll, getById, getByTemplateId, remove, update } from './service'
import { AxiosError } from 'axios'
import { useHistory } from 'react-router-dom'
import { Automation, AutomationInput, AutomationTriggerType } from './interfaces'
import { useMemo } from 'react'
import { QUERY_AUDIT_AUTOMATION } from '../audits'

export const QUERY_AUTOMATIONS_KEY = 'automations'

const getTriggerType = (variables: AutomationInput): string => {
  if (variables.trigger?.type === AutomationTriggerType.SCHEDULE) {
    if (variables.target.params.isExpired) {
      return 'Reschedule task'
    } else {
      return 'Frequency tasks'
    }
  }
  return 'Tasks associated with a batch'
}

export const useCreateAutomation = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const history = useHistory()

  const { mutate, mutateAsync, ...rest } = useMutation(create, {
    onSuccess: async (data, variables) => {
      analytics.track('CUSTOMER_CREATE_AUTOMATION', {
        Automation: variables.name,
        Type: getTriggerType(variables),
      })

      NotifyService.success({
        title: t('automations.create.title'),
        description: t('automations.create.description'),
        actionText: t('automations.create.action'),
        closeText: t('close'),
        onActionClick: () => history.push('/automations/create'),
      })
      queryClient.invalidateQueries(QUERY_AUTOMATIONS_KEY)
    },
    onError: (error: AxiosError) => {
      analytics.track(
        'CUSTOMER_CREATE_AUTOMATION_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return {
    ...rest,
    createAutomation: mutate,
    createAutomationAsync: mutateAsync,
  }
}

export const useAutomations = (
  options?: Omit<
    UseQueryOptions<Automation[], AxiosError, Automation[], string[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery([QUERY_AUTOMATIONS_KEY], getAll, options)
  return { ...rest, automations: useMemo(() => data || [], [data]) }
}

export const useUpdateAutomation = (automationId: string) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()

  const { mutate, mutateAsync, ...rest } = useMutation(
    (params: AutomationInput) => update(automationId, params),
    {
      onSuccess: async (data, variables) => {
        analytics.track('CUSTOMER_EDIT_AUTOMATION', {
          Automation: variables.name,
          Type: getTriggerType(variables),
        })
        NotifyService.success({
          title: t('automations.update.title'),
          description: t('automations.update.description'),
          closeText: t('close'),
        })
        queryClient.invalidateQueries([QUERY_AUTOMATIONS_KEY, automationId])
        queryClient.invalidateQueries(QUERY_AUDIT_AUTOMATION)
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_EDIT_AUTOMATION_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    updateAutomation: mutate,
    updateAutomationAsync: mutateAsync,
  }
}

export const useAutomation = (
  automationId: string,
  options?: Omit<
    UseQueryOptions<Automation | undefined, AxiosError, Automation, string[]>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(
    [QUERY_AUTOMATIONS_KEY, automationId],
    () => getById(automationId),
    options
  )
  return { ...rest, automation: data }
}

export const useDeleteAutomation = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const history = useHistory()

  const { mutate, mutateAsync, ...rest } = useMutation(remove, {
    onSuccess: async () => {
      NotifyService.success({
        title: t('automations.delete.title'),
        description: t('automations.delete.description'),
        actionText: t('automations.delete.action'),
        closeText: t('close'),
        onActionClick: () => history.push('/automations/create'),
      })
      queryClient.invalidateQueries(QUERY_AUTOMATIONS_KEY)
    },
    onError: (error: AxiosError) => {
      analytics.track(
        'CUSTOMER_DELETE_AUTOMATION_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return {
    ...rest,
    deleteAutomation: mutate,
    deleteAutomationAsync: mutateAsync,
  }
}

export const useAutomationByTemplateId = (
  templateId: string = '',
  options?:
    | Omit<
        UseQueryOptions<Automation[], unknown, Automation[], (string | { templateId: string })[]>,
        'queryKey' | 'queryFn'
      >
    | undefined
) => {
  const { data, ...rest } = useQuery(
    [QUERY_AUTOMATIONS_KEY, { templateId }],
    () => getByTemplateId(templateId),
    options
  )
  return { ...rest, automations: data }
}
