import classNames from 'classnames'
import { ReactNode } from 'react'
import { EntryNaCheck } from './EntryNaCheck'

export interface EntryLabelProps {
  children?: ReactNode

  naCheckboxId: string
  naCheckboxName: string
  naUnchecked: boolean
  naCheckboxLabel: string
  lightTextOnNAChecked?: boolean
  onNaChange: (value: boolean) => void
  withNa?: boolean

  mandatoryPrefixText?: string
  isMandatory?: boolean

  lightTextOnDisabled?: boolean
  disabled?: boolean
}

export const EntryLabel = ({
  naCheckboxId,
  naCheckboxName,
  children,
  naUnchecked,
  naCheckboxLabel,
  lightTextOnNAChecked,
  onNaChange,
  withNa,
  mandatoryPrefixText,
  isMandatory,
  lightTextOnDisabled,
  disabled,
}: EntryLabelProps) => {
  const labelContent = isMandatory ? (
    <>
      {children} {mandatoryPrefixText}
    </>
  ) : (
    children
  )

  if (disabled) {
    return (
      <span
        className={classNames('text-sm font-medium ', {
          'text-gray-400': lightTextOnDisabled,
          'text-gray-700': !lightTextOnDisabled,
        })}
      >
        {labelContent}
      </span>
    )
  }

  const displayNa = withNa ?? !isMandatory

  return (
    <div className='flex justify-between items-center'>
      <div
        className={classNames('flex items flex-nowrap pr-20', {
          'text-gray-400': !naUnchecked && lightTextOnNAChecked && displayNa,
          'cursor-not-allowed': !naUnchecked && displayNa,
        })}
      >
        {labelContent}
      </div>
      {displayNa && (
        <EntryNaCheck
          checkboxLabel={naCheckboxLabel}
          unchecked={naUnchecked}
          id={naCheckboxId}
          name={naCheckboxName}
          onChange={onNaChange}
        />
      )}
    </div>
  )
}
