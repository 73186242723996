import { SidebarNavigation } from '@/routes/router'
import {
  BookOpenIcon,
  ChartPieIcon,
  ChatAltIcon,
  ClipboardCheckIcon,
  CubeIcon,
  DocumentDuplicateIcon,
  DocumentReportIcon,
  DocumentTextIcon,
  FolderIcon,
  GlobeAltIcon,
  GlobeIcon,
  HomeIcon,
  LightningBoltIcon,
  OfficeBuildingIcon,
  QrcodeIcon,
  SearchIcon,
  TruckIcon,
  UserGroupIcon,
} from '@heroicons/react/outline'
import { useMyTasksCount } from '@/features/tasks'
import {
  useIsUserCompany,
  useIsUserFacilitiesEnabled,
  useUserAddons,
  useUserPermissions,
} from '@/features/auth'
import {
  ValidationRequestType,
  usePendingToValidateValidationsRequestCount,
} from '@/features/validationRequests'
import formatRoutes from './format-routes'
import { RawMaterialIcon } from '@/features/stakeHolderProducts'
import { useFeatureFlag } from '@/features/flags'
import { FacilitiesIcon } from '@/features/facilities'
import { useTranslation } from 'react-i18next'
import { BoxIcon, FoodAlertIcon } from '@blockchain-traceability-sl/tailwind-components'

export const useSidebarNavigation = (): SidebarNavigation => {
  const userAddons = useUserAddons()
  const userPermissions = useUserPermissions()
  const isUserCompany = useIsUserCompany()

  const isUserFacilitiesEnabled = useIsUserFacilitiesEnabled()

  const { t } = useTranslation('nsSidebar')

  const { value: flagFoodAlerts } = useFeatureFlag('food_alerts')

  const { count: pendingTasksCount } = useMyTasksCount(
    {
      status: ['open'],
    },
    {
      enabled: userAddons.registries.enabled,
    }
  )
  const { count: taskValidationRequestsPendingCount } = usePendingToValidateValidationsRequestCount(
    {
      types: [ValidationRequestType.TASK],
    },
    {
      enabled: userAddons.registries.enabled,
    }
  )

  const { count: documentValidationRequestsPendingCount } =
    usePendingToValidateValidationsRequestCount(
      {
        types: [ValidationRequestType.DOCUMENT],
      },
      {
        enabled: userAddons.documents.upload.enabled,
      }
    )

  const routes: SidebarNavigation = [
    {
      name: t('navigation.home'),
      path: '/home',
      icon: HomeIcon,
      section: 'Dashboard',
      isAvailable: isUserCompany || userPermissions.tasks.admin,
      testId: 'sidebar-home-menu',
    },
    {
      name: t('navigation.menuProduction'),
      path: null,
      icon: BoxIcon,
      section: null,
      testId: 'sidebar-production-menu',

      onboarding: { section: 'production' },
      subItems: [
        {
          name: t('navigation.stakeHolders'),
          path: '/stakeholders',
          icon: UserGroupIcon,
          section: 'My stakeholders',
          isAvailable: userPermissions?.stakeHolders.read || userPermissions?.stakeHolders.write,
          onboarding: { key: 'stakeholders' },
          testId: 'sidebar-stakeholders-submenu',
        },
        {
          name: t('navigation.facilities'),
          path: '/facilities',
          icon: FacilitiesIcon,
          section: 'Production facilities',
          isAvailable:
            isUserFacilitiesEnabled &&
            (userPermissions?.products.read || userPermissions?.products.write),
          onboarding: { key: 'facilities' },
          testId: 'sidebar-facilities-submenu',
        },
        {
          name: t('navigation.stakeHolderProducts'),
          path: '/stakeholder-products',
          icon: RawMaterialIcon,
          section: 'Acquired products',
          isAvailable:
            userPermissions?.stakeHolderProducts.read || userPermissions?.stakeHolderProducts.write,
          onboarding: { key: 'stakeholder-products' },
          testId: 'sidebar-stakeholder-products-submenu',
        },
        {
          name: t('navigation.products'),
          path: '/products',
          icon: OfficeBuildingIcon,
          section: 'My products',
          isAvailable: userPermissions?.products.read || userPermissions?.products.write,
          onboarding: { key: 'products' },
          testId: 'sidebar-products-submenu',
        },
        {
          name: t('navigation.metadataTemplates'),
          path: '/metadata-templates',
          icon: DocumentDuplicateIcon,
          section: 'Data templates',
          isAvailable:
            userPermissions?.metadataTemplates.read || userPermissions?.metadataTemplates.write,
          onboarding: { key: 'metadata-templates' },
          testId: 'sidebar-metadata-templates-submenu',
        },
        {
          name: t('navigation.trus'),
          path: '/trus',
          icon: CubeIcon,
          section: 'My TRUs',
          isAvailable: userPermissions?.trus.read || userPermissions?.trus.write,
          onboarding: { key: 'trus' },
          testId: 'sidebar-trus-submenu',
        },
        {
          name: t('navigation.shipments'),
          path: '/shipments',
          icon: TruckIcon,
          section: 'Shipments',
          isAvailable: userPermissions?.trus.read || userPermissions?.trus.write,
          onboarding: { key: 'shipments' },
          testId: 'sidebar-shipments-submenu',
        },
        {
          name: t('navigation.foodAlerts'),
          path: '/food-alerts',
          icon: FoodAlertIcon,
          section: 'Food alerts',
          isAvailable: flagFoodAlerts,
          onboarding: { key: 'foodAlerts' },
          testId: 'sidebar-foodAlerts-submenu',
        },
      ],
    },
    {
      name: t('navigation.menuTransparency'),
      path: null,
      icon: SearchIcon,
      section: null,
      onboarding: { section: 'transparency' },
      testId: 'sidebar-transparency-menu',
      subItems: [
        {
          name: t('navigation.traceability'),
          path: '/traceability',
          icon: GlobeIcon,
          section: 'Traceability',
          isAvailable: userPermissions?.traceability.read || userPermissions?.traceability.write,
          onboarding: { key: 'traceability' },
          testId: 'sidebar-traceability-submenu',
        },
        {
          name: t('navigation.qrCodes'),
          path: '/transparency/qr-codes',
          icon: QrcodeIcon,
          section: 'My QR codes',
          lock: !userAddons?.transparency.enabled,
          isAvailable: userPermissions?.qr.read || userPermissions?.qr.write,
          onboarding: { key: 'qr-codes' },
          testId: 'sidebar-qrcodes-submenu',
        },
      ],
    },
    {
      name: t('navigation.menuDigitalization'),
      path: null,
      icon: BookOpenIcon,
      section: null,
      testId: 'sidebar-digitalization-menu',
      onboarding: { section: 'digitalization' },
      subItems: [
        {
          name: t('navigation.reports'),
          path: '/reports/create',
          icon: DocumentReportIcon,
          section: 'Reports',
          isAvailable: userPermissions?.reports.write,
          onboarding: { key: 'reports' },
          testId: 'sidebar-reports-submenu',
        },
        {
          name: t('navigation.documents'),
          path: '/documents',
          icon: FolderIcon,
          section: 'Document management',
          badge:
            documentValidationRequestsPendingCount > 0
              ? documentValidationRequestsPendingCount
              : undefined,
          isAvailable: userPermissions?.documents.read || userPermissions?.documents.write,
          onboarding: { key: 'documents' },
          testId: 'sidebar-documents-submenu',
        },
        {
          name: t('navigation.departments'),
          path: '/departments',
          icon: GlobeAltIcon,
          section: 'Departments',
          lock: !userAddons?.departments.enabled,
          testId: 'sidebar-departments-submenu',
          isAvailable: userPermissions?.departments.read || userPermissions?.departments.write,
          onboarding: { key: 'departments' },
        },
        {
          name: t('navigation.forms'),
          path: '/forms',
          icon: DocumentTextIcon,
          section: 'Forms',
          testId: 'sidebar-forms-submenu',
          lock: !userAddons?.registries.enabled,
          isAvailable: userPermissions?.forms.read || userPermissions?.forms.write,
          onboarding: { key: 'forms' },
        },
        {
          name: t('navigation.tasks'),
          path: '/tasks',
          icon: ClipboardCheckIcon,
          section: 'Tasks',
          testId: 'sidebar-tasks-submenu',
          badge:
            pendingTasksCount + taskValidationRequestsPendingCount > 0
              ? pendingTasksCount + taskValidationRequestsPendingCount
              : undefined,
          lock: !userAddons?.registries.enabled,
          isAvailable: userPermissions?.tasks.read || userPermissions?.tasks.write,
          onboarding: { key: 'tasks' },
        },
        {
          name: t('navigation.dashboards'),
          path: '/dashboards',
          icon: ChartPieIcon,
          section: 'Dashboard',
          lock: !userAddons?.registries.enabled,
          isAvailable: userPermissions?.dashboards.read || userPermissions?.dashboards.write,
          onboarding: { key: 'my-dashboards' },
          testId: 'sidebar-dashboards-submenu',
        },
        {
          name: t('navigation.automations'),
          path: '/automations',
          icon: LightningBoltIcon,
          section: 'Automation',
          lock: !userAddons?.registries.enabled,
          isAvailable: isUserCompany,
          onboarding: { key: 'automations' },
          testId: 'sidebar-automations-submenu',
        },
      ],
    },
    {
      name: t('navigation.support'),
      path: '/support',
      icon: ChatAltIcon,
      section: 'Support',
      isAvailable: true,
      onboarding: { key: 'support' },
      testId: 'sidebar-support-menu',
    },
  ]

  return formatRoutes(routes)
}
