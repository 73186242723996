import { Task } from '@/features/tasks'
import { Audit, AuditOperation } from '@/features/audits'
import {
  Automation,
  AutomationEntityEventTrigger,
  AutomationTriggerType,
} from '@/features/automations'
import { ACTIVITY_TYPE, AuditAutomationAndAutomatedTaskRequest } from '../interfaces'

interface AutomationActivity {
  previousAudit?: AuditAutomationAndAutomatedTaskRequest | undefined
  audit: AuditAutomationAndAutomatedTaskRequest
  type: ACTIVITY_TYPE
}

export const formatAuditToActivities = (
  audit: Audit<Task | Automation, AuditOperation>,
  index: number,
  self: Audit<Task | Automation, AuditOperation>[]
): AutomationActivity[] => {
  const activities: AutomationActivity[] = []

  const onlyAutomationAudits = self.filter(({ collection }) => collection === 'automations')

  const onlyAutomationAuditIndex = onlyAutomationAudits.findIndex(item => audit._id === item._id)

  const previousAudit = onlyAutomationAudits[onlyAutomationAuditIndex - 1] as
    | Audit<Automation, AuditOperation>
    | undefined
  if (audit.microserviceTrigger === 'tasks' && audit.operation === AuditOperation.CREATE) {
    activities.push({
      audit,
      previousAudit,
      type: ACTIVITY_TYPE.AUTOMATED_TASK_CREATED,
    })

    return activities
  }

  if (audit.collection !== 'automations') {
    return activities
  }

  if (audit.operation === AuditOperation.CREATE) {
    activities.push({
      audit,
      type: ACTIVITY_TYPE.AUTOMATION_CREATED,
    })
    return activities
  }

  if (audit.operation !== AuditOperation.UPDATE) {
    return activities
  }

  const currentAudit = audit as Audit<Automation, AuditOperation>

  if (
    previousAudit?.data.target.deleted !== currentAudit.data.target.deleted &&
    currentAudit.data.target.deleted === true
  ) {
    activities.push({
      audit,
      previousAudit,
      type: ACTIVITY_TYPE.FORM_TARGET_DELETED,
    })
    return activities
  }
  const previousEntityEventTrigger = previousAudit?.data.trigger as AutomationEntityEventTrigger

  if (
    currentAudit.data.trigger.type === AutomationTriggerType.ENTITY_EVENT &&
    previousEntityEventTrigger
  ) {
    if (
      previousEntityEventTrigger.entityEvent?.deleted !==
        currentAudit.data.trigger.entityEvent.deleted &&
      currentAudit.data.trigger.entityEvent.deleted === true
    ) {
      activities.push({
        audit,
        previousAudit,
        type: ACTIVITY_TYPE.PRODUCT_TRIGGER_DELETED,
      })
      return activities
    }
  }

  activities.push({
    audit,
    type: ACTIVITY_TYPE.AUTOMATION_EDITED,
  })

  return activities
}
