import { useFacilityById } from '@/features/facilities'
import { MemoFormStatus } from '@/features/memorised-form'
import { useReferrer } from '@/hooks/use-referrer'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'

export const ProductReferrers = () => {
  const { status, setFieldValue } = useFormikContext()

  const { referrerResultName, referrerResultValue } = useReferrer()

  const { facility: facilityFromReferrer, isFetched: isFacilityFromReferrerFetched } =
    useFacilityById(referrerResultValue || '', {
      enabled: referrerResultName === 'facilityId' && !!referrerResultValue,
    })

  useEffect(() => {
    if (status === MemoFormStatus.LOADED && facilityFromReferrer && isFacilityFromReferrerFetched) {
      setFieldValue('facilityOption', {
        label: facilityFromReferrer?.name,
        value: facilityFromReferrer?._id,
      })
    }
  }, [facilityFromReferrer, isFacilityFromReferrerFetched, status, setFieldValue])

  return null
}
