import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DEFAULT_PAGE_SIZE, PDF_MANUAL_EN_URL, PDF_MANUAL_ES_URL } from '@/app/constants'
import {
  TaskFilters,
  TaskStatus,
  TaskTable,
  TaskTableMode,
  useMyPaginatedTasks,
  useTaskManager,
} from '@/features/tasks'
import { CallToAction, Loader } from '@blockchain-traceability-sl/tailwind-components'
import { useAnalytics } from '@/features/analytics'
import { usePagination } from '@/hooks/use-pagination'
import { useAssociatedStakeholdersByTasks, useFormatTaskToTab } from '../hooks'
import {
  ValidationRequestStatus,
  useValidationsRequestByEntityIds,
} from '@/features/validationRequests'
import { useUserPermissions } from '@/features/auth'
import { useLocalStorage } from '@/hooks/use-local-storage'
import { PAGE_SIZE_KEY } from '../constants'
import dayjs from 'dayjs'
import i18n from '@/i18n'

interface TasksTabProps {
  isFiltersActive?: boolean
  leftMenu?: ReactNode
  taskFilters: TaskFilters
}

export const TasksTab = ({ isFiltersActive, leftMenu, taskFilters }: TasksTabProps) => {
  const { t } = useTranslation('nsTasksListPage')
  const history = useHistory()
  const analytics = useAnalytics()
  const userPermissions = useUserPermissions()
  const [pageSize, setPageSize] = useLocalStorage(PAGE_SIZE_KEY, DEFAULT_PAGE_SIZE)

  const { onPageChange, pageIndex, pageOffset } = usePagination({ pageSize })

  const {
    isLoading: isTasksLoading,
    isFetching: isTasksFetching,
    isFetched: isTasksFetched,
    totalCount,
    tasks,
  } = useMyPaginatedTasks(taskFilters, { offset: pageOffset, limit: pageSize })

  const taskIds = useMemo(() => tasks.map(task => task._id), [tasks])

  const { validationRequests } = useValidationsRequestByEntityIds(taskIds, {
    enabled: isTasksFetched && taskIds.length > 0,
  })

  const { associatedStakeholders } = useAssociatedStakeholdersByTasks(taskIds, {
    enabled: isTasksFetched && taskIds.length > 0,
  })

  const { formatTaskToTab } = useFormatTaskToTab({ validationRequests, associatedStakeholders })

  const tasksToTable = useMemo(() => tasks.map(formatTaskToTab), [tasks, formatTaskToTab])

  const { openManager, completeTaskBulk, reviewTaskBulk, closeTaskBulk } = useTaskManager()

  const isTableLoading = isTasksFetching && !isTasksFetched

  const language = i18n.language

  const userManualUrl = useMemo(() => {
    if (language === 'es') {
      return PDF_MANUAL_ES_URL
    }
    return PDF_MANUAL_EN_URL
  }, [language])

  return (
    <>
      {isTasksLoading ? (
        <div className='h-80'>
          <Loader center='full' />
        </div>
      ) : totalCount === 0 && !isFiltersActive ? (
        <CallToAction
          title={t('tabAll.callToAction.title')}
          subtitle={t('tabAll.callToAction.subtitle')}
          primaryActionText={t('tabAll.callToAction.action')}
          primaryActionDisabled={!userPermissions.tasks.write}
          onPrimaryActionClick={() => {
            analytics.track('ACTION_CLICK_CREATE_TASK', {
              From: 'CallToAction',
            })
            history.push('/tasks/create')
          }}
          secondaryActionText={t('tabAll.callToAction.needHelp')}
          onSecondaryActionClick={() => {
            analytics.track('ACTION_CLICK_HELP', { Source: 'Tasks section' })
            window.open(userManualUrl, '_blank')
          }}
        />
      ) : (
        <TaskTable
          mode={TaskTableMode.EDIT}
          isLoading={isTableLoading}
          tasks={tasksToTable}
          onRowClick={openManager}
          onPageChange={page => {
            onPageChange(page)
            setPageSize(page.pageSize)
          }}
          pagination={{ pageIndex, pageSize }}
          totalCount={totalCount}
          leftMenu={leftMenu}
          bulkActionsEnabled
          bulkCloseEnabled={userPermissions.tasks.write}
          onCompleteBulk={completeTaskBulk}
          onReviewTaskBulk={reviewTaskBulk}
          onCloseBulk={closeTaskBulk}
        />
      )}
    </>
  )
}

export const PendingTasksTab = ({ taskFilters, ...props }: TasksTabProps) => (
  <TasksTab
    taskFilters={{
      ...taskFilters,
      status: [TaskStatus.OPEN],
    }}
    {...props}
  />
)

export const PendingTodayTasksTab = ({ taskFilters, ...props }: TasksTabProps) => (
  <TasksTab
    taskFilters={{
      ...taskFilters,
      assignedToMe: true,
      status: [TaskStatus.OPEN],
      startDate: dayjs().startOf('day').toISOString(),
      endDate: dayjs().endOf('day').toISOString(),
      validationStatus: [
        'null',
        ValidationRequestStatus.APPROVED,
        ValidationRequestStatus.REJECTED,
      ],
    }}
    {...props}
  />
)

export const ApprovedTasksTab = ({ taskFilters, ...props }: TasksTabProps) => (
  <TasksTab
    taskFilters={{
      ...taskFilters,
      validationStatus: [ValidationRequestStatus.APPROVED],
    }}
    {...props}
  />
)

export const RejectedTasksTab = ({ taskFilters, ...props }: TasksTabProps) => (
  <TasksTab
    taskFilters={{
      ...taskFilters,
      validationStatus: [ValidationRequestStatus.REJECTED],
    }}
    {...props}
  />
)
