import { api } from '@/app/api'
import { Paginated, PaginationParams } from '@/hooks/use-pagination'
import { ObjectToSearchParams, objectToURLSearchParams } from '@/utils/search-params'
import {
  IssueTemplateInput,
  IssueTemplatesChanges,
  Template,
  TemplateAssociationType,
  TemplateDataInput,
  TemplateEntryType,
  TemplateFilters,
  TemplateType,
} from './interfaces'

/**
 * Define the url path
 * @constant
 */
const TEMPLATES_URL = '/templates'
const TEMPLATES_URL_METADATA = `${TEMPLATES_URL}/metadata-templates`
const TEMPLATES_URL_FORM = `${TEMPLATES_URL}/forms`
const TEMPLATES_URL_ISSUE = `${TEMPLATES_URL}/issue-templates`

const getBaseUrlByTemplateType = (type: TemplateType | 'form'): string => {
  switch (type) {
    case TemplateType.REGISTRY:
    case TemplateType.INCIDENCE:
    case TemplateType.COMPLIANCE:
    case 'form':
      return TEMPLATES_URL_FORM
    case TemplateType.METADATA:
    case 'metadata':
      return TEMPLATES_URL_METADATA
    case TemplateType.ISSUE:
      return TEMPLATES_URL_ISSUE
  }
}

/**
 * Create a new Template
 *
 * @param templateRaw  - Raw data to create a new Template
 * @returns the new template id created
 */
export const create = async (templateRaw: TemplateDataInput): Promise<string> => {
  const { data } = await api.post<string>(getBaseUrlByTemplateType(templateRaw.type), templateRaw)
  return data
}

/**
 * Create a new Template with issues
 *
 * @param template - Data to create a new Template
 * @param issueTemplates - Data to create a new issue templates referenced in template
 * @returns the new template id created
 */
export const createWithIssueTemplates = async ({
  template,
  issueTemplates,
}: {
  template: TemplateDataInput
  issueTemplates?: IssueTemplateInput[]
}): Promise<string> => {
  if (!issueTemplates || issueTemplates.length === 0) return create(template)

  const issueTemplatesPromises = issueTemplates.map(async ({ entryId, name, entries }) => {
    const id = await create({
      type: TemplateType.ISSUE,
      name,
      entries,
    })

    return {
      id,
      entryId,
    }
  })

  const issueTemplatePairIds = await Promise.all(issueTemplatesPromises)

  const templateEntriesWithIssueTemplates = template.entries.map(entry => {
    if (entry.type === TemplateEntryType.ISSUE_REFERENCE) {
      const issueTemplatePair = issueTemplatePairIds.find(({ entryId }) => entryId === entry.id)

      if (issueTemplatePair) {
        return {
          ...entry,
          templateId: issueTemplatePair.id,
        }
      }
    }
    return entry
  })

  return create({
    ...template,
    entries: templateEntriesWithIssueTemplates,
  })
}

/**
 * Get all Templates
 */
export const getAll = async (filters?: TemplateFilters): Promise<Template[]> => {
  const searchParams = objectToURLSearchParams(filters as ObjectToSearchParams)

  const { data: templates } = await api.get<Template[] | void>(
    `${TEMPLATES_URL}/templates?${searchParams.toString()}`
  )
  return templates || []
}

export const getPaginated = async (
  filters: TemplateFilters,
  pagination: PaginationParams
): Promise<Paginated<Template>> => {
  const searchParams = objectToURLSearchParams(filters as ObjectToSearchParams)

  searchParams.append('limit', pagination.limit.toString())
  searchParams.append('offset', pagination.offset.toString())
  const { data } = await api.get<Paginated<Template>>(
    `${TEMPLATES_URL}/templates?${searchParams.toString()}`
  )
  return data
}

/**
 * Get all Templates by association
 *
 * @param associationId - the association id
 * @returns All the Company logged Templates by association
 */
export const getAllByAssociation = async (
  associationId: string,
  associationType: string,
  type: TemplateType.METADATA | 'form'
): Promise<Template[]> => {
  switch (type) {
    case TemplateType.METADATA:
      switch (associationType) {
        case TemplateAssociationType.PRODUCT: {
          const { data: templates } = await api.get<Template[] | void>(
            `${getBaseUrlByTemplateType(type)}/product/${associationId}`
          )
          return templates || []
        }
        case TemplateAssociationType.STAKEHOLDER_PRODUCT: {
          const { data: templates } = await api.get<Template[] | void>(
            `${getBaseUrlByTemplateType(type)}/stakeholder/product/${associationId}`
          )
          return templates || []
        }
        default:
          return []
      }
    default:
      return []
  }
}

/**
 * Update a Template
 */
export const update = async (templateId: string, template: TemplateDataInput): Promise<void> => {
  await api.patch<void>(
    `${getBaseUrlByTemplateType(template.type)}/${encodeURIComponent(templateId)}`,
    template
  )
}

/**
 * Delete a Template
 */
export const remove = async (
  templateId: string,
  templateType: 'form' | TemplateType
): Promise<void> => {
  await api.delete<void>(
    `${getBaseUrlByTemplateType(templateType)}/${encodeURIComponent(templateId)}`
  )
}

export const updateWithIssueTemplates = async (
  templateId: string,
  {
    template,
    issueTemplatesChanges,
  }: {
    template: TemplateDataInput
    issueTemplatesChanges?: IssueTemplatesChanges
  }
): Promise<void> => {
  if (!issueTemplatesChanges || issueTemplatesChanges.length === 0)
    return update(templateId, template)

  const issueTemplatesPromises = issueTemplatesChanges.map(async ({ operation, value }) => {
    switch (operation) {
      case 'create': {
        const { entries, name, entryId } = value as IssueTemplateInput
        const id = await create({
          type: TemplateType.ISSUE,
          name,
          entries,
        })

        return {
          id,
          entryId,
        }
      }

      case 'update': {
        const { id, entries, name } = value as IssueTemplateInput
        if (id)
          await update(id, {
            type: TemplateType.ISSUE,
            name,
            entries,
          })
        break
      }

      case 'delete': {
        await remove(value as string, TemplateType.ISSUE)
        break
      }
    }
  })

  const issueTemplatePairIds = (await Promise.all(issueTemplatesPromises)).filter(Boolean) as {
    entryId: string
    id: string
  }[]

  const templateEntriesWithIssueTemplates = template.entries.map(entry => {
    if (entry.type === TemplateEntryType.ISSUE_REFERENCE && !entry.templateId) {
      const issueTemplatePair = issueTemplatePairIds.find(({ entryId }) => entryId === entry.id)

      if (issueTemplatePair) {
        return {
          ...entry,
          templateId: issueTemplatePair.id,
        }
      }
    }
    return entry
  })

  return update(templateId, {
    ...template,
    entries: templateEntriesWithIssueTemplates,
  })
}

/**
 *
 * @param type
 * @param templateId
 * @returns

 */
export const getByTypeAndId = async (
  type: 'form' | TemplateType,
  templateId: string
): Promise<Template | undefined> => {
  const { data: template } = await api.get<Template | undefined>(
    `${getBaseUrlByTemplateType(type)}/${templateId}`
  )
  return template || undefined
}

/**
 *
 * @param type
 * @param templateIds
 * TODO: support other types
 * @returns
 */
export const getByIds = async (
  type: TemplateType.ISSUE | 'form',
  templateIds: string[]
): Promise<Template[]> => {
  const { data: templates } = await api.post<Template[]>(`${getBaseUrlByTemplateType(type)}/ids`, {
    ids: templateIds,
  })
  return templates || []
}

export const getAllTags = async (): Promise<string[]> => {
  const { data: tags } = await api.get<string[]>(`${TEMPLATES_URL}/templates/tags`)

  return tags || []
}
