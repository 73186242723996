import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { PencilIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateLocaleField } from '@/components/DateLocaleField'
import { COMPLIANCE_ALMOST_DEADLINE_DAYS } from '../constants'
import { ComplianceStatus } from '../interfaces'
import { isComplianceAlmostExpired } from '../utils/utils'

export const ComplianceListDeadlineCell = ({
  complianceId,
  deadlineAt,
  status,
  onDeadlineChange,
}: {
  complianceId: string
  deadlineAt: string
  status: ComplianceStatus
  onDeadlineChange: (value: string) => void
}) => {
  const { t } = useTranslation('nsCompliancesList')
  const [isDateFieldPopperOpen, setIsDateFieldPopperOpen] = useState(false)

  const { distanceInDays, distanceInHours } = useMemo(() => {
    const distanceInHours = dayjs(deadlineAt).diff(dayjs(), 'hour')
    const distanceInDays = dayjs
      .duration({
        hours: distanceInHours,
      })
      .asDays()
    return {
      distanceInDays,
      distanceInHours,
    }
  }, [deadlineAt])

  const shouldDisplayWarningIcon =
    isComplianceAlmostExpired(deadlineAt) && status !== ComplianceStatus.ACCEPTED

  return (
    <DateLocaleField
      id={`compliance-deadline-date-field-${complianceId}`}
      name={`compliance-deadline-date-field-${complianceId}`}
      popperIsOpen={isDateFieldPopperOpen}
      onPopperChange={setIsDateFieldPopperOpen}
      value={deadlineAt}
      onChange={value => {
        if (!value) return
        onDeadlineChange(value)
      }}
    >
      <div className='group flex items-center gap-2'>
        {shouldDisplayWarningIcon ? (
          <ExclamationCircleIcon
            className={classNames('h-5 w-5', {
              'text-yellow-500':
                distanceInDays <= COMPLIANCE_ALMOST_DEADLINE_DAYS && distanceInDays > 1,
              'text-red-500': distanceInDays === 1 || distanceInHours > 0 || distanceInHours < 0,
            })}
          />
        ) : (
          <div className='h-5 w-5' />
        )}
        <div>{t('table.deadlineAtFormat', { date: new Date(deadlineAt) })}</div>
        <div className='opacity-0 group-hover:opacity-100'>
          <PencilIcon
            className='h-5 w-5 text-gray-300'
            onClick={() => {
              setIsDateFieldPopperOpen(true)
            }}
          />
        </div>
      </div>
    </DateLocaleField>
  )
}
