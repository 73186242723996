import { api } from '@/app/api'
import { Traceability } from '@/features/traceabilities'

/**
 * Define the url path
 * @constant
 */
export const TRACEABILITY_URL = '/traceability/traceability'

/**
 * Get Traceability by TRU ids
 *
 * @returns The TRU traceability
 */
export const getByTruId = async (truId: string): Promise<Traceability[] | void | string> => {
  const { data: traceabilities } = await api.get<Traceability[] | void | string>(
    `${TRACEABILITY_URL}/${encodeURIComponent(truId)}`
  )
  return traceabilities
}
