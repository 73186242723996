import { Button } from '@blockchain-traceability-sl/tailwind-components'
import { useTranslation } from 'react-i18next'

export const TableDocumentInput = ({
  value,
  openDocumentPicker,
  onRemove,
  documentPickedName,
}: {
  value?: string
  openDocumentPicker: () => void
  onRemove: () => void
  documentPickedName?: string
}) => {
  const { t } = useTranslation('nsEntries')

  return (
    <div>
      {!value ? (
        <Button
          color='secondary'
          onClick={() => {
            openDocumentPicker()
          }}
        >
          {t('form.document.choose')}
        </Button>
      ) : (
        <>
          <div className='space-x-3'>
            <Button
              color='secondary'
              onClick={() => {
                openDocumentPicker()
              }}
            >
              {t('form.document.change')}
            </Button>
            <Button
              color='danger'
              onClick={() => {
                onRemove()
              }}
            >
              {t('form.document.remove')}
            </Button>
          </div>
          <p className='mt-2 text-sm text-gray-500'>
            {t('form.document.fileSelected', {
              name: documentPickedName,
            })}
          </p>
        </>
      )}
    </div>
  )
}
