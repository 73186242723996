import { useCompanyUser } from '@/features/auth'
import { useDebounceState } from '@/hooks/use-debounce-state'
import { matchWithSearch } from '@/hooks/use-filter-algorithm'
import {
  SelectMultiField,
  SelectMultiFieldProps,
  SelectorOption,
  createSelectorOption,
  noop,
} from '@blockchain-traceability-sl/tailwind-components'
import { useEffect, useState } from 'react'
import { useFetchStakeHolders, useInfiniteStakeholders } from '../hooks'
import { StakeHolderType } from '../interfaces'

export interface StakeHoldersSelectMultiFieldProps extends Omit<SelectMultiFieldProps, 'options'> {
  types?: StakeHolderType[]
  /**
   * Includes company in selector options
   */
  withCompany?: boolean
}

export const StakeHoldersSelectMultiField = ({
  types,
  withCompany,
  ...props
}: StakeHoldersSelectMultiFieldProps) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const [stakeholdersOptions, setStakeholdersOptions] = useState<SelectorOption[]>([])

  const {
    isLoading: isInfinityStakeHoldersLoading,
    stakeholders,
    fetchNextPage,
  } = useInfiniteStakeholders({
    search: debouncedSearch,
    types,
  })

  const { fetchStakeHolders } = useFetchStakeHolders({
    search: debouncedSearch,
    types,
  })

  const companyUser = useCompanyUser()

  const [isLoading, setIsLoading] = useState(isInfinityStakeHoldersLoading)

  useEffect(() => {
    setStakeholdersOptions([
      ...(withCompany && matchWithSearch(companyUser.name, debouncedSearch)
        ? [createSelectorOption(companyUser.name, companyUser._id)]
        : []),
      ...stakeholders.map(stakeholder =>
        createSelectorOption(stakeholder.company.name, stakeholder._id)
      ),
    ])
  }, [companyUser.name, companyUser._id, debouncedSearch, stakeholders, withCompany])

  useEffect(() => {
    setIsLoading(isInfinityStakeHoldersLoading)
  }, [isInfinityStakeHoldersLoading])

  const handleSelectAll = async () => {
    setIsLoading(true)
    fetchStakeHolders()
      .then(stakeHolders => {
        const newOptions = [
          ...(withCompany ? [createSelectorOption(companyUser.name, companyUser._id)] : []),
          ...stakeHolders.map(stakeholder =>
            createSelectorOption(stakeholder.company.name, stakeholder._id)
          ),
        ]
        setStakeholdersOptions(newOptions)
        if (props.onChange) {
          props.onChange(newOptions)
        }
      })
      .catch(noop)
      .finally(() => setIsLoading(false))
  }

  return (
    <SelectMultiField
      {...props}
      isLoading={isLoading}
      options={stakeholdersOptions}
      onInputChange={setSearch}
      onMenuScrollToBottom={() => fetchNextPage()}
      onSelectAll={handleSelectAll}
    />
  )
}
