import { Button, InlineSelectField } from '@blockchain-traceability-sl/tailwind-components'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { DocumentFiltersExpirationStatus } from '../../interfaces'
import { ALL_FILTER_OPTION_VALUE } from './hooks'
import { StakeHolderType, StakeHoldersInlineSelectField } from '@/features/stakeHolders'
import { DateRangeLocaleField } from '@/components/DateRangeLocaleField'
import { AdvancedFiltersMenu } from './AdvancedFiltersMenu'
import { useState } from 'react'
import { DocumentTagsInlineSelectField } from './DocumentTagsInlineSelectField'
import { TemplateType, TemplatesInlineSelectField } from '@/features/templates'
import { DocumentFiltersState } from './interfaces'

const getI18nKeyByExpirationStatus = (expirationStatus: DocumentFiltersExpirationStatus) => {
  switch (expirationStatus) {
    case DocumentFiltersExpirationStatus.EXPIRED:
      return 'filters.status.dropdown.expired'
    case DocumentFiltersExpirationStatus.ALMOST_EXPIRED:
      return 'filters.status.dropdown.almost'
    default:
      return 'filters.status.dropdown.all'
  }
}

export const DocumentFiltersBar = ({
  className,
  filters,
  onReset,
  onFilterChange,
  isFiltersTouched,
}: {
  className?: string
  filters: DocumentFiltersState
  onReset: () => void
  onFilterChange: (filtersPatch: Partial<DocumentFiltersState>) => void
  isFiltersTouched?: boolean
}) => {
  const { t } = useTranslation('nsDocumentsViewPage')
  const [advancedFilterKeys, setAdvancedFilterKeys] = useState<string[]>([])

  const allOption = {
    label: t(`filters.status.dropdown.all`),
    value: ALL_FILTER_OPTION_VALUE,
  }

  const updateAdvancedFilterKeys = (key: string) => {
    if (!advancedFilterKeys.includes(key)) {
      setAdvancedFilterKeys([...advancedFilterKeys, key])
    }
  }

  const removeAdvancedFilterKey = (key: string) => {
    setAdvancedFilterKeys(advancedFilterKeys.filter(k => k !== key))
  }

  const handleReset = () => {
    onReset()
    setAdvancedFilterKeys([])
  }

  return (
    <div className={classNames('md:grid md:grid-cols-6 gap-2 flex flex-col', className)}>
      <div className='lg:grid lg:grid-cols-3 flex flex-col gap-2 mb-2 lg:mb-1 z-30 col-span-5'>
        <div>
          <InlineSelectField
            id='expirationStatus'
            name='expirationStatus'
            multiple
            options={[
              {
                label: t('filters.status.dropdown.all'),
                value: 'all',
              },
              {
                label: t('filters.status.dropdown.expired'),
                value: DocumentFiltersExpirationStatus.EXPIRED,
              },
              {
                label: t('filters.status.dropdown.almost'),
                value: DocumentFiltersExpirationStatus.ALMOST_EXPIRED,
              },
            ]}
            onChange={options => {
              const value = options?.map(
                option => option.value
              ) as DocumentFiltersExpirationStatus[]
              onFilterChange({ expirationStatus: value })
            }}
            label={`${t('filters.status.placeholder')} `}
            value={
              filters.expirationStatus.length > 0
                ? filters.expirationStatus.map(status => ({
                    label: t(getI18nKeyByExpirationStatus(status)),
                    value: status,
                  }))
                : [allOption]
            }
            menuClassName='z-10'
          />
        </div>

        <div>
          <DateRangeLocaleField
            id='createdAtRange'
            name='createdAtRange'
            value={{ startDate: filters.startCreatedAt, endDate: filters.endCreatedAt }}
            onChange={value => {
              onFilterChange({ startCreatedAt: value.startDate, endCreatedAt: value.endDate })
            }}
            leadingAddon={`${t('filters.creation.placeholder')} `}
            isClearable
            leadingOffset={20}
            inputClassName='truncate text-sm'
          />
        </div>

        <div>
          <StakeHoldersInlineSelectField
            id='stakeholders'
            name='stakeholders'
            label={`${t('filters.company.placeholder')} `}
            multiple
            all={allOption}
            value={
              filters.stakeHolders.length > 0
                ? filters.stakeHolders.map(item => ({
                    label: item.name,
                    value: item.id,
                  }))
                : [allOption]
            }
            onChange={options => {
              const value = options?.map(option => ({
                id: option.value,
                name: option.label,
              }))
              onFilterChange({ stakeHolders: value })
            }}
            types={[StakeHolderType.PROVIDER, StakeHolderType.CLIENT, StakeHolderType.BOTH]}
            searchPlaceholderText={t('filters.dropdown.search')}
            menuClassName='z-10'
          />
        </div>

        {advancedFilterKeys.map(key => (
          <div key={key}>
            {key === 'tags' && (
              <DocumentTagsInlineSelectField
                id='tags'
                name='tags'
                multiple
                all={allOption}
                onChange={(options, meta) => {
                  const value = options?.map(option => option.value) || []
                  onFilterChange({ tags: value })
                  if (meta.type === 'clear') {
                    removeAdvancedFilterKey('tags')
                  }
                }}
                value={
                  filters.tags.length > 0
                    ? filters.tags.map(tag => ({
                        label: tag,
                        value: tag,
                      }))
                    : [allOption]
                }
                label={`${t('advancedFilters.list.tag.title')} `}
                clearable
                searchPlaceholderText={t('filters.dropdown.search')}
                menuClassName='z-10'
              />
            )}
            {key === 'expiredAt' && (
              <DateRangeLocaleField
                id='expiredAtRange'
                name='expiredAtRange'
                value={{
                  startDate: filters.startExpiredAt,
                  endDate: filters.endExpiredAt,
                }}
                onChange={(value, meta) => {
                  onFilterChange({
                    startExpiredAt: value.startDate,
                    endExpiredAt: value.endDate,
                  })
                  if (meta?.type === 'clear') {
                    removeAdvancedFilterKey('expiredAt')
                  }
                }}
                leadingAddon={`${t('advancedFilters.list.date.title')} `}
                isClearableWithAddon
                isClearable
                leadingOffset={20}
                inputClassName='truncate text-sm'
                popperPlacement='bottom-end'
              />
            )}
            {key === 'creationTriggerTemplates' && (
              <TemplatesInlineSelectField
                id='templates'
                name='templates'
                multiple
                all={allOption}
                onChange={(options, meta) => {
                  const value = options?.map(option => ({
                    id: option.value,
                    name: option.label,
                  }))
                  onFilterChange({ creationTriggerTemplates: value })
                  if (meta?.type === 'clear') {
                    removeAdvancedFilterKey('creationTriggerTemplates')
                  }
                }}
                value={
                  filters.creationTriggerTemplates.length > 0
                    ? filters.creationTriggerTemplates.map(item => ({
                        label: item.name,
                        value: item.id,
                      }))
                    : [allOption]
                }
                label={`${t('advancedFilters.list.form.title')} `}
                searchPlaceholderText={t('filters.dropdown.search')}
                menuClassName='z-10'
                types={[TemplateType.COMPLIANCE]}
                clearable
              />
            )}
          </div>
        ))}
      </div>

      <div className='flex justify-end'>
        <div className='flex flex-col pt-1.5'>
          <AdvancedFiltersMenu
            filters={filters}
            onFilterChange={(key, filters) => {
              onFilterChange(filters)
              updateAdvancedFilterKeys(key)
            }}
            all={allOption}
          />

          <div className='self-start'>
            <Button
              color='link'
              className='text-xs underline'
              onClick={handleReset}
              disabled={!isFiltersTouched}
            >
              {t('filters.actionLink')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
