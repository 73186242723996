import { Stat } from '@blockchain-traceability-sl/tailwind-components'
import { useTranslation } from 'react-i18next'
import { useShipmentsCount } from '../hooks'
import { TruckIcon } from '@heroicons/react/outline'
import { useAnalytics } from '@/features/analytics'
import { useHistory } from 'react-router-dom'

export const StatShipmentCount = () => {
  const { t } = useTranslation('nsHomePage', { keyPrefix: 'stats.trusSent' })
  const { shipmentsCount } = useShipmentsCount()
  const analytics = useAnalytics()
  const history = useHistory()

  return (
    <Stat
      name={t('name').toString()}
      stat={shipmentsCount}
      IconComponent={TruckIcon}
      actionText={t('action').toString()}
      onActionClick={() => {
        analytics.track('ACTION_CLICK_TOP_ACTIVITY', {
          Source: 'TRU shipping',
        })
        history.push('/traceability')
      }}
    />
  )
}
