import { DownloaderMIMEType, downloader } from '@/helpers/downloader'
import {
  Document,
  DocumentFile,
  DocumentFolder,
  DocumentType,
  SpecialFolderType,
} from '../interfaces'
import * as NotifyService from '@/services/notify.service'
import { getPublicById } from '@/features/documents'
import { AxiosError } from 'axios'
import { TFunction } from 'i18next'
import dayjs from 'dayjs'
import { DOCUMENT_ALMOST_EXPIRED_DAYS } from '../constants'

export const isDocumentFile = (object: Document): object is DocumentFile => {
  return object.type === DocumentType.FILE
}

export const createTreeFolder = (
  _id: string,
  title: string,
  parentId: string,
  companyId: string,
  extraProps?: Record<string, unknown>
): DocumentFolder => ({
  _id,
  companyId,
  title,
  parentId,
  type: DocumentType.FOLDER,
  tags: [],
  companyIds: [],
  productIds: [],
  truIds: [],
  stakeHolderIds: [],
  stakeHolderProductIds: [],
  receptionIds: [],
  mimeType: null,
  path: null,
  deleted: false,
  createdAt: '',
  updatedAt: '',
  expireAt: null,
  ...(extraProps || {}),
})

export const createTreeFile = (
  _id: string,
  title: string,
  parentId: string,
  companyId: string,
  mimeType: string,
  path: string,
  extraProps?: Record<string, unknown>
): DocumentFile => ({
  _id,
  companyId,
  title,
  parentId,
  type: DocumentType.FILE,
  tags: [],
  companyIds: [],
  productIds: [],
  truIds: [],
  stakeHolderIds: [],
  stakeHolderProductIds: [],
  receptionIds: [],
  mimeType,
  path,
  deleted: false,
  createdAt: '',
  updatedAt: '',
  expireAt: null,
  sharedWith: [],
  ...(extraProps || {}),
})

export const treeFilesExtraProps = ({
  companyIds,
  productIds,
  truIds,
  stakeHolderIds,
  stakeHolderProductIds,
  receptionIds,
  isPublic,
  publicUrl,
}: DocumentFile): Record<string, unknown> => {
  return {
    // Associations
    companyIds,
    productIds,
    truIds,
    stakeHolderIds,
    stakeHolderProductIds,
    receptionIds,
    // Public
    isPublic,
    publicUrl,
  }
}

export const downloadPublicDocument = async (documentId: string) => {
  try {
    const documentFile = await getPublicById(documentId)

    if (!documentFile || !documentFile.publicUrl) throw new Error('Is not public')

    const response = await fetch(documentFile.publicUrl)
    const blob = await response.blob()
    downloader(blob, documentFile.mimeType as DownloaderMIMEType, documentFile.title)
  } catch (error) {
    NotifyService.error(NotifyService.customErrorToNotify((error as AxiosError).response?.data))
    throw error
  }
}

export const sortFoldersFirst = (a: Document, b: Document): number => {
  if (a.type === DocumentType.FOLDER && b.type !== DocumentType.FOLDER) {
    return -1
  }
  if (a.type !== DocumentType.FOLDER && b.type === DocumentType.FOLDER) {
    return 1
  }
  return 0
}

export const getDocumentDisplayTitle = (
  document: Pick<Document, 'title' | 'specialFolderType'>,
  { t }: { t: TFunction }
): string => {
  return document.specialFolderType === SpecialFolderType.COMPLIANCES
    ? t('nsDocumentsViewPage:roots.provider')
    : document.title
}

export const isDocumentAlmostExpired = (expirationDate?: string | null): boolean => {
  if (expirationDate === null) return false

  const remainingTime = dayjs()
    .add(DOCUMENT_ALMOST_EXPIRED_DAYS, 'day')
    .endOf('day')
    .diff(dayjs(expirationDate))

  const remainingTimeFromToday = dayjs().diff(dayjs(expirationDate))

  return remainingTime > 0 && remainingTimeFromToday < 0
}

export const isDocumentExpired = (expirationDate: string | null): boolean => {
  if (expirationDate === null) return false

  return dayjs(expirationDate).isBefore(dayjs())
}
