import { Help } from '@/components/Help'
import { DocumentFolderPicker } from '@/features/documents'
import {
  Button,
  ButtonWithIcon,
  Card,
  Step,
  Tooltip,
} from '@blockchain-traceability-sl/tailwind-components'
import { ArrowSmUpIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { FormikErrors, useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { UploadRequestCreate } from '../interfaces'

export const StepTwoCreateUploadRequest = () => {
  const { t } = useTranslation('nsStepTwoCreateUploadRequest')

  const { setTouched, handleSubmit } =
    useFormikContext<Pick<UploadRequestCreate, 'targetFolderId'>>()

  const [, , targetFolderIdHelper] = useField<string>('targetFolderId')

  const handleFinish = async () => {
    const errors = (await setTouched({
      targetFolderId: true,
    })) as unknown as FormikErrors<Pick<UploadRequestCreate, 'targetFolderId'>>

    if (!errors?.targetFolderId) {
      handleSubmit()
    }
  }

  return (
    <Step
      title={t('title')}
      subtitle={
        <div className='flex gap-3'>
          <span>{t('subtitle')}</span>
          <Tooltip tip={t('description')} cursor>
            <span>
              <InformationCircleIcon className='w-4 h-4 text-gray-400' />
            </span>
          </Tooltip>
        </div>
      }
      overview={null}
    >
      {({ goPrevious }) => (
        <div className='space-y-6 m-1'>
          <Card className='overflow-visible'>
            <Card.Body>
              <div className='space-y-6'>
                <DocumentFolderPicker
                  onPick={documentFolder => targetFolderIdHelper.setValue(documentFolder._id)}
                />
              </div>
            </Card.Body>
          </Card>
          <div className='flex flex-wrap justify-between gap-4'>
            <Help analyticsProperties={{ Source: 'Request documents' }} />
            <div className='flex items-center gap-4'>
              <ButtonWithIcon color='white' IconComponent={ArrowSmUpIcon} onClick={goPrevious}>
                {t('previousText')}
              </ButtonWithIcon>
              <Button onClick={handleFinish}>{t('finishText')}</Button>
            </div>
          </div>
        </div>
      )}
    </Step>
  )
}
