import { Feeds, Loader, Modal, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { FeedProps } from '@blockchain-traceability-sl/tailwind-components/dist/components/molecules/Feeds/src/Feed'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useComplianceActivityAudits } from './hooks'
import { complianceAuditToFeed } from './utils'

interface ModalComplianceActivityProps {
  complianceId?: string | null
  onHide: () => void
  show: boolean
}

export const ModalComplianceActivity = ({
  complianceId,
  onHide,
  show,
}: ModalComplianceActivityProps) => {
  const { t } = useTranslation('nsComplianceActivityModal')

  const { audits, isLoading } = useComplianceActivityAudits(complianceId || '')

  const feeds: FeedProps[] = useMemo(
    () =>
      audits
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        // For each audit, we create a feed
        .map((audit, index, self) =>
          complianceAuditToFeed(
            {
              complianceId: complianceId || '',
              audit,
              index,
              audits: self,
            },
            { t }
          )
        )
        // Filter out the feeds that are not valid
        .filter((feed): feed is FeedProps => !!feed),
    [audits, complianceId, t]
  )

  return (
    <Modal show={show} toggle={onHide} dismissButton size={SIZE.LARGE}>
      <Modal.Title>{t('title')}</Modal.Title>
      <Modal.Body>
        {isLoading && (
          <div className='h-80'>
            <Loader center='full' />
          </div>
        )}
        <Feeds feeds={feeds} />
      </Modal.Body>
    </Modal>
  )
}
