import { Entry, EntryCreateInput } from '../entries'
import { StakeHolderType } from '../stakeHolders'

export enum ComplianceEntityType {
  STAKEHOLDER = 'stakeholder',
  STAKEHOLDER_PRODUCT = 'stakeholder_product',
}

export enum ComplianceStatus {
  PENDING = 'pending',
  SUBMITTED = 'submitted',
  ACCEPTED = 'accepted',
}

export enum ComplianceValidationStatus {
  APPROVED = 'approved',
  PARTIALLY_APPROVED = 'partially_approved',
  REJECTED = 'rejected',
}

export interface ComplianceValidation {
  status: ComplianceValidationStatus
  message?: string
  validatedAt: string
}

export interface Compliance {
  _id: string
  companyId: string
  templateId: string
  templateVersion: number
  templateName: string
  entries: Entry[]
  conflicts?: Conflict[]

  status: ComplianceStatus

  requesterCompany: {
    id: string
    name: string
  }

  compliantEntityType: ComplianceEntityType

  compliantStakeHolder: CompliantStakeHolder

  compliantStakeHolderProduct?: {
    reference?: string
  }

  publicId: string
  parentComplianceId?: string

  deadlineAt: string

  dueDate: string
  timezone?: string

  validationState: ComplianceValidation[]

  createdAt: string
  updatedAt: string
}

export interface CompliantStakeHolder {
  id: string
  name?: string
  emails: string[]
  isContact?: boolean
  language?: string
  contactDetails?: ContactDetails

  stakeholderProductTemplate?: {
    templateId: string
    templateVersion: number
    templateName: string
    entries: EntryCreateInput[]
  }
}

export interface ContactDetails {
  name?: string
  countryCode?: string
  reference?: string
  type?: StakeHolderType
  localization?: {
    address?: string
  }
}

export interface Conflict {
  entryId: string // Id of the entry
  message?: string
}

export interface PublicComplianceContactDetails {
  name?: string
  countryCode?: string
  reference?: string
  localization?: {
    address?: string
  }
  type?: StakeHolderType
}

export interface ChildCompliance {
  entries: Entry[]
  contactDetails?: PublicComplianceContactDetails
  compliantStakeHolderProduct?: {
    reference?: string
  }
  publicId: string
  compliantEntityType: ComplianceEntityType
  conflicts?: Conflict[]
}

export interface PublicCompliance {
  templateName: string
  entries: Entry[]

  conflicts?: Conflict[]

  status: ComplianceStatus

  requesterCompanyName: string

  publicId: string

  deadlineAt: string

  dueDate: string

  compliantEntityType: ComplianceEntityType

  compliantStakeHolder: {
    isContact: boolean
    contactDetails?: PublicComplianceContactDetails
    stakeholderProductTemplate?: {
      templateId: string
      templateVersion: number
      templateName: string
      entries: EntryCreateInput[]
    }
  }

  compliantStakeHolderProduct?: {
    reference?: string
  }

  lastValidation?: ComplianceValidation
}

export interface PublicComplianceWithChildren extends PublicCompliance {
  childCompliances: PublicCompliance[]
}

export type ComplianceCreateInput = Required<
  Pick<
    Compliance,
    | 'templateId'
    | 'templateVersion'
    | 'templateName'
    | 'entries'
    | 'requesterCompany'
    | 'compliantEntityType'
    | 'compliantStakeHolder'
    | 'deadlineAt'
    | 'dueDate'
    | 'timezone'
  >
> & {
  emailContent?: string
  emailContentRichText?: string
  stakeholderProductTemplate?: {
    templateId: string
    templateVersion: number
    templateName: string
    entries: Entry[]
  }
}

export type ComplianceUpdateInput = Partial<
  | Pick<Compliance, 'deadlineAt' | 'conflicts' | 'entries' | 'compliantStakeHolderProduct'>
  | Pick<CompliantStakeHolder, 'contactDetails'>
>

interface ComplianceEntryData {
  entries?: EntryCreateInput[]
  contactDetails?: ContactDetails
  compliantStakeHolderProduct?: {
    reference?: string
  }
}

interface ChildComplianceEntryData extends ComplianceEntryData {
  publicId?: string
  compliantEntityType: ComplianceEntityType
}

export interface AddChildToCompliance {
  publicId?: string
  childCompliance: ChildComplianceEntryData
}

export interface UpdatePublicCompliance {
  publicId: string
  body: {
    entries: Entry[]
    contactDetails?: PublicComplianceContactDetails
    compliantStakeHolderProduct?: {
      reference?: string
    }
  }
}
