import { ChangeEvent, Dispatch, SetStateAction, useMemo, useState } from 'react'

/**
 *
 * @param text text to normalize
 * @returns text normalized
 */
export const normalize = (text: string): string => {
  return text
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

/**
 * Check that a value match with a filter
 *
 * @param item Item to check
 * @param filterValue string that item should match
 * @returns If the filter match
 */
export const matchWithSearch = (
  item: string | number | null | undefined,
  filterValue?: string
): boolean => {
  if (!filterValue) return true
  return !!(item && normalize(String(item)).includes(normalize(filterValue)))
}

/**
 * Filter algorithm
 *
 * @param item Item to filter
 * @param filterValue string that items should match
 * @returns If the filter match
 */
export const filterAlgorithm = (
  item: Record<string, string | number | null | undefined>,
  filterValue: string
): boolean => {
  for (const key in item) {
    const value = item[key]
    if (matchWithSearch(value, filterValue)) {
      return true
    }
  }

  return false
}

interface UseFilterAlgorithmState<T> {
  /**
   * Data filtered
   */
  data: T[]
  /**
   * Current filter search value
   */
  filterValue: string
  /**
   * Dispatcher to modify the filter value
   */
  setFilterValue: Dispatch<SetStateAction<string>>
  /**
   * Helper so trigger input change events and modify the filter value
   */
  handleInputFilterChange: (event: ChangeEvent<HTMLInputElement>) => void
}

/**
 *
 * @param items Array of items to be filteres
 * @important Each item must be a record of string
 * @param replacer Replacer function
 * @returns An array of items that match with the filter value
 */
export const useFilterAlgorithm = <T extends Record<string, unknown | undefined>>(
  items: T[],
  replacer: (value: T, index: number, array: T[]) => Record<string, string> = item =>
    Object.fromEntries(
      Object.entries(item).filter(
        (param): param is [string, string] => typeof param[1] === 'string'
      )
    )
): UseFilterAlgorithmState<T> => {
  const [filterValue, setFilterValue] = useState<string>('')

  const data = useMemo(
    () =>
      filterValue
        ? items.filter((item, index, self) =>
            filterAlgorithm(replacer(item, index, self), filterValue)
          )
        : items,
    [filterValue, items, replacer]
  )
  const handleInputFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.currentTarget.value)
  }

  return {
    data,
    filterValue,
    setFilterValue,
    handleInputFilterChange,
  }
}
