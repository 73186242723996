import { CustomerStripe } from './interfaces'
import { getCustomer, getCustomerPortalUrl, updateCustomerPreferences } from './service'
import { UseQueryOptions, useMutation, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { useMyProfile } from '../auth'

export const QUERY_PAYMENTS_CUSTOMER_KEY = 'payments-customer'

/**
 * @deprecated
 * We will be removed after the migration to the new subscription system
 * https://trazable.atlassian.net/browse/PRD-2597
 */
export const useCustomerStripe = (
  options?: Omit<
    UseQueryOptions<CustomerStripe, AxiosError, CustomerStripe>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data, ...rest } = useQuery(QUERY_PAYMENTS_CUSTOMER_KEY, getCustomer, options)

  return {
    ...rest,
    customer: useMemo(
      () =>
        data
          ? {
              ...data,
              product: {
                ...data.product,
                amount: data.product?.amount ?? 0 / 100,
              },
            }
          : data,
      [data]
    ),
  }
}

/**
 * @deprecated
 * We will be removed after the migration to the new subscription system
 * https://trazable.atlassian.net/browse/PRD-2597
 */
export const useCustomerPortal = () => {
  const { profile } = useMyProfile()
  const { customer } = useCustomerStripe({
    enabled: profile?.type === 'company',
  })

  const redirectToCustomerPortal = useCallback(async () => {
    if (customer?.stripeId) {
      const customerPortalUrl = await getCustomerPortalUrl(
        customer.stripeId,
        window.location.origin
      )
      window.location.assign(customerPortalUrl)
    }
  }, [customer?.stripeId])

  return {
    redirectToCustomerPortal,
  }
}

/**
 * @deprecated
 * We will be removed after the migration to the new subscription system
 * https://trazable.atlassian.net/browse/PRD-2597
 */
export const useUpdateCustomerPreferences = () => {
  const { mutate, mutateAsync, ...rest } = useMutation(updateCustomerPreferences)

  return {
    ...rest,
    updateCustomerPreferences: mutate,
    updateCustomerPreferencesAsync: mutateAsync,
  }
}
