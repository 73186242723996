import { Dropdown } from '@blockchain-traceability-sl/tailwind-components'
import { useTranslation } from 'react-i18next'
import { CALENDAR_VIEW_TYPE } from '.'
import { CheckIcon } from '@heroicons/react/outline'
import { ComponentProps } from 'react'
import classNames from 'classnames'
import { useLocalStorage } from 'usehooks-ts'
import { PERSISTED_TASK_CALENDAR_VIEW_TYPE_KEY } from '@/pages/tasks/TasksList/constants'

const SelectionCheckIcon = (props: ComponentProps<'svg'>) => (
  <CheckIcon {...props} className={classNames(props.className, 'text-blue-600')} />
)
const SelectionNoCheckIcon = (props: ComponentProps<'svg'>) => <svg {...props} />

export const CalendarViewTypeSelector = () => {
  const { t } = useTranslation('nsTasksListPage', { keyPrefix: 'calendar.dates' })
  const [currentView, setCurrentView] = useLocalStorage<CALENDAR_VIEW_TYPE>(
    PERSISTED_TASK_CALENDAR_VIEW_TYPE_KEY,
    CALENDAR_VIEW_TYPE.WEEKLY
  )

  const viewTypeToTranslation = {
    [CALENDAR_VIEW_TYPE.WEEKLY]: t('week'),
    [CALENDAR_VIEW_TYPE.MONTHLY]: t('month'),
  }

  const items = [
    {
      id: CALENDAR_VIEW_TYPE.WEEKLY,
      name: viewTypeToTranslation[CALENDAR_VIEW_TYPE.WEEKLY],
      onClick: () => setCurrentView(CALENDAR_VIEW_TYPE.WEEKLY),
      disabled: currentView === CALENDAR_VIEW_TYPE.WEEKLY,
      icon: currentView === CALENDAR_VIEW_TYPE.WEEKLY ? SelectionCheckIcon : SelectionNoCheckIcon,
    },
    {
      id: CALENDAR_VIEW_TYPE.MONTHLY,
      name: viewTypeToTranslation[CALENDAR_VIEW_TYPE.MONTHLY],
      onClick: () => setCurrentView(CALENDAR_VIEW_TYPE.MONTHLY),
      disabled: currentView === CALENDAR_VIEW_TYPE.MONTHLY,
      icon: currentView === CALENDAR_VIEW_TYPE.MONTHLY ? SelectionCheckIcon : SelectionNoCheckIcon,
    },
  ]

  return (
    <div className='w-44'>
      <Dropdown items={items} color='tertiary' buttonClassName='w-44 justify-between'>
        {viewTypeToTranslation[currentView]}
      </Dropdown>
    </div>
  )
}
