import { EntryCreateInput, TableEntryColumn, UseFileEntryHandlerResult } from '@/features/entries'
import { TemplateEntryType } from '@/features/templates'
import { useOnChangeEvent } from '@/hooks/use-onchange-event'
import { isEven, isOdd } from '@/utils/even-odd'
import classNames from 'classnames'
import { nanoid } from 'nanoid'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DocumentPicker, useDocumentPicker } from '@/features/documents'
import { EntryLabel } from '../EntryLabel'
import { useEntryFieldAttributes } from '../utils'
import { TableInput } from './TableInput'
import { DirtyTableEntryColumn, DirtyTableEntryRow } from './types'

export interface TableInputFieldProps {
  prefixName?: string
  index: number
  entry: EntryCreateInput
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => unknown

  fileOptions?: UseFileEntryHandlerResult

  name: string
  values: TableEntryColumn[]
  onChange: (values: DirtyTableEntryColumn[]) => void
  label?: ReactNode

  disableEntry?: boolean
}

const prepareValues = (values: TableEntryColumn[]): DirtyTableEntryColumn[] =>
  values.map(value => ({
    ...value,
    id: nanoid(),
    rows: value.rows.map(row => ({
      ...row,
      id: nanoid(),
    })),
  }))

export const TableInputField = ({
  index,
  entry,
  name,
  values,
  onChange,
  setFieldValue,
  fileOptions,
  prefixName,
  disableEntry,
  label,
}: TableInputFieldProps) => {
  const { t } = useTranslation('nsEntries')
  const [inputValues, setInputValues] = useState(() => prepareValues(values))

  const onChangeInputValues = useCallback(
    (columnId: string, row: DirtyTableEntryRow) => {
      const column = inputValues.find(column => column.id === columnId)
      if (!column) return

      const rowIndex = column.rows.findIndex(item => item.id === row.id)
      if (rowIndex === -1) return
      column.rows[rowIndex] = row
      setInputValues([...inputValues])
    },
    [inputValues, setInputValues]
  )

  const documentIds = useMemo(
    () =>
      inputValues.flatMap(({ rows }) =>
        rows
          .filter(row => row.type === TemplateEntryType.DOCUMENT && row.values[0])
          .map(({ values }) => values[0] as string)
      ),
    [inputValues]
  )

  const hasDocuments = useMemo(
    () => inputValues.some(({ rows }) => rows.some(row => row.type === TemplateEntryType.DOCUMENT)),
    [inputValues]
  )

  const {
    showDocumentPicker,
    toggleShowDocumentPicker,
    documentsPicked,
    setDocumentsPicked,
    documentPickerTrigger,
    setDocumentPickerTrigger,
  } = useDocumentPicker<{
    columnId: string
    row: DirtyTableEntryRow
  }>(documentIds)

  const allRows = useMemo(() => {
    const allRawRows = inputValues.reduce((accumulator, currentValue) => {
      currentValue.rows.forEach((row, index) => {
        accumulator[index] = accumulator[index] || { id: nanoid(), cells: [] }
        accumulator[index].cells.push({
          data: row,
          component: (
            <TableInput
              columnId={currentValue.id}
              row={row}
              onChange={onChangeInputValues}
              fileOptions={fileOptions}
              openDocumentPicker={() => {
                setDocumentPickerTrigger({
                  columnId: currentValue.id,
                  row,
                })
                toggleShowDocumentPicker(true)
              }}
              documentPickedName={
                row.values[0] && row.type === TemplateEntryType.DOCUMENT
                  ? documentsPicked.find(({ _id }) => _id === row.values[0])?.title
                  : undefined
              }
            />
          ),
        })
      })
      return accumulator
    }, [] as { id: string; cells: { data: DirtyTableEntryRow; component: ReactNode }[] }[])

    const maxLength = Math.max(...allRawRows.map(row => row.cells.length))
    return allRawRows.map(row => {
      const length = maxLength - row.cells.length
      if (length === 0) {
        return row
      }
      return {
        ...row,
        cells: [
          ...row.cells,
          ...Array.from(new Array(length)).map(() => ({
            data: { id: `blank-${nanoid()}` },
            component: null,
          })),
        ],
      }
    })
  }, [
    inputValues,
    onChangeInputValues,
    fileOptions,
    documentsPicked,
    setDocumentPickerTrigger,
    toggleShowDocumentPicker,
  ])

  useOnChangeEvent({
    value: inputValues,
    onChange,
  })

  const firstColumFixed = inputValues.length > 0 && inputValues[0].lock

  const enabled = disableEntry === true ? false : entry.enabled ?? true

  const { enabledFieldId, enabledFieldName } = useEntryFieldAttributes(index, prefixName)

  return (
    <>
      {label ? (
        <span
          className={classNames('block text-sm font-medium mb-4', {
            'text-gray-400': !enabled,
            'text-gray-700': enabled,
          })}
        >
          {label}
        </span>
      ) : (
        <EntryLabel
          naCheckboxId={enabledFieldId}
          naCheckboxName={enabledFieldName}
          naUnchecked={enabled}
          onNaChange={enabled => {
            setFieldValue(enabledFieldName, enabled)
          }}
          naCheckboxLabel={t('form.na')}
          withNa
          lightTextOnNAChecked
        >
          <span
            className={classNames('block text-sm font-medium mb-4', {
              'text-gray-400': !enabled,
              'text-gray-700': enabled,
            })}
          >
            {name}
          </span>
        </EntryLabel>
      )}

      {enabled && (
        <div className='flex flex-col'>
          <div className='-my-2  sm:-mx-6 lg:-mx-8'>
            <div className='py-2 align-middle sm:px-6 lg:px-8'>
              <div className='shadow border-b border-gray-200 sm:rounded-lg'>
                <div className='flex w-full'>
                  <div className='overflow-x-auto w-full'>
                    <table className='divide-y divide-gray-200 min-w-full'>
                      <thead className='bg-gray-50'>
                        <tr>
                          {inputValues.map((column, columnIndex) => (
                            <th
                              key={`th-${column.id}`}
                              className={classNames(
                                'px-6 py-3 max-h-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50',
                                {
                                  'sticky left-0 z-20': firstColumFixed && columnIndex === 0,
                                }
                              )}
                              style={{
                                minWidth: '20rem',
                              }}
                            >
                              {column.title}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {allRows.map((row, rowIndex) => (
                          <tr key={`tr-${rowIndex}`} className='relative'>
                            {row.cells.map(({ data, component }, cellIndex) => (
                              <td
                                key={`td-${data.id}`}
                                className={classNames(
                                  'px-6 py-4 text-sm text-gray-500 whitespace-nowrap md:whitespace-normal',
                                  {
                                    'bg-white': isEven(rowIndex),
                                    'bg-gray-50': isOdd(rowIndex),

                                    'sticky left-0 z-20': firstColumFixed && cellIndex === 0,
                                  }
                                )}
                              >
                                <div className='TaskTableInputField-Input'>
                                  <div className=''>{component}</div>
                                </div>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {hasDocuments && (
        <DocumentPicker
          show={showDocumentPicker}
          toggle={toggleShowDocumentPicker}
          onPick={documentFile => {
            if (!documentPickerTrigger) {
              return
            }
            onChangeInputValues(documentPickerTrigger.columnId, {
              ...documentPickerTrigger.row,
              values: [documentFile._id],
            })
            setDocumentsPicked([...documentsPicked, documentFile])
            setDocumentPickerTrigger(null)
            toggleShowDocumentPicker(false)
          }}
        />
      )}
    </>
  )
}
