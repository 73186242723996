import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  SelectField,
  SelectFieldProps,
  createSelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { useState } from 'react'
import { useInfiniteTemplates, useTemplate } from '../hooks'
import { TemplateFilters } from '../interfaces'
import { getTemplateEndpointTypeByContext, getTemplateTypesByContext } from '../utils'

export const TemplatesSelectField = ({
  filters,
  templateIdValue,
  type,
  ...props
}: Omit<SelectFieldProps, 'options'> & {
  filters?: TemplateFilters
  type: 'tasks' | 'compliances' | 'issues' | 'metadata'
  templateIdValue?: string
}) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const templateTypes = getTemplateTypesByContext(type)

  const {
    templates,
    isLoading: isLoadingTemplateOptions,
    fetchNextPage,
  } = useInfiniteTemplates({
    search: debouncedSearch,
    types: templateTypes,
    ...filters,
  })
  const { template: templateValue, isLoading: isLoadingTemplateValue } = useTemplate(
    templateIdValue || '',
    getTemplateEndpointTypeByContext(type),
    {
      enabled: !!templateIdValue,
    }
  )

  const templatesOptions = templates.map(template =>
    createSelectorOption(template.name, template._id)
  )

  const isLoading = isLoadingTemplateOptions || isLoadingTemplateValue

  const value = templateValue
    ? createSelectorOption(templateValue.name, templateValue._id)
    : props.value

  return (
    <SelectField
      {...props}
      value={value}
      isLoading={isLoading}
      options={templatesOptions}
      onInputChange={inputValue => {
        setSearch(inputValue)
      }}
      onMenuScrollToBottom={() => {
        fetchNextPage()
      }}
    />
  )
}
