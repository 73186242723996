import { COLOR, DropdownBadge } from '@blockchain-traceability-sl/tailwind-components'
import { CheckIcon, EyeIcon, LockClosedIcon, RefreshIcon } from '@heroicons/react/outline'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IssueStatus } from '../interfaces'

export interface IssueStatusSelectorProps {
  status: IssueStatus
  onIssueStatusChange: (status: IssueStatus) => void
}

export const IssueStatusSelector = ({ status, onIssueStatusChange }: IssueStatusSelectorProps) => {
  const { t } = useTranslation('nsModalTaskIssues', {
    keyPrefix: 'status',
  })

  const [text, color] = useMemo(() => {
    switch (status) {
      case IssueStatus.OPEN:
      case IssueStatus.ACKNOWLEDGED:
      case IssueStatus.IN_PROGRESS:
        return [t('progress'), COLOR.RED]
      case IssueStatus.IN_REVIEW:
        return [t('review'), COLOR.YELLOW]
      case IssueStatus.RESOLVED:
        return [t('solved'), COLOR.PURPLE]
      case IssueStatus.CLOSED:
        return [t('closed'), COLOR.GREEN]

      default:
        return [t('progress'), COLOR.RED]
    }
  }, [status, t])

  return (
    <div className='relative'>
      <DropdownBadge
        badgeClassName='w-32'
        color={color}
        items={[
          {
            id: IssueStatus.IN_PROGRESS,
            name: t('progress'),
            onClick: () => onIssueStatusChange(IssueStatus.IN_PROGRESS),
            icon: RefreshIcon,
          },
          {
            id: IssueStatus.IN_REVIEW,
            name: t('review'),
            onClick: () => onIssueStatusChange(IssueStatus.IN_REVIEW),
            icon: EyeIcon,
          },
          {
            id: IssueStatus.RESOLVED,
            name: t('solved'),
            onClick: () => onIssueStatusChange(IssueStatus.RESOLVED),
            icon: CheckIcon,
          },
          {
            id: IssueStatus.CLOSED,
            name: t('closed'),
            onClick: () => onIssueStatusChange(IssueStatus.CLOSED),
            icon: LockClosedIcon,
          },
        ]}
      >
        <span className='text-xs'>{text}</span>
      </DropdownBadge>
    </div>
  )
}
