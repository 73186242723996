import { useUserId } from '@/features/auth'
import { Template } from '@/features/templates'
import { useCallback } from 'react'
import { TaskCreation, TaskStatus } from '../interfaces'
import { generateEntriesFromTemplate } from '@/features/entries'

export const useGenerateTaskFromTemplate = () => {
  const userId = useUserId()
  const generateTaskFromTemplate = useCallback(
    ({ template, dueDate }: { template: Template; dueDate: string }): TaskCreation => {
      return {
        formId: template._id,
        formName: template.name,
        formVersion: template.version,
        formControl: {
          code: template.control?.code || undefined,
          isoStandardReference: template.control?.isoStandardReference || undefined,
        },
        dueDate,
        entries: generateEntriesFromTemplate(template),
        status: TaskStatus.OPEN,
        assignedTo: template.defaults?.assignedTo || userId,
        issueResponder: template.defaults?.issueResponder || userId,
        truIds: [],
        receptionIds: [],
        tags: template.tags || [],
      }
    },
    [userId]
  )

  return {
    generateTaskFromTemplate,
  }
}
