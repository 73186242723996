/**
 * Item keys supported by this service
 */
export enum KEYS {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  TRACEABILITY_MODE = 'TRACEABILITY_MODE',
  TRANSLATION_LNG = 'i18nextLng',
  STAKEHOLDER_BANNER = 'STAKEHOLDER_BANNER',
  PRODUCT_BANNER = 'PRODUCT_BANNER',
}

/**
 * Get an item stored in localStorage
 *
 * @param itemKey item key to find
 * @param defaultValue default value
 * @deprecated use useLocalStorage instead
 */
export const get = <T = string>(itemKey: KEYS): T | null => {
  return localStorage.getItem(itemKey) as T | null
}

/**
 * Get an item stored in localStorage
 *
 * @param itemKey item key to find
 * @param defaultValue default value
 * @deprecated use useLocalStorage instead
 */
export const getJSON = <T>(itemKey: KEYS): T | undefined => {
  const item = localStorage.getItem(itemKey)
  return item ? (JSON.parse(item) as T) : undefined
}

/**
 * Set an item to be stored in localStorage
 *
 * @param itemKey item key to set
 * @param itemValue item value
 * @deprecated use useLocalStorage instead
 */
export const set = (itemKey: KEYS, itemValue: string) => {
  localStorage.setItem(itemKey, itemValue)
}

/**
 * Set an item to be stored in localStorage
 *
 * @param itemKey item key to set
 * @param itemJSON item value
 * @deprecated use useLocalStorage instead
 */
export const setJSON = <T>(itemKey: KEYS, itemJSON: T): void => {
  localStorage.setItem(itemKey, JSON.stringify(itemJSON))
}

/**
 * Remove an item stored in localStorage
 *
 * @param itemKey item key to remove
 * @deprecated use useLocalStorage instead
 */
export const remove = (itemKey: KEYS): void => {
  localStorage.removeItem(itemKey)
}

/**
 * Remove all items stored in localStorage
 *
 * @param itemKey item key to remove
 * @deprecated
 */
export const clear = (): void => {
  localStorage.clear()
}
