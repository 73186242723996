import { api } from '@/app/api'
import { Paginated, PaginationParams } from '@/hooks/use-pagination'
import { Shipment, ShipmentCreation, ShipmentFilters, ShipmentUpdate } from './interfaces'

/**
 * Define the url path
 * @constant
 */
export const SHIPMENT_URL = '/hydra/trus/shipments'

/**
 * Get Shipments by TRU ids
 *
 * @returns All the Shipments that match
 */
export const getByTRUIds = async (truIds: string[]): Promise<Shipment[]> => {
  if (truIds.length === 0) return []

  const { data: shipments } = await api.post<Shipment[] | void>(`${SHIPMENT_URL}/ids`, {
    ids: truIds,
  })
  return shipments || []
}

/**
 * Get Shipment by id
 * @param shipmentId The Shipment id
 * @returns The Shipment that match
 */
export const getById = async (shipmentId: string): Promise<Shipment | undefined> => {
  const { data: shipment } = await api.post<Shipment | void>(`${SHIPMENT_URL}/${shipmentId}`)
  return shipment || undefined
}

/**
 * Get all company Shipments with pagination and filter support
 * @returns All the Shipments that match
 */
export const getPaginatedShipments = async (
  { truIds, shipmentDates, startDate, endDate, tos, productIds }: ShipmentFilters,
  pagination: PaginationParams
): Promise<Paginated<Shipment>> => {
  const searchParams = new URLSearchParams()
  truIds?.forEach(truId => {
    searchParams.append('truIds[]', truId)
  })
  shipmentDates?.forEach(shipmentDate => {
    searchParams.append('shipmentDates[]', shipmentDate)
  })
  if (startDate) searchParams.append('startDate', startDate)
  if (endDate) searchParams.append('endDate', endDate)
  tos?.forEach(to => {
    searchParams.append('tos[]', to)
  })
  productIds?.forEach(productId => {
    searchParams.append('productIds[]', productId)
  })
  searchParams.append('limit', pagination.limit.toString())
  searchParams.append('offset', pagination.offset.toString())
  const { data } = await api.get<Paginated<Shipment>>(SHIPMENT_URL, { params: searchParams })
  return data
}

/**
 * Get Shipments by To id
 *
 * @returns All the Shipments shipped to the stakeHolder
 */
export const getByShippedTo = async (stakeHolderId: string): Promise<Shipment[]> => {
  const { data: shipments } = await api.get<Shipment[] | void>(
    `${SHIPMENT_URL}/to/${encodeURIComponent(stakeHolderId)}`
  )
  return shipments || []
}

/**
 * Get the Company logged shipments count
 *
 *
 * @returns All the Company logged shipments count
 */
export const getCount = async (): Promise<number> => {
  const { data: shipmentsCount } = await api.get<number>(`${SHIPMENT_URL}/count`)
  return shipmentsCount
}

/**
 * Create Shipment
 */
export const create = async (shipment: ShipmentCreation): Promise<void> => {
  await api.post<void>(SHIPMENT_URL, shipment)
}

/**
 * Create Shipment with CSV file
 */
export const createWithCSV = async (file: File): Promise<{ total: number }> => {
  const formData = new FormData()
  formData.append('file', file)
  const { data } = await api.post<{ total: number }>(`${SHIPMENT_URL}/csv`, formData)
  return data
}

/**
 * Get Shipment CSV template
 */
export const getCsvTemplate = async (): Promise<ArrayBuffer> => {
  const { data } = await api.get<ArrayBuffer>(`${SHIPMENT_URL}/csv`, {
    responseType: 'arraybuffer',
  })
  return data
}

/**
 * Update Shipment
 */
export const update = async (shipmentId: string, shipment: ShipmentUpdate): Promise<void> => {
  await api.patch<void>(`${SHIPMENT_URL}/${encodeURIComponent(shipmentId)}`, shipment)
}

/**
 * Delete Shipment by _id
 *
 * @param shipmentId The Shipment _id to delete
 */
export const remove = async (shipmentId: string): Promise<void> => {
  await api.delete<void>(`${SHIPMENT_URL}/${encodeURIComponent(shipmentId)}`)
}
