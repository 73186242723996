import { Banner } from '@blockchain-traceability-sl/tailwind-components'
import { ExclamationIcon } from '@heroicons/react/outline'
import { useSubscriptionInfo } from '../hooks'
import { SubscriptionStatus } from '../interfaces'
import { Trans, useTranslation } from 'react-i18next'

export const SubscriptionStatusBanner = () => {
  const { t } = useTranslation('nsPricingBanner')
  const { subscriptionInfo } = useSubscriptionInfo()

  if (subscriptionInfo?.status === SubscriptionStatus.UNPAID) {
    return (
      <div className='px-4 sm:px-6 md:px-8 mt-2'>
        <Banner
          message={<Trans t={t} i18nKey='billing.payment' components={{ b: <b /> }} />}
          MainIcon={ExclamationIcon}
          color='danger'
          closeable={false}
          compact
        />
      </div>
    )
  }

  return null
}
