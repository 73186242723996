import { api } from '@/app/api'
import { DownloaderMIMEType, downloader } from '@/helpers/downloader'
import { Paginated, PaginationParams } from '@/hooks/use-pagination'
import {
  AddChildToCompliance,
  ChildCompliance,
  Compliance,
  ComplianceCreateInput,
  ComplianceStatus,
  ComplianceUpdateInput,
  ComplianceValidationStatus,
  Conflict,
  PublicCompliance,
  PublicComplianceContactDetails,
  UpdatePublicCompliance,
} from './interfaces'
import { Entry } from '@/features/entries'
import { FileUploadResponse } from '@/features/files'

const COMPLIANCES_URL = '/tasks/compliances'

export const createCompliance = async (complianceInput: ComplianceCreateInput): Promise<string> => {
  const { data: complianceId } = await api.post<string>(COMPLIANCES_URL, complianceInput)

  return complianceId
}

export const updateCompliance = async (
  id: string,
  complianceInput: ComplianceUpdateInput
): Promise<void> => {
  await api.patch<string>(`${COMPLIANCES_URL}/${id}`, complianceInput)
}

export const deleteCompliance = async (complianceId: string): Promise<void> => {
  await api.delete(`${COMPLIANCES_URL}/${complianceId}`)
}

export const getCompliancesByStakeHolderIds = async (
  stakeHolderIds: string[],
  { status, active }: { status?: ComplianceStatus; active?: boolean } = {}
): Promise<Compliance[]> => {
  const searchParams = new URLSearchParams()
  stakeHolderIds.forEach(stakeHolderId => {
    searchParams.append('stakeHolderIds[]', stakeHolderId)
  })
  if (status) {
    searchParams.append('status', status)
  }
  if (active) {
    searchParams.append('active', 'true')
  }
  const { data } = await api.get<Compliance[] | void>(
    `${COMPLIANCES_URL}?${searchParams.toString()}`
  )
  return data || []
}

export const getPaginatedCompliances = async (
  {
    search,
    status = [],
    hasParentCompliance,
    excludeChildsNotApproved,
  }: {
    search?: string
    status?: (ComplianceStatus | ComplianceValidationStatus)[]
    hasParentCompliance?: boolean
    excludeChildsNotApproved?: true
  },
  pagination: PaginationParams
): Promise<Paginated<Compliance>> => {
  const searchParams = new URLSearchParams()
  if (search) {
    searchParams.append('search', search)
  }
  status.forEach(value => {
    searchParams.append('status[]', value)
  })

  if (hasParentCompliance !== undefined) {
    searchParams.append('hasParentCompliance', String(hasParentCompliance))
  }

  if (excludeChildsNotApproved) {
    searchParams.append('excludeChildsNotApproved', String(excludeChildsNotApproved))
  }

  searchParams.append('limit', pagination.limit.toString())
  searchParams.append('offset', pagination.offset.toString())
  const { data } = await api.get<Paginated<Compliance>>(
    `${COMPLIANCES_URL}?${searchParams.toString()}`
  )
  return data
}

export const getCompliances = async ({
  search,
  status = [],
  templateIds = [],
  stakeHolderIds = [],
  hasParentCompliance,
  parentComplianceIds = [],
}: {
  search?: string
  status?: (ComplianceStatus | ComplianceValidationStatus)[]
  templateIds?: string[]
  stakeHolderIds?: string[]
  hasParentCompliance?: boolean
  parentComplianceIds?: string[]
}): Promise<Compliance[]> => {
  const searchParams = new URLSearchParams()
  if (search) {
    searchParams.append('search', search)
  }
  status.forEach(value => {
    searchParams.append('status[]', value)
  })
  templateIds.forEach(value => {
    searchParams.append('templateIds[]', value)
  })
  stakeHolderIds.forEach(stakeHolderId => {
    searchParams.append('stakeHolderIds[]', stakeHolderId)
  })

  if (hasParentCompliance !== undefined) {
    searchParams.append('hasParentCompliance', String(hasParentCompliance))
  }

  parentComplianceIds.forEach(parentComplianceId => {
    searchParams.append('parentComplianceIds[]', parentComplianceId)
  })

  const { data } = await api.get<Compliance[] | void>(
    `${COMPLIANCES_URL}?${searchParams.toString()}`
  )
  return data || []
}

export const getComplianceCount = async ({
  search,
  status = [],
  hasParentCompliance,
}: {
  search?: string
  status?: (ComplianceStatus | ComplianceValidationStatus)[]
  hasParentCompliance?: boolean
}): Promise<number> => {
  const searchParams = new URLSearchParams()
  if (search) {
    searchParams.append('search', search)
  }

  status.forEach(value => {
    searchParams.append('status[]', value)
  })

  if (hasParentCompliance !== undefined) {
    searchParams.append('hasParentCompliance', String(hasParentCompliance))
  }

  const { data } = await api.get<number>(`${COMPLIANCES_URL}/count?${searchParams.toString()}`)
  return data
}

export const getComplianceById = async (complianceId: string) => {
  const { data } = await api.get<Compliance | undefined>(`${COMPLIANCES_URL}/${complianceId}`)

  return data
}

export const getPublicCompliance = async (
  publicId: string
): Promise<(PublicCompliance & { childCompliances: PublicCompliance[] }) | undefined> => {
  const { data } = await api.get<
    (PublicCompliance & { childCompliances: PublicCompliance[] }) | undefined
  >(`${COMPLIANCES_URL}/public/${publicId}`)
  return data
}

export const updatePublicCompliance = async ({
  body,
  publicId,
}: UpdatePublicCompliance): Promise<void> => {
  await api.patch<void>(`${COMPLIANCES_URL}/public/${publicId}`, body)
}

export const submitPublicCompliance = async (
  publicId: string,
  body: {
    entries: Entry[]
    contactDetails: PublicComplianceContactDetails
    compliantStakeHolderProduct?: {
      reference?: string
    }
    childCompliances?: ChildCompliance[]
  }
): Promise<void> => {
  await api.post<void>(`${COMPLIANCES_URL}/public/${publicId}/submit`, body)
}

export const uploadPublicComplianceFile = async (
  publicId: string,
  file: File
): Promise<FileUploadResponse> => {
  const formData = new FormData()
  formData.append('file', file)
  const { data } = await api.post<FileUploadResponse>(
    `${COMPLIANCES_URL}/public/${publicId}/file`,
    formData
  )
  return data
}

export const getPublicUrlComplianceFile = async (
  publicId: string,
  filename: string
): Promise<string | void> => {
  const { data: publicUrl } = await api.get<string | void>(
    `${COMPLIANCES_URL}/public/${publicId}/file/public?name=${filename}`
  )
  return publicUrl
}

export const downloadComplianceFile = async (
  publicId: string,
  filename: string,
  mimeType: DownloaderMIMEType
): Promise<void> => {
  const response = await api.get<string | void>(
    `${COMPLIANCES_URL}/public/${publicId}/file?name=${filename}`,
    {
      responseType: 'blob',
    }
  )
  const blob = response.data as unknown
  downloader(blob as Blob, mimeType, filename)
}

export const validateCompliance = async (
  complianceId: string,
  complianceInput: { status: ComplianceValidationStatus; message?: string; conflicts?: Conflict[] }
) => {
  await api.post<void>(`${COMPLIANCES_URL}/${complianceId}/validate`, complianceInput)
}

export const createChildCompliance = async (
  publicId: string,
  childCompliance: AddChildToCompliance
) => {
  const { data } = await api.post<ChildCompliance>(
    `${COMPLIANCES_URL}/public/${publicId}/children`,
    childCompliance
  )
  return data
}

export const deleteChildCompliance = async (publicId: string, childCompliancePublicId: string) => {
  const object = {
    childCompliancePublicId: childCompliancePublicId,
  }
  await api.delete(`${COMPLIANCES_URL}/public/${publicId}/children`, { data: object })
}

export const getChildCompliancesByPublicId = async (
  publicId: string
): Promise<ChildCompliance[]> => {
  const { data } = await api.get<ChildCompliance[] | void>(
    `${COMPLIANCES_URL}/public/${publicId}/children`
  )
  return data || []
}
