import { useLayout } from '../context'
import { useEventListener } from '@/hooks/use-event-listener'
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

export interface ButtonScrollProps {
  /**
   * @default true
   */
  active?: boolean

  /**
   * Dependencies to force update the calculation effects
   */
  readonly depsEffect?: unknown

  /**
   * Offset pixels to change the arrow direction
   * @default 100
   */
  triggerOffset?: number

  /**
   * Tolerance value to active or not the scroll button
   * @default 100
   */
  tolerance?: number
}

/**
 * Button component to manage the main content layout scroll
 */
export const ButtonScroll = ({
  active = true,
  depsEffect,
  triggerOffset = 100,
  tolerance = 100,
}: ButtonScrollProps) => {
  const { mainContentElementRef } = useLayout()
  const [direction, setDirection] = useState<'up' | 'down'>('down')
  const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState<boolean>(true)

  const checkHasVerticalScrollbar = (): boolean => {
    if (!mainContentElementRef.current) return false
    return (
      mainContentElementRef.current.scrollHeight >
        mainContentElementRef.current.clientHeight + tolerance ||
      mainContentElementRef.current.scrollWidth >
        mainContentElementRef.current.clientWidth + tolerance
    )
  }

  useEffect(() => {
    setImmediate(() => {
      if (mainContentElementRef.current) {
        setHasVerticalScrollbar(checkHasVerticalScrollbar)
        if (
          mainContentElementRef.current.scrollTop +
            mainContentElementRef.current.clientHeight +
            triggerOffset >=
          mainContentElementRef.current.scrollHeight
        ) {
          setDirection('up')
        } else if (mainContentElementRef.current.scrollTop < triggerOffset) {
          setDirection('down')
        }
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    active,
    mainContentElementRef.current,
    mainContentElementRef.current?.scrollTop,
    mainContentElementRef.current?.scrollHeight,
    mainContentElementRef.current?.clientHeight,
    mainContentElementRef.current?.scrollHeight,
    mainContentElementRef.current?.clientHeight,
    depsEffect,
  ])

  const handleClick = () => {
    if (mainContentElementRef.current && direction === 'up') {
      mainContentElementRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } else if (mainContentElementRef.current && direction === 'down') {
      mainContentElementRef.current.scrollTo({
        top: mainContentElementRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }

  useEventListener(
    'scroll',
    () => {
      if (mainContentElementRef.current) {
        setHasVerticalScrollbar(checkHasVerticalScrollbar)
        if (
          mainContentElementRef.current.scrollTop +
            mainContentElementRef.current.clientHeight +
            triggerOffset >=
          mainContentElementRef.current.scrollHeight
        ) {
          setDirection('up')
        } else if (mainContentElementRef.current.scrollTop < triggerOffset) {
          setDirection('down')
        }
      }
    },
    mainContentElementRef.current
  )

  const ArrowCircleDirectionIcon = direction === 'down' ? ArrowCircleDownIcon : ArrowCircleUpIcon

  return (
    <button
      type='button'
      /**
       * TODO: Change to arbitrary values when upgrade to TailwindCSS v3
       * @see https://tailwindcss.com/docs/adding-custom-styles#using-arbitrary-values
       */
      style={{ top: '60%' }}
      className={classNames(
        'rounded-l-md bg-blue-600 p-1.5 text-white shadow-md-dark hover:bg-blue-700 fixed right-5 md:right-9 px-4 py-3 lg:hidden',
        { hidden: !active || !hasVerticalScrollbar }
      )}
      onClick={handleClick}
    >
      <span>
        <ArrowCircleDirectionIcon className='h-6 w-6' aria-hidden='true' />
      </span>
    </button>
  )
}
