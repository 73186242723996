import { ReadableTask, TaskStatus } from '../../interfaces'
import { ReactNode, useMemo } from 'react'
import { ValidationRequestStatus } from '@/features/validationRequests'
import { IssueStatus } from '@/features/issues'
import dayjs from 'dayjs'
import classNames from 'classnames'

export const DayCell = ({
  tasks,
  date,
  onCellClick,
}: {
  tasks: ReadableTask[]
  date: Date
  onCellClick?: () => void
}) => {
  const isToday: boolean = dayjs(date).isSame(new Date(), 'day')

  const pendingTasks = useMemo(() => {
    return tasks.filter(
      task =>
        // Opened tasks
        task.status === TaskStatus.OPEN ||
        // Validation pending
        task.lastValidation?.status === ValidationRequestStatus.PENDING ||
        // Validation rejected
        task.lastValidation?.status === ValidationRequestStatus.REJECTED ||
        // Open issue
        task.issues.some(issue => issue.status !== IssueStatus.CLOSED)
    )
  }, [tasks])

  const doneTasks = useMemo(() => {
    // Return tasks not included in pendingTasks
    return tasks.filter(task => !pendingTasks.some(pendingTask => pendingTask._id === task._id))
  }, [tasks, pendingTasks])

  /**
   * Get the content of the cell
   * @param quantity The number of tasks
   * @returns The content of the cell
   */
  const getCellContent = (quantity: number): ReactNode => {
    if (quantity <= 1) {
      return null
    }
    return quantity
  }

  // Only pending tasks
  if (pendingTasks.length === 0 && doneTasks.length !== 0) {
    return (
      <div
        className='w-full min-w-5 h-14 bg-gray-200 flex items-center justify-center text-xs font-medium text-gray-800 cursor-pointer'
        onClick={onCellClick}
      >
        {getCellContent(doneTasks.length)}
      </div>
    )
  }

  // Only done tasks
  if (doneTasks.length === 0 && pendingTasks.length !== 0) {
    return (
      <div
        className='w-full min-w-5 h-14 bg-yellow-100 flex items-center justify-center text-xs font-medium text-yellow-800 cursor-pointer'
        onClick={onCellClick}
      >
        {getCellContent(pendingTasks.length)}
      </div>
    )
  }

  // Both done and pending tasks
  if (doneTasks.length !== 0 && pendingTasks.length !== 0) {
    return (
      <div className='w-full min-w-5 h-14'>
        <div
          className='w-full min-w-5 h-7 bg-gray-200 flex items-center justify-center text-xs font-medium text-gray-800 cursor-pointer'
          onClick={onCellClick}
        >
          {getCellContent(doneTasks.length)}
        </div>
        <div
          className='w-full min-w-5 h-7 bg-yellow-100 flex items-center justify-center text-xs font-medium text-yellow-800 cursor-pointer'
          onClick={onCellClick}
        >
          {getCellContent(pendingTasks.length)}
        </div>
      </div>
    )
  }

  // No tasks
  return (
    <div
      className={classNames('w-full min-w-5 h-14', {
        'bg-blue-50': isToday,
        'bg-white': !isToday,
      })}
    />
  )
}
