import { useEffect, useRef } from 'react'

export const useInterval = (callback: Function, delay: number) => {
  const callbacRef = useRef<Function>()

  // update callback function with current render callback that has access to latest props and state
  useEffect(() => {
    callbacRef.current = callback
  })

  useEffect(() => {
    if (!delay) {
      return () => {
        // Do nothing
      }
    }

    const interval = setInterval(() => {
      callbacRef.current && callbacRef.current()
    }, delay)
    return () => clearInterval(interval)
  }, [delay])
}
