import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  SelectMultiField,
  SelectMultiFieldProps,
  SelectorOption,
  createSelectorOption,
  noop,
} from '@blockchain-traceability-sl/tailwind-components'
import { useEffect, useState } from 'react'
import { useFetchProducts, usePaginatedProducts } from '../hooks'
import { IProductFilters } from '../interfaces'
import { getProductNameWithReference } from '../utils'

export const ProductsSelectMultiField = (
  props: Omit<SelectMultiFieldProps, 'options'> & Omit<IProductFilters, 'search'>
) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const [options, setOptions] = useState<SelectorOption[]>([])

  const {
    isLoading: isPaginatedProductsLoading,
    products,
    fetchNextPage,
  } = usePaginatedProducts({
    filters: { search: debouncedSearch },
  })

  const { fetchProducts } = useFetchProducts({
    search: debouncedSearch,
  })

  const [isLoading, setIsLoading] = useState(isPaginatedProductsLoading)

  useEffect(() => {
    setOptions(
      products.map(product =>
        createSelectorOption(getProductNameWithReference(product), product._id)
      )
    )
  }, [debouncedSearch, products])

  useEffect(() => {
    setIsLoading(isPaginatedProductsLoading)
  }, [isPaginatedProductsLoading])

  const handleSelectAll = async () => {
    setIsLoading(true)
    fetchProducts()
      .then(products => {
        const newOptions = products.map(product =>
          createSelectorOption(getProductNameWithReference(product), product._id)
        )
        setOptions(newOptions)
        if (props.onChange) {
          props.onChange(newOptions)
        }
      })
      .catch(noop)
      .finally(() => setIsLoading(false))
  }

  return (
    <SelectMultiField
      {...props}
      isLoading={isLoading}
      options={options}
      onInputChange={setSearch}
      onMenuScrollToBottom={() => fetchNextPage()}
      onSelectAll={handleSelectAll}
    />
  )
}
