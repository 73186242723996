import { useUserId } from '@/features/auth'
import { ValidationRequestStatus, useGetLastValidator } from '@/features/validationRequests'
import { Badge, COLOR, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { Popover } from '@headlessui/react'
import {
  CalendarIcon,
  CogIcon,
  CollectionIcon,
  ExclamationIcon,
  EyeIcon,
  UserIcon,
} from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { usePopper } from 'react-popper'
import { ReadableTask, TaskStatus } from '../../interfaces'
import { useGetAssignedCreatorTaskName } from '../../utils'
import { TaskIcon } from '../Icons'
import { TaskIssuesBadges } from '../TaskIssuesBadges'
import { TaskValidationIconBadge } from '../TaskValidationIconBadge'

interface TaskCardProps {
  task: ReadableTask
  onManageClick: () => void
  onReviewClick: () => void
}

// Offset y according to the number of items
const POPOVER_MENU_OFFSET_Y = [20, 30, 45, 80]

export const TaskCard = ({ task, onManageClick, onReviewClick }: TaskCardProps) => {
  const { t } = useTranslation('nsTasksListPage')
  const userId = useUserId()
  const { getAssignedCreatorTaskName } = useGetAssignedCreatorTaskName()
  const { getLastValidatorName } = useGetLastValidator()

  const { isDragging, setNodeRef, listeners, attributes, transform } = useDraggable({
    id: task._id,
  })

  const style = useMemo(
    () => ({
      transform: CSS.Transform.toString(
        transform ? { ...transform, scaleX: 1, scaleY: 1 } : transform
      ),
    }),
    [transform]
  )

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const naCount = useMemo(
    () =>
      [...task.entries, ...task.issues.flatMap(issue => issue.entries)].reduce(
        (accumulator, entry) => {
          if (!entry.enabled) {
            return accumulator + 1
          }
          return accumulator
        },
        0
      ),
    [task.entries, task.issues]
  )

  const numberOfExtraLines = [
    !!task.lastValidationRequest,
    naCount > 0,
    task.issues.length > 0,
  ].filter(Boolean).length

  const offsetY = POPOVER_MENU_OFFSET_Y[numberOfExtraLines]

  const popper = usePopper(referenceElement, popperElement, {
    placement: 'right',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [offsetY, 18],
        },
      },
    ],
  })

  const isReviewEnabled =
    task.lastValidationRequest?.status === ValidationRequestStatus.PENDING &&
    task.lastValidationRequest?.reviewers.find(reviewer => reviewer.id === userId)

  return (
    <Popover>
      <Popover.Button
        as='div'
        ref={element => {
          setNodeRef(element)
          setReferenceElement(element)
        }}
        data-testid='calendar-task-card'
        className={classNames('relative m-2 bg-white shadow rounded-lg py-3.5 h-44', {
          'cursor-move z-10': isDragging,
        })}
        {...attributes}
        {...listeners}
        style={style}
      >
        <div className='mb-2 flex gap-1 flex-col lg:flex-row px-2'>
          <Badge color={task.status === TaskStatus.OPEN ? COLOR.YELLOW : COLOR.GRAY}>
            {t(`calendar.statusTypes.${task.status}`)}
          </Badge>
        </div>
        <div className='px-2'>
          <div className='flex mb-2 items-start gap-2 h-8'>
            <TaskValidationIconBadge validation={task.lastValidationRequest} />

            <div className='line-clamp-2 text-xs leading-4 font-semibold text-gray-900'>
              {task.template?.name}
            </div>
          </div>
          <div className='flex mb-2 items-start gap-2'>
            <div>
              <UserIcon className='h-5 w-5 text-blue-600' />
            </div>
            <div className='text-xs leading-4 font-normal text-gray-500 truncate'>
              {task.assignedTo === userId
                ? t('table.assignedToMe')
                : getAssignedCreatorTaskName(task.assignedTo)}
            </div>
          </div>

          {task.issues.length > 0 && (
            <div className='flex mb-2 gap-2 items-center'>
              <div>
                <ExclamationIcon className='h-5 w-5 text-blue-600' />
              </div>
              <TaskIssuesBadges issues={task.issues} size={SIZE.SMALL} />
            </div>
          )}
          {naCount > 0 && (
            <div className='flex mb-2 gap-2 items-center'>
              <div className='text-xs leading-4 font-medium text-blue-600'>N/A</div>
              <span className='inline-flex items-center justify-center font-medium bg-gray-300 text-gray-800 px-2 py-0.5 text-xs rounded-full'>
                {naCount}
              </span>
            </div>
          )}
        </div>
      </Popover.Button>
      <Popover.Panel>
        <div
          ref={setPopperElement}
          style={popper.styles.popper}
          {...popper.attributes.popper}
          className='z-30 bg-white shadow-2xl rounded-lg w-80'
          data-testid='calendar-task-card-popover'
        >
          <div className='max-h-80 divide-y divide-gray-200 flex flex-col justify-between px-2 pt-3.5'>
            <div>
              <div className='flex justify-between'>
                <div className='mb-2 flex gap-1'>
                  <Badge color={task.status === TaskStatus.OPEN ? COLOR.YELLOW : COLOR.GRAY}>
                    {t(`calendar.statusTypes.${task.status}`)}
                  </Badge>
                </div>
                <Popover.Button
                  className='w-5 h-5 text-gray-400 cursor-pointer'
                  aria-hidden='true'
                  data-testid='calendar-task-card-popover-exit'
                  as={XIcon}
                />
              </div>
              <div className='flex mb-2 items-start gap-2'>
                <div>
                  <TaskIcon className='h-6 w-5 text-blue-600' />
                </div>
                <div className='line-clamp-3 text-sm leading-5 font-medium text-gray-900'>
                  {task.template?.name}
                </div>
              </div>

              <div className='flex mb-2 items-center gap-2'>
                <div>
                  <CollectionIcon className='h-5 w-5 text-blue-600' />
                </div>
                <div className='text-sm leading-5 font-normal text-gray-500'>
                  {t('calendar.card.version', {
                    version: task.formVersion,
                  })}
                </div>
              </div>

              <div className='flex mb-2 items-center gap-2'>
                <div>
                  <CalendarIcon className='h-5 w-5 text-blue-600' />
                </div>
                <div className='text-sm leading-5 font-normal text-gray-900 '>
                  <Trans
                    t={t}
                    i18nKey='calendar.card.dueDate'
                    components={{
                      b: <b />,
                    }}
                    values={{
                      date: new Date(task.dueDate),
                    }}
                  />
                </div>
              </div>

              <div className='flex mb-2 items-start gap-2'>
                <div>
                  <UserIcon className='h-5 w-5 text-blue-600' />
                </div>
                <div className='text-xs leading-4 font-normal text-gray-500 truncate'>
                  {task.assignedTo === userId
                    ? t('table.assignedToMe')
                    : getAssignedCreatorTaskName(task.assignedTo)}
                </div>
              </div>

              {task.issues.length > 0 && (
                <div className='flex mb-2 gap-2 items-center'>
                  <div>
                    <ExclamationIcon className='h-5 w-5 text-blue-600' />
                  </div>
                  <TaskIssuesBadges issues={task.issues} size={SIZE.SMALL} />
                </div>
              )}
              {naCount > 0 && (
                <div className='flex mb-2 gap-2 items-center'>
                  <div className='text-xs leading-4 font-medium text-blue-600'>N/A</div>
                  <span className='inline-flex items-center justify-center font-medium bg-gray-300 text-gray-800 px-2 py-0.5 text-xs rounded-full'>
                    {naCount}
                  </span>
                </div>
              )}

              {task.lastValidationRequest && (
                <div className='flex mb-3 items-start gap-2'>
                  <TaskValidationIconBadge validation={task.lastValidationRequest} />
                  <div className='text-sm leading-5 font-normal text-gray-900'>
                    <Trans
                      t={t}
                      i18nKey={`calendar.card.validator.${task.lastValidationRequest.status}`}
                      components={{
                        b: <b />,
                      }}
                      values={{
                        name: getLastValidatorName(task.lastValidationRequest),
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            <div
              className={classNames('flex divide-x divide-gray-200', {
                '-mt-2.5': isReviewEnabled,
              })}
            >
              <div className='w-0 flex-1 flex'>
                <button
                  onClick={onManageClick}
                  className={
                    'relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium hover:text-gray-500 focus:outline-none -mr-px'
                  }
                >
                  <CogIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                  <span className='ml-3'>{t('calendar.card.manage')}</span>
                </button>
              </div>
              {isReviewEnabled && (
                <div className='w-0 flex-1 flex'>
                  <button
                    onClick={onReviewClick}
                    className={
                      'relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium hover:text-gray-500 focus:outline-none'
                    }
                  >
                    <EyeIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                    <span className='ml-3'>{t('calendar.card.review')}</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  )
}
