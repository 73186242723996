import { PropsWithChildren, useEffect } from 'react'
import { SlideOver } from '@blockchain-traceability-sl/tailwind-components'
import { TaskDetailsCard } from './TaskDetailsCard'
import { ReadableTask } from '../../interfaces'
import dayjs from 'dayjs'

export type SidebarDetailsProps = PropsWithChildren<{
  open: boolean
  onClose: () => void
  date?: Date
  taskData: ReadableTask[]
}>
export const SidebarDetails = ({ open, date, onClose, taskData }: SidebarDetailsProps) => {
  const title = dayjs(date).format('MMMM YYYY')
  const subTitle = dayjs(date).format('dddd D')

  useEffect(() => {
    if (taskData.length === 0) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskData.length])

  return (
    <SlideOver open={open} toggle={onClose} dismissButton>
      <SlideOver.Title>
        <div>
          <div className='flex items-start justify-between gap-x-6'>
            <div className="font-semibold  font-['Inter']  text-gray-900 flex gap-2">
              <span>{title}</span>
              <span className='text-gray-400 '>- {subTitle}</span>
            </div>
          </div>
        </div>
      </SlideOver.Title>
      <SlideOver.Body>
        <div className='relative flex-1'>
          {taskData.length > 0 && (
            <div className='mb-4 flex flex-col gap-6'>
              {taskData.map((task: ReadableTask) => (
                <TaskDetailsCard key={task._id} task={task} />
              ))}
            </div>
          )}
        </div>
      </SlideOver.Body>
    </SlideOver>
  )
}
