import { DEFAULT_PAGE_SIZE, Paginated, PaginationParams } from '@/hooks/use-pagination'
import { mergeInfiniteQueryOptions, mergeQueryOptions } from '@/utils/merge-query-options'
import { AxiosError } from 'axios'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { useHistory } from 'react-router-dom'
import { customErrorToTrack, useAnalytics } from '../analytics'
import { Facility, FacilityUpdate } from './interfaces'
import {
  createFacility,
  createWithCSV,
  getById,
  getCsvTemplate,
  getPaginated,
  remove,
  updateFacility,
} from './service'
import * as NotifyService from '@/services/notify.service'
import { BulkError, CustomError, ErrorCodes } from '@blockchain-traceability-sl/custom-error-codes'
import { downloader } from '@/helpers/downloader'
import { downloadFacilityBulkErrorReport } from './helpers'

export const QUERY_FACILITIES_KEY = 'facilities'

export const usePaginatedFacilities = (
  filter: { search?: string },
  pagination: PaginationParams,
  overrideOptions?: Omit<UseQueryOptions<Paginated<Facility>, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const options = mergeQueryOptions(overrideOptions, {
    keepPreviousData: true,
  })
  const { data, ...rest } = useQuery(
    [QUERY_FACILITIES_KEY, filter, pagination],
    () => getPaginated(filter, pagination),
    options
  )

  const facilities = useMemo(() => data?.items || [], [data?.items])
  const totalCount = data?.totalCount || 0

  return {
    ...rest,
    facilities,
    totalCount,
  }
}

export const useFacilityById = (
  facilityId: string,
  options?: Omit<UseQueryOptions<Facility, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_FACILITIES_KEY, facilityId],
    () => getById(facilityId),
    options
  )

  return {
    ...rest,
    facility: data || undefined,
  }
}

export const useInfiniteFacilities = (
  filter?: { search?: string },
  overrideOptions?: Omit<
    UseInfiniteQueryOptions<Paginated<Facility>, AxiosError>,
    'queryKey' | 'queryFn'
  >
) => {
  const options = mergeInfiniteQueryOptions(
    {
      getNextPageParam: ({ pageInfo }) => pageInfo?.nextPage || undefined,
    },
    overrideOptions
  )

  const { data, ...rest } = useInfiniteQuery(
    [QUERY_FACILITIES_KEY, filter],
    ({ pageParam }) => getPaginated(filter, pageParam || { limit: DEFAULT_PAGE_SIZE, offset: 0 }),
    options
  )
  const facilities = useMemo(
    () => data?.pages.flatMap(page => page.items).flat() || [],
    [data?.pages]
  )

  return {
    ...rest,
    facilities,
  }
}

export const useDeleteFacility = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const history = useHistory()
  const queryClient = useQueryClient()

  const { mutate, ...rest } = useMutation(remove, {
    onSuccess(data, variables, context) {
      analytics.track('CUSTOMER_DELETE_PRODUCITON_FACILITY', {
        'Production Facility': variables.name,
      })
      NotifyService.success({
        title: t('facility.delete.title'),
        description: t('facility.delete.description'),
        actionText: t('facility.delete.action'),
        onActionClick: () => history.push(`/facilities/create`),
      })

      queryClient.invalidateQueries(QUERY_FACILITIES_KEY)
    },
    onError(error: AxiosError, variables) {
      analytics.track(
        'CUSTOMER_DELETE_PRODUCTION_FACILITY_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
    },
  })

  return {
    ...rest,
    deleteFacility: mutate,
  }
}

export const useCreateFacility = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const queryClient = useQueryClient()
  const history = useHistory()
  const { mutate, ...rest } = useMutation(createFacility, {
    onSuccess: (data, variables) => {
      analytics.track('CUSTOMER_CREATE_PRODUCTION_FACILITY', {
        'Production Facility': variables.name,
      })
      NotifyService.success({
        title: t('facility.create.success.title'),
        description: t('facility.create.success.description'),
        actionText: t('facility.create.success.action'),
        onActionClick: () => {
          history.push('/facilities/create')
        },
      })
      queryClient.invalidateQueries(QUERY_FACILITIES_KEY)
    },
    onError: (error: AxiosError) => {
      analytics.track(
        'CUSTOMER_CREATE_PRODUCTION_FACILITY_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return { ...rest, createFacility: mutate }
}

export const useUpdateFacility = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const queryClient = useQueryClient()
  const { mutate, ...rest } = useMutation(
    ({ _id, ...update }: FacilityUpdate & { _id: string }) => updateFacility(_id, update),
    {
      onSuccess: (data, variables) => {
        analytics.track('CUSTOMER_EDIT_PRODUCTION_FACILITY', {
          'Production Facility': variables.name,
        })
        NotifyService.success({
          title: t('facility.update.success.title'),
          description: t('facility.update.success.description'),
        })
        queryClient.invalidateQueries(QUERY_FACILITIES_KEY)
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_EDIT_PRODUCTION_FACILITY_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return { ...rest, updateFacility: mutate }
}

export const useCreateFacilitiesWithCSV = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const history = useHistory()

  /**
   * Set as valid processed if is Bulk error and all errors are already exist
   */
  const isAcceptableError = (error: AxiosError) => {
    return (
      error.response?.data.code === ErrorCodes.BULK_ERROR &&
      (error.response?.data as BulkError).errors.every(
        ({ error: customError }) => customError.code === ErrorCodes.ALREADY_EXISTS
      )
    )
  }

  const { mutate, ...rest } = useMutation(createWithCSV, {
    onSuccess(data) {
      analytics.track('CUSTOMER_CREATE_PRODUCTION_FACILITIES_CSV')
      NotifyService.success({
        title: t('facility.createBulk.success.title'),
        description: t('facility.createBulk.success.description', {
          count: data.total,
        }),
        actionText: t('facility.createBulk.success.action'),
        onActionClick: () => history.push('/facilities/create'),
      })
      queryClient.invalidateQueries(QUERY_FACILITIES_KEY)
    },
    onError(error: AxiosError) {
      analytics.track(
        'CUSTOMER_CREATE_PRODUCTION_FACILITIES_CSV_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )

      switch (error.response?.data.code) {
        case ErrorCodes.BULK_ERROR:
          {
            const bulkError: BulkError = error.response?.data

            // Set as valid processed if all errors are already exist
            if (isAcceptableError(error)) {
              NotifyService.success({
                title: t('facility.createBulk.success.title'),
                description: t('facility.createBulk.success.description', {
                  count: bulkError.total,
                }),
                actionText: t('facility.createBulk.success.action'),
                onActionClick: () => history.push('/facilities/create'),
              })
            } else {
              NotifyService.error({
                title: t('facility.createBulk.bulkError.title'),
                description: t('facility.createBulk.bulkError.description', {
                  processed: bulkError.processed,
                  total: bulkError.total,
                }),
                actionText: t('facility.createBulk.bulkError.action'),
                onActionClick: () => downloadFacilityBulkErrorReport(bulkError),
              })
            }
          }
          break

        case 10: // InvalidCSVError
          {
            const invalidCSVError: CustomError = error.response?.data

            if (invalidCSVError.entity !== 'file') {
              NotifyService.error({
                title: t('facility.createBulk.entityError.title'),
                description: t('facility.createBulk.entityError.description', {
                  context: invalidCSVError.entity,
                }),
                actionText: t('facility.createBulk.entityError.action'),
              })
            } else {
              NotifyService.error({
                title: t('facility.createBulk.error.title'),
                description: t('facility.createBulk.error.description'),
                actionText: t('facility.createBulk.error.action'),
              })
            }
          }
          break
        default:
          NotifyService.error({
            title: t('facility.createBulk.error.title'),
            description: t('facility.createBulk.error.description'),
            actionText: t('facility.createBulk.error.action'),
          })
          break
      }
    },
  })

  return { ...rest, createFacilitiesWithCSV: mutate, isAcceptableError }
}

export const useDownloadFacilitiesTemplateCSV = () => {
  const { data } = useQuery([QUERY_FACILITIES_KEY, { template: 'csv' }], getCsvTemplate)

  const downloadTemplate = (fileName: string) => {
    if (data) downloader(data, 'text/csv', fileName)
  }

  return { downloadTemplate }
}
