import { ErrorPage } from '@/pages/error'
import { PropsWithChildren } from 'react'
import { ErrorReporterService } from '@/services/error-reporter.service'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'

type ErrorBoundaryProps = PropsWithChildren<{
  /**
   * Disable error page on error boundary fallback
   * @default false
   */
  disableErrorPage?: boolean
}>

export const ErrorBoundary = ({ children, disableErrorPage = false }: ErrorBoundaryProps) => (
  <SentryErrorBoundary
    // Define fallback component
    fallback={disableErrorPage ? undefined : <ErrorPage />}
    // Propagate error to google error reporter service
    onError={error => ErrorReporterService.report(error)}
  >
    {children}
  </SentryErrorBoundary>
)
