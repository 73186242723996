import { Permissions } from '@/features/roles'

/**
 * Get redirect path by permissions and user type
 * @param userType
 * @param userPermissions
 * @returns {string}
 *
 * @note We only take into account the permissions inside digitalization section. In the next iterations we will support other sections.
 */
export function getRedirectBasedOnPermissions(
  userType: string,
  userPermissions?: Permissions
): string {
  if (userType === 'company') {
    return '/home'
  }

  if (userPermissions?.stakeHolders?.read || userPermissions?.stakeHolders?.write) {
    return '/stakeholders'
  }

  if (userPermissions?.forms.read || userPermissions?.forms.write) {
    return '/forms'
  }

  if (userPermissions?.tasks.read || userPermissions?.tasks.write) {
    return '/tasks'
  }

  if (userPermissions?.documents.read || userPermissions?.documents.write) {
    return '/documents'
  }

  if (userPermissions?.departments.read || userPermissions?.departments.write) {
    return '/departments'
  }

  if (userPermissions?.dashboards.read || userPermissions?.dashboards.write) {
    return '/dashboards'
  }
  return '/profile'
}
