import { Button, DropAreaField, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { useTranslation } from 'react-i18next'
import backgroundTransparent from '@/assets/images/backgroundTransparent.png'
import { EntryFieldProps } from '../interfaces'
import { MAX_FILE_SIZE } from '@/app/constants'
import { useEntryFieldAttributes } from '../utils'
import { useField } from 'formik'
import { FileEntryCreateInput } from '@/features/entries'
import { EntryLabel } from '../EntryLabel'

export const FileEntryField = ({ index, prefixName, fileOptions, disabled }: EntryFieldProps) => {
  const { t } = useTranslation('nsEntries')

  const { entryFieldName, valueFieldName, enabledFieldName, valueFieldId, enabledFieldId } =
    useEntryFieldAttributes(index, prefixName)

  const [{ value: entry }] = useField<FileEntryCreateInput>(entryFieldName)
  const [, , enabledInputFieldHelpers] = useField<boolean>(enabledFieldName)
  const [, { error, touched }, valueInputFieldHelpers] = useField<string>(valueFieldName)

  const isEntryEnabled = entry.enabled ?? true

  if (!fileOptions) return null
  const hasError: boolean = !!error && !!touched

  const { filePreviewUrls, handleFileSelection, changeFile, downloadFile } = fileOptions

  const currentLabel = (
    <EntryLabel
      naCheckboxId={enabledFieldId}
      naCheckboxName={enabledFieldName}
      naUnchecked={isEntryEnabled}
      onNaChange={enabled => {
        enabledInputFieldHelpers.setValue(enabled)
        enabledInputFieldHelpers.setTouched(true)
      }}
      naCheckboxLabel={t('form.na')}
      mandatoryPrefixText={t('form.mandatory.indicator')}
      isMandatory={entry.mandatory}
      disabled={disabled}
      lightTextOnNAChecked
    >
      {entry.name}
    </EntryLabel>
  )

  if (!isEntryEnabled) {
    return currentLabel
  }

  if (disabled) {
    return (
      <>
        <span>{entry.name}</span>
        <p className='mt-3'>
          <Button
            color='secondary'
            size={SIZE.EXTRA_SMALL}
            className='mr-3'
            onClick={() => downloadFile(entry)}
            disabled={!entry.file && !entry.value}
          >
            {t('form.file.download')}
          </Button>
        </p>
      </>
    )
  }

  return (
    <div>
      {(!entry.file && !entry.value) || hasError ? (
        <>
          <label className='font-medium text-gray-700' htmlFor={valueFieldId}>
            {currentLabel}
          </label>

          <DropAreaField
            id={valueFieldId}
            name={valueFieldName}
            uploadText={t('form.file.uploadText')}
            dragAndDropText={t('form.file.dragAndDropText')}
            acceptText={t('form.file.acceptText')}
            onDropAccepted={([file]) => {
              handleFileSelection(file, entryFieldName, entry)
            }}
            onDropRejected={() => {
              valueInputFieldHelpers.setError(t('form.file.invalidFormat'))
              valueInputFieldHelpers.setTouched(true)
            }}
            value={hasError ? null : entry.file}
            error={hasError && t('form.file.invalidFormat')}
            maxSize={MAX_FILE_SIZE.BYTES}
          />
        </>
      ) : (
        <div>
          <label className='mb-1 block text-sm font-medium text-gray-700'>{currentLabel}</label>
          {filePreviewUrls[entry.id] && entry.mimeType?.includes('image') ? (
            <div
              className='position-relative mr-3'
              style={{
                height: 150,
                width: 150,
                backgroundImage: `url(${backgroundTransparent})`,
              }}
            >
              <div
                style={{
                  width: '100%',
                  paddingBottom: '100%',
                  backgroundImage: `url(${filePreviewUrls[entry.id] || ''})`,
                  backgroundSize: '100%',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                className='image-none-preview'
              />
            </div>
          ) : (
            ''
          )}
          <p className='mt-3'>
            <Button
              color='secondary'
              size={SIZE.EXTRA_SMALL}
              className='mr-3'
              onClick={() => downloadFile(entry)}
            >
              {t('form.file.download')}
            </Button>

            <Button
              color='white'
              size={SIZE.EXTRA_SMALL}
              onClick={() => changeFile(entryFieldName, entry)}
            >
              {t('form.file.change')}
            </Button>
          </p>
        </div>
      )}
    </div>
  )
}
