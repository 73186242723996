import { ValidationRequest, ValidationRequestStatus } from '.'
import { ReadableUser, useUsersWithOwner } from '@/features/users'
import { ValidationRequestReviewer } from './entity'
import { CompanyUser, useCompanyUser, useIsMe } from '@/features/auth'

import { useCallback } from 'react'

const sortByDate = (a: ValidationRequestReviewer, b: ValidationRequestReviewer) => {
  return (
    new Date(b.validationDate as string).getTime() - new Date(a.validationDate as string).getTime()
  )
}

export const getValidatorName = (
  lastValidator: ValidationRequestReviewer,
  users: ReadableUser[],
  company: Pick<CompanyUser, '_id' | 'name'>
): string => {
  if (lastValidator.id === company._id) return company.name

  const validator = users.find(({ _id }) => _id === lastValidator.id)
  const validatorUserName = validator?.name || validator?.auth?.email

  return validatorUserName || 'n/a'
}

export const useGetLastValidator = () => {
  const company = useCompanyUser()
  const { users } = useUsersWithOwner()
  const { isMe } = useIsMe()

  const getLastValidator = useCallback(
    ({
      status,
      reviewers,
    }: Pick<ValidationRequest, 'status' | 'reviewers'>):
      | {
          name: string
          message?: string
          validationDate?: string
          isValidatedByMe?: boolean
          signed?: boolean
          id?: string
        }
      | undefined => {
      if (status === ValidationRequestStatus.APPROVED) {
        const lastValidator = reviewers
          .filter(({ validationDate }) => validationDate)
          .sort(sortByDate)[0]

        return {
          id: lastValidator.id,
          isValidatedByMe: isMe(lastValidator.id),
          signed: lastValidator.signed,
          name: getValidatorName(lastValidator, users, company),
          validationDate: lastValidator.validationDate,
        }
      }

      if (status === ValidationRequestStatus.REJECTED) {
        const lastValidator = reviewers
          .filter(
            ({ validationDate, status }) =>
              validationDate && status === ValidationRequestStatus.REJECTED
          )
          .sort(sortByDate)[0]

        return {
          id: lastValidator.id,
          isValidatedByMe: isMe(lastValidator.id),
          name: getValidatorName(lastValidator, users, company),
          message: lastValidator.message,
          validationDate: lastValidator.validationDate,
          signed: lastValidator.signed,
        }
      }
      return {
        isValidatedByMe: !!reviewers.find(reviewer => isMe(reviewer.id)),
        name: reviewers.map(reviewer => getValidatorName(reviewer, users, company)).join(', '),
      }
    },
    [company, isMe, users]
  )

  const getLastValidatorName = useCallback(
    (validationRequest: Pick<ValidationRequest, 'status' | 'reviewers'>): string | undefined => {
      const lastValidator = getLastValidator(validationRequest)
      return lastValidator?.name
    },
    [getLastValidator]
  )

  return {
    getLastValidator,
    getLastValidatorName,
  }
}
