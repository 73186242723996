import { useTranslation } from 'react-i18next'
import { SortableContainer } from '@/components/SortableContainer'
import { nanoid } from 'nanoid'
import { ButtonWithIcon, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { PlusIcon } from '@heroicons/react/solid'
import { useField } from 'formik'
import { ProductMetadataItem, ProductMetadataItemType } from '../../interfaces'
import { SortableMetadataEntry } from './SortableMetadataEntry'

export const ProductMetadata = () => {
  const { t } = useTranslation('nsProductCreationManualPage')
  const [metadataField, , metadataHelper] = useField<
    (ProductMetadataItem & { id: string })[] | undefined
  >('metadata')

  const handleEntryAdd = () => {
    metadataHelper.setValue([
      ...(metadataField.value || []),
      { id: nanoid(), key: '', type: ProductMetadataItemType.TEXT, value: '' },
    ])
  }

  const enableAddMore: boolean =
    !metadataField.value ||
    metadataField.value.every(entry => !!entry.key.trim() && !!entry.value.trim())

  return (
    <>
      <SortableContainer
        items={metadataField.value || []}
        onSort={items => {
          metadataHelper.setValue(items as (ProductMetadataItem & { id: string })[])
        }}
      >
        {metadataField.value?.map(({ id }, index) => (
          <SortableMetadataEntry key={id} id={id} index={index} />
        ))}
      </SortableContainer>

      <div className='flex justify-end'>
        <ButtonWithIcon
          color='secondary'
          size={SIZE.EXTRA_SMALL}
          IconComponent={PlusIcon}
          onClick={handleEntryAdd}
          disabled={!enableAddMore}
        >
          {t('form.metadata.addMore')}
        </ButtonWithIcon>
      </div>
    </>
  )
}
