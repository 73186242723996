import { useEffect, useState } from 'react'

export const useDebounceState = <D>(initialState: D, delay: number = 500): [D, boolean, D] => {
  const [debounceValue, setDebounceValue] = useState(initialState)
  const [isDebounced, setIsDebounced] = useState(false)

  useEffect(() => {
    setIsDebounced(true)

    const timer = setTimeout(() => {
      setDebounceValue(initialState)
      setIsDebounced(false)
    }, delay)

    return () => clearTimeout(timer)
  }, [initialState, delay])

  return [debounceValue, isDebounced, initialState]
}
