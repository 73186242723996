import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  SelectMultiField,
  SelectMultiFieldProps,
  SelectorOption,
  createSelectorOption,
  noop,
} from '@blockchain-traceability-sl/tailwind-components'
import { useEffect, useState } from 'react'
import { useFetchTRUs, usePaginatedTRUs, useTRUsByIds } from '../hooks'
import { ITRUFilters } from '../interfaces'

type TrusSelectMultiFieldProps = Omit<SelectMultiFieldProps, 'options'> &
  Omit<ITRUFilters, 'search'> & {
    truIdsValue?: string[]
  }

export const TrusSelectMultiField = ({
  productId,
  originId,
  truIdsValue,
  ...props
}: TrusSelectMultiFieldProps) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const [options, setOptions] = useState<SelectorOption[]>([])

  const {
    isLoading: isPaginatedTRUsLoading,
    trus,
    fetchNextPage,
  } = usePaginatedTRUs({
    filters: { search: debouncedSearch, productId, originId },
  })

  const { trus: truValues, isLoading: truValuesLoading } = useTRUsByIds(truIdsValue || [], {
    enabled: !!truIdsValue?.length,
  })

  const { fetchTRUs } = useFetchTRUs({
    search: debouncedSearch,
    productId,
    originId,
  })

  const [isLoading, setIsLoading] = useState(isPaginatedTRUsLoading)

  useEffect(() => {
    setOptions(trus.map(tru => createSelectorOption(tru.reference, tru._id)))
  }, [debouncedSearch, trus])

  useEffect(() => {
    setIsLoading(isPaginatedTRUsLoading || (!!truIdsValue?.length && truValuesLoading))
  }, [isPaginatedTRUsLoading, truIdsValue?.length, truValuesLoading])

  const handleSelectAll = async () => {
    setIsLoading(true)
    fetchTRUs()
      .then(trus => {
        const newOptions = trus.map(tru => createSelectorOption(tru.reference, tru._id))
        setOptions(newOptions)
        if (props.onChange) {
          props.onChange(newOptions)
        }
      })
      .catch(noop)
      .finally(() => setIsLoading(false))
  }

  const values = truValues.length
    ? truValues.map(({ _id, reference }) => createSelectorOption(reference, _id))
    : props.value

  return (
    <SelectMultiField
      {...props}
      value={values}
      isLoading={isLoading}
      options={options}
      onInputChange={setSearch}
      onMenuScrollToBottom={() => fetchNextPage()}
      onSelectAll={handleSelectAll}
    />
  )
}
