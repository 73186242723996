import { Company } from '@/features/company'
import { Origin, OriginType } from '@/features/origins'
import { Product } from '@/features/products'
import { StakeHolderProduct } from '@/features/stakeHolderProducts'
import { StakeHolder } from '@/features/stakeHolders'

export const getAvailableOriginRelationsData = (
  origin: Origin,
  {
    stakeHolders,
    stakeHolderProducts,
    products,
    company,
  }: {
    stakeHolders: StakeHolder[]
    stakeHolderProducts: StakeHolderProduct[]
    products: Product[]
    company: Company
  }
): {
  stakeHolder?: { id: string; name: string; reference: string }
  product?: { id: string; name: string; reference: string; measurementUnit?: string | null }
} => {
  if (origin.originType === OriginType.RECEPTION) {
    const stakeHolder = stakeHolders.find(stakeHolder => stakeHolder._id === origin.stakeHolder.id)

    const stakeHolderProduct = stakeHolderProducts.find(
      stakeHolderProduct => stakeHolderProduct._id === origin.stakeHolderProductId
    )
    return {
      stakeHolder: stakeHolder && {
        id: stakeHolder._id,
        name: stakeHolder.company.name,
        reference: `${stakeHolder.company.countryCode}${stakeHolder.company.reference}`,
      },

      product: stakeHolderProduct && {
        id: stakeHolderProduct._id,
        name: stakeHolderProduct.stakeHolder.product.name,
        reference: stakeHolderProduct.stakeHolder.product.reference,
        measurementUnit: stakeHolderProduct.measurementUnit,
      },
    }
  }

  const product = products.find(product => product._id === origin.productId)

  return {
    product: product && {
      id: product._id,
      name: product.name,
      reference: product.reference,
      measurementUnit: product.measurementUnit,
    },
    stakeHolder: {
      id: company._id,
      name: company.name,
      reference: `${company.countryCode}${company.reference}`,
    },
  }
}
