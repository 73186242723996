import { useIsClamped } from '@/hooks/use-is-clamped'
import { Tooltip } from '@blockchain-traceability-sl/tailwind-components'

export const TemplateCard = ({ templateName }: { templateName: string }) => {
  const [isClamped, setContentRef] = useIsClamped()

  return (
    <div>
      <div className='w-64 h-12 bg-white rounded-lg border border-gray-50 shadow mt-1 mr-4 flex items-center px-6'>
        <Tooltip className='break-words' tip={templateName} cursor disabled={!isClamped}>
          <span ref={setContentRef} className='text-gray-800 text-xs font-semibold line-clamp-2'>
            {templateName}
          </span>
        </Tooltip>
      </div>
    </div>
  )
}
