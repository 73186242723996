import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query'
import { customErrorToTrack, useAnalytics } from '../analytics'
import { Issue, IssueStatus, IssuesSummaryReportFilters } from './interfaces'
import {
  changeStatus,
  comment,
  getAllByIds,
  getById,
  getByTaskId,
  getCount,
  getReportIssues,
} from './service'
import * as NotifyService from '@/services/notify.service'
import { useTranslation } from 'react-i18next'
import { useIsTaskAdmin } from '../tasks'
import { useMyDepartments } from '../departments'
import { TaskTemplateTypes, useTemplates } from '../templates'
import { mergeQueryOptions } from '@/utils/merge-query-options'
import { QUERY_AUDITS_KEY } from '../audits'

export const QUERY_ISSUES_KEY = 'issues'
export const QUERY_ISSUES_COUNT_KEY = 'issues-count'

export const useIssuesByIds = (
  issuesIds: string[],
  options?: Omit<UseQueryOptions<Issue[], AxiosError, Issue[]>, 'queryKey' | 'queryFn'>
) => {
  const queryClient = useQueryClient()
  const newOptions: Omit<UseQueryOptions<Issue[], AxiosError>, 'queryKey' | 'queryFn'> = {
    ...options,
    onSuccess(data: Issue[]) {
      options?.onSuccess?.(data)
      data.forEach(item => {
        queryClient.setQueryData([QUERY_ISSUES_KEY, item._id], item)
      })
    },
  }
  const { data, ...rest } = useQuery(
    [QUERY_ISSUES_KEY, { ids: issuesIds }],
    () => getAllByIds(issuesIds),
    newOptions
  )
  return { ...rest, issues: useMemo(() => data || [], [data]) }
}

export const useIssueById = (
  issueId: string,
  options?: Omit<UseQueryOptions<Issue, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery([QUERY_ISSUES_KEY, issueId], () => getById(issueId), options)
  return { ...rest, issue: data }
}

export const useFetchIssuesByTaskId = () => {
  const queryClient = useQueryClient()

  const fetchIssuesByTaskId = useCallback(
    (taskId: string) => {
      return queryClient.fetchQuery([QUERY_ISSUES_KEY, { taskId }], () => getByTaskId(taskId))
    },
    [queryClient]
  )

  return {
    fetchIssuesByTaskId,
  }
}

export const useIssuesByTaskId = (
  taskId: string,
  options?: Omit<UseQueryOptions<Issue[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_ISSUES_KEY, { taskId }],
    () => getByTaskId(taskId),
    options
  )
  return { ...rest, issues: useMemo(() => data || [], [data]) }
}

export const useChangeIssueStatus = () => {
  const analytics = useAnalytics()
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')

  const { mutate, ...rest } = useMutation(
    ({ issueId, status }: { issueId: string; status: IssueStatus; taskName: string }) =>
      changeStatus(status, issueId),
    {
      onSuccess: (data, variables) => {
        const { status } = variables

        const statusText = status === IssueStatus.RESOLVED ? 'solved' : status
        analytics.track('CUSTOMER_CHANGE_INCIDENT_STATUS', {
          'Task Name': variables.taskName,
          Status: statusText,
        })

        if (variables.status === IssueStatus.CLOSED) {
          NotifyService.success({
            title: t('issue.resolve.success.title'),
            description: t('issue.resolve.success.description'),
          })
        }

        queryClient.invalidateQueries(QUERY_ISSUES_KEY)
        queryClient.invalidateQueries(QUERY_ISSUES_COUNT_KEY)
        queryClient.invalidateQueries([QUERY_AUDITS_KEY, { entityId: variables.issueId }])
      },
      onError: ({ response }: AxiosError) => {
        analytics.track(
          'CUSTOMER_CHANGE_INCIDENT_STATUS_ERROR',
          customErrorToTrack(response?.data, response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(response?.data))
      },
    }
  )

  return { ...rest, changeIssueStatus: mutate }
}

export const useCommentIssue = () => {
  const analytics = useAnalytics()
  const queryClient = useQueryClient()

  const { mutate, ...rest } = useMutation(
    ({ issueId, message }: { issueId: string; message: string; taskName: string }) =>
      comment(issueId, message),
    {
      onSuccess: (data, variables) => {
        analytics.track('CUSTOMER_ANSWER_INCIDENT', {
          Task: variables.taskName,
        })

        queryClient.invalidateQueries(QUERY_ISSUES_KEY)
      },
      onError: ({ response }: AxiosError) => {
        analytics.track(
          'CUSTOMER_ANSWER_INCIDENT_ERROR',
          customErrorToTrack(response?.data, response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(response?.data))
      },
    }
  )

  return { ...rest, commentIssue: mutate }
}

export const useIssuesCount = (
  filters?: { status?: IssueStatus[]; responder?: string[]; formId?: string[] },
  overrideOptions?: Omit<UseQueryOptions<{ count: number }, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { isTaskAdmin } = useIsTaskAdmin()

  const { departments, isFetched: isDepartmentFetched } = useMyDepartments({
    enabled: !isTaskAdmin && overrideOptions?.enabled,
  })

  const { templates, isFetched: isTemplatesFetched } = useTemplates(
    { types: TaskTemplateTypes, departmentIds: departments.map(({ _id }) => _id) },
    {
      enabled: isDepartmentFetched,
    }
  )

  const templateIds = templates.map(({ _id }) => _id)

  const normalizedFormIds =
    filters?.formId && filters.formId.length > 0
      ? templateIds.filter(templateId => filters.formId?.includes(templateId))
      : templateIds

  const normalizedFilters = isTaskAdmin
    ? filters
    : {
        ...filters,
        formId: normalizedFormIds,
      }

  const options = mergeQueryOptions(overrideOptions, {
    enabled: isTaskAdmin
      ? overrideOptions?.enabled
      : isTemplatesFetched && normalizedFormIds.length > 0,
  })

  const { data, ...rest } = useQuery(
    [QUERY_ISSUES_COUNT_KEY, normalizedFilters],
    () => getCount(normalizedFilters),
    options
  )

  const count = data?.count || 0

  return {
    ...rest,
    count,
  }
}

export const useFetchReportIssues = () => {
  const queryClient = useQueryClient()
  const fetchReportIssues = useCallback(
    (filters: IssuesSummaryReportFilters) => {
      return queryClient.fetchQuery([QUERY_ISSUES_KEY, 'report', { filters }], () =>
        getReportIssues(filters)
      )
    },
    [queryClient]
  )

  return { fetchReportIssues }
}
