import * as Yup from 'yup'
import { ExtendedAutomationTriggerType } from './components/CreateAutomationStep'
import { AutomationTriggerTypes, ScheduleTypes, TimeIncrement, TimeIncrements } from './interfaces'

const rescheduleTimeIncrements = TimeIncrements.filter(
  increment => increment === TimeIncrement.TODAY
)

const defaultTimeIncrements = TimeIncrements.filter(increment => increment !== TimeIncrement.TODAY)

export const editValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  trigger: Yup.object().shape({
    type: Yup.string()
      .matches(new RegExp(AutomationTriggerTypes.join('|')))
      .required(),
    schedule: Yup.object().when('type', {
      is: ExtendedAutomationTriggerType.SCHEDULE || ExtendedAutomationTriggerType.RESCHEDULE,
      then: schema =>
        schema
          .shape({
            type: Yup.string()
              .matches(new RegExp(ScheduleTypes.join('|')))
              .required(),
          })
          .required(),
    }),
    entityEvent: Yup.object().when('type', {
      is: ExtendedAutomationTriggerType.ENTITY_EVENT,
      then: schema =>
        schema.shape({
          params: Yup.object()
            .shape({
              productId: Yup.string().required(),
            })
            .required(),
        }),
    }),
  }),
  target: Yup.object().when('trigger.type', {
    is: ExtendedAutomationTriggerType.RESCHEDULE,
    then: schema =>
      schema.shape({
        params: Yup.object().shape({
          isExpired: Yup.boolean().required(),
          dueDateIncrementType: Yup.string()
            .matches(new RegExp(rescheduleTimeIncrements.join('|')))
            .required(),
        }),
      }),
    otherwise: schema =>
      schema.shape({
        params: Yup.object().shape({
          assignedTo: Yup.string().required(),
          dueDateIncrementType: Yup.string()
            .matches(new RegExp(defaultTimeIncrements.join('|')))
            .required(),
        }),
      }),
  }),
})
