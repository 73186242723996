import { TRUCreationOrUpdateOriginInput } from '../../interfaces'
import { ButtonWithIcon, Card, Step } from '@blockchain-traceability-sl/tailwind-components'
import { useTranslation } from 'react-i18next'
import { ArrowNarrowRightIcon } from '@heroicons/react/solid'
import { TruOriginsResume } from './TruOriginsResume'
import { AvailableTruOriginsTable } from './AvailableTruOriginsTable'

/**
 * This component is used to setup the origins of a TRU
 * @todo: Implement this component replacing the following props for the context hooks
 */
export interface SetupTruOriginsStepProps {
  // Mandatory to know the possible product origins
  productId: string
  // To know available origins, this property only works in updates. To know available origins in create use productId
  truId?: string
  // Current origins selected from form context
  origins: TRUCreationOrUpdateOriginInput[]
  // Origin manage handlers
  onAddOrigin: (origin: TRUCreationOrUpdateOriginInput) => void
  onRemoveOrigin: (entityId: string) => void
  // Finish step
  onFinish: () => void
  finishText: string
}

export const SetupTruOriginsStep = ({
  productId,
  truId,
  origins,
  onAddOrigin,
  onRemoveOrigin,
  onFinish,
  finishText,
}: SetupTruOriginsStepProps) => {
  const { t } = useTranslation('nsTRUCreationManualPage')

  return (
    <Step title={t('steps.step2.stepTitle')} subtitle={t('steps.step2.stepSubtitle')} overview=''>
      <Card className='overflow-visible' divideY={false}>
        <Card.Body className='space-y-5'>
          <TruOriginsResume origins={origins} onRemoveOrigin={onRemoveOrigin} />

          <AvailableTruOriginsTable
            productId={productId}
            truId={truId}
            origins={origins}
            onAddOrigin={onAddOrigin}
          />
        </Card.Body>

        <Card.Footer className='flex justify-end'>
          <ButtonWithIcon
            data-testid='submit'
            onClick={onFinish}
            IconComponent={ArrowNarrowRightIcon}
          >
            {finishText}
          </ButtonWithIcon>
        </Card.Footer>
      </Card>
    </Step>
  )
}
