import { useState } from 'react'
import { useInterval } from './use-interval'

interface UseTimerProps {
  startsAt?: Date
}

interface UseTimerState {
  seconds: number
  minutes: number
  hours: number
}

export const getTimeFromSeconds = (secs: number) => {
  const totalSeconds = Math.ceil(secs)

  return {
    seconds: Math.floor(totalSeconds % 60),
    minutes: Math.floor((totalSeconds / 60) % 60),
    hours: Math.floor(totalSeconds / (60 * 60)),
  }
}

export const getSecondsFromDate = (offset: Date, current = new Date()) => {
  const currentTime = current.getTime()
  const offsetTime = offset.getTime()
  const timeDifference = currentTime - offsetTime
  return timeDifference / 1000
}

export const useTimer = ({ startsAt = new Date() }: UseTimerProps): UseTimerState => {
  const [seconds, setSeconds] = useState(getSecondsFromDate(startsAt))

  useInterval(() => {
    setSeconds(getSecondsFromDate(startsAt))
  }, 1000)

  return getTimeFromSeconds(seconds)
}

export const Timer = ({
  startsAt,
  children,
}: UseTimerProps & { children: (time: UseTimerState) => JSX.Element }) => {
  const time = useTimer({ startsAt })

  return children(time)
}
