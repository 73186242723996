import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import {
  createUser,
  deleteUser,
  getPaginatedUsers,
  getUserById,
  getUserCount,
  getUsers,
  updateUser,
} from './service'
import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { User, UserFilters, UserUpdate } from './interfaces'
import { useTranslation } from 'react-i18next'
import { customErrorToTrack, useAnalytics } from '@/features/analytics'
import * as NotifyService from '@/services/notify.service'
import { QUERY_MY_PROFILE_KEY, useCompanyUser, useMyProfile } from '../auth'
import { Paginated } from '@/hooks/use-pagination'
import { mergeInfiniteQueryOptions } from '@/utils/merge-query-options'
import * as Yup from 'yup'
import { passwordIsSecure } from '@/utils/password-security'
import { COMPANY_OR_USER_NAME_REGEX } from '@/app/constants'

export const QUERY_USERS_KEY = 'users'
export const QUERY_USERS_COUNT_KEY = 'users-count'

/**
 * Fetch all Company Users
 */
export const useUsers = (
  options?: Omit<UseQueryOptions<User[], AxiosError, User[]>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(QUERY_USERS_KEY, () => getUsers(), options)

  return { ...rest, users: useMemo(() => data || [], [data]) }
}

/**
 *
 * @deprecated use useUsersWithOwner instead
 */
export const useUsersWithCompany = (
  options?: Omit<UseQueryOptions<User[], AxiosError, User[]>, 'queryKey' | 'queryFn'>
) => {
  const { users, ...rest } = useUsers(options)
  const companyUser = useCompanyUser()

  const results = useMemo(
    () => [
      ...users,
      {
        ...companyUser,
        auth: {
          email: companyUser.email,
        },
      },
    ],
    [users, companyUser]
  )

  return {
    ...rest,
    users: results,
  }
}

export const useUsersWithOwner = (
  options?: Omit<UseQueryOptions<User[], AxiosError, User[]>, 'queryKey' | 'queryFn'>
) => {
  const { users, ...rest } = useUsers(options)
  const { profile } = useMyProfile()

  const results = useMemo(
    () =>
      profile?.owner
        ? [
            ...users,
            {
              _id: profile.owner.id,
              name: profile.owner.name,
              auth: {
                email: profile.owner.email,
              },
            },
          ]
        : users,
    [profile?.owner, users]
  )

  return {
    ...rest,
    users: results,
  }
}

export const usePaginatedUsers = ({
  filters = {},
  pageSize = 10,
  ...overrideOptions
}: Omit<UseInfiniteQueryOptions<Paginated<User>, AxiosError>, 'queryKey' | 'queryFn'> & {
  filters?: UserFilters
  pageSize?: number
}) => {
  const queryClient = useQueryClient()

  const options = mergeInfiniteQueryOptions(overrideOptions, {
    keepPreviousData: true,
    // Cache each product as individual cached item by id
    onSuccess(data) {
      overrideOptions?.onSuccess?.(data)
      data.pages
        .flatMap(({ items }) => items)
        .forEach(item => {
          queryClient.setQueryData([QUERY_USERS_KEY, item._id], item)
        })
    },
    getNextPageParam: lastPage => lastPage.pageInfo.nextPage || undefined,
    getPreviousPageParam: lastPage => lastPage.pageInfo.previousPage || undefined,
  })

  const { data, ...rest } = useInfiniteQuery(
    [QUERY_USERS_KEY, { filters, pageSize }, { isInfiniteQuery: true }],
    async ({ pageParam }) => {
      return getPaginatedUsers(filters, {
        offset: pageParam?.offset || 0,
        limit: pageParam?.limit || pageSize,
      })
    },
    options
  )

  const users: User[] = useMemo(
    () => data?.pages.flatMap(({ items }) => items) || [],
    [data?.pages]
  )

  const totalCount = useMemo(
    () => data?.pages[data?.pages.length - 1]?.totalCount || 0,
    [data?.pages]
  )

  return {
    ...rest,
    data,
    users,
    totalCount,
  }
}

export const useFetchUsers = (filter?: UserFilters) => {
  const queryClient = useQueryClient()

  const fetchUsers = useCallback(
    () => queryClient.fetchQuery([QUERY_USERS_KEY, filter], () => getUsers(filter)),
    [filter, queryClient]
  )

  return {
    fetchUsers,
  }
}

export const useUserById = (
  userId: string,
  options?: Omit<UseQueryOptions<User | void, AxiosError, User>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery([QUERY_USERS_KEY, userId], () => getUserById(userId), options)

  return {
    ...rest,
    user: data,
  }
}

export const useUserCount = (
  filters?: {
    includeOwner?: boolean
  },
  options?: Omit<UseQueryOptions<number | void, AxiosError, number>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery([QUERY_USERS_COUNT_KEY], () => getUserCount(filters), options)

  return {
    ...rest,
    userCount: data || 0,
  }
}

export const useCreateUser = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()

  const { mutate, mutateAsync, ...rest } = useMutation(createUser, {
    onSuccess: async (data, variables) => {
      analytics.track('CUSTOMER_CREATE_USER', {
        User: variables.email,
      })
      NotifyService.success({
        title: t('profile.createUser.success.title'),
        description: t('profile.createUser.success.description'),
      })
      queryClient.invalidateQueries(QUERY_USERS_KEY)
      queryClient.invalidateQueries(QUERY_USERS_COUNT_KEY)
      queryClient.invalidateQueries(QUERY_MY_PROFILE_KEY)
    },
    onError: (error: AxiosError) => {
      analytics.track(
        'CUSTOMER_CREATE_USER_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return {
    ...rest,
    createUser: mutate,
    createUserAsync: mutateAsync,
  }
}

export const useUpdateUser = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()

  const { mutate, mutateAsync, ...rest } = useMutation(
    ({ id, ...data }: { id: string } & UserUpdate) => updateUser(id, data),
    {
      onSuccess: async (data, variables) => {
        analytics.track('CUSTOMER_EDIT_USER', {
          User: variables.email,
        })
        NotifyService.success({
          title: t('profile.editUser.success.title'),
          description: t('profile.editUser.success.description'),
        })
        queryClient.invalidateQueries(QUERY_USERS_KEY)
        queryClient.invalidateQueries(QUERY_MY_PROFILE_KEY)
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_EDIT_USER_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    updateUser: mutate,
    updateUserAsync: mutateAsync,
  }
}

export const useDeleteUser = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()

  const { mutate, mutateAsync, ...rest } = useMutation(({ _id }: User) => deleteUser(_id), {
    onSuccess: async (data, variables) => {
      analytics.track('CUSTOMER_DELETE_USER', {
        User: variables.auth.email,
      })
      NotifyService.success({
        title: t('profile.deleteUser.success.title'),
        description: t('profile.deleteUser.success.description'),
      })
      queryClient.invalidateQueries(QUERY_USERS_KEY)
      queryClient.invalidateQueries(QUERY_USERS_COUNT_KEY)
      queryClient.invalidateQueries(QUERY_MY_PROFILE_KEY)
    },
    onError: (error: AxiosError) => {
      analytics.track(
        'CUSTOMER_DELETE_USER_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return {
    ...rest,
    deleteUser: mutate,
    deleteUserAsync: mutateAsync,
  }
}

export const useGetUserName = (
  missingUserMessage?: string
): { getUserName: (userId: string) => string | undefined } => {
  const { profile } = useMyProfile()
  const { users } = useUsers()

  const getUserName = useCallback(
    (userId: string) => {
      const user = users.find(user => user._id === userId)
      if (profile && userId === profile.company.id) {
        return profile.company.name
      } else if (profile && userId === profile.user.id) {
        return profile.user.name || profile.user.email
      } else if (profile && userId === profile.owner.id) {
        return profile.owner.name || profile.owner.email
      } else if (user) {
        return user.publicName
      } else {
        return missingUserMessage
      }
    },
    [missingUserMessage, profile, users]
  )

  return {
    getUserName,
  }
}

export const useUserValidationSchema = () => {
  const { t } = useTranslation('nsProfilePage')

  const userValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('usersTab.newUserForm.validation.required'))
      .matches(COMPANY_OR_USER_NAME_REGEX, t('nsCommon:validations.nameCharacters')),
    email: Yup.string()
      .email(t('usersTab.newUserForm.validation.notValidEmail'))
      .required(t('usersTab.newUserForm.validation.required')),
    password: Yup.string()
      .required(t('usersTab.newUserForm.validation.required'))
      .test(
        'security',
        t('usersTab.newUserForm.validation.newFeedbackRequirements'),
        value => !!value && passwordIsSecure(value)
      ),

    confirmPassword: Yup.string()
      .required(t('usersTab.newUserForm.validation.required'))
      .equals([Yup.ref('password')], t('usersTab.newUserForm.validation.passwordsNotMatch')),
    roleId: Yup.string().nullable(),
  })

  return { userValidationSchema }
}
