import { createSelectorOption } from '@blockchain-traceability-sl/tailwind-components'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { TRUFiltersState } from '../../interfaces'
import { ALL_FILTER_OPTION_VALUE } from './hooks'
import { useAnalytics } from '@/features/analytics'
import { ReceptionsInlineSelectField } from '@/features/receptions'

export const TRUFilters = ({
  className,
  filters,
  onFilterChange,
}: {
  className?: string
  filters: TRUFiltersState
  onFilterChange: (filtersPatch: Partial<TRUFiltersState>) => void
}) => {
  const { t } = useTranslation('nsTRUsListPage', { keyPrefix: 'filters' })
  const analytics = useAnalytics()

  const allOption = {
    label: t('rawMaterial.dropdown.all'),
    value: ALL_FILTER_OPTION_VALUE,
  }

  return (
    <div className={classNames('flex flex-col', className)}>
      <div className='grid sm:grid-cols-4 grid-rows-2 sm:grid-rows-none gap-2 mb-4 sm:mb-8'>
        <div className='z-10'>
          <ReceptionsInlineSelectField
            multiple
            id='originId'
            name='originId'
            label={`${t('rawMaterial.placeholder')} `}
            searchPlaceholderText={t('rawMaterial.dropdown.search')}
            all={allOption}
            onChange={options => {
              const value = options?.map(option => ({
                id: option.value,
                name: option.label,
              }))
              onFilterChange({ originId: value })
              analytics.track('ACTION_FILTER_RESULTS', {
                From: 'My batches section',
                By: 'Reception ID',
              })
            }}
            value={
              filters.originId.length > 0
                ? filters.originId.map(item => createSelectorOption(item.name, item.id))
                : [allOption]
            }
          />
        </div>
      </div>
    </div>
  )
}
