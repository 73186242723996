import { DateLocaleField } from '@/components/DateLocaleField'
import {
  TemplateEntryReferenceType,
  TemplateEntryReferenceValue,
  TemplateEntryType,
} from '@/features/templates'

import {
  InputField,
  SIZE,
  SelectField,
  SelectMultiField,
  SwitchField,
  TextAreaField,
} from '@blockchain-traceability-sl/tailwind-components'
import { UseFileEntryHandlerResult } from '../../hooks'
import { TableDocumentInput } from './TableDocumentInput'
import { TableFileInput } from './TableFileInput'
import { DirtyTableEntryRow } from './types'
import { SignInputField } from '../../../signatures/components/SignInputField'
import { StakeHoldersSelectField } from '@/features/stakeHolders'
import { ProductsSelectField } from '@/features/products'
import { TruSelectField } from '@/features/trus'

export const TableInput = ({
  columnId,
  row,
  onChange,
  fileOptions,
  openDocumentPicker,
  documentPickedName,
}: {
  columnId: string
  row: DirtyTableEntryRow
  onChange: (columnId: string, row: DirtyTableEntryRow) => void
  fileOptions?: UseFileEntryHandlerResult
  openDocumentPicker: () => void
  documentPickedName?: string
}) => {
  const { id, type, values, options } = row
  const inputId = `table-input-${columnId}-${id}`
  switch (type) {
    case TemplateEntryType.SHORT_TEXT:
      return (
        <InputField
          key={inputId}
          id={inputId}
          name={inputId}
          onChange={event => {
            onChange(columnId, {
              ...row,
              values: [event.target.value],
            })
          }}
          value={values[0] as string}
        />
      )

    case TemplateEntryType.BOOLEAN:
      return (
        <div className='flex justify-center content-center -mb-8'>
          <SwitchField
            id={inputId}
            name={inputId}
            checked={values[0] === 'true'}
            onChange={checked => {
              onChange(columnId, {
                ...row,
                values: [checked ? 'true' : 'false'],
              })
            }}
          />
        </div>
      )

    case TemplateEntryType.LONG_TEXT:
      return (
        <TextAreaField
          id={inputId}
          name={inputId}
          onChange={event => {
            onChange(columnId, {
              ...row,
              values: [event.target.value],
            })
          }}
          value={values[0] as string}
        />
      )

    case TemplateEntryType.DATE:
      return (
        <DateLocaleField
          id={inputId}
          name={inputId}
          onChange={value => {
            if (value)
              onChange(columnId, {
                ...row,
                values: [value],
              })
          }}
          value={values[0] as string}
        />
      )

    case TemplateEntryType.TIME:
      return (
        <InputField
          id={inputId}
          name={inputId}
          type='time'
          onChange={event => {
            onChange(columnId, {
              ...row,
              values: [event.target.value],
            })
          }}
          value={values[0] as string}
        />
      )

    case TemplateEntryType.SINGLE_CHOICE:
      return (
        <SelectField
          id={inputId}
          name={inputId}
          options={
            options?.map(option => ({
              label: option,
              value: option,
            })) || []
          }
          onChange={option => {
            if (!option) return
            onChange(columnId, {
              ...row,
              values: [option?.value],
            })
          }}
          value={
            values[0]
              ? {
                  label: values[0] as string,
                  value: values[0] as string,
                }
              : null
          }
          menuSize={SIZE.SMALL}
          menuPortalTarget={document.body}
        />
      )

    case TemplateEntryType.MULTIPLE_CHOICE:
      return (
        <SelectMultiField
          id={inputId}
          name={inputId}
          options={
            options?.map(option => ({
              label: option,
              value: option,
            })) || []
          }
          value={
            values.length > 0
              ? values.map(option => ({
                  label: option as string,
                  value: option as string,
                }))
              : []
          }
          menuPortalTarget={document.body}
          menuSize={SIZE.SMALL}
          onChange={values => {
            onChange(columnId, {
              ...row,
              values: values.map(option => option.value),
            })
          }}
        />
      )

    case TemplateEntryType.FILE:
      return (
        <TableFileInput
          value={values[0] as string}
          valueFile={row.file}
          onChange={file => {
            if (!file) {
              return onChange(columnId, {
                ...row,
                values: [],
                file,
              })
            }

            if (!fileOptions) {
              onChange(columnId, {
                ...row,
                values: file ? [file.name] : [],
                file,
              })
            }

            if (fileOptions) {
              fileOptions.handleTableFileSelection(file, filePath => {
                onChange(columnId, {
                  ...row,
                  values: [filePath],
                  file,
                })
              })
            }
          }}
        />
      )

    case TemplateEntryType.REFERENCE: {
      const value = values[0] as TemplateEntryReferenceValue
      if (
        ![
          TemplateEntryReferenceType.STAKEHOLDER,
          TemplateEntryReferenceType.PRODUCT,
          TemplateEntryReferenceType.TRU,
        ].includes(value.type)
      ) {
        return null
      }
      switch (value.type) {
        case TemplateEntryReferenceType.STAKEHOLDER:
          return (
            <StakeHoldersSelectField
              id={inputId}
              name={inputId}
              menuPortalTarget={document.body}
              onChange={option => {
                if (!option) {
                  return onChange(columnId, {
                    ...row,
                    values: [
                      {
                        type: value.type,
                      },
                    ],
                  })
                }
                onChange(columnId, {
                  ...row,
                  values: [
                    {
                      id: option.value,
                      name: option.label,
                      type: value.type,
                    },
                  ],
                })
              }}
              stakeHolderIdValue={value.id}
              isClearable
            />
          )
        case TemplateEntryReferenceType.PRODUCT:
          return (
            <ProductsSelectField
              id={inputId}
              name={inputId}
              menuPortalTarget={document.body}
              onChange={option => {
                if (!option) {
                  return onChange(columnId, {
                    ...row,
                    values: [
                      {
                        type: value.type,
                      },
                    ],
                  })
                }
                onChange(columnId, {
                  ...row,
                  values: [
                    {
                      id: option.value,
                      name: option.label,
                      type: value.type,
                    },
                  ],
                })
              }}
              productIdValue={value.id}
              isClearable
            />
          )
        case TemplateEntryReferenceType.TRU:
          return (
            <TruSelectField
              id={inputId}
              name={inputId}
              menuPortalTarget={document.body}
              onChange={option => {
                if (!option) {
                  return onChange(columnId, {
                    ...row,
                    values: [
                      {
                        type: value.type,
                      },
                    ],
                  })
                }
                onChange(columnId, {
                  ...row,
                  values: [
                    {
                      id: option.value,
                      name: option.label,
                      type: value.type,
                    },
                  ],
                })
              }}
              truIdValue={value.id}
              isClearable
            />
          )
        default:
          return null
      }
    }

    case TemplateEntryType.LINK: {
      return (
        <InputField
          type='url'
          id={inputId}
          name={inputId}
          onChange={event => {
            onChange(columnId, {
              ...row,
              values: [event.target.value],
            })
          }}
          value={values[0] as string}
        />
      )
    }

    case TemplateEntryType.SIGNATURE: {
      return (
        <SignInputField
          id={inputId}
          onChange={signatures => {
            onChange(columnId, {
              ...row,
              values: signatures,
            })
          }}
          values={values as string[]}
          isLittlePreview
        />
      )
    }

    case TemplateEntryType.NUMBER: {
      return (
        <InputField
          type='number'
          id={inputId}
          name={inputId}
          onChange={event => {
            onChange(columnId, {
              ...row,
              values: [event.target.value],
            })
          }}
          value={values[0] as string}
        />
      )
    }

    case TemplateEntryType.DOCUMENT: {
      return (
        <TableDocumentInput
          value={values[0] as string}
          openDocumentPicker={openDocumentPicker}
          documentPickedName={documentPickedName}
          onRemove={() => {
            onChange(columnId, {
              ...row,
              values: [''],
            })
          }}
        />
      )
    }

    default:
      return null
  }
}
