import { noop } from '@/utils/noop'
import { createContext } from 'react'
import { OnboardingStep } from './steps'

export interface OnboardingContextStateValue {
  stepIndex: number
  run: boolean
  active: boolean
  inTutorial: boolean
  isLoading: boolean
  steps: OnboardingStep[]
}

export interface OnboardingContextValue extends OnboardingContextStateValue {
  startStep(): void
  skipDemo(): void
  startTutorial(): void
}

export const OnboardingContext = createContext<OnboardingContextValue>({
  stepIndex: 0,
  run: false,
  active: false,
  inTutorial: false,
  isLoading: false,
  startStep: noop,
  skipDemo: noop,
  steps: [],
  startTutorial: noop,
})
