export interface PublicPricingPlans {
  primaries: { name: string; amount: number; score: number; seatsCount: number }[]
  seats: { name: string; amount: number }[]
  modules: { name: string; amount: number }[]
}

export enum SubscriptionPaymentMethod {
  DIRECT = 'direct',
  INVOICE = 'invoice',

  TRAZABLE_BANK_TRANSFER = 'trazable_bank_transfer',
}

export interface SubscriptionCreationPlanBundle {
  primaryPlan: string
  modules?: string[]
  seatQuantity?: number
}

export interface SubscriptionCreation {
  planBundle: {
    primaryPlan: string
    modules?: string[]
    seatQuantity?: number
  }
  paymentMethod: SubscriptionPaymentMethod
}

export interface SubscriptionCreationResult {
  paymentMethod: SubscriptionPaymentMethod
  hostedPageUrl: string
}

export enum SubscriptionPaymentStatus {
  NONE = 'none',
  PENDING = 'pending',
  COMPLETED = 'completed',
  UNPAID = 'unpaid',
  PAUSED = 'paused',
}

export interface SubscriptionPaymentInfo {
  status: SubscriptionPaymentStatus
  paymentMethod?: SubscriptionPaymentMethod
  hostedPageUrl?: string | null
}

export enum SubscriptionStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  UNPAID = 'unpaid',
  CANCELLED = 'cancelled',
}

export enum SubscriptionInfoOperationType {
  UPDATE = 'update',

  /**
   * Upgrade and downgrade are not used anymore, but we keep them here for compatibility reasons.
   */
  UPGRADE = 'upgrade',
  DOWNGRADE = 'downgrade',
}

export interface SubscriptionInfo {
  status: SubscriptionStatus
  paymentMethod: SubscriptionPaymentMethod
  renewalDate?: Date
  isTrialPeriod?: boolean
  cancellationRequested?: boolean
  planBundle: {
    primaryPlan: {
      id: string
      name: string
    }
    modules: {
      id: string
      name: string
    }[]
    seatQuantity: number
    pendingOperations: {
      type: SubscriptionInfoOperationType
      changes: {
        primaryPlanId: string
        moduleIds: string[]
        seatQuantity: number
      }
      createdAt: string
    }[]
  }

  isUpgrading?: boolean
}

export interface SubscriptionPlanUpdate {
  primaryPlan?: string
  modules?: string[]
  seatQuantity?: number
}
