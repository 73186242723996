import { ValidationRequest, ValidationRequestStatus } from '@/features/validationRequests'
import { COLOR, NEW_COLOR } from '@blockchain-traceability-sl/tailwind-components'
import { ApprovedTaskIcon, PendingToValidateTaskIcon, RejectedTaskIcon, TaskIcon } from './Icons'

export const TaskValidationIconBadge = ({ validation }: { validation?: ValidationRequest }) => {
  return (
    <div>
      {!validation && <TaskIcon className='h-4 w-3.5' color={COLOR.BLUE} />}
      {validation?.status === ValidationRequestStatus.PENDING && (
        <div className='bg-orange-100 rounded'>
          <PendingToValidateTaskIcon className='h-5 w-5' color={NEW_COLOR.ORANGE} />
        </div>
      )}
      {validation?.status === ValidationRequestStatus.APPROVED && (
        <div className='bg-green-100 rounded'>
          <ApprovedTaskIcon className='h-5 w-5' color={COLOR.GREEN} />
        </div>
      )}
      {validation?.status === ValidationRequestStatus.REJECTED && (
        <div className='bg-red-100 rounded'>
          <RejectedTaskIcon className='h-5 w-5' color={COLOR.RED} />
        </div>
      )}
    </div>
  )
}
