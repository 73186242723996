import { ComponentProps } from 'react'

export const RejectedTaskIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.673 3.416c.275-.728 1.016-1.25 1.886-1.25h1.334c.87 0 1.611.522 1.886 1.25h.78c1.105 0 2 .84 2 1.875v7.5c0 1.035-.895 1.875-2 1.875H4.893c-1.105 0-2-.84-2-1.875v-7.5c0-1.036.895-1.875 2-1.875h.78zm0 1.25h-.78c-.368 0-.667.28-.667.625v7.5c0 .345.299.625.667.625h6.666c.369 0 .667-.28.667-.625v-7.5c0-.345-.298-.625-.667-.625h-.78c-.275.728-1.015 1.25-1.886 1.25H7.559c-.87 0-1.611-.522-1.886-1.25zm1.886-1.25c-.368 0-.666.28-.666.625s.298.625.666.625h1.334c.368 0 .666-.28.666-.625s-.298-.625-.666-.625H7.559z'
        fill='#991B1B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.229 8.204a.392.392 0 01.495 0l1.502 1.288L9.73 8.204a.392.392 0 01.495 0 .27.27 0 010 .424L8.72 9.916l1.503 1.288a.27.27 0 010 .424.392.392 0 01-.495 0L8.226 10.34l-1.502 1.288a.392.392 0 01-.495 0 .27.27 0 010-.424L7.73 9.916 6.23 8.628a.27.27 0 010-.424z'
        fill='#991B1B'
        stroke='#991B1B'
        strokeWidth={0.5}
        strokeLinecap='round'
      />
    </svg>
  )
}
