import { LegacyRef, useEffect, useState } from 'react'

/**
 *  This hook is used to determine if the content of a container is clamped or not.
 * It returns a boolean and a ref to the container.
 * @returns [isClamped, contentRef]
 * @see https://tailwindcss.com/blog/multi-line-truncation-with-tailwindcss-line-clamp
 * @example
 * const [isClamped, contentRef] = useIsClamped()
 * return (
 *  <div ref={contentRef}>
 *   ...
 *   {isClamped && <span>...</span>}
 * </div>
 */
export const useIsClamped = (): [boolean, LegacyRef<HTMLElement>] => {
  const [contentRef, setContentRef] = useState<HTMLElement | null>(null)
  const [isClamped, setClamped] = useState(false)

  useEffect(() => {
    if (!contentRef) return
    setClamped(contentRef.scrollHeight > contentRef.clientHeight + 1)
  }, [contentRef])

  return [isClamped, setContentRef]
}
