import { TemplateBlockEntryTypes, TemplateEntry, TemplateEntryType } from '@/features/templates'
import { Entry } from '../interfaces'

interface BlockChildEntry {
  index: number
  blockId: string
  id: string
  type: TemplateEntryType
  mandatory?: boolean
}

type ConditionalBlockEntry = {
  id: string
  type: TemplateEntryType.CONDITIONAL
  options: [string[], string[]]
}

type BlockEntry =
  | {
      id: string
      type: TemplateEntryType.SECTION
    }
  | ConditionalBlockEntry

export type SectionBlockMapValue = BlockChildEntry[]

export type ConditionalBlockMapValue = {
  if: BlockChildEntry[]
  else: BlockChildEntry[]
}

export interface EntryBlocksMap {
  sections: Record<string, SectionBlockMapValue>
  conditionals: Record<string, ConditionalBlockMapValue>
}

const prepareSectionBlockMap = (
  entries: BlockChildEntry[],
  sectionEntry: BlockEntry
): BlockChildEntry[] => entries.filter(item => item.blockId === sectionEntry.id)

const prepareConditionalBlockMap = (
  entries: BlockChildEntry[],
  conditionalEntry: ConditionalBlockEntry
): {
  if: BlockChildEntry[]
  else: BlockChildEntry[]
} => {
  const [ifIds, elseIds] = conditionalEntry.options

  return entries.reduce<{
    if: BlockChildEntry[]
    else: BlockChildEntry[]
  }>(
    (accumulator, item) => {
      if (item.blockId === conditionalEntry.id) {
        if (ifIds.includes(item.id)) {
          accumulator.if.push(item)
        } else if (elseIds.includes(item.id)) {
          accumulator.else.push(item)
        }
      }
      return accumulator
    },
    { if: [], else: [] }
  )
}

/**
 * Helper to make blocks more performance accessible during entries filling
 * Allows to get block by id in the useField hook
 * @param entries
 */
export const prepareEntryBlocksMaps = (entries: Entry[]): EntryBlocksMap => {
  const entriesWithIndexes = entries.map<[Entry, number]>((item, index) => [item, index])

  const blockChildEntries = entriesWithIndexes
    .filter(([item]) => !!item.blockId)
    .map<BlockChildEntry>(([item, index]) => ({
      index,
      blockId: item.blockId as string,
      id: item.id,
      type: item.type,
      mandatory: item.mandatory,
    }))

  return entriesWithIndexes
    .filter(([item]) => TemplateBlockEntryTypes.includes(item.type))
    .reduce<EntryBlocksMap>(
      (accumulator, [entry]) => {
        if (entry.type === TemplateEntryType.SECTION) {
          accumulator.sections[entry.id] = prepareSectionBlockMap(blockChildEntries, {
            id: entry.id,
            type: entry.type,
          })
        }
        if (entry.type === TemplateEntryType.CONDITIONAL) {
          accumulator.conditionals[entry.id] = prepareConditionalBlockMap(blockChildEntries, {
            id: entry.id,
            options: (entry.options || [[], []]) as [string[], string[]],
            type: entry.type,
          })
        }
        return accumulator
      },
      {
        sections: {},
        conditionals: {},
      }
    )
}

export const prepareEntryBlocksMapsFromTemplateEntries = (
  entries: TemplateEntry[]
): EntryBlocksMap => {
  const entriesWithIndexes = entries.map<[TemplateEntry, number]>((item, index) => [item, index])

  const blockChildEntries = entriesWithIndexes
    .filter(([item]) => !!item.blockId)
    .map<BlockChildEntry>(([item, index]) => ({
      index,
      blockId: item.blockId as string,
      id: item.id,
      type: item.type,
      mandatory: item.mandatory,
    }))

  return entriesWithIndexes
    .filter(([item]) => TemplateBlockEntryTypes.includes(item.type))
    .reduce<EntryBlocksMap>(
      (accumulator, [entry]) => {
        if (entry.type === TemplateEntryType.SECTION) {
          accumulator.sections[entry.id] = prepareSectionBlockMap(blockChildEntries, {
            id: entry.id,
            type: entry.type,
          })
        }
        if (entry.type === TemplateEntryType.CONDITIONAL) {
          accumulator.conditionals[entry.id] = prepareConditionalBlockMap(blockChildEntries, {
            id: entry.id,
            options: (entry.values || [[], []]) as [string[], string[]],
            type: entry.type,
          })
        }
        return accumulator
      },
      {
        sections: {},
        conditionals: {},
      }
    )
}
