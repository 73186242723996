import { PDF_MANUAL_EN_URL, PDF_MANUAL_ES_URL, TABLE_SIZE_PRESETS } from '@/app/constants'
import i18n from '@/i18n'
import { noop } from '@/utils/noop'
import { Button, SIZE, Table } from '@blockchain-traceability-sl/tailwind-components'
import { memo, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'react-table'

export type DepartmentsTableRow = {
  id: string
  name: string
  description: string
  totalUsers: number | string
}
export type DepartmentsTableRowClickResult = Row<DepartmentsTableRow>

export interface DepartmentsTableProps {
  isLoading?: boolean
  /**
   * Must be memoized to avoid extra renders
   */
  data: DepartmentsTableRow[]
  /**
   * Must be memoized to avoid extra renders
   */
  onRowClick?: (row: DepartmentsTableRowClickResult) => void
  rowActionsDisabled?: boolean

  /**
   * Pagination
   */
  onPageChange: (page: { pageIndex: number; pageSize: number }) => void
  totalCount: number
  pageIndex: number
  pageSize: number
}

export const DepartmentsTable = memo(
  ({
    isLoading,
    data,
    onRowClick = noop,
    rowActionsDisabled,
    onPageChange,
    totalCount,
    pageIndex,
    pageSize,
  }: DepartmentsTableProps) => {
    const { t } = useTranslation('nsDepartmentsListPage')

    const language = i18n.language

    const userManualUrl = useMemo(() => {
      if (language === 'es') {
        return PDF_MANUAL_ES_URL
      }
      return PDF_MANUAL_EN_URL
    }, [language])

    return (
      <Table
        manualPagination
        onPageChange={onPageChange}
        onPageSizeChange={newPageSize => onPageChange({ pageIndex, pageSize: newPageSize })}
        totalCount={totalCount}
        pageSize={pageSize}
        defaultPageIndex={pageIndex}
        isLoading={isLoading}
        columns={[
          {
            Header: t(`table.departmentName`).toString(),
            accessor: 'name',
          },
          {
            Header: t(`table.description`).toString(),
            accessor: 'description',
          },
          {
            Header: t(`table.totalUsers`).toString(),
            accessor: 'totalUsers',
          },
          {
            Header: t('table.actions').toString(),
            accessor: 'actions',
            disableGlobalFilter: true,
          },
        ]}
        data={data.map(row => ({
          ...row,
          actions: (
            <Button color='link' disabled={rowActionsDisabled}>
              {t('table.action.cell')}
            </Button>
          ),
        }))}
        onRowClick={row => onRowClick(row as unknown as Row<DepartmentsTableRow>)}
        previousText={t('pagination.previous')}
        nextText={t('pagination.next')}
        renderInfoText={(from, to, total) => (
          <Trans
            t={t}
            i18nKey='pagination.show'
            components={{ b: <span className='font-semibold' /> }}
            values={{ from, to, total }}
          />
        )}
        sizePresetsText={t('pagination.items')}
        showSizePresets
        sizePresets={TABLE_SIZE_PRESETS}
        noDataText={
          <tr>
            <td colSpan={5} className='text-center p-8 space-y-6'>
              <p className='text-4xl leading-10 font-extrabold text-center whitespace-pre-wrap'>
                {t('table.noData')}
              </p>
              <Button
                color='secondary'
                size={SIZE.EXTRA_LARGE}
                className='m-auto'
                onClick={() => {
                  window.analytics.track('ACTION_CLICK_HELP', {
                    Source: 'Departments section',
                  })
                  window.open(userManualUrl, '_blank')
                }}
              >
                {t('table.needHelp')}
              </Button>
            </td>
          </tr>
        }
      />
    )
  }
)
