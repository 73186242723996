export enum DocumentType {
  FOLDER = 'folder',
  FILE = 'file',
}

export enum SpecialFolderType {
  COMPLIANCES = 'compliances',
  TASKS = 'tasks',
}

export enum DocumentCreationTriggerType {
  COMPLIANCE = 'compliance',
  TASK = 'task',
}

interface DocumentCreationTrigger {
  entityType: DocumentCreationTriggerType
  entityId: string
  templateId?: string
}

/**
 * Base common interface in all Documents
 */
export interface BaseDocument {
  _id: string
  companyId: string

  /**
   * Title to display
   */
  title: string

  /**
   * Parent folder id to manage nesting
   * @description
   * ROOT_FOLDER_ID for root folder
   */
  parentId: string

  /**
   * Document type
   */
  type: DocumentType

  /**
   * Document tags
   */
  tags: string[]

  /**
   * Companies linked
   */
  companyIds: string[]

  /**
   * Products linked
   */
  productIds: string[]

  /**
   * TRUs linked
   */
  truIds: string[]

  /**
   * StakeHolders linked
   */
  stakeHolderIds: string[]

  /**
   * StakeHolderProduct linked
   */
  stakeHolderProductIds: string[]

  /**
   * Receptions linked
   */
  receptionIds: string[]

  /**
   * Mime type
   */
  mimeType: string | null

  /**
   * File allocated path
   */
  path: string | null

  /**
   * Document is deleted
   */
  deleted: boolean

  /**
   * Document created ISODate
   */
  createdAt: string

  /**
   * Document last modification ISODate
   */
  updatedAt: string

  /**
   * Document exiration ISODate
   */
  expireAt: string | null

  isPublic?: boolean

  publicUrl?: string

  specialFolderType?: SpecialFolderType

  creationTrigger?: DocumentCreationTrigger
}

/**
 * Interface to Folder
 * @extends {BaseDocument}
 */
export interface DocumentFolder extends BaseDocument {
  type: DocumentType.FOLDER
  mimeType: null
  path: null
  expireAt: null
}

/**
 * Interface to Folder
 */
export interface DocumentFolderCreation {
  title: string
  parentId: string
  type: DocumentType.FOLDER
  specialFolderType?: SpecialFolderType
  stakeHolderIds?: string[]

  creationTrigger?: DocumentCreationTrigger
}

export interface DocumentFolderUpdate {
  title: string
  parentId: string
}

/**
 * Interface to File
 * @extends {BaseDocument}
 */
export interface DocumentFile extends BaseDocument {
  type: DocumentType.FILE
  mimeType: string
  path: string
  sharedWith: string[]
}

/**
 * Interface to File
 */
export interface DocumentFileCreation {
  title: string
  parentId: string
  type: DocumentType.FILE
  tags: string[]
  companyIds: string[]
  productIds: string[]
  truIds: string[]
  stakeHolderIds: string[]
  stakeHolderProductIds: string[]
  receptionIds: string[]
  file: {
    mimetype: string
    path: string
  }
  expireAt?: string
  sharedWith: string[]
  creationTrigger?: DocumentCreationTrigger
}

export interface DocumentFileUpdate {
  title: string
  parentId: string
  tags: string[]
  companyIds: string[]
  productIds: string[]
  truIds: string[]
  stakeHolderIds: string[]
  stakeHolderProductIds: string[]
  receptionIds: string[]
  expireAt: string | null
  sharedWith: string[]
  file?: {
    mimetype: string
    path: string
  }
}

export type DocumentUpdate = Partial<DocumentFolderUpdate | DocumentFileUpdate>

export type Document = DocumentFolder | DocumentFile

export enum DocumentSettingsExpirationDuration {
  THREE_YEAR = '3years',
  TWO_YEAR = '2years',
  ONE_YEAR = '1year',
  SIX_MONTHS = '6months',
  NINETY_DAYS = '90days',
  SIXTY_DAYS = '60days',
  THIRTY_DAYS = '30days',
  NEVER = 'never',
}

export const DocumentSettingsExpirationDurations = Object.values(DocumentSettingsExpirationDuration)

export enum DocumentFiltersExpirationStatus {
  EXPIRED = 'expired',
  ALMOST_EXPIRED = 'almostExpired',
}

export enum DocumentSortOptions {
  CREATED_AT = 'createdAt',
  TITLE = 'title',
}

export interface DocumentFilters {
  search: string
  expirationStatus: DocumentFiltersExpirationStatus[]
  companyIds: string[]
  productIds: string[]
  truIds: string[]
  stakeHolderIds: string[]
  stakeHolderProductIds: string[]
  receptionIds: string[]
  startCreatedAt: string | null
  endCreatedAt: string | null
  tags: string[]
  startExpiredAt: string | null
  endExpiredAt: string | null
  sortBy?: DocumentSortOptions
  sortDirection?: 'asc' | 'desc'
  creationTriggerTypes: string[]
  creationTriggerTemplateIds: string[]
  parentId: string | null
  title: string | null

  type?: DocumentType
  specialFolderType?: SpecialFolderType
}
