import { TRU } from '@/features/trus'
import { Metadata } from '@/features/metadatas'
import { Product } from '@/features/products'
import { Shipment } from '@/features/shipments'
import { Reception } from '@/features/receptions'
import { StakeHolderProduct } from '@/features/stakeHolderProducts'
import { Facility } from '@/features/facilities'

export enum TraceabilityErrorCodes {
  PRODUCT_SERVICE_UNAVAILABLE = 'PRODUCT_SERVICE_UNAVAILABLE',
  TRACEABILITY_SERVICE_UNAVAILABLE = 'TRACEABILITY_SERVICE_UNAVAILABLE',
  COMPANY_SERVICE_UNAVAILABLE = 'COMPANY_SERVICE_UNAVAILABLE',
  RECEPTION_SERVICE_UNAVAILABLE = 'RECEPTION_SERVICE_UNAVAILABLE',
  METADATA_SERVICE_UNAVAILABLE = 'METADATA_SERVICE_UNAVAILABLE',
  SHIPMENT_SERVICE_UNAVAILABLE = 'SHIPMENT_SERVICE_UNAVAILABLE',
  STAKEHOLDER_PRODUCT_SERVICE_UNAVAILABLE = 'STAKEHOLDER_PRODUCT_SERVICE_UNAVAILABLE',
}

export const TraceabilityHideLabel = 'HIDE'

export interface TraceabilityNode {
  _id: string
  countryCode: string
  reference: string
  name: string
  hide: boolean
  level: number
  coordinates?: { lat: number; long: number } | null
  products:
    | (Product & { facility?: Facility })[]
    | TraceabilityErrorCodes.PRODUCT_SERVICE_UNAVAILABLE
  trus: (TRU & {
    metadata: Metadata[] | TraceabilityErrorCodes.METADATA_SERVICE_UNAVAILABLE
    shipments:
      | (Shipment & {
          metadata: Metadata[] | TraceabilityErrorCodes.METADATA_SERVICE_UNAVAILABLE
        })[]
      | TraceabilityErrorCodes.SHIPMENT_SERVICE_UNAVAILABLE
  })[]
  stakeHolderProducts:
    | StakeHolderProduct[]
    | TraceabilityErrorCodes.STAKEHOLDER_PRODUCT_SERVICE_UNAVAILABLE
  receptions:
    | (Reception & { metadata: Metadata[] | TraceabilityErrorCodes.METADATA_SERVICE_UNAVAILABLE })[]
    | TraceabilityErrorCodes.RECEPTION_SERVICE_UNAVAILABLE
}

export interface TraceabilityEdge {
  from?: string
  to?: string
}

export interface TraceabilityTruEdge {
  from: string
  to: string
}

export interface Traceability {
  edges: TraceabilityEdge[]
  nodes: TraceabilityNode[]
  truEdges: TraceabilityTruEdge[]
}
