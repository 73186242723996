import { useCompanyUser } from '@/features/auth'
import { useDebounceState } from '@/hooks/use-debounce-state'
import { matchWithSearch } from '@/hooks/use-filter-algorithm'
import {
  SelectMultiField,
  SelectMultiFieldProps,
  SelectorOption,
  createSelectorOption,
  noop,
} from '@blockchain-traceability-sl/tailwind-components'
import { useEffect, useState } from 'react'
import { useFetchUsers, usePaginatedUsers } from '../hooks'
import { UserFilters } from '../interfaces'

export interface UsersSelectMultiFieldProps
  extends Omit<SelectMultiFieldProps, 'options'>,
    Omit<UserFilters, 'search'> {
  /**
   * Includes company in selector options
   */
  withCompany?: boolean
}

export const UsersSelectMultiField = ({
  withCompany,
  departmentId,
  ...props
}: UsersSelectMultiFieldProps) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const [options, setOptions] = useState<SelectorOption[]>([])

  const {
    isLoading: isPaginatedUsersLoading,
    users,
    fetchNextPage,
  } = usePaginatedUsers({
    filters: {
      search: debouncedSearch,
      departmentId,
    },
  })

  const { fetchUsers } = useFetchUsers({
    search: debouncedSearch,
    departmentId,
  })

  const companyUser = useCompanyUser()

  const [isLoading, setIsLoading] = useState(isPaginatedUsersLoading)

  useEffect(() => {
    setOptions([
      ...(withCompany && matchWithSearch(companyUser.name, debouncedSearch)
        ? [createSelectorOption(companyUser.name, companyUser._id)]
        : []),
      ...users.map(user => user.toSelectorOption()),
    ])
  }, [companyUser.name, companyUser._id, debouncedSearch, users, withCompany])

  useEffect(() => {
    setIsLoading(isPaginatedUsersLoading)
  }, [isPaginatedUsersLoading])

  const handleSelectAll = async () => {
    setIsLoading(true)
    fetchUsers()
      .then(users => {
        const newOptions = [
          ...(withCompany ? [createSelectorOption(companyUser.name, companyUser._id)] : []),
          ...users.map(user => user.toSelectorOption()),
        ]
        setOptions(newOptions)
        if (props.onChange) {
          props.onChange(newOptions)
        }
      })
      .catch(noop)
      .finally(() => setIsLoading(false))
  }

  return (
    <SelectMultiField
      {...props}
      isLoading={isLoading}
      options={options}
      onInputChange={setSearch}
      onMenuScrollToBottom={() => fetchNextPage()}
      onSelectAll={handleSelectAll}
    />
  )
}
