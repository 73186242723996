export enum NotificationAction {
  // TASKS
  TASK_ASSIGNED = 'task_assigned',
  TASK_CLOSED = 'task_closed',
  TASK_DELETED = 'task_deleted',
  TASK_REPORT_SENT = 'task_report_sent',

  // ISSUES
  ISSUE_RESPONDER_COMMUNICATION = 'issue_responder_communication',
  ISSUE_COMMUNICATED = 'issue_communicated',
  ISSUE_COMMENTED = 'issue_commented',
  ISSUE_RESOLVED = 'issue_resolved',

  // COMPLIANCE
  COMPLIANCE_SUBMITTED = 'compliance_submitted',
  COMPLIANCE_ALMOST_EXPIRED = 'compliance_almost_expired',
  COMPLIANCE_EXPIRED = 'compliance_expired',

  // UPLOAD REQUEST
  UPLOAD_REQUEST_CONFIRMED = 'uploadRequest_confirmed',

  // VALIDATIONS
  VALIDATION_CREATED = 'validation_created',
}

export enum NotificationEntityType {
  TASK = 'task',
  ISSUE = 'issue',
  COMPLIANCE = 'compliance',
  UPLOAD_REQUEST = 'uploadRequest',
  VALIDATION = 'validation',
  PRODUCT = 'product',
  STAKEHOLDER_PRODUCT = 'stakeholderProduct',
}

export enum BroadcastType {
  SINGLE = 'single',
  ALL_ADMINS = 'allAdmins',
}

export interface Notification<T extends Record<string, unknown> = Record<string, unknown>> {
  _id: string
  companyId: string
  recipientId: string
  acknowledged: boolean
  triggeredBy: string
  action: NotificationAction
  broadcastType: BroadcastType
  entityId: string
  entityType: NotificationEntityType
  payload?: T
  createdAt: string
  updatedAt: string
}
export enum NotificationCategoryType {
  HOME = 'home',
}

export interface NotificationFilters {
  allUsers?: boolean
  unacknowledged?: boolean
  categoryTypes?: NotificationCategoryType[]
}

export interface AcknowlegeNotificationParams {
  notificationId: string
}
