import { DocumentFile, DocumentType, getDocumentDisplayTitle } from '@/features/documents'
import { DocumentListItemIcon } from '@/pages/documents/DocumentsView/components/DocumentsList/DocumentListItemIcon'
import { isEven, isOdd } from '@/utils/even-odd'
import { Breadcrumb, Loader, Modal, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import classNames from 'classnames'
import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ROOT_FOLDER_ID } from '../../constants'
import { useDocuments } from '../../hooks'

export interface DocumentPickerProps {
  show: boolean
  toggle: () => void
  onPick: (document: DocumentFile) => void
}

export const DocumentPicker = memo(({ show, toggle, onPick }: DocumentPickerProps) => {
  const { t } = useTranslation('nsDocumentManagerPicker')
  const [parentId, setParentId] = useState<string>(ROOT_FOLDER_ID)
  const { documents, isLoading } = useDocuments({ parentId }, { enabled: show })
  const [breadcrumb, setBreadcrumb] = useState<Array<{ id: string; name: string }>>([])

  const handleDoubleClick = useCallback(
    (documentId: string) => {
      const documentSelected = documents.find(({ _id }) => _id === documentId)
      if (documentSelected && documentSelected.type === DocumentType.FOLDER) {
        setParentId(documentId)
        const displayTitle = getDocumentDisplayTitle(documentSelected, { t })
        setBreadcrumb([...breadcrumb, { id: documentSelected._id, name: displayTitle }])
      } else if (documentSelected && documentSelected.type === DocumentType.FILE) {
        onPick(documentSelected)
      }
    },
    [breadcrumb, documents, onPick, t]
  )

  const handleBreadcrumbClick = useCallback(
    (folderId: string) => {
      const breadcrumbIndex = breadcrumb.findIndex(({ id }) => id === folderId)

      setParentId(folderId)

      // Re calculate breadcrump items
      if (folderId === ROOT_FOLDER_ID) {
        setBreadcrumb([])
      } else {
        setBreadcrumb(breadcrumb.slice(0, breadcrumbIndex + 1))
      }
    },
    [breadcrumb]
  )

  return (
    <Modal show={show} toggle={toggle} size={SIZE.MEDIUM} dismissButton>
      <Modal.Title>
        <p className='text-lg leading-6 font-medium'>{t('title')}</p>
        <small className='text-xs leading-none font-normal'>{t('disclaimer')}</small>
      </Modal.Title>
      <Modal.Body>
        <div className='mt-6 space-y-2'>
          {/* Breadcrumb */}
          <Breadcrumb
            pages={breadcrumb}
            onClick={handleBreadcrumbClick}
            className='border select-none'
          />

          {/* Documents list */}
          <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
              <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-200 select-none'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                        {t('table.name')}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={4}>
                          <Loader center='horizontal' className='my-3' size={SIZE.SMALL} />
                        </td>
                      </tr>
                    ) : (
                      documents.map(({ _id, title, mimeType }, index) => (
                        <tr
                          key={_id}
                          className={classNames('group cursor-pointer hover:bg-gray-100', {
                            'bg-white': isEven(index),
                            'bg-gray-50': isOdd(index),
                          })}
                          onDoubleClick={() => handleDoubleClick(_id)}
                        >
                          <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex flex-row items-center'>
                            <DocumentListItemIcon mimeType={mimeType} />
                            {title}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
})
