import dayjs from 'dayjs'
import { LOCALSTORAGE_KEY } from './constants'
import { MemoFormKeys } from './interfaces'

export const stateIsExpired = (timestamp: number) => {
  return dayjs().isAfter(dayjs(timestamp).add(24, 'hour'))
}

export const generateStoreKey = ({ entity, entityId, action }: MemoFormKeys): string =>
  `${LOCALSTORAGE_KEY}.${entity}${
    entityId ? `_${typeof entityId === 'string' ? entityId : JSON.stringify(entityId)}` : ''
  }.${action}`
