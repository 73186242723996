import { ComponentProps } from 'react'

export const ApprovedTaskIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.574 3.416c.274-.728 1.015-1.25 1.886-1.25h1.333c.871 0 1.612.522 1.887 1.25h.78c1.105 0 2 .84 2 1.875v7.5c0 1.035-.895 1.875-2 1.875H4.793c-1.104 0-2-.84-2-1.875v-7.5c0-1.036.896-1.875 2-1.875h.78zm0 1.25h-.78c-.369 0-.667.28-.667.625v7.5c0 .345.298.625.666.625h6.667c.368 0 .667-.28.667-.625v-7.5c0-.345-.299-.625-.667-.625h-.78c-.275.728-1.016 1.25-1.887 1.25H7.46c-.87 0-1.612-.522-1.886-1.25zm1.886-1.25c-.368 0-.667.28-.667.625s.299.625.667.625h1.333c.369 0 .667-.28.667-.625s-.298-.625-.667-.625H7.46zm3.138 4.558c.26.244.26.64 0 .884l-2.667 2.5a.698.698 0 01-.942 0l-1.334-1.25a.598.598 0 010-.884.698.698 0 01.943 0l.862.808 2.195-2.058a.699.699 0 01.943 0z'
        fill='#047857'
      />
    </svg>
  )
}
