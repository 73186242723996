export enum CustomerPlan {
  STARTER = 'Starter',
  BUSINESS = 'Business',
  CORPORATE = 'Corporate',
}

export interface CustomerStripe {
  _id: string
  trazableId: string
  stripeId: string | null
  email: string
  language: string
  /**
   * If current plan is active
   */
  active: boolean
  product: {
    id: string
    name: CustomerPlan
    amount: number
    endDate: string
  } | null
  createdAt: string
  updatedAt: string
}
