import { DateRangeLocaleField } from '@/components/DateRangeLocaleField'
import { Button, ButtonWithIcon } from '@blockchain-traceability-sl/tailwind-components'
import { ExclamationIcon } from '@heroicons/react/outline'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useDownloadReportIssuesSummary } from '@/features/reports'
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon'

type DateRange = {
  startDate: Date | null
  endDate?: Date | null
}

export const IssuesResumeReporter = () => {
  const { t } = useTranslation('nsHomePage', { keyPrefix: 'quickActions.card.incidentReport' })

  const { downloadIssuesSummaryReport, isLoading } = useDownloadReportIssuesSummary()

  const downloadIssuesResumeReport = async (startDate: Date, endDate: Date) => {
    const reportName = `report-${dayjs(startDate).format('L')}-${dayjs(endDate).format('L')}`

    // Generate report in backend
    downloadIssuesSummaryReport({
      startDate,
      endDate,
      filename: reportName,
    })
  }

  const { values, submitForm, setFieldValue } = useFormik<DateRange>({
    initialValues: {
      startDate: dayjs(new Date()).startOf('day').toDate(),
      endDate: dayjs(new Date()).endOf('day').toDate(),
    },
    validationSchema: Yup.object().shape({
      startDate: Yup.date().required(),
      endDate: Yup.date().required(),
    }),
    onSubmit: data => {
      if (!data.startDate || !data.endDate) return
      downloadIssuesResumeReport(data.startDate, data.endDate)
    },
  })

  return (
    <div className='rounded-lg bg-white p-4'>
      <div className='flex flex-col'>
        <div className='flex flex-row'>
          <div>
            <ExclamationIcon className='w-6 text-blue-600 mr-4' />
          </div>
          <div>
            <span className='text-blue-600 text-sm'>{t('title')}</span>
          </div>
        </div>
        <div className='flex flex-row'>
          <div className='w-6 mr-4' />
          <div>
            <span className='text-gray-500 text-sm'>{t('description')}</span>
          </div>
        </div>
        <div className='flex  flex-col lg:flex-row w-full mt-4 gap-8 justify-between'>
          <DateRangeLocaleField
            className='w-full flex-1'
            id='fastTaskReport'
            name='fastTaskReport'
            value={{
              startDate: values.startDate?.toString() ?? null,
              endDate: values.endDate?.toString() ?? null,
            }}
            onChange={dates => {
              if (dates.startDate) {
                setFieldValue('startDate', new Date(dates.startDate))
              } else {
                setFieldValue('startDate', null)
              }
              if (dates.endDate) {
                setFieldValue('endDate', new Date(dates.endDate))
              } else {
                setFieldValue('endDate', null)
              }
            }}
            presetYear={false}
            max={90}
            isClearable
          />
          {!isLoading ? (
            <Button
              onClick={submitForm}
              className='flex-shrink-0'
              disabled={!values.startDate || !values.endDate}
            >
              {t('submit')}
            </Button>
          ) : (
            <ButtonWithIcon IconComponent={SpinnerIcon} className='flex-shrink-0' disabled>
              {t('downloading')}
            </ButtonWithIcon>
          )}
        </div>
      </div>
    </div>
  )
}
