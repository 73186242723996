export const ROOT_FOLDER_ID = 'root' as const
export const REGISTRIES_FOLDER_ID = 'REGISTRIES_0' as const

export const DOCUMENT_MAXIMUM_SIZE = 10 * 1000000

export const DOCUMENT_ALMOST_EXPIRED_DAYS = 30

export const AnalyticsFiltersAttributes: Record<string, string> = {
  expirationStatus: 'Document status',
  createdAt: 'Creation date',
  stakeHolders: 'Company',
  tags: 'Tag',
  creationTriggerTemplates: 'Compliance form',
}
