import * as Sentry from '@sentry/react'
import config from '@/config'
import {
  CaptureConsole as CaptureConsoleIntegration,
  HttpClient as HttpClientIntegration,
} from '@sentry/integrations'

export const sentryInitialize = (dsn: string) => {
  Sentry.init({
    // The DSN tells the SDK where to send the events to.
    dsn,
    /**
     * Enable Sentry only in production
     * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/#enabled
     * @see https://create-react-app.dev/docs/adding-custom-environment-variables/
     */
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      Sentry.browserTracingIntegration({
        enableInp: true,
        tracePropagationTargets: ['localhost'],
      }),
      Sentry.replayIntegration({
        // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        sessionSampleRate: 0.1,
      }),

      /**
       * This is used to capture all console logs and display them in Sentry
       * By default capture all console levels
       * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/plugin/#captureconsole
       */
      new CaptureConsoleIntegration(),

      /**
       * This integration captures errors on failed requests from Fetch and XHR and attaches request and response information
       * By default only catch 5xx errors
       * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/plugin/#httpclient
       */
      new HttpClientIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    release: `${config.MICROSERVICE_NAME}@${config.MICROSERVICE_VERSION}`,
    environment: config.ENVIRONMENT_NAME,
  })
}

/**
 * Set the user in Sentry
 * @param userId The user id or company id
 */
export const sentrySetUser = (userId: string) => {
  Sentry.setUser({ id: userId })
}
