import { useGetUserName } from '@/features/users'
import { Feeds, Loader, NEW_COLOR } from '@blockchain-traceability-sl/tailwind-components'
import { FeedProps } from '@blockchain-traceability-sl/tailwind-components/dist/components/molecules/Feeds/src/Feed'
import { AnnotationIcon } from '@heroicons/react/outline'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useIssueById } from '../hooks'

interface IssueHistoryProps {
  issueId: string
}

export const IssueComments = ({ issueId }: IssueHistoryProps) => {
  const { t } = useTranslation('nsModalTaskIssues')

  const { issue, isLoading } = useIssueById(issueId)
  const { getUserName } = useGetUserName(t('userDeleted'))
  const issueHistoryFeeds = useMemo(
    (): FeedProps[] =>
      (issue?.comments || [])
        .map(
          (comment): FeedProps => ({
            title: (
              <div className='text-left whitespace-pre-wrap'>
                <Trans
                  t={t}
                  i18nKey='history.commented'
                  components={{ b: <b /> }}
                  values={{ name: getUserName(comment.createdBy), message: comment.message }}
                />
              </div>
            ),
            time: comment.createdAt,
            color: NEW_COLOR.ORANGE,
            iconColor: NEW_COLOR.ORANGE,
            icon: AnnotationIcon,
            isIconLight: true,
            content: null,
          })
        )

        .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
        .map(feed => ({ ...feed, time: t('history.date', { date: new Date(feed.time) }) })),
    [getUserName, issue?.comments, t]
  )

  if (isLoading || !issue) {
    return <Loader center='full' />
  }
  return (
    <div>
      <Feeds feeds={issueHistoryFeeds} />
    </div>
  )
}
