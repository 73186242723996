import { User as AuthUser, OAuthProvider, SAMLAuthProvider, getIdToken } from 'firebase/auth'
import { api } from '@/app/api'
import { convertPunycodeToUnicode } from '@/utils/convert-punycode-to-unicode'
import { AuthType, SSOAuthType, VerifyTokenResponse } from './interfaces'

export const getExternalAuthenticationProvider = (
  authType: SSOAuthType,
  providerId: string,
  { loginHint }: { loginHint: string }
) => {
  switch (authType) {
    case AuthType.SAML:
      return new SAMLAuthProvider(providerId)

    case AuthType.OIDC: {
      const provider = new OAuthProvider(providerId)
      provider.setCustomParameters({
        login_hint: loginHint,
      })
      return provider
    }

    default:
      throw new Error('Auth type is not supported')
  }
}

export const addTokenToRequests = (user: AuthUser) => {
  return api.interceptors.request.use(async function (config) {
    const token = await getIdToken(user)
    config.headers.Authorization = `Bearer ${token}`
    return config
  })
}

export const removeTokenFromRequests = (id: number | null) => {
  if (id !== undefined && id !== null) {
    api.interceptors.request.eject(id)
  }
}

export const mapResponseToVerifyTokenResult = (
  response: VerifyTokenResponse
): VerifyTokenResponse => ({
  ...response,
  user: {
    ...response.user,
    email: convertPunycodeToUnicode(response.user.email),
  },
  owner: {
    ...response.owner,
    email: convertPunycodeToUnicode(response.owner.email),
  },
  /**
   * @deprecated use user.email or owner.email
   */
  email: convertPunycodeToUnicode(response.email),
  account: {
    ...response.account,
    email: convertPunycodeToUnicode(response.account.email),
  },
})
