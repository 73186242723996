import {
  TemplateEntryReferenceType,
  TemplateEntryType,
  TemplateTableEntryColumn,
} from '@/features/templates'
import { usePrevious } from '@/hooks/use-previous'
import {
  CreatableMultiField,
  InputField,
  SIZE,
  SelectField,
  SelectorGroup,
} from '@blockchain-traceability-sl/tailwind-components'
import classNames from 'classnames'
import { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useTemplateEntriesSelectorOptions } from '../../hooks'
import { TemplateEntryReferenceTypes, TemplateEntryReferenceValue } from '../../interfaces'
import {
  DirtyTemplateTableEntryColumn,
  DirtyTemplateTableEntryRow,
  UseTableEntryFieldReturnValue,
  useTableEntryField,
} from './hooks'
import { TableColumnDropdown } from './TableColumnDropdown'
import { TableRowDropdown } from './TableRowDropdown'

const Row = ({
  className,
  entryTypeOptions,
  value,
  onAddRowClick,
  onRemoveRowClick,
  onChange,
  disableRemoveRow,
}: {
  className?: string
  entryTypeOptions: SelectorGroup[]
  value: DirtyTemplateTableEntryRow
  onAddRowClick: () => void
  onRemoveRowClick: () => void
  onChange: (value: Pick<DirtyTemplateTableEntryRow, 'type' | 'values'>) => void
  disableRemoveRow?: boolean
}) => {
  let selectValue

  if (value.type === TemplateEntryType.REFERENCE) {
    const [referenceValue] = value.values as TemplateEntryReferenceValue[]
    selectValue = {
      label:
        entryTypeOptions
          .flatMap(({ options }) => options)
          .find(selection => selection.value === referenceValue?.type)?.label || '',
      value: referenceValue?.type,
    }
  } else {
    selectValue = {
      label:
        entryTypeOptions
          .flatMap(({ options }) => options)
          .find(selection => selection.value === value.type)?.label || '',
      value: value.type,
    }
  }

  return (
    <>
      <div className={classNames('flex items-center flex-grow gap-1', className)}>
        <div className='flex-grow'>
          <SelectField
            id={`row-select-type-${value.id}`}
            name={`row-select-type-${value.id}`}
            options={entryTypeOptions}
            value={selectValue}
            onChange={option => {
              if (!option) return
              if (
                TemplateEntryReferenceTypes.includes(option.value as TemplateEntryReferenceType)
              ) {
                return onChange({
                  ...value,
                  type: TemplateEntryType.REFERENCE,
                  values: [
                    {
                      type: option.value as TemplateEntryReferenceType,
                    },
                  ],
                })
              }
              onChange({
                ...value,
                type: option.value as Exclude<
                  TemplateEntryType,
                  TemplateEntryType.TABLE | TemplateEntryType.STATEMENT
                >,
              })
            }}
            menuPortalTarget={document.body}
            menuSize={SIZE.SMALL}
          />
        </div>

        <TableRowDropdown
          onAddRowClick={onAddRowClick}
          onRemoveRowClick={onRemoveRowClick}
          disableRemove={disableRemoveRow}
        />
      </div>

      {(value.type === TemplateEntryType.MULTIPLE_CHOICE ||
        value.type === TemplateEntryType.SINGLE_CHOICE) && (
        <div className={classNames('mr-5', className)}>
          <CreatableMultiField
            id={`row-options-input-${value.id}`}
            name={`row-options-input-${value.id}`}
            value={(value.values as string[]).map(option => ({
              label: option,
              value: option,
            }))}
            onChange={options => {
              if (!options) return
              onChange({
                ...value,
                values: options.map(option => option.value),
              })
            }}
          />
        </div>
      )}
    </>
  )
}

const Column = ({
  index,
  inputPlaceholder,
  className,
  entryTypeOptions,
  addColumn,
  removeColumn,
  fixColumn,
  addRow,
  removeRow,
  updateColumn,
  updateRow,
  value,
  rowsPerColumn,
  rowsWithOptions,
  disableRemoveColumns,
}: {
  index: number
  inputPlaceholder: string
  className?: string
  entryTypeOptions: SelectorGroup[]
  value: DirtyTemplateTableEntryColumn
  disableRemoveColumns?: boolean
} & Pick<
  UseTableEntryFieldReturnValue,
  | 'addColumn'
  | 'removeColumn'
  | 'fixColumn'
  | 'addRow'
  | 'removeRow'
  | 'updateColumn'
  | 'updateRow'
  | 'rowsPerColumn'
  | 'rowsWithOptions'
>) => {
  const onColumnChange = (title: string) => updateColumn(value.id, { title })
  const rowSpacesLength = rowsPerColumn - value.rows.length

  return (
    <div className='flex flex-col flex-grow mb-1 flex-nowrap' style={{ minWidth: '20rem' }}>
      <div className={classNames('flex items-center flex-grow px-1 gap-1', className)}>
        <div className='flex-grow'>
          <InputField
            id={`column-input-${value.id}`}
            name={`column-input-${value.id}`}
            value={value.title}
            onChange={event => {
              onColumnChange(event.target.value)
            }}
            placeholder={inputPlaceholder}
            className='shadow-inner'
          />
        </div>

        <TableColumnDropdown
          enableFix={index === 0}
          valueFix={value.lock}
          onAddColumnClick={() => addColumn(index)}
          onFixColumnClick={() => fixColumn(value.id)}
          onRemoveColumnClick={() => removeColumn(value.id)}
          disableRemove={disableRemoveColumns}
        />
      </div>

      <div className='h-0.5 bg-gray-200 mt-1 mb-1' />

      <div className={classNames('flex flex-col px-1', className)}>
        {value.rows.map((row, index) => (
          <Fragment key={row.id}>
            <Row
              entryTypeOptions={entryTypeOptions}
              value={row}
              onAddRowClick={() => addRow(value.id, row.id)}
              onRemoveRowClick={() => removeRow(value.id, row.id)}
              onChange={changes => updateRow(value.id, row.id, changes)}
              disableRemoveRow={value.rows.length === 1}
            />

            {rowsWithOptions.includes(index) &&
              row.type !== TemplateEntryType.MULTIPLE_CHOICE &&
              row.type !== TemplateEntryType.SINGLE_CHOICE && <div style={{ height: '42px' }} />}
          </Fragment>
        ))}

        {rowSpacesLength > 0 &&
          Array.from(new Array(rowSpacesLength)).map((_, index) => (
            <Fragment key={`fill-${index}`}>
              <div style={{ height: '42px' }} />

              {rowsWithOptions.includes(index + value.rows.length) && (
                <div style={{ height: '42px' }} />
              )}
            </Fragment>
          ))}
      </div>
    </div>
  )
}

export const TableEntryField = ({
  onChange,
  value,
  referenceEnabled,
  documentEnabled,
  signatureEnabled,
}: {
  onChange: (columns: TemplateTableEntryColumn[]) => void
  value?: TemplateTableEntryColumn[]

  // Flags
  referenceEnabled?: boolean
  documentEnabled?: boolean
  signatureEnabled?: boolean
}) => {
  const { t } = useTranslation('nsTemplate')

  const entryTypeOptions = useTemplateEntriesSelectorOptions({
    isTable: true,
    referenceEnabled,
    documentEnabled,
    fileEnabled: true,
    signatureEnabled,
  })

  const { columns, ...columnProps } = useTableEntryField({
    initialValue: value,
  })

  const previousColumns = usePrevious(columns)

  useEffect(() => {
    if (previousColumns !== columns) {
      onChange(columns as TemplateTableEntryColumn[])
    }
  }, [columns, previousColumns, onChange])

  return (
    <div className='bg-gray-50 border-solid border-2 border-gray-200 rounded-lg'>
      <div
        className={classNames('flex items-center py-1', {
          'overflow-x-auto': columns.length > 1,
        })}
      >
        {columns.map((value, index) => (
          <Column
            key={value.id}
            index={index}
            inputPlaceholder={t('entries.table.column')}
            entryTypeOptions={entryTypeOptions}
            className={classNames({
              'ml-4': index !== 0,
            })}
            value={value}
            disableRemoveColumns={columns.length === 1}
            {...columnProps}
          />
        ))}
      </div>
    </div>
  )
}
