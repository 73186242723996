import { DirtyIssue, useIssuesByIds } from '@/features/issues'
import { TaskStatus } from '../interfaces'
import {
  EntryCreateInput,
  getDefaultEntry,
  getDefaultEntryFromTemplate,
  prepareEntryBlocksMaps,
  prepareEntryBlocksMapsFromTemplateEntries,
} from '@/features/entries'
import { useCallback, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { useCompanyUser, useUserId } from '@/features/auth'
import { TemplateEntryType, useTemplate, useTemplateByIds } from '@/features/templates'

import {
  useReceptionTaskRelationById,
  useTaskById,
  useTasksByIds,
  useTruTaskRelationsByTaskId,
} from '../hooks'
import { createSelectorOption } from '@blockchain-traceability-sl/tailwind-components'
import { useUserById } from '@/features/users'

export const useInitialTaskData = (
  taskId?: string | null,
  {
    isPreset,
    defaultFormId,
    associationsEnabled,
  }: { defaultFormId?: string | null; isPreset?: boolean; associationsEnabled?: boolean } = {}
) => {
  const userId = useUserId()
  const companyUser = useCompanyUser()

  const {
    task,
    isFetched,
    isLoading: isTaskLoading,
  } = useTaskById(taskId || '', {
    enabled: !!taskId,
  })

  const { truTaskRelations, isLoading: isTruTaskRelationsLoading } = useTruTaskRelationsByTaskId(
    taskId || '',
    {
      enabled: associationsEnabled && isFetched,
    }
  )
  const { receptionTaskRelations, isLoading: isReceptionTaskRelationsLoading } =
    useReceptionTaskRelationById(taskId || '', {
      enabled: associationsEnabled && isFetched,
    })

  const { template, isLoading: isTemplateLoading } = useTemplate(
    defaultFormId || task?.formId || '',
    'form',
    {
      enabled: !!defaultFormId || !!task?.formId,
    }
  )

  const initialEntries = useMemo(() => {
    if (!template || isTaskLoading || isTemplateLoading) return []

    if (task?.entries) {
      return task.entries.map((entry, index): EntryCreateInput => {
        const templateEntry = template.entries[index]
        return getDefaultEntry(
          {
            ...entry,
            values:
              entry.type === TemplateEntryType.ISSUE_REFERENCE && isPreset ? [] : entry.values,
          },
          templateEntry
        ) as EntryCreateInput
      })
    }

    return template.entries.map(entry => getDefaultEntryFromTemplate(entry) as EntryCreateInput)
  }, [task?.entries, template, isPreset, isTaskLoading, isTemplateLoading])

  const issueReferencesWithValue = useMemo(
    () =>
      initialEntries.filter(
        (
          entry
        ): entry is {
          id: string
          name: string
          blockId?: string
          mandatory: boolean
          templateId: string
          type: TemplateEntryType.ISSUE_REFERENCE
          value: string
        } => entry.type === TemplateEntryType.ISSUE_REFERENCE && !!entry.value
      ),
    [initialEntries]
  )

  const issueIds = useMemo(
    () => issueReferencesWithValue.map(entry => entry.value),
    [issueReferencesWithValue]
  )

  const { issues, isLoading: isIssuesLoading } = useIssuesByIds(issueIds, {
    enabled: issueIds.length > 0,
  })

  const initialIssues = useMemo(
    () =>
      issues?.reduce<DirtyIssue[]>((accumulator, issue) => {
        const issueReference = issueReferencesWithValue.find(
          reference => reference.value === issue._id
        )
        if (issueReference) {
          accumulator.push({
            id: issue._id,
            templateId: issue.templateId,
            templateVersion: issue.templateVersion,
            responder: issue.responder,
            taskEntryId: issueReference.id,
            media: issue.media
              ? issue.media.map(media => ({
                  ...media,
                  preview: media.url,
                }))
              : [],
            entries: issue.entries.map((entry): EntryCreateInput => {
              return getDefaultEntry(entry) as EntryCreateInput
            }),
          })
        }

        return accumulator
      }, []) || [],
    [issues, issueReferencesWithValue]
  )

  const documentIds = useMemo(
    () =>
      [...initialEntries, ...initialIssues.flatMap(issue => issue.entries)]
        .filter(
          (
            entry
          ): entry is {
            id: string
            name: string
            blockId?: string
            mandatory: boolean
            type: TemplateEntryType.DOCUMENT
            value: string
          } => entry.type === TemplateEntryType.DOCUMENT
        )
        .map(({ value }) => value as string),
    [initialEntries, initialIssues]
  )

  const assignedToUserId = task?.assignedTo || template?.defaults?.assignedTo || userId

  const { user: assignedToUser, isLoading: isAssignedToUserLoading } = useUserById(
    assignedToUserId,
    {
      enabled: !!assignedToUserId,
    }
  )

  const issueResponderUserId = task?.issueResponder || template?.defaults?.issueResponder || userId

  const { user: issueResponderUser, isLoading: isIssueResponserUserLoading } = useUserById(
    issueResponderUserId,
    {
      enabled: !!issueResponderUserId,
    }
  )

  const blocks = useMemo(() => {
    if (task) {
      return prepareEntryBlocksMaps(task.entries)
    }

    if (template && defaultFormId) {
      return prepareEntryBlocksMapsFromTemplateEntries(template.entries)
    }

    return prepareEntryBlocksMaps([])
  }, [defaultFormId, task, template])

  /**
   * Memoized initial task data to prevent reinitialization on every render
   */
  const initialTask = useMemo(() => {
    const assignedToOption = assignedToUser
      ? createSelectorOption(assignedToUser.name || assignedToUser.auth.email, assignedToUser._id)
      : createSelectorOption(companyUser.name, companyUser._id)

    const issueResponderOption = issueResponderUser
      ? createSelectorOption(
          issueResponderUser.name || issueResponderUser.auth.email,
          issueResponderUser._id
        )
      : createSelectorOption(companyUser.name, companyUser._id)
    return {
      formId: defaultFormId || task?.formId || '',
      dueDate: task?.dueDate ? dayjs(task.dueDate).format('YYYY-MM-DD') : '',
      entries: initialEntries,
      status: task?.status || TaskStatus.OPEN,
      assignedTo: assignedToOption,
      issueResponder: issueResponderOption,
      truIds: task
        ? truTaskRelations.filter(({ taskId }) => taskId === task._id).map(({ truId }) => truId)
        : [],
      receptionIds: task
        ? receptionTaskRelations
            .filter(relation => relation.taskId === task._id)
            .map(r => r.receptionId)
        : [],

      issues: initialIssues,
      blocks,
    }
  }, [
    assignedToUser,
    blocks,
    companyUser._id,
    companyUser.name,
    defaultFormId,
    initialEntries,
    initialIssues,
    issueResponderUser,
    receptionTaskRelations,
    task,
    truTaskRelations,
  ])
  return {
    isLoading:
      isTaskLoading ||
      isTemplateLoading ||
      isIssuesLoading ||
      isAssignedToUserLoading ||
      isIssueResponserUserLoading ||
      isTruTaskRelationsLoading ||
      isReceptionTaskRelationsLoading,
    template,
    task,
    issues,
    truTaskRelations,
    receptionTaskRelations,

    documentIds,

    initialTask,
  }
}

export const useTaskBulkSelection = ({
  tasks,
}: {
  tasks: {
    id: string
    isPendingToValidate: boolean
    isPendingToValidateByMe: boolean
    isPending: boolean
  }[]
}) => {
  const [selectedTasks, setSelectedTasks] = useState<
    {
      id: string
      isPendingToValidate: boolean
      isPendingToValidateByMe: boolean
      isPending: boolean
    }[]
  >([])

  const selectedTaskIds = useMemo(() => selectedTasks.map(({ id }) => id), [selectedTasks])
  const selectedTaskIdsToValidate = useMemo(
    () => selectedTasks.filter(item => item.isPendingToValidateByMe).map(({ id }) => id),
    [selectedTasks]
  )
  const selectedTaskIdsToComplete = useMemo(
    () => selectedTasks.filter(item => !item.isPendingToValidate).map(({ id }) => id),
    [selectedTasks]
  )
  const selectedTaskIdsToClose = useMemo(
    () => selectedTasks.filter(item => item.isPending).map(({ id }) => id),
    [selectedTasks]
  )

  const allTaskPageSelected = useMemo(
    () => tasks.map(item => item.id).every(taskId => selectedTaskIds.includes(taskId)),
    [tasks, selectedTaskIds]
  )

  const toggleSelectedTask = useCallback(
    (taskId: string, checked: boolean) => {
      const task = tasks.find(item => item.id === taskId)
      if (checked && task) {
        setSelectedTasks(previous => [...previous, task])
      } else {
        setSelectedTasks(previous => previous.filter(previousTask => previousTask.id !== taskId))
      }
    },
    [tasks]
  )

  const toggleAllTasksInPage = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectedTasks(previous => Array.from(new Set([...previous, ...tasks])))
      } else {
        setSelectedTasks([])
      }
    },
    [tasks]
  )

  return {
    allTaskPageSelected,
    selectedTaskIds,
    selectedTaskIdsToValidate,
    selectedTaskIdsToComplete,
    selectedTaskIdsToClose,
    disableToggle: tasks.length === 0,
    toggleSelectedTask,
    toggleAllTasksInPage,
  }
}

export const useInitialTaskBulkData = (taskIds?: string[], options: { enabled?: boolean } = {}) => {
  const { tasks, isLoading: isTasksLoading } = useTasksByIds(taskIds, {
    enabled: options.enabled,
  })

  const templateIds = useMemo(() => {
    if (tasks.length === 0) return []
    return Array.from(new Set(tasks.map(task => task.formId)))
  }, [tasks])

  const { templates, isLoading: isTemplatesLoading } = useTemplateByIds(templateIds, 'form', {
    enabled: templateIds.length > 0,
  })

  const initialTaskUpdates = useMemo(() => {
    if (isTasksLoading || isTemplatesLoading) return []

    return tasks.map(task => {
      const template = templates?.find(template => template._id === task.formId)
      return {
        id: task._id,
        formName: template?.name,
        entries: task.entries.map(entry => getDefaultEntry(entry) as EntryCreateInput),
        issueResponder: task.issueResponder || template?.defaults?.issueResponder,
        blocks: prepareEntryBlocksMaps(task.entries),
      }
    })
  }, [tasks, isTasksLoading, templates, isTemplatesLoading])

  const issueIdAndTaskIds = useMemo(
    () =>
      initialTaskUpdates.flatMap(task =>
        task.entries
          .filter(
            (
              entry
            ): entry is {
              id: string
              name: string
              blockId?: string
              mandatory: boolean
              templateId: string
              type: TemplateEntryType.ISSUE_REFERENCE
              value: string
            } => entry.type === TemplateEntryType.ISSUE_REFERENCE && !!entry.value
          )
          .map(entry => ({
            issueId: entry.value,
            taskId: task.id,
            taskEntryId: entry.id,
          }))
      ),
    [initialTaskUpdates]
  )

  const issueIds = useMemo(() => issueIdAndTaskIds.map(entry => entry.issueId), [issueIdAndTaskIds])

  const { issues, isLoading: isIssuesLoading } = useIssuesByIds(issueIds, {
    enabled: issueIds.length > 0,
  })

  const initialIssues = useMemo(
    () =>
      issues?.reduce<(DirtyIssue & { taskId: string })[]>((accumulator, issue) => {
        const relation = issueIdAndTaskIds.find(reference => reference.issueId === issue._id)

        if (relation) {
          accumulator.push({
            id: issue._id,
            templateId: issue.templateId,
            templateVersion: issue.templateVersion,
            responder: issue.responder,
            taskId: relation.taskId,
            taskEntryId: relation.taskEntryId,
            entries: issue.entries.map((entry): EntryCreateInput => {
              return getDefaultEntry(entry) as EntryCreateInput
            }),
          })
        }

        return accumulator
      }, []) || [],
    [issues, issueIdAndTaskIds]
  )

  const documentIds = useMemo(
    () =>
      [
        ...initialTaskUpdates.flatMap(task => task.entries),
        ...initialIssues.flatMap(issue => issue.entries),
      ]
        .filter(
          (
            entry
          ): entry is {
            id: string
            name: string
            blockId?: string
            mandatory: boolean
            type: TemplateEntryType.DOCUMENT
            value: string
          } => entry.type === TemplateEntryType.DOCUMENT
        )
        .map(({ value }) => value as string),
    [initialTaskUpdates, initialIssues]
  )

  const initialTasks = useMemo(
    () =>
      initialTaskUpdates.map(task => ({
        ...task,
        issues: initialIssues.filter(issue => issue.taskId === task.id),
      })),
    [initialIssues, initialTaskUpdates]
  )

  return {
    isLoading: isTasksLoading || isTemplatesLoading || isIssuesLoading,
    templates,
    tasks,
    issues,

    documentIds,

    initialTasks,
  }
}
