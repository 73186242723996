import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  SelectField,
  SelectFieldProps,
  SelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { useState } from 'react'
import { useInfiniteFacilities } from '../hooks'

export const FacilitiesSelectField = (props: Omit<SelectFieldProps, 'options'>) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const { isLoading, facilities, fetchNextPage } = useInfiniteFacilities({
    search: debouncedSearch,
  })

  const facilitiesOptions: SelectorOption[] = facilities.map(facility => ({
    label: facility.name,
    value: facility._id,
  }))

  return (
    <SelectField
      {...props}
      isLoading={isLoading}
      options={facilitiesOptions}
      isClearable
      onInputChange={inputValue => {
        setSearch(inputValue)
      }}
      onMenuScrollToBottom={() => {
        fetchNextPage()
      }}
    />
  )
}
