/**
 * Transform a Blob to their dataUrl representation
 *
 * @param blob Blob to transform to dataUrl
 * @param callback Function to invoque on success
 */
export const blobToDataURL = async (blob: Blob, callback: (dataUrl: string) => void) => {
  const fileReader = new FileReader()

  fileReader.onload = event => {
    // eslint-disable-next-line node/no-callback-literal
    if (event.target) callback(event.target.result as string)
  }

  fileReader.readAsDataURL(blob)
}

/**
 * Transform a Blob to their dataUrl representation
 *
 * @param blob Blob to transform to dataUrl
 * @returns The dataUrl
 */
export const asyncBlobToDataURL = async (blob: Blob): Promise<string> => {
  return new Promise(resolve => {
    blobToDataURL(blob, resolve)
  })
}
