import { useMyCompany, useMyCompanyKnownAs } from '@/features/company'
import { useEffect, useReducer, useRef } from 'react'
import { User as AuthUser, onAuthStateChanged } from 'firebase/auth'
import i18n from '@/i18n'
import { ErrorReporterService } from '@/services/error-reporter.service'
import { useEnsureSSOUser, useMyProfile } from '../hooks'
import { useAnalytics } from '@/features/analytics'
import { auth } from '@/app/firebase'
import { addTokenToRequests, removeTokenFromRequests } from '../utils'
import { sentrySetUser } from '@/app/sentry'

export const useUserDataFirstLoad = ({ enabled }: { enabled: boolean }) => {
  const analytics = useAnalytics()
  const {
    isSuccess: isMyProfileSuccess,
    profile,
    isLoading: isProfileLoading,
  } = useMyProfile({
    enabled,
  })

  const companyDataLoadEnabled = enabled && !!profile?.active
  const { isSuccess: isMyCompanySuccess, isLoading: isMyCompanyLoading } = useMyCompany({
    enabled: companyDataLoadEnabled,
  })

  const { isSuccess: isMyCompanyKnownAsSuccess, isLoading: isMyCompanyKnownAsLoading } =
    useMyCompanyKnownAs({
      enabled: companyDataLoadEnabled,
    })

  const firstLoadRef = useRef(false)

  useEffect(() => {
    if (isMyProfileSuccess && profile && !firstLoadRef.current) {
      firstLoadRef.current = true

      const { user, company } = profile

      analytics.identify(user.id || company.id, {
        name: company.name,
        email: user.email,
      })

      ErrorReporterService.setUser(company.id)
      sentrySetUser(user.id || company.id)

      if (!i18n.language.startsWith(user.language)) {
        i18n.changeLanguage(user.language)
      }
    }
  }, [analytics, isMyProfileSuccess, profile])

  let isLoading = isProfileLoading

  if (profile?.active) {
    isLoading = isMyCompanyLoading || isMyCompanyKnownAsLoading
  }

  return {
    isLoading,
    isProfileLoaded: isMyProfileSuccess,
    isUserDataLoadedSuccessfully:
      isMyCompanySuccess && isMyProfileSuccess && isMyCompanyKnownAsSuccess,
    isActivated: !!profile?.active,
  }
}

interface AuthState {
  isLoading: boolean
  isAuthenticated: boolean
  authUser?: AuthUser | null
}

enum AuthActionType {
  SET_USER,
  AUTHENTICATED,
  UNAUTHENTICATED,
}

interface AuthAction {
  type: AuthActionType
  authUser?: AuthUser | null
}

function authStateReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionType.SET_USER:
      return { ...state, authUser: action.authUser }

    case AuthActionType.AUTHENTICATED:
      return { ...state, isLoading: false, isAuthenticated: true }

    case AuthActionType.UNAUTHENTICATED:
      return { ...state, isLoading: false, authUser: null, isAuthenticated: false }

    default:
      return state
  }
}

export const useAuthState = () => {
  const analytics = useAnalytics()
  const [state, dispatch] = useReducer(authStateReducer, {
    isLoading: true,
    isAuthenticated: false,
    authUser: null,
  })

  const { ensureSSOUser } = useEnsureSSOUser()
  const ensuredSSOUserMutexRef = useRef<boolean>(false)

  useEffect(() => {
    let tokenInterceptorId: number | null = null
    const unsubscribe = onAuthStateChanged(
      auth,
      user => {
        if (!user) {
          removeTokenFromRequests(tokenInterceptorId)
          dispatch({
            type: AuthActionType.UNAUTHENTICATED,
          })
          return
        }

        tokenInterceptorId = addTokenToRequests(user)
        dispatch({
          type: AuthActionType.SET_USER,
          authUser: user,
        })

        const [provider] = user.providerData

        const isPasswordUser = provider.providerId === 'password'

        if (provider.providerId === 'password' || ensuredSSOUserMutexRef.current) {
          dispatch({
            type: AuthActionType.AUTHENTICATED,
          })
          analytics.track('CUSTOMER_LOGIN_PLATFORM', {
            Via: isPasswordUser ? 'Form' : 'SSO',
          })
        } else {
          ensuredSSOUserMutexRef.current = true
          ensureSSOUser(undefined, {
            onSuccess() {
              ensuredSSOUserMutexRef.current = false
              dispatch({
                type: AuthActionType.AUTHENTICATED,
              })
              analytics.track('CUSTOMER_LOGIN_PLATFORM', {
                Via: 'SSO',
              })
            },
            onError() {
              ensuredSSOUserMutexRef.current = false
              analytics.track('CUSTOMER_LOGIN_ERROR', {
                Via: 'SSO',
              })
            },
          })
        }
      },
      () => {
        analytics.track('CUSTOMER_LOGIN_ERROR', {
          Via: 'Form',
        })
        removeTokenFromRequests(tokenInterceptorId)
      }
    )

    return () => {
      removeTokenFromRequests(tokenInterceptorId)
      unsubscribe()
    }
  }, [analytics, ensureSSOUser])

  return state
}
