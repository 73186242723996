import i18n from '@/i18n'
import { Document } from '@/features/documents'
import { Metadata } from '@/features/metadatas'
import { TemplateEntryReferenceValue, TemplateEntryType } from '@/features/templates'
import { TraceabilityErrorCodes } from '@/features/traceabilities'
import dayjs from 'dayjs'
import { isValidHttpUrl } from '@/utils/is-valid-http-url'
import { CustomReportTemplate, CustomReportTemplateType } from './interfaces'
import { StakeHolder } from '../stakeHolders'

export const EMPTY_VALUE = '-'

/**
 * Parse metadata to be representable in report
 */
export const getEntityMetadata = (
  metadata: Metadata[] | TraceabilityErrorCodes.METADATA_SERVICE_UNAVAILABLE | undefined,
  documents: Document[]
) => {
  return (Array.isArray(metadata) ? metadata : []).flatMap(({ data }) =>
    (data || []).flatMap(({ key, value, values, type }) => {
      switch (type) {
        case TemplateEntryType.DOCUMENT: {
          const metadataDocument = documents.find(({ _id }) => _id === value)
          return metadataDocument && metadataDocument.publicUrl
            ? {
                text: [
                  `${key}: `,
                  {
                    text: i18n.t('nsPDFReport:metadata.document'),
                    link: metadataDocument.publicUrl,
                    style: 'link',
                  },
                ],
              }
            : `${key}: ${EMPTY_VALUE}`
        }
        case TemplateEntryType.MULTIPLE_CHOICE: {
          return `${key}: ${values.join(', ')}`
        }
        case TemplateEntryType.BOOLEAN:
          return `${key}: ${
            !!value && value === 'true'
              ? i18n.t('nsPDFReport:metadata.boolean.true')
              : i18n.t('nsPDFReport:metadata.boolean.false')
          }`
        case TemplateEntryType.NUMBER:
          return `${key}: ${i18n.t('number', {
            value,
          })}`
        case TemplateEntryType.REFERENCE:
          return `${key}: ${(values as TemplateEntryReferenceValue[])
            .map(({ name }) => name)
            .join(', ')}`

        case TemplateEntryType.DATE:
          return `${key}: ${value ? dayjs(value).format('DD/MM/YYYY') : ''}` // We use the non localized format here for compatibility with the Arabic language
        default:
          return isValidHttpUrl(value)
            ? {
                text: [
                  `${key}: `,
                  {
                    text: i18n.t('nsPDFReport:metadata.link'),
                    link: value,
                    style: 'link',
                  },
                ],
              }
            : `${key}: ${value || EMPTY_VALUE}`
      }
    })
  )
}

export const getCustomReportFilename = (
  customReportTemplate: CustomReportTemplate,
  {
    source,
    relations,
  }: {
    source: { id: string; name: string; createdAt: string }
    relations: { stakeHolder?: StakeHolder }
  }
) => {
  if (
    customReportTemplate.type === CustomReportTemplateType.COMPLIANCE_REPORT &&
    relations.stakeHolder
  ) {
    return `${source.name}_${relations.stakeHolder?.company.name}_${dayjs(source.createdAt).format(
      'L'
    )}.pdf`
  }

  return `${source.name}_${dayjs(source.createdAt).format('L')}`
}
