import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'
import { FC } from 'react'
import { useAuthentication } from '@/features/auth'
import { RedirectBasedOnSubscription } from '@/features/pricing'

export interface PrivateRouteProps extends RouteProps {
  component: FC<RouteComponentProps>
  redirectPath: string
}

export const PrivateRoute = ({
  component: Component,
  redirectPath,
  ...rest
}: PrivateRouteProps): JSX.Element => {
  const { isActivated, isAuthenticated, isActivationLoading } = useAuthentication()

  return (
    <Route
      {...rest}
      render={props => {
        if (isActivationLoading) {
          return null
        }
        if (!isAuthenticated) {
          return <Redirect to={`/login?redirect=${encodeURIComponent(redirectPath)}`} />
        }

        if (!isActivated) {
          return <RedirectBasedOnSubscription />
        }
        return <Component {...props} />
      }}
    />
  )
}
