import { TemplateEntryType } from '@/features/templates'
import { DownloaderMIMEType, downloader } from '@/helpers/downloader'
import mime from 'mime'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { FileUploadResponse, uploadFile as defaultUploadFile } from '@/features/files'
import { Entry, EntryCreateInput } from './interfaces'
/**
 * TODO: use a file specific functions
 */
import {
  downloadTaskFile as defaultDownloadFile,
  getPublicUrlTaskFile as defaultGetPublicUrlFile,
} from '@/features/tasks'

export const PREVIEW_FILES_QUERY_KEY = 'preview-files'

export interface UseFileEntryHandlerResult {
  filePreviewUrls: Record<string, string | null>
  handleFileSelection: (file: File, field: string, entry: EntryCreateInput) => Promise<void>
  handleTableFileSelection: (file: File, callback: (filepath: string) => void) => Promise<void>
  downloadFile: (dirtyEntry: EntryCreateInput) => Promise<void>
  changeFile: (field: string, entry: EntryCreateInput) => void
}

export interface UseFileEntryHandlerProps {
  id?: string
  entries?: Entry[]
  issues?: { entries: Entry[] }[]

  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => unknown
  setFieldTouched: (
    field: string,
    touched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void

  uploadFile?: (file: File) => Promise<FileUploadResponse>
  getPublicUrl?: (filename: string) => Promise<string | void>
  downloadFile?: (filename: string, mimeType: DownloaderMIMEType) => Promise<void>
  enabled?: boolean
}

export const useFileEntryHandler = ({
  id,
  entries,
  issues,
  setFieldValue,
  setFieldTouched,
  uploadFile = defaultUploadFile,
  getPublicUrl = defaultGetPublicUrlFile,
  downloadFile = defaultDownloadFile,
  enabled,
}: UseFileEntryHandlerProps): UseFileEntryHandlerResult => {
  const [filePreviewUrls, setFilePreviewUrls] = useState<Record<string, string | null>>({})

  const fileFromEntries = useMemo(() => {
    const issueEntries = issues?.flatMap(({ entries }) => entries) || []
    const allEntries = [...(entries || []), ...issueEntries]

    return allEntries
      .filter(entry => entry?.type === TemplateEntryType.FILE && !!entry.values[0])
      .map(({ id, values }) => ({ id, value: values[0] as string }))
  }, [entries, issues])

  const { data: initialFilePreviewUrls, isFetched } = useQuery(
    [PREVIEW_FILES_QUERY_KEY, { id, entryIds: fileFromEntries.map(entry => entry.id) }],
    async () => {
      const urlPromises = fileFromEntries.map(async ({ id, value }) => {
        const previewUrl = await getPublicUrl(value)
        return {
          id,
          previewUrl,
        }
      })

      const results = await Promise.all(urlPromises)

      return results.reduce<Record<string, string | null>>((accumulator, { id, previewUrl }) => {
        accumulator[id] = previewUrl || null

        return accumulator
      }, {})
    },
    {
      enabled:
        enabled !== undefined
          ? enabled && !!id && fileFromEntries.length > 0
          : !!id && fileFromEntries.length > 0,
    }
  )

  useEffect(() => {
    if (isFetched) {
      setFilePreviewUrls(previous => ({
        ...previous,
        ...initialFilePreviewUrls,
      }))
    }
  }, [initialFilePreviewUrls, isFetched])

  const updateFilePreviewUrl = useCallback((entryId: string, previewUrl: string | null = null) => {
    setFilePreviewUrls(previous => ({
      ...previous,
      [entryId]: previewUrl,
    }))
  }, [])

  const handleFileSelection = useCallback(
    async (file: File, fieldName: string, entry: EntryCreateInput) => {
      if (file.type.includes('image')) {
        const reader = new FileReader()
        reader.onload = () => {
          updateFilePreviewUrl(entry.id, reader.result as string)
        }
        reader.readAsDataURL(file)
      }
      const response = await uploadFile(file)
      setFieldValue(fieldName, {
        ...entry,
        file: file || null,
        mimeType: file.type || null,
        value: response.path,
      })
      setFieldTouched(`${fieldName}.value`, true)
    },
    [setFieldTouched, setFieldValue, updateFilePreviewUrl, uploadFile]
  )

  const downloadFileFromDirtyEntry = useCallback(
    async (dirtyEntry: EntryCreateInput) => {
      if (dirtyEntry.type !== TemplateEntryType.FILE) return
      const file = dirtyEntry.file as File
      if (file && typeof file.name !== 'undefined' && file.name !== '') {
        const extension = mime.getExtension(file.type as string)
        const fileExtension = extension ? `.${extension}` : ''
        const filenameWithoutExtension = file.name.replace(fileExtension, '')
        downloader(file, file.type as DownloaderMIMEType, filenameWithoutExtension)
      } else {
        const filename = dirtyEntry.value as string
        await downloadFile(filename, dirtyEntry.mimeType as DownloaderMIMEType)
      }
    },
    [downloadFile]
  )

  const changeFile = useCallback(
    (field: string, entry: EntryCreateInput) => {
      setFieldValue(field, {
        ...entry,
        file: null,
        mimeType: null,
        value: null,
      })
      updateFilePreviewUrl(entry.id)
    },
    [setFieldValue, updateFilePreviewUrl]
  )

  const handleTableFileSelection = useCallback(
    async (file: File, callback: (filepath: string) => void) => {
      const response = await uploadFile(file)
      callback(response.path)
    },
    [uploadFile]
  )

  return useMemo(
    () => ({
      filePreviewUrls,
      handleFileSelection,
      handleTableFileSelection,
      downloadFile: downloadFileFromDirtyEntry,
      changeFile,
    }),
    [
      changeFile,
      downloadFileFromDirtyEntry,
      filePreviewUrls,
      handleFileSelection,
      handleTableFileSelection,
    ]
  )
}
