import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  SortableContext,
  SortableContextProps,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { memo, useCallback, useMemo, useState } from 'react'

/**
 * Sortable Items must have the property id as string to idenfy it
 */
export type SortableContextItem = { id: string }

export interface SortableContainerProps extends SortableContextProps {
  /**
   * Array of items to sort
   */
  items: SortableContextItem[]

  /**
   * Sort handler with new array order
   */
  onSort: (items: SortableContextItem[]) => void
}

export const SortableContainer = memo(({ onSort, items, ...props }: SortableContainerProps) => {
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor),
    useSensor(TouchSensor)
  )

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const getIndex = useCallback(
    (itemId: UniqueIdentifier) => items.findIndex(item => item.id === itemId),
    [items]
  )
  const activeIndex = useMemo(() => (activeId ? getIndex(activeId) : -1), [activeId, getIndex])

  const handleDragStart = useCallback(({ active }: DragStartEvent) => {
    if (active) {
      setActiveId(active.id)
    }
  }, [])

  const handleDragEnd = useCallback(
    ({ over }: DragEndEvent) => {
      setActiveId(null)

      if (over) {
        const overIndex = getIndex(over.id)
        if (activeIndex !== overIndex) {
          const newItemsOrder = arrayMove(items, activeIndex, overIndex)
          onSort(newItemsOrder)
        }
      }
    },
    [activeIndex, getIndex, items, onSort]
  )

  const handleDragCancel = useCallback(() => {
    setActiveId(null)
  }, [])

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
    >
      <SortableContext {...props} items={items} strategy={verticalListSortingStrategy} />
    </DndContext>
  )
})
