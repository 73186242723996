import { SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { useCallback, useEffect, useState } from 'react'
import { useEventListener } from './use-event-listener'

export const useBreakpoint = (): SIZE | null => {
  const [breakpoint, setBreakPoint] = useState<SIZE | null>(null)

  const handleResize = useCallback(() => {
    if (window.innerWidth > 0 && window.innerWidth <= 640) {
      setBreakPoint(SIZE.SMALL)
    }
    if (window.innerWidth > 640 && window.innerWidth <= 768) {
      setBreakPoint(SIZE.MEDIUM)
    }
    if (window.innerWidth > 768 && window.innerWidth <= 1024) {
      setBreakPoint(SIZE.LARGE)
    }
    if (window.innerWidth > 1024) {
      setBreakPoint(SIZE.EXTRA_LARGE)
    }
  }, [])

  useEffect(() => {
    handleResize()
  }, [handleResize])

  useEventListener('resize', handleResize)
  return breakpoint
}

export default useBreakpoint
