import { api } from '@/app/api'
import {
  Document,
  DocumentFile,
  DocumentFileCreation,
  DocumentFilters,
  DocumentFolder,
  DocumentFolderCreation,
  DocumentType,
  DocumentUpdate,
} from './interfaces'
import { Paginated } from '@/hooks/use-pagination'

/**
 * Define the url path
 * @constant
 */
export const DOCUMENTS_URL = '/documents/documents'

/**
 * Get all documents
 *
 * @returns All documents
 */
export const getAll = async (filters: Partial<DocumentFilters>): Promise<Document[]> => {
  const { data: documents } = await api.get<Document[] | void>(`${DOCUMENTS_URL}`, {
    params: filters,
  })
  return documents || []
}

/**
 * Get a document by _id
 *
 * @returns Document
 */
export const getById = async (documentId: string): Promise<Document | void> => {
  const { data: document } = await api.get<Document | void>(
    `${DOCUMENTS_URL}/${encodeURIComponent(documentId)}`
  )
  return document
}

/**
 * Get a public document by _id
 *
 * @returns Document
 */
export const getPublicById = async (documentId: string): Promise<Document> => {
  const { data: document } = await api.get<Document>(
    `${DOCUMENTS_URL}/public/${encodeURIComponent(documentId)}`
  )
  return document
}

/**
 * Get all documents count
 *
 * @returns All documents count
 */
export const getCount = async (): Promise<number> => {
  const { data: documentsCount } = await api.get<number>(`${DOCUMENTS_URL}/count`)
  return documentsCount
}

/**
 * Get all documents with tru association id
 *
 * @returns All documents with tru association id
 */
export const getAllWithTRUAssociationId = async (
  truAssociationId: string
): Promise<DocumentFile[]> => {
  const { data: documents } = await api.get<DocumentFile[] | void>(
    `${DOCUMENTS_URL}/association/tru/${encodeURIComponent(truAssociationId)}`
  )
  return documents || []
}

/**
 * Get all documents shared with me
 *
 * @returns All documents
 */
export const getAllSharedWithMe = async (): Promise<Document[]> => {
  const { data: documents } = await api.get<Document[] | void>(`${DOCUMENTS_URL}/shared`)
  return documents || []
}

/**
 * Get all documents shared with id
 *
 * @returns All documents
 */
export const getAllSharedWith = async (ids: string[]): Promise<Document[]> => {
  if (ids.length === 0) return []
  const { data: documents } = await api.post<Document[] | void>(`${DOCUMENTS_URL}/shared/ids`, {
    ids,
  })
  return documents || []
}

/**
 * Get all documents shared with me with tru association id
 *
 * @returns All documents shared with me with tru association id
 */
export const getAllSharedWithMeWithTRUAssociationId = async (
  truAssociationId: string
): Promise<DocumentFile[]> => {
  const { data: documents } = await api.get<DocumentFile[] | void>(
    `${DOCUMENTS_URL}/shared/association/tru/${encodeURIComponent(truAssociationId)}`
  )
  return documents || []
}

/**
 * Get all documents shared with me with tru association id
 *
 * @returns All documents shared with me with tru association id
 */
export const getDocumentTree = async (documentId: string): Promise<DocumentFolder[]> => {
  const { data: documents } = await api.get<DocumentFolder[] | void>(
    `${DOCUMENTS_URL}/tree/${encodeURIComponent(documentId)}`
  )
  return documents || []
}

/**
 *
 * @param ids
 * @returns All documents matching ids
 */
export const getDocumentsByIds = async (ids: string[]): Promise<Document[]> => {
  const { data: documents } = await api.post<Document[] | void>(`${DOCUMENTS_URL}/ids`, { ids })
  return documents || []
}

/**
 * Get all documents shared with me
 *
 * @returns All documents
 */
export const getAllBySearch = async (
  filters: Partial<DocumentFilters>,
  pagination: { limit: number; offset: number }
): Promise<Paginated<Document>> => {
  const { data: documents } = await api.get<Paginated<Document>>(`${DOCUMENTS_URL}/filter`, {
    params: { ...filters, ...pagination },
  })
  return documents
}

/**
 * Find documents using filters
 * @param filters
 * @returns documents
 */
export const getByFilters = async (filters: Partial<DocumentFilters>): Promise<Document[]> => {
  const { data: documents } = await api.get<Document[] | void>(`${DOCUMENTS_URL}/filter`, {
    params: filters,
  })
  return documents || []
}

/**
 * Get a file content
 *
 * @returns blob
 */
export const getFileContent = async (filePath: string): Promise<Blob | void> => {
  const { data: fileBlob } = await api.get<Blob | void>(
    `${DOCUMENTS_URL}/file?path=${encodeURIComponent(filePath)}`,
    {
      responseType: 'blob',
    }
  )
  return fileBlob
}

/**
 * Create a folder
 *
 * @param folder new folder to create
 */
export const createFolder = async (folder: DocumentFolderCreation): Promise<string> => {
  const { data: folderId } = await api.post<string>(DOCUMENTS_URL, folder)
  return folderId
}

/**
 * Create a file
 *
 * @param file new file to create
 */
export const createFile = async (file: DocumentFileCreation): Promise<void> => {
  await api.post<void>(DOCUMENTS_URL, file)
}

/**
 * Ensure folder creation or return existing folder id
 * @returns folder id
 */
export const upsertFolder = async (creation: Omit<DocumentFolderCreation, 'type'>) => {
  const { specialFolderType, title } = creation
  const folderResults = await getByFilters({
    type: DocumentType.FOLDER,
    parentId: creation.parentId,
    specialFolderType,
    title,
  })

  if (folderResults && folderResults.length > 0) {
    return folderResults[0]._id
  }
  return createFolder({
    ...creation,
    type: DocumentType.FOLDER,
  })
}

/**
 * Update a document
 *
 * @param documentId - Document id to update
 * @param document - Document changes
 */
export const updateDocumentFile = async (
  documentId: string,
  document: DocumentUpdate
): Promise<void> => {
  await api.patch<void>(`${DOCUMENTS_URL}/${documentId}`, document)
}

/**
 * Remove a document
 *
 * @param documentId - Document id to remove
 */
export const remove = async (documentId: string): Promise<void> => {
  await api.delete<void>(`${DOCUMENTS_URL}/${documentId}`)
}

/**
 * Make public a document
 *
 * @param documentId - Document id to make public
 */
export const makePublic = async (documentId: string): Promise<Document> => {
  const { data: document } = await api.patch<Document>(`${DOCUMENTS_URL}/${documentId}/makePublic`)

  return document
}

export const getAllTags = async (): Promise<string[]> => {
  const { data: tags } = await api.get<string[]>(`${DOCUMENTS_URL}/tags`)
  return tags
}
