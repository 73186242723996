import { useContext } from 'react'
import { entryFieldComponentContext } from './context'
import { TemplateEntryType } from '@/features/templates'

export const useEntryFieldComponentByType = (type: TemplateEntryType) => {
  const { components } = useContext(entryFieldComponentContext)
  return components[type]
}

export const useEntryFieldWrapper = () => {
  const { wrapper } = useContext(entryFieldComponentContext)
  return wrapper
}
