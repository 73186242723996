import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query'
import { customErrorToTrack, useAnalytics } from '../analytics'
import { confirmRequest, create, getAll, getByPublicId, uploadPublicFile } from './service'
import * as NotifyService from '@/services/notify.service'
import { UploadRequest, UploadRequestCreate, UploadRequestFilters } from './interfaces'
import { useMemo } from 'react'

export const QUERY_UPLOAD_REQUESTS_KEY = 'uploadRequest'

export const useCreateUploadRequest = (analyticsPayload: {
  From: 'Home' | 'Document management'
}) => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const queryClient = useQueryClient()

  const { mutate, ...rest } = useMutation(create, {
    onSuccess() {
      analytics.track('CUSTOMER_SEND_DOCUMENTS_REQUEST', analyticsPayload)
      NotifyService.success({
        title: t('uploadRequest.create.title'),
        description: t('uploadRequest.create.description'),
      })
      queryClient.invalidateQueries(QUERY_UPLOAD_REQUESTS_KEY)
    },
    onError(error: AxiosError) {
      analytics.track('CUSTOMER_SEND_DOCUMENTS_REQUEST_ERROR', {
        ...analyticsPayload,
        ...customErrorToTrack(error.response?.data, error.response?.status),
      })
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return {
    ...rest,
    createUploadRequest: mutate,
  }
}

export const useCreateUploadRequests = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const queryClient = useQueryClient()

  const { mutate, ...rest } = useMutation(
    (uploadRequests: UploadRequestCreate[]) =>
      Promise.all(uploadRequests.map(uploadRequest => create(uploadRequest))),
    {
      onSuccess() {
        analytics.track('CUSTOMER_SEND_ALL_DOCUMENTS_REQUEST')
        NotifyService.success({
          title: t('uploadRequest.create.title'),
          description: t('uploadRequest.create.description'),
        })
        queryClient.invalidateQueries(QUERY_UPLOAD_REQUESTS_KEY)
      },
      onError(error: AxiosError) {
        analytics.track('CUSTOMER_SEND_ALL_DOCUMENTS_REQUEST_ERROR')
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return {
    ...rest,
    createUploadRequests: mutate,
  }
}

export const usePublicUploadRequest = (publicId: string) => {
  const { data: uploadRequest, ...rest } = useQuery(
    [QUERY_UPLOAD_REQUESTS_KEY, publicId, { public: true }],
    () => getByPublicId(publicId)
  )

  return {
    ...rest,
    uploadRequest,
  }
}

export const useUploadRequests = (
  filters?: UploadRequestFilters,
  options?: Omit<UseQueryOptions<UploadRequest[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data: uploadRequests, ...rest } = useQuery(
    [QUERY_UPLOAD_REQUESTS_KEY, filters],
    () => getAll(filters),
    options
  )

  return {
    ...rest,
    uploadRequests: useMemo(() => uploadRequests || [], [uploadRequests]),
  }
}

export const useUploadRequestFile = (publicId: string) => {
  const { mutate: uploadRequestFiles, ...rest } = useMutation((files: File[]) =>
    Promise.all(files.map(file => uploadPublicFile(publicId, file)))
  )

  return {
    ...rest,
    uploadRequestFiles,
  }
}

export const useConfirmUploadRequest = (publicId: string) => {
  const { mutate: confirmUploadRequest, ...rest } = useMutation(
    (
      files: {
        fileName: string
        mimeType: string
        path: string
        expireAt?: Date
      }[]
    ) => confirmRequest(publicId, files)
  )

  return {
    ...rest,
    confirmUploadRequest,
  }
}
