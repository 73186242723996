import { useId } from '@/hooks/use-id'

export const generateEntryFieldName = (index: number, prefix?: string) => {
  return `${prefix ? `${prefix}.` : ''}entries.${index}`
}

/**
 * Generates names and unique id for entry fields
 * @param index Entry index
 * @param prefix Optional prefix for the field names
 * @returns Object with field names and ids
 */
export const useEntryFieldAttributes = (index: number, prefix?: string) => {
  const randomId = useId()
  const entryFieldName = generateEntryFieldName(index, prefix)

  const valueFieldName = `${entryFieldName}.value`
  const valuesFieldName = `${entryFieldName}.values`
  const enabledFieldName = `${entryFieldName}.enabled`

  const entryFieldId = `${randomId}_${entryFieldName.replaceAll('.', '_')}`
  const valueFieldId = `${randomId}_${valueFieldName.replaceAll('.', '_')}`
  const valuesFieldId = `${randomId}_${valuesFieldName.replaceAll('.', '_')}`
  const enabledFieldId = `${randomId}_${enabledFieldName.replaceAll('.', '_')}`

  return {
    entryFieldName,
    valueFieldName,
    valuesFieldName,
    enabledFieldName,

    entryFieldId,
    enabledFieldId,
    valueFieldId,
    valuesFieldId,
  }
}
