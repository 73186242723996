import { useProductById } from '@/features/products'
import { useStakeHolderProductById } from '@/features/stakeHolderProducts'
import { useStakeHolderById } from '@/features/stakeHolders'
import { TRUCreationOrUpdateOriginInput } from '../../interfaces'
import { useMyCompany } from '@/features/company'

interface UseTruOriginRowDataProps {
  /**
   * Could represent a Product or a StakeholderProduct
   */
  entityId: string
}

interface UseTruOriginRowDataResult
  extends Pick<TRUCreationOrUpdateOriginInput, 'product' | 'stakeHolder'> {
  isLoading: boolean
  type: 'product' | 'stakeholderProduct'
}

export const useTruOriginRowData = ({
  entityId,
}: UseTruOriginRowDataProps): UseTruOriginRowDataResult => {
  const { company } = useMyCompany()

  const { product, isLoading: isLoadingProduct } = useProductById(entityId)
  const { stakeHolderProduct, isLoading: isLoadingStakeholderProduct } =
    useStakeHolderProductById(entityId)

  const { stakeHolder, isLoading: isLoadingStakeholder } = useStakeHolderById(
    stakeHolderProduct?.stakeHolder.id || '',
    {
      enabled: !!stakeHolderProduct,
    }
  )

  const isLoading = isLoadingProduct || isLoadingStakeholderProduct || isLoadingStakeholder

  return {
    isLoading,
    stakeHolder: {
      id: stakeHolder?._id || company?._id || '',
      name: stakeHolder?.company.name || company?.name || '',
      reference: stakeHolder
        ? `${stakeHolder?.company.countryCode}${stakeHolder?.company.reference}`
        : `${company?.countryCode}${company?.reference}`,
    },
    product: {
      id: product?._id || stakeHolderProduct?._id || '',
      name: product?.name || stakeHolderProduct?.stakeHolder.product.name || '',
      reference: product?.reference || stakeHolderProduct?.stakeHolder.product.reference || '',
      measurementUnit: product?.measurementUnit || stakeHolderProduct?.measurementUnit,
    },
    type: product ? 'product' : 'stakeholderProduct',
  }
}
