import { api } from '@/app/api'
import { Signature } from './interfaces'

const SIGNATURE_URL = '/auth/signature'

export const getSignature = async () => {
  const { data: signature } = await api.get<Signature | void>(SIGNATURE_URL)

  return signature || undefined
}

export const getSignatureByUserId = async (userId: string) => {
  const { data: signature } = await api.get<Signature | void>(`${SIGNATURE_URL}/user/${userId}`)

  return signature || undefined
}

export const createSignature = async (path: string) => {
  const { data: signatureId } = await api.post<string>(SIGNATURE_URL, {
    path,
  })

  return signatureId
}

export const updateSignature = async (path: string) => {
  const { data: signatureId } = await api.put<string>(SIGNATURE_URL, {
    path,
  })

  return signatureId
}

export const deleteSignature = async () => {
  await api.delete<void>(SIGNATURE_URL)
}
