import { useAuthentication } from '@/features/auth'
import { useGoToSubscriptionCustomerPortal, useSubscriptionPaymentInfo } from '../hooks'
import { SubscriptionPaymentStatus } from '../interfaces'
import { Redirect } from 'react-router-dom'
import { useEffect } from 'react'

export const RedirectBasedOnSubscription = ({
  preventPricingPlans,
}: {
  preventPricingPlans?: boolean
}) => {
  const { isActivated, isAuthenticated, logout } = useAuthentication()
  const { goToSubscriptionCustomerPortal } = useGoToSubscriptionCustomerPortal()

  const { subscriptionPaymentInfo } = useSubscriptionPaymentInfo({
    enabled: isAuthenticated && !isActivated,
  })

  useEffect(() => {
    if (
      subscriptionPaymentInfo?.status === SubscriptionPaymentStatus.PENDING &&
      subscriptionPaymentInfo?.hostedPageUrl
    ) {
      logout()
      window.location.replace(subscriptionPaymentInfo.hostedPageUrl)
    } else if (subscriptionPaymentInfo?.status === SubscriptionPaymentStatus.PAUSED) {
      goToSubscriptionCustomerPortal()
    }
  }, [
    goToSubscriptionCustomerPortal,
    logout,
    subscriptionPaymentInfo?.hostedPageUrl,
    subscriptionPaymentInfo?.status,
  ])

  if (
    !preventPricingPlans &&
    (subscriptionPaymentInfo?.status === SubscriptionPaymentStatus.NONE ||
      subscriptionPaymentInfo?.status === SubscriptionPaymentStatus.UNPAID)
  ) {
    return <Redirect to='/pricing-plans' />
  }
  if (subscriptionPaymentInfo?.status === SubscriptionPaymentStatus.COMPLETED) {
    return <Redirect to='/home' />
  }

  return null
}
