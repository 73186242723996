import { SidebarNavigation } from '@/routes/router'

const formatRoutes = (routes: SidebarNavigation): SidebarNavigation => {
  const newRoutes: SidebarNavigation = []

  routes.forEach(route => {
    if (route.isAvailable) {
      newRoutes.push(route)
    }

    if (route.subItems) {
      const newSubItems = formatRoutes(route.subItems)

      if (newSubItems.length > 0) {
        newRoutes.push({
          ...route,
          subItems: newSubItems,
        })
      }
    }
  })

  return newRoutes
}

export default formatRoutes
