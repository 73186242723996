import { DateField, DateFieldProps } from '@blockchain-traceability-sl/tailwind-components'
import { useMemo } from 'react'
import es from 'date-fns/locale/es'
import ar from 'date-fns/locale/ar'
import { useTranslation } from 'react-i18next'

export const DateLocaleField = (props: DateFieldProps) => {
  const { i18n, t } = useTranslation('nsDateFields')

  const locale = useMemo(() => {
    if (props.locale) return props.locale

    try {
      let currentLanguage = ''

      if (i18n?.language) {
        currentLanguage = i18n.language.slice(0, 2)
      } else if ((i18n?.options?.fallbackLng as string[])[0]) {
        currentLanguage = (i18n.options.fallbackLng as string[])[0].slice(0, 2)
      }

      switch (currentLanguage) {
        case 'es':
          return es
        case 'ar':
          return ar
        default:
          return undefined
      }
    } catch {
      return undefined
    }
  }, [props.locale, i18n?.language, i18n?.options?.fallbackLng])

  const direction = useMemo(() => {
    if (props.dir) return props.dir

    try {
      let currentLanguage = ''

      if (i18n?.language) {
        currentLanguage = i18n.language.slice(0, 2)
      } else if ((i18n?.options?.fallbackLng as string[])[0]) {
        currentLanguage = (i18n.options.fallbackLng as string[])[0].slice(0, 2)
      }

      if (currentLanguage === 'ar') {
        return 'rtl'
      }
    } catch {
      return undefined
    }
  }, [props.dir, i18n?.language, i18n?.options?.fallbackLng])

  const dateFormat = useMemo(() => {
    if (props.format) return props.format

    try {
      let currentLanguage = ''

      if (i18n?.language) {
        currentLanguage = i18n.language.slice(0, 2)
      } else if ((i18n?.options?.fallbackLng as string[])[0]) {
        currentLanguage = (i18n.options.fallbackLng as string[])[0].slice(0, 2)
      }

      switch (currentLanguage) {
        case 'es':
          return 'dd/MM/yyyy'
        default:
          return 'MM/dd/yyyy'
      }
    } catch {
      return 'MM/dd/yyyy'
    }
  }, [props.format, i18n?.language, i18n?.options?.fallbackLng])

  return (
    <DateField
      {...props}
      locale={locale}
      format={dateFormat}
      dir={direction}
      presetToday
      presetTodayText={t('presets.today')}
      presetSixMonths
      presetSixMonthsText={t('presets.sixMonths')}
      presetOneYear
      presetOneYearText={t('presets.oneYear')}
      presetTwoYears
      presetTwoYearsText={t('presets.twoYears')}
      presetThreeYears
      presetThreeYearsText={t('presets.threeYears')}
      ISOWeek={false}
    />
  )
}
