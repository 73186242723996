import imgLogo from '@/assets/images/brand/logo-blue.png'
import { useTranslation } from 'react-i18next'
import Config from '@/config'
import {
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from '@blockchain-traceability-sl/tailwind-components'

export const PublicFooter = (): JSX.Element => {
  const { t } = useTranslation('nsFooterPublic')

  return (
    <footer className='bg-white'>
      <div className='max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-12 lg:px-8'>
        <div className='xl:grid xl:grid-cols-3 xl:gap-8'>
          {/* Footer header */}
          <div className='space-y-8 xl:col-span-1 text-center md:text-left'>
            {/* Logo */}
            <img className='h-4 hidden md:block' src={imgLogo} alt='Trazable' />
            {/* Title */}
            <p className='text-base leading-6 font-normal text-gray-900'>{t('title')}</p>
            {/* Social media */}
            <div className='flex space-x-4 justify-center md:justify-start'>
              <a
                href='https://www.linkedin.com/company/trazable'
                className='text-blue-600 hover:text-blue-700'
                target='_blank'
                rel='noreferrer'
              >
                <span className='sr-only'>{t('social.linkedin')}</span>
                <LinkedInIcon className='h-8 w-8' aria-hidden='true' />
              </a>
              <a
                href='https://www.instagram.com/trazable.io/'
                className='text-blue-600 hover:text-blue-700'
                target='_blank'
                rel='noreferrer'
              >
                <span className='sr-only'>{t('social.instagram')}</span>
                <InstagramIcon className='h-8 w-8' aria-hidden='true' />
              </a>
              <a
                href='https://twitter.com/trazable_io'
                className='text-blue-600 hover:text-blue-700'
                target='_blank'
                rel='noreferrer'
              >
                <span className='sr-only'>{t('social.twitter')}</span>
                <TwitterIcon className='h-8 w-8' aria-hidden='true' />
              </a>
            </div>
          </div>

          {/* Trazable info */}
          <div className='mt-12 grid-cols-1 gap-8 xl:mt-0 xl:col-span-2 hidden md:grid'>
            <div className='md:grid md:grid-cols-3 md:gap-8'>
              <div>
                <h3 className='text-base leading-6 font-bold uppercase text-gray-900'>
                  {t('sectionSolutions.title')}
                </h3>
                <ul className='mt-4 space-y-4'>
                  <li>
                    <a
                      href={`${Config.WEBSITE_URL}${t('sectionSolutions.traceabilityUrl')}`}
                      className='text-base text-gray-500 hover:text-gray-700'
                    >
                      {t('sectionSolutions.traceability')}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${Config.WEBSITE_URL}${t(
                        'sectionSolutions.digitalTransformationUrl'
                      )}`}
                      className='text-base text-gray-500 hover:text-gray-700'
                    >
                      {t('sectionSolutions.digitalTransformation')}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${Config.WEBSITE_URL}${t('sectionSolutions.transparencyUrl')}`}
                      className='text-base text-gray-500 hover:text-gray-700'
                    >
                      {t('sectionSolutions.transparency')}
                    </a>
                  </li>
                </ul>
              </div>
              <div className='mt-12 md:mt-0'>
                <h3 className='text-base leading-6 font-bold uppercase text-gray-900'>
                  {t('sectionCompany.title')}
                </h3>
                <ul className='mt-4 space-y-4'>
                  <li>
                    <a
                      href={`${Config.WEBSITE_URL}${t('sectionCompany.aboutUrl')}`}
                      className='text-base text-gray-500 hover:text-gray-900'
                    >
                      {t('sectionCompany.about')}
                    </a>
                  </li>

                  <li>
                    <a
                      href={`${Config.WEBSITE_URL}${t('sectionCompany.blogUrl')}`}
                      className='text-base text-gray-500 hover:text-gray-900'
                    >
                      {t('sectionCompany.blog')}
                    </a>
                  </li>

                  <li>
                    <a
                      href='https://www.linkedin.com/company/trazable/jobs/'
                      target='_blank'
                      className='text-base text-gray-500 hover:text-gray-900'
                      rel='noreferrer'
                    >
                      {t('sectionCompany.hire')}
                    </a>
                  </li>

                  <li>
                    <a
                      href={`${Config.WEBSITE_URL}${t('sectionCompany.supportsUrl')}`}
                      className='text-base text-gray-500 hover:text-gray-900'
                    >
                      {t('sectionCompany.supports')}
                    </a>
                  </li>
                </ul>
              </div>
              <div className='mt-12 md:mt-0'>
                <h3 className='text-base leading-6 font-bold uppercase text-gray-900'>
                  {t('sectionLegal.title')}
                </h3>
                <ul className='mt-4 space-y-4'>
                  <li>
                    <a
                      href={`${Config.WEBSITE_URL}${t('sectionLegal.privacyPolicyUrl')}`}
                      className='text-base text-gray-500 hover:text-gray-900'
                    >
                      {t('sectionLegal.privacyPolicy')}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${Config.WEBSITE_URL}${t('sectionLegal.cookiesPolicyUrl')}`}
                      className='text-base text-gray-500 hover:text-gray-900'
                    >
                      {t('sectionLegal.cookiesPolicy')}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${Config.WEBSITE_URL}${t('sectionLegal.legalNoticeUrl')}`}
                      className='text-base text-gray-500 hover:text-gray-900'
                    >
                      {t('sectionLegal.legalNotice')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Copyright */}
        <div className='mt-12 hidden md:block'>
          <p className='text-sm leading-5 font-normal text-gray-800 xl:text-center'>
            &copy; {t('copyright')}
          </p>
        </div>
        {/* Mobile Trazable info */}
        <div className='flex flex-col justify-center md:hidden mt-6 items-center gap-4 text-gray-700'>
          {/* Logo */}
          <img className='h-4' src={imgLogo} alt='Trazable' />
          <a href={Config.WEBSITE_URL} target='_blank' rel='noreferrer'>
            {t('referrer')}
          </a>
          <a
            href={`${Config.WEBSITE_URL}${t('sectionLegal.privacyPolicyUrl')}`}
            target='_blank'
            rel='noreferrer'
          >
            {t('privacyPolicy')}
          </a>
        </div>
      </div>
    </footer>
  )
}
