import { ComponentProps, ComponentType, LazyExoticComponent, ReactText } from 'react'
import { createBrowserHistory } from 'history'

export { useDashboardRoutes } from './dashboard'

export const appHistory = createBrowserHistory()

// Add analytics page track
let previousPath: string | null = null
appHistory.listen(location => {
  if (location.pathname !== previousPath) {
    window.analytics.page(undefined, undefined, { referrer: previousPath })
    previousPath = location.pathname
  }
})

export interface AppRoute {
  exact: boolean
  path: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Page: LazyExoticComponent<ComponentType<any>>
}

export type SidebarNavigation = Array<{
  name: string
  icon: (props: ComponentProps<'svg'>) => JSX.Element
  path: string | null
  badge?: ReactText
  subItems?: SidebarNavigation
  section: string | null
  onboarding?: {
    section?: string
    key?: string
  }
  testId?: string
  /**
   * Lock Sidebar navigation item
   */
  lock?: boolean
  isAvailable?: boolean
  isExternal?: boolean
  onClick?: Function
}>
