import { FileDropField, FileUploadResponse, useUploadFile } from '@/features/files'
import { StakeHolder, StakeHolderUpdate, useUpdateStakeHolder } from '@/features/stakeHolders'
import {
  Button,
  CreatableMultiField,
  FullLoader,
  InputField,
  Modal,
  RichTextField,
  RichTextFieldMode,
  SIZE,
} from '@blockchain-traceability-sl/tailwind-components'
import { TrashIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useCallback, useMemo, useRef } from 'react'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { useSendCustomReportByEmail } from '../hooks'

export interface ModalCustomReportSendByEmailProps {
  show: boolean
  report: {
    blob?: Blob
    fileName?: string
    relations?: { stakeHolder?: StakeHolder }
  }
  toggle: () => void
}

export const ModalCustomReportSendByEmail = ({
  show,
  report,
  toggle,
}: ModalCustomReportSendByEmailProps) => {
  const { t } = useTranslation('nsSendReport')
  const initialFocusRef = useRef(null)

  const stakeHolder = report.relations?.stakeHolder

  const stakeHolderEmails = useMemo(() => {
    if (!stakeHolder) return []
    const emails = []
    if (stakeHolder.company.email) {
      emails.push(stakeHolder.company.email)
    }
    if (stakeHolder.company.secondaryEmails) {
      emails.push(...stakeHolder.company.secondaryEmails)
    }
    return emails
  }, [stakeHolder])

  const { uploadFileAsync } = useUploadFile()

  const { sendCustomReportByEmail } = useSendCustomReportByEmail()

  const { updateStakeHolder } = useUpdateStakeHolder({ silently: true })

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    resetForm,
  } = useFormik<{
    recipients: string[]
    subject: string
    content: string
    contentRichText: string
    attachments: FileUploadResponse[]
  }>({
    initialValues: {
      recipients: stakeHolderEmails,
      subject: '',
      content: '',
      contentRichText: '',
      attachments: [],
    },
    validationSchema: Yup.object().shape({
      recipients: Yup.array().of(Yup.string()).min(1),
      subject: Yup.string().required(),
      content: Yup.string().required(),
      contentRichText: Yup.string().required(),
      attachments: Yup.array().of(
        Yup.object().shape({
          fileName: Yup.string(),
          path: Yup.string(),
        })
      ),
    }),
    onSubmit: async data => {
      if (!report.blob || !report.fileName) {
        setSubmitting(false)
        toggle()
        return
      }

      const reportFileResponse = await uploadFileAsync(
        new File([report.blob], report.fileName, {
          type: report.blob.type,
        })
      )

      if (stakeHolder) {
        const newEmails = data.recipients.filter(
          email =>
            !stakeHolder.company.secondaryEmails?.includes(email) &&
            stakeHolder.company.email !== email
        )
        if (newEmails.length > 0) {
          const stakeHolderUpdate: StakeHolderUpdate = {
            name: stakeHolder.company.name,
            email: stakeHolder.company.email,
            secondaryEmails: stakeHolder.company.secondaryEmails || [],
          }
          if (!stakeHolderUpdate.email) {
            const [firstEmail, ...restOfEmails] = newEmails
            stakeHolderUpdate.email = firstEmail
            if (restOfEmails.length > 0) {
              stakeHolderUpdate.secondaryEmails?.push(...restOfEmails)
            }
          } else {
            stakeHolderUpdate.secondaryEmails?.push(...newEmails)
          }
          updateStakeHolder({ id: stakeHolder._id, stakeHolder: stakeHolderUpdate })
        }
      }

      sendCustomReportByEmail(
        {
          to: data.recipients,
          stakeHolderName: stakeHolder?.company.name,
          subject: data.subject,
          content: data.content,
          contentRichText: data.contentRichText,
          attachments: [
            {
              fileName: reportFileResponse.fileName,
              path: reportFileResponse.path,
            },
            ...data.attachments,
          ],
        },
        {
          onSuccess() {
            setSubmitting(false)
            toggle()
          },
        }
      )
    },
  })

  const stakeHolderEmailsOptions = stakeHolderEmails.map(email => ({
    label: email,
    value: email,
  }))

  const handleCloseModal = useCallback(() => {
    toggle()
    resetForm()
  }, [resetForm, toggle])

  const memoRichTextField = useMemo(
    () => (
      <RichTextField
        mode={RichTextFieldMode.EDITOR}
        id='contentRichText'
        key='contentRichText'
        name='contentRichText'
        label={t('form.content.label')}
        value={values.contentRichText}
        onChange={(richTextValue: string, plainTextValue: string) => {
          setFieldValue('content', plainTextValue)
          setFieldValue('contentRichText', richTextValue)
        }}
        error={touched.contentRichText && !!errors.contentRichText}
        autoFocus={false}
      />
    ),
    [errors.contentRichText, setFieldValue, t, touched.contentRichText, values.contentRichText]
  )

  return (
    <>
      <Modal
        size={SIZE.LARGE}
        show={show}
        toggle={handleCloseModal}
        dismissButton
        initialFocus={initialFocusRef}
      >
        <Modal.Title>{t('title')}</Modal.Title>
        <Modal.Body>
          <form className='text-left space-y-4 mb-2' onSubmit={handleSubmit} ref={initialFocusRef}>
            <CreatableMultiField
              id='recipients'
              name='recipients'
              options={stakeHolderEmailsOptions}
              label={t('form.to.label')}
              value={values.recipients.map(email => ({ label: email, value: email }))}
              onChange={options => {
                setFieldValue(
                  'recipients',
                  options.map(option => option.value)
                )
              }}
              createLabel={t('form.to.createLabel').toString()}
              error={touched.recipients && !!errors.recipients}
            />
            <InputField
              id='subject'
              name='subject'
              label={t('form.subject.label')}
              value={values.subject}
              onChange={handleChange}
              error={touched.subject && !!errors.subject}
            />
            {memoRichTextField}
            <ul className='flex flex-wrap'>
              <li className='mb-3'>
                <button
                  type='button'
                  className='mr-4 inline-flex text-left items-center px-2 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none'
                >
                  <CheckCircleIcon className='w-4 h-4 text-green-500 mr-2' />
                  {report.fileName}
                </button>
              </li>
              {values.attachments.map(attachment => (
                <li className='mb-3' key={attachment.path}>
                  <button
                    type='button'
                    className='mr-4 inline-flex text-left items-center px-2 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none'
                    onClick={() => {
                      setFieldValue(
                        'attachments',
                        values.attachments.filter(({ path }) => path !== attachment.path)
                      )
                    }}
                  >
                    <TrashIcon className='h-4 w-4 text-gray-400 mr-2' aria-hidden='true' />
                    {attachment.fileName}
                  </button>
                </li>
              ))}
            </ul>

            <FileDropField
              id='attachments'
              name='attachments'
              label={t('form.addFiles.label')}
              description={t('form.addFiles.description')}
              /**
               * TODO: Fix translations
               */
              dragAndDropText={t('nsEntries:form.file.dragAndDropText')}
              uploadText={t('nsEntries:form.file.uploadText')}
              onUpload={files => {
                setFieldValue('attachments', [...values.attachments, ...files])
              }}
            />

            <div className='flex flex-row-reverse'>
              <Button type='submit'>{t('submit')}</Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {isSubmitting && <FullLoader className='z-50' />}
    </>
  )
}
