import { useMemo } from 'react'
import { EntryFieldSetItem } from './interfaces'
import { DEFAULT_ENTRIES_FIELDSET_PAGE_SIZE } from './constants'
import { TemplateEntryType } from '@/features/templates'

export const useShouldPaginate = ({
  items,
  enabled,
  pageSize = DEFAULT_ENTRIES_FIELDSET_PAGE_SIZE,
}: {
  items: EntryFieldSetItem[]
  enabled?: boolean
  pageSize?: number
}) =>
  useMemo(() => {
    if (!enabled || items.length < pageSize) return false

    const itemsIncludedInPages = items.filter(item => {
      if (!item.blockId) return true

      const section = items.find(
        block => block.id === item.blockId && block.type === TemplateEntryType.SECTION
      )

      return !!section
    })

    return itemsIncludedInPages.length > pageSize
  }, [items, enabled, pageSize])

type SectionEntry = { id: string; index: number }
type PageItem = {
  entryIndex: number
  entryId: string
  entryType: TemplateEntryType
  sectionEntries?: SectionEntry[]
  sectionChunkIndex?: number
}
type Page = PageItem[]

interface UseEntryPagesState {
  totalCount: number
  pages: Page[]
}

/**
 * This hook will paginate the entries fieldset items.
 */
export const useEntryPages = ({
  items,
  pageSize = DEFAULT_ENTRIES_FIELDSET_PAGE_SIZE,
}: {
  items: EntryFieldSetItem[]
  pageSize?: number
}): UseEntryPagesState => {
  const getCurrentPageAmount = (page?: Page): number => {
    if (!page) return 0

    return page.reduce((total, current) => {
      if (current.sectionEntries) {
        return total + current.sectionEntries.length
      }

      return total
    }, page.length)
  }

  const checkIfPageIsFull = (pageLength: number, page?: Page): boolean => {
    return getCurrentPageAmount(page) >= pageLength
  }

  const countTotal = (pages: Page[]): number => {
    return pages.reduce((total, page) => {
      return total + getCurrentPageAmount(page)
    }, 0)
  }

  return useMemo((): UseEntryPagesState => {
    const itemsIncludedInPages = items
      .map<[EntryFieldSetItem, number]>((item, index) => [item, index])
      .filter(([item]) => {
        // Include items that are not inside blocks
        if (!item.blockId) return true

        // Is inside a conditional block
        const isInsideConditional = !!items.find(
          block => block.id === item.blockId && block.type === TemplateEntryType.CONDITIONAL
        )

        // Exclude items that are inside conditional blocks
        if (isInsideConditional) return false

        // Is inside a section block
        const isInsideSection = !!items.find(
          block => block.id === item.blockId && block.type === TemplateEntryType.SECTION
        )

        // Include items that are inside section blocks
        if (isInsideSection) return false

        return false
      })

    // Split items into chunks of "pageSize" items each

    let pageIndex = 0
    const itemsPaginated: Page[] = []

    const createPageIfNotExist = () => {
      if (!itemsPaginated[pageIndex]) {
        itemsPaginated[pageIndex] = [] // start a new page
      }
    }

    const paginateWithSection = (sectionId: string, itemIndex: number) => {
      // If it is a section, calculate the number of items inside the section in that page
      const itemsInsideSection = items
        .map((item, index): [EntryFieldSetItem, number] => [item, index])
        .filter(([{ blockId }]) => blockId === sectionId)

      // Calculate the number of items that can fit inside the section in that page
      const sectionItemsInThisPage = pageSize - getCurrentPageAmount(itemsPaginated[pageIndex])

      const entriesChunks: SectionEntry[][] = []

      // Add chunk to fill current page
      entriesChunks.push(
        itemsInsideSection
          .filter((item, index) => index < sectionItemsInThisPage)
          .map(([item, entryIndex]): SectionEntry => ({ id: item.id, index: entryIndex }))
      )
      // Add chunk to fill next pages
      if (itemsInsideSection.length > sectionItemsInThisPage) {
        const remainingItems = itemsInsideSection.slice(sectionItemsInThisPage)

        // Split remaining items into chunks of 50 items each
        remainingItems.forEach(([item, itemIndex], index) => {
          if (index % pageSize === 0) {
            entriesChunks.push([])
          }

          entriesChunks[entriesChunks.length - 1].push({ id: item.id, index: itemIndex })
        }, [])
      }

      entriesChunks.forEach((entriesChunk, chunkIndex) => {
        createPageIfNotExist()
        if (checkIfPageIsFull(pageSize, itemsPaginated[pageIndex])) {
          pageIndex++
        }

        createPageIfNotExist()

        itemsPaginated[pageIndex].push({
          entryIndex: itemIndex,
          entryId: sectionId,
          entryType: TemplateEntryType.SECTION,
          sectionEntries: entriesChunk,
          sectionChunkIndex: chunkIndex,
        })
      })
    }

    itemsIncludedInPages.forEach(([item, itemIndex]) => {
      if (checkIfPageIsFull(pageSize, itemsPaginated[pageIndex])) {
        pageIndex++
      }

      createPageIfNotExist()

      // Check if the item is a section
      if (item.type === TemplateEntryType.SECTION) {
        paginateWithSection(item.id, itemIndex)
      } else {
        // Add item to the chunk
        itemsPaginated[pageIndex].push({
          entryIndex: itemIndex,
          entryId: item.id,
          entryType: item.type,
        })
      }
    })

    const totalCount: number = countTotal(itemsPaginated)

    return {
      totalCount,
      pages: itemsPaginated,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, pageSize])
}
