import { PDF_MANUAL_EN_URL, PDF_MANUAL_ES_URL } from '@/app/constants'
import { useAnalytics } from '@/features/analytics'
import { useBreakpoint } from '@/hooks/use-breakpoints'
import i18n from '@/i18n'
import { SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { Popover } from '@headlessui/react'
import classNames from 'classnames'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const Help = ({
  analyticsProperties,
  className,
}: {
  docUrl?: string
  analyticsProperties: { Source: string }
  portal?: boolean
  className?: string
}) => {
  const { t } = useTranslation('nsHelp')
  const analytics = useAnalytics()
  const breakpoint = useBreakpoint()
  const language = i18n.language

  const userManualUrl = useMemo(() => {
    if (language === 'es') {
      return PDF_MANUAL_ES_URL
    }
    return PDF_MANUAL_EN_URL
  }, [language])

  const trackHelp = () => {
    analytics.track('ACTION_CLICK_HELP', analyticsProperties)
  }

  const handleClick = (event: React.MouseEvent): void => {
    trackHelp()
    event.preventDefault()
    window.open(userManualUrl, '_blank')
  }

  if (breakpoint !== SIZE.EXTRA_LARGE) {
    return (
      <p className='text-sm mt-2'>
        <a
          href={userManualUrl}
          target='_blank'
          rel='noreferrer'
          className='underline hover:text-gray-400'
          data-testid='help-link'
          onClick={handleClick}
        >
          {t('title')}
        </a>
      </p>
    )
  }

  return (
    <div className={classNames('text-base mt-2 flex mb-5 leading-none font-normal', className)}>
      <Popover className='relative'>
        <Popover.Button
          ref={undefined}
          onClick={handleClick}
          className='underline hover:text-gray-400'
          data-testid='help-link-with-popover'
        >
          {t('title')}
        </Popover.Button>
      </Popover>
    </div>
  )
}
