import { SortableContainer } from '@/components/SortableContainer'
import { noop } from '@/utils/noop'
import {
  Button,
  CheckboxField,
  CreatableMultiField,
  InputField,
  SIZE,
  SelectField,
  SelectorOption,
  TextAreaField,
} from '@blockchain-traceability-sl/tailwind-components'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { nanoid } from 'nanoid'
import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TemplateEntryError } from '../..'
import { useTemplateEntriesSelectorOptions } from '../../hooks'
import {
  EditableEntry,
  TemplateEntryReferenceType,
  TemplateEntryType,
  TemplateTableEntryColumn,
} from '../../interfaces'
import {
  getDefaultTemplateValue,
  isMandatoriableField,
  normalizeTemplateType,
  validateTemplateEntry,
} from '../../utils'
import { ConditionalEntryField } from '../ConditionalEntryField'
import { SortableEntry } from '../SortableEntry'
import { SortableEntryEditable } from '../SortableEntryEditable'
import { TableEntryField } from '../TableEntryField'

const generateNewEntry = () => ({
  id: nanoid(),
  editable: false,
  name: '',
  type: TemplateEntryType.SHORT_TEXT,
  mandatory: false,
  values: [],
})

interface BlockFieldsProps {
  collapsed?: boolean
  toggleCollapsed?: () => void
  title?: string
  entries: EditableEntry[]
  errors: { [id: string]: TemplateEntryError | undefined }
  onAddField: (newEntry: EditableEntry, entriesInNestedBlock?: EditableEntry[]) => void
  onRemoveField: (entry: EditableEntry) => void
  onChangeField: (
    entry: EditableEntry,
    nestedBlockOperation?: { entry: EditableEntry; operation: 'add' | 'remove' }
  ) => void
  onSortFields: (entries: EditableEntry[]) => void
  mandatoryEnabled?: boolean
  conditionalEnabled?: boolean
  entriesInNestedBlock?: EditableEntry[]
  errorsInNestedBlock?: TemplateEntryError[]
  tableEnabled?: boolean
  statementEnabled?: boolean
}

export const BlockFields = ({
  collapsed,
  title,
  entries: allEntries,
  errors,
  toggleCollapsed = noop,
  onAddField,
  onRemoveField,
  onChangeField,
  onSortFields,
  mandatoryEnabled = false,
  conditionalEnabled,
  tableEnabled,
  statementEnabled,
  entriesInNestedBlock,
  errorsInNestedBlock,
}: BlockFieldsProps) => {
  const { t } = useTranslation('nsTemplate')
  const entryTypeOptions = useTemplateEntriesSelectorOptions({
    documentEnabled: true,
    fileEnabled: true,
    referenceEnabled: true,
    conditionalEnabled,
    tableEnabled,
    statementEnabled,
  })

  const [touched, setTouched] = useState(
    () =>
      allEntries[allEntries.length - 1] && !validateTemplateEntry(allEntries[allEntries.length - 1])
  )

  const entries = touched ? allEntries.slice(0, allEntries.length - 1) : allEntries
  const newEntry = touched ? allEntries[allEntries.length - 1] : undefined

  const newEntriesInNestedBlock =
    entriesInNestedBlock && newEntry && newEntry.type === TemplateEntryType.CONDITIONAL
      ? entriesInNestedBlock.filter(entry => entry.blockId === newEntry.id)
      : []

  const isEditionEnabled = useMemo(() => entries.some(({ editable }) => editable), [entries])
  const hasEntriesErrors = entries.some(({ id }) => errors[id])

  const addFieldsDisabled = !(
    validateTemplateEntry(newEntry) && newEntriesInNestedBlock.every(validateTemplateEntry)
  )

  return (
    <div className='flex flex-col'>
      {title && (
        <div
          className='flex items-center text-sm leading-8 font-medium text-gray-600 cursor-pointer'
          onClick={toggleCollapsed}
        >
          {title}
          {!collapsed ? (
            <ChevronDownIcon className='h-6 w-6 ml-1' aria-hidden='true' />
          ) : (
            <ChevronRightIcon className='h-6 w-6 ml-1' aria-hidden='true' />
          )}
        </div>
      )}

      <p className='text-sm leading-5 font-normal text-gray-500'>
        <Trans
          t={t}
          i18nKey='entries.block.numberOfFields'
          values={{ count: allEntries.length }}
          components={{ strong: <span className='font-semibold' /> }}
        />
      </p>

      {!collapsed && (
        <>
          {entries.length > 0 && (
            <div className='my-4'>
              <SortableContainer
                items={entries}
                onSort={items => {
                  onSortFields(items as EditableEntry[])
                }}
              >
                {entries.map(({ editable, ...entry }, index) =>
                  !editable ? (
                    <SortableEntry
                      key={entry.id}
                      {...entry}
                      className={classNames({ 'mt-4': index !== 0 })}
                      typeOptions={entryTypeOptions}
                      hasEntriesErrors={hasEntriesErrors}
                      sortable={!isEditionEnabled}
                      onEntryEdit={() => {
                        if (hasEntriesErrors) return
                        onChangeField({
                          ...entry,
                          editable: !editable,
                        })
                      }}
                      onEntryDelete={() => {
                        onRemoveField(entry)
                      }}
                    />
                  ) : (
                    <SortableEntryEditable
                      key={entry.id}
                      {...entry}
                      className={classNames({ 'mt-4 mb-6': index !== 0 })}
                      typeOptions={entryTypeOptions}
                      hasKeysErrors={hasEntriesErrors}
                      hasNameError={!!errors[entry.id]?.name}
                      hasTypeError={!!errors[entry.id]?.type}
                      hasSelectorError={!!errors[entry.id]?.values}
                      onKeyEditEnd={() => {
                        if (hasEntriesErrors) return
                        onChangeField({
                          ...entry,
                          editable: !editable,
                        })
                      }}
                      onKeyDelete={() => {
                        onRemoveField(entry)
                      }}
                      onNameChange={value => {
                        onChangeField({
                          ...entry,
                          name: value,
                        })
                      }}
                      onTypeChange={value => {
                        const type = value as TemplateEntryType | TemplateEntryReferenceType
                        onChangeField({
                          ...entry,
                          type: normalizeTemplateType(type),
                          values: getDefaultTemplateValue(type),
                        })
                      }}
                      onSelectorChange={values => {
                        onChangeField({
                          ...entry,
                          values,
                        })
                      }}
                      onContentTableChange={values => {
                        onChangeField({
                          ...entry,
                          values,
                        })
                      }}
                      onMandatoryChange={value => {
                        onChangeField({
                          ...entry,
                          mandatory: value,
                        })
                      }}
                      conditionalOptions={{
                        nested: true,
                        entries: entriesInNestedBlock || entries,
                        entriesErrors: errorsInNestedBlock,
                        onAddEntryInBlock: (blockEntry, entryToAdd) => {
                          onChangeField(blockEntry, {
                            entry: entryToAdd,
                            operation: 'add',
                          })
                        },
                        onRemoveEntryInBlock: (blockEntry, entryToRemove) => {
                          onChangeField(blockEntry, {
                            entry: entryToRemove,
                            operation: 'remove',
                          })
                        },
                        onChangeEntryInBlock: entryToChange => {
                          onChangeField(entryToChange)
                        },
                        onChange: entryToChange => {
                          onChangeField(entryToChange)
                        },
                      }}
                      mandatoryEnabled={mandatoryEnabled}
                      documentEnabled
                      referenceEnabled
                    />
                  )
                )}
              </SortableContainer>
            </div>
          )}

          {newEntry && (
            <>
              <div className='grid gap-6 mt-4'>
                <InputField
                  key={`entry.name.${newEntry.id}`}
                  id={`entry_name_${newEntry.id}`}
                  name={`entry_name_${newEntry.id}`}
                  onChange={event => {
                    onChangeField({
                      ...newEntry,
                      name: event.target.value,
                    })
                  }}
                  autoFocus
                  value={newEntry.name}
                  label={t('entries.name.label')}
                  description={t('entries.name.description')}
                />

                <div>
                  <div className='flex sm:items-center sm:gap-6 flex-col sm:flex-row'>
                    <div
                      className={classNames('flex flex-col', {
                        'flex-grow':
                          newEntry.type !== TemplateEntryType.SINGLE_CHOICE &&
                          newEntry.type !== TemplateEntryType.MULTIPLE_CHOICE,
                      })}
                    >
                      <SelectField
                        key={`entry_type_${newEntry.id}`}
                        id={`entry_type_${newEntry.id}`}
                        name={`entry.type.${newEntry.id}`}
                        options={entryTypeOptions}
                        menuSize={SIZE.SMALL}
                        value={entryTypeOptions
                          .flatMap(group => group.options)
                          .find(selection => selection.value === newEntry.type)}
                        onChange={option => {
                          if (!option) return
                          const type = option.value as
                            | TemplateEntryType
                            | TemplateEntryReferenceType
                          onChangeField({
                            ...newEntry,
                            type: normalizeTemplateType(type),
                            values: getDefaultTemplateValue(type),
                          })
                        }}
                        label={t('entries.type.label')}
                      />
                    </div>

                    {(newEntry.type === TemplateEntryType.SINGLE_CHOICE ||
                      newEntry.type === TemplateEntryType.MULTIPLE_CHOICE) && (
                      <div className='flex-grow sm:self-end'>
                        <CreatableMultiField
                          key={`entry.values.${newEntry.id}`}
                          id={`entry_values_${newEntry.id}`}
                          name={`entry.values.${newEntry.id}`}
                          value={((newEntry as { values?: string[] }).values || []).map(
                            (value): SelectorOption => ({ label: value, value })
                          )}
                          onChange={options => {
                            onChangeField({
                              ...newEntry,
                              values: options.map(({ value }) => value),
                            })
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <p className='mt-1 text-sm text-gray-500'>{t('entries.type.description')}</p>
                </div>
              </div>

              {newEntry.type === TemplateEntryType.STATEMENT && (
                <div className='mt-4'>
                  <TextAreaField
                    key={`entry.values.${newEntry.id}`}
                    id={`key_values_${newEntry.id}`}
                    name={`entry.values.${newEntry.id}`}
                    value={(newEntry.values[0] as string) || ''}
                    onChange={event =>
                      onChangeField({
                        ...newEntry,
                        values: [event.currentTarget.value],
                      })
                    }
                    label={t('entries.content.label')}
                    description={t('entries.content.description')}
                  />
                </div>
              )}
              {mandatoryEnabled && isMandatoriableField(newEntry.type) && (
                <div className='my-4'>
                  <CheckboxField
                    key={`entry.mandatory.${newEntry.id}`}
                    id={`entry_mandatory_${newEntry.id}`}
                    name={`entry.mandatory.${newEntry.id}`}
                    label={t('entries.checkbox.mandatory.label')}
                    checked={newEntry.mandatory}
                    onChange={event =>
                      onChangeField({
                        ...newEntry,
                        mandatory: event.currentTarget.checked,
                      })
                    }
                  />
                </div>
              )}

              {conditionalEnabled &&
                newEntry.type === TemplateEntryType.CONDITIONAL &&
                entriesInNestedBlock && (
                  <div className='my-4'>
                    <ConditionalEntryField
                      nested
                      targetEntry={newEntry}
                      entries={entriesInNestedBlock}
                      onAddEntryInBlock={(blockEntry, entryToAdd) => {
                        onChangeField(
                          {
                            ...newEntry,
                            ...blockEntry,
                          },
                          {
                            entry: entryToAdd,
                            operation: 'add',
                          }
                        )
                      }}
                      onRemoveEntryInBlock={(blockEntry, entryToRemove) => {
                        onChangeField(
                          {
                            ...newEntry,
                            ...blockEntry,
                          },
                          {
                            entry: entryToRemove,
                            operation: 'remove',
                          }
                        )
                      }}
                      onChangeEntryInBlock={entryToChange => {
                        onChangeField(entryToChange)
                      }}
                      onChange={entry => {
                        onChangeField({
                          ...newEntry,
                          ...entry,
                        })
                      }}
                    />
                  </div>
                )}

              {newEntry.type === TemplateEntryType.TABLE && (
                <div className='mt-4'>
                  <TableEntryField
                    key={newEntry.id}
                    onChange={(columns: TemplateTableEntryColumn[]): void => {
                      onChangeField({
                        ...newEntry,
                        values: columns,
                      })
                    }}
                    referenceEnabled
                    documentEnabled
                  />
                </div>
              )}
            </>
          )}

          <div className='mt-2'>
            <Button
              color='secondary'
              onClick={() => {
                onAddField(generateNewEntry())
                setTouched(true)
              }}
              disabled={addFieldsDisabled}
            >
              {t('entries.block.addField', { count: entries.length })}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
