import { api } from '@/app/api'
import { Paginated, PaginationParams } from '@/hooks/use-pagination'

import { IUser, User, UserCreation, UserFilters, UserUpdate } from './interfaces'

/**
 * Define the url path
 * @constant
 */
export const USERS_URL = '/auth/users'
/**
 * Fetch users
 *
 * @returns {Promise<User[]>}
 */
export const getUsers = async (filters?: UserFilters): Promise<User[]> => {
  const searchParams = new URLSearchParams()
  if (filters?.search) {
    searchParams.append('search', filters.search)
  }

  const { data } = await api.get<IUser[] | void>(USERS_URL, { params: searchParams })

  return (data || []).map(item => new User(item))
}

export const getPaginatedUsers = async (
  { search }: UserFilters,
  pagination: PaginationParams
): Promise<Paginated<User>> => {
  const searchParams = new URLSearchParams()
  if (search) {
    searchParams.append('search', search)
  }
  searchParams.append('limit', pagination.limit.toString())
  searchParams.append('offset', pagination.offset.toString())
  const { data } = await api.get<Paginated<IUser>>(USERS_URL, { params: searchParams })

  return {
    ...data,
    items: data.items.map(item => new User(item)),
  }
}

/**
 * Fetch user by id
 */
export const getUserById = async (userId: string): Promise<User | void> => {
  const { data } = await api.get<IUser | void>(`${USERS_URL}/${userId}`)
  return data ? new User(data) : undefined
}

/**
 *
 * Fetch user count
 */
export const getUserCount = async ({
  includeOwner,
}: {
  includeOwner?: boolean
} = {}): Promise<number> => {
  const searchParameters = new URLSearchParams()
  if (includeOwner) {
    searchParameters.append('includeOwner', 'true')
  }
  const {
    data: { count },
  } = await api.get<{ count: number }>(`${USERS_URL}/count?${searchParameters}`)
  return count
}

/**
 * Create new user
 *
 * @param {{ email: string, password: string }}
 *
 * @returns {Promise<void>}
 */
export const createUser = async ({
  name,
  email,
  password,
  roleId,
}: UserCreation): Promise<string> => {
  const { data: userId } = await api.post<string>(USERS_URL, {
    name,
    email,
    password,
    roleId,
  })

  return userId
}

/**
 * Delete users by id
 *
 * @param {id : string} - the  id to delete
 */
export const deleteUser = async (id: string): Promise<void> => {
  await api.delete<void>(`${USERS_URL}/${id}`)
}

/**
 * Update a User by id
 *
 * @param id - the user id
 */
export const updateUser = async (id: string, data: UserUpdate): Promise<void> => {
  await api.patch<void>(`${USERS_URL}/${id}`, data)
}
