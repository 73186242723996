import { Button } from '@blockchain-traceability-sl/tailwind-components'
import { ReactNode } from 'react'

interface StepOverviewItemProps {
  children: ReactNode
}

interface StepOverviewProps {
  items: (ReactNode | undefined | null)[]
}

const StepOverviewItem = ({ children }: StepOverviewItemProps) => {
  if (children !== null && children !== undefined && children !== '')
    return <Button color='white'>{children}</Button>
  else return null
}

export const StepOverview = ({ items }: StepOverviewProps) => {
  return (
    <div className='flex flex-wrap gap-3'>
      {items.map((item, index) => (
        <StepOverviewItem key={index}>{item}</StepOverviewItem>
      ))}
    </div>
  )
}
