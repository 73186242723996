import { useAnalytics } from '@/features/analytics'
import { BellIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import { useHasNotificationNotScknowledged, useNotificationPanel } from '../hooks'

type NotificationBellButtonProps = Omit<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'children'
>

export const NotificationBellButton = ({
  onClick,
  className,
  ...props
}: NotificationBellButtonProps) => {
  const analytics = useAnalytics()
  const { toggleShowPanel } = useNotificationPanel()
  const hasNotificationNotScknowledged = useHasNotificationNotScknowledged()

  return (
    <button
      {...props}
      className={classNames('group block', className)}
      onClick={event => {
        analytics.track('ACTION_CLICK_NOTIFICATIONS')
        toggleShowPanel()
        onClick && onClick(event)
      }}
    >
      <div className='relative'>
        <BellIcon className='h-5 w-5 text-blue-200 group-hover:text-blue-50' />

        {/* Active dot */}
        {hasNotificationNotScknowledged && (
          <span className='absolute top-1 right-1 block h-2 w-2 transform -translate-y-1/2 translate-x-1/2 rounded-full bg-red-600' />
        )}
      </div>
    </button>
  )
}
