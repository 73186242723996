import {
  Badge,
  Button,
  COLOR,
  CheckboxField,
} from '@blockchain-traceability-sl/tailwind-components'
import classNames from 'classnames'

export const CompactPlanItemBox = ({
  className,

  plan,
  checked,
  title,
  subtitle,
  onToggle,
  amountClassName,
  amountSuffixText,
  seatsDescriptionText,
  isCurrentPlan,
  comingSoonText,
  isComingSoon,
  requestInfoText,
  onRequestInfo,
}: {
  className?: string

  plan: { name: string; amount: number; seatsCount?: number }
  checked?: boolean
  onToggle: (checked: boolean) => void
  title: string
  subtitle: string
  amountClassName?: string
  amountSuffixText: string
  seatsDescriptionText?: string
  isCurrentPlan?: boolean
  isComingSoon?: boolean
  comingSoonText?: string
  requestInfoText?: string
  onRequestInfo?: () => void
}) => {
  const canBeChecked = !isCurrentPlan && !isComingSoon
  return (
    <div
      className={classNames(className, 'shadow-lg rounded-xl', {
        'bg-blue-600 text-white': isCurrentPlan,
        'bg-transparent text-black cursor-pointer': canBeChecked,
        'border-4 border-blue-600': checked,
        'border border-gray-300': !checked,
      })}
      onClick={() => canBeChecked && onToggle(!checked)}
    >
      <div className='p-4 flex flex-col justify-center'>
        <div className='flex flex-col pb-6'>
          <div
            className={classNames('flex items-center', {
              'justify-center': isCurrentPlan || isComingSoon,
              'justify-between': canBeChecked,
            })}
          >
            <h3 className='text-lg leading-7 font-bold text-center'>{title}</h3>
            {canBeChecked && (
              <div className='-mr-3'>
                <CheckboxField
                  id={`primary-plan-checkbox-${plan.name}`}
                  name={`primary-plan-checkbox-${plan.name}`}
                  checked={checked}
                  onChange={event => onToggle(event.target.checked)}
                />
              </div>
            )}
          </div>
          <p className='text-xs leading-5 font-normal text-center mt-7 h-16'>{subtitle}</p>
          <div className={classNames(amountClassName, 'flex items-center justify-center')}>
            {isComingSoon ? (
              <Badge color={COLOR.BLUE} className='text-sm leading-5 font-medium'>
                {comingSoonText}
              </Badge>
            ) : (
              <>
                <span
                  className={classNames('text-2xl leading-8 font-extrabold mr-1', {
                    'text-blue-600': !isCurrentPlan,
                  })}
                >
                  {plan.amount / 100} €
                </span>
                <span className='text-base leading-5 font-normal '>{amountSuffixText}</span>
              </>
            )}
          </div>
        </div>
        {seatsDescriptionText && (
          <div className='flex flex-col mt-3 mb-7'>
            <p className='text-sm leading-5 font-normal'>{seatsDescriptionText}</p>
          </div>
        )}
        {isComingSoon && (
          <Button color='link' className='underline' onClick={onRequestInfo}>
            {requestInfoText}
          </Button>
        )}
      </div>
    </div>
  )
}
