import {
  COLOR,
  HorizontalLinkCard,
  HorizontalLinkCardProps,
  Modal,
  SIZE,
} from '@blockchain-traceability-sl/tailwind-components'
import {
  ClipboardCheckIcon,
  CubeIcon,
  DocumentTextIcon,
  FolderOpenIcon,
  OfficeBuildingIcon,
  QrcodeIcon,
  TemplateIcon,
  TruckIcon,
  UserGroupIcon,
} from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useUserAddons, useUserPermissions } from '@/features/auth'
import { PermissionScopeKey } from '@/features/roles'
import { useLayout } from '../context'
import { useAnalytics } from '@/features/analytics'
import { RawMaterialIcon } from '@/features/stakeHolderProducts'

export const CreateNewModalPermissionScopes: PermissionScopeKey[] = [
  'stakeHolders',
  'trus',
  'stakeHolderProducts',
  'metadataTemplates',
  'documents',
  'products',
  'qr',
  'forms',
  'tasks',
]

export const ModalCreateNew = () => {
  const { t } = useTranslation('nsModalCreateNew')
  const history = useHistory()
  const userPermissions = useUserPermissions()
  const userAddons = useUserAddons()
  const { toggleModalCreateNew, modalCreateNew } = useLayout()
  const analytics = useAnalytics()

  const createNew: (HorizontalLinkCardProps & { permissionScope: string })[] = useMemo(
    () =>
      [
        {
          title: t('stakeHolder.title').toString(),
          description: t('stakeHolder.description').toString(),
          color: COLOR.GREEN,
          permissionScope: 'stakeHolders',
          IconComponent: UserGroupIcon,
          onClick: () => {
            analytics.track('ACTION_CLICK_CREATE_NEW', {
              Element: 'Stakeholder',
            })
            history.push('/stakeholders/create')
            toggleModalCreateNew()
          },
        },
        {
          title: t('tru.title').toString(),
          description: t('tru.description').toString(),
          color: COLOR.GREEN,
          permissionScope: 'trus',
          IconComponent: CubeIcon,
          onClick: () => {
            analytics.track('ACTION_CLICK_CREATE_NEW', {
              Element: 'TRU',
            })
            history.push('/trus/create')
            toggleModalCreateNew()
          },
        },
        {
          title: t('stakeHolderProduct.title').toString(),
          description: t('stakeHolderProduct.description').toString(),
          color: COLOR.GREEN,
          permissionScope: 'stakeHolderProducts',
          IconComponent: RawMaterialIcon,
          onClick: () => {
            analytics.track('ACTION_CLICK_CREATE_NEW', {
              Element: 'Raw material',
            })
            history.push('/stakeholder-products/create')
            toggleModalCreateNew()
          },
        },
        {
          title: t('metadataTemplate.title').toString(),
          description: t('metadataTemplate.description').toString(),
          color: COLOR.GREEN,
          permissionScope: 'metadataTemplates',
          IconComponent: TemplateIcon,
          onClick: () => {
            analytics.track('ACTION_CLICK_CREATE_NEW', {
              Element: 'Data template',
            })
            history.push('/metadata-templates/create')
            toggleModalCreateNew()
          },
        },
        {
          title: t('reception.title').toString(),
          description: t('reception.description').toString(),
          color: COLOR.GREEN,
          permissionScope: 'trus',
          IconComponent: TruckIcon,
          onClick: () => {
            analytics.track('ACTION_CLICK_CREATE_NEW', {
              Element: 'Goods receipt',
            })
            history.push('/receptions/create')
            toggleModalCreateNew()
          },
        },
        {
          title: t('document.title').toString(),
          description: t('document.description').toString(),
          color: COLOR.GREEN,
          permissionScope: 'documents',
          IconComponent: FolderOpenIcon,
          onClick: () => {
            analytics.track('ACTION_CLICK_CREATE_NEW', {
              Element: 'Document',
            })
            history.push('/documents/files/create')
            toggleModalCreateNew()
          },
        },
        {
          title: t('product.title').toString(),
          description: t('product.description').toString(),
          color: COLOR.GREEN,
          permissionScope: 'products',
          IconComponent: OfficeBuildingIcon,
          onClick: () => {
            analytics.track('ACTION_CLICK_CREATE_NEW', {
              Element: 'Product',
            })
            history.push('/products/create')
            toggleModalCreateNew()
          },
        },
        {
          title: t('transparency.title').toString(),
          description: t('transparency.description').toString(),
          color: COLOR.GREEN,
          permissionScope: 'qr',
          IconComponent: QrcodeIcon,
          isBlocked: !userAddons?.transparency.enabled,
          onClick: () => {
            analytics.track('ACTION_CLICK_CREATE_NEW', {
              Element: 'QR',
            })
            history.push(
              userAddons?.transparency.enabled
                ? '/transparency/qr-codes/create'
                : '/transparency/qr-codes'
            )
            toggleModalCreateNew()
          },
        },
        {
          title: t('form.title').toString(),
          description: t('form.description').toString(),
          color: COLOR.GREEN,
          permissionScope: 'forms',
          isBlocked: !userAddons?.registries.enabled,
          IconComponent: DocumentTextIcon,
          onClick: () => {
            analytics.track('ACTION_CLICK_CREATE_NEW', {
              Element: 'Form',
            })
            history.push(userAddons?.registries.enabled ? '/forms/create' : '/forms')
            toggleModalCreateNew()
          },
        },
        {
          title: t('task.title').toString(),
          description: t('task.description').toString(),
          color: COLOR.GREEN,
          permissionScope: 'tasks',
          isBlocked: !userAddons?.registries.enabled,
          IconComponent: ClipboardCheckIcon,
          onClick: () => {
            analytics.track('ACTION_CLICK_CREATE_NEW', {
              Element: 'Task',
            })
            history.push(userAddons?.registries.enabled ? '/tasks/create' : '/tasks')
            toggleModalCreateNew()
          },
        },
      ].filter(({ permissionScope }) => {
        const scope = userPermissions[permissionScope as PermissionScopeKey]
        return scope && scope.write
      }),
    [
      analytics,
      history,
      t,
      toggleModalCreateNew,
      userAddons?.registries.enabled,
      userAddons?.transparency.enabled,
      userPermissions,
    ]
  )

  return (
    <Modal
      show={modalCreateNew.isOpen}
      size={SIZE.LARGE}
      toggle={() => toggleModalCreateNew(false)}
      dismissButton
    >
      <Modal.Title>{t('title')}</Modal.Title>
      <Modal.Body>
        <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
          {createNew.map((createNewItem, index) => (
            <HorizontalLinkCard key={index} {...createNewItem} />
          ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}
