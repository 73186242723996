import { useCallback, useMemo, useReducer, useState } from 'react'
import { DocumentFiltersExpirationStatus } from '../../interfaces'
import { DocumentFiltersState } from './interfaces'

export const ALL_FILTER_OPTION_VALUE = 'all' as const

type AllFilter = typeof ALL_FILTER_OPTION_VALUE

interface DocumentFiltersAction extends Partial<Omit<DocumentFiltersState, 'expirationStatus'>> {
  actionType: 'change' | 'reset'

  expirationStatus?: (DocumentFiltersExpirationStatus | AllFilter)[]
}

const initialState: DocumentFiltersState = {
  expirationStatus: [],
  stakeHolders: [],
  parentId: null,
  search: '',
  startCreatedAt: null,
  endCreatedAt: null,
  tags: [],
  startExpiredAt: null,
  endExpiredAt: null,
  creationTriggerTypes: [],
  creationTriggerTemplates: [],
}

const cleanAllOption = <T>(
  previousArray: T[],
  array: T[],
  { isAllOption }: { isAllOption: (item: T) => boolean }
) => {
  const allPreviouslySelected = previousArray.length === 0
  const allSelected = array.find(isAllOption)

  if (allPreviouslySelected && allSelected) {
    return array.filter(value => !isAllOption(value))
  }

  if (!allPreviouslySelected && allSelected) {
    return []
  }

  return array
}

const documentFiltersReducer = (state: DocumentFiltersState, action: DocumentFiltersAction) => {
  const { actionType, ...filters } = action
  if (actionType === 'reset') {
    return initialState
  }

  const newState = { ...state }

  if (filters.expirationStatus) {
    newState.expirationStatus = cleanAllOption(state.expirationStatus, filters.expirationStatus, {
      isAllOption: status => status === ALL_FILTER_OPTION_VALUE,
    }) as DocumentFiltersExpirationStatus[]
  }

  if (filters.stakeHolders) {
    newState.stakeHolders = cleanAllOption(state.stakeHolders, filters.stakeHolders, {
      isAllOption: stakeHolder => stakeHolder.id === ALL_FILTER_OPTION_VALUE,
    }) as {
      id: string
      name: string
    }[]
  }

  if (filters.tags) {
    newState.tags = cleanAllOption(state.tags, filters.tags, {
      isAllOption: tag => tag === ALL_FILTER_OPTION_VALUE,
    }) as string[]
  }

  if (filters.search !== undefined) {
    newState.search = filters.search
  }

  if (filters.startCreatedAt !== undefined) {
    newState.startCreatedAt = filters.startCreatedAt
  }

  if (filters.endCreatedAt !== undefined) {
    newState.endCreatedAt = filters.endCreatedAt
  }

  if (filters.startExpiredAt !== undefined) {
    newState.startExpiredAt = filters.startExpiredAt
  }

  if (filters.endExpiredAt !== undefined) {
    newState.endExpiredAt = filters.endExpiredAt
  }

  if (filters.sortBy !== undefined) {
    newState.sortBy = filters.sortBy
  }

  if (filters.sortDirection !== undefined) {
    newState.sortDirection = filters.sortDirection
  }
  if (filters.creationTriggerTemplates !== undefined) {
    newState.creationTriggerTemplates = cleanAllOption(
      state.creationTriggerTemplates,
      filters.creationTriggerTemplates,
      {
        isAllOption: stakeHolder => stakeHolder.id === ALL_FILTER_OPTION_VALUE,
      }
    ) as {
      id: string
      name: string
    }[]
  }

  return newState
}

export const useDocumentFilters = () => {
  const [state, dispatch] = useReducer(documentFiltersReducer, initialState)
  const [isFiltersTouched, setIsFiltersTouched] = useState(false)

  const isFiltersEmpty = useMemo(() => Object.values(state).every(value => !value), [state])

  const changeFilter = useCallback((filter: Omit<DocumentFiltersAction, 'actionType'>) => {
    dispatch({ actionType: 'change', ...filter })
    setIsFiltersTouched(true)
  }, [])

  const resetFilters = useCallback(() => {
    dispatch({ actionType: 'reset' })
    setIsFiltersTouched(false)
  }, [])

  return {
    filtersState: state,
    isFiltersEmpty,
    isFiltersTouched,
    changeFilter,
    resetFilters,
  }
}
