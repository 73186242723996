import config from '@/config'
import { useSearchParam } from '@/hooks/use-search-params'
import { initializeApp } from 'firebase/app'
import { browserSessionPersistence, getAuth, setPersistence } from 'firebase/auth'
import { useEffect } from 'react'

export const app = initializeApp({
  apiKey: config.FIREBASE_API_KEY,
  authDomain: config.FIREBASE_AUTH_DOMAIN,
})

export const auth = getAuth(app)

/**
 * By default firebase is using IndexedDB to persist session-
 * This is not working well with E2E tests.
 * We need to change to use local storage only in e2e tests.
 */
export const useAuthDevelopmentMode = () => {
  const mode = useSearchParam('mode')

  useEffect(() => {
    if (mode === 'dev') {
      setPersistence(auth, browserSessionPersistence)
    }
  }, [mode])
}
