import { Entry, EntryCreateInput } from '@/features/entries'

export enum IssueStatus {
  /**
   * @deprecated use IN_PROGRESS instead
   */
  OPEN = 'open',
  /**
   * @deprecated use IN_PROGRESS instead
   */
  ACKNOWLEDGED = 'acknowledged',
  IN_PROGRESS = 'inProgress',
  IN_REVIEW = 'inReview',
  RESOLVED = 'resolved',
  CLOSED = 'closed',
}

export interface Issue {
  _id: string
  companyId: string
  templateId: string
  templateVersion: number
  entries: Entry[]
  status: IssueStatus

  associatedTaskId?: string

  responder: string

  tags?: string[]

  media: {
    url: string
    mimeType: string
  }[]

  comments: { message: string; createdBy: string; createdAt: string }[]

  resolvedAt?: string

  notificationAttempts: number
  lastNotificationAt: string

  createdAt: string
  createdBy: string

  updatedAt: string
  updatedBy: string
}

export type DirtyIssue = Pick<Issue, 'templateId' | 'templateVersion'> & {
  id?: string
  taskEntryId: string
  responder?: string
  entries: EntryCreateInput[]
  media?: {
    url: string
    mimeType: string
    preview: string
  }[]
}

export type IssueUpdateOrCreate = Pick<Issue, 'templateId' | 'templateVersion' | 'entries'> & {
  id?: string
  associatedTaskId?: string
  responder?: string
}

export type IssueUpdateOrCreateWithTaskEntryId = IssueUpdateOrCreate & { taskEntryId: string }

export type IssueChange =
  | {
      operation: 'create' | 'update'
      value: IssueUpdateOrCreateWithTaskEntryId
    }
  | { operation: 'delete'; value: string }

export type IssueChanges = Array<
  Partial<Pick<Issue, 'templateId' | 'templateVersion' | 'tags' | 'responder' | 'entries'>> & {
    id?: string
    taskEntryId?: string
    delete?: boolean
  }
>

export interface IssuesSummaryReportData {
  totalIssues: number
  averageIssueTime: number // average of difference in hours between the createdAt and the resolvedAt dates of the issues within that range //
  closedIssues: number
  openIssues: number
  pendingIssues: number
  items: Issue[]
}

export interface IssuesSummaryReportFilters {
  startDate: Date
  endDate: Date
}
