import { Audit, AuditOperation, useAuditsById } from '@/features/audits'
import { useGetUserName } from '@/features/users'
import {
  COLOR,
  Feeds,
  Loader,
  NEW_COLOR,
  ReloadIcon,
} from '@blockchain-traceability-sl/tailwind-components'
import { FeedProps } from '@blockchain-traceability-sl/tailwind-components/dist/components/molecules/Feeds/src/Feed'
import {
  AnnotationIcon,
  CheckIcon,
  EyeIcon,
  LockClosedIcon,
  MailIcon,
} from '@heroicons/react/outline'
import { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useIssueById } from '../hooks'
import { Issue, IssueStatus } from '../interfaces'
interface IssueHistoryProps {
  issueId: string
}

export const IssueHistory = ({ issueId }: IssueHistoryProps) => {
  const { t } = useTranslation('nsModalTaskIssues')

  const { issue, isLoading: isLoadingIssues } = useIssueById(issueId)
  const { audits, isLoading: isLoadingAudits } = useAuditsById<Issue>(issueId)
  const { getUserName } = useGetUserName(t('userDeleted'))

  const isLoading = isLoadingIssues || isLoadingAudits

  const formatAuditToFeed = useCallback(
    (audit: Audit<Issue>, index: number, audits: Audit<Issue>[]): FeedProps | undefined => {
      // Issue is created and the receiver notified
      if (audit.operation === AuditOperation.CREATE) {
        return {
          title: (
            <div className='text-left whitespace-pre-wrap'>
              <Trans
                t={t}
                i18nKey='history.notified'
                components={{ b: <b /> }}
                values={{ name: getUserName(audit.data.responder) }}
              />
            </div>
          ),
          time: audit.createdAt,
          color: COLOR.BLUE,
          iconColor: COLOR.BLUE,
          icon: MailIcon,
          isIconLight: true,
          content: null,
        }
      }

      // Each time the receiver is notified
      if (audit.operation === AuditOperation.UPDATE) {
        const previousAudit = audits[index - 1]

        if (audit.data.status === IssueStatus.IN_PROGRESS) {
          if (previousAudit && previousAudit.data.status !== IssueStatus.IN_PROGRESS) {
            return {
              title: (
                <div className='text-left whitespace-pre-wrap'>
                  <Trans t={t} i18nKey='history.inProgress' components={{ b: <b /> }} />
                </div>
              ),
              time: audit.createdAt,
              color: COLOR.RED,
              iconColor: COLOR.RED,
              icon: ReloadIcon,
              isIconLight: true,
              content: null,
            }
          }
        }

        if (audit.data.status === IssueStatus.OPEN) {
          if (previousAudit && previousAudit.data.status !== IssueStatus.OPEN) {
            return {
              title: (
                <div className='text-left whitespace-pre-wrap'>
                  <Trans t={t} i18nKey='history.inProgress' components={{ b: <b /> }} />
                </div>
              ),
              time: audit.createdAt,
              color: COLOR.RED,
              iconColor: COLOR.RED,
              icon: ReloadIcon,
              isIconLight: true,
              content: null,
            }
          }
        }

        if (audit.data.status === IssueStatus.IN_REVIEW) {
          if (previousAudit && previousAudit.data.status !== IssueStatus.IN_REVIEW) {
            return {
              title: (
                <div className='text-left whitespace-pre-wrap'>
                  <Trans
                    t={t}
                    i18nKey='history.inReview'
                    components={{ b: <b /> }}
                    values={{ userName: getUserName(audit.data.updatedBy) }}
                  />
                </div>
              ),
              time: audit.createdAt,
              color: COLOR.YELLOW,
              iconColor: COLOR.YELLOW,
              icon: EyeIcon,
              isIconLight: true,
              content: null,
            }
          }
        }

        if (audit.data.status === IssueStatus.RESOLVED) {
          if (previousAudit && previousAudit.data.status !== IssueStatus.RESOLVED) {
            return {
              title: (
                <div className='text-left whitespace-pre-wrap'>
                  <Trans
                    t={t}
                    i18nKey='history.inSolved'
                    components={{ b: <b /> }}
                    values={{ userName: getUserName(audit.data.updatedBy) }}
                  />
                </div>
              ),
              time: audit.createdAt,

              color: COLOR.PURPLE,

              iconColor: COLOR.PURPLE,

              icon: CheckIcon,
              isIconLight: true,
              content: null,
            }
          }
        }

        if (audit.data.status === IssueStatus.CLOSED) {
          if (
            (previousAudit && previousAudit.data.status !== IssueStatus.CLOSED) ||
            !previousAudit
          ) {
            return {
              title: (
                <div className='text-left whitespace-pre-wrap'>
                  <Trans
                    t={t}
                    i18nKey='history.closed'
                    components={{ b: <b /> }}
                    values={{ userName: getUserName(audit.data.updatedBy) }}
                  />
                </div>
              ),
              time: audit.createdAt,
              color: COLOR.GREEN,
              iconColor: COLOR.GREEN,
              icon: LockClosedIcon,
              isIconLight: true,
              content: null,
            }
          }
        }

        if (
          previousAudit &&
          previousAudit.data.notificationAttempts !== audit.data.notificationAttempts
        ) {
          return {
            title: (
              <div className='text-left whitespace-pre-wrap'>
                <Trans
                  t={t}
                  i18nKey='history.notified'
                  components={{ b: <b /> }}
                  values={{ name: getUserName(audit.data.responder) }}
                />
              </div>
            ),
            time: audit.createdAt,
            color: COLOR.BLUE,
            iconColor: COLOR.BLUE,
            icon: MailIcon,
            isIconLight: true,
            content: null,
          }
        }
      }
    },
    [getUserName, t]
  )

  const issueHistoryFeeds = useMemo(
    (): FeedProps[] =>
      [
        ...(issue?.comments || []).map(
          (comment): FeedProps => ({
            title: (
              <div className='text-left whitespace-pre-wrap'>
                <Trans
                  t={t}
                  i18nKey='history.commented'
                  components={{ b: <b /> }}
                  values={{ name: getUserName(comment.createdBy), message: comment.message }}
                />
              </div>
            ),
            time: comment.createdAt,
            color: NEW_COLOR.ORANGE,
            iconColor: NEW_COLOR.ORANGE,
            icon: AnnotationIcon,
            isIconLight: true,
            content: null,
          })
        ),
        ...audits
          .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
          .filter((audit, index) => {
            // eslint-disable-next-line no-console
            return audit
          })
          .map(formatAuditToFeed)
          .filter((feed): feed is FeedProps => !!feed),
      ].map(feed => ({ ...feed, time: t('history.date', { date: new Date(feed.time) }) })),
    [audits, formatAuditToFeed, getUserName, issue?.comments, t]
  )

  if (isLoading || !issue) {
    return <Loader center='full' />
  }
  return (
    <div>
      <Feeds feeds={issueHistoryFeeds} />
    </div>
  )
}
