import { useEffect, useState } from 'react'

interface ImageMeta {
  height: number
  width: number
  aspectRatio: number
}

/**
 * Get image meta data
 * @param url
 * @returns {Promise<ImageMeta>}
 * @example
 * const meta = await getImageMeta('https://example.com/image.jpg')
 * console.log(meta)
 */
const getImageMeta = async (url: string): Promise<ImageMeta> => {
  const img = new Image()
  img.src = url
  await img.decode()

  return {
    height: img.naturalHeight,
    width: img.naturalWidth,
    aspectRatio: img.naturalWidth / img.naturalHeight,
  }
}

/**
 * React hook to get image meta data
 * @param imageUrl
 * @returns {[ImageMeta | undefined, boolean]}
 * @example
 * const [meta, error] = useImageMeta('https://example.com/image.jpg')
 * console.log(meta)
 * console.log(error)
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement
 */
export const useImageMeta = (imageUrl: string): [ImageMeta | undefined, boolean] => {
  const [imageMeta, setImageMeta] = useState<ImageMeta>()
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    getImageMeta(imageUrl)
      .then(meta => {
        setImageMeta(meta)
        setError(false)
      })
      .catch(() => setError(true))
  }, [imageUrl])

  return [imageMeta, error]
}
