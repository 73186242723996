import { Issue, IssueUpdateOrCreateWithTaskEntryId } from './interfaces'

export const calculateIssueChanges = (
  newIssues: IssueUpdateOrCreateWithTaskEntryId[],
  initialIssues: Pick<Issue, '_id'>[]
): Array<
  Partial<
    Pick<Issue, 'templateId' | 'templateVersion' | 'tags' | 'responder' | 'entries' | 'media'>
  > & {
    id?: string
    taskEntryId?: string
    delete?: boolean
  }
> => {
  const changes: Array<
    Partial<
      Pick<Issue, 'templateId' | 'templateVersion' | 'tags' | 'responder' | 'media' | 'entries'>
    > & {
      id?: string
      taskEntryId?: string
      media?: {
        url: string
        mimeType: string
        preview: string
      }[]
      delete?: boolean
    }
  > = newIssues

  initialIssues.forEach(issue => {
    const exists = newIssues.find(item => item.id === issue._id)
    if (!exists) {
      changes.push({
        id: issue._id,
        delete: true,
      })
    }
  })

  return changes
}
