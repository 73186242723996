import { Document, useDocumentsByIds } from '@/features/documents'
import { useToggle } from '@/hooks/use-toggle'
import { useState } from 'react'

export const useDocumentPicker = <I = number>(documentIds: string[]) => {
  const [showDocumentPicker, toggleShowDocumentPicker] = useToggle(false)

  const { documents } = useDocumentsByIds(documentIds)

  const [documentsPicked, setDocumentsPicked] = useState<Document[]>(documents)
  const [documentPickerTrigger, setDocumentPickerTrigger] = useState<I | null>(null)

  return {
    showDocumentPicker,
    toggleShowDocumentPicker,
    documentsPicked,
    setDocumentsPicked,
    documentPickerTrigger,
    setDocumentPickerTrigger,
  }
}
