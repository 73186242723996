import { downloader } from './downloader'

/**
 * Download an in memory csv file
 */
export const downloadCSV = (csv: BlobPart, filename: string) => {
  downloader(['\ufeff', csv], 'text/csv', filename)
}

/**
 * Transform a table data in to csv type
 */
export const tableToCSV = (columns: string[], data: string[][]): string => {
  const csv = []

  csv.push(columns.join(','))
  data.forEach(row =>
    csv.push(row.map(cell => (cell.includes(',') ? `"${cell.trim()}"` : cell.trim())).join(','))
  )

  return csv.join('\n')
}
