import { Audit, AuditOperation } from '@/features/audits'
import { COLOR } from '@blockchain-traceability-sl/tailwind-components'
import { FeedProps } from '@blockchain-traceability-sl/tailwind-components/dist/components/molecules/Feeds/src/Feed'
import { CheckIcon, ClipboardCheckIcon, MailIcon, PencilAltIcon } from '@heroicons/react/outline'
import { Trans } from 'react-i18next'
import {
  Compliance,
  ComplianceStatus,
  ComplianceValidation,
  ComplianceValidationStatus,
} from '../../interfaces'
import { differenceInSeconds } from 'date-fns'
import { XIcon } from '@heroicons/react/solid'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'

export const getAuditValidationState = (
  audit: Audit<Compliance, AuditOperation>
): ComplianceValidation | undefined => {
  if (audit.data.validationState && audit.data.validationState.length > 0) {
    const refDate = new Date(audit.createdAt)
    const validationStates = audit.data.validationState
    const nearest = validationStates.reduce((a, b) =>
      differenceInSeconds(refDate, new Date(a.validatedAt)) <
      differenceInSeconds(refDate, new Date(b.validatedAt))
        ? a
        : b
    )
    return nearest
  }
}

export const complianceAuditToFeed = (
  {
    complianceId,
    audit,
    index,
    audits,
  }: {
    complianceId: string
    audit: Audit<Compliance>
    index: number
    audits: Audit<Compliance>[]
  },
  { t }: { t: TFunction }
) => {
  if (audit.operation === AuditOperation.CREATE) {
    return {
      color: COLOR.BLUE,
      iconColor: COLOR.BLUE,
      isIconLight: true,
      icon: MailIcon,
      content: '',
      title: (
        <span className='flex justify-start flex-col'>
          <span className='text-left'>
            {audit.data.requesterCompany.name}{' '}
            <Trans t={t} i18nKey='action.create' components={{ b: <b /> }} />{' '}
            {audit.data.compliantStakeHolder.emails.join(',')}
          </span>
        </span>
      ),
      time: dayjs(audit.createdAt).format('DD/MM/YYYY, HH:mm'),
    }
  }

  if (audit.operation !== AuditOperation.UPDATE) {
    return undefined
  }

  if (audit.data.status === ComplianceStatus.ACCEPTED && index < audits.length - 1) {
    const validationState = getAuditValidationState(audit)
    const nextAudit = audits[index + 1]
    const nextAuditValidationState = getAuditValidationState(nextAudit)

    if (
      nextAuditValidationState?.status === validationState?.status &&
      validationState?.status === ComplianceValidationStatus.APPROVED
    ) {
      // If the audit is not the current compliance omit it
      if (audit.data._id !== complianceId) return undefined

      return {
        color: COLOR.YELLOW,
        iconColor: COLOR.YELLOW,
        isIconLight: true,
        icon: PencilAltIcon,
        content: '',
        title: (
          <span className='flex justify-start flex-col'>
            <span className='text-left'>
              {audit.data.requesterCompany.name}{' '}
              <Trans t={t} i18nKey='action.edit' components={{ b: <b /> }} />{' '}
            </span>
          </span>
        ),
        time: dayjs(audit.createdAt).format('DD/MM/YYYY, HH:mm'),
      }
    }

    if (audit.data._id === complianceId) {
      return {
        color: COLOR.GREEN,
        iconColor: COLOR.GREEN,
        isIconLight: true,
        icon: CheckIcon,
        content: '',
        title: (
          <span className='flex justify-start flex-col'>
            <span className='text-left'>
              {audit.data.requesterCompany.name}{' '}
              <Trans t={t} i18nKey='action.approve' components={{ b: <b /> }} />{' '}
            </span>
          </span>
        ),
        time: dayjs(audit.createdAt).format('DD/MM/YYYY, HH:mm'),
      }
    }
  }

  let feed: FeedProps | undefined
  const previousAudit = audits[index - 1]
  if (previousAudit?.data?.status === audit.data.status) return feed
  switch (audit.data.status) {
    case ComplianceStatus.SUBMITTED:
      feed = {
        color: COLOR.INDIGO,
        iconColor: COLOR.INDIGO,
        isIconLight: true,
        icon: ClipboardCheckIcon,
        content: '',
        title: (
          <span className='flex justify-start flex-col'>
            <span className='text-left'>
              {audit.data.compliantStakeHolder.emails.join(',')}{' '}
              <Trans t={t} i18nKey='action.update' components={{ b: <b /> }} />{' '}
            </span>
          </span>
        ),
        time: dayjs(audit.createdAt).format('DD/MM/YYYY, HH:mm'),
      }
      break
    case ComplianceStatus.PENDING:
      if (audit.data.validationState && audit.data.validationState.length) {
        const validationState = getAuditValidationState(audit)
        feed = {
          color: COLOR.RED,
          icon: XIcon,
          iconColor: COLOR.RED,
          isIconLight: true,
          content: '',
          title: (
            <span className='flex justify-start flex-col'>
              <span className='text-left'>
                {audit.data.requesterCompany.name}{' '}
                <Trans t={t} i18nKey='action.reject' components={{ b: <b /> }} /> <br />
              </span>
              <span className='text-left'>
                <Trans t={t} i18nKey='action.actionMessage' components={{ b: <b /> }} />{' '}
                {validationState ? validationState.message : ' - '}
              </span>
            </span>
          ),
          time: dayjs(audit.createdAt).format('DD/MM/YYYY, HH:mm'),
        }
      }
      break
  }
  return feed
}
