export enum ValidationRequestStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum ValidationRequestType {
  DOCUMENT = 'document',
  TASK = 'task',
}

export interface ValidationRequestReviewer {
  id: string
  name: string
  email: string
  language: string
  status: ValidationRequestStatus
  signed?: boolean
  message: string
  validationDate?: string
}

export interface ValidationRequest {
  _id: string
  companyId: string
  requester: {
    id: string
    email: string
    language?: string
  }
  entity: {
    id: string
    name: string
    type: ValidationRequestType
  }
  status: ValidationRequestStatus
  reviewers: ValidationRequestReviewer[]
  message: string
  createdAt: string
  updatedAt: string
}

export interface ValidationRequestCreation {
  entity: {
    id: string
    name: string
  }
  language?: string
  redirectUrl: string
  type: ValidationRequestType
  reviewers: Array<{
    id: string
    email: string
    language?: string
    signed?: boolean
  }>
  message?: string
}

export interface ValidationRequestValidation {
  validationRequestId: string
  status: ValidationRequestStatus.APPROVED | ValidationRequestStatus.REJECTED
  redirectUrl: string
  message?: string
  signed?: boolean
  entityName: string
  type: string
}
