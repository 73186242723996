import { downloadCSV, tableToCSV } from '@/helpers/csv'
import { BulkError, ErrorCodes } from '@blockchain-traceability-sl/custom-error-codes'

export const downloadProductBulkErrorReport = (bulkError: BulkError) => {
  downloadCSV(
    tableToCSV(
      ['index', 'status', 'entity', 'reason'],
      bulkError.errors.map(({ index, error }) => [
        String(index),
        error.code === ErrorCodes.ALREADY_EXISTS ? 'OK' : 'FAIL',
        error.entity,
        error.message,
      ])
    ),
    'error-report'
  )
}
