import { api } from '@/app/api'

/**
 * Define the url path
 * @constant
 */
export const PDF_URL = '/charts/pdf'

export const generatePdfFromDefinition = async (
  pdfDefinition: string,
  filename: string
): Promise<Blob | undefined> => {
  const { data } = await api.post<Blob>(
    `${PDF_URL}/definition`,
    {
      definition: pdfDefinition,
      filename,
    },
    {
      responseType: 'blob',
    }
  )
  return data
}
