import { ComponentProps } from 'react'

export const TaskIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.71 1.416C3.985.688 4.725.166 5.596.166H6.93c.87 0 1.611.522 1.886 1.25h.78c1.105 0 2 .84 2 1.875v7.5c0 1.035-.895 1.875-2 1.875H2.93c-1.105 0-2-.84-2-1.875v-7.5c0-1.036.895-1.875 2-1.875h.78zm0 1.25h-.78c-.369 0-.667.28-.667.625v7.5c0 .345.298.625.667.625h6.666c.368 0 .667-.28.667-.625v-7.5c0-.345-.299-.625-.667-.625h-.78c-.275.728-1.016 1.25-1.886 1.25H5.596c-.87 0-1.611-.522-1.886-1.25zm1.886-1.25c-.368 0-.666.28-.666.625s.298.625.666.625H6.93c.368 0 .666-.28.666-.625s-.298-.625-.666-.625H5.596z'
        fill='#2563EB'
      />
    </svg>
  )
}
