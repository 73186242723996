import { InputField } from '@blockchain-traceability-sl/tailwind-components'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { SwitchVerticalIcon, TrashIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { useField } from 'formik'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductMetadataItem } from '../../interfaces'

export interface SortableMetadataEntryProps {
  id: string
  index: number
}

export const SortableMetadataEntry = ({ id, index }: SortableMetadataEntryProps) => {
  const { t } = useTranslation('nsProductCreationManualPage')

  const [keyField, keyMeta] = useField<string>(`metadata.${index}.key`)
  const [valueField, valueMeta] = useField<string>(`metadata.${index}.value`)
  const [metadataField, , metadataHelper] = useField<
    (ProductMetadataItem & { id: string })[] | undefined
  >('metadata')

  const { attributes, listeners, setNodeRef, transform, transition, isDragging, isSorting } =
    useSortable({ id })

  const style = useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition: !isDragging || !isSorting ? 'none' : transition,
    }),
    [transform, transition, isDragging, isSorting]
  )

  const handleDelete = () => {
    metadataHelper.setValue(metadataField.value?.filter(entry => entry.id !== id))
  }

  return (
    <div ref={setNodeRef} className='flex' style={style}>
      <div className='flex-grow grid grid-cols-1 md:grid-cols-2 gap-4'>
        <InputField
          id={`metadata_${index}_key`}
          {...keyField}
          label={t('form.metadata.inputName')}
          error={!!(keyMeta.error && keyMeta.touched)}
        />
        <InputField
          id={`metadata_${index}_value`}
          {...valueField}
          label={t('form.metadata.inputValue')}
          error={!!(valueMeta.error && valueMeta.touched)}
        />
      </div>

      {/* Actions */}
      <div className='flex-shrink ml-2 flex flex-col md:flex-row gap-2 mt-7'>
        <div>
          <button
            type='button'
            className='inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
            onClick={handleDelete}
          >
            <span>
              <TrashIcon className='h-5 w-5' aria-hidden='true' />
            </span>
          </button>
        </div>

        <div>
          <button
            type='button'
            className={classNames(
              'inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500',
              {
                'cursor-move': isDragging || isSorting,
              }
            )}
            {...attributes}
            {...listeners}
          >
            <span>
              <SwitchVerticalIcon className='h-5 w-5' aria-hidden='true' />
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}
