import { api } from '@/app/api'
import {
  ValidationRequest,
  ValidationRequestCreation,
  ValidationRequestStatus,
  ValidationRequestType,
  ValidationRequestValidation,
} from '.'

/**
 * Define the url path
 * @constant
 */
export const VALIDATIONS_URL = '/validations/validations'

/**
 * Create new validation request
 *
 * @param validationRequest - New ValidationRequest to create
 */
export const createValidationRequest = async (
  validationRequest: ValidationRequestCreation,
  params?: URLSearchParams
): Promise<void> => {
  await api.post<void>(VALIDATIONS_URL, validationRequest, { params })
}

/**
 * Get all validation request pending to validate
 *
 * @returns All ValidationRequests by Entity ids
 */
export const getByEntityIds = async (entityIds: string[]): Promise<ValidationRequest[]> => {
  if (entityIds.length === 0) return []
  const { data } = await api.post<ValidationRequest[] | void>(`${VALIDATIONS_URL}/latest`, {
    ids: entityIds,
  })
  return data || []
}

export const getLatestValidations = async ({
  types = [],
  status = [],
}: {
  types?: ValidationRequestType[]
  status?: ValidationRequestStatus[]
} = {}): Promise<ValidationRequest[]> => {
  const searchParams = new URLSearchParams()
  types.forEach(type => {
    searchParams.append('types[]', type)
  })
  status.forEach(value => {
    searchParams.append('status[]', value)
  })
  const { data } = await api.get<ValidationRequest[] | void>(
    `${VALIDATIONS_URL}/latest?${searchParams.toString()}`
  )
  return data || []
}

export const getPendingToValidateCount = async ({
  types = [],
}: {
  types?: ValidationRequestType[]
} = {}): Promise<number> => {
  const searchParams = new URLSearchParams()
  types.forEach(type => {
    searchParams.append('types[]', type)
  })
  const { data } = await api.get<{ count: number }>(
    `${VALIDATIONS_URL}/pending/count?${searchParams.toString()}`
  )
  return data.count
}

export const getPendingToValidate = async (): Promise<ValidationRequest[]> => {
  const { data } = await api.get<ValidationRequest[] | void>(`${VALIDATIONS_URL}/pending`)
  return data || []
}

/**
 * Validate
 *
 * @params Validation data
 */
export const validate = async ({
  validationRequestId,
  ...validation
}: ValidationRequestValidation): Promise<void> => {
  const object: {
    status: string
    redirectUrl: string
    message?: string
    signed?: boolean
  } = {
    status: validation.status,
    redirectUrl: validation.redirectUrl,
    signed: validation.signed,
  }
  if (validation.message) {
    object.message = validation.message
  }
  await api.post<void>(`${VALIDATIONS_URL}/${validationRequestId}`, object)
}

export const getById = async (
  validationRequestId: string
): Promise<ValidationRequest | undefined> => {
  const { data } = await api.get<ValidationRequest>(`${VALIDATIONS_URL}/${validationRequestId}`)
  return data
}
