import { useCompanyUser, useUserId } from '@/features/auth'
import { useDocumentById } from '@/features/documents'
import { useIssueById } from '@/features/issues'
import { useStakeHolderById } from '@/features/stakeHolders'
import { useTaskById } from '@/features/tasks'
import { useTemplate } from '@/features/templates'
import { useUsers } from '@/features/users'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  BroadcastType,
  Notification,
  NotificationAction,
  NotificationEntityType,
} from '../interfaces'
import { IconByAction, IconColorByAction } from './item.styles'
import { useValidationRequestById } from '@/features/validationRequests'

export interface NotificationItemProps {
  notification: Notification
}

export const NotificationItem = ({ notification }: NotificationItemProps) => {
  const { t } = useTranslation('nsNotificationPanel')

  const { users } = useUsers()
  const companyUser = useCompanyUser()
  const userId = useUserId()
  const { validationRequest } = useValidationRequestById(notification.entityId, {
    enabled: notification.entityType === NotificationEntityType.VALIDATION,
  })

  const stakeHolderId =
    notification.action === NotificationAction.COMPLIANCE_SUBMITTED
      ? notification.triggeredBy
      : (notification.payload?.stakeHolderId as string)

  const { stakeHolder } = useStakeHolderById(stakeHolderId, {
    enabled: notification.entityType === NotificationEntityType.COMPLIANCE && !!stakeHolderId,
  })

  const { issue } = useIssueById(notification.entityId, {
    enabled: notification.entityType === NotificationEntityType.ISSUE,
  })

  const { document: documentFolder } = useDocumentById(
    (notification.payload as { targetFolderId?: string } | undefined)?.targetFolderId || '',
    {
      enabled: notification.entityType === NotificationEntityType.UPLOAD_REQUEST,
    }
  )

  const { task } = useTaskById(
    notification.entityType === NotificationEntityType.TASK
      ? notification.entityId
      : (notification.payload?.associatedTaskId as string),
    {
      enabled: !!(notification.entityType === NotificationEntityType.TASK
        ? notification.entityId
        : (notification.payload?.associatedTaskId as string)),
    }
  )

  const { template } = useTemplate(task?.formId || '', 'form', { enabled: !!task?.formId })

  const notificationTranslationKey = useMemo((): string => {
    switch (notification.action) {
      case NotificationAction.TASK_ASSIGNED:
        if (notification.payload?.assignedTo === userId) {
          return `content.${notification.entityType}.assignedYou`
        } else {
          return `content.${notification.entityType}.assigned`
        }
      case NotificationAction.TASK_DELETED:
        return `content.${notification.entityType}.deleted`
      case NotificationAction.TASK_CLOSED:
        return `content.${notification.entityType}.closed`
      case NotificationAction.TASK_REPORT_SENT:
        return `content.${notification.entityType}.reportSend`

      case NotificationAction.ISSUE_RESPONDER_COMMUNICATION:
      case NotificationAction.ISSUE_COMMUNICATED:
        return `content.${notification.entityType}.communicated`
      case NotificationAction.ISSUE_COMMENTED:
        return `content.${notification.entityType}.commented`
      case NotificationAction.ISSUE_RESOLVED:
        return `content.${notification.entityType}.resolved`
      case NotificationAction.COMPLIANCE_SUBMITTED:
        return `content.${notification.entityType}.submitted`
      case NotificationAction.COMPLIANCE_ALMOST_EXPIRED:
        return `content.${notification.entityType}.almostExpired`
      case NotificationAction.COMPLIANCE_EXPIRED:
        return `content.${notification.entityType}.expired`
      case NotificationAction.UPLOAD_REQUEST_CONFIRMED:
        return `content.${notification.entityType}.confirmed`
      case NotificationAction.VALIDATION_CREATED:
        switch (notification.broadcastType) {
          case BroadcastType.ALL_ADMINS:
            return `content.${notification.entityType}.assigned`
          case BroadcastType.SINGLE:
            return `content.${notification.entityType}.assignedYou`
          default:
            return ''
        }
      default:
        return ''
    }
  }, [
    notification.action,
    notification.payload?.assignedTo,
    notification.entityType,
    notification.broadcastType,
    userId,
  ])

  const notificationContentValues = useMemo(() => {
    let trigger
    let target = null
    let entity

    // Set trigger
    if (notification.triggeredBy === companyUser._id) {
      trigger = companyUser.name
    } else {
      const userTrigger = users.find(user => user._id === notification.triggeredBy)

      if (userTrigger) {
        trigger = userTrigger.publicName
      } else {
        trigger = t('userDeleted')
      }
    }

    switch (notification.entityType) {
      case NotificationEntityType.TASK: {
        if (notification.payload?.assignedTo === companyUser._id) {
          target = companyUser.name
        } else {
          const user = users.find(user => user._id === notification.payload?.assignedTo)

          if (user) {
            target = user.publicName
          } else {
            target = t('userDeleted')
          }
        }
        break
      }

      case NotificationEntityType.ISSUE: {
        if (issue?.responder === companyUser._id) {
          target = companyUser.name
        } else {
          const user = users.find(user => user._id === issue?.responder)

          if (user) {
            target = user.publicName
          } else {
            target = t('userDeleted')
          }
        }
        break
      }

      case NotificationEntityType.VALIDATION: {
        if (validationRequest) {
          const targets = validationRequest.reviewers.map(reviewer =>
            reviewer.id === companyUser._id
              ? companyUser
              : users.find(user => user._id === reviewer.id)
          )
          target =
            targets.length === 1 ? targets[0]?.name : targets.map(target => target?.name).join(', ')
        }
        entity = notification.payload?.entityName
        break
      }

      case NotificationEntityType.COMPLIANCE: {
        trigger = stakeHolder?.company.name || stakeHolder?.company.email || t('companyDeleted')
        break
      }

      case NotificationEntityType.UPLOAD_REQUEST: {
        target = documentFolder?.title || ''
        break
      }
    }
    return {
      ...notification.payload,
      user: trigger,
      entity: entity || template?.name || notification.payload?.formName,
      taskName: entity,
      target,
      date: new Date(notification.createdAt),
    }
  }, [
    notification.triggeredBy,
    notification.entityType,
    notification.payload,
    notification.createdAt,
    companyUser,
    users,
    t,
    template?.name,
    issue?.responder,
    validationRequest,
    stakeHolder?.company.name,
    stakeHolder?.company.email,
    documentFolder?.title,
  ])

  const notificationIcon = useMemo(() => {
    const Icon = IconByAction[notification.action]

    return (
      <div
        className={`relative w-8 h-8 rounded-full ${IconColorByAction[notification.action]?.bg}`}
      >
        <span className='flex justify-center w-full h-full'>
          <Icon className={`w-5 h-5 m-auto ${IconColorByAction[notification.action]?.text}`} />
        </span>

        {/* Active dot */}
        {!notification.acknowledged && (
          <span className='absolute top-1 right-1 block h-2 w-2 transform -translate-y-1/2 translate-x-1/2 rounded-full bg-red-600' />
        )}
      </div>
    )
  }, [notification.acknowledged, notification.action])

  return (
    <div className='flex space-x-2'>
      {/* Icon */}
      <div className='flex-shrink'>{notificationIcon}</div>

      {/* Description */}
      <div className='text-gray-700 text-base'>
        <Trans
          t={t}
          i18nKey={notificationTranslationKey}
          defaults='N/A'
          components={[
            <span className='text-gray-900 font-semibold' />,
            <span className='text-gray-500' />,
          ]}
          values={notificationContentValues}
        />
      </div>
    </div>
  )
}
