import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query'
import * as NotifyService from '@/services/notify.service'
import {
  createSubscription,
  getPublicPricingPlans,
  getSubscriptionCustomerPortal,
  getSubscriptionInfo,
  getSubscriptionPaymentInfo,
  updateSubscriptionPlan,
} from './service'
import { AxiosError } from 'axios'
import {
  PublicPricingPlans,
  SubscriptionInfo,
  SubscriptionInfoOperationType,
  SubscriptionPaymentInfo,
  SubscriptionPaymentMethod,
} from './interfaces'
import { useMemo, useRef } from 'react'
import { customErrorToTrack, useAnalytics } from '../analytics'
import { useTranslation } from 'react-i18next'
import { useAuthentication, useMyProfile } from '../auth'
import { mergeQueryOptions } from '@/utils/merge-query-options'
import { useHistory } from 'react-router-dom'

export const QUERY_PUBLIC_PRICING_PLANS_KEY = 'public-pricing-plans'
export const QUERY_SUBSCRIPTION_PAYMENT_INFO_KEY = 'subscription-payment-info'
export const QUERY_SUBSCRIPTION_INFO_KEY = 'subscription-info'

export const usePublicPricingPlans = (
  options?: Omit<UseQueryOptions<PublicPricingPlans, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(QUERY_PUBLIC_PRICING_PLANS_KEY, getPublicPricingPlans, options)

  const pricingPlans = useMemo(
    () =>
      data
        ? {
            ...data,
            primaries: data?.primaries.sort((a, b) => a.score - b.score) || [],
            modules: data?.modules.sort(a => (a.name === 'transparency' ? -1 : 1)) || [],
          }
        : undefined,
    [data]
  )

  return { pricingPlans, ...rest }
}

export const useCreateSubscription = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsRegisterPage')
  const { logout } = useAuthentication()
  const { mutate, mutateAsync, ...rest } = useMutation(createSubscription, {
    async onSuccess(data, variables) {
      analytics.track('CUSTOMER_CREATE_SUBSCRIPTION_SUCCESS')
      logout()
      if (variables.paymentMethod === SubscriptionPaymentMethod.INVOICE) {
        NotifyService.success({
          title: t('notification.successful.title'),
          description: t('notification.successful.description'),
        })
        setTimeout(() => {
          window.location.replace(data.hostedPageUrl)
        }, 5000)
      } else {
        window.location.replace(data.hostedPageUrl)
      }
    },

    onError: (error: AxiosError) => {
      analytics.track(
        'CUSTOMER_CREATE_SUBSCRIPTION_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return {
    createSubscription: mutate,
    createSubscriptionAsync: mutateAsync,
    ...rest,
  }
}

export const useSubscriptionPaymentInfo = (
  options?: Omit<UseQueryOptions<SubscriptionPaymentInfo, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    QUERY_SUBSCRIPTION_PAYMENT_INFO_KEY,
    getSubscriptionPaymentInfo,
    options
  )
  return { subscriptionPaymentInfo: data, ...rest }
}

export const useSubscriptionInfo = ({
  refetchOnUpdate,
  ...overrideOptions
}: Omit<UseQueryOptions<SubscriptionInfo, AxiosError>, 'queryKey' | 'queryFn'> & {
  refetchOnUpdate?: boolean
} = {}) => {
  const { refreshToken } = useAuthentication()
  const isUpdatingRef = useRef(false)
  const options = mergeQueryOptions(
    {
      retry(failureCount, error) {
        /**
         * If the error is a 400, it means the user has no subscription.
         */
        if (error?.response?.status === 400) {
          return false
        }

        return true
      },

      retryOnMount: false,

      refetchInterval(data) {
        /**
         * If the user has a pending upgrade operation, we refetch the data every second.
         */
        if (
          refetchOnUpdate &&
          data?.planBundle &&
          data?.planBundle.pendingOperations.find(
            operation =>
              operation.type === SubscriptionInfoOperationType.UPDATE ||
              /**
               * Upgrade are not used anymore, but we keep them here for compatibility reasons.
               */
              operation.type === SubscriptionInfoOperationType.UPGRADE
          )
        ) {
          return 1000
        }

        return false
      },

      /**
       *
       *  If the user upgraded and the pending operation was removed, we need to refresh the token to get the new entitlements.
       */
      onSuccess(data) {
        if (!refetchOnUpdate) return
        const wasUpdating = isUpdatingRef.current

        isUpdatingRef.current = !!data?.planBundle.pendingOperations.find(
          operation =>
            operation.type === SubscriptionInfoOperationType.UPDATE ||
            /**
             * Upgrade are not used anymore, but we keep them here for compatibility reasons.
             */
            operation.type === SubscriptionInfoOperationType.UPGRADE
        )

        if (wasUpdating && !isUpdatingRef.current) {
          refreshToken()
        }
      },
    },
    overrideOptions
  )
  const { data, ...rest } = useQuery(QUERY_SUBSCRIPTION_INFO_KEY, getSubscriptionInfo, options)

  return { subscriptionInfo: data, ...rest }
}

export const useUpdateSubscriptionPlan = (subscriptionInfo?: SubscriptionInfo) => {
  const analytics = useAnalytics()
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const { mutate, mutateAsync, ...rest } = useMutation(updateSubscriptionPlan, {
    async onSuccess(data, variables) {
      await queryClient.invalidateQueries(QUERY_SUBSCRIPTION_INFO_KEY)

      analytics.track('CUSTOMER_UPDATE_SUBSCRIPTION', {
        Plan: variables.primaryPlan,
        Addons: variables.modules?.join(',') || 'N/A',
        Users: variables.seatQuantity || 'N/A',
      })

      /**
       * The notification is displayed if the seat quantity is only updated
       */
      if (
        subscriptionInfo?.planBundle.seatQuantity !== variables.seatQuantity &&
        !variables.primaryPlan &&
        variables.modules?.length === subscriptionInfo?.planBundle.modules.length
      ) {
        NotifyService.success({
          title: t('billing.updateUser.success.title'),
          description: t('billing.updateUser.success.description'),
        })
        return
      }

      NotifyService.success({
        title: t('billing.update.success.title'),
        description: t('billing.update.success.description'),
      })
    },

    onError: (error: AxiosError) => {
      analytics.track(
        'CUSTOMER_UPDATE_SUBSCRIPTION_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )

      // if custom error is code 10 manage as warning
      if (error.response?.data?.code === 10) {
        NotifyService.warn(NotifyService.customErrorToNotify(error.response?.data))
        return
      }

      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return {
    updateSubscriptionPlan: mutate,
    updateSubscriptionPlanAsync: mutateAsync,
    ...rest,
  }
}

export const useGoToSubscriptionCustomerPortal = () => {
  const { mutate, ...rest } = useMutation(getSubscriptionCustomerPortal, {
    onSuccess({ url }) {
      window.location.href = url
    },
  })

  return {
    ...rest,
    goToSubscriptionCustomerPortal: mutate,
  }
}

export const useGoToSubscriptionPlanUpgrade = () => {
  const { t } = useTranslation('nsPricingPage')
  const { profile } = useMyProfile()
  const analytics = useAnalytics()
  const history = useHistory()

  return {
    goToSubscriptionPlanUpgrade: () => {
      analytics.track('ACTION_CLICK_UPGRADE_PLAN')

      if (!profile?.companyOwner) {
        window.location.href = t('header.contactLink')
        return
      }

      return history.push('/profile?tab=billing')
    },
  }
}
