import { Help } from '@/components/Help'
import { StepOverview } from '@/components/StepOverview'
import {
  ButtonWithIcon,
  Card,
  InputField,
  SelectField,
  SelectorOption,
  Step,
  Tooltip,
  createSelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { ArrowSmDownIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { FormikErrors, useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { Automation, AutomationTargetOperation } from '../interfaces'
import { useMemo } from 'react'
import { useFeatureFlag } from '@/features/flags'
import classNames from 'classnames'

export enum ExtendedAutomationTriggerType {
  SCHEDULE = 'schedule',
  ENTITY_EVENT = 'entity_event',
  RESCHEDULE = 'reschedule',
}

const AutomationTriggerTypes = Object.values(ExtendedAutomationTriggerType)

export const CreateAutomationStep = () => {
  const { t } = useTranslation('nsCreateAutomationStep')
  const { value: flagAutomationTime } = useFeatureFlag('personalize_hour_of_automation_execution')

  const { setTouched } = useFormikContext<Pick<Automation, 'name' | 'trigger'>>()

  const [nameField, nameMeta] = useField('name')
  const [triggerTypeField, triggerTypeMeta, triggerTypeHelpers] = useField('trigger.type')
  const [triggerTimeField, triggerTimeMeta, triggerTimeHelpers] = useField('trigger.schedule.time')
  const [, , operationTargetFieldHelpers] = useField('target.operation')

  const handleNext = async (callbackFunction: Function) => {
    const errors = (await setTouched({
      name: true,
      trigger: {
        type: true,
      },
    })) as unknown as FormikErrors<Pick<Automation, 'name' | 'trigger'>>

    if (!errors?.name && !errors?.trigger?.type) {
      callbackFunction()
    }
  }

  const triggerTypeOptions = useMemo(() => {
    return AutomationTriggerTypes.map(type =>
      createSelectorOption(t(`form.triggerType.options.${type}`), type)
    )
  }, [t])

  const triggerTimeOptions = Array.from({ length: 24 }, (v, index) =>
    createSelectorOption(`${String(index).padStart(2, '0')}:00`)
  )

  const handleTypeChange = (option: SelectorOption | null) => {
    triggerTypeHelpers.setValue(option?.value)

    if (option?.value === ExtendedAutomationTriggerType.RESCHEDULE) {
      operationTargetFieldHelpers.setValue(AutomationTargetOperation.UPDATE)
    } else {
      operationTargetFieldHelpers.setValue(AutomationTargetOperation.CREATE)
    }
  }

  const isSchedule =
    triggerTypeField.value === ExtendedAutomationTriggerType.SCHEDULE ||
    triggerTypeField.value === ExtendedAutomationTriggerType.RESCHEDULE

  return (
    <Step
      title={t('title')}
      subtitle={
        <div className='flex gap-3'>
          <span>{t('subtitle')}</span>
          <Tooltip tip={t('description')} cursor>
            <span>
              <InformationCircleIcon className='w-4 h-4 text-gray-400' />
            </span>
          </Tooltip>
        </div>
      }
      overview={
        <StepOverview
          items={[
            nameField.value,
            triggerTypeField.value ? t(`form.triggerType.options.${triggerTypeField.value}`) : '',
            flagAutomationTime ? triggerTimeField.value : undefined,
          ]}
        />
      }
    >
      {({ goNext }) => (
        <div className='space-y-6 m-1'>
          <Card className='overflow-visible'>
            <Card.Body>
              <div className='space-y-6'>
                <InputField
                  id={nameField.name}
                  {...nameField}
                  label={t('form.name.label')}
                  description={t('form.name.description')}
                  placeholder={t('form.name.placeholder')}
                  error={!!nameMeta.error && nameMeta.touched}
                  autoFocus
                />

                <div
                  className={classNames({
                    'grid grid-cols-1 md:grid-cols-2 gap-6': flagAutomationTime && isSchedule,
                  })}
                >
                  <SelectField
                    {...triggerTypeField}
                    id={triggerTypeField.name.replace('.', '_')}
                    key={triggerTypeField.name}
                    label={t('form.triggerType.label')}
                    description={t('form.triggerType.description')}
                    placeholder={t('form.triggerType.placeholder')}
                    options={triggerTypeOptions}
                    value={triggerTypeOptions.find(
                      option => option.value === triggerTypeField.value
                    )}
                    onBlur={() => {
                      triggerTypeHelpers.setTouched(true)
                    }}
                    onChange={option => {
                      handleTypeChange(option)
                    }}
                    error={!!triggerTypeMeta.error && triggerTypeMeta.touched}
                    menuPortalTarget={document.body}
                  />

                  {flagAutomationTime && isSchedule && (
                    <SelectField
                      {...triggerTimeField}
                      id={triggerTimeField.name.replace('.', '_')}
                      key={triggerTimeField.name}
                      options={triggerTimeOptions}
                      value={triggerTimeOptions.find(
                        option => option.value === triggerTimeField.value
                      )}
                      defaultValue={triggerTimeOptions[0]}
                      label={t('form.triggerTime.label')}
                      description={t('form.triggerTime.description')}
                      onBlur={() => {
                        triggerTimeHelpers.setTouched(true)
                      }}
                      onChange={option => {
                        triggerTimeHelpers.setValue(option?.value ?? '00:00')
                      }}
                      error={!!triggerTimeMeta.error && triggerTimeMeta.touched}
                      menuPortalTarget={document.body}
                    />
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>

          <div className='flex flex-wrap justify-between gap-4'>
            <Help analyticsProperties={{ Source: 'Automations creation' }} />
            <div className='flex items-center gap-4'>
              <ButtonWithIcon IconComponent={ArrowSmDownIcon} onClick={() => handleNext(goNext)}>
                {t('nextText')}
              </ButtonWithIcon>
            </div>
          </div>
        </div>
      )}
    </Step>
  )
}
