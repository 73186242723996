import i18n from '@/i18n'
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query'
import {
  createDepartments,
  deleteDepartment,
  getAll,
  getMyDepartments,
  getPaginatedDepartments,
  updateDepartment,
} from './services'
import { appHistory } from '@/routes/router'
import * as NotifyService from '@/services/notify.service'
import { customErrorToTrack } from '../analytics'
import { AxiosError } from 'axios'
import { useMemo } from 'react'
import { useAnalytics } from '@/features/analytics'
import { Department } from './interfaces'
import { Paginated, PaginationParams } from '@/hooks/use-pagination'
import { mergeQueryOptions } from '@/utils/merge-query-options'
import { useTranslation } from 'react-i18next'

export const QUERY_DEPARTMENTS_KEY = 'departments'

export const useDepartments = (
  options?: Omit<UseQueryOptions<Department[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery([QUERY_DEPARTMENTS_KEY], getAll, options)
  return { ...rest, departments: useMemo(() => data || [], [data]) }
}

export const useMyDepartments = (
  options?: Omit<UseQueryOptions<Department[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_DEPARTMENTS_KEY, { me: true }],
    getMyDepartments,
    options
  )
  return { ...rest, departments: useMemo(() => data || [], [data]) }
}

export const usePaginatedDepartments = (
  filter: { search?: string },
  pagination: PaginationParams,
  overrideOptions?: Omit<UseQueryOptions<Paginated<Department>, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const queryClient = useQueryClient()
  const options = mergeQueryOptions(overrideOptions, {
    keepPreviousData: true,
    onSuccess(data: Paginated<Department>) {
      overrideOptions?.onSuccess?.(data)
      data.items.forEach(item => {
        queryClient.setQueryData([QUERY_DEPARTMENTS_KEY, item._id], item)
      })
    },
  })
  const { data, ...rest } = useQuery(
    [QUERY_DEPARTMENTS_KEY, { filter, pagination }],
    () => getPaginatedDepartments(filter, pagination),
    options
  )

  const departments = useMemo(() => data?.items || [], [data?.items])
  const totalCount = data?.totalCount || 0

  return {
    ...rest,
    departments,
    totalCount,
  }
}

export const useCreateDepartments = () => {
  const analytics = useAnalytics()

  const { mutate, mutateAsync, ...rest } = useMutation({
    mutationFn: createDepartments,
    onSuccess: (data, department) => {
      analytics.track('CUSTOMER_CREATE_DEPARTMENT', {
        Department: department.name,
      })

      NotifyService.success({
        title: i18n.t('nsNotification:departments.create.success.title'),
        description: i18n.t('nsNotification:departments.create.success.description'),
        actionText: i18n.t('nsNotification:departments.create.success.action'),
        onActionClick: () => appHistory.push('/departments/create'),
      })
    },
    onError: ({ response }) => {
      if (response) {
        analytics.track(
          'CUSTOMER_CREATE_DEPARTMENT_ERROR',
          customErrorToTrack(response?.data, response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(response?.data))
      }
    },
  })

  return { ...rest, createDepartments: mutate, createDepartmentsAsync: mutateAsync }
}

export const useUpdateDepartment = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const queryClient = useQueryClient()

  const { mutate, mutateAsync, ...rest } = useMutation({
    mutationFn: updateDepartment,
    onSuccess: (data, variables) => {
      analytics.track('CUSTOMER_EDIT_DEPARTMENT', {
        Department: variables.name,
      })
      NotifyService.success({
        title: t('departments.edit.success.title'),
        description: t('departments.edit.success.description'),
        actionText: t('departments.edit.success.action'),
        onActionClick: () => appHistory.push('/departments/create'),
      })
      queryClient.invalidateQueries(QUERY_DEPARTMENTS_KEY)
    },
    onError: ({ response }, variables) => {
      if (response) {
        analytics.track('CUSTOMER_EDIT_DEPARTMENT_ERROR', {
          ...customErrorToTrack(response?.data, response?.status),
          Department: variables.name,
        })
        NotifyService.error(NotifyService.customErrorToNotify(response?.data))
      }
    },
  })

  return { ...rest, updateDepartment: mutate, updateDepartmentAsync: mutateAsync }
}

export const useDeleteDepartments = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const queryClient = useQueryClient()

  const { mutate, mutateAsync, ...rest } = useMutation({
    mutationFn: ({ id }: { id: string; name: string }) => deleteDepartment(id),
    onSuccess: (data, variables) => {
      analytics.track('CUSTOMER_DELETE_DEPARTMENT', {
        Departament: variables.name,
      })

      NotifyService.success({
        title: t('departments.delete.success.title'),
        description: t('departments.delete.success.description'),
        actionText: t('departments.delete.success.action'),
        onActionClick: () => appHistory.push('/departments/create'),
      })
      queryClient.invalidateQueries(QUERY_DEPARTMENTS_KEY)
    },
  })

  return { ...rest, deleteDepartment: mutate, deleteDepartmentAsync: mutateAsync }
}
