import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  InlineSelectField,
  InlineSelectFieldProps,
  SelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { useMemo, useState } from 'react'
import { useInfiniteStakeholders } from '../hooks'
import { StakeHolderType } from '../interfaces'

export const StakeHoldersInlineSelectField = <isMulti extends boolean>({
  types,
  all,
  ...props
}: Omit<InlineSelectFieldProps<isMulti>, 'options'> & {
  types?: StakeHolderType[]
  all?: { label: string; value: string }
}) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const { stakeholders, fetchNextPage } = useInfiniteStakeholders({
    search: debouncedSearch,
    types,
  })

  const stakeholdersOptions: SelectorOption[] = useMemo(() => {
    const options = stakeholders.map(stakeholder => ({
      label: stakeholder.company.name,
      value: stakeholder._id,
    }))
    return all?.label && all?.value ? [{ label: all.label, value: all.value }, ...options] : options
  }, [stakeholders, all?.label, all?.value])

  return (
    <InlineSelectField
      {...props}
      searchable
      options={stakeholdersOptions}
      onSearch={inputValue => {
        setSearch(inputValue)
      }}
      onMenuScrollToBottom={() => {
        fetchNextPage()
      }}
    />
  )
}
