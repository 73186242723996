import { Card } from '@blockchain-traceability-sl/tailwind-components'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import { useMonthlyNavigation, useTasksInMonth } from './hooks'
import { TaskFiltersState } from '../TaskListFilters'
import { CalendarViewTypeSelector } from '../TaskCalendar/CalendarViewTypeSelector'
import { useTranslation } from 'react-i18next'
import { HeaderDay } from './HeaderDay'
import { TemplateCard } from './TemplateCard'
import { DayCell } from './DayCell'
import { PadRows } from './PadRows'
import { ReadableTask } from '../../interfaces'
import dayjs from 'dayjs'
import { Help } from '@/components/Help'

import { SidebarDetails } from '../SidebarDetails'
import { useAnalytics } from '@/features/analytics'
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon'

export const TaskCalendarMonthly = ({ taskFilters }: { taskFilters: TaskFiltersState }) => {
  const [slideOverState, setSlideOverState] = useState<{
    visible: boolean
    selectedDate?: Date
    templateId: string
    dayNumber: number
  }>({
    visible: false,
    selectedDate: undefined,
    templateId: '',
    dayNumber: 0,
  })

  const { t } = useTranslation('nsTasksListPage', { keyPrefix: 'calendar.list' })
  const {
    startDate,
    endDate,
    monthDays,
    month,
    year,
    displayMonthAndYear,
    nextMonth,
    previousMonth,
    goToDate,
    goToNow,
  } = useMonthlyNavigation()

  const { tasksGroupByTemplate, templates, isFetched } = useTasksInMonth({
    filters: taskFilters,
    monthRange: { startDate, endDate },
  })
  const analytics = useAnalytics()

  useEffect(() => {
    if (taskFilters.startDate) {
      goToDate(taskFilters.startDate)
    } else {
      goToNow()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskFilters.startDate])

  const getCellData = (templateId: string, dayNumber: number): ReadableTask[] => {
    if (!tasksGroupByTemplate[templateId]) return []

    return tasksGroupByTemplate[templateId]?.filter(task => {
      const dueDate = dayjs(task.dueDate)

      return dueDate.isSame(new Date(year, month, dayNumber), 'day')
    })
  }

  const handleDayCellClick = (date: Date, templateId: string, dayNumber: number) => {
    analytics.track('NAVIGATION_VIEW_TASKS', { Status: 'Sidebar' })
    setSlideOverState({ visible: true, selectedDate: date, templateId, dayNumber })
  }
  const handleSidebarClose = () => {
    setSlideOverState({ ...slideOverState, visible: false })
  }

  return (
    <div data-testid='task-calendar-view-monthly'>
      <Card divideY={false}>
        <Card.Body>
          <div className='flex gap-5 sm:items-center justify-between items-start flex-wrap	sm:flex-nowrap'>
            <div className='flex gap-5 items-center'>
              <div className='flex mt-1'>
                <ChevronLeftIcon
                  className='w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-500'
                  onClick={previousMonth}
                />
                <ChevronRightIcon
                  className='w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-500'
                  onClick={nextMonth}
                />
              </div>
              <div className='flex flex-grow mt-1 gap-2'>
                <div className='capitalize text-2xl leading-7 font-bold'>{displayMonthAndYear}</div>
                {/* Spinner */}
                {!isFetched && (
                  <span className='pt-1'>
                    <SpinnerIcon className='h-5 w-5 text-blue-500' />
                  </span>
                )}
              </div>
            </div>
            <CalendarViewTypeSelector />
          </div>
        </Card.Body>

        <div className='pb-3 bg-gray-50 rounded-b-lg'>
          <div className='overflow-auto relative h-112 flex flex-col flex-nowrap gap-1/2'>
            {/* Header */}
            <div className='sticky top-0 right-0 bg-gray-50 pl-4 shadow w-full min-w-max'>
              <div className='flex gap-1/2 bg-gray-50'>
                <div className='w-64 h-10 flex items-center mr-4'>
                  <span className='w-64 text-gray-500 text-base font-medium'>{t('formsName')}</span>
                </div>
                <div
                  className='flex-grow w-full grid gap-1/2'
                  style={{ gridTemplateColumns: `repeat(${monthDays}, minmax(0, 1fr))` }}
                >
                  {Array.from({ length: monthDays }, (_, index) => (
                    <HeaderDay key={`header-${index}`} date={new Date(year, month, index + 1)} />
                  ))}
                </div>
              </div>
            </div>

            <div className='pl-4 gap-1/2 flex flex-col w-full min-w-max'>
              {templates.map(template => (
                <div key={template._id} className='flex gap-1/2'>
                  <TemplateCard templateName={template.name} />
                  <div
                    className='flex-grow w-full grid gap-1/2'
                    style={{ gridTemplateColumns: `repeat(${monthDays}, minmax(0, 1fr))` }}
                  >
                    {Array.from({ length: monthDays }, (_, index) => (
                      <DayCell
                        key={`cell-${template._id}-${index}`}
                        tasks={getCellData(template._id, index + 1)}
                        date={new Date(year, month, index + 1)}
                        onCellClick={() =>
                          handleDayCellClick(
                            new Date(year, month, index + 1),
                            template._id,
                            index + 1
                          )
                        }
                      />
                    ))}
                  </div>
                </div>
              ))}

              <PadRows
                currentRows={templates.length}
                monthDays={monthDays}
                year={year}
                month={month}
              />
            </div>
          </div>
        </div>
      </Card>
      <SidebarDetails
        open={slideOverState.visible}
        onClose={handleSidebarClose}
        date={slideOverState.selectedDate}
        taskData={getCellData(slideOverState.templateId, slideOverState.dayNumber)}
      />

      <Help className='mt-8' analyticsProperties={{ Source: 'Task calendar month' }} />
    </div>
  )
}
