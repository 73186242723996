import { api } from '@/app/api'
import { Paginated, PaginationParams } from '@/hooks/use-pagination'
import {
  StakeHolderProduct,
  StakeHolderProductCreation,
  StakeHolderProductFilters,
  StakeHolderProductUpdate,
  StakeholderProductStock,
} from './interfaces'

export const STAKEHOLDER_PRODUCTS_URL = '/hydra/products/stakeholders'

export const createStakeHolderProduct = async (
  stakeHolderProduct: StakeHolderProductCreation
): Promise<string> => {
  const { data } = await api.post<string>(STAKEHOLDER_PRODUCTS_URL, stakeHolderProduct)
  return data
}

/**
 * Create StakeHolderProduct with CSV file
 */
export const createWithCSV = async (file: File): Promise<{ total: number }> => {
  const formData = new FormData()
  formData.append('file', file)
  const { data } = await api.post<{ total: number }>(`${STAKEHOLDER_PRODUCTS_URL}/csv`, formData)
  return data
}

export const updateStakeHolderProduct = async (
  id: string,
  stakeHolderProductUpdate: StakeHolderProductUpdate
): Promise<void> => {
  await api.patch<void>(`${STAKEHOLDER_PRODUCTS_URL}/${id}`, stakeHolderProductUpdate)
}

/**
 * Get the Company logged StakeHolderProducts
 *
 * @returns All the Company logged StakeHolderProducts
 */
export const getAll = async (
  filters?: StakeHolderProductFilters
): Promise<StakeHolderProduct[]> => {
  const searchParams = new URLSearchParams()

  if (filters?.search) {
    searchParams.append('search', filters.search)
  }

  if (filters?.categoryId) {
    searchParams.append('categoryId', filters.categoryId)
  }

  const { data: stakeholderProducts } = await api.get<StakeHolderProduct[] | void>(
    STAKEHOLDER_PRODUCTS_URL,
    {
      params: searchParams,
    }
  )
  return stakeholderProducts || []
}

/**
 * Get all company StakeHolderProduct with pagination and filter support
 * @returns All the StakeHolderProduct that match
 */
export const getPaginatedStakeholderProducts = async (
  { search, categoryId }: StakeHolderProductFilters,
  pagination: PaginationParams
): Promise<Paginated<StakeHolderProduct>> => {
  const searchParams = new URLSearchParams()

  if (search) {
    searchParams.append('search', search)
  }

  if (categoryId) {
    searchParams.append('categoryId', categoryId)
  }
  searchParams.append('limit', pagination.limit.toString())
  searchParams.append('offset', pagination.offset.toString())
  const { data } = await api.get<Paginated<StakeHolderProduct>>(STAKEHOLDER_PRODUCTS_URL, {
    params: searchParams,
  })
  return data
}

/**
 * Get the Company logged StakeHolderProducts
 * @returns All the Company logged StakeHolderProducts count
 */
export const getCount = async (): Promise<number> => {
  const { data: stakeHolderProductsCount } = await api.get<number>(
    `${STAKEHOLDER_PRODUCTS_URL}/count`
  )
  return stakeHolderProductsCount
}

export const getByIds = async (ids: string[]): Promise<StakeHolderProduct[]> => {
  const { data: stakeHolderProducts } = await api.post<StakeHolderProduct[]>(
    `${STAKEHOLDER_PRODUCTS_URL}/ids`,
    { ids }
  )
  return stakeHolderProducts
}

export const getById = async (id: string): Promise<StakeHolderProduct | void> => {
  const { data: stakeHolderProduct } = await api.get<StakeHolderProduct | void>(
    `${STAKEHOLDER_PRODUCTS_URL}/${id}`
  )
  return stakeHolderProduct
}

export const getStockById = async (id: string): Promise<StakeholderProductStock> => {
  const { data } = await api.get<StakeholderProductStock>(`${STAKEHOLDER_PRODUCTS_URL}/${id}/stock`)
  return data
}

/**
 * Get StakeHolderProduct CSV template
 */
export const getCsvTemplate = async (): Promise<ArrayBuffer> => {
  const { data } = await api.get<ArrayBuffer>(`${STAKEHOLDER_PRODUCTS_URL}/csv`, {
    responseType: 'arraybuffer',
  })
  return data
}

/**
 * Delete a StakeHolderProduct
 *
 * @param stakeholderProductId - the StakeHolderProduct id
 */
export const remove = async (stakeholderProductId: string): Promise<void> => {
  await api.delete<void>(`${STAKEHOLDER_PRODUCTS_URL}/${encodeURIComponent(stakeholderProductId)}`)
}

export const getByCategoryId = async (categoryId: string): Promise<StakeHolderProduct[]> => {
  const { data } = await api.get<StakeHolderProduct[]>(
    `${STAKEHOLDER_PRODUCTS_URL}/category/${categoryId}`
  )

  return data
}
