import {
  Button,
  CreatableMultiField,
  FullLoader,
  Modal,
  RichTextField,
  RichTextFieldMode,
  SIZE,
  SelectField,
  SelectorGroup,
  SelectorOption,
  SwitchField,
  clickHandlerOnce,
} from '@blockchain-traceability-sl/tailwind-components'
import { Trans, useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { DateLocaleField } from '@/components/DateLocaleField'
import {
  StakeHolderUpdate,
  useStakeHolderById,
  useUpdateStakeHolder,
} from '@/features/stakeHolders'
import { useEffect, useMemo, useRef } from 'react'
import { TemplateComplianceEntityType, TemplateType, useTemplates } from '@/features/templates'
import { useCreateCompliance, useCreateContactStakeholderAndComplianceByEmails } from '../hooks'
import { generateEntriesFromTemplate } from '@/features/entries'
import { useMemoForm } from '@/features/memorised-form'
import { useReferrer } from '@/hooks/use-referrer'
import { Link } from 'react-router-dom'
import { useCompanyUser } from '@/features/auth'
import dayjs from 'dayjs'
import { ComplianceCreateInput } from '../interfaces'
import { remapCompliantEntityTypeFromTemplate } from '../utils'

export interface ModalComplianceRequestProps {
  show: boolean
  stakeHolderId?: string | null
  toggle: () => void
  onCompliance?: () => void
}

export const ModalComplianceRequest = ({
  show,
  stakeHolderId,
  toggle,
  onCompliance,
}: ModalComplianceRequestProps) => {
  const { t } = useTranslation('nsComplianceRequest')

  const initialFocusRef = useRef(null)

  const isContact = show && !stakeHolderId

  const isSingle = show && !!stakeHolderId

  const { stakeHolder: initialStakeHolder } = useStakeHolderById(stakeHolderId || '', {
    enabled: isSingle,
  })

  const initialStakeHolderEmails = useMemo(() => {
    if (!initialStakeHolder) return []
    const emails = []
    if (initialStakeHolder.company.email) {
      emails.push(initialStakeHolder.company.email)
    }
    if (initialStakeHolder.company.secondaryEmails) {
      emails.push(...initialStakeHolder.company.secondaryEmails)
    }
    return emails
  }, [initialStakeHolder])

  const { templates } = useTemplates({ types: [TemplateType.COMPLIANCE] })
  const company = useCompanyUser()

  const { createCompliance } = useCreateCompliance()
  const { updateStakeHolder } = useUpdateStakeHolder({ silently: true })

  const { createContactStakeholderAndComplianceByEmails } =
    useCreateContactStakeholderAndComplianceByEmails()

  const { generateReferrerUrl, updateFormWithReferrerResult } = useReferrer()

  const {
    isLoaded,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    submitForm,
    isSubmitting,
    setSubmitting,
    resetForm,
  } = useMemoForm<{
    templateId?: string
    dueDate?: string
    deadlineAt?: string
    stakeHolderEmails: string[]
    emailContent?: string
    emailContentRichText?: string
    // This is a flag to indicate if the compliance is a combination of stakeholder and stakeholder product
    combined: boolean
    childTemplateId?: string
  }>({
    action: 'create',
    entity: 'compliance',
    entityId: { stakeHolderId },
    initialValues: {
      templateId: undefined,
      dueDate: undefined,
      deadlineAt: undefined,
      stakeHolderEmails: initialStakeHolderEmails,
      emailContent: '',
      emailContentRichText: '',
      combined: false,
      childTemplateId: undefined,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      templateId: Yup.string().required(),
      dueDate: Yup.string().required(),
      deadlineAt: Yup.string().required(),
      stakeHolderEmails: Yup.array()
        .of(Yup.string().email())
        .min(1)
        .test(function (values) {
          if (!isContact || !values || values?.length === 0) return true
          // Don't allow emails with same domain
          const allDomainsSet = new Set(values.map(value => value?.split('@').pop()))
          if (allDomainsSet.size === values.length) return true
          return this.createError({
            message: 'emailWithDuplicatedDomains',
          })
        }),
      emailContent: Yup.string(),
      emailContentRichText: Yup.string(),
      combined: Yup.boolean(),
      childTemplateId: Yup.string().when('combined', {
        is: true,
        then: Yup.string().required(),
      }),
    }),
    onSubmit: async ({
      templateId,
      stakeHolderEmails,
      deadlineAt,
      dueDate,
      emailContent,
      emailContentRichText,
      combined,
      childTemplateId,
    }) => {
      const template = templates.find(template => template._id === templateId)
      if (!template || !deadlineAt || !dueDate) return

      const entries = generateEntriesFromTemplate(template)

      let complianceData: Omit<ComplianceCreateInput, 'compliantStakeHolder'> = {
        templateId: template._id,
        templateVersion: template.version,
        templateName: template.name,
        compliantEntityType: remapCompliantEntityTypeFromTemplate(
          template.defaults?.complianceEntityType
        ),
        entries,
        dueDate,
        requesterCompany: {
          id: company._id,
          name: company.name,
        },
        deadlineAt,
        timezone: dayjs.tz.guess(),
        emailContent,
        emailContentRichText,
      }

      // If the compliance is a combination of stakeholder and stakeholder product
      if (combined && childTemplateId) {
        const childTemplate = templates.find(template => template._id === childTemplateId)
        if (!childTemplate) return

        complianceData = {
          ...complianceData,
          stakeholderProductTemplate: {
            templateId: childTemplate._id,
            templateVersion: childTemplate.version,
            templateName: childTemplate.name,
            entries: generateEntriesFromTemplate(childTemplate),
          },
        }
      }

      if (isSingle && initialStakeHolder) {
        const newEmails = stakeHolderEmails.filter(
          email =>
            !initialStakeHolder.company.secondaryEmails?.includes(email) &&
            initialStakeHolder.company.email !== email
        )
        if (newEmails.length > 0) {
          const stakeHolderUpdate: StakeHolderUpdate = {
            name: initialStakeHolder?.company.name,
            email: initialStakeHolder?.company.email,
            secondaryEmails: initialStakeHolder?.company.secondaryEmails || [],
          }
          if (!stakeHolderUpdate.email) {
            const [firstEmail, ...restOfEmails] = newEmails
            stakeHolderUpdate.email = firstEmail
            if (restOfEmails.length > 0) {
              stakeHolderUpdate.secondaryEmails?.push(...restOfEmails)
            }
          } else {
            stakeHolderUpdate.secondaryEmails?.push(...newEmails)
          }
          updateStakeHolder({ id: initialStakeHolder._id, stakeHolder: stakeHolderUpdate })
        }

        createCompliance(
          {
            ...complianceData,
            compliantStakeHolder: {
              id: initialStakeHolder._id,
              name: initialStakeHolder.company.name,
              emails: stakeHolderEmails,
            },
          },
          {
            onSuccess() {
              onCompliance?.()
            },
            onSettled() {
              handleClose()
            },
          }
        )
      } else {
        createContactStakeholderAndComplianceByEmails(
          {
            ...complianceData,
            stakeHolderEmails,
          },
          {
            onSuccess() {
              onCompliance?.()
            },
            onSettled() {
              handleClose()
            },
          }
        )
      }
    },
  })

  // Manage referrer result
  useEffect(() => {
    if (isLoaded) {
      updateFormWithReferrerResult(setFieldValue, values)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, setFieldValue, updateFormWithReferrerResult])

  const stakeHolderEmailsOptions = initialStakeHolderEmails.map(email => ({
    label: email,
    value: email,
  }))

  const hasSomeComplianceStakeholderProductTemplates = templates.some(
    template =>
      template.defaults?.complianceEntityType === TemplateComplianceEntityType.STAKEHOLDER_PRODUCT
  )

  const currentTemplateSelected = templates.find(template => template._id === values.templateId)

  const templatesStakeholderOptions: SelectorOption[] = templates
    .filter(
      template =>
        template.defaults?.complianceEntityType === TemplateComplianceEntityType.STAKEHOLDER
    )
    .map(
      (template): SelectorOption => ({
        label: template.name,
        value: template._id,
      })
    )
  const templatesStakeholderProductOptions: SelectorOption[] = templates
    .filter(
      template =>
        template.defaults?.complianceEntityType === TemplateComplianceEntityType.STAKEHOLDER_PRODUCT
    )
    .map(
      (template): SelectorOption => ({
        label: template.name,
        value: template._id,
      })
    )

  const templatesOptions = useMemo((): SelectorGroup[] => {
    const options = [
      {
        label: t('form.templateId.dropdown.stakeholder'),
        options: templatesStakeholderOptions,
      },
    ]

    const rawMaterialOptions = {
      label: t('form.templateId.dropdown.rawMaterial'),
      options: templatesStakeholderProductOptions,
    }

    if (isContact) {
      return options
    }

    options.push(rawMaterialOptions)
    return options
  }, [isContact, t, templatesStakeholderOptions, templatesStakeholderProductOptions])

  const memoRichTextField = useMemo(
    () => (
      <RichTextField
        mode={RichTextFieldMode.EDITOR}
        id='emailContentRichText'
        key='emailContentRichText'
        name='emailContentRichText'
        label={
          <Trans
            t={t}
            i18nKey='form.emailContent.label'
            components={[<span className='font-light text-gray-400 italic' />]}
          />
        }
        description={t('form.emailContent.description')}
        placeholder={t('form.emailContent.placeholder')}
        value={values.emailContentRichText || ''}
        onChange={(richTextValue: string, plainTextValue: string) => {
          setFieldValue('emailContent', plainTextValue)
          setFieldValue('emailContentRichText', richTextValue)
        }}
        error={touched.emailContent && !!errors.emailContent}
      />
    ),
    [errors.emailContent, setFieldValue, t, touched.emailContent, values.emailContentRichText]
  )

  const handleClose = () => {
    resetForm()
    toggle()
    setSubmitting(false)
  }

  return (
    <>
      <Modal
        size={SIZE.LARGE}
        show={show && !isSubmitting}
        toggle={handleClose}
        dismissButton
        initialFocus={initialFocusRef}
      >
        <Modal.Title>{t('title')}</Modal.Title>
        <Modal.Body>
          <form className='text-left space-y-4' ref={initialFocusRef}>
            {isContact ? (
              <CreatableMultiField
                id='stakeHolderEmails'
                name='stakeHolderEmails'
                options={isContact ? undefined : stakeHolderEmailsOptions}
                label={t('form.contactStakeHolderEmails.label')}
                description={t('form.contactStakeHolderEmails.description')}
                onBlur={() => setFieldTouched('stakeHolderEmails', true)}
                // Add space delimiter su support multiple emails separated by space
                keyDelimeters={[' ']}
                onChange={options => {
                  setFieldValue(
                    'stakeHolderEmails',
                    options.map(option => option.value)
                  )
                }}
                value={values.stakeHolderEmails.map(email => ({ label: email, value: email }))}
                error={touched.stakeHolderEmails && !!errors.stakeHolderEmails}
                errorText={
                  errors.stakeHolderEmails === 'emailWithDuplicatedDomains'
                    ? t('form.contactStakeHolderEmails.errors.emailWithDuplicatedDomains')
                    : undefined
                }
              />
            ) : (
              <CreatableMultiField
                id='stakeHolderEmails'
                name='stakeHolderEmails'
                options={isContact ? undefined : stakeHolderEmailsOptions}
                label={t('form.stakeHolderEmails.label')}
                description={t('form.stakeHolderEmails.description')}
                isSearchable
                onBlur={() => setFieldTouched('stakeHolderEmails', true)}
                noOptionsMessage={() => t('form.stakeHolderEmails.noOptions')}
                onChange={options => {
                  setFieldValue(
                    'stakeHolderEmails',
                    options.map(option => option.value)
                  )
                }}
                createLabel={t('form.stakeHolderEmails.createLabel').toString()}
                value={values.stakeHolderEmails.map(email => ({ label: email, value: email }))}
                error={touched.stakeHolderEmails && !!errors.stakeHolderEmails}
              />
            )}

            <SelectField
              id='templateId'
              name='templateId'
              options={templatesOptions}
              label={t('form.templateId.label')}
              description={
                <Trans
                  t={t}
                  i18nKey='form.templateId.description'
                  components={{
                    a: (
                      <Link
                        to={generateReferrerUrl({
                          url: '/forms/create',
                          fieldName: 'templateId',
                        })}
                        className='underline'
                      />
                    ),
                  }}
                />
              }
              placeholder={t('form.templateId.placeholder')}
              noOptionsMessage={() => t('form.templateId.noOptions')}
              onBlur={() => setFieldTouched('templateId', true)}
              onChange={option => {
                if (!option) {
                  setFieldValue('templateId', undefined)
                  setFieldValue('combined', false)
                } else {
                  setFieldValue('templateId', option.value)

                  // If the new template is a stakeholder product compliance, then we disable the combined flag
                  if (
                    templates.find(template => template._id === option.value)?.defaults
                      ?.complianceEntityType === TemplateComplianceEntityType.STAKEHOLDER_PRODUCT
                  ) {
                    setFieldValue('combined', false)
                  }
                }
              }}
              value={templatesOptions
                .flatMap(template => template.options)
                .find(option => option.value === values.templateId)}
              error={touched.templateId && !!errors.templateId}
            />

            {/* Only show the combined compliance on root compliance is stakeholder type */}
            <SwitchField
              reverse
              id=''
              name=''
              label={t('form.addRawMaterial.label')}
              description={
                <Trans
                  t={t}
                  i18nKey={
                    hasSomeComplianceStakeholderProductTemplates
                      ? 'form.addRawMaterial.enabled.description'
                      : 'form.addRawMaterial.disabled.description'
                  }
                  components={{
                    a: (
                      <Link
                        to={generateReferrerUrl({
                          url: '/forms/create',
                          fieldName: 'childTemplateId',
                          searchParams: {
                            compliantEntityType: TemplateComplianceEntityType.STAKEHOLDER_PRODUCT,
                          },
                        })}
                        className='underline'
                      />
                    ),
                  }}
                />
              }
              checked={values.combined}
              onChange={value => setFieldValue('combined', value)}
              disabled={
                !hasSomeComplianceStakeholderProductTemplates ||
                !currentTemplateSelected ||
                currentTemplateSelected.defaults?.complianceEntityType !==
                  TemplateComplianceEntityType.STAKEHOLDER
              }
            />

            {values.combined && (
              <SelectField
                id='childTemplateId'
                name='childTemplateId'
                options={templatesStakeholderProductOptions}
                placeholder={t('form.addRawMaterial.label')}
                onBlur={() => setFieldTouched('childTemplateId', true)}
                onChange={option => {
                  if (!option) return
                  setFieldValue('childTemplateId', option.value)
                }}
                value={templatesStakeholderProductOptions.find(
                  option => option.value === values.childTemplateId
                )}
                error={touched.childTemplateId && !!errors.childTemplateId}
              />
            )}

            <div className='flex flex-col md:flex-row gap-4'>
              <DateLocaleField
                className='flex-grow'
                id='dueDate'
                name='dueDate'
                mode='single'
                onChange={value => setFieldValue('dueDate', value)}
                value={values.dueDate}
                label={t('form.formExpiration.label')}
                error={touched.dueDate && !!errors.dueDate}
              />
              <DateLocaleField
                className='flex-grow'
                id='deadlineAt'
                name='deadlineAt'
                mode='single'
                onChange={value => setFieldValue('deadlineAt', value)}
                value={values.deadlineAt}
                label={t('form.deadlineAt.label')}
                error={touched.deadlineAt && !!errors.deadlineAt}
              />
            </div>

            {memoRichTextField}

            <div className='flex flex-row-reverse'>
              <Button type='button' onClick={clickHandlerOnce(submitForm)}>
                {t('submit')}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {isSubmitting && <FullLoader className='z-50' />}
    </>
  )
}
