import { useCallback, useMemo } from 'react'
import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import {
  create,
  createWithCSV,
  getById,
  getByShippedTo,
  getByTRUIds,
  getCount,
  getCsvTemplate,
  getPaginatedShipments,
  remove,
  update,
} from './service'
import { Shipment, ShipmentFilters, ShipmentUpdate } from './interfaces'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { customErrorToTrack, useAnalytics } from '@/features/analytics'
import * as NotifyService from '@/services/notify.service'
import { BulkError, CustomError, ErrorCodes } from '@blockchain-traceability-sl/custom-error-codes'
import { useHistory } from 'react-router-dom'
import { downloadShipmentBulkErrorReport } from './helpers'
import { downloader } from '@/helpers/downloader'
import { useProducts } from '@/features/products'
import { useQueryTRUs } from '@/features/trus'
import { mergeInfiniteQueryOptions } from '@/utils/merge-query-options'
import { useStakeHolders } from '@/features/stakeHolders'
import { Paginated } from '@/hooks/use-pagination'

export const QUERY_SHIPMENTS_KEY = 'shipments'

export const usePaginatedShipments = ({
  filters,
  pageSize = 10,
  ...overrideOptions
}: Omit<UseInfiniteQueryOptions<Paginated<Shipment>, AxiosError>, 'queryKey' | 'queryFn'> & {
  filters: ShipmentFilters
  pageSize?: number
}) => {
  const queryClient = useQueryClient()

  const options = mergeInfiniteQueryOptions(overrideOptions, {
    keepPreviousData: true,
    // Cache each product as individual cached item by id
    onSuccess(data) {
      overrideOptions?.onSuccess?.(data)
      data.pages
        .flatMap(({ items }) => items)
        .forEach(item => {
          queryClient.setQueryData([QUERY_SHIPMENTS_KEY, item._id], item)
        })
    },
    getNextPageParam: lastPage => lastPage.pageInfo.nextPage || undefined,
    getPreviousPageParam: lastPage => lastPage.pageInfo.previousPage || undefined,
  })

  const { data, ...rest } = useInfiniteQuery(
    [QUERY_SHIPMENTS_KEY, { filters, pageSize }, { isInfiniteQuery: true }],
    async ({ pageParam }) =>
      getPaginatedShipments(filters, {
        offset: pageParam?.offset || 0,
        limit: pageParam?.limit || pageSize,
      }),
    options
  )

  const shipments: Shipment[] = useMemo(
    () => data?.pages.flatMap(({ items }) => items) || [],
    [data?.pages]
  )

  const totalCount = useMemo(
    () => data?.pages[data?.pages.length - 1]?.totalCount || 0,
    [data?.pages]
  )

  return {
    ...rest,
    data,
    shipments,
    totalCount,
    pageSize,
  }
}

export const useQueryShipmentsByTruIds = (
  truIds: string[],
  options?: Omit<UseQueryOptions<Shipment[], AxiosError, Shipment[]>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_SHIPMENTS_KEY, { truIds }],
    () => getByTRUIds(truIds),
    options
  )
  const shipments = useMemo(() => data || [], [data])

  return { ...rest, shipments }
}

export const useShipmentsCount = (
  options?: Omit<UseQueryOptions<number, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery([QUERY_SHIPMENTS_KEY, { result: 'count' }], getCount, options)

  return { ...rest, shipmentsCount: useMemo(() => data || 0, [data]) }
}

export const useQueryShipmentsByTo = (
  to: string,
  options?: Omit<UseQueryOptions<Shipment[], AxiosError, Shipment[]>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_SHIPMENTS_KEY, { to }],
    () => getByShippedTo(to),
    options
  )
  const shipments = useMemo(() => data || [], [data])

  return { ...rest, shipments }
}

export const useFetchShipmentById = () => {
  const queryClient = useQueryClient()

  const fetchShipment = useCallback(
    (shipmentId: string) =>
      queryClient.fetchQuery([QUERY_SHIPMENTS_KEY, shipmentId], () => getById(shipmentId)),
    [queryClient]
  )

  return {
    fetchShipment,
  }
}

export const useCreateShipment = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const history = useHistory()
  const { products } = useProducts()

  const { mutate, ...rest } = useMutation(create, {
    onSuccess(data, variables) {
      queryClient.invalidateQueries(QUERY_SHIPMENTS_KEY)
      NotifyService.success({
        title: t('shipment.create.success.title'),
        description: t('shipment.create.success.description'),
        actionText: t('shipment.create.success.action'),
        onActionClick: () => history.push('/trus/shipment'),
      })
      const product = products.find(({ reference }) => reference === variables.productReference)
      analytics.track('CUSTOMER_SEND_TRU', {
        TRU: variables.truReference,
        Product: product?.name,
      })
    },
    onError(error: AxiosError) {
      analytics.track(
        'CUSTOMER_SEND_TRU_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return { ...rest, createShipment: mutate }
}

export const useUpdateShipment = () => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()

  const { products } = useProducts()
  const { trus } = useQueryTRUs()

  const { mutate, ...rest } = useMutation(
    ({
      shipmentId,
      shipment,
      truId,
    }: {
      shipmentId: string
      shipment: ShipmentUpdate
      truId: string
    }) => update(shipmentId, shipment),
    {
      onSuccess(data, variables) {
        analytics.track('CUSTOMER_EDIT_TRU', {
          TRU: trus.find(tru => tru._id === variables?.truId)?.reference,
          Product: products?.find(
            ({ _id }) => _id === trus.find(tru => tru._id === variables?.truId)?.productId
          )?.name,
          Type: 'Shipment',
          Source: 'Company to',
        })
        queryClient.invalidateQueries(QUERY_SHIPMENTS_KEY)
      },
      onError(error: AxiosError) {
        analytics.track(
          'CUSTOMER_EDIT_TRU_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return { ...rest, updateShipment: mutate }
}

export const useCreateShipmentsWithCSV = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const history = useHistory()

  const { mutate, ...rest } = useMutation(createWithCSV, {
    onSuccess(data) {
      analytics.track('CUSTOMER_CREATE_SHIPMENTS_CSV')
      NotifyService.success({
        title: t('shipment.createBulk.success.title'),
        description: t('shipment.createBulk.success.description', {
          count: data.total,
        }),
        actionText: t('shipment.createBulk.success.action'),
        onActionClick: () => history.push('/trus/shipment'),
      })
      queryClient.invalidateQueries(QUERY_SHIPMENTS_KEY)
    },
    onError(error: AxiosError) {
      analytics.track(
        'CUSTOMER_CREATE_SHIPMENTS_CSV_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )

      switch (error.response?.data.code) {
        case ErrorCodes.BULK_ERROR:
          {
            const bulkError: BulkError = error.response?.data

            // Set as valid processed if all errors are already exist
            if (
              bulkError.errors.every(
                ({ error: customError }) => customError.code === ErrorCodes.ALREADY_EXISTS
              )
            ) {
              NotifyService.success({
                title: t('shipment.createBulk.success.title'),
                description: t('shipment.createBulk.success.description', {
                  count: bulkError.total,
                }),
                actionText: t('shipment.createBulk.success.action'),
                onActionClick: () => history.push('/trus/shipment'),
              })
            } else {
              NotifyService.error({
                title: t('shipment.createBulk.bulkError.title'),
                description: t('shipment.createBulk.bulkError.description', {
                  processed: bulkError.processed,
                  total: bulkError.total,
                }),
                actionText: t('shipment.createBulk.bulkError.action'),
                onActionClick: () => downloadShipmentBulkErrorReport(bulkError),
              })
            }
          }
          break

        case 10: // InvalidCSVError
          {
            const invalidCSVError: CustomError = error.response?.data

            if (invalidCSVError.entity !== 'file') {
              NotifyService.error({
                title: t('shipment.createBulk.entityError.title'),
                description: t('shipment.createBulk.entityError.description', {
                  context: invalidCSVError.entity,
                }),
                actionText: t('shipment.createBulk.entityError.action'),
              })
            } else {
              NotifyService.error({
                title: t('shipment.createBulk.error.title'),
                description: t('shipment.createBulk.error.description'),
                actionText: t('shipment.createBulk.error.action'),
              })
            }
          }
          break
        default:
          NotifyService.error({
            title: t('shipment.createBulk.error.title'),
            description: t('shipment.createBulk.error.description'),
            actionText: t('shipment.createBulk.error.action'),
          })
          break
      }
    },
  })

  return { ...rest, createShipmentsWithCSV: mutate }
}

export const useDownloadTemplateShipmentsCSV = () => {
  const { data } = useQuery([QUERY_SHIPMENTS_KEY, { template: 'csv' }], getCsvTemplate)

  const downloadTemplate = (fileName: string) => {
    if (data) downloader(data, 'text/csv', fileName)
  }

  return { downloadTemplate }
}

export const useDeleteShipment = () => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')

  const { products } = useProducts()
  const { trus } = useQueryTRUs()
  const { stakeHolders } = useStakeHolders()

  const { mutate, ...rest } = useMutation(
    ({ shipmentId }: { shipmentId: string; truId: string; to: string }) => remove(shipmentId),
    {
      onSuccess(data, variables) {
        analytics.track('CUSTOMER_DELETE_SHIPMENT', {
          TRU: trus.find(tru => tru._id === variables?.truId)?.reference,
          Product: products?.find(
            ({ _id }) => _id === trus.find(tru => tru._id === variables?.truId)?.productId
          )?.name,
          Company: stakeHolders.find(({ _id }) => _id === variables?.to)?.company.name,
        })
        NotifyService.success({
          title: t('shipment.delete.success.title'),
          description: t('shipment.delete.success.description'),
        })
        queryClient.invalidateQueries(QUERY_SHIPMENTS_KEY)
      },
      onError(error: AxiosError) {
        analytics.track(
          'CUSTOMER_DELETE_SHIPMENT_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return { ...rest, deleteShipment: mutate }
}
