import {
  ButtonWithIcon,
  COLOR,
  SidebarItem,
  SidebarItemProps,
} from '@blockchain-traceability-sl/tailwind-components'
import { LockClosedIcon, PlusIcon } from '@heroicons/react/outline'
import { ComponentType, useMemo } from 'react'
import trazableLogoWhite from '@/assets/images/brand/logo-white-400-100.png'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { useSidebarNavigation } from '@/routes/sidebar-navigation'
import { useTranslation } from 'react-i18next'
import { useCompanyUser, useMyProfile, useUserPermissions } from '@/features/auth'
import { CreateNewModalPermissionScopes } from './ModalCreateNew'
import { SidebarWithOnboarding } from '@/features/onboarding'
import { NotificationBellButton } from '@/features/notifications'
import { useAnalytics } from '@/features/analytics'
import { useLayout } from '../context'
import classNames from 'classnames'
import { useImageMeta } from '@/hooks/use-image-meta'
import { SubscriptionTrialInfo } from '@/features/pricing'

/* Static sidebar for desktop */
export const SidebarDesktop = () => {
  const { t } = useTranslation('nsSidebar')
  const location = useLocation()
  const history = useHistory()
  const company = useCompanyUser()
  const { profile } = useMyProfile()
  const sidebarNavigation = useSidebarNavigation()
  const userPermissions = useUserPermissions()
  const analytics = useAnalytics()
  const { toggleModalCreateNew, toggleSidebar } = useLayout()

  const shouldDisplayCreateButton = useMemo(
    () => CreateNewModalPermissionScopes.some(scopeKey => userPermissions[scopeKey].write),
    [userPermissions]
  )

  // Account logo image meta
  const [logoImageMeta] = useImageMeta(
    company.media.logo && company.media.logo !== 'default' ? company.media.logo : trazableLogoWhite
  )

  return (
    <div className='hidden bg-blue-700 lg:flex lg:flex-shrink-0 relative'>
      <div className='flex flex-col w-64'>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className='flex flex-col h-0 flex-1'>
          <div className='flex-1 flex flex-col pt-5 pb-4 overflow-y-auto'>
            <div className='flex items-center flex-shrink-0 px-4'>
              <img
                className={classNames('m-auto rounded-sm', {
                  // The aspect ratio of the logo could be 1:1 or 1:4 for default and legacy logos
                  'h-10': logoImageMeta && logoImageMeta.aspectRatio > 1,
                  'w-1/2': logoImageMeta && logoImageMeta.aspectRatio <= 1,
                })}
                src={
                  company.media.logo && company.media.logo !== 'default'
                    ? company.media.logo
                    : trazableLogoWhite
                }
                alt='Trazable'
              />
            </div>

            {shouldDisplayCreateButton && (
              <div className='mt-2 px-2 space-y-1'>
                <ButtonWithIcon
                  IconComponent={PlusIcon}
                  iconPosition='trailing'
                  color='white'
                  className='w-full'
                  onClick={() => {
                    analytics.track('ACTION_CLICK_CREATE_GLOBAL')
                    toggleModalCreateNew()
                  }}
                >
                  {t('createNew')}
                </ButtonWithIcon>
              </div>
            )}

            <nav className='mt-5 flex-1 px-2 space-y-1'>
              {sidebarNavigation.map(item =>
                item.isExternal ? (
                  <SidebarItem
                    key={item.name}
                    title={item.name}
                    IconComponent={item.icon}
                    as='div'
                    className='cursor-pointer'
                    data-testid={item.testId}
                    onClick={item.onClick}
                  />
                ) : (
                  <SidebarWithOnboarding
                    key={item.name}
                    title={item.name}
                    IconComponent={item.icon}
                    as={NavLink as ComponentType}
                    to={item.path}
                    onboardingSection={item.onboarding?.section}
                    active={item.path ? location.pathname.startsWith(item.path) : undefined}
                    {...(item.lock
                      ? {
                          badge: <LockClosedIcon className='h-4 w-4' />,
                          badgeColor: COLOR.RED,
                          badgeClassname: 'pr-1 pl-1 pt-1 pb-1',
                        }
                      : { badge: item.badge })}
                    onClick={() => {
                      if (item.section) {
                        analytics.track('ACTION_CLICK_MENU_SECTION', {
                          Section: item.section,
                        })
                      }
                    }}
                    className={item.testId}
                    subItems={item.subItems?.map(
                      (subItem): SidebarItemProps => ({
                        key: subItem.name,
                        IconComponent: subItem.icon,
                        title: subItem.name,
                        active: subItem.path
                          ? location.pathname.startsWith(subItem.path)
                          : undefined,
                        className: subItem.onboarding?.key
                          ? `onboarding-${subItem.onboarding?.key}`
                          : undefined,
                        as: NavLink as ComponentType,
                        to: subItem.path,
                        ...(subItem.lock
                          ? {
                              badge: <LockClosedIcon className='h-4 w-4' />,
                              badgeColor: COLOR.RED,
                              badgeClassname: 'pr-1 pl-1 pt-1 pb-1',
                            }
                          : { badge: subItem.badge }),
                        onClick: () => {
                          if (subItem.section) {
                            analytics.track('ACTION_CLICK_MENU_SECTION', {
                              Section: subItem.section,
                            })
                          }
                        },
                        'data-testid': subItem.testId,
                      })
                    )}
                  />
                )
              )}
            </nav>

            <div className='px-2'>
              <SubscriptionTrialInfo />
            </div>
          </div>

          <div className='flex-shrink-0 flex justify-between border-t border-white p-4'>
            {/* Profile button */}
            <div className='onboarding-profile flex-shrink-0'>
              <button
                className='group block focus:outline-none'
                onClick={() => {
                  analytics.track('ACTION_CLICK_MENU_SECTION', { Section: 'Profile' })
                  history.push('/profile')
                  toggleSidebar(false)
                }}
              >
                <div className='flex'>
                  <div className='text-left'>
                    <p className='text-sm font-medium text-white truncate w-44'>
                      {profile?.companyOwner ? profile.company.name : profile?.user.name}
                    </p>
                    <p className='text-xs font-medium text-blue-200 group-hover:text-white'>
                      {t('viewProfile')}
                    </p>
                  </div>
                </div>
              </button>
            </div>

            {/* Notification button */}
            <NotificationBellButton
              data-testid='notification-button-desktop'
              className='flex-shrink'
              onClick={() => toggleSidebar(false)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
