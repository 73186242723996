import { TemplateEntryType } from '@/features/templates'
import { createContext } from 'react'
import { EntryFieldProps } from '../interfaces'
import { TextEntryField } from '../fields/TextEntryField'
import { SectionEntryField } from '../fields/SectionEntryField'
import { NumberEntryField } from '../fields/NumberEntryField'
import { ConditionalEntryField } from '../fields/ConditionalEntryField'
import { BooleanEntryField } from '../fields/BooleanEntryField'
import { LongTextEntryField } from '../fields/LongTextEntryField'
import { DateEntryField } from '../fields/DateEntryField'
import { StatementEntryField } from '../fields/StatementEntryField'
import { SingleChoiceEntryField } from '../fields/SingleChoiceEntryField'
import { MultipleChoiceEntryField } from '../fields/MultipleChoiceEntryField'
import { FileEntryField } from '../fields/FileEntryField'
import { TimeEntryField } from '../fields/TimeEntryField'
import { ReferenceEntryField } from '../fields/ReferenceEntryField'
import { DocumentEntryField } from '../fields/DocumentEntryField'
import { SignatureEntryField } from '../fields/SignatureEntryField'
import { LinkEntryField } from '../fields/LinkEntryField'
import { TableEntryField } from '../fields/TableEntryField'
import { IssueReferenceEntryField } from '../fields/IssueReferenceEntryField'

interface EntryFieldComponentContext {
  components: Record<TemplateEntryType, React.FC<EntryFieldProps>>
  wrapper?: (props: {
    componentProps: EntryFieldProps
    TargetComponent: React.FC<EntryFieldProps>
  }) => JSX.Element
}

export const entryFieldComponentContext = createContext<EntryFieldComponentContext>({
  components: {
    [TemplateEntryType.SHORT_TEXT]: TextEntryField,
    [TemplateEntryType.LONG_TEXT]: LongTextEntryField,
    [TemplateEntryType.NUMBER]: NumberEntryField,
    [TemplateEntryType.DATE]: DateEntryField,
    [TemplateEntryType.TIME]: TimeEntryField,
    [TemplateEntryType.LINK]: LinkEntryField,

    [TemplateEntryType.BOOLEAN]: BooleanEntryField,
    [TemplateEntryType.MULTIPLE_CHOICE]: MultipleChoiceEntryField,
    [TemplateEntryType.SINGLE_CHOICE]: SingleChoiceEntryField,

    [TemplateEntryType.REFERENCE]: ReferenceEntryField,

    [TemplateEntryType.FILE]: FileEntryField,
    [TemplateEntryType.DOCUMENT]: DocumentEntryField,
    [TemplateEntryType.SIGNATURE]: SignatureEntryField,

    [TemplateEntryType.STATEMENT]: StatementEntryField,
    [TemplateEntryType.SECTION]: SectionEntryField,
    [TemplateEntryType.CONDITIONAL]: ConditionalEntryField,
    [TemplateEntryType.ISSUE_REFERENCE]: IssueReferenceEntryField,
    [TemplateEntryType.TABLE]: TableEntryField,
  },
})
