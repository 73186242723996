import { useTemplatesForTasks } from '@/features/templates'
import { Card, Loader } from '@blockchain-traceability-sl/tailwind-components'
import { UniqueIdentifier } from '@dnd-kit/core'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCreateTask, useUpdateTask } from '../../hooks'
import { useTasksInCalendar, useWeeklyNavigation } from './hooks'
import { CalendarColumn } from './CalendarColumn'
import { CalendarDnDContext } from './TaskCalendarDnDContext'
import { TaskCard } from './TaskCard'
import { CalendarCreateNew } from './CalendarCreateNew'
import { Dayjs } from 'dayjs'
import { ModalAddTasks } from './ModalAddTasks'
import { useToggle } from '@/hooks/use-toggle'
import { useGenerateTaskFromTemplate } from '../../utils/generate-task-from-template'
import { useAnalytics } from '@/features/analytics'
import { useTaskManager } from '../TaskManager'
import { TaskFiltersState } from '../TaskListFilters'
import { CalendarViewTypeSelector } from '../TaskCalendar/CalendarViewTypeSelector'
import { Help } from '@/components/Help'

const WEEK_DAYS_COUNT = 7

export const TaskCalendarWeekly = ({ taskFilters }: { taskFilters: TaskFiltersState }) => {
  const { t } = useTranslation('nsTasksListPage')
  const analytics = useAnalytics()
  const [modalAddTaskShow, toggleModalAddTaskShow] = useToggle(false)
  const [addTaskDaySelected, setAddTaskDaySelected] = useState<Dayjs | null>(null)

  const { createTask } = useCreateTask({
    silently: true,
    ignoreMandatoryEntries: true,
    trackPayload: { From: 'Calendar' },
  })
  const { generateTaskFromTemplate } = useGenerateTaskFromTemplate()

  const { templates } = useTemplatesForTasks()

  const { updateTask } = useUpdateTask({ silently: true })

  const {
    startDate,
    endDate,
    displayMonthAndYear,
    normalizedWeekNumber,
    nextWeek,
    prevWeek,
    goToDate,
    goToNow,
  } = useWeeklyNavigation()

  useEffect(() => {
    if (taskFilters.startDate) {
      goToDate(taskFilters.startDate)
    } else {
      goToNow()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskFilters.startDate])

  const { isLoading, isFetched, tasks, tasksGroupByDay } = useTasksInCalendar({
    filters: taskFilters,
    weekRange: { startDate, endDate },
  })

  const handleClickCreateNew = (currentDay: Dayjs) => {
    setAddTaskDaySelected(currentDay)
    toggleModalAddTaskShow(true)
    analytics.track('ACTION_CLICK_CREATE_TASK', {
      From: 'Calendar',
    })
  }

  const { openManager, reviewTask } = useTaskManager()

  const { headers, columns } = Array.from(new Array(WEEK_DAYS_COUNT)).reduce<{
    headers: ReactNode[]
    columns: ReactNode[]
  }>(
    (accumulator, value, index) => {
      const currentDay = startDate.weekday(index)
      const isToday = currentDay.isToday()
      const endOfWeek = index === WEEK_DAYS_COUNT - 1
      const dayTasks = tasksGroupByDay[currentDay.weekday()] || []

      accumulator.headers.push(
        <div
          key={`header-${currentDay.toISOString()}`}
          className={classNames(
            'text-center py-4 border-b border-t border-gray-100 text-xs leading-4 font-normal',
            {
              'text-gray-700': !isToday,
              'text-blue-600': isToday,
              'border-r': !endOfWeek,
            }
          )}
        >
          <span className='font-semibold'>{currentDay.format('D')}</span>{' '}
          <span className='capitalize'>{currentDay.format('dddd')}</span>
        </div>
      )

      accumulator.columns.push(
        <CalendarColumn
          key={`column-${currentDay.toISOString()}`}
          id={currentDay.toISOString()}
          endOfWeek={endOfWeek}
        >
          <CalendarCreateNew onClick={() => handleClickCreateNew(currentDay)} />
          {dayTasks.map((task, index) => {
            if (!task) {
              return <div key={`column-${currentDay.toISOString()}-${index}`} className='h-36' />
            }
            return (
              <TaskCard
                key={task._id}
                task={task}
                onManageClick={() => openManager(task._id)}
                onReviewClick={() => reviewTask(task._id)}
              />
            )
          })}
        </CalendarColumn>
      )

      return accumulator
    },
    { headers: [], columns: [] }
  )

  const handleDragTaskCard = (itemId: UniqueIdentifier, overId: UniqueIdentifier) => {
    const taskToUpdate = tasks.find(({ _id }) => _id === itemId)

    if (taskToUpdate) {
      updateTask({
        taskId: taskToUpdate._id,
        task: { dueDate: overId.toString() },
      })
    }
  }

  const handleSubmitAddTasks = (templateIds: string[]) => {
    templateIds.forEach(templateId => {
      const templateToCreate = templates.find(({ _id }) => _id === templateId)

      if (!templateToCreate || !addTaskDaySelected) {
        return
      }

      const taskToCreate = generateTaskFromTemplate({
        template: templateToCreate,
        dueDate: addTaskDaySelected.toISOString(),
      })
      createTask({ task: taskToCreate })
    })
  }

  return (
    <div data-testid='task-calendar-view-weekly'>
      <Card>
        <Card.Body>
          <div className='flex gap-5 items-center'>
            <div className='flex'>
              <ChevronLeftIcon
                className='w-6 h-6 text-gray-400 cursor-pointer'
                onClick={prevWeek}
              />
              <ChevronRightIcon
                className='w-6 h-6 text-gray-400 cursor-pointer'
                onClick={nextWeek}
              />
            </div>
            <div className='flex flex-col flex-grow'>
              <div className='capitalize text-2xl leading-7 font-bold'>{displayMonthAndYear}</div>
              <div className='text-base leading-6 font-medium text-gray-400'>
                {t('calendar.dates.week')} {normalizedWeekNumber}
              </div>
            </div>
            <div>
              <CalendarViewTypeSelector />
            </div>
          </div>
          <div className='mt-5 overflow-auto relative h-148'>
            <div className='grid grid-cols-7 h-12 sticky top-0 bg-white z-40 min-w-224'>
              {headers}
            </div>
            <div className='overflow-hidden min-w-224'>
              <div className='overflow-auto'>
                <CalendarDnDContext onDrag={handleDragTaskCard}>
                  <div className='grid grid-cols-7 bg-gray-50'>{columns}</div>

                  {isLoading && !isFetched && (
                    <div className='absolute top-2/4 left-2/4'>
                      <Loader center='full' />
                    </div>
                  )}
                </CalendarDnDContext>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Help className='mt-8' analyticsProperties={{ Source: 'Task calendar week' }} />

      <ModalAddTasks
        show={modalAddTaskShow}
        toggle={toggleModalAddTaskShow}
        daySelected={addTaskDaySelected}
        onSubmit={handleSubmitAddTasks}
      />
    </div>
  )
}
