import { Category } from '@/features/categories'
import { MetadataEntryRender, useMetadataByBelongsTos } from '@/features/metadatas'
import { useReceptionsWithFilters } from '@/features/receptions'
import { StakeHolderProduct } from '@/features/stakeHolderProducts'
import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  Badge,
  COLOR,
  CollapsableText,
  Loader,
  Modal,
  SIZE,
  Table,
} from '@blockchain-traceability-sl/tailwind-components'
import Big from 'big.js'
import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useStockByStakeHolderProduct } from '../hooks'

export interface ModalStakeHolderProductStockDetailsProps {
  show: boolean
  category?: Category | null
  stakeHolderProduct?: StakeHolderProduct
  toggle: () => void
}

export const ModalStakeHolderProductStockDetails = ({
  show,
  category,
  stakeHolderProduct,
  toggle,
}: ModalStakeHolderProductStockDetailsProps) => {
  const { t } = useTranslation('nsModalStakeHolderProductStockDetails')
  const { receptions: receptionsWithStock, isLoading: isReceptionsLoading } =
    useReceptionsWithFilters(
      {
        stakeHolderProductId: [stakeHolderProduct?._id || ''],
        withStock: true,
      },
      {
        enabled: !!stakeHolderProduct,
      }
    )

  const { stakeholderProductStock, isLoading: stakeholderProductStockIsLoading } =
    useStockByStakeHolderProduct(stakeHolderProduct?._id || '', {
      enabled: !!stakeHolderProduct,
    })

  const { metadatas, isLoading: isMetadataLoading } = useMetadataByBelongsTos(
    receptionsWithStock.map(({ _id }) => _id)
  )
  const getStockBadge = useCallback(
    (quantity?: string | null) => {
      if (!quantity || new Big(quantity).lte(0)) {
        return (
          <Badge color={COLOR.RED} style={{ minWidth: 110 }}>
            {t('table.outOfStock')}
          </Badge>
        )
      } else {
        return (
          <Badge color={COLOR.GREEN} style={{ minWidth: 70 }}>
            <span>{t('table.inStock')}</span>
          </Badge>
        )
      }
    },
    [t]
  )

  const matadaEnabled = metadatas.length > 0

  const [isLoadingDebounced, , isLoading] = useDebounceState(
    isReceptionsLoading || isMetadataLoading || stakeholderProductStockIsLoading
  )
  return (
    <Modal size={SIZE.LARGE} show={show} toggle={toggle} dismissButton>
      <Modal.Title className='mx-2'>
        {t('title', {
          stakeHolderProduct: stakeHolderProduct?.stakeHolder.product.name || category?.name || '',
          receptions: receptionsWithStock.length,
          stock: t('number', {
            value: stakeholderProductStock?.stock || 0,
          }),
          measurementUnit: stakeHolderProduct?.measurementUnit || category?.measurementUnit,
        })}
      </Modal.Title>
      <Modal.Body>
        {isLoading || isLoadingDebounced ? (
          <div>
            <Loader center='full' />
          </div>
        ) : (
          <div className='text-left'>
            <Table
              columns={[
                {
                  Header: t('table.reception').toString(),
                  accessor: 'reception',
                },
                {
                  Header: t('table.stock').toString(),
                  accessor: 'stock',
                },
                {
                  Header: t('table.quantity').toString(),
                  accessor: 'quantity',
                },
                {
                  Header: t('table.receptionDate').toString(),
                  accessor: 'receptionDate',
                },
                ...(matadaEnabled
                  ? [
                      {
                        Header: t('table.metadata').toString(),
                        accessor: 'metadata',
                      },
                    ]
                  : []),
              ]}
              data={receptionsWithStock.map(({ _id, reference, receptionDate, stock }) => {
                const receptionMetadata = metadatas.find(metadata => metadata.belongsTo === _id)

                return {
                  id: _id,
                  reception: reference,
                  stock: getStockBadge(stock?.toString()),
                  quantity: `${t('number', {
                    value: stock,
                  })} ${(stakeHolderProduct?.measurementUnit || category?.measurementUnit) ?? ''}`,
                  receptionDate: dayjs.utc(receptionDate).format('DD/MM/YYYY'),
                  ...(matadaEnabled
                    ? {
                        metadata: receptionMetadata ? (
                          <CollapsableText
                            seeMoreText={t('seeMore')}
                            seeLessText={t('seeLess')}
                            lineCount={2}
                          >
                            {receptionMetadata.data.map(entry => (
                              <p key={entry.key}>
                                <span className='font-semibold'>{entry.key}</span>:{' '}
                                <MetadataEntryRender {...entry} />
                              </p>
                            ))}
                          </CollapsableText>
                        ) : (
                          '-'
                        ),
                      }
                    : {}),
                }
              })}
              pagination={false}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}
