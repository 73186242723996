import { MetadataEntry } from '@/features/metadatas'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { MetadataEntryRender } from './MetadataEntryRender'

export const MetadataTable = memo(
  ({ data, onDownloadFail }: { data: MetadataEntry[]; onDownloadFail: () => void }) => {
    const { t } = useTranslation('nsModalMetadata')

    return (
      <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-200'>
          <thead className='bg-gray-50'>
            <tr>
              <th
                scope='col'
                className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
              >
                {t('table.detail')}
              </th>
              <th
                scope='col'
                className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
              >
                {t('table.value')}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((entry, index) => (
              <tr key={`mcr-${index}`} className={index % 2 ? 'bg-gray-50' : 'bg-white'}>
                <td className='px-6 py-4 text-sm font-medium text-gray-900'>{entry.key}</td>
                <td className='px-6 py-4 text-sm text-gray-500'>
                  <MetadataEntryRender {...entry} onDownloadFail={onDownloadFail} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
)
