import { UseQueryOptions, useMutation, useQueries, useQuery, useQueryClient } from 'react-query'
import { uploadFile } from '@/features/files'
import {
  createSignature,
  deleteSignature,
  getSignature,
  getSignatureByUserId,
  updateSignature,
} from './service'
import { useMyProfile } from '@/features/auth'
import { Signature } from './interfaces'
import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { mergeQueryOptions } from '@/utils/merge-query-options'

export const QUERY_SIGNATURES_KEY = 'signatures'

export const useMySignature = (
  overrideOptions?: Omit<UseQueryOptions<Signature | undefined, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { profile } = useMyProfile()
  const queryOptions = mergeQueryOptions({ enabled: !!profile?.user.id }, overrideOptions)
  const { data, ...rest } = useQuery(
    [QUERY_SIGNATURES_KEY, profile?.user.id],
    getSignature,
    queryOptions
  )

  return {
    ...rest,
    signature: data,
  }
}

export const useSignatureByUserId = (
  userId: string,
  options?:
    | Omit<UseQueryOptions<Signature | undefined, AxiosError, 'signatures'>, 'queryKey' | 'queryFn'>
    | undefined
) => {
  const { data, ...rest } = useQuery(
    [QUERY_SIGNATURES_KEY, userId],
    () => getSignatureByUserId(userId),
    options
  )

  return {
    ...rest,
    signature: data,
  }
}

export const useSignatureByUserIds = (
  userIds: string[],
  options?: Omit<UseQueryOptions<Signature[], AxiosError, 'signatures'>, 'queryKey' | 'queryFn'>
) => {
  const results = useQueries(
    userIds.map(userId => ({
      queryKey: [QUERY_SIGNATURES_KEY, userId],
      queryFn: () => getSignatureByUserId(userId),
      options,
    }))
  )

  return {
    isLoading: useMemo(() => results.some(result => result.isLoading), [results]),
    isError: useMemo(() => results.some(result => result.isError), [results]),
    signatures: useMemo(
      () => results.map(result => result.data).filter(Boolean) as Signature[],
      [results]
    ),
    isFetched: useMemo(() => results.every(result => result.isFetched), [results]),
    isFetching: useMemo(() => results.some(result => result.isFetching), [results]),
  }
}

export const useFetchSignatureByUserId = () => {
  const queryClient = useQueryClient()

  const fetchSignatureByUserId = useCallback(
    (userId: string) => {
      return queryClient.fetchQuery([QUERY_SIGNATURES_KEY, userId], () =>
        getSignatureByUserId(userId)
      )
    },
    [queryClient]
  )

  return {
    fetchSignatureByUserId,
  }
}

export const useFetchSignatureByUserIds = () => {
  const queryClient = useQueryClient()

  const fetchSignatureByUserIds = useCallback(
    async (userIds: string[]): Promise<Signature[]> => {
      const results = await Promise.all(
        userIds.map(userId => {
          return queryClient.fetchQuery([QUERY_SIGNATURES_KEY, userId], () =>
            getSignatureByUserId(userId)
          )
        })
      )

      return results.filter(Boolean) as Signature[]
    },
    [queryClient]
  )

  return {
    fetchSignatureByUserIds,
  }
}

export const useCreateSignature = () => {
  const queryClient = useQueryClient()
  const { mutate, mutateAsync, ...rest } = useMutation(
    async (svg: File) => {
      const fileResponse = await uploadFile(svg)
      return createSignature(fileResponse.path)
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(QUERY_SIGNATURES_KEY)
      },
    }
  )

  return { ...rest, createSignature: mutate, createSignatureAsync: mutateAsync }
}

export const useUpdateSignature = () => {
  const queryClient = useQueryClient()
  const { mutate, mutateAsync, ...rest } = useMutation(
    async (svg: File) => {
      const fileResponse = await uploadFile(svg)
      return updateSignature(fileResponse.path)
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(QUERY_SIGNATURES_KEY)
      },
    }
  )

  return { ...rest, updateSignature: mutate, updateSignatureAsync: mutateAsync }
}

export const useDeleteSignature = () => {
  const queryClient = useQueryClient()
  const { mutate, mutateAsync, ...rest } = useMutation(deleteSignature, {
    onSuccess() {
      queryClient.invalidateQueries(QUERY_SIGNATURES_KEY)
    },
  })

  return { ...rest, deleteSignature: mutate, deleteSignatureAsync: mutateAsync }
}
