import { api } from '@/app/api'
import { Automation } from '@/features/automations'
import { Task } from '@/features/tasks'
import { Compliance } from '../compliances'
import { Audit, AuditMetadata, AuditOperation } from './interfaces'

/**
 * Define the url path
 * @constant
 */
export const AUDITS_URL = '/heimdal/audits'

export const getMetadatasByBelongsTo = async (belongsTo: string): Promise<AuditMetadata[]> => {
  const { data: audits } = await api.get<AuditMetadata[] | void>(
    `${AUDITS_URL}/search?${new URLSearchParams({
      collection: 'metadatas',
      data: JSON.stringify({ belongsTo }),
    })}`
  )
  return audits || []
}

export const getByCreationEntityId = async <Entity = unknown>(
  entityId: string
): Promise<Audit<Entity, AuditOperation.CREATE>[]> => {
  const { data: audits } = await api.get<Audit<Entity, AuditOperation.CREATE>[] | void>(
    `${AUDITS_URL}/search?${new URLSearchParams({
      operation: AuditOperation.CREATE,
      data: JSON.stringify({ _id: entityId }),
    })}`
  )
  return audits || []
}

export const getByEntityId = async <Entity = unknown>(
  entityId: string
): Promise<Audit<Entity>[]> => {
  const { data: audits } = await api.get<Audit<Entity>[] | void>(
    `${AUDITS_URL}/search?${new URLSearchParams({
      data: JSON.stringify({ _id: entityId }),
    })}`
  )
  return audits || []
}

export const getTaskActivityById = async <Entity = unknown>(
  taskID: string
): Promise<Audit<Entity>[]> => {
  const { data: audits } = await api.get<Audit<Entity>[] | void>(`${AUDITS_URL}/tasks/${taskID}`)
  return audits || []
}

export const getLastAudit = async <Entity = unknown>(
  id: string
): Promise<Audit<Entity> | undefined> => {
  const { data } = await api.get<Audit<Entity> | undefined>(`${AUDITS_URL}/latest/${id}`)
  return data
}

export const getLatestAuditByIds = async <Entity = unknown>(
  ids: string[]
): Promise<Audit<Entity>[]> => {
  const promises = ids.map(async id => {
    const { data } = await api.get<Audit<Entity> | undefined>(`${AUDITS_URL}/latest/${id}`)
    return data
  })

  const result = await Promise.all(promises)

  return result.filter(Boolean) as Audit<Entity>[]
}

export const getAutomationActivityById = async (
  automationId: string
): Promise<Audit<Task | Automation, AuditOperation>[]> => {
  const { data: audits } = await api.get<Audit<Task | Automation, AuditOperation>[] | void>(
    `${AUDITS_URL}/automations/${automationId}`
  )
  return audits || []
}

export const getComplianceAudits = async (
  complianceId: string
): Promise<Audit<Compliance, AuditOperation>[]> => {
  const { data: audits } = await api.get<Audit<Compliance, AuditOperation>[] | void>(
    `${AUDITS_URL}/compliance/${complianceId}`
  )
  return audits || []
}
