import {
  AlertType,
  Notification,
  NotificationType,
} from '@blockchain-traceability-sl/tailwind-components'
import { ToastOptions, toast } from 'react-hot-toast'
import { EventEmitter } from 'events'
import i18n from '@/i18n'
import { isObject } from 'formik'

export const NotifyEventEmitter = new EventEmitter()

enum NOTIFY_EVENT {
  ERROR = 'error',
}

export interface NotifyBaseProps {
  title: string
  description: string
  actionText?: string
  onActionClick?: () => void
}

export interface NotifyToastProps extends NotifyBaseProps {
  closeText?: string
  onClose?: () => void
}

export interface NotifyErrorProps extends NotifyBaseProps {
  actionText: string
}

export interface NotifyErrorPayload extends NotifyErrorProps {
  severity: AlertType
}

/**
 * Show a success notification
 *
 * @param content notification content
 * @param options notification options
 * @returns notification id
 */
export const success = (content: NotifyToastProps, options?: ToastOptions): string => {
  return toast.custom(
    ({ id, visible }) => (
      <Notification
        show={visible}
        type={NotificationType.SUCCESS}
        closeText={i18n.t('nsNotification:close')}
        {...content}
        onClose={() => {
          content.onClose && content.onClose()
          toast.dismiss(id)
        }}
        onActionClick={() => {
          content.onActionClick && content.onActionClick()
          toast.dismiss(id)
        }}
      />
    ),
    options
  )
}

/**
 * Show a alert notification
 *
 * @param content notification content
 * @param options notification options
 * @returns notification id
 */
export const alert = (content: NotifyToastProps, options?: ToastOptions): string => {
  return toast.custom(
    ({ id, visible }) => (
      <Notification
        show={visible}
        type={NotificationType.ALERT}
        closeText={i18n.t('nsNotification:close')}
        {...content}
        onClose={() => {
          content.onClose && content.onClose()
          toast.dismiss(id)
        }}
        onActionClick={() => {
          content.onActionClick && content.onActionClick()
          toast.dismiss(id)
        }}
      />
    ),
    options
  )
}

/**
 * Show an error notification
 *
 * @param content notification content
 */
export const error = (content: NotifyErrorProps) => {
  NotifyEventEmitter.emit(NOTIFY_EVENT.ERROR, {
    ...content,
    severity: AlertType.ERROR,
  })
}

/**
 * Show a warning notification
 *
 * @param content notification content
 */
export const warn = (content: NotifyErrorProps) => {
  NotifyEventEmitter.emit(NOTIFY_EVENT.ERROR, {
    ...content,
    severity: AlertType.WARNING,
  })
}

/**
 * Dismiss a notification by id
 *
 * @param toastId
 */
export const dismiss = (toastId: string) => toast.dismiss(toastId)

/**
 * Dismiss all notifications
 */
export const dismissAll = () => toast.dismiss()

/**
 * Handle error notifications
 *
 * @param handler handler function
 */
export const onError = (handler: (payload: NotifyErrorPayload) => void) =>
  NotifyEventEmitter.on(NOTIFY_EVENT.ERROR, handler)

// UTILS
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const customErrorToNotify = (error: any) => {
  // Is not managed error
  if (!error || !isObject(error) || !Object.prototype.hasOwnProperty.call(error, 'entity')) {
    return {
      title: i18n.t('nsNotifyError:unknown.title'),
      description: i18n.t('nsNotifyError:unknown.description'),
      actionText: i18n.t('nsNotifyError:unknown.action'),
    }
  }

  return {
    title: i18n.t(
      `nsNotifyError:${error.entity}.${error.code}.title`,
      i18n.t(
        `nsNotifyError:${error.entity}.unknown.title`,
        i18n.t(`nsNotifyError:unknown.title`),
        {}
      ),
      {}
    ),
    description: i18n.t(
      `nsNotifyError:${error.entity}.${error.code}.description`,
      i18n.t(
        `nsNotifyError:${error.entity}.unknown.description`,
        i18n.t(`nsNotifyError:unknown.description`),
        {}
      ),
      {
        context: error.context,
      }
    ),
    actionText: i18n.t(
      `nsNotifyError:${error.entity}.${error.code}.action`,
      i18n.t(
        `nsNotifyError:${error.entity}.unknown.action`,
        i18n.t(`nsNotifyError:unknown.action`),
        {}
      ),
      {}
    ),
  }
}
