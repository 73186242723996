import { noop } from '@/utils/noop'
import { createContext } from 'react'

export interface TaskManagerContext {
  openManager: (taskId: string) => void
  reviewTask: (taskId: string) => void
  manageTaskIssues: (taskId: string) => void
  completeTaskBulk: (taskIds: string[]) => void
  requestValidationBulk: (taskIds: string[]) => void
  reviewTaskBulk: (taskIds: string[]) => void
  closeTaskBulk: (taskIds: string[]) => void
}

export const taskManagerContext = createContext<TaskManagerContext>({
  openManager: noop,
  reviewTask: noop,
  manageTaskIssues: noop,
  completeTaskBulk: noop,
  requestValidationBulk: noop,
  reviewTaskBulk: noop,
  closeTaskBulk: noop,
})
