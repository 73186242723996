import { Button } from '@blockchain-traceability-sl/tailwind-components'
import { EntryFieldProps } from '../interfaces'
import { useTranslation } from 'react-i18next'
import { TemplateEntryType } from '@/features/templates'
import classNames from 'classnames'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useEntryFieldAttributes } from '../utils'
import { EntryLabel } from '../EntryLabel'
import { EntryCreateInput } from '../../interfaces'
import { useField } from 'formik'

export const DocumentEntryField = ({
  index,
  prefixName,
  documentOptions,
  disabled,
}: EntryFieldProps) => {
  const { t } = useTranslation('nsEntries')

  const { entryFieldName, valueFieldName, enabledFieldName, enabledFieldId } =
    useEntryFieldAttributes(index, prefixName)

  const [{ value: entry }] = useField<EntryCreateInput>(entryFieldName)
  const [, , enabledInputFieldHelpers] = useField<boolean>(enabledFieldName)
  const [, { error, touched }, valueInputFieldHelpers] = useField<string>(valueFieldName)

  const requiredError = !!error && touched

  if (entry.type !== TemplateEntryType.DOCUMENT || !documentOptions) return null

  const isEntryEnabled = entry.enabled ?? true

  const { setDocumentPickerTrigger, toggleShowDocumentPicker, documentsPicked } = documentOptions

  const labelWithNa = (
    <EntryLabel
      naCheckboxId={enabledFieldId}
      naCheckboxName={enabledFieldName}
      naUnchecked={isEntryEnabled}
      onNaChange={enabled => {
        enabledInputFieldHelpers.setValue(enabled)
        enabledInputFieldHelpers.setTouched(true)
      }}
      naCheckboxLabel={t('form.na')}
      mandatoryPrefixText={t('form.mandatory.indicator')}
      isMandatory={entry.mandatory}
      disabled={disabled}
    >
      <label
        className={classNames('block text-sm font-medium  mb-1', {
          'text-gray-400': !isEntryEnabled,
          'text-gray-700': isEntryEnabled,
          'cursor-not-allowed': !isEntryEnabled,
        })}
      >
        {entry.name}
      </label>
    </EntryLabel>
  )

  if (!isEntryEnabled) {
    return labelWithNa
  }

  return (
    <div>
      {labelWithNa}
      {!entry.value ? (
        <Button
          color='secondary'
          className={classNames({
            'border-red-500': !!requiredError,
          })}
          onClick={() => {
            setDocumentPickerTrigger(valueFieldName)
            toggleShowDocumentPicker()
          }}
          disabled={disabled}
        >
          {t('form.document.choose')}
          {!!requiredError && (
            <ExclamationCircleIcon className='ml-1 -mr-1 h-5 w-5 text-red-500' aria-hidden='true' />
          )}
        </Button>
      ) : (
        <>
          <div className='space-x-3'>
            <Button
              color='secondary'
              onClick={() => {
                setDocumentPickerTrigger(valueFieldName)
                toggleShowDocumentPicker()
              }}
              disabled={disabled}
            >
              {t('form.document.change')}
            </Button>
            <Button
              color='danger'
              onClick={() => {
                valueInputFieldHelpers.setValue('')
                valueInputFieldHelpers.setTouched(false)
              }}
              disabled={disabled}
            >
              {t('form.document.remove')}
            </Button>
          </div>
          <p className='mt-2 text-sm text-gray-500'>
            {documentsPicked.length > 0 &&
              t('form.document.fileSelected', {
                name: documentsPicked.find(({ _id }) => _id === entry.value)?.title,
              })}
          </p>
        </>
      )}
    </div>
  )
}
