import { useLayout } from '@/features/layout'
import { Loader } from '@blockchain-traceability-sl/tailwind-components'
import { Popover, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
import { useAckNotifications, useNotificationPanel, useNotifications } from '../hooks'
import { NotificationItem } from './NotificationItem'

interface NotificationsPanelProps {
  className?: string
}
export const NotificationsPanel = ({ className }: NotificationsPanelProps) => {
  const { t } = useTranslation('nsNotificationPanel')
  const { showPanel, toggleShowPanel } = useNotificationPanel()
  const { toggleSidebar } = useLayout()

  const { notifications, isLoading } = useNotifications()
  const { ackNotifications } = useAckNotifications()

  const notificationsNotAcknowledged = notifications.filter(
    ({ acknowledged }) => !acknowledged
  ).length

  // Ack all notifications on close
  const handleClose = () => {
    ackNotifications(notifications.map(({ _id }) => _id))
    toggleShowPanel(false)
  }

  // Ack all notifications on go back
  const handleGoBack = () => {
    handleClose()
    toggleSidebar(true)
  }

  return (
    <Popover className='relative'>
      {showPanel && <Popover.Overlay static onClick={handleClose} className='fixed inset-0 z-10' />}

      <Transition
        show={showPanel}
        className='absolute h-full left-full z-10'
        enter='transition-width duration-150'
        enterFrom='w-0 lg:w-48'
        enterTo='w-screen lg:w-144'
        leave='transition-width duration-75'
        leaveFrom='w-screen lg:w-144'
        leaveTo='w-0 lg:w-48'
      >
        <Popover.Panel
          static
          data-testid='notification-panel'
          className='bg-white lg:rounded-r-3xl border-r border-gray-200 filter drop-shadow-lg h-screen z-20 flex flex-col divide-y divide-gray-200'
        >
          {/* Header */}
          <div className='flex justify-between p-8'>
            <div className='text-lg leading-6 font-semibold flex space-x-2'>
              <span>{t('header')}</span>
              {notificationsNotAcknowledged > 0 && (
                <div>
                  <div className='flex flex-wrap justify-center content-center w-5 h-5 bg-red-600 text-white text-xs m-auto rounded-full'>
                    <span>{notificationsNotAcknowledged}</span>
                  </div>
                </div>
              )}
            </div>
            <button
              className='hidden lg:block absolute top-6 right-6'
              onClick={handleClose}
              data-testid='notification-panel-dismiss-desktop'
            >
              <XIcon className='w-6 h-6 text-gray-400' />
            </button>
            <button
              className='lg:hidden self-center'
              onClick={handleGoBack}
              data-testid='notification-panel-dismiss-mobile'
            >
              <ChevronLeftIcon className='w-6 h-6 text-gray-900' />
            </button>
          </div>

          {/* Content */}
          <div className='px-8 pt-8 mb-8 space-y-8 overflow-y-auto'>
            {isLoading ? (
              <Loader center='full' />
            ) : (
              notifications.map(notification => (
                <NotificationItem key={notification._id} notification={notification} />
              ))
            )}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
