import { XCircleIcon } from '@heroicons/react/solid'
import { Trans } from 'react-i18next'

export const EntryConflictError = ({ message }: { message?: string }) => (
  <div className='py-2'>
    <div className='flex flex-row'>
      <XCircleIcon className='h-6 w-6 text-red-400 mr-2' />
      {message ? (
        <div>
          <Trans
            i18nKey='nsComplianceFillingPage:form.comment'
            components={[<span className='text-sm font-semibold text-gray-700' />]}
          />
          <span className='text-sm ml-2 font-normal'>{message}</span>
        </div>
      ) : (
        <span>
          <Trans
            i18nKey='nsComplianceFillingPage:form.noComment'
            components={[<span className='text-sm font-semibold text-gray-700' />]}
          />
        </span>
      )}
    </div>
  </div>
)
