import { TAX_ID_EXPLANATION } from '@/app/constants'
import { useCountryCodes } from '@/app/hooks'
import config from '@/config'
import { StakeHolderType } from '@/features/stakeHolders'
import {
  AddressField,
  InputField,
  Section,
  SelectField,
  SelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface StakeholderHeaderProps {
  nameValue?: string
  nameError: boolean
  nameErrorText?: string
  onNameChange: (value: string) => void

  referenceValue?: string
  referenceError: boolean
  referenceErrorText?: string
  onReferenceChange: (value: string) => void

  countryCodeValue?: string
  countryCodeError: boolean
  onCountryCodeChange: (value: string | null) => void

  addressValue?: string
  addressError: boolean
  onAddressChange: (value: string | null) => void

  typeValue?: string
  typeError: boolean
  onTypeChange: (value: string | null) => void

  onInputBlur: (event: React.FocusEvent<HTMLInputElement>) => void
}

export const StakeholderHeader = ({
  nameValue,
  nameError,
  nameErrorText,
  onNameChange,

  referenceValue,
  referenceError,
  referenceErrorText,
  onReferenceChange,

  countryCodeValue,
  countryCodeError,
  onCountryCodeChange,

  addressValue,
  addressError,
  onAddressChange,

  typeValue,
  typeError,
  onTypeChange,

  onInputBlur,
}: StakeholderHeaderProps) => {
  const { t } = useTranslation('nsComplianceFillingPage')

  const stakeHolderTypeOptions: SelectorOption[] = useMemo(
    () => [
      {
        label: t(`contact.type.types.${StakeHolderType.PROVIDER}`),
        value: StakeHolderType.PROVIDER,
      },
      {
        label: t(`contact.type.types.${StakeHolderType.CLIENT}`),
        value: StakeHolderType.CLIENT,
      },
      {
        label: t(`contact.type.types.${StakeHolderType.BOTH}`),
        value: StakeHolderType.BOTH,
      },
    ],
    [t]
  )

  const countryCodes = useCountryCodes()

  return (
    <div className='flex flex-col'>
      <Section title={t('contact.title')} defaultOpen>
        <div className='grid grid-cols-1 gap-6 mt-4 bg-gray-100 p-6 rounded-md'>
          <InputField
            id='contactDetails_name'
            name='contactDetails.name'
            onChange={event => {
              onNameChange(event.currentTarget.value)
            }}
            onBlur={onInputBlur}
            value={nameValue}
            label={t('contact.name.label')}
            description={t('contact.name.description')}
            error={nameError}
            errorText={nameErrorText}
          />
          <InputField
            id='contactDetails_reference'
            name='contactDetails.reference'
            className='uppercase'
            onChange={event => {
              onReferenceChange(event.currentTarget.value.toUpperCase())
            }}
            onBlur={onInputBlur}
            value={referenceValue}
            label={t('contact.reference.label')}
            description={
              <Trans
                t={t}
                i18nKey='contact.reference.description'
                components={{
                  a: (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      href={TAX_ID_EXPLANATION}
                      target='_blank'
                      rel='noreferrer'
                      className='underline hover:text-gray-400'
                    />
                  ),
                }}
              />
            }
            error={referenceError}
            errorText={referenceErrorText}
          />
          <SelectField
            id='contactDetails_countryCode'
            name='contactDetails.countryCode'
            options={countryCodes}
            value={countryCodes.find(countryCode => countryCode.value === countryCodeValue)}
            onChange={option => onCountryCodeChange(option?.value || null)}
            onBlur={onInputBlur}
            label={t('contact.countryCode.label')}
            error={countryCodeError}
          />
          <AddressField
            mapboxAccessToken={config.MAPBOX_ACCESS_TOKEN}
            id='contactDetails_address'
            name='contactDetails.address'
            onChange={address => onAddressChange(address)}
            onBlur={onInputBlur}
            value={addressValue}
            label={t('contact.address.label')}
            description={t('contact.address.description')}
            placeholder={t('contact.address.placeholder')}
            error={addressError}
          />
          <SelectField
            id='contactDetails_type'
            name='contactDetails.type'
            options={stakeHolderTypeOptions}
            value={stakeHolderTypeOptions.find(
              stakeHolderTypeOption => stakeHolderTypeOption.value === typeValue
            )}
            onChange={option => onTypeChange(option?.value || null)}
            onBlur={onInputBlur}
            label={t('contact.type.label')}
            error={typeError}
          />
        </div>
      </Section>
    </div>
  )
}
