import { TaskFiltersState } from '../TaskListFilters'
import { useLocalStorage } from 'usehooks-ts'
import { PERSISTED_TASK_CALENDAR_VIEW_TYPE_KEY } from '@/pages/tasks/TasksList/constants'
import { TaskCalendarWeekly } from '../TaskCalendarWeek'
import { TaskCalendarMonthly } from '../TaskCalendarMonthly'

export enum CALENDAR_VIEW_TYPE {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export const TaskCalendar = ({ taskFilters }: { taskFilters: TaskFiltersState }) => {
  const [currentView] = useLocalStorage<CALENDAR_VIEW_TYPE>(
    PERSISTED_TASK_CALENDAR_VIEW_TYPE_KEY,
    CALENDAR_VIEW_TYPE.WEEKLY
  )

  if (currentView === CALENDAR_VIEW_TYPE.WEEKLY) {
    return <TaskCalendarWeekly taskFilters={taskFilters} />
  }

  if (currentView === CALENDAR_VIEW_TYPE.MONTHLY) {
    return <TaskCalendarMonthly taskFilters={taskFilters} />
  }

  return null
}
