import { api } from '@/app/api'
import { Automation, AutomationInput } from './interfaces'

export const AUTOMATION_URL = '/templates/automations'

export const create = async (automationParams: AutomationInput) => {
  const { data } = await api.post<string>(AUTOMATION_URL, automationParams)

  return data
}

export const getAll = async () => {
  const { data } = await api.get<Automation[]>(AUTOMATION_URL)
  return data
}

export const getById = async (automationId: string) => {
  const { data } = await api.get<Automation | undefined>(`${AUTOMATION_URL}/${automationId}`)
  return data
}

export const remove = async (automationId: string) => {
  await api.delete<void>(`${AUTOMATION_URL}/${automationId}`)
}

export const update = async (automationId: string, automationParams: AutomationInput) => {
  await api.patch<string>(`${AUTOMATION_URL}/${automationId}`, automationParams)
}

export const getByTemplateId = async (templateId: string) => {
  const { data } = await api.get<Automation[]>(`${AUTOMATION_URL}/template/${templateId}`)
  return data || []
}
