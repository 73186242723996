import { useCompanyUser, useIsMe } from '@/features/auth'
import { Issue, IssueStatus } from '@/features/issues'
import { StakeHolder, useStakeHolderByIds } from '@/features/stakeHolders'
import {
  ITaskTableItem,
  StakeHolderTaskRelation,
  Task,
  getAssignedCreatorTaskName,
  useStakeHolderTaskRelationsByIds,
} from '@/features/tasks'
import { useTemplatesForTasks } from '@/features/templates'
import { useUsersWithOwner } from '@/features/users'
import { ValidationRequest, useGetLastValidator } from '@/features/validationRequests'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type AssociatedTaskStakeHolder = StakeHolderTaskRelation & { stakeHolder?: StakeHolder }

export const useFormatTaskToTab = ({
  validationRequests = [],
  issues = [],
  associatedStakeholders = [],
}: {
  validationRequests?: ValidationRequest[]
  associatedStakeholders?: AssociatedTaskStakeHolder[]
  issues?: Issue[]
} = {}) => {
  const { t } = useTranslation('nsTasksListPage')
  const { templates } = useTemplatesForTasks()
  const { isMe } = useIsMe()
  const company = useCompanyUser()
  const { getLastValidator } = useGetLastValidator()
  const { users } = useUsersWithOwner()

  const formatTaskToTab = useCallback(
    ({
      _id,
      formId,
      assignedTo,
      status,
      dueDate,
      createdAt,
      formVersion,
    }: Task): ITaskTableItem => {
      const taskForm = templates.find(({ _id }) => _id === formId)
      const taskValidation = validationRequests.find(({ entity }) => entity.id === _id)
      const taskIssues = issues.filter(issue => issue.associatedTaskId === _id)

      const lastValidator = taskValidation ? getLastValidator(taskValidation) : undefined
      return {
        id: _id,
        name: taskForm?.name || 'n/a',
        version: formVersion,
        assignedTo: isMe(assignedTo)
          ? t('table.assignedToMe')
          : getAssignedCreatorTaskName(assignedTo, { company, users }),
        ...(lastValidator && taskValidation
          ? {
              validation: {
                isValidatedByMe: lastValidator.isValidatedByMe,
                status: taskValidation.status,
                validator: lastValidator.name,
              },
            }
          : {}),
        status,
        dueDate: t('table.dueDateFormat', { date: new Date(dueDate) }),
        dueDateRaw: dueDate,
        creationDate: t('table.creationDateFormat', { date: new Date(createdAt) }),
        creationDateRaw: createdAt,
        issues: taskIssues.map(issue => ({
          id: issue._id,
          status: issue?.status,
        })),

        associatedStakeHolders: associatedStakeholders.reduce(
          (accumulator, { taskId, stakeHolder }) => {
            if (taskId === _id && stakeHolder) {
              accumulator.push(stakeHolder)
            }
            return accumulator
          },
          [] as StakeHolder[]
        ),
      }
    },
    [
      templates,
      validationRequests,
      issues,
      getLastValidator,
      isMe,
      t,
      company,
      users,
      associatedStakeholders,
    ]
  )
  return {
    formatTaskToTab,
  }
}

export const useAssociatedStakeholdersByTasks = (
  taskIds: string[],
  { enabled }: { enabled: boolean }
) => {
  const { stakeHolderTaskRelations, isLoading: isRelationsLoading } =
    useStakeHolderTaskRelationsByIds(taskIds, {
      enabled,
    })

  const stakeHolderIds = useMemo(
    () => stakeHolderTaskRelations.map(({ stakeHolderId }) => stakeHolderId),
    [stakeHolderTaskRelations]
  )

  const { stakeHolders, isLoading: isStakeHoldersLoading } = useStakeHolderByIds(stakeHolderIds, {
    enabled: enabled && stakeHolderIds.length > 0,
  })

  const associatedStakeholders = useMemo(() => {
    return stakeHolderTaskRelations.map(relation => {
      const stakeHolder = stakeHolders.find(({ _id }) => _id === relation.stakeHolderId)
      return {
        ...relation,
        stakeHolder,
      }
    })
  }, [stakeHolderTaskRelations, stakeHolders])

  return {
    associatedStakeholders,
    isLoading: isRelationsLoading || isStakeHoldersLoading,
  }
}

export const useIssueStatusGroups = () => {
  const issueStatusGroups = {
    /**
     * @description the issue status that represent an open issue, used in the open issues TAB
     */
    OPEN: [
      IssueStatus.ACKNOWLEDGED,
      IssueStatus.OPEN,
      IssueStatus.IN_PROGRESS,
      IssueStatus.IN_REVIEW,
      IssueStatus.RESOLVED,
    ],
    /**
     * @description the issue status that represent a closed issue, used in the closed issues TAB and in the table summary
     */
    CLOSED: [IssueStatus.CLOSED],
    /**
     * @description the issue status that represent a new  issue, used in the table summary
     */
    NEW: [IssueStatus.ACKNOWLEDGED, IssueStatus.OPEN, IssueStatus.IN_PROGRESS],
    /**
     * @description the issue status that represent an in progress issue, used in the table summary
     */
    IN_PROGRESS: [IssueStatus.IN_REVIEW, IssueStatus.RESOLVED],
  }
  return {
    issueStatusGroups,
  }
}
