import { CheckboxField } from '@blockchain-traceability-sl/tailwind-components'

export interface EntryNaCheckProps {
  id: string
  name: string
  unchecked: boolean
  checkboxLabel: string
  onChange: (value: boolean) => void
}
export const EntryNaCheck = ({
  unchecked,
  id,
  name,
  onChange,
  checkboxLabel,
}: EntryNaCheckProps) => {
  return (
    <CheckboxField
      checked={!unchecked}
      className='text-sm leading-5 font-medium text-gray-500'
      id={id}
      name={name}
      onChange={event => {
        onChange(!event.target.checked)
      }}
      label={checkboxLabel}
    />
  )
}
