import { DotsVerticalIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
import { Dropdown } from './Dropdown'

interface TableColumnDropdownProps {
  onAddColumnClick: () => void
  onFixColumnClick: () => void
  onRemoveColumnClick: () => void
  enableFix?: boolean
  valueFix?: boolean
  disableRemove?: boolean
}
export const TableColumnDropdown = ({
  onAddColumnClick,
  onFixColumnClick,
  onRemoveColumnClick,
  enableFix,
  valueFix,
  disableRemove,
}: TableColumnDropdownProps) => {
  const { t } = useTranslation('nsTemplate')

  return (
    <Dropdown
      button={
        <Dropdown.IconButton>
          <span className='sr-only'>Open options</span>

          <DotsVerticalIcon className='h-5 w-5' aria-hidden='true' />
        </Dropdown.IconButton>
      }
    >
      <Dropdown.Item onClick={onAddColumnClick}>
        {t('entries.table.menuItems.addColumn')}
      </Dropdown.Item>

      <Dropdown.Item
        onClick={!disableRemove ? onRemoveColumnClick : undefined}
        disabled={disableRemove}
      >
        {t('entries.table.menuItems.removeColumn')}
      </Dropdown.Item>

      {enableFix && (
        <Dropdown.Item onClick={onFixColumnClick}>
          {valueFix ? t('entries.table.menuItems.unfix') : t('entries.table.menuItems.fix')}
        </Dropdown.Item>
      )}
    </Dropdown>
  )
}
