import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from 'react-query'
import * as NotifyService from '@/services/notify.service'
import { customErrorToTrack, useAnalytics } from '../analytics'
import {
  createStakeHolderProduct,
  createWithCSV,
  getAll,
  getById,
  getByIds,
  getCount,
  getCsvTemplate,
  getPaginatedStakeholderProducts,
  getStockById,
  remove,
  updateStakeHolderProduct,
} from './service'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import { useCallback, useMemo } from 'react'
import {
  StakeHolderProduct,
  StakeHolderProductFilters,
  StakeHolderProductUpdate,
  StakeholderProductStock,
} from './interfaces'
import { downloader } from '@/helpers/downloader'
import { useHistory } from 'react-router-dom'
import { BulkError, CustomError, ErrorCodes } from '@blockchain-traceability-sl/custom-error-codes'
import { downloadstakeHolderProductBulkErrorReport } from './helpers'
import { Paginated } from '@/hooks/use-pagination'
import { mergeInfiniteQueryOptions } from '@/utils/merge-query-options'

export const QUERY_STAKEHOLDER_PRODUCTS_KEY = 'stakeholder-products'
export const QUERY_STAKEHOLDER_PRODUCTS_STOCK = 'stakeholder-products-stock'

export const useStakeHolderProducts = (
  filters?: StakeHolderProductFilters,
  options?: Omit<UseQueryOptions<StakeHolderProduct[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_STAKEHOLDER_PRODUCTS_KEY, { filters }],
    () => getAll(filters),
    options
  )

  return { ...rest, stakeHolderProducts: useMemo(() => data || [], [data]) }
}

export const useStakeHolderProduct = (
  id: string,
  options?: Omit<UseQueryOptions<StakeHolderProduct | void, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_STAKEHOLDER_PRODUCTS_KEY, id],
    () => getById(id),
    options
  )

  return { ...rest, stakeHolderProduct: data || undefined }
}

export const usePaginatedStakeHolderProducts = ({
  filters = {},
  pageSize = 10,
  ...overrideOptions
}: Omit<
  UseInfiniteQueryOptions<Paginated<StakeHolderProduct>, AxiosError>,
  'queryKey' | 'queryFn'
> & {
  filters?: StakeHolderProductFilters
  /**
   * @default 10
   */
  pageSize?: number
}) => {
  const queryClient = useQueryClient()

  const options = mergeInfiniteQueryOptions(overrideOptions, {
    keepPreviousData: true,
    // Cache each product as individual cached item by id
    onSuccess(data) {
      overrideOptions?.onSuccess?.(data)
      data.pages
        .flatMap(({ items }) => items)
        .forEach(item => {
          queryClient.setQueryData([QUERY_STAKEHOLDER_PRODUCTS_KEY, item._id], item)
        })
    },
    getNextPageParam: lastPage => lastPage.pageInfo.nextPage || undefined,
    getPreviousPageParam: lastPage => lastPage.pageInfo.previousPage || undefined,
  })

  const { data, ...rest } = useInfiniteQuery(
    [QUERY_STAKEHOLDER_PRODUCTS_KEY, { filters, pageSize }, { isInfiniteQuery: true }],
    async ({ pageParam }) => {
      return getPaginatedStakeholderProducts(filters, {
        offset: pageParam?.offset || 0,
        limit: pageParam?.limit || pageSize,
      })
    },
    options
  )

  const stakeHolderProducts: StakeHolderProduct[] = useMemo(
    () => data?.pages.flatMap(({ items }) => items) || [],
    [data?.pages]
  )

  const totalCount = useMemo(
    () => data?.pages[data?.pages.length - 1]?.totalCount || 0,
    [data?.pages]
  )

  return {
    ...rest,
    data,
    stakeHolderProducts,
    totalCount,
  }
}

export const useStockByStakeHolderProduct = (
  stakeholderProductId: string,
  options?:
    | Omit<
        UseQueryOptions<
          StakeholderProductStock,
          unknown,
          StakeholderProductStock,
          (string | { stakeholderProductId: string })[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined
) => {
  const { data, ...rest } = useQuery(
    [QUERY_STAKEHOLDER_PRODUCTS_STOCK, { stakeholderProductId }],
    () => getStockById(stakeholderProductId),
    options
  )

  return {
    ...rest,
    stakeholderProductStock: data,
  }
}

export const useFetchStakeHolderProducts = (filters: StakeHolderProductFilters) => {
  const queryClient = useQueryClient()

  const fetchStakeHolderProducts = useCallback(
    () => queryClient.fetchQuery([QUERY_STAKEHOLDER_PRODUCTS_KEY, filters], () => getAll(filters)),
    [filters, queryClient]
  )

  return {
    fetchStakeHolderProducts,
  }
}

export const useFetchStakeHolderProductById = () => {
  const queryClient = useQueryClient()

  const fetchStakeHolderProduct = useCallback(
    (stakeHolderProductId: string) =>
      queryClient.fetchQuery([QUERY_STAKEHOLDER_PRODUCTS_KEY, stakeHolderProductId], () =>
        getById(stakeHolderProductId)
      ),
    [queryClient]
  )

  return {
    fetchStakeHolderProduct,
  }
}

export const useStakeHolderProductsCount = (
  options?: Omit<UseQueryOptions<number, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data = 0, ...rest } = useQuery(
    [QUERY_STAKEHOLDER_PRODUCTS_KEY, { count: true }],
    getCount,
    options
  )

  return { ...rest, stakeHolderProductsCount: data }
}

export const useStakeHolderProductsByIds = (
  ids: string[],
  options?: Omit<UseQueryOptions<StakeHolderProduct[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_STAKEHOLDER_PRODUCTS_KEY, { ids }],
    () => getByIds(ids),
    options
  )

  const stakeHolderProducts = useMemo(() => data || [], [data])

  return {
    ...rest,
    stakeHolderProducts,
  }
}

export const useStakeHolderProductById = (
  stakeHolderProductId: string,
  options?: Omit<UseQueryOptions<StakeHolderProduct | void, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_STAKEHOLDER_PRODUCTS_KEY, stakeHolderProductId],
    () => getById(stakeHolderProductId),
    options
  )

  return {
    ...rest,
    stakeHolderProduct: data || undefined,
  }
}

export const useUpdateStakeHolderProduct = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const queryClient = useQueryClient()
  const { mutate, ...rest } = useMutation(
    ({ _id, ...update }: StakeHolderProductUpdate & { _id: string }) =>
      updateStakeHolderProduct(_id, update),
    {
      onSuccess: (data, variables) => {
        analytics.track('CUSTOMER_EDIT_STAKEHOLDER_PRODUCT', {
          Product: variables.name,
        })
        NotifyService.success({
          title: t('stakeHolderProduct.update.success.title'),
          description: t('stakeHolderProduct.update.success.description'),
        })
        queryClient.invalidateQueries(QUERY_STAKEHOLDER_PRODUCTS_KEY)
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_EDIT_STAKEHOLDER_PRODUCT_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return { ...rest, updateStakeHolderProduct: mutate }
}

export const useCreateStakeHolderProduct = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const queryClient = useQueryClient()
  const { mutate, mutateAsync, ...rest } = useMutation(createStakeHolderProduct, {
    onSuccess: (data, variables) => {
      analytics.track('CUSTOMER_CREATE_STAKEHOLDER_PRODUCT', {
        Product: variables.name,
      })
      NotifyService.success({
        title: t('stakeHolderProduct.create.success.title'),
        description: t('stakeHolderProduct.create.success.description'),
      })
      queryClient.invalidateQueries(QUERY_STAKEHOLDER_PRODUCTS_KEY)
    },
    onError: (error: AxiosError) => {
      analytics.track(
        'CUSTOMER_CREATE_STAKEHOLDER_PRODUCT_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return { ...rest, createStakeHolderProduct: mutate, createStakeHolderProductAsync: mutateAsync }
}

export const useCreateStakeHolderProductsWithCSV = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const history = useHistory()

  /**
   * Set as valid processed if is Bulk error and all errors are already exist
   */
  const isAcceptableError = (error: AxiosError) => {
    return (
      error.response?.data.code === ErrorCodes.BULK_ERROR &&
      (error.response?.data as BulkError).errors.every(
        ({ error: customError }) => customError.code === ErrorCodes.ALREADY_EXISTS
      )
    )
  }

  const { mutate, ...rest } = useMutation(createWithCSV, {
    onSuccess(data) {
      analytics.track('CUSTOMER_CREATE_STAKEHOLDER_PRODUCTS_CSV')
      NotifyService.success({
        title: t('stakeHolderProduct.createBulk.success.title'),
        description: t('stakeHolderProduct.createBulk.success.description', {
          count: data.total,
        }),
        actionText: t('stakeHolderProduct.createBulk.success.action'),
        onActionClick: () => history.push('/stakeholder-product/create'),
      })
      queryClient.invalidateQueries(QUERY_STAKEHOLDER_PRODUCTS_KEY)
    },
    onError(error: AxiosError) {
      analytics.track(
        'CUSTOMER_CREATE_STAKEHOLDER_PRODUCTS_CSV_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )

      switch (error.response?.data.code) {
        case ErrorCodes.BULK_ERROR:
          {
            const bulkError: BulkError = error.response?.data

            // Set as valid processed if all errors are already exist
            if (
              bulkError.errors.every(
                ({ error: customError }) => customError.code === ErrorCodes.ALREADY_EXISTS
              )
            ) {
              NotifyService.success({
                title: t('stakeHolderProduct.createBulk.success.title'),
                description: t('stakeHolderProduct.createBulk.success.description', {
                  count: bulkError.total,
                }),
                actionText: t('stakeHolderProduct.createBulk.success.action'),
                onActionClick: () => history.push('/stakeholder-product/create'),
              })
            } else {
              NotifyService.error({
                title: t('stakeHolderProduct.createBulk.bulkError.title'),
                description: t('stakeHolderProduct.createBulk.bulkError.description', {
                  processed: bulkError.processed,
                  total: bulkError.total,
                }),
                actionText: t('stakeHolderProduct.createBulk.bulkError.action'),
                onActionClick: () => downloadstakeHolderProductBulkErrorReport(bulkError),
              })
            }
          }
          break

        case 10: // InvalidCSVError
          {
            const invalidCSVError: CustomError = error.response?.data

            if (invalidCSVError.entity !== 'file') {
              NotifyService.error({
                title: t('stakeHolderProduct.createBulk.entityError.title'),
                description: t('stakeHolderProduct.createBulk.entityError.description', {
                  context: invalidCSVError.entity,
                }),
                actionText: t('stakeHolderProduct.createBulk.entityError.action'),
              })
            } else {
              NotifyService.error({
                title: t('stakeHolderProduct.createBulk.error.title'),
                description: t('stakeHolderProduct.createBulk.error.description'),
                actionText: t('stakeHolderProduct.createBulk.error.action'),
              })
            }
          }
          break
        default:
          NotifyService.error({
            title: t('stakeHolderProduct.createBulk.error.title'),
            description: t('stakeHolderProduct.createBulk.error.description'),
            actionText: t('stakeHolderProduct.createBulk.error.action'),
          })
          break
      }
    },
  })

  return { ...rest, createStakeHolderProductWithCSV: mutate, isAcceptableError }
}

export const useDownloadTemplateStakeHolderProductCSV = () => {
  const { data } = useQuery([QUERY_STAKEHOLDER_PRODUCTS_KEY, { template: 'csv' }], getCsvTemplate)

  const downloadTemplate = (fileName: string) => {
    if (data) downloader(data, 'text/csv', fileName)
  }

  return { downloadTemplate }
}

export const useDeleteStakeHolderProduct = () => {
  const analytics = useAnalytics()
  const { t } = useTranslation('nsNotification')
  const queryClient = useQueryClient()
  const history = useHistory()

  const { mutate, ...rest } = useMutation(
    (stakeHolderProduct: StakeHolderProduct) => remove(stakeHolderProduct._id),
    {
      onSuccess: (data, stakeHolderProduct) => {
        analytics.track('CUSTOMER_DELETE_STAKEHOLDER_PRODUCT', {
          Product: stakeHolderProduct.stakeHolder.product.name,
        })
        NotifyService.success({
          title: t('stakeHolderProduct.delete.success.title'),
          description: t('stakeHolderProduct.delete.success.description'),
          actionText: t('stakeHolderProduct.delete.success.action'),
          onActionClick: () => history.push('/stakeholder-products/create'),
        })
        queryClient.invalidateQueries([QUERY_STAKEHOLDER_PRODUCTS_KEY])
      },
      onError: (error: AxiosError) => {
        analytics.track(
          'CUSTOMER_DELETE_STAKEHOLDER_PRODUCT_ERROR',
          customErrorToTrack(error.response?.data, error.response?.status)
        )
        NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
      },
    }
  )

  return { ...rest, deleteStakeHolderProduct: mutate }
}
export const useStakeHolderProductsByCategoryIds = (
  categoryIds: string[],
  options?: Omit<UseQueryOptions<StakeHolderProduct[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const stakeholderProductsQueries = useQueries([
    ...categoryIds.map(categoryId => {
      const filters: StakeHolderProductFilters = { categoryId }
      return {
        queryKey: [QUERY_STAKEHOLDER_PRODUCTS_KEY, filters],
        queryFn: () => getAll(filters),
        options,
      }
    }),
  ])

  return {
    isLoading: stakeholderProductsQueries.some(
      stakeholderProductsQuery => stakeholderProductsQuery.isLoading
    ),
    stakeholderProducts: stakeholderProductsQueries
      .flatMap(stakeholderProductsQuery => stakeholderProductsQuery.data)
      .filter(
        stakeholderProductsQuery => stakeholderProductsQuery !== undefined
      ) as StakeHolderProduct[],
  }
}

export const useFetchStakeHolderProductsByIds = () => {
  const queryClient = useQueryClient()

  const fetchStakeHolderProductsByIds = useCallback(
    (ids: string[]) =>
      queryClient.fetchQuery([QUERY_STAKEHOLDER_PRODUCTS_KEY, { ids }], () => getByIds(ids)),
    [queryClient]
  )

  return {
    fetchStakeHolderProductsByIds,
  }
}
