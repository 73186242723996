import { MetadataEntryRender, useMetadataByBelongsTos } from '@/features/metadatas'
import { useQueryShipmentsByTruIds } from '@/features/shipments'
import { useQueryStockRelationsByParentIds } from '@/features/stockRelations'
import { useQueryTRUsByProduct } from '@/features/trus'
import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  Badge,
  COLOR,
  CollapsableText,
  Loader,
  Modal,
  SIZE,
  Table,
} from '@blockchain-traceability-sl/tailwind-components'
import Big from 'big.js'
import dayjs from 'dayjs'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Product } from '../interfaces'

export interface ModalProductStockDetailsProps {
  show: boolean
  product?: Product
  toggle: () => void
}

export const ModalProductStockDetails = ({
  show,
  product,
  toggle,
}: ModalProductStockDetailsProps) => {
  const { t } = useTranslation('nsModalProductStockDetails')
  const buttonRef = useRef<HTMLButtonElement>(null)

  const { trus, isLoading: isTrusLoading } = useQueryTRUsByProduct(product?._id || '', {
    enabled: !!product?._id,
  })
  const { stockRelations, isLoading: isStockRelationsLoading } = useQueryStockRelationsByParentIds(
    trus.map(({ _id }) => _id)
  )
  const { shipments, isLoading: isShipmentsLoading } = useQueryShipmentsByTruIds(
    trus.map(({ _id }) => _id)
  )
  const { metadatas, isLoading: isMetadataLoading } = useMetadataByBelongsTos(
    trus.map(({ _id }) => _id)
  )

  const getStockBadge = useCallback(
    (quantity?: string) => {
      if (!quantity || new Big(quantity).lte(0)) {
        return (
          <Badge color={COLOR.RED} style={{ minWidth: 110 }}>
            {t('table.outOfStock')}
          </Badge>
        )
      } else {
        return (
          <Badge color={COLOR.GREEN} style={{ minWidth: 70 }}>
            <span>{t('table.inStock')}</span>
          </Badge>
        )
      }
    },
    [t]
  )
  const getQuantity = useCallback(
    (truId: string, quantity?: string): string => {
      if (!quantity || new Big(quantity).lte(0)) {
        return '0'
      }

      return (
        [
          // Filter all tru stock relations
          ...stockRelations.filter(stockRelation => stockRelation.parent === truId),
          // Filter all tru shipments
          ...shipments.filter(shipment => shipment.truId === truId),
        ]
          // Extract the quantity
          .map(({ quantity }) => quantity || 0)
          // Substract the negative stock to its tru quantity
          .reduce((previousValue, quantity) => previousValue.sub(quantity), new Big(quantity))
          // Format to entire string number
          .toString()
      )
    },
    [stockRelations, shipments]
  )

  const matadaEnabled = metadatas.length > 0

  const [isLoadingDebounced, , isLoading] = useDebounceState(
    isTrusLoading || isStockRelationsLoading || isShipmentsLoading || isMetadataLoading
  )

  return (
    <Modal size={SIZE.LARGE} show={show} toggle={toggle} initialFocus={buttonRef} dismissButton>
      <Modal.Title className='mx-2'>
        {t('title', {
          product: product?.name,
          trus: trus.length,
          stock: t('number', {
            value: product?.quantity,
          }),
          measurementUnit: product?.measurementUnit,
        })}
      </Modal.Title>
      <Modal.Body>
        {isLoading || isLoadingDebounced ? (
          <div>
            <Loader center='full' />
          </div>
        ) : (
          <div className='text-left'>
            <Table
              columns={[
                {
                  Header: t('table.tru').toString(),
                  accessor: 'tru',
                },
                {
                  Header: t('table.stock').toString(),
                  accessor: 'stock',
                },
                {
                  Header: t('table.quantity').toString(),
                  accessor: 'quantity',
                },
                {
                  Header: t('table.creationDate').toString(),
                  accessor: 'creationDate',
                },
                ...(matadaEnabled
                  ? [
                      {
                        Header: t('table.metadata').toString(),
                        accessor: 'metadata',
                      },
                    ]
                  : []),
              ]}
              data={trus.map(({ _id, reference, quantity, creationDate }) => {
                const truMetadata = metadatas.find(metadata => metadata.belongsTo === _id)

                return {
                  id: _id,
                  tru: reference,
                  stock: getStockBadge(getQuantity(_id, quantity)),
                  quantity: `${t('number', {
                    value: getQuantity(_id, quantity),
                  })} ${product?.measurementUnit || ''}`,
                  creationDate: dayjs(creationDate).format('DD/MM/YYYY'),
                  ...(matadaEnabled
                    ? {
                        metadata: truMetadata ? (
                          <CollapsableText
                            seeMoreText={t('seeMore')}
                            seeLessText={t('seeLess')}
                            lineCount={2}
                          >
                            {truMetadata.data.map(entry => (
                              <p key={entry.key}>
                                <span className='font-semibold'>{entry.key}</span>:{' '}
                                <MetadataEntryRender {...entry} />
                              </p>
                            ))}
                          </CollapsableText>
                        ) : (
                          '-'
                        ),
                      }
                    : {}),
                }
              })}
              pagination={false}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}
