import { isFunction } from '@/utils/is-function'
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  PointerActivationConstraint,
  TouchSensor,
  UniqueIdentifier,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { ReactNode, useState } from 'react'
interface CalendarDnDContextProps {
  children?: ((props: { isDragging: boolean }) => ReactNode) | ReactNode
  onDrag?: (itemId: UniqueIdentifier, overId: UniqueIdentifier) => void
}

const activationConstraint: PointerActivationConstraint = {
  /**
   * Activate dnd after move the TaskCard 15px
   * @see https://docs.dndkit.com/api-documentation/sensors/pointer#distance
   */
  distance: 15,
}

export const CalendarDnDContext = ({ children, onDrag }: CalendarDnDContextProps) => {
  const [isDragging, setIsDragging] = useState(false)

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint,
    }),
    useSensor(KeyboardSensor),
    useSensor(TouchSensor, {
      activationConstraint,
    })
  )

  const handleDragStart = () => setIsDragging(true)

  const handleDragEnd = (event: DragEndEvent) => {
    if (event.over && onDrag) onDrag(event.active.id, event.over.id)
    setIsDragging(false)
  }

  const handleDragCancel = () => setIsDragging(false)

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      {isFunction(children) ? children({ isDragging }) : children}
    </DndContext>
  )
}
