import { useDebounceState } from '@/hooks/use-debounce-state'
import { useFormikContext } from 'formik'
import { useEffect, useRef } from 'react'
import { MemoFormStatus } from '../interfaces'
import { stateIsExpired } from '../utils'
import { useIndexedDataBaseValue, useSaveStateInDB } from '..'

export interface MemoFormMemorizerProps {
  storeKey: string
  /***
   * Enable or disable the memorization until the form dynamic data is loaded, to prevent unexpected behaviors
   */
  enabled: boolean
  entity: string
}

export const MemoFormMemorizer = <Values extends unknown>({
  storeKey,
  enabled,
  entity,
}: MemoFormMemorizerProps) => {
  const { saveStateIDB } = useSaveStateInDB<Values>(entity, storeKey)
  const { valueIDB } = useIndexedDataBaseValue<Values>(entity, storeKey)
  const { values, status, setStatus, setValues } = useFormikContext<Values>()
  /**
   * Keep previous form state from the beginning to override it on initialize
   */
  const previousFormStateRef = useRef(valueIDB)

  /**
   * Debounce form values to improve performance
   */
  const [formValuesDebounced] = useDebounceState<Values>(values)

  /**
   * Set saved form state on initialize if exist and is not expired
   */
  useEffect(() => {
    if (!enabled) return
    const previousFormState = previousFormStateRef.current

    if (previousFormState) {
      if (!stateIsExpired(previousFormState.timestamp)) {
        setValues(previousFormState.content)
      }
    }

    setStatus(MemoFormStatus.LOADED)
  }, [setValues, setStatus, storeKey, enabled])

  /**
   * Update, or create if not exist, the stored form state on change debounced formik values
   */

  useEffect(() => {
    if (!enabled || status !== MemoFormStatus.LOADED) return
    saveStateIDB(formValuesDebounced)
  }, [enabled, formValuesDebounced, saveStateIDB, status, storeKey])

  return <></>
}
