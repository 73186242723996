import { ReactNode, useMemo } from 'react'
import { getIdToken, signInWithRedirect, signOut } from 'firebase/auth'
import { auth } from '@/app/firebase'
import { AuthenticationContext } from '../context'
import { IAuthenticationContext, SSOAuthType } from '../interfaces'
import { QUERY_MY_PROFILE_KEY, useLogin } from '../hooks'
import { useQueryClient } from 'react-query'
import { useAuthState, useUserDataFirstLoad } from './hooks'
import { getExternalAuthenticationProvider } from '../utils'

export const AuthenticationProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient()

  const {
    isLoading: isAuthStateLoading,
    authUser,
    isAuthenticated: isAuthenticatedFromAuthState,
  } = useAuthState()
  const {
    isUserDataLoadedSuccessfully,
    isProfileLoaded,
    isLoading: isUserDataFirstLoading,
    isActivated: isActivatedFromUserLoad,
  } = useUserDataFirstLoad({
    enabled: isAuthenticatedFromAuthState,
  })
  const { login } = useLogin()

  const isAuthenticating = (isAuthenticatedFromAuthState && !isProfileLoaded) || isAuthStateLoading
  const isAuthenticated = isAuthenticatedFromAuthState && isProfileLoaded
  const isActivated = isAuthenticated && isUserDataLoadedSuccessfully && isActivatedFromUserLoad
  const isActivationLoading = isAuthenticating || isUserDataFirstLoading

  const value = useMemo<IAuthenticationContext>(
    () => ({
      isAuthenticating,
      isAuthenticated,
      isActivationLoading,
      isActivated,
      loginWithEmailAndPassword(
        email: string,
        password: string,
        options?: { onSuccess?: () => void }
      ) {
        return login({ email, password }, options)
      },
      loginWithSSO(authType: SSOAuthType, providerId: string, parameters: { loginHint: string }) {
        const provider = getExternalAuthenticationProvider(authType, providerId, parameters)
        signInWithRedirect(auth, provider)
      },
      async logout() {
        await signOut(auth)
        queryClient.clear()
      },
      async refreshToken() {
        if (authUser) {
          await getIdToken(authUser, true)
          queryClient.invalidateQueries(QUERY_MY_PROFILE_KEY)
        }
      },
    }),
    [
      authUser,
      isActivated,
      isActivationLoading,
      isAuthenticated,
      isAuthenticating,
      login,
      queryClient,
    ]
  )
  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
}
