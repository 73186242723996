import { Fragment, ReactNode, useRef, useState } from 'react'
import { Menu, Portal, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { usePopper } from 'react-popper'

export const Dropdown = ({ children, button }: { children: ReactNode; button: ReactNode }) => {
  const popperElementRef = useRef(null)
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom',
  })
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div ref={setTargetElement}>{button}</div>

      <Portal>
        <div ref={popperElementRef} style={styles.popper} {...attributes.popper}>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
            beforeEnter={() => setPopperElement(popperElementRef.current)}
            afterLeave={() => setPopperElement(null)}
          >
            <Menu.Items className='origin-top-right absolute z-20 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
              <div className='py-1'>{children}</div>
            </Menu.Items>
          </Transition>
        </div>
      </Portal>
    </Menu>
  )
}

Dropdown.IconButton = ({ children }: { children: ReactNode }) => {
  return (
    <Menu.Button className='rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500'>
      {children}
    </Menu.Button>
  )
}

Dropdown.Item = ({
  children,
  onClick,
  disabled,
}: {
  children: ReactNode
  onClick?: () => void
  disabled?: boolean
}) => {
  return (
    <Menu.Item as='div' onClick={onClick} disabled={disabled}>
      {({ active }) => (
        <p
          className={classNames('block px-4 py-2 text-sm', {
            'cursor-not-allowed opacity-50': disabled,
            'bg-gray-100 text-gray-900': active,
            'text-gray-700': !active,
          })}
        >
          {children}
        </p>
      )}
    </Menu.Item>
  )
}
