import {
  TemplateDeprecatedEntryType,
  TemplateEntry,
  TemplateEntryReferenceValue,
  TemplateEntryType,
  patchDeprecationTypes,
} from '@/features/templates'
import { isValidHttpUrl } from '@/utils/is-valid-http-url'
import { SelectorOption } from '@blockchain-traceability-sl/tailwind-components'
import dayjs from 'dayjs'
import * as Yup from 'yup'
import { Metadata, MetadataEntry, MetadataOption } from './interfaces'

/**
 * Parse the raw metadata array of objects to array of valid metadata
 *
 * @param metadata - Raw metadata to parse
 * @returns metadata parsed
 */
export const parseMetadata = (metadata: {
  [key: string]:
    | string
    | string[]
    | {
        value: string
        values: string[] | TemplateEntryReferenceValue[]
        type: TemplateEntryType | TemplateDeprecatedEntryType
        options?: string[]
      }
}): {
  key: string
  value: string | string[] | TemplateEntryReferenceValue[]
  type: TemplateEntryType
  options: string[]
}[] => {
  return Object.entries(metadata).map(([metadataKey, metadataValue]) =>
    typeof metadataValue === 'string' || Array.isArray(metadataValue)
      ? {
          key: metadataKey,
          value: metadataValue,
          type: TemplateEntryType.SHORT_TEXT,
          options: Array.isArray(metadataValue) ? (metadataValue as string[]) : [],
        }
      : {
          key: metadataKey,
          value: metadataValue.value || metadataValue.values,
          type: patchDeprecationTypes(metadataValue.type),
          options:
            patchDeprecationTypes(metadataValue.type) === TemplateEntryType.SINGLE_CHOICE
              ? metadataValue.options !== undefined
                ? metadataValue.options.includes(metadataValue.value as string)
                  ? metadataValue.options
                  : [metadataValue.value as string, ...metadataValue.options]
                : [metadataValue.value as string]
              : patchDeprecationTypes(metadataValue.type) === TemplateEntryType.MULTIPLE_CHOICE
              ? metadataValue.options !== undefined
                ? metadataValue.options.some(option =>
                    (metadataValue.values as string[]).includes(option)
                  )
                  ? metadataValue.options
                  : [...(metadataValue.values as string[]), ...metadataValue.options]
                : [...(metadataValue.values as string[])]
              : [],
        }
  )
}

export const MetadataValidationSchema = Yup.array().of(
  Yup.object().shape({
    key: Yup.string(),
    type: Yup.string(),
    options: Yup.mixed(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: Yup.string().test('value', function (value, context: any) {
      if (!context.from[context.from.length - 1].value.showMetadata) return true

      switch (this.parent.type) {
        case TemplateEntryType.SHORT_TEXT:
        case TemplateEntryType.LONG_TEXT:
        case TemplateEntryType.NUMBER:
        case TemplateEntryType.DATE:
        case TemplateEntryType.TIME:
        case TemplateEntryType.SINGLE_CHOICE:
        case TemplateEntryType.DOCUMENT:
          return (
            (this.parent.mandatory && value && value[0]) ||
            !this.parent.mandatory ||
            this.createError({
              message: this.parent.key,
            })
          )
        case TemplateEntryType.BOOLEAN:
          return true
        case TemplateEntryType.LINK: {
          if (value && value[0]) {
            return (
              isValidHttpUrl(value) ||
              this.createError({
                message: this.parent.key,
              })
            )
          }
          if (this.parent.mandatory) {
            return (
              (value && value[0]) ||
              this.createError({
                message: this.parent.key,
              })
            )
          }

          return true
        }
        case TemplateEntryType.REFERENCE:
        case TemplateEntryType.MULTIPLE_CHOICE:
          return true
        default:
          return false
      }
    }),
    values: Yup.array()
      .of(Yup.mixed())
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .test('values', function (values, context: any) {
        if (!context.from[context.from.length - 1].value.showMetadata) return true

        switch (this.parent.type) {
          case TemplateEntryType.MULTIPLE_CHOICE:
            return (
              (this.parent.mandatory && values && values[0]) ||
              !this.parent.mandatory ||
              this.createError({
                message: this.parent.key,
              })
            )
          case TemplateEntryType.REFERENCE:
            return (
              (this.parent.mandatory && values && values[0] && values[0].id && values[0].name) ||
              !this.parent.mandatory ||
              this.createError({
                message: this.parent.key,
              })
            )
          default:
            return true
        }
      }),
    mandatory: Yup.boolean(),
  })
)

export const templateEntryToMetadataOption = (entry: TemplateEntry): MetadataOption => ({
  key: entry.name,
  type: patchDeprecationTypes(entry.type),
  options:
    patchDeprecationTypes(entry.type) === TemplateEntryType.SINGLE_CHOICE ||
    patchDeprecationTypes(entry.type) === TemplateEntryType.MULTIPLE_CHOICE
      ? (entry.values as string[]).map(valueOption => ({
          label: valueOption,
          value: valueOption,
        }))
      : [],
  value: '',
  values:
    patchDeprecationTypes(entry.type) === TemplateEntryType.REFERENCE
      ? (entry.values as TemplateEntryReferenceValue[])
      : [],
  mandatory: entry.mandatory,
})

export const metadataToMetadataOptions = (metadata: Metadata[]): MetadataOption[] => {
  return metadata.length === 0 || !metadata[0] || !metadata[0].data
    ? []
    : metadata[0].data.map(
        (meta): MetadataOption => ({
          ...meta,
          type: patchDeprecationTypes(meta.type) || TemplateEntryType.SHORT_TEXT,
          value:
            patchDeprecationTypes(meta.type) === TemplateEntryType.DATE &&
            meta.value &&
            meta.value !== ''
              ? dayjs(String(meta.value)).format('YYYY-MM-DD')
              : meta.value ?? '',
          options:
            meta.options?.map((option): SelectorOption => ({ label: option, value: option })) || [],
        })
      )
}

export const isMetadataEntryEmpty = ({
  type,
  value,
  values,
}: Pick<MetadataEntry, 'type' | 'value' | 'values'>): boolean => {
  switch (type) {
    case TemplateEntryType.MULTIPLE_CHOICE:
      return (
        values.length === 0 ||
        values.map(item => (item as string).trim()).filter(item => !!item).length === 0
      )
    case TemplateEntryType.REFERENCE:
      return (values as TemplateEntryReferenceValue[]).length === 0
    default:
      return value === '' || !value
  }
}
