import {
  DocumentIcon,
  DocumentTextIcon,
  FilmIcon,
  FolderIcon,
  MusicNoteIcon,
  PhotographIcon,
} from '@heroicons/react/outline'
import { useMemo } from 'react'
import { IconComponentType } from '@blockchain-traceability-sl/tailwind-components/dist/types'

export interface DocumentListItemIconProps {
  mimeType: string | null
}

/**
 * Component to print an icon depending of the document mimeType
 */
export const DocumentListItemIcon = ({ mimeType }: DocumentListItemIconProps) => {
  const IconComponent: IconComponentType = useMemo(() => {
    if (!mimeType) return FolderIcon

    switch (mimeType) {
      case 'application/pdf':
        return DocumentIcon

      case 'text/csv':
      case 'text/tsv':
      case 'application/vnd.ms-excel':
        return DocumentIcon
    }

    if (mimeType.startsWith('image/')) return PhotographIcon
    else if (mimeType.startsWith('audio/')) return MusicNoteIcon
    else if (mimeType.startsWith('video/')) return FilmIcon
    else if (mimeType.startsWith('text/')) return DocumentTextIcon

    return DocumentIcon
  }, [mimeType])

  if (!mimeType) return <IconComponent className='h-6 w-6 mr-2 fill-current' aria-hidden='true' />
  return <IconComponent className='h-6 w-6 mr-2' aria-hidden='true' />
}
