import { useIssuesByIds } from '@/features/issues'
import { TemplateEntryType, useTemplateByIds } from '@/features/templates'
import { useValidationsRequestByEntityIds } from '@/features/validationRequests'
import dayjs, { Dayjs } from 'dayjs'
import { useMemo, useState } from 'react'
import { useMyTasks } from '../../hooks'
import { ReadableTask, Task, TaskFilters } from '../../interfaces'

export const useWeeklyNavigation = () => {
  const [currentDate, setCurrentDate] = useState<Dayjs>(() => dayjs())

  const weekData = useMemo(() => {
    const startDate = currentDate.startOf('week')
    const endDate = startDate.endOf('week')

    return {
      startDate,
      endDate,
      normalizedWeekNumber: currentDate.isoWeek(),
      displayMonthAndYear: startDate.format('MMMM YYYY'),
    }
  }, [currentDate])

  // eslint-disable-next-line unicorn/prevent-abbreviations
  const prevWeek = () => setCurrentDate(previous => previous.week(previous.week() - 1))
  const nextWeek = () => setCurrentDate(previous => previous.week(previous.week() + 1))
  const goToDate = (date: Date | string | number) => {
    setCurrentDate(dayjs(date))
  }
  const goToNow = () => setCurrentDate(dayjs())

  return {
    ...weekData,
    prevWeek,
    nextWeek,
    goToDate,
    goToNow,
  }
}

export const useGroupTasksByDay = <T extends Task>(targetDate: dayjs.Dayjs, tasks: T[]) => {
  return useMemo(
    () =>
      tasks.reduce<{ [key: number]: T[] }>((accumulator, task) => {
        const dueDate = dayjs(task.dueDate)

        if (dueDate.isSame(targetDate, 'week')) {
          const week = dueDate.weekday()
          accumulator[week] = accumulator[week] || []

          accumulator[week].push(task)
        }

        return accumulator
      }, {}),
    [tasks, targetDate]
  )
}

export const useTasksInCalendar = ({
  filters,
  weekRange: { startDate, endDate },
}: {
  filters?: TaskFilters
  weekRange: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs }
}) => {
  const weekFilters =
    filters?.startDate || filters?.endDate
      ? undefined
      : {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        }

  const {
    tasks,
    isLoading: isTaskLoading,
    isFetched: isMyTasksFetched,
  } = useMyTasks(
    {
      ...(filters as Record<string, string | string[]>),
      ...weekFilters,
    },
    {
      keepPreviousData: true,
    }
  )

  const templateIds = useMemo(() => {
    if (tasks.length === 0) return []
    return Array.from(new Set(tasks.map(task => task.formId)))
  }, [tasks])

  const {
    templates,
    isLoading: isTemplatesLoading,
    isFetched: isTemplatesFetched,
  } = useTemplateByIds(templateIds, 'form', {
    enabled: isMyTasksFetched,
    keepPreviousData: true,
  })

  const { validationRequests, isLoading: isValidationRequestsLoading } =
    useValidationsRequestByEntityIds(
      tasks.map(task => task._id),
      {
        enabled: isMyTasksFetched,
      }
    )

  const issuesIds = useMemo(
    () =>
      tasks.flatMap(({ entries }) =>
        entries
          // Filter entries type issue that are active
          .filter(
            entry => entry.type === TemplateEntryType.ISSUE_REFERENCE && entry.values.length >= 1
          )
          // Extract issues ids
          .flatMap(({ values }) => values as string[])
      ),
    [tasks]
  )

  const { issues, isLoading: isIssuesLoading } = useIssuesByIds(issuesIds, {
    enabled: issuesIds.length !== 0,
  })

  const fulfilledTasks = useMemo<ReadableTask[]>(() => {
    return tasks.map(task => {
      const taskValidationRequests = validationRequests.filter(
        validationRequest => validationRequest.entity.id === task._id
      )
      const lastValidationRequest = taskValidationRequests.sort(
        (requestA, requestB) =>
          new Date(requestA.createdAt).getTime() - new Date(requestB.createdAt).getTime()
      )[0]
      return {
        ...task,
        template: templates?.find(template => template._id === task.formId),
        issues: issues.filter(issue => issue.associatedTaskId === task._id),
        validationRequests: taskValidationRequests,
        lastValidationRequest,
      }
    })
  }, [tasks, templates, issues, validationRequests])

  const tasksGroupByDay = useGroupTasksByDay(startDate, fulfilledTasks)

  return {
    isLoading:
      isTaskLoading || isTemplatesLoading || isValidationRequestsLoading || isIssuesLoading,
    isFetched: isMyTasksFetched || isTemplatesFetched,
    tasks: fulfilledTasks,
    tasksGroupByDay,
  }
}
