import { useDebounceState } from '@/hooks/use-debounce-state'
import { FormikState, FormikValues, useFormik } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { UseMemoFormInstance, UseMemoFormProps } from './interfaces'
import { generateStoreKey, stateIsExpired } from './utils'
import { getStateFromDB, removeState, removeStateFromDB, saveStateInDB } from './service'
import { useMutation, useQuery } from 'react-query'

const INDEXED_DB_KEY = 'memo-form'
export const useIndexedDataBaseValue = <T = unknown>(store: string, storeKey: string) => {
  const { data, ...rest } = useQuery([INDEXED_DB_KEY, store, storeKey], async () =>
    getStateFromDB(store, storeKey)
  )

  const value = useMemo(() => {
    return data ? JSON.parse(data) : (null as { timestamp: number; content: T } | null)
  }, [data])

  return {
    valueIDB: value,
    ...rest,
  }
}

export const useSaveStateInDB = <T = unknown>(store: string, storeKey: string) => {
  const { mutate, ...rest } = useMutation((values: T) => saveStateInDB<T>(store, storeKey, values))

  return {
    saveStateIDB: mutate,
    ...rest,
  }
}

export const useRemoveState = (store: string, storeKey: string) => {
  const { mutate, ...rest } = useMutation(() => removeStateFromDB(store, storeKey))

  return {
    removeStateIDB: mutate,
    ...rest,
  }
}

export const useMemoForm = <Values extends FormikValues = FormikValues>({
  entity,
  entityId,
  action,
  onSubmit,
  ...formikProps
}: UseMemoFormProps<Values>): UseMemoFormInstance<Values> => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  /**
   * Dinamic store key based on entity and action to be unique
   */
  const storeKey = useMemo(
    () => generateStoreKey({ entity, entityId, action }),
    [action, entity, entityId]
  )
  const { saveStateIDB } = useSaveStateInDB<Values>(entity, storeKey)
  const { removeStateIDB } = useRemoveState(entity, storeKey)
  const { valueIDB } = useIndexedDataBaseValue<Values>(entity, storeKey)
  /**
   * Initialize Formik hook
   */
  const { resetForm, setValues, ...formik } = useFormik({
    ...formikProps,
    onSubmit: (values, formikHelpers) => {
      onSubmit(values, formikHelpers)
      removeState(storeKey)
      removeStateIDB()
    },
  })

  /**
   * Debounce form values to improve performance
   */
  const [formValuesDebounced] = useDebounceState(formik.values)

  /**
   * Reset memo form handler
   */
  const resetMemoForm = useCallback(
    (nextState?: Partial<FormikState<Values>>) => {
      removeStateIDB()
      removeState(storeKey)

      resetForm(nextState)
    },
    [removeStateIDB, resetForm, storeKey]
  )

  /**
   * Set saved form state on initialize if exist and is not expired
   */
  useEffect(() => {
    const previousFormState = valueIDB
    if (previousFormState) {
      if (!stateIsExpired(previousFormState.timestamp)) {
        setValues(previousFormState.content)
      }
    }

    setIsLoaded(true)
  }, [setValues, storeKey, valueIDB])

  /**
   * Update storage form state on change debounced formik values
   */
  useEffect(() => {
    const saveState = async (store: string, values: Values) => {
      await saveStateInDB<Values>(store, storeKey, values)
    }

    saveState(entity, formValuesDebounced)
  }, [entity, formValuesDebounced, saveStateIDB, storeKey])

  return {
    ...formik,
    setValues,
    resetForm: resetMemoForm,
    handleReset: resetMemoForm,
    isLoaded,
  }
}
