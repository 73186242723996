import { useEffect, useMemo, useState } from 'react'

export default function useOnScreen(): [
  boolean,
  React.Dispatch<React.SetStateAction<Element | null>>
] {
  const [isIntersecting, setIntersecting] = useState(false)
  const [elementObserved, setElementObserved] = useState<Element | null>(null)

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)),
    []
  )

  useEffect(() => {
    if (elementObserved) observer.observe(elementObserved)
    return () => {
      observer.disconnect()
    }
  }, [elementObserved, observer])

  return [isIntersecting, setElementObserved]
}
