import { TemplateEntryType } from '@/features/templates'
import { SwitchField } from '@blockchain-traceability-sl/tailwind-components'
import { useTranslation } from 'react-i18next'
import { EntryFieldProps } from '../interfaces'
import { generateEntryFieldName, useEntryFieldAttributes } from '../utils'
import { EntryLabel } from '../EntryLabel'
import { useField, useFormikContext } from 'formik'
import { EntryCreateInput } from '../../interfaces'
import { ConditionalBlockMapValue, getDefaultEntryValue } from '../../utils'
import { EntryField } from '../EntryField'

export const ConditionalEntryField = (props: EntryFieldProps) => {
  const { index, prefixName, disabled, previousChildrenContainer } = props
  const { t } = useTranslation('nsEntries')

  const { entryFieldName, valueFieldName, valueFieldId, enabledFieldId, enabledFieldName } =
    useEntryFieldAttributes(index, prefixName)

  const { setFieldValue } = useFormikContext()
  const [entryInputField] = useField<EntryCreateInput>(entryFieldName)
  const [, , valueInputFieldHelpers] = useField<boolean>(valueFieldName)
  const [, , enabledInputFieldHelpers] = useField<boolean>(enabledFieldName)

  const entry = entryInputField.value

  const [{ value: childEntries }] = useField<ConditionalBlockMapValue | undefined>(
    `${prefixName ? `${prefixName}.` : ''}blocks.conditionals.${entry.id}`
  )

  if (!childEntries) {
    console.warn('Blocks is missing, use prepareEntryBlocksMaps in Form initial values')
  }

  if (entry.type !== TemplateEntryType.CONDITIONAL) return null

  const ifChildEntries = childEntries?.if ?? []
  const elseChildEntries = childEntries?.else ?? []

  const showChildrenContainer =
    (entry.value && ifChildEntries.length > 0) || (!entry.value && elseChildEntries.length > 0)

  const isEntryEnabled = entry.enabled ?? true

  return (
    <>
      <SwitchField
        id={valueFieldId}
        name={valueFieldName}
        reverse
        label={
          <EntryLabel
            naCheckboxId={enabledFieldId}
            naCheckboxName={enabledFieldName}
            naUnchecked={isEntryEnabled}
            onNaChange={enabled => {
              enabledInputFieldHelpers.setValue(enabled)
              enabledInputFieldHelpers.setTouched(true)
            }}
            naCheckboxLabel={t('form.na')}
            lightTextOnNAChecked
            lightTextOnDisabled
            disabled={disabled}
          >
            {entry.name}
          </EntryLabel>
        }
        onChange={checked => {
          valueInputFieldHelpers.setValue(checked)
          valueInputFieldHelpers.setTouched(true)
          ;(checked ? ifChildEntries : elseChildEntries).forEach(child => {
            const defaultValue = getDefaultEntryValue(child.type)

            if (!defaultValue) return
            const [key] = Object.keys(defaultValue)
            const [value] = Object.values(defaultValue)

            setFieldValue(`${generateEntryFieldName(child.index, prefixName)}.${key}`, value)
          })
        }}
        checked={entry.value}
        disabled={!isEntryEnabled || disabled}
      />
      {previousChildrenContainer}
      {showChildrenContainer && isEntryEnabled && (
        <div className='grid grid-cols-1 gap-6 ml-8'>
          {entry.value
            ? ifChildEntries.map(child => {
                return (
                  <div key={`${entryFieldName}.if.${child.id}`}>
                    <EntryField {...props} index={child.index} />
                  </div>
                )
              })
            : elseChildEntries.map(child => {
                return (
                  <div key={`${entryFieldName}.else.${child.id}`}>
                    <EntryField {...props} index={child.index} />
                  </div>
                )
              })}
        </div>
      )}
    </>
  )
}
