import dayjs from 'dayjs'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { i18nextPlugin as translationCheckPlugin } from 'translation-check'

// Formater function
import { formater } from './formater'

// Import locales http://www.lingoes.net/en/translator/langcode.htm
import ar from './locales/ar.json'
import enGB from './locales/en-GB.json'
import esES from './locales/es-ES.json'

import config from '@/config'
import '@/setup-dates'
import 'dayjs/locale/ar'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/es'

export enum SupportedLanguage {
  ES = 'es',
  EN = 'en',
  AR = 'ar',
}

export const SupportedLanguages: string[] = Object.values(SupportedLanguage)

export const toSupportedLanguage = (lang: string) => {
  const langCode = lang.split('-')[0]

  return SupportedLanguages.includes(langCode) ? langCode : SupportedLanguage.EN
}

// Events
// Must be before initialization to work
i18n.on('languageChanged', lng => {
  document.getElementsByTagName('html')[0].setAttribute('lang', lng)

  if (lng.startsWith(SupportedLanguage.ES)) {
    dayjs.locale('es')
  } else {
    dayjs.locale(lng.toLowerCase())
  }
})

i18n.use(LanguageDetector).use(initReactI18next)

if (config.ENABLE_TRANSLATIONS_MANAGEMENT_I18N_PLUGIN) {
  i18n.use(translationCheckPlugin)
}

i18n.use(translationCheckPlugin)

i18n.init({
  resources: {
    'en-GB': enGB,
    es: esES,
    esEs: esES,
    ar: ar,
  },
  fallbackLng: 'en-GB',
  fallbackNS: 'nsCommon',
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    format: formater,
  },
})

export default i18n
