import { TemplateEntryType } from '@/features/templates'
import { useToggle } from '@/hooks/use-toggle'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { EntryField } from '../EntryField'
import { EntryFieldProps } from '../interfaces'
import { generateEntryFieldName, useEntryFieldAttributes } from '../utils'
import { useField, useFormikContext } from 'formik'
import { EntryCreateInput } from '../../interfaces'
import { EntryNaCheck } from '../EntryNaCheck'
import { SectionBlockMapValue } from '../../utils'

export const SectionPaginatedEntryField = (
  props: EntryFieldProps & {
    sectionEntries?: { id: string; index: number }[]
    sectionChunkIndex?: number
  }
) => {
  const { index, prefixName, sectionEntries, sectionChunkIndex } = props

  const { t } = useTranslation('nsEntries')

  const { entryFieldName, enabledFieldId, enabledFieldName } = useEntryFieldAttributes(
    index,
    prefixName
  )

  const { setFieldValue } = useFormikContext()
  const [entryInputField] = useField<EntryCreateInput>(entryFieldName)
  const [, , enabledInputFieldHelpers] = useField<boolean>(enabledFieldName)
  const entry = entryInputField.value

  const [{ value: childEntriesWithIndex }] = useField<SectionBlockMapValue | undefined>(
    `${prefixName ? `${prefixName}.` : ''}blocks.sections.${entry.id}`
  )

  if (!childEntriesWithIndex) {
    console.warn('Blocks is missing, use prepareEntryBlocksMaps in Form initial values')
  }

  if (!sectionEntries || sectionChunkIndex === undefined) {
    console.warn('Section pagination config is missing')
  }

  const isEntryEnabled = entry.enabled ?? true

  const [collapsed, toggleCollapsed] = useToggle(!isEntryEnabled)

  if (entry.type !== TemplateEntryType.SECTION) return null

  const showNaCheck = childEntriesWithIndex?.every(entry => !entry.mandatory)

  return (
    <div>
      {(sectionChunkIndex === 0 || !isEntryEnabled) && (
        <div className='flex justify-between items-center'>
          <div
            className={classNames('flex items flex-nowrap pr-20', {
              'text-gray-300 font-bold cursor-not-allowed': !isEntryEnabled,
              'cursor-pointer': isEntryEnabled,
            })}
            onClick={isEntryEnabled ? toggleCollapsed : undefined}
          >
            <span className='font-bold text-base leading-6 flex-grow' style={{ hyphens: 'auto' }}>
              {entry.name}
            </span>

            {!collapsed ? (
              <ChevronDownIcon className='h-6 w-6 ml-1 flex-shrink-0' aria-hidden='true' />
            ) : (
              <ChevronRightIcon className='h-6 w-6 ml-1 flex-shrink-0' aria-hidden='true' />
            )}
          </div>
          {showNaCheck && (
            <EntryNaCheck
              unchecked={isEntryEnabled}
              onChange={enabled => {
                enabledInputFieldHelpers.setValue(enabled)
                enabledInputFieldHelpers.setTouched(true)
                childEntriesWithIndex?.forEach(child => {
                  if (child.type === TemplateEntryType.STATEMENT) return
                  const fieldName = generateEntryFieldName(child.index, prefixName)
                  setFieldValue(`${fieldName}.enabled`, enabled)
                })
                toggleCollapsed(!enabled)
              }}
              checkboxLabel={t('form.section.na')}
              id={enabledFieldId}
              name={enabledFieldName}
            />
          )}
        </div>
      )}

      {collapsed ? null : (
        <div className='grid grid-cols-1 gap-6 mt-4 bg-gray-100 p-6 rounded-md'>
          {sectionEntries?.map(child => {
            return (
              <div key={`${entryFieldName}.section.${child.id}`}>
                <EntryField {...props} index={child.index} />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
