import { ReactNode, useMemo } from 'react'
import {
  TaskFilters,
  TaskTable,
  TaskTableMode,
  usePaginatedMyDepartmentTasks,
  useTaskManager,
} from '@/features/tasks'
import { useValidationsRequestByEntityIds } from '@/features/validationRequests'
import { Loader } from '@blockchain-traceability-sl/tailwind-components'
import { useIssuesByIds, useIssuesCount } from '@/features/issues'
import { usePagination } from '@/hooks/use-pagination'
import {
  useAssociatedStakeholdersByTasks,
  useFormatTaskToTab,
  useIssueStatusGroups,
} from '../hooks'
import { TemplateEntryType } from '@/features/templates'
import { DEFAULT_PAGE_SIZE } from '@/app/constants'
import { PAGE_SIZE_KEY } from '../constants'
import { useLocalStorage } from '@/hooks/use-local-storage'

interface TasksWithIssuesTabProps {
  taskFilters: TaskFilters
  showResume?: boolean
  leftMenu?: ReactNode
}

export const TasksWithIssuesTab = ({
  taskFilters,
  showResume,
  leftMenu,
}: TasksWithIssuesTabProps) => {
  const [pageSize, setPageSize] = useLocalStorage(PAGE_SIZE_KEY, DEFAULT_PAGE_SIZE)
  const { onPageChange, pageIndex, pageOffset } = usePagination({ pageSize })
  const { issueStatusGroups } = useIssueStatusGroups()
  const {
    isLoading: isTasksLoading,
    isFetching: isTasksFetching,
    isFetched: isTasksFetched,
    totalCount,
    tasks,
  } = usePaginatedMyDepartmentTasks(taskFilters, { offset: pageOffset, limit: pageSize })

  const taskIds = useMemo(() => tasks.map(task => task._id), [tasks])
  const { validationRequests } = useValidationsRequestByEntityIds(taskIds, {
    enabled: isTasksFetched && taskIds.length > 0,
  })

  const { associatedStakeholders } = useAssociatedStakeholdersByTasks(taskIds, {
    enabled: isTasksFetched && taskIds.length > 0,
  })

  const issuesIds = useMemo(
    () =>
      tasks.flatMap(({ entries }) =>
        entries
          // Filter entries type issue that are active
          .filter(
            entry => entry.type === TemplateEntryType.ISSUE_REFERENCE && entry.values.length >= 1
          )
          // Extract issues ids
          .flatMap(({ values }) => values as string[])
      ),
    [tasks]
  )

  const { issues } = useIssuesByIds(issuesIds, {
    enabled: tasks.length !== 0,
  })

  const { count: openIssuesCount } = useIssuesCount(
    {
      status: issueStatusGroups.NEW,
      formId: taskFilters.formId,
    },
    {
      enabled: !!showResume,
    }
  )

  const { count: acknowledgedIssuesCount } = useIssuesCount(
    {
      status: issueStatusGroups.IN_PROGRESS,
      formId: taskFilters.formId,
    },
    {
      enabled: !!showResume,
    }
  )

  const { count: resolvedIssuesCount } = useIssuesCount(
    {
      status: issueStatusGroups.CLOSED,
      formId: taskFilters.formId,
    },
    {
      enabled: !!showResume,
    }
  )

  const { formatTaskToTab } = useFormatTaskToTab({
    validationRequests,
    issues,
    associatedStakeholders,
  })

  const tasksToTable = useMemo(() => tasks.map(formatTaskToTab), [tasks, formatTaskToTab])

  const { manageTaskIssues } = useTaskManager()

  const isTableLoading = isTasksFetching && !isTasksFetched

  return (
    <>
      {isTasksLoading ? (
        <div className='h-80'>
          <Loader center='full' />
        </div>
      ) : (
        <TaskTable
          mode={TaskTableMode.ISSUE_HANDLE}
          isLoading={isTableLoading}
          tasks={tasksToTable}
          onRowClick={manageTaskIssues}
          onPageChange={page => {
            onPageChange(page)
            setPageSize(page.pageSize)
          }}
          pagination={{ pageIndex, pageSize }}
          totalCount={totalCount}
          leftMenu={leftMenu}
          issuesSummary={
            showResume
              ? {
                  open: openIssuesCount,
                  acknowledged: acknowledgedIssuesCount,
                  resolved: resolvedIssuesCount,
                }
              : undefined
          }
        />
      )}
    </>
  )
}

export const TasksWithActiveIssuesTab = ({ taskFilters, ...props }: TasksWithIssuesTabProps) => {
  const { issueStatusGroups } = useIssueStatusGroups()

  return (
    <TasksWithIssuesTab
      taskFilters={{
        ...taskFilters,
        issueStatus: issueStatusGroups.OPEN,
      }}
      showResume
      {...props}
    />
  )
}

export const TasksWithResolvedIssuesTab = ({ taskFilters, ...props }: TasksWithIssuesTabProps) => {
  const { issueStatusGroups } = useIssueStatusGroups()

  return (
    <TasksWithIssuesTab
      taskFilters={{
        ...taskFilters,
        issueStatus: issueStatusGroups.CLOSED,
      }}
      {...props}
    />
  )
}
