import { TemplateEntry } from '@/features/templates'
import { EntryFieldSetItem, EntryFieldSetProps } from './interfaces'
import { fromTemplateEntries } from './strategies'
import { useMemo } from 'react'
import { PaginatedEntryFieldSet } from './PaginatedEntryFieldSet'
import { NormalEntryFieldSet } from './NormalEntryFieldSet'
import { useShouldPaginate } from './hooks'

/**
 *
 * This component manage the rendering of the entries in the form.
 * Including pagination or normal listing
 * If pagination is enabled, it will render the PaginatedEntryFieldSet
 * only if the number of entries is greater than the DEFAULT_ENTRIES_FIELDSET_PAGE_SIZE
 *
 */
export const EntryFieldSet = ({
  items,
  paginationEnabled,
  onPageChange,
  ...entryFieldProps
}: EntryFieldSetProps<EntryFieldSetItem>) => {
  const shouldPaginate = useShouldPaginate({
    items,
    enabled: paginationEnabled,
  })

  if (shouldPaginate) {
    return <PaginatedEntryFieldSet items={items} {...entryFieldProps} onPageChange={onPageChange} />
  }
  return <NormalEntryFieldSet items={items} {...entryFieldProps} />
}

export const EntryFieldSetFromTemplateEntries = ({
  items: originalItems,
  ...props
}: EntryFieldSetProps<TemplateEntry>) => {
  const items = useMemo(() => fromTemplateEntries(originalItems), [originalItems])
  return <EntryFieldSet {...props} items={items} />
}
