import { BulkError, ErrorCodes } from '@blockchain-traceability-sl/custom-error-codes'
import { downloadCSV, tableToCSV } from '@/helpers/csv'
import { Shipment } from '@/features/shipments'
import { StockRelation } from '@/features/stockRelations'
import Big from 'big.js'

export const downloadTRUBulkErrorReport = (bulkError: BulkError) => {
  downloadCSV(
    tableToCSV(
      ['index', 'status', 'entity', 'reason'],
      bulkError.errors.map(({ index, error }) => [
        String(index),
        error.code === ErrorCodes.ALREADY_EXISTS ? 'OK' : 'FAIL',
        error.entity,
        error.message,
      ])
    ),
    'error-report'
  )
}

export const getTRUAvailableStock = (
  truId: string,
  truQuantity: string,
  shipments: Shipment[],
  stockRelations: StockRelation[]
): string => {
  const stock = [
    ...shipments
      .filter(
        (shipment): shipment is Required<Shipment> =>
          shipment.truId === truId && !!shipment.quantity
      )
      .map(({ quantity }) => quantity),
    ...stockRelations
      .filter(stockRelation => stockRelation.parent === truId)
      .map(({ quantity }) => quantity),
  ]
    .reduce((previous, current) => {
      return previous.minus(current || 0)
    }, new Big(truQuantity))
    .toFixed(2)
    .toString()

  return stock
}
