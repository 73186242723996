import { useContext, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { NotificationPanelContext } from './context'
import { acknowNotification, getAll, getByFilters } from './service'
import { NotificationFilters } from './interfaces'

const QUERY_NOTIFICATIONS_KEY = 'notifications'

export const useNotificationPanel = () => {
  const context = useContext(NotificationPanelContext)
  if (!context)
    throw new Error('useNotificationPanel must be used within the NotificationPanelProvider')

  return context
}

export const useNotificationsWithFilters = (filters: NotificationFilters) => {
  const { data, ...rest } = useQuery(
    [QUERY_NOTIFICATIONS_KEY, filters],
    () => getByFilters(filters),
    {
      refetchInterval: 10000, // 10 seconds
    }
  )

  return { ...rest, notifications: useMemo(() => data?.slice().reverse() || [], [data]) }
}

export const useNotifications = () => {
  const { data, ...rest } = useQuery(QUERY_NOTIFICATIONS_KEY, getAll, {
    refetchInterval: 10000, // 10 seconds
  })

  return { ...rest, notifications: useMemo(() => data?.slice().reverse() || [], [data]) }
}

export const useHasNotificationNotScknowledged = (): boolean => {
  const { notifications } = useNotifications()

  return notifications.some(({ acknowledged }) => !acknowledged)
}

export const useAckNotification = () => {
  const queryClient = useQueryClient()
  const { mutate, ...rest } = useMutation(acknowNotification, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_NOTIFICATIONS_KEY)
    },
  })

  return { ...rest, ackNotification: mutate }
}

export const useAckNotifications = () => {
  const queryClient = useQueryClient()
  const { mutate, ...rest } = useMutation(
    (notificationIds: string[]) =>
      Promise.all(
        notificationIds.map(notificationId =>
          acknowNotification({ notificationId: notificationId })
        )
      ),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QUERY_NOTIFICATIONS_KEY)
      },
    }
  )

  return { ...rest, ackNotifications: mutate }
}
