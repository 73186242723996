import dayjs from 'dayjs'
import { COMPLIANCE_ALMOST_DEADLINE_DAYS } from '../constants'
import { ComplianceValidation } from '../interfaces'

export const isComplianceAlmostExpired = (deadlineAt: string): boolean => {
  const distanceInHours = dayjs(deadlineAt).diff(dayjs(), 'hour')
  const distanceInDays = dayjs
    .duration({
      hours: distanceInHours,
    })
    .asDays()

  const isAlmostExpired = distanceInDays < COMPLIANCE_ALMOST_DEADLINE_DAYS
  return isAlmostExpired
}

export const getLastComplianceValidation = (
  validationState?: ComplianceValidation[]
): ComplianceValidation | undefined => {
  if (!validationState) return undefined
  const sortedValidations = validationState.sort(
    (validationA, validationB) =>
      new Date(validationB.validatedAt).getTime() - new Date(validationA.validatedAt).getTime()
  )

  return sortedValidations[0]
}
