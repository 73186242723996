import { useDebounceState } from '@/hooks/use-debounce-state'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ROOT_FOLDER_ID } from '../../constants'
import { useDocumentTree, useDocuments } from '../../hooks'
import { Document, DocumentFolder, DocumentType } from '../../interfaces'
import { sortFoldersFirst } from '../../utils'

export interface UseDocumentFolderPickerProps {
  /**
   * @default ROOT_FOLDER_ID
   */
  defaultFolderId?: string
  hideIds?: string[]
  onPick: (documentFolder: Pick<DocumentFolder, '_id' | 'title'>) => void
}

export interface UseDocumentFolderPickerState {
  isLoading: string
  documents: Document[]
  breadcrumb: {
    id: string
    name: string
  }[]
  handleFolderSelect: (folderId: string) => void
  handleBreadcrumbClick: (folderId: string) => void
}

export const useDocumentFolderPicker = ({
  defaultFolderId = ROOT_FOLDER_ID,
  hideIds = [],
  onPick,
}: UseDocumentFolderPickerProps) => {
  const { t } = useTranslation('nsDocumentsViewPage')
  const [parentId, setParentId] = useState<string>(defaultFolderId)

  const { documents, isLoading: isDocumentsLoading } = useDocuments(
    { parentId },
    {
      // Sort data to view first the folders
      select: documents =>
        documents.filter(({ _id }) => !hideIds.includes(_id)).sort(sortFoldersFirst),
    }
  )

  const { documents: treeFolders, isLoading: isTreeFoldersLoading } = useDocumentTree(
    defaultFolderId,
    {
      enabled: defaultFolderId !== ROOT_FOLDER_ID,
    }
  )

  const [isLoadingDebounced, , isLoading] = useDebounceState(
    isDocumentsLoading || isTreeFoldersLoading
  )

  const [breadcrumb, setBreadcrumb] = useState<Array<{ id: string; name: string }>>([])

  const handleFolderSelect = useCallback(
    (documentId: string) => {
      const documentSelected = documents.find(({ _id }) => _id === documentId)
      if (documentSelected && documentSelected.type === DocumentType.FOLDER) {
        setParentId(documentId)
        onPick(documentSelected)
        setBreadcrumb([...breadcrumb, { id: documentSelected._id, name: documentSelected.title }])
      }
    },
    [breadcrumb, documents, onPick]
  )

  const handleBreadcrumbClick = useCallback(
    (folderId: string) => {
      const breadcrumbIndex = breadcrumb.findIndex(({ id }) => id === folderId)
      const breadcrumbSelected = breadcrumb.find(({ id }) => id === folderId)

      setParentId(folderId)
      breadcrumbSelected && onPick({ _id: breadcrumbSelected.id, title: breadcrumbSelected?.name })

      // Re calculate breadcrump items
      if (folderId === ROOT_FOLDER_ID) {
        setBreadcrumb([])
        onPick({ _id: ROOT_FOLDER_ID, title: t('roots.myDocuments') })
      } else {
        setBreadcrumb(breadcrumb.slice(0, breadcrumbIndex + 1))
      }
    },
    [breadcrumb, onPick, t]
  )

  useEffect(() => {
    if (treeFolders.length !== 0) {
      setBreadcrumb(
        treeFolders
          .slice()
          .reverse()
          .map(treeFolder => ({ id: treeFolder._id, name: treeFolder.title }))
      )
    }
  }, [treeFolders])

  useEffect(() => {
    setParentId(defaultFolderId)
  }, [defaultFolderId])

  return {
    isLoading: isLoading || isLoadingDebounced,
    documents,
    breadcrumb,
    handleFolderSelect,
    handleBreadcrumbClick,
  }
}
