import { Template } from '@/features/templates'
import { Issue, IssueStatus } from '@/features/issues'
import { ValidationRequest, ValidationRequestStatus } from '../validationRequests'
import { Entry } from '@/features/entries'

export enum TaskStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export interface Task {
  _id: string
  companyId: string
  formId: string
  formName: string
  formVersion: number
  formControl?: {
    code?: string
    isoStandardReference?: string
  }
  dueDate: string
  entries: Entry[]
  status: TaskStatus
  assignedTo: string
  issueResponder?: string | null
  createdAt: string
  updatedAt: string
  closedAt?: string
  createdBy: string
  updatedBy: string

  automated?: boolean

  lastValidation?: {
    id: string
    requesterId: string
    reviewerIds: string[]
    status: ValidationRequestStatus
    updatedAt: string
    createdAt: string
  }

  // Task incidence
  stakeHolderId?: string
  productId?: string
  truId?: string

  automationTriggeredBy?: {
    id: string
    userId?: string
  }
}

export interface ReadableTask extends Task {
  readonly template?: Template
  readonly issues: Issue[]
  readonly lastValidationRequest?: ValidationRequest
  readonly validationRequests: ValidationRequest[]
}

export interface TaskCreation {
  formId: string
  formName: string
  formVersion?: number
  formControl?: {
    code?: string
    isoStandardReference?: string
  }
  dueDate: string
  entries: Entry[]
  status: TaskStatus
  assignedTo: string
  issueResponder?: string | null
  // Task incidence
  stakeHolderId?: string
  productId?: string
  truId?: string
  truIds: string[]
  receptionId?: string
  receptionIds: string[]
  tags?: string[]
}
export interface TaskUpdate {
  formId?: string
  formVersion?: number
  formName?: string
  dueDate?: string
  entries?: Entry[]
  status?: TaskStatus
  assignedTo?: string
  issueResponder?: string | null
  // Task incidence
  stakeHolderId?: string
  productId?: string
  truId?: string
  truIds?: string[]
  receptionId?: string
  receptionIds?: string[]
  tags?: string[]
}

export interface TruTaskRelation {
  _id: string
  companyId: string
  taskId: string
  truId: string
  createdAt: string
  updatedAt: string
}

export interface ReceptionTaskRelation {
  _id: string
  companyId: string
  taskId: string
  receptionId: string
  createdAt: Date
}

export interface StakeHolderTaskRelation {
  _id: string
  companyId: string
  taskId: string
  stakeHolderId: string
  createdAt: Date
}

export interface TaskReportSent {
  taskId: string
  to: string[]
  subject: string
  content: string
  metadata: {
    userEmail: string
    companyName: string
    formName: string
  }
  createdBy: string
  createdAt: string
  updatedAt: string
}

export interface TaskFilters {
  assignedToMe?: boolean
  respondedByMe?: boolean
  startDate?: string
  endDate?: string
  dueDate?: string[]
  assignedTo?: string[]
  formId?: string[]
  status?: string[]
  /**
   * null means no validation request
   */
  validationStatus?: (ValidationRequestStatus | 'null')[]
  validationReviewerId?: string[]

  tagsIn?: string[]

  hasIssues?: boolean
  issueStatus?: IssueStatus[]
  issueResponder?: string[]
}

export interface TasksSummaryReportData {
  totalTasks: number
  approvedTasks: number
  rejectedTasks: number
  pendingTasks: number
  items: Task[]
}

export interface TasksSummaryReportFilters {
  startDate: Date
  endDate: Date
}

export enum TaskDetailsStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}
