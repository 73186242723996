import { useDebounceState } from '@/hooks/use-debounce-state'
import { Button, Loader, Modal, SIZE } from '@blockchain-traceability-sl/tailwind-components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ROOT_FOLDER_ID } from '../constants'
import { useDocumentById } from '../hooks'
import { Document, DocumentFolder } from '../interfaces'
import { DocumentFolderPicker } from './DocumentFolderPicker'

export type ModalMoveDocumentToMoveHandler = (
  document: Pick<Document, '_id' | 'title' | 'type'>,
  source: Pick<DocumentFolder, '_id' | 'title'>,
  target: Pick<DocumentFolder, '_id' | 'title'>
) => void

export interface ModalMoveDocumentToProps {
  documentId?: string
  defaultFolderId?: string
  show: boolean
  toggle: () => void
  onMove: ModalMoveDocumentToMoveHandler
}

export const ModalMoveDocumentTo = ({
  show,
  toggle,
  documentId,
  onMove,
}: ModalMoveDocumentToProps) => {
  const { t } = useTranslation('nsDocumentsViewPage')
  const [targetPicked, setTargetPicked] = useState<{
    _id: string
    title: string
  } | null>(null)

  const { document, isLoading: isDocumentLoading } = useDocumentById(documentId || '', {
    enabled: !!documentId && show,
  })

  const { document: documentParent, isLoading: isParentLoading } = useDocumentById(
    document?.parentId || '',
    {
      enabled: !!document?.parentId && show && document.parentId !== ROOT_FOLDER_ID,
    }
  )

  const [isLoadingDebounced, , isLoading] = useDebounceState(isDocumentLoading || isParentLoading)

  const handleSubmit = () => {
    if (document && targetPicked && (documentParent || document.parentId === ROOT_FOLDER_ID)) {
      onMove(
        document,
        documentParent || {
          _id: ROOT_FOLDER_ID,
          title: t('roots.myDocuments'),
        },
        targetPicked
      )
    }
  }

  return (
    <Modal size={SIZE.LARGE} show={show} toggle={toggle} dismissButton>
      <Modal.Title>{t('modalMove.title')}</Modal.Title>
      <Modal.Body>
        {isLoading || isLoadingDebounced || !document ? (
          <Loader center='full' />
        ) : (
          <DocumentFolderPicker
            onPick={setTargetPicked}
            defaultFolderId={document.parentId}
            hideIds={documentId ? [documentId] : []}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit}>{t('modalMove.action')}</Button>
      </Modal.Footer>
    </Modal>
  )
}
