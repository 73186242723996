import {
  TemplateEntryReferenceType,
  TemplateEntryType,
  TemplateEntryValues,
  TemplateTableEntryColumn,
} from '@/features/templates'
import {
  CheckboxField,
  CreatableMultiField,
  InputField,
  SelectField,
  SelectorGroup,
  SelectorOption,
  TextAreaField,
} from '@blockchain-traceability-sl/tailwind-components'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { CheckIcon, TrashIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ConditionalEntryField, ConditionalEntryFieldProps } from './ConditionalEntryField'
import { SectionEntryField, SectionEntryFieldProps } from './SectionEntryField'
import { TableEntryField } from './TableEntryField'
import { isMandatoriableField } from '../utils'

export interface SortableEntryEditableProps {
  hasKeysErrors: boolean

  id: string
  name: string
  mandatory: boolean
  type: TemplateEntryType | TemplateEntryReferenceType
  values: TemplateEntryValues
  defaultValues?: TemplateEntryValues

  typeOptions: SelectorGroup[]

  // ERRORS
  hasNameError: boolean
  hasTypeError: boolean
  hasSelectorError: boolean
  hasContentError?: boolean

  // HANDLERS
  onKeyEditEnd: () => void
  onKeyDelete: (id: string) => void
  onNameChange: (value: string, itemId: string) => void
  onTypeChange: (value: TemplateEntryType, itemId: string) => void
  onSelectorChange: (values: string[], itemId: string) => void
  onContentChange?: (value: string, itemId: string) => void
  onContentTableChange?: (value: TemplateTableEntryColumn[], itemId: string) => void
  onMandatoryChange?: (value: boolean, itemId: string) => void

  conditionalOptions?: Omit<ConditionalEntryFieldProps, 'targetEntry'>

  sectionOptions?: Omit<SectionEntryFieldProps, 'targetEntry'>

  // Flags
  mandatoryEnabled?: boolean
  referenceEnabled?: boolean
  documentEnabled?: boolean

  className?: string
}

export const SortableEntryEditable = memo(
  ({
    hasKeysErrors,
    id,
    name,
    mandatory,
    type,
    values,
    defaultValues,
    typeOptions,
    hasNameError,
    hasTypeError,
    hasSelectorError,
    hasContentError,
    onKeyEditEnd,
    onKeyDelete,
    onNameChange,
    onTypeChange,
    onSelectorChange,
    onContentChange,
    onContentTableChange,
    onMandatoryChange,
    mandatoryEnabled = true,
    referenceEnabled,
    documentEnabled,
    conditionalOptions,
    sectionOptions,
    className,
  }: SortableEntryEditableProps) => {
    const { t } = useTranslation('nsTemplate')
    const { setNodeRef, transform, transition, isDragging, isSorting } = useSortable({
      id,
      disabled: true,
    })

    const style = useMemo(
      () => ({
        transform: CSS.Transform.toString(transform),
        transition: !isDragging || !isSorting ? 'none' : transition,
      }),
      [transform, transition, isDragging, isSorting]
    )

    return (
      <div ref={setNodeRef} className={className} style={style}>
        <div className='grid gap-6'>
          <div className='flex'>
            <div className='flex-grow'>
              <InputField
                id={`key_${id}_key`}
                name={`key_${id}_key`}
                onChange={event => onNameChange(event.currentTarget.value, id)}
                autoFocus
                value={name}
                label={t('entries.name.label')}
                description={t('entries.name.description')}
                error={hasNameError}
              />
            </div>

            <div className='mt-6 flex-shrink ml-2 flex flex-col space-y-2 xl:block m-auto xl:mb-1 lg:ml-2 xl:space-x-2'>
              <button
                type='button'
                className={classNames(
                  'inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
                  {
                    'cursor-not-allowed': hasKeysErrors,
                  }
                )}
                onClick={onKeyEditEnd}
              >
                <CheckIcon className='h-5 w-5' aria-hidden='true' />
              </button>

              <button
                type='button'
                className='inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                onClick={() => onKeyDelete(id)}
              >
                <TrashIcon className='h-5 w-5' aria-hidden='true' />
              </button>
            </div>
          </div>

          <div className='grid grid-cols-3 gap-6'>
            <div
              className={classNames({
                'col-span-1':
                  type === TemplateEntryType.MULTIPLE_CHOICE ||
                  type === TemplateEntryType.SINGLE_CHOICE,
                'col-span-3':
                  type !== TemplateEntryType.MULTIPLE_CHOICE &&
                  type !== TemplateEntryType.SINGLE_CHOICE,
              })}
            >
              <SelectField
                key={`entry.type.${id}`}
                id={`key_type_${id}`}
                name={`entry.type.${id}`}
                options={typeOptions}
                value={typeOptions
                  .flatMap(group => group.options)
                  .find(selection => selection.value === type)}
                onChange={option =>
                  onTypeChange(
                    option
                      ? (option.value as TemplateEntryType.SHORT_TEXT)
                      : TemplateEntryType.SHORT_TEXT,
                    id
                  )
                }
                label={t('entries.type.label')}
                error={hasTypeError}
              />
            </div>

            {(type === TemplateEntryType.SINGLE_CHOICE ||
              type === TemplateEntryType.MULTIPLE_CHOICE) && (
              <div className='col-span-2'>
                <CreatableMultiField
                  key={`entry.values.${id}`}
                  id={`key_values_${id}`}
                  name={`entry.values.${id}`}
                  value={values.map(
                    (value): SelectorOption => ({ label: value as string, value: value as string })
                  )}
                  onChange={options =>
                    onSelectorChange(
                      options.map(({ value }) => value),
                      id
                    )
                  }
                  label={t('entries.selector.label')}
                  error={hasSelectorError}
                />
              </div>
            )}
          </div>

          <p className='text-sm text-gray-500 -mt-5'>{t('entries.type.description')}</p>

          {type === TemplateEntryType.STATEMENT && (
            <div>
              <TextAreaField
                key={`entry.values.${id}`}
                id={`key_values_${id}`}
                name={`entry.values.${id}`}
                value={(values[0] as string) || ''}
                onChange={event =>
                  onContentChange && onContentChange(event.currentTarget.value, id)
                }
                label={t('entries.content.label')}
                description={t('entries.content.description')}
                error={hasContentError}
              />
            </div>
          )}

          {mandatoryEnabled && isMandatoriableField(type) && (
            <div>
              <CheckboxField
                id={`entry.type.mandatory.${id}`}
                key={`entry.type.mandatory.${id}`}
                label={t('entries.checkbox.mandatory.label')}
                name={`entry.type.mandatory.${id}`}
                onChange={event =>
                  onMandatoryChange && onMandatoryChange(event.currentTarget.checked, id)
                }
                checked={!!mandatory}
              />
            </div>
          )}

          {type === TemplateEntryType.TABLE && (
            <div className='grid grid-cols-1 gap-6'>
              <TableEntryField
                onChange={columns => onContentTableChange && onContentTableChange(columns, id)}
                value={values as TemplateTableEntryColumn[]}
                referenceEnabled={referenceEnabled}
                documentEnabled={documentEnabled}
              />
            </div>
          )}

          {type === TemplateEntryType.CONDITIONAL && conditionalOptions && (
            <div className='mt-4'>
              <ConditionalEntryField
                targetEntry={{
                  id,
                  name,
                  mandatory,
                  type,
                  values,
                  defaultValues,
                }}
                {...conditionalOptions}
              />
            </div>
          )}

          {type === TemplateEntryType.SECTION && sectionOptions && (
            <div className='mt-4'>
              <SectionEntryField
                targetEntry={{
                  id,
                  name,
                  mandatory,
                  type,
                  values,
                  defaultValues,
                }}
                {...sectionOptions}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
)
