import { api } from '@/app/api'
import { Onboarding } from './interfaces'

const ONBOARDING_URL = '/auth/onboarding'

export const getMyOnboarding = async () => {
  const { data: onboarding } = await api.get<Onboarding | undefined>(
    `${ONBOARDING_URL}/app/dashboard/me`
  )
  return onboarding
}

export const markOnboardingAsCompleted = async (onboardingId: string) => {
  await api.patch<void>(`${ONBOARDING_URL}/${onboardingId}/markAsCompleted`)
}

export const markOnboardingStepAsCompleted = async (onboardingId: string, stepId: string) => {
  await api.patch<void>(`${ONBOARDING_URL}/${onboardingId}/steps/${stepId}/markAsCompleted`)
}
