import { openDB } from 'idb'

export const saveState = <T = unknown>(storeKey: string, values: T) => {
  /**
   * We are using localStorage here to save the state of the form.
   * This can produce quota exceeded error if the data is too large.
   * In that case, we catch the error and do nothing.
   */
  try {
    window.localStorage.setItem(
      storeKey,
      JSON.stringify({
        timestamp: new Date().getTime(),
        content: values,
      })
    )
  } catch (error) {
    console.warn('Error saving state to local storage', error)
  }
}

export const getState = <T = unknown>(
  storeKey: string
): { timestamp: number; content: T } | null => {
  const item = window.localStorage.getItem(storeKey)

  if (item) {
    return JSON.parse(item)
  }

  return null
}

export const removeState = (storeKey: string) => {
  window.localStorage.removeItem(storeKey)
}

const createStoreInDB = async (storeName: string) => {
  const databaseName = `memo-form-${storeName}`
  const databasePromise = await openDB(databaseName, 1, {
    upgrade(database) {
      if (!database.objectStoreNames.contains(storeName)) {
        database.createObjectStore(storeName)
      }
    },
  })

  return databasePromise
}

export const saveStateInDB = async <T = unknown>(store: string, storeKey: string, values: T) => {
  const database = await createStoreInDB(store)

  await database.put(
    store,
    JSON.stringify({
      timestamp: new Date().getTime(),
      content: values,
    }),
    storeKey
  )
}

export const getStateFromDB = async (store: string, storeKey: string): Promise<string | null> => {
  const database = await createStoreInDB(store)

  return database.get(store, storeKey)
}

export const removeStateFromDB = async (store: string, storeKey: string) => {
  const database = await createStoreInDB(store)

  await database.delete(store, storeKey)
}
