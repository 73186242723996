import { SelectorOption } from '@blockchain-traceability-sl/tailwind-components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useCountryCodes = () => {
  const { i18n } = useTranslation()
  const [countryCodes, setCountryCodes] = useState<SelectorOption[]>([])

  useEffect(() => {
    async function getCountryCodes() {
      try {
        const countryCode = i18n.language.split('-')[0]
        const countryCodesByUserLang = await import(
          `../i18n/country-codes-${countryCode || 'en'}.json`
        ).then(module => module.default)
        setCountryCodes(countryCodesByUserLang)
      } catch (error) {
        const fallbackLng = (i18n.options.fallbackLng as string[])[0].split('-')[0]
        const countryCodesByFallbackLang = await import(
          `../i18n/country-codes-${fallbackLng}.json`
        ).then(module => module.default)
        setCountryCodes(countryCodesByFallbackLang)
      }
    }
    getCountryCodes()
  }, [i18n])

  return countryCodes
}
