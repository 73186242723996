import { useTranslation } from 'react-i18next'
import { TemplateEntryType } from '@/features/templates'
import { EntryFieldProps } from '../interfaces'
import { useEntryFieldAttributes } from '../utils'
import { EntryCreateInput } from '../../interfaces'
import { EntryLabel } from '../EntryLabel'
import { useField } from 'formik'
import { noop } from '@/utils/noop'

export const StatementEntryField = ({ index, prefixName }: EntryFieldProps) => {
  const { t } = useTranslation('nsEntries')

  const { entryFieldName, enabledFieldName, enabledFieldId } = useEntryFieldAttributes(
    index,
    prefixName
  )

  const [{ value: entry }] = useField<EntryCreateInput>(entryFieldName)

  if (entry.type !== TemplateEntryType.STATEMENT) return null

  return (
    <dl key={`entries.${index}.value`}>
      <dt className='font-bold text-2xl'>
        <EntryLabel
          naCheckboxId={enabledFieldId}
          naCheckboxName={enabledFieldName}
          naUnchecked={true}
          onNaChange={noop}
          naCheckboxLabel={t('form.na')}
          withNa={false}
        >
          {entry.name}
        </EntryLabel>
      </dt>
      {!!entry.value &&
      entry.value.length !== 0 &&
      typeof entry.value === 'string' &&
      entry.value.trim() !== '' ? (
        <dd className='mt-1 whitespace-pre-wrap break-words'>{entry.value}</dd>
      ) : (
        ''
      )}
    </dl>
  )
}
