import {
  DocumentSettingsExpirationDuration,
  DocumentSettingsExpirationDurations,
} from '@/features/documents'
import { SelectField, SelectFieldProps } from '@blockchain-traceability-sl/tailwind-components'
import { Trans, useTranslation } from 'react-i18next'

const getLabelI18nKey = (value: DocumentSettingsExpirationDuration) => {
  switch (value) {
    case DocumentSettingsExpirationDuration.THREE_YEAR:
      return 'threeyears'
    case DocumentSettingsExpirationDuration.TWO_YEAR:
      return 'twoyears'
    case DocumentSettingsExpirationDuration.ONE_YEAR:
      return 'year'
    case DocumentSettingsExpirationDuration.SIX_MONTHS:
      return 'month'
    case DocumentSettingsExpirationDuration.NINETY_DAYS:
      return 'ninety'
    case DocumentSettingsExpirationDuration.SIXTY_DAYS:
      return 'sixty'
    case DocumentSettingsExpirationDuration.THIRTY_DAYS:
      return 'thirty'
    case DocumentSettingsExpirationDuration.NEVER:
      return 'withoutExpiration'
  }
}

export const FileExpirationSelectionField = ({
  value,
  ...props
}: Pick<SelectFieldProps, 'id' | 'name' | 'onChange' | 'isDisabled'> & {
  value?: DocumentSettingsExpirationDuration
}) => {
  /**
   * TODO: Replace with a better i18n keys
   */
  const { t } = useTranslation('nsTemplate', {
    keyPrefix: 'entries.documentSettings.expiration',
  })

  const options = DocumentSettingsExpirationDurations.map(item => ({
    value: item,
    label: t(getLabelI18nKey(item)),
  }))

  return (
    <SelectField
      {...props}
      menuPortalTarget={document.body}
      isClearable
      options={options}
      value={
        value
          ? {
              value,
              label: t(getLabelI18nKey(value)),
            }
          : null
      }
      placeholder={t('placeholder')}
      label={
        <Trans
          t={t}
          i18nKey='title'
          components={[<span className='font-light text-gray-400 italic' />]}
        />
      }
    />
  )
}
