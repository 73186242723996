import { getButtonClassName } from '@blockchain-traceability-sl/tailwind-components'
import { useTranslation } from 'react-i18next'

export const ErrorPage = () => {
  const { t } = useTranslation('nsErrorPage')

  return (
    <div className='bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8'>
      <div className='max-w-max mx-auto'>
        <main className='sm:flex'>
          <p className='text-4xl font-extrabold text-blue-600 sm:text-5xl'>404</p>
          <div className='sm:ml-6'>
            <div className='sm:border-l sm:border-gray-200 sm:pl-6'>
              <h1 className='mb-5 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl'>
                {t('title', 'Sorry, something went wrong')}
              </h1>
              <p className='mt-1 text-base text-gray-500'>
                {t(
                  'description',
                  "Looks like this page failed. Don't worry though. Our best dev is on the case."
                )}
                <br />
                {t('whatToDo', 'Refresh the webiste or go back to the home page.')}
              </p>
            </div>
            <div className='mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6'>
              <a href='/' className={getButtonClassName({})}>
                {t('backToHome', 'Back to home')}
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
