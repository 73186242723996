import { PDF_MANUAL_EN_URL, PDF_MANUAL_ES_URL, TABLE_SIZE_PRESETS } from '@/app/constants'
import { noop } from '@/utils/noop'
import { Button, SIZE, Table } from '@blockchain-traceability-sl/tailwind-components'
import { memo, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'react-table'
import { TemplateComplianceEntityType, TemplateType } from '@/features/templates'
import i18n from '@/i18n'

export type FormsListItem = {
  id: string
  name: string
  type: TemplateType.REGISTRY | TemplateType.INCIDENCE | TemplateType.COMPLIANCE
  departments: string[]
  totalEntries: number
  complianceEntityType?: TemplateComplianceEntityType | null
}
export type FormsListItemClickResult = Row<FormsListItem>

export interface FormsListProps {
  isLoading?: boolean
  /**
   * Must be memoized to avoid extra renders
   */
  data: FormsListItem[]
  /**
   * Must be memoized to avoid extra renders
   */
  onRowClick?: (row: FormsListItemClickResult) => void

  rowActionsDisabled?: boolean

  /**
   * Pagination
   */
  onPageChange: (page: { pageIndex: number; pageSize: number }) => void
  totalCount: number
  pagination: { pageIndex: number; pageSize: number }
}

export const FormsList = memo(
  ({
    isLoading,
    data,
    onRowClick = noop,
    rowActionsDisabled,
    onPageChange,
    totalCount,
    pagination,
  }: FormsListProps) => {
    const { t } = useTranslation('nsFormsListPage')

    const getTemplateTypeName = (item: FormsListItem): string => {
      if (item.type === TemplateType.COMPLIANCE && item.complianceEntityType) {
        if (item.complianceEntityType === TemplateComplianceEntityType.STAKEHOLDER) {
          return t('table.types.stakeholderCompliance')
        } else if (item.complianceEntityType === TemplateComplianceEntityType.STAKEHOLDER_PRODUCT) {
          return t('table.types.rawMaterialCompliance')
        }
      }

      return t(`table.types.${item.type}`)
    }

    const language = i18n.language

    const userManualUrl = useMemo(() => {
      if (language === 'es') {
        return PDF_MANUAL_ES_URL
      }
      return PDF_MANUAL_EN_URL
    }, [language])

    return (
      <Table
        manualPagination
        onPageChange={onPageChange}
        onPageSizeChange={newPageSize =>
          onPageChange({ pageIndex: pagination.pageIndex, pageSize: newPageSize })
        }
        totalCount={totalCount}
        pageSize={pagination.pageSize}
        defaultPageIndex={pagination.pageIndex}
        columns={[
          {
            Header: t('table.name').toString(),
            accessor: 'name',
          },
          { Header: t('table.version').toString(), accessor: 'version' },
          {
            Header: t('table.type').toString(),
            accessor: 'type',
          },
          {
            Header: t('table.departments').toString(),
            accessor: 'departments',
          },
          {
            Header: t('table.totalEntries').toString(),
            accessor: 'totalEntries',
            disableGlobalFilter: true,
          },
          {
            Header: t('table.actions').toString(),
            accessor: 'actions',
            disableGlobalFilter: true,
          },
        ]}
        data={data.map(row => ({
          ...row,
          type: getTemplateTypeName(row),
          departments:
            row.departments.length > 0 ? (
              row.departments.join(', ')
            ) : (
              <div className='text-center'>-</div>
            ),
          actions: (
            <Button color='link' disabled={rowActionsDisabled}>
              {t('table.action.cell')}
            </Button>
          ),
        }))}
        isLoading={isLoading}
        onRowClick={row => onRowClick(row as unknown as Row<FormsListItem>)}
        previousText={t('pagination.previous')}
        nextText={t('pagination.next')}
        renderInfoText={(from, to, total) => (
          <Trans
            t={t}
            i18nKey='pagination.show'
            components={{ b: <span className='font-semibold' /> }}
            values={{ from, to, total }}
          />
        )}
        sizePresetsText={t('pagination.items')}
        showSizePresets
        sizePresets={TABLE_SIZE_PRESETS}
        noDataText={
          <tr>
            <td colSpan={6} className='text-center p-8 space-y-6'>
              <p className='text-4xl leading-10 font-extrabold text-center whitespace-pre-wrap'>
                {t('table.noData')}
              </p>
              <Button
                color='secondary'
                size={SIZE.EXTRA_LARGE}
                className='m-auto'
                onClick={() => {
                  window.analytics.track('ACTION_CLICK_HELP', { Source: 'Forms section' })
                  window.open(userManualUrl, '_blank')
                }}
              >
                {t('table.needHelp')}
              </Button>
            </td>
          </tr>
        }
      />
    )
  }
)
