import { api } from '@/app/api'
import { Paginated, PaginationParams } from '@/hooks/use-pagination'
import { Reception, ReceptionCreation, ReceptionFilters, ReceptionUpdate } from './interfaces'

/**
 * Define the url path
 * @constant
 */
export const RECEPTIONS_URL = '/hydra/trus/receptions'

/**
 * Create a new Reception
 *
 * @param receptionRaw Raw data to create a new reception
 * @returns The created reception id
 */
export const create = async (receptionRaw: ReceptionCreation): Promise<string> => {
  const { data: receptionId } = await api.post<string>(RECEPTIONS_URL, receptionRaw)
  return receptionId
}

/**
 * Create a new Reception from a category
 *
 * @param receptionRaw Raw data to create a new reception
 */
export const createFromCategory = async (receptionRaw: ReceptionCreation): Promise<string> => {
  const { data: receptionId } = await api.post<string>(`${RECEPTIONS_URL}/beta`, receptionRaw)
  return receptionId
}

/**
 * Get the Company logged Receptions
 *
 * @returns {Reception[]} All the Company logged Receptions
 */
export const getAll = async (filters?: ReceptionFilters): Promise<Reception[]> => {
  const searchParams = new URLSearchParams()
  if (filters?.search) {
    searchParams.append('search', filters.search)
  }
  if (filters?.stakeHolderId) {
    filters.stakeHolderId.forEach(value => {
      searchParams.append('stakeHolderId[]', value)
    })
  }
  if (filters?.stakeHolderProductId) {
    filters.stakeHolderProductId.forEach(value => {
      searchParams.append('stakeHolderProductId[]', value)
    })
  }
  if (filters?.withStock) {
    searchParams.append('withStock', filters.withStock.toString())
  }
  const { data: receptions } = await api.get<Reception[] | void>(RECEPTIONS_URL, {
    params: searchParams,
  })
  return receptions || []
}

/**
 * Get all company Reception with pagination and filter support
 * @returns All the Reception that match
 */
export const getPaginatedReceptions = async (
  { search, stakeHolderId, stakeHolderProductId }: ReceptionFilters,
  pagination: PaginationParams
): Promise<Paginated<Reception>> => {
  const searchParams = new URLSearchParams()

  if (search) {
    searchParams.append('search', search)
  }
  stakeHolderId?.forEach(value => {
    searchParams.append('stakeHolderId[]', value)
  })
  stakeHolderProductId?.forEach(value => {
    searchParams.append('stakeHolderProductId[]', value)
  })
  searchParams.append('limit', pagination.limit.toString())
  searchParams.append('offset', pagination.offset.toString())
  const { data } = await api.get<Paginated<Reception>>(RECEPTIONS_URL, {
    params: searchParams,
  })
  return data
}

/**
 * Get the Company logged Receptions by ids
 *
 * @returns Company logged Receptions by ids
 */
export const getByIds = async (receptionIds: string[]): Promise<Reception[]> => {
  if (receptionIds.length === 0) return []
  const { data: receptions } = await api.post<Reception[] | void>(`${RECEPTIONS_URL}/ids`, {
    ids: receptionIds,
  })
  return receptions || []
}

export const getByProduct = async (productId: string): Promise<Reception[]> => {
  const { data: receptions } = await api.get<Reception[] | void>(
    `${RECEPTIONS_URL}/product/${encodeURIComponent(productId)}`
  )
  return receptions || []
}

export const getByStakeHolderProduct = async (
  stakeHolderProductId: string
): Promise<Reception[]> => {
  const { data: receptions } = await api.get<Reception[] | void>(
    `${RECEPTIONS_URL}/stakeHolderProduct/${encodeURIComponent(stakeHolderProductId)}`
  )
  return receptions || []
}

export const getByStakeHolderProductIds = async (
  stakeHolderProductIds: string[]
): Promise<Reception[]> => {
  const { data: receptions } = await api.post<Reception[] | void>(
    `${RECEPTIONS_URL}/stakeHolderProduct/ids`,
    {
      ids: stakeHolderProductIds,
    }
  )
  return receptions || []
}

export const update = async (receptionId: string, reception: ReceptionUpdate): Promise<void> => {
  await api.patch<void>(`${RECEPTIONS_URL}/${encodeURIComponent(receptionId)}`, reception)
}

export const remove = async (receptionId: string): Promise<void> => {
  await api.delete<void>(`${RECEPTIONS_URL}/${encodeURIComponent(receptionId)}`)
}

/**
 * Create Category with CSV file
 * This endpoint accepts an unexisting raw material reference and
 * it needs a category name in order to create the raw material if it doesn't exists
 */
export const createWithCSVBeta = async (file: File): Promise<{ total: number }> => {
  const formData = new FormData()
  formData.append('file', file)
  const { data } = await api.post<{ total: number }>(`${RECEPTIONS_URL}/beta/csv`, formData)
  return data
}

/**
 * Create Category with CSV file
 */
export const createWithCSV = async (file: File): Promise<{ total: number }> => {
  const formData = new FormData()
  formData.append('file', file)
  const { data } = await api.post<{ total: number }>(`${RECEPTIONS_URL}/csv`, formData)
  return data
}

/**
 * Get StakeHolderProduct CSV template with category name
 * This endpoint returns the csv template adding a "raw material name" header where we need to add a category name
 */
export const getCsvTemplateBeta = async (): Promise<ArrayBuffer> => {
  const { data } = await api.get<ArrayBuffer>(`${RECEPTIONS_URL}/beta/csv`, {
    responseType: 'arraybuffer',
  })
  return data
}

/**
 * Get StakeHolderProduct CSV template
 */
export const getCsvTemplate = async (): Promise<ArrayBuffer> => {
  const { data } = await api.get<ArrayBuffer>(`${RECEPTIONS_URL}/csv`, {
    responseType: 'arraybuffer',
  })
  return data
}
