import { IconComponentType } from '@blockchain-traceability-sl/tailwind-components'
import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import { ReactNode, ReactText } from 'react'

/**
 * Item to show in the dropdown menu
 */
export interface DropdownMenuItemProps {
  /**
   * Item id
   */
  id: ReactText
  /**
   * Item name to show
   */
  name: ReactNode
  /**
   * Item click event handler
   */
  onClick: () => void
  /**
   * Optional prepend item icon
   */
  icon?: IconComponentType
  /**
   * Optional disabled item status.
   * Items disabled cannot handle the click event
   */
  disabled?: boolean
}

export const DropdownMenuItem = ({
  id,
  disabled,
  onClick,
  name,
  icon: IconComponent,
}: DropdownMenuItemProps) => {
  return (
    <Menu.Item disabled={disabled}>
      {({ active }) => (
        <button
          key={`DropdownMenuItem-${id}`}
          onClick={onClick}
          className={classNames('px-4 py-2 text-sm inline-flex w-full', {
            'bg-gray-100 text-gray-900': active,
            'text-gray-700': !active,
            'cursor-not-allowed bg-gray-50': disabled,
            'cursor-pointer': !disabled,
          })}
        >
          {IconComponent && (
            <span>
              <IconComponent className='-ml-1 mr-2 h-5 w-5 text-gray-400' aria-hidden='true' />
            </span>
          )}
          {name}
        </button>
      )}
    </Menu.Item>
  )
}
