import { Audit, AuditOperation } from '@/features/audits'
import { Task } from '@/features/tasks'
import { Automation } from '../../interfaces'

export type AuditAutomatedTask = Audit<Task & { truIds: string[] }, AuditOperation>
export type AuditAutomation = Audit<Automation, AuditOperation>
export type AuditAutomationAndAutomatedTaskRequest =
  | AuditAutomatedTask
  | AuditAutomation
  | Audit<Task | Automation, AuditOperation>

export enum ACTIVITY_TYPE {
  AUTOMATION_CREATED,
  AUTOMATION_EDITED,
  AUTOMATED_TASK_CREATED,
  PRODUCT_TRIGGER_DELETED,
  FORM_TARGET_DELETED,
}
