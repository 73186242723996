import { useCallback, useMemo } from 'react'
import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import {
  create,
  createWithCSV,
  getAll,
  getByIds,
  getByProduct,
  getByProductIds,
  getCount,
  getCsvTemplate,
  getPaginatedTRUs,
  remove,
  update,
} from './service'
import { ITRUFilters, TRU } from './interfaces'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { customErrorToTrack, useAnalytics } from '@/features/analytics'
import { useProducts } from '@/features/products'
import * as NotifyService from '@/services/notify.service'
import { useHistory } from 'react-router-dom'
import { BulkError, CustomError, ErrorCodes } from '@blockchain-traceability-sl/custom-error-codes'
import { downloader } from '@/helpers/downloader'
import { downloadTRUBulkErrorReport } from './helpers'
import { Paginated } from '@/hooks/use-pagination'
import { mergeInfiniteQueryOptions, mergeQueryOptions } from '@/utils/merge-query-options'
import { DEFAULT_PAGE_SIZE } from '@/app/constants'

export const QUERY_TRUS_KEY = 'trus'

/**
 * @deprecated use hooks with pagination or filters instead
 */
export const useQueryTRUs = (
  options?: Omit<UseQueryOptions<TRU[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(QUERY_TRUS_KEY, () => getAll(), options)

  return { ...rest, trus: useMemo(() => data || [], [data]) }
}

export const useTrusCount = (
  options?: Omit<UseQueryOptions<number, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data = 0, ...rest } = useQuery([QUERY_TRUS_KEY, { count: true }], getCount, options)

  return { ...rest, trusCount: data }
}

export const usePaginatedTRUs = ({
  filters,
  pageSize = DEFAULT_PAGE_SIZE,
  ...overrideOptions
}: Omit<UseInfiniteQueryOptions<Paginated<TRU>, AxiosError>, 'queryKey' | 'queryFn'> & {
  pageSize?: number
  filters: ITRUFilters
}) => {
  const queryClient = useQueryClient()

  const options = mergeInfiniteQueryOptions(overrideOptions, {
    keepPreviousData: true,
    // Cache each tru as individual cached item by id
    onSuccess(data) {
      overrideOptions?.onSuccess?.(data)
      data.pages
        .flatMap(({ items }) => items)
        .forEach(item => {
          queryClient.setQueryData([QUERY_TRUS_KEY, item._id], item)
        })
    },
    getNextPageParam: lastPage => lastPage.pageInfo.nextPage || undefined,
    getPreviousPageParam: lastPage => lastPage.pageInfo.previousPage || undefined,
  })

  const { data, ...rest } = useInfiniteQuery(
    [QUERY_TRUS_KEY, { filters, pageSize }, { isInfiniteQuery: true }],
    async ({ pageParam }) => {
      return getPaginatedTRUs(filters, {
        offset: pageParam?.offset || 0,
        limit: pageParam?.limit || pageSize,
      })
    },
    options
  )

  const trus = useMemo(() => data?.pages.flatMap(({ items }) => items) || [], [data?.pages])

  const totalCount = data?.pages[data?.pages.length - 1]?.totalCount || 0

  return {
    ...rest,
    data,
    trus,
    totalCount,
  }
}

export const useTRUById = (
  truId: string,
  options?: Omit<UseQueryOptions<TRU | undefined, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_TRUS_KEY, truId],
    async () => {
      /**
       * TODO: migrate to get by id endpoint (missing hydra endpoint)
       */
      const trus = await getByIds([truId])
      return trus[0]
    },
    options
  )

  return {
    ...rest,
    tru: data,
  }
}

export const useTRUsByIds = (
  truIds: string[],
  overrideOptions?: Omit<UseQueryOptions<TRU[], AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const queryClient = useQueryClient()

  const options = mergeQueryOptions(overrideOptions, {
    // Cache each reception as individual cached item by id
    onSuccess(data) {
      overrideOptions?.onSuccess?.(data)
      data.forEach(item => {
        queryClient.setQueryData([QUERY_TRUS_KEY, item._id], item)
      })
    },
  })

  const { data, ...rest } = useQuery([QUERY_TRUS_KEY, { truIds }], () => getByIds(truIds), options)
  const trus = useMemo(() => data || [], [data])

  return {
    ...rest,
    trus,
  }
}

export const useFetchTRUs = (filters?: ITRUFilters) => {
  const queryClient = useQueryClient()

  const fetchTRUs = useCallback(
    () => queryClient.fetchQuery([QUERY_TRUS_KEY, filters], () => getAll(filters)),
    [filters, queryClient]
  )

  return {
    fetchTRUs,
  }
}

export const useFetchTRUById = () => {
  const queryClient = useQueryClient()

  const fetchTRU = useCallback(
    (truId: string) =>
      queryClient.fetchQuery([QUERY_TRUS_KEY, truId], async (): Promise<TRU | undefined> => {
        const [tru] = await getByIds([truId])
        return tru
      }),
    [queryClient]
  )

  return {
    fetchTRU,
  }
}

export const useQueryTRUById = (
  truId: string,
  options?: Omit<UseQueryOptions<TRU | void, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_TRUS_KEY, truId],
    async (): Promise<TRU | void> => {
      const [result] = await getByIds([truId])
      return result
    },
    options
  )

  return { ...rest, tru: data }
}

export const useQueryTRUsByProduct = (
  productId: string,
  options?: Omit<UseQueryOptions<TRU[], AxiosError, TRU[]>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_TRUS_KEY, { product: productId }],
    () => getByProduct(productId),
    options
  )
  const trus = useMemo(() => data || [], [data])

  return { ...rest, trus }
}

export const useTRUsByProductIds = (
  productIds: string[],
  options?: Omit<UseQueryOptions<TRU[], AxiosError, TRU[]>, 'queryKey' | 'queryFn'>
) => {
  const { data, ...rest } = useQuery(
    [QUERY_TRUS_KEY, { productIds }],
    () => getByProductIds(productIds),
    options
  )
  const trus = useMemo(() => data || [], [data])

  return { ...rest, trus }
}

export const useCreateTRU = (notify?: boolean) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const { products } = useProducts()

  const { mutate, ...rest } = useMutation(create, {
    async onSuccess(truId, variables) {
      analytics.track('CUSTOMER_CREATE_TRU', {
        TRU: variables.truReference,
        Product: products.find(({ reference }) => reference === variables.productReference)?.name,
      })
      if (notify) {
        NotifyService.success({
          title: t('tru.create.success.title'),
          description: t('tru.create.success.description'),
        })
      }
      queryClient.invalidateQueries(QUERY_TRUS_KEY)
    },
    onError(error: AxiosError) {
      analytics.track(
        'CUSTOMER_CREATE_TRU_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return { ...rest, createTRU: mutate }
}

export const useUpdateTRU = (notify?: boolean) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const { products } = useProducts()
  const { trus } = useQueryTRUs()

  const { mutate, ...rest } = useMutation(update, {
    async onSuccess(data, variables) {
      const truToUpdate = trus.find(({ _id }) => _id === variables.truId)

      analytics.track('CUSTOMER_EDIT_TRU', {
        TRU: truToUpdate?.reference,
        Product: products.find(({ _id }) => _id === truToUpdate?.productId)?.name,
        Type: 'Creation',
        Source: 'Creation date',
      })
      if (notify) {
        NotifyService.success({
          title: t('tru.update.success.title'),
          description: t('tru.update.success.description'),
        })
      }
      queryClient.invalidateQueries(QUERY_TRUS_KEY)
    },
    onError(error: AxiosError) {
      analytics.track(
        'CUSTOMER_EDIT_TRU_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return { ...rest, updateTRU: mutate }
}

export const useDeleteTRU = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const { products } = useProducts()
  const { trus } = useQueryTRUs()

  const { mutate, ...rest } = useMutation(remove, {
    async onSuccess(data, variables) {
      const truToDelete = trus.find(({ _id }) => _id === variables)
      analytics.track('CUSTOMER_DELETE_TRU', {
        TRU: truToDelete?.reference,
        Product: products.find(({ _id }) => _id === truToDelete?.productId)?.name,
      })
      NotifyService.success({
        title: t('tru.delete.success.title'),
        description: t('tru.delete.success.description'),
      })
      queryClient.invalidateQueries(QUERY_TRUS_KEY)
    },
    onError(error: AxiosError) {
      analytics.track(
        'CUSTOMER_DELETE_TRU_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )
      NotifyService.error(NotifyService.customErrorToNotify(error.response?.data))
    },
  })

  return { ...rest, deleteTRU: mutate }
}

export const useCreateTRUsWithCSV = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('nsNotification')
  const analytics = useAnalytics()
  const history = useHistory()

  /**
   * Set as valid processed if is Bulk error and all errors are already exist
   */
  const isAcceptableError = (error: AxiosError) => {
    return (
      error.response?.data.code === ErrorCodes.BULK_ERROR &&
      (error.response?.data as BulkError).errors.every(
        ({ error: customError }) => customError.code === ErrorCodes.ALREADY_EXISTS
      )
    )
  }

  const { mutate, ...rest } = useMutation(createWithCSV, {
    onSuccess(data) {
      analytics.track('CUSTOMER_CREATE_TRU_CSV')
      NotifyService.success({
        title: t('tru.createBulk.success.title'),
        description: t('tru.createBulk.success.description', {
          count: data.total,
        }),
        actionText: t('tru.createBulk.success.action'),
        onActionClick: () => history.push('/trus/create'),
      })
      queryClient.invalidateQueries(QUERY_TRUS_KEY)
    },
    onError(error: AxiosError) {
      analytics.track(
        'CUSTOMER_CREATE_TRU_CSV_ERROR',
        customErrorToTrack(error.response?.data, error.response?.status)
      )

      switch (error.response?.data.code) {
        case ErrorCodes.BULK_ERROR:
          {
            const bulkError: BulkError = error.response?.data

            // Set as valid processed if all errors are already exist
            if (isAcceptableError(error)) {
              NotifyService.success({
                title: t('tru.createBulk.success.title'),
                description: t('tru.createBulk.success.description', {
                  count: bulkError.total,
                }),
                actionText: t('tru.createBulk.success.action'),
                onActionClick: () => history.push('/stakeholders/create'),
              })
            } else {
              NotifyService.error({
                title: t('tru.createBulk.bulkError.title'),
                description: t('tru.createBulk.bulkError.description', {
                  processed: bulkError.processed,
                  total: bulkError.total,
                }),
                actionText: t('tru.createBulk.bulkError.action'),
                onActionClick: () => downloadTRUBulkErrorReport(bulkError),
              })
            }
          }
          break

        case 10: // InvalidCSVError
          {
            const invalidCSVError: CustomError = error.response?.data

            if (invalidCSVError.entity !== 'file') {
              NotifyService.error({
                title: t('tru.createBulk.entityError.title'),
                description: t('tru.createBulk.entityError.description', {
                  context: invalidCSVError.entity,
                }),
                actionText: t('tru.createBulk.entityError.action'),
              })
            } else {
              NotifyService.error({
                title: t('tru.createBulk.error.title'),
                description: t('tru.createBulk.error.description'),
                actionText: t('tru.createBulk.error.action'),
              })
            }
          }
          break
        default:
          NotifyService.error({
            title: t('tru.createBulk.error.title'),
            description: t('tru.createBulk.error.description'),
            actionText: t('tru.createBulk.error.action'),
          })
          break
      }
    },
  })

  return { ...rest, createTRUsWithCSV: mutate, isAcceptableError }
}

export const useDownloadTemplateTRUsCSV = () => {
  const { data } = useQuery([QUERY_TRUS_KEY, { template: 'csv' }], getCsvTemplate)

  const downloadTemplate = (fileName: string) => {
    if (data) downloader(data, 'text/csv', fileName)
  }

  return { downloadTemplate }
}
