import { usePrevious } from '@/hooks/use-previous'
import { useEffect, useMemo } from 'react'
import {
  EditableEntry,
  EditableIssueTemplateInput,
  IssueTemplateInputError,
  TemplateEntryRedesignError,
  TemplateEntryType,
} from '../interfaces'
import { getErrorsByEntryIds } from '../utils'

export interface IssueReferenceHelpersOptions {
  targetEntry?: Pick<EditableEntry, 'id' | 'type'>
  issueTemplates?: EditableIssueTemplateInput[]
  issueTemplatesErrors?: IssueTemplateInputError[]
  setIssueTemplatesValue?: (issueTemplates: EditableIssueTemplateInput[]) => void
}

export interface IssueReferenceHelpers {
  issueTemplate?: EditableIssueTemplateInput
  errors: {
    [id: string]: TemplateEntryRedesignError | undefined
  }
  onIssueTemplateCreate: (issueTemplate: EditableIssueTemplateInput) => void

  onAddEntry: (newIssueTemplateEntry: EditableEntry) => void

  onChangeEntry: (updatedIssueTemplateEntry: EditableEntry) => void

  onRemoveEntry: (updatedIssueTemplateEntry: EditableEntry) => void

  onSort: (newOrderedEntries: EditableEntry[]) => void
}

export const useIssueReferenceHelpers = ({
  targetEntry,
  issueTemplates,
  issueTemplatesErrors,
  setIssueTemplatesValue,
}: IssueReferenceHelpersOptions): IssueReferenceHelpers => {
  const previousTargetEntryType = usePrevious(targetEntry?.type)

  useEffect(() => {
    if (!issueTemplates || !setIssueTemplatesValue || !targetEntry?.type) return

    if (
      targetEntry.type === TemplateEntryType.ISSUE_REFERENCE &&
      previousTargetEntryType !== targetEntry.type
    ) {
      const issueTemplate = issueTemplates.find(
        issueTemplate => issueTemplate.entryId === targetEntry.id
      )
      if (!issueTemplate) {
        setIssueTemplatesValue([...issueTemplates, { entryId: targetEntry.id, entries: [] }])
      }
    }

    if (
      previousTargetEntryType === TemplateEntryType.ISSUE_REFERENCE &&
      previousTargetEntryType !== targetEntry.type
    ) {
      setIssueTemplatesValue(
        issueTemplates.filter(issueTemplate => issueTemplate.entryId !== targetEntry.id)
      )
    }
  }, [
    targetEntry?.type,
    previousTargetEntryType,
    issueTemplates,
    targetEntry?.id,
    setIssueTemplatesValue,
  ])

  const { issueTemplate, errors } = useMemo(() => {
    const issueTemplateIndex =
      issueTemplates?.findIndex(item => item.entryId === targetEntry?.id) ?? -1
    if (issueTemplateIndex < 0) {
      return {
        errors: {},
      }
    }
    const issueTemplate = issueTemplates ? issueTemplates[issueTemplateIndex] : undefined
    const issueTemplateErrors = issueTemplatesErrors
      ? issueTemplatesErrors[issueTemplateIndex]
      : undefined

    const errors =
      issueTemplateErrors?.entries && issueTemplate
        ? getErrorsByEntryIds(issueTemplateErrors.entries, issueTemplate.entries)
        : {}
    return {
      issueTemplate,
      errors,
    }
  }, [issueTemplates, targetEntry?.id, issueTemplatesErrors])

  return {
    issueTemplate,
    errors,

    onIssueTemplateCreate: (issueTemplate: EditableIssueTemplateInput) => {
      if (!issueTemplates || !setIssueTemplatesValue) return
      setIssueTemplatesValue([...issueTemplates, issueTemplate])
    },

    onAddEntry: (newIssueTemplateEntry: EditableEntry) => {
      if (!issueTemplates || !setIssueTemplatesValue) return
      const updatedIssueTemplates = issueTemplates.map(issueTemplate =>
        issueTemplate.entryId === targetEntry?.id
          ? {
              ...issueTemplate,
              entries: [
                ...issueTemplate.entries.map(entry => ({ ...entry, isNewEntry: undefined })),
                {
                  ...newIssueTemplateEntry,
                  isNewEntry: issueTemplate.entries.length > 0,
                },
              ],
            }
          : issueTemplate
      )

      setIssueTemplatesValue(updatedIssueTemplates)
    },
    onChangeEntry: (updatedIssueTemplateEntry: EditableEntry) => {
      if (!issueTemplates || !setIssueTemplatesValue) return
      const updatedIssueTemplates = issueTemplates.map(issueTemplate => {
        if (issueTemplate.entryId !== targetEntry?.id) return issueTemplate
        const entryIndex = issueTemplate.entries.findIndex(
          entry => entry.id === updatedIssueTemplateEntry.id
        )

        if (entryIndex > -1) {
          issueTemplate.entries[entryIndex] = {
            ...issueTemplate.entries[entryIndex],
            ...updatedIssueTemplateEntry,
          }
        }

        return issueTemplate
      })

      setIssueTemplatesValue(updatedIssueTemplates)
    },
    onRemoveEntry: (updatedIssueTemplateEntry: EditableEntry) => {
      if (!issueTemplates || !setIssueTemplatesValue) return
      const updatedIssueTemplates = issueTemplates.map(issueTemplate => {
        if (issueTemplate.entryId !== targetEntry?.id) return issueTemplate
        issueTemplate.entries = issueTemplate.entries.filter(
          entry => entry.id !== updatedIssueTemplateEntry.id
        )

        return issueTemplate
      })

      setIssueTemplatesValue(updatedIssueTemplates)
    },
    onSort: (newOrderedEntries: EditableEntry[]) => {
      if (!issueTemplates || !setIssueTemplatesValue) return
      const updatedIssueTemplates = issueTemplates.map(issueTemplate => {
        if (issueTemplate.entryId !== targetEntry?.id) return issueTemplate
        issueTemplate.entries = newOrderedEntries

        return issueTemplate
      })

      setIssueTemplatesValue(updatedIssueTemplates)
    },
  }
}
