import { useCompanyUser } from '@/features/auth'
import { useDebounceState } from '@/hooks/use-debounce-state'
import { matchWithSearch } from '@/hooks/use-filter-algorithm'
import {
  SelectField,
  SelectFieldProps,
  SelectorOption,
  createSelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { useMemo, useState } from 'react'
import { usePaginatedUsers, useUserById } from '../hooks'
import { UserFilters } from '../interfaces'

export interface UsersSelectFieldProps
  extends Omit<SelectFieldProps, 'options'>,
    Pick<{ userIdValue?: string }, 'userIdValue'>,
    Omit<UserFilters, 'search'> {
  /**
   * Includes company in selector options
   */
  withCompany?: boolean
}

export const UsersSelectField = ({
  withCompany,
  departmentId,
  userIdValue,
  ...props
}: UsersSelectFieldProps) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const {
    isLoading: isLoadingUsers,
    users,
    fetchNextPage,
  } = usePaginatedUsers({
    filters: {
      search: debouncedSearch,
      departmentId,
    },
  })

  const companyUser = useCompanyUser()

  const { user: userValue, isLoading: isLoadingUserValue } = useUserById(userIdValue || '', {
    enabled: !!userIdValue,
  })

  const isLoading = isLoadingUsers || isLoadingUserValue

  const value = userValue ? userValue.toSelectorOption() : props.value

  const options: SelectorOption[] = useMemo(
    () => [
      ...(withCompany && matchWithSearch(companyUser.name, debouncedSearch)
        ? [createSelectorOption(companyUser.name, companyUser._id)]
        : []),
      ...users.map(user => user.toSelectorOption()),
    ],
    [companyUser._id, companyUser.name, debouncedSearch, users, withCompany]
  )

  return (
    <SelectField
      {...props}
      value={value}
      isLoading={isLoading}
      options={options}
      onInputChange={inputValue => {
        setSearch(inputValue)
      }}
      onMenuScrollToBottom={() => {
        fetchNextPage()
      }}
    />
  )
}
