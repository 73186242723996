import { TemplateComplianceEntityType } from '@/features/templates'
import { ComplianceEntityType } from '../interfaces'

export const remapCompliantEntityTypeFromTemplate = (
  templateCompliantEntityType?: TemplateComplianceEntityType | null
): ComplianceEntityType => {
  switch (templateCompliantEntityType) {
    case TemplateComplianceEntityType.STAKEHOLDER_PRODUCT:
      return ComplianceEntityType.STAKEHOLDER_PRODUCT
    default:
      return ComplianceEntityType.STAKEHOLDER
  }
}
