import { EntryFieldProps } from '../interfaces'
import { useTranslation } from 'react-i18next'
import { TemplateEntryType } from '@/features/templates'
import classNames from 'classnames'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { CheckboxField } from '@blockchain-traceability-sl/tailwind-components'
import { useEntryFieldAttributes } from '../utils'
import { useField } from 'formik'
import { EntryCreateInput } from '@/features/entries'
import { EntryLabel } from '../EntryLabel'

export const MultipleChoiceEntryField = ({ index, prefixName, disabled }: EntryFieldProps) => {
  const { t } = useTranslation('nsEntries')

  const { entryFieldName, valuesFieldName, enabledFieldName, enabledFieldId, valuesFieldId } =
    useEntryFieldAttributes(index, prefixName)

  const [{ value: entry }, { error, touched }] = useField<EntryCreateInput>(entryFieldName)
  const [, , enabledInputFieldHelpers] = useField<boolean>(enabledFieldName)
  const [, , valueInputFieldHelpers] = useField<string[]>(valuesFieldName)

  const requiredError = !!error && touched

  if (entry.type !== TemplateEntryType.MULTIPLE_CHOICE) return null

  const isEntryEnabled = entry.enabled ?? true
  const isInputDisabled = disabled || !isEntryEnabled

  const label = entry.mandatory ? `${entry.name} ${t('form.mandatory.indicator')}` : entry.name

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      valueInputFieldHelpers.setValue([
        ...entry.values.filter(value => !!value),
        event.currentTarget.value,
      ])
    } else {
      valueInputFieldHelpers.setValue(
        entry.values.filter(value => value !== event.currentTarget.value)
      )
    }
    // We need to set touched after the value has been set
    setImmediate(() => {
      valueInputFieldHelpers.setTouched(true)
    })
  }

  return (
    <div>
      <EntryLabel
        naCheckboxId={enabledFieldId}
        naCheckboxName={enabledFieldName}
        naUnchecked={isEntryEnabled}
        onNaChange={enabled => {
          enabledInputFieldHelpers.setValue(enabled)
          enabledInputFieldHelpers.setTouched(true)
        }}
        naCheckboxLabel={t('form.na')}
        withNa={!entry.mandatory}
        disabled={disabled}
      >
        <label
          className={classNames(`block text-sm font-medium`, {
            'text-red-700': requiredError,
            'text-gray-700': !requiredError,
            'cursor-not-allowed': isInputDisabled,
          })}
        >
          {label}
          {requiredError && (
            <ExclamationCircleIcon className='ml-1 -mr-1 h-5 w-5 text-red-500' aria-hidden='true' />
          )}
        </label>
      </EntryLabel>

      <div className='mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
        {entry.options.map((option, optionIndex) => (
          <CheckboxField
            key={`${valuesFieldId}_${optionIndex}`}
            id={`${valuesFieldId}_${optionIndex}`}
            name={`${valuesFieldName}.${optionIndex}`}
            label={option}
            value={option}
            onChange={handleChange}
            checked={entry.values.some(value => value === option)}
            disabled={isInputDisabled}
          />
        ))}
      </div>
    </div>
  )
}
