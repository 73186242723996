/**
 * Page size key for local storage
 * @see useLocalStorage
 * @internal
 * @constant
 * @nanoid length 10
 */
export const PAGE_SIZE_KEY = 'o3KB3LyqMq'

export const PERSISTED_TASK_LIST_VIEW_TYPE_KEY = 'task-list-view-type'
export const PERSISTED_TASK_LIST_VIEW_TAB = 'task-list-view-tab'
export const PERSISTED_TASK_FILTERS = 'task-list-filters'
export const PERSISTED_TASK_CALENDAR_VIEW_TYPE_KEY = 'task-calendar-view-type'
