import { useTranslation } from 'react-i18next'
import { SortableContainer } from '@/components/SortableContainer'
import {
  TemplateEntryReferenceType,
  TemplateEntryType,
  TemplateTableEntryColumn,
  useTemplateEntriesSelectorOptions,
} from '@/features/templates'
import { SortableEntry } from './SortableEntry'
import { SortableEntryEditable } from './SortableEntryEditable'
import { memo, useCallback, useMemo } from 'react'
import { nanoid } from 'nanoid'
import {
  ButtonWithIcon,
  CheckboxField,
  CreatableMultiField,
  InputField,
  SIZE,
  SelectField,
  SelectorOption,
  TextAreaField,
} from '@blockchain-traceability-sl/tailwind-components'
import { TableEntryField } from './TableEntryField'
import { PlusIcon } from '@heroicons/react/solid'
import { ConditionalEntryField } from './ConditionalEntryField'
import { SectionEntryField } from './SectionEntryField'
import {
  EditableEntry,
  EditableIssueTemplateInput,
  IssueTemplateInputError,
  TemplateEntryRedesignError,
} from '../interfaces'
import { isMandatoriableField, validateTemplateEntry } from '../utils'
import { IssueReferenceEntryField } from './IssueReferenceEntryField'
import { useIssueReferenceHelpers } from './hooks'
import { FileExpirationSelectionField } from './FileExpirationSelectionField'
import { DocumentSettingsExpirationDuration } from '@/features/documents'
import { deepDeleteEntry } from '@/features/entries'

export interface TemplateEntriesRedesignProps {
  // Values
  entries: EditableEntry[]
  newEntry: Omit<EditableEntry, 'editable'>
  issueTemplates?: EditableIssueTemplateInput[]
  // Errors
  entriesErrors?: TemplateEntryRedesignError[]
  newEntryErrors?: TemplateEntryRedesignError
  issueTemplatesErrors?: IssueTemplateInputError[]
  // Modifiers
  setEntriesValue: (entries: EditableEntry[]) => void
  setNewEntryValue: (entry: Omit<EditableEntry, 'editable'>) => void
  setIssueTemplatesValue?: (issueTemplates: EditableIssueTemplateInput[]) => void
  // Flags
  tableEnabled?: boolean
  statementEnabled?: boolean
  referenceEnabled?: boolean
  documentEnabled?: boolean
  fileEnabled?: boolean
  conditionalEnabled?: boolean
  sectionEnabled?: boolean
  mandatoryEnabled?: boolean
  issueReferenceEnabled?: boolean
  signatureEnabled?: boolean
  fileDocumentSettingsEnabled?: boolean
}

export const TemplateEntriesRedesign = memo(
  ({
    entries,
    newEntry,
    entriesErrors,
    newEntryErrors,
    setEntriesValue,
    setNewEntryValue,
    tableEnabled,
    statementEnabled,
    referenceEnabled,
    documentEnabled,
    fileEnabled,
    conditionalEnabled,
    sectionEnabled,
    issueReferenceEnabled,
    mandatoryEnabled = true,
    setIssueTemplatesValue,
    issueTemplates,
    issueTemplatesErrors,
    signatureEnabled,
    fileDocumentSettingsEnabled,
  }: TemplateEntriesRedesignProps) => {
    const { t } = useTranslation('nsTemplate')

    const entryTypeOptions = useTemplateEntriesSelectorOptions({
      referenceEnabled,
      statementEnabled,
      tableEnabled,
      documentEnabled,
      fileEnabled,
      conditionalEnabled,
      sectionEnabled,
      issueReferenceEnabled,
      signatureEnabled,
    })

    const hasEntriesErrors = useMemo(
      () => !!entriesErrors && Object.keys(entriesErrors).length !== 0,
      [entriesErrors]
    )

    const isEditionEnabled = useMemo(() => entries.some(({ editable }) => editable), [entries])

    const handleEntryAdd = useCallback(() => {
      setEntriesValue([
        ...entries.map(entry => ({ ...entry, editable: false })),
        { ...newEntry, editable: false },
      ])
      setNewEntryValue({
        id: nanoid(),
        name: '',
        type: TemplateEntryType.SHORT_TEXT,
        mandatory: false,
        values: [],
      })
    }, [entries, newEntry, setEntriesValue, setNewEntryValue])

    const handleEntryDelete = useCallback(
      (filterId: string) => {
        const entryToDelete = entries.find(({ id }) => id === filterId)
        if (!entryToDelete) return

        const entriesToDelete = deepDeleteEntry(filterId, entries)
        setEntriesValue(entries.filter(entry => !entriesToDelete.includes(entry.id)))

        if (
          entryToDelete.type === TemplateEntryType.ISSUE_REFERENCE &&
          setIssueTemplatesValue &&
          issueTemplates
        ) {
          setIssueTemplatesValue(
            issueTemplates.filter(issueTemplate => issueTemplate.entryId !== filterId)
          )
        }
      },
      [entries, setEntriesValue, setIssueTemplatesValue, issueTemplates]
    )

    const handleEntryEdit = useCallback(
      (id: string) => {
        if (!hasEntriesErrors) {
          setEntriesValue(
            entries.map(entry =>
              entry.id === id ? { ...entry, editable: true } : { ...entry, editable: false }
            )
          )
        }
      },
      [entries, hasEntriesErrors, setEntriesValue]
    )

    const handleEntryEditEnd = useCallback(() => {
      // If no errors
      if (!hasEntriesErrors) {
        setEntriesValue(entries.map(entry => ({ ...entry, editable: false })))
      }
    }, [entries, hasEntriesErrors, setEntriesValue])

    const entriesInFrontline = useMemo(() => entries.filter(({ blockId }) => !blockId), [entries])

    const entriesErrorsByIds = useMemo(() => {
      if (!entriesErrors) return {}
      return entries.reduce<{ [id: string]: TemplateEntryRedesignError | undefined }>(
        (accumulator, { id }, index) => {
          accumulator[id] = entriesErrors[index]
          return accumulator
        },
        {}
      )
    }, [entriesErrors, entries])

    const issueReferenceHelpers = useIssueReferenceHelpers({
      targetEntry: newEntry,
      issueTemplates,
      setIssueTemplatesValue,
      issueTemplatesErrors,
    })

    return (
      <>
        <SortableContainer
          items={entries}
          onSort={items => {
            setEntriesValue(items as EditableEntry[])
          }}
        >
          {entriesInFrontline.map(({ editable, ...entry }) =>
            !editable ? (
              <SortableEntry
                key={entry.id}
                {...entry}
                typeOptions={entryTypeOptions}
                hasEntriesErrors={hasEntriesErrors}
                sortable={!isEditionEnabled}
                onEntryEdit={handleEntryEdit}
                onEntryDelete={handleEntryDelete}
              />
            ) : (
              <SortableEntryEditable
                key={entry.id}
                {...entry}
                typeOptions={entryTypeOptions}
                hasKeysErrors={hasEntriesErrors}
                hasNameError={!!entriesErrorsByIds[entry.id]?.name}
                hasTypeError={!!entriesErrorsByIds[entry.id]?.type}
                hasSelectorError={!!entriesErrorsByIds[entry.id]?.values}
                hasContentError={!!entriesErrorsByIds[entry.id]?.values}
                onKeyEditEnd={handleEntryEditEnd}
                onKeyDelete={handleEntryDelete}
                onNameChange={value => {
                  setEntriesValue(
                    entries.map(targetEntry =>
                      entry.id === targetEntry.id ? { ...targetEntry, name: value } : targetEntry
                    )
                  )
                }}
                onTypeChange={value => {
                  setEntriesValue(
                    entries.map(targetEntry =>
                      targetEntry.id === entry.id
                        ? {
                            ...targetEntry,
                            type: value,
                            mandatory: false,
                            values: [],
                          }
                        : targetEntry
                    )
                  )
                }}
                onSelectorChange={value => {
                  setEntriesValue(
                    entries.map(targetEntry =>
                      entry.id === targetEntry.id ? { ...targetEntry, values: value } : targetEntry
                    )
                  )
                }}
                onContentChange={value => {
                  setEntriesValue(
                    entries.map(targetEntry =>
                      entry.id === targetEntry.id
                        ? { ...targetEntry, values: [value] }
                        : targetEntry
                    )
                  )
                }}
                onContentTableChange={value => {
                  setEntriesValue(
                    entries.map(targetEntry =>
                      entry.id === targetEntry.id ? { ...targetEntry, values: value } : targetEntry
                    )
                  )
                }}
                onMandatoryChange={event => {
                  setEntriesValue(
                    entries.map(targetEntry =>
                      entry.id === targetEntry.id
                        ? { ...targetEntry, mandatory: event }
                        : targetEntry
                    )
                  )
                }}
                onDocumentSettingsExpirationChange={value => {
                  setEntriesValue(
                    entries.map(targetEntry =>
                      entry.id === targetEntry.id
                        ? {
                            ...targetEntry,
                            documentSettings: {
                              ...targetEntry.documentSettings,
                              expirationDuration: value as DocumentSettingsExpirationDuration,
                            },
                          }
                        : targetEntry
                    )
                  )
                }}
                conditionalOptions={{
                  entries,
                  entriesErrors,
                  onAddEntryInBlock: (blockEntry, entryToAdd, valuesIndex) => {
                    const entryIdsInBlock = blockEntry.values[valuesIndex] as string[]
                    setEntriesValue([
                      ...entries.map(targetEntry => {
                        if (targetEntry.id === blockEntry.id) {
                          return { ...targetEntry, ...blockEntry }
                        }
                        if (entryIdsInBlock.includes(targetEntry.id)) {
                          return { ...targetEntry, isNewEntry: undefined }
                        }
                        return targetEntry
                      }),
                      entryToAdd,
                    ])
                  },
                  onRemoveEntryInBlock: (blockEntry, entryToRemove) => {
                    setEntriesValue(
                      entries
                        .filter(targetEntry => targetEntry.id !== entryToRemove.id)
                        .map(targetEntry =>
                          targetEntry.id === blockEntry.id
                            ? { ...targetEntry, ...blockEntry }
                            : targetEntry
                        )
                    )
                  },
                  onChangeEntryInBlock: entryToChange => {
                    setEntriesValue(
                      entries.map(targetEntry =>
                        targetEntry.id === entryToChange.id
                          ? { ...targetEntry, ...entryToChange }
                          : targetEntry
                      )
                    )
                  },
                  onChange: entryToChange => {
                    setEntriesValue(
                      entries.map(targetEntry =>
                        targetEntry.id === entryToChange.id
                          ? { ...targetEntry, ...entryToChange }
                          : targetEntry
                      )
                    )
                  },
                }}
                sectionOptions={{
                  entries,
                  entriesErrors,
                  onAddEntryInBlock: (entryToAdd, entriesInNestedBlock) => {
                    const normalizedEntries = entries.map(entry =>
                      entry.blockId === newEntry.id ? { ...entry, isNewEntry: undefined } : entry
                    )
                    const newEntries = [...normalizedEntries, entryToAdd]
                    if (entriesInNestedBlock) {
                      newEntries.push(...entriesInNestedBlock)
                    }
                    setEntriesValue(newEntries)
                  },
                  onRemoveEntryInBlock: entryToRemove => {
                    setEntriesValue(
                      entries.filter(targetEntry => targetEntry.id !== entryToRemove.id)
                    )
                  },
                  onChangeEntryInBlock: (entryToChange, nestedBlockOperation) => {
                    let newEntries = entries.map(targetEntry =>
                      targetEntry.id === entryToChange.id
                        ? { ...targetEntry, ...entryToChange }
                        : targetEntry
                    )
                    if (nestedBlockOperation) {
                      const { entry: nestedEntry, operation, valuesIndex } = nestedBlockOperation
                      if (operation === 'add') {
                        /**
                         * We need to clean up the isNewEntry, when an entry is inserted
                         */
                        newEntries = newEntries.map(targetEntry => {
                          const shouldBeNormalized =
                            (targetEntry.blockId === entryToChange.id &&
                              valuesIndex === undefined) ||
                            (valuesIndex !== undefined &&
                              (entryToChange.values[valuesIndex] as string[]).includes(
                                targetEntry.id
                              ))
                          if (shouldBeNormalized) {
                            return { ...targetEntry, isNewEntry: undefined }
                          }
                          return targetEntry
                        })
                        newEntries.push(nestedEntry)
                      } else {
                        newEntries.splice(
                          newEntries.findIndex(innerEntry => innerEntry.id === nestedEntry.id),
                          1
                        )
                      }
                    }
                    setEntriesValue(newEntries)
                  },
                  onSort: blockEntries => {
                    const blockEntryIds = blockEntries.map(entry => entry.id)

                    const filteredEntries = entries.filter(
                      entry => !blockEntryIds.includes(entry.id)
                    )

                    setEntriesValue([...filteredEntries, ...blockEntries])
                  },
                  issueReferenceEnabled,
                }}
                issueReferenceOptions={{
                  helpersOptions: {
                    issueTemplates,
                    setIssueTemplatesValue,
                    issueTemplatesErrors,
                  },
                }}
                referenceEnabled={referenceEnabled}
                documentEnabled={documentEnabled}
                mandatoryEnabled={mandatoryEnabled}
                signatureEnabled={signatureEnabled}
                fileDocumentSettingsEnabled={fileDocumentSettingsEnabled}
              />
            )
          )}
        </SortableContainer>

        <div className='grid md:grid-cols-3 gap-6 md:gap-4'>
          <div className='md:col-span-2'>
            <InputField
              key={`entry.name.${newEntry.id}`}
              id={`entry_name_${newEntry.id}`}
              name={`entry_name_${newEntry.id}`}
              onChange={event => setNewEntryValue({ ...newEntry, name: event.currentTarget.value })}
              autoFocus
              value={newEntry.name}
              label={t('entries.name.label')}
              description={t('entries.name.description')}
              error={!!newEntryErrors?.name}
            />
            {mandatoryEnabled &&
              isMandatoriableField(newEntry.type) &&
              newEntry.type !== TemplateEntryType.SINGLE_CHOICE &&
              newEntry.type !== TemplateEntryType.MULTIPLE_CHOICE && (
                <div className='mt-4'>
                  <CheckboxField
                    key={`entry.mandatory.${newEntry.id}`}
                    id={`entry_mandatory_${newEntry.id}`}
                    name={`entry.mandatory.${newEntry.id}`}
                    label={t('entries.checkbox.mandatory.label')}
                    checked={newEntry.mandatory}
                    onChange={event =>
                      setNewEntryValue({
                        ...newEntry,
                        mandatory: event.currentTarget.checked,
                      })
                    }
                  />
                </div>
              )}
          </div>
          <div className='md:col-span-1'>
            <SelectField
              menuPortalTarget={document.body}
              key={`entry_type_${newEntry.id}`}
              id={`entry_type_${newEntry.id}`}
              name={`entry.type.${newEntry.id}`}
              options={entryTypeOptions}
              value={entryTypeOptions
                .flatMap(group => group.options)
                .find(selection => selection.value === newEntry.type)}
              onChange={option =>
                setNewEntryValue({
                  ...newEntry,
                  mandatory: false,
                  type: option ? (option.value as TemplateEntryType) : TemplateEntryType.SHORT_TEXT,
                  values:
                    option &&
                    (option.value === TemplateEntryReferenceType.STAKEHOLDER ||
                      option.value === TemplateEntryReferenceType.PRODUCT ||
                      option.value === TemplateEntryReferenceType.TRU)
                      ? [{ type: option.value }]
                      : [],
                })
              }
              label={t('entries.type.label')}
              description={t('entries.type.description')}
            />

            {newEntry.type === TemplateEntryType.FILE && fileDocumentSettingsEnabled && (
              <div className='mt-4'>
                <FileExpirationSelectionField
                  key={`entry_document_settings_expiration_${newEntry.id}`}
                  id={`entry_document_settings_expiration_${newEntry.id}`}
                  name={`entry.documentSettings.expirationDuration.${newEntry.id}`}
                  value={newEntry.documentSettings?.expirationDuration}
                  onChange={option =>
                    setNewEntryValue({
                      ...newEntry,
                      documentSettings: {
                        ...newEntry.documentSettings,
                        expirationDuration: option?.value as DocumentSettingsExpirationDuration,
                      },
                    })
                  }
                />
              </div>
            )}
          </div>
        </div>

        <div>
          <div>
            {(newEntry.type === TemplateEntryType.SINGLE_CHOICE ||
              newEntry.type === TemplateEntryType.MULTIPLE_CHOICE) && (
              <>
                <div className='col-span-2'>
                  <CreatableMultiField
                    key={`entry.values.${newEntry.id}`}
                    id={`entry_values_${newEntry.id}`}
                    name={`entry.values.${newEntry.id}`}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    value={((newEntry as { values?: string[] }).values || []).map(
                      (value): SelectorOption => ({ label: value, value })
                    )}
                    onChange={options =>
                      setNewEntryValue({
                        ...newEntry,
                        values: options.map(({ value }) => value),
                      })
                    }
                    label={t('entries.selector.label')}
                    error={!!newEntryErrors?.values}
                  />
                </div>
                <div className='mt-4'>
                  <CheckboxField
                    key={`entry.mandatory.${newEntry.id}`}
                    id={`entry_mandatory_${newEntry.id}`}
                    name={`entry.mandatory.${newEntry.id}`}
                    label={t('entries.checkbox.mandatory.label')}
                    checked={newEntry.mandatory}
                    onChange={event =>
                      setNewEntryValue({
                        ...newEntry,
                        mandatory: event.currentTarget.checked,
                      })
                    }
                  />
                </div>
              </>
            )}
          </div>

          {newEntry.type === TemplateEntryType.STATEMENT && (
            <div className='mt-4'>
              <TextAreaField
                key={`entry.values.${newEntry.id}`}
                id={`entry_values_${newEntry.id}`}
                name={`entry.values.${newEntry.id}`}
                value={(newEntry.values[0] as string) || ''}
                onChange={event =>
                  setNewEntryValue({
                    ...newEntry,
                    values: [event.currentTarget.value],
                  })
                }
                label={t('entries.content.label')}
                description={t('entries.content.description')}
                error={!!newEntryErrors?.values}
              />
            </div>
          )}
        </div>

        {newEntry.type === TemplateEntryType.CONDITIONAL && (
          <div className='mt-4'>
            <ConditionalEntryField
              targetEntry={newEntry}
              entries={entries}
              entriesErrors={entriesErrors}
              onAddEntryInBlock={(blockEntry, entryToAdd, valuesIndex) => {
                const entryIdsInBlock = blockEntry.values[valuesIndex] as string[]
                const normalizedEntries = entries.map(entry =>
                  entryIdsInBlock.includes(entry.id) ? { ...entry, isNewEntry: undefined } : entry
                )
                setEntriesValue([...normalizedEntries, entryToAdd])
                setNewEntryValue({
                  ...newEntry,
                  ...blockEntry,
                })
              }}
              onRemoveEntryInBlock={(blockEntry, entryToRemove) => {
                setEntriesValue(entries.filter(entry => entry.id !== entryToRemove.id))
                setNewEntryValue({
                  ...newEntry,
                  ...blockEntry,
                })
              }}
              onChangeEntryInBlock={entryToChange => {
                setEntriesValue(
                  entries.map(entry =>
                    entry.id === entryToChange.id ? { ...entry, ...entryToChange } : entry
                  )
                )
              }}
              onChange={entry => {
                setNewEntryValue({
                  ...newEntry,
                  ...entry,
                })
              }}
              documentEnabled={documentEnabled}
              referenceEnabled={referenceEnabled}
              signatureEnabled={signatureEnabled}
              fileDocumentSettingsEnabled={fileDocumentSettingsEnabled}
            />
          </div>
        )}

        {newEntry.type === TemplateEntryType.SECTION && (
          <div className='mt-4'>
            <SectionEntryField
              targetEntry={newEntry}
              entries={entries}
              entriesErrors={entriesErrors}
              onAddEntryInBlock={(entryToAdd, entriesInNestedBlock) => {
                const normalizedEntries = entries.map(entry =>
                  entry.blockId === newEntry.id ? { ...entry, isNewEntry: undefined } : entry
                )
                const newEntries = [...normalizedEntries, entryToAdd]
                if (entriesInNestedBlock) {
                  newEntries.push(...entriesInNestedBlock)
                }
                setEntriesValue(newEntries)
              }}
              onRemoveEntryInBlock={entryToRemove => {
                setEntriesValue(entries.filter(entry => entry.id !== entryToRemove.id))
              }}
              onChangeEntryInBlock={(entryToChange, nestedBlockOperation) => {
                let newEntries = entries.map(targetEntry =>
                  targetEntry.id === entryToChange.id
                    ? { ...targetEntry, ...entryToChange }
                    : targetEntry
                )
                if (nestedBlockOperation) {
                  const { entry: nestedEntry, operation, valuesIndex } = nestedBlockOperation
                  if (operation === 'add') {
                    /**
                     * We need to clean up the isNewEntry, when an entry is inserted
                     */
                    newEntries = newEntries.map(targetEntry => {
                      const shouldBeNormalized =
                        (targetEntry.blockId === entryToChange.id && valuesIndex === undefined) ||
                        (valuesIndex !== undefined &&
                          (entryToChange.values[valuesIndex] as string[]).includes(targetEntry.id))
                      if (shouldBeNormalized) {
                        return { ...targetEntry, isNewEntry: undefined }
                      }
                      return targetEntry
                    })
                    newEntries.push(nestedEntry)
                  } else {
                    newEntries.splice(
                      newEntries.findIndex(innerEntry => innerEntry.id === nestedEntry.id),
                      1
                    )
                  }
                }
                setEntriesValue(newEntries)
              }}
              onSort={blockEntries => {
                const blockEntryIds = blockEntries.map(entry => entry.id)

                const filteredEntries = entries.filter(entry => !blockEntryIds.includes(entry.id))

                setEntriesValue([...filteredEntries, ...blockEntries])
              }}
              issueReferenceEnabled={issueReferenceEnabled}
              issueHelpersOptions={{
                issueTemplates,
                setIssueTemplatesValue,
                issueTemplatesErrors,
              }}
              documentEnabled={documentEnabled}
              referenceEnabled={referenceEnabled}
              signatureEnabled={signatureEnabled}
              fileDocumentSettingsEnabled={fileDocumentSettingsEnabled}
            />
          </div>
        )}

        {newEntry.type === TemplateEntryType.ISSUE_REFERENCE && (
          <div className='mt-4'>
            <IssueReferenceEntryField {...issueReferenceHelpers} />
          </div>
        )}

        <div className='flex justify-end'>
          <ButtonWithIcon
            color='secondary'
            size={SIZE.EXTRA_SMALL}
            IconComponent={PlusIcon}
            onClick={handleEntryAdd}
            disabled={!validateTemplateEntry(newEntry) || hasEntriesErrors}
          >
            {t('entries.addMore')}
          </ButtonWithIcon>
        </div>

        {newEntry.type === TemplateEntryType.TABLE && (
          <div className='mt-4'>
            <TableEntryField
              key={newEntry.id}
              onChange={(columns: TemplateTableEntryColumn[]): void => {
                setNewEntryValue({
                  ...newEntry,
                  values: columns,
                })
              }}
              referenceEnabled={referenceEnabled}
              documentEnabled={documentEnabled}
              signatureEnabled={signatureEnabled}
            />
          </div>
        )}
      </>
    )
  }
)
