import { Button, COLOR, IconCircle } from '@blockchain-traceability-sl/tailwind-components'
import { CheckCircleIcon } from '@heroicons/react/outline'
import { ReactNode } from 'react'

import '../steps.css'

export const OnboardingStepModalContent = ({
  title,
  description,
  nextButtonText,
  skipButtonText,
  backButtonText,
  closeButtonText,
  completeButtonText,
  skip,
  next,
  back,
  close,
  complete,
}: {
  title: string
  description: ReactNode
  skipButtonText?: string
  nextButtonText?: string
  backButtonText?: string
  closeButtonText?: string
  completeButtonText?: string
  skip?: () => void
  next?: () => void
  back?: () => void
  close?: () => void
  complete?: () => void
}) => (
  <div
    className='flex flex-col items-center justify-center'
    data-testid='onboarding-step-modal-content'
  >
    {complete && (
      <IconCircle IconComponent={CheckCircleIcon} color={COLOR.GREEN} className='mb-6' />
    )}
    <h2 className='text-lg leading-6 font-medium text-gray-900 mb-2'>{title}</h2>
    <p className='text-sm leading-5 font-normal text-gray-500'>{description}</p>
    <div className='grid grid-cols-2 gap-2 mt-6 w-full'>
      {skip && (
        <Button color='white' className='flex-grow' onClick={skip}>
          {skipButtonText}
        </Button>
      )}
      {back && (
        <Button color='white' className='flex-grow' onClick={back}>
          {backButtonText}
        </Button>
      )}

      {next && (
        <Button
          className='flex-grow'
          onClick={next}
          data-testid='onboarding-step-modal-next-button'
        >
          {nextButtonText}
        </Button>
      )}
    </div>
    {complete && (
      <div className='flex mt-6 w-full'>
        <Button
          className='flex-grow'
          onClick={complete}
          data-testid='onboarding-step-modal-complete-button'
        >
          {completeButtonText}
        </Button>
      </div>
    )}
    {close && (
      <button
        onClick={close}
        className='mt-6 text-sm leading-5 font-medium underline text-gray-700'
        color='link'
      >
        {closeButtonText}
      </button>
    )}
  </div>
)
