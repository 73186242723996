import i18n from '@/i18n'
import { Content, TableCell } from 'pdfmake/interfaces'
import { PDF_COMPONENTS } from '@/features/pdf'
import { Entry, TableEntryRow } from '../../interfaces'
import { TemplateEntryType } from '@/features/templates'
import dayjs from 'dayjs'

const EMPTY_VALUE = '-'

export const getTableEntryReportCellsByType = (
  { type, name, values, enabled }: Entry,
  { isChildren }: { isChildren?: boolean } = {}
) => {
  const baseStyles = isChildren
    ? { color: '#6B7280', fillColor: '#FFF', margin: [25, 0] }
    : { bold: true, margin: [15, 0] }

  switch (type) {
    case TemplateEntryType.FILE: {
      return [
        { ...baseStyles, text: name },
        !values[0] || values[0] === ''
          ? { ...baseStyles, text: EMPTY_VALUE }
          : {
              ...baseStyles,
              text: i18n.t('nsEntriesReport:document'),
              link: values[0],
              style: 'link',
            },
      ]
    }
    case TemplateEntryType.MULTIPLE_CHOICE: {
      return [
        { ...baseStyles, text: name },
        { ...baseStyles, text: values && values.length !== 0 ? values.join(', ') : EMPTY_VALUE },
      ]
    }
    case TemplateEntryType.BOOLEAN:
    case TemplateEntryType.CONDITIONAL: {
      return [
        { ...baseStyles, text: name },
        {
          ...baseStyles,
          text: i18n.t(`nsEntriesReport:boolean.${values[0]}`),
        },
      ]
    }
    case TemplateEntryType.ISSUE_REFERENCE: {
      return [
        { ...baseStyles, text: name },
        {
          ...baseStyles,
          text: i18n.t(`nsEntriesReport:boolean.${values.length === 0}`),
        },
      ]
    }

    case TemplateEntryType.LINK: {
      return [
        { ...baseStyles, text: name },
        !values[0] || values[0] === ''
          ? { ...baseStyles, text: EMPTY_VALUE }
          : {
              ...baseStyles,
              text: values[0],
              link: values[0],
              style: 'link',
            },
      ]
    }

    case TemplateEntryType.DOCUMENT: {
      return [
        { ...baseStyles, text: name },
        !values[0] || values[0] === ''
          ? { ...baseStyles, text: EMPTY_VALUE }
          : {
              ...baseStyles,
              text: values[1],
              link: values[0],
              style: 'link',
            },
      ]
    }

    case TemplateEntryType.STATEMENT:
      return [
        { ...baseStyles, text: name, fillColor: '#E5E7EB' },
        { ...baseStyles, text: values[0], fillColor: '#E5E7EB' },
      ]

    case TemplateEntryType.DATE:
      return [
        { ...baseStyles, text: name },
        {
          ...baseStyles,
          text: values[0]
            ? i18n.language === 'ar'
              ? dayjs(new Date(values[0] as string)).format('DD/MM/YYYY')
              : i18n.t('nsEntriesReport:date', { date: new Date(values[0] as string) })
            : EMPTY_VALUE,
        },
      ]

    case TemplateEntryType.SIGNATURE:
      return [
        { ...baseStyles, text: name },
        {
          ...baseStyles,
          ...(values.length === 0
            ? { text: EMPTY_VALUE }
            : {
                stack: values.map(signatureSvg => ({
                  width: 150,
                  svg: signatureSvg,
                })),
              }),
        },
      ]

    case TemplateEntryType.SECTION:
      return [
        { ...baseStyles, text: name, fillColor: '#D1D5DB' },
        {
          ...baseStyles,
          text: enabled === false ? i18n.t(`nsEntriesReport:section.na`) : EMPTY_VALUE,
          fillColor: '#D1D5DB',
        },
      ]

    case TemplateEntryType.NUMBER:
      return [
        { ...baseStyles, text: name },
        { ...baseStyles, text: values[0] ? i18n.t('number', { value: values[0] }) : EMPTY_VALUE },
      ]
    default:
      return [
        { ...baseStyles, text: name },
        { ...baseStyles, text: values[0] || EMPTY_VALUE },
      ]
  }
}

const getReportTableCellsForConditionalType = (
  entry: Entry,
  allEntries: Entry[]
): TableCell[][] => {
  const [ifEntries = [], elseEntries = []] = entry.options as [string[], string[]]

  const conditionalEntryIds = entry.values[0] === 'true' ? ifEntries : elseEntries

  return allEntries
    .filter(
      innerEntry =>
        innerEntry.id && conditionalEntryIds.includes(innerEntry.id) && innerEntry.enabled !== false
    )
    .map(entry => getTableEntryReportCellsByType(entry, { isChildren: true }))
}

const getReportTableCellsForSectionType = (
  entry: Entry,
  allEntries: (Entry & { issuesEntries?: Entry[] })[]
): TableCell[][] => {
  return allEntries
    .filter(innerEntry => innerEntry.blockId === entry.id && innerEntry.enabled !== false)
    .reduce<TableCell[][]>((accumulator, innerEntry) => {
      accumulator.push(getTableEntryReportCellsByType(innerEntry))
      if (innerEntry.type === TemplateEntryType.CONDITIONAL) {
        accumulator.push(...getReportTableCellsForConditionalType(innerEntry, allEntries))
      }
      if (innerEntry.type === TemplateEntryType.ISSUE_REFERENCE && innerEntry.issuesEntries) {
        accumulator.push(
          ...innerEntry.issuesEntries.map(entry => getTableEntryReportCellsByType(entry))
        )
      }
      return accumulator
    }, [])
}

export const generateEntriesReportRows = ({
  entries,
  tables,
  direction,
}: {
  entries: (Entry & {
    issuesEntries?: Entry[]
  })[]
  tables: {
    name: string
    columns: string[]
    rows: TableEntryRow[][]
  }[]
  direction: 'rtl' | 'ltr'
}) => [
  {
    style: 'table',
    color: '#444',
    table: {
      widths: [120, '*'],
      body: [
        [
          { text: i18n.t('nsEntriesReport:table.key'), style: 'tableHeader', marginLeft: 15 },
          { text: i18n.t('nsEntriesReport:table.value'), style: 'tableHeader', marginLeft: 15 },
        ],
        ...entries
          .filter(entry => !entry.blockId)
          .reduce<TableCell[][]>((accumulator, entry) => {
            accumulator.push(getTableEntryReportCellsByType(entry))

            if (entry.type === TemplateEntryType.CONDITIONAL) {
              accumulator.push(...getReportTableCellsForConditionalType(entry, entries))
            }

            if (entry.type === TemplateEntryType.SECTION) {
              accumulator.push(...getReportTableCellsForSectionType(entry, entries))
            }

            if (entry.type === TemplateEntryType.ISSUE_REFERENCE && entry.issuesEntries) {
              accumulator.push(
                ...entry.issuesEntries.map(entry =>
                  getTableEntryReportCellsByType(entry, { isChildren: true })
                )
              )
            }

            return accumulator
          }, []),
      ].map(row => (direction === 'rtl' ? row.reverse() : row)),
    },
    layout: 'tableWithoutVerticalLinesLayout',
  },

  ...tables.map((entryTable): Content => {
    const maxRowSize = Math.max(...entryTable.rows.map(row => row.length))
    const tableBodyRows: Content[][] = []

    for (let index = 0; index < maxRowSize; index++) {
      tableBodyRows[index] = []
      entryTable.rows.forEach(row => {
        const cell = row[index]

        if (cell) {
          switch (cell.type) {
            case TemplateEntryType.FILE: {
              tableBodyRows[index].push(
                !cell.values[0] || cell.values[0] === ''
                  ? EMPTY_VALUE
                  : {
                      text: i18n.t('nsEntriesReport:document'),
                      link: cell.values[0] as string,
                      style: 'link',
                    }
              )
              break
            }
            case TemplateEntryType.MULTIPLE_CHOICE: {
              tableBodyRows[index].push(cell.values.join(', '))
              break
            }
            case TemplateEntryType.BOOLEAN: {
              tableBodyRows[index].push(i18n.t(`nsEntriesReport:boolean.${cell.values[0]}`))
              break
            }

            case TemplateEntryType.LINK: {
              tableBodyRows[index].push(
                !cell.values[0] || cell.values[0] === ''
                  ? EMPTY_VALUE
                  : {
                      text: cell.values[0] as string,
                      link: cell.values[0] as string,
                      style: 'link',
                    }
              )
              break
            }

            case TemplateEntryType.DOCUMENT: {
              tableBodyRows[index].push(
                !cell.values[0] || cell.values[0] === ''
                  ? EMPTY_VALUE
                  : {
                      text: cell.values[1] as string,
                      link: cell.values[0] as string,
                      style: 'link',
                    }
              )
              break
            }

            case TemplateEntryType.SIGNATURE: {
              tableBodyRows[index].push(
                cell.values.length === 0
                  ? EMPTY_VALUE
                  : {
                      stack: (cell.values as string[]).map(signatureSvg => ({
                        width: 150,
                        svg: signatureSvg,
                      })),
                    }
              )
              break
            }

            case TemplateEntryType.DATE: {
              tableBodyRows[index].push(
                !cell.values[0] || cell.values[0] === ''
                  ? EMPTY_VALUE
                  : i18n.language === 'ar'
                  ? dayjs(new Date(cell.values[0] as string)).format('DD/MM/YYYY')
                  : i18n.t('nsEntriesReport:date', { date: new Date(cell.values[0] as string) })
              )
              break
            }

            default:
              tableBodyRows[index].push({
                text: cell.values[0] as string,
              })
              break
          }
        } else {
          tableBodyRows[index].push('')
        }
      })
    }

    return [
      {
        pageOrientation: 'landscape',
        pageBreak: 'before',
        stack: [
          PDF_COMPONENTS.subHeader(entryTable.name),
          {
            style: 'table',
            table: {
              widths: new Array(entryTable.columns.length).fill('auto'),
              body: [
                entryTable.columns.map(column => ({ text: column, style: 'tableHeader' })),
                ...tableBodyRows,
              ],
            },
            layout: 'tableVerticalLayout',
          },
        ],
      },
    ]
  }),
]
