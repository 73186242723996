import { MenuIcon } from '@heroicons/react/outline'
import { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useDashboardRoutes } from '@/routes/router'
import { Sidebar } from './Sidebar'
import { ModalCreateNew } from './ModalCreateNew'
import { useUserPermissions, useUserType } from '@/features/auth'
import { getRedirectBasedOnPermissions } from '@/helpers/permissions'
import { OnboardingTourGuide } from '../../onboarding'
import { NotificationPanelProvider, NotificationsPanel } from '@/features/notifications'
import { useLayout } from '../context'
import { MAIN_CONTENT_ELEMENT_ID } from '../constants'
import { SubscriptionStatusBanner } from '@/features/pricing'

export const Layout = () => {
  const { toggleSidebar, mainContentElementRef } = useLayout()
  const dashboardRoutes = useDashboardRoutes()
  const userPermissions = useUserPermissions()
  const userType = useUserType()

  return (
    <NotificationPanelProvider>
      <div className='h-screen flex overflow-hidden bg-gray-100'>
        <Sidebar />
        <NotificationsPanel />

        <div className='flex flex-col w-0 flex-1 overflow-hidden'>
          <SubscriptionStatusBanner />
          <div className='lg:hidden pl-1 pt-1 sm:pl-3 sm:pt-3'>
            <button
              data-testid='sidebar-main-menu'
              className='-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500'
              onClick={() => toggleSidebar(true)}
            >
              <span className='sr-only'>Open sidebar</span>
              <MenuIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>

          <main
            id={MAIN_CONTENT_ELEMENT_ID}
            ref={mainContentElementRef}
            className='flex-1 relative z-0 overflow-y-auto focus:outline-none'
          >
            <Suspense fallback={null}>
              <Switch>
                {dashboardRoutes.map(({ path, exact, Page }, index) => (
                  <Route key={index} path={path} exact={exact}>
                    <Page />
                  </Route>
                ))}
                <Redirect from='/' to={getRedirectBasedOnPermissions(userType, userPermissions)} />
              </Switch>
            </Suspense>
          </main>
        </div>

        <ModalCreateNew />
        <OnboardingTourGuide />
      </div>
    </NotificationPanelProvider>
  )
}
