import { useCompanyUser } from '@/features/auth'
import {
  Button,
  CreatableMultiField,
  Modal,
  SIZE,
  createSelectorOption,
} from '@blockchain-traceability-sl/tailwind-components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useRequestExportData } from '../hooks'

export interface ModalRequestExportDataProps {
  show: boolean
  toggle: () => void
}

export const ModalRequestExportData = ({ show, toggle }: ModalRequestExportDataProps) => {
  const { t } = useTranslation('nsProfilePage', { keyPrefix: 'personalInfoTab.request' })

  const { email } = useCompanyUser()
  const { requestExportData } = useRequestExportData()

  const { values, errors, touched, submitForm, setFieldValue, setFieldTouched } = useFormik({
    initialValues: { emails: [email] },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      emails: Yup.array().of(Yup.string().email().required()).min(1).required(),
    }),
    onSubmit: data => {
      requestExportData(data.emails)
      toggle()
    },
  })

  return (
    <Modal size={SIZE.LARGE} show={show} toggle={toggle} dismissButton>
      <Modal.Title>{t('title')}</Modal.Title>
      <Modal.Body className='text-left'>
        <p className='text-sm leading-5 font-normal text-gray-900 text-center mb-8'>
          {t('description')}
        </p>

        <CreatableMultiField
          id='stakeHolderEmails'
          name='stakeHolderEmails'
          label={t('form.stakeHolderEmails.label')}
          description={t('form.stakeHolderEmails.description')}
          createLabel={t('form.stakeHolderEmails.createLabel').toString()}
          options={[createSelectorOption(email)]}
          value={values.emails.map(item => createSelectorOption(item))}
          onChange={value => {
            setFieldValue(
              'emails',
              value.map(item => item.value)
            )
            setFieldTouched('emails', true)
          }}
          error={!!(touched.emails && errors.emails)}
        />
        <div className='flex justify-end mt-6'>
          <Button onClick={submitForm}>{t('submit')}</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
