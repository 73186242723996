import { AxiosInstance } from 'axios'
import Config from '../config'

const FRONTEND_VERSION_HEADER_KEY = 'x-trazable-frontend-version'
const FRONTEND_PROJECT_HEADER_KEY = 'x-trazable-frontend-project'
const FRONTEND_ROUTE_HEADER_KEY = 'x-trazable-frontend-route'

export const addTraceInterceptors = (api: AxiosInstance) => {
  api.interceptors.request.use(function (config) {
    config.headers[FRONTEND_VERSION_HEADER_KEY] = Config.MICROSERVICE_VERSION
    config.headers[FRONTEND_PROJECT_HEADER_KEY] = Config.MICROSERVICE_NAME
    config.headers[FRONTEND_ROUTE_HEADER_KEY] = window.location.pathname
    return config
  })
}
