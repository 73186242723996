import { useTranslation } from 'react-i18next'
import { getButtonClassName } from '@blockchain-traceability-sl/tailwind-components'
import { useAnalytics } from '@/features/analytics'
import { Link } from 'react-router-dom'
import { useProductById } from '@/features/products'
import { useReferrer } from '@/hooks/use-referrer'

export const AvailableTruOriginsEmpty = ({ productId }: { productId: string }) => {
  const { t } = useTranslation('nsTRUCreationManualPage', { keyPrefix: 'form.origins' })
  const analytics = useAnalytics()
  const { generateReferrerUrl } = useReferrer()

  const { product } = useProductById(productId)

  return (
    <div className='lg:grid md:grid-cols-3 md:gap-6 space-y-6 lg:space-y-0'>
      <div className='lg:col-span-2'>
        <h3 className='text-lg font-medium leading-6 text-gray-900'>
          {t('empty.label', {
            productName: product?.name,
          })}
        </h3>
        <p className='mt-1 text-sm text-gray-500'>{t('empty.description')}</p>
      </div>
      <div className='flex items-end'>
        <Link
          to={generateReferrerUrl({
            url: '/receptions/create',
            fieldName: null,
          })}
          className={getButtonClassName({ color: 'secondary' })}
          onClick={() => {
            analytics.track('ACTION_CLICK_CREATE_RECEPTION')
          }}
        >
          {t('empty.action')}
        </Link>
      </div>
    </div>
  )
}
