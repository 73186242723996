import { SwitchField } from '@blockchain-traceability-sl/tailwind-components'
import { useTranslation } from 'react-i18next'
import { TemplateEntryType } from '@/features/templates'
import classNames from 'classnames'
import { EntryFieldProps } from '../interfaces'
import { useEntryFieldAttributes } from '../utils'
import { useField } from 'formik'
import { EntryCreateInput } from '../../interfaces'
import { EntryLabel } from '../EntryLabel'

export const BooleanEntryField = ({ index, prefixName, disabled }: EntryFieldProps) => {
  const { t } = useTranslation('nsEntries')

  const { entryFieldName, valueFieldName, enabledFieldName, enabledFieldId, valueFieldId } =
    useEntryFieldAttributes(index, prefixName)

  const [{ value: entry }] = useField<EntryCreateInput>(entryFieldName)
  const [, , enabledInputFieldHelpers] = useField<boolean>(enabledFieldName)
  const [, , valueInputFieldHelpers] = useField<boolean>(valueFieldName)

  if (entry.type !== TemplateEntryType.BOOLEAN) return null

  const isEntryEnabled = entry.enabled ?? true

  return (
    <div
      className={classNames({
        'mt-8 sm:mt-0': entry.blockId,
      })}
    >
      <SwitchField
        id={valueFieldId}
        name={valueFieldName}
        reverse
        label={
          <EntryLabel
            naCheckboxId={enabledFieldId}
            naCheckboxName={enabledFieldName}
            naUnchecked={isEntryEnabled}
            onNaChange={enabled => {
              enabledInputFieldHelpers.setValue(enabled)
              enabledInputFieldHelpers.setTouched(true)
            }}
            naCheckboxLabel={t('form.na')}
            mandatoryPrefixText={t('form.mandatory.indicator')}
            isMandatory={entry.mandatory}
            disabled={disabled}
            lightTextOnNAChecked
            lightTextOnDisabled
          >
            {entry.name}
          </EntryLabel>
        }
        onChange={checked => {
          valueInputFieldHelpers.setValue(checked)
          valueInputFieldHelpers.setTouched(true)
        }}
        checked={entry.value}
        disabled={!isEntryEnabled || disabled}
      />
    </div>
  )
}
