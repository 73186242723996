import { Stat } from '@blockchain-traceability-sl/tailwind-components'
import { useTranslation } from 'react-i18next'
import { useDocumentCount } from '../hooks'
import { FolderOpenIcon } from '@heroicons/react/outline'
import { useAnalytics } from '@/features/analytics'
import { useHistory } from 'react-router-dom'

export const StatDocumentCount = () => {
  const { t } = useTranslation('nsHomePage', { keyPrefix: 'stats.documents' })
  const { documentsCount } = useDocumentCount()
  const analytics = useAnalytics()
  const history = useHistory()

  return (
    <Stat
      name={t('name').toString()}
      stat={documentsCount}
      IconComponent={FolderOpenIcon}
      actionText={t('action').toString()}
      onActionClick={() => {
        analytics.track('ACTION_CLICK_TOP_ACTIVITY', {
          Source: 'Documents',
        })
        history.push('/documents')
      }}
    />
  )
}
