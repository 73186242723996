import { noop } from '@/utils/noop'
import { createContext } from 'react'
import { IAuthenticationContext } from './interfaces'

export const AuthenticationContext = createContext<IAuthenticationContext>({
  isAuthenticated: false,
  isActivated: false,
  isAuthenticating: false,
  isActivationLoading: false,
  loginWithEmailAndPassword: noop,
  loginWithSSO: noop,
  logout: noop,
  refreshToken: noop,
})
