import {
  DEFAULT_PAGE_SIZE,
  PDF_MANUAL_EN_URL,
  PDF_MANUAL_ES_URL,
  TABLE_SIZE_PRESETS,
} from '@/app/constants'
import { useAnalytics } from '@/features/analytics'
import { noop } from '@/utils/noop'
import { Button, Loader, SIZE, Table } from '@blockchain-traceability-sl/tailwind-components'
import { memo, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Row } from 'react-table'

import {
  AutomationTargetOperation,
  AutomationTriggerType,
  EntityEventType,
  TimeIncrement,
} from '../interfaces'
import { useLocalStorage } from '@/hooks/use-local-storage'
import { ExtendedAutomationTriggerType } from './CreateAutomationStep'
import i18n from '@/i18n'

export type AutomationsListItem = {
  isExpired?: boolean
  operation?: string
  id: string
  name: string
  triggerType: AutomationTriggerType
  entityEventType?: EntityEventType
  dueDate: TimeIncrement
  disabled?: boolean
}

export type AutomationsListItemClickResult = Row<AutomationsListItem>

export interface AutomationsListProps {
  isLoading?: boolean
  globalFilter?: string
  /**
   * Must be memoized to avoid extra renders
   */
  data: AutomationsListItem[]
  /**
   * Must be memoized to avoid extra renders
   */
  onRowClick?: (row: AutomationsListItemClickResult) => void
}

const getI18nKeyForTriggerType = (
  triggerType: ExtendedAutomationTriggerType | AutomationTriggerType,
  entityEventType?: EntityEventType
) => {
  if (entityEventType && entityEventType === EntityEventType.COMPLIANCE) {
    return 'table.types.complianceEvent'
  }

  return `table.types.${triggerType}`
}

/**
 * Page size key for local storage
 * @see useLocalStorage
 * @internal
 * @constant
 * @nanoid length 10
 */
const PAGE_SIZE_KEY = 'IIsQuN3tBw'

export const AutomationsList = memo(
  ({ isLoading, globalFilter, data, onRowClick = noop }: AutomationsListProps) => {
    const { t } = useTranslation('nsAutomationsListPage')
    const analytics = useAnalytics()
    const [pageSize, setPageSize] = useLocalStorage(PAGE_SIZE_KEY, DEFAULT_PAGE_SIZE)
    const language = i18n.language

    const userManualUrl = useMemo(() => {
      if (language === 'es') {
        return PDF_MANUAL_ES_URL
      }
      return PDF_MANUAL_EN_URL
    }, [language])
    const getType = (row: AutomationsListItem) => {
      if (row.isExpired && row.operation === AutomationTargetOperation.UPDATE) {
        return t(
          getI18nKeyForTriggerType(ExtendedAutomationTriggerType.RESCHEDULE, row.entityEventType)
        )
      } else {
        return t(getI18nKeyForTriggerType(row.triggerType, row.entityEventType))
      }
    }

    const getDuedate = (dueDate: TimeIncrement) => {
      if (dueDate === TimeIncrement.TODAY) {
        return t(`table.dueDates.24h`)
      } else {
        return dueDate ? t(`table.dueDates.${dueDate}`) : '-'
      }
    }

    return (
      <Table
        globalFilter={globalFilter}
        columns={[
          {
            Header: t('table.name').toString(),
            accessor: 'name',
          },
          { Header: t('table.type').toString(), accessor: 'type' },
          {
            Header: t('table.dueDate').toString(),
            accessor: 'dueDate',
          },
          {
            Header: t('table.actions').toString(),
            accessor: 'actions',
            disableGlobalFilter: true,
          },
        ]}
        data={data.map(row => ({
          ...row,
          type: getType(row),
          dueDate: getDuedate(row.dueDate),
          actions: (
            <Button color='link' disabled={row.disabled}>
              {t('table.action.cell')}
            </Button>
          ),
        }))}
        isLoading={isLoading}
        onRowClick={row => {
          if (row.original.disabled) return
          onRowClick(row as unknown as AutomationsListItemClickResult)
        }}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        previousText={t('pagination.previous')}
        nextText={t('pagination.next')}
        renderInfoText={(from, to, total) => (
          <Trans
            t={t}
            i18nKey='pagination.show'
            components={{ b: <span className='font-semibold' /> }}
            values={{ from, to, total }}
          />
        )}
        sizePresetsText={t('pagination.items')}
        showSizePresets
        sizePresets={TABLE_SIZE_PRESETS}
        noDataText={
          <tr>
            <td colSpan={5} className='text-center p-8 space-y-6'>
              <p className='text-4xl leading-10 font-extrabold text-center whitespace-pre-wrap'>
                {t('table.noData')}
              </p>
              <Button
                color='secondary'
                size={SIZE.EXTRA_LARGE}
                className='m-auto'
                onClick={() => {
                  analytics.track('ACTION_CLICK_HELP', { Source: 'Automations section' })
                  window.open(userManualUrl, '_blank')
                }}
              >
                {t('table.needHelp')}
              </Button>
            </td>
          </tr>
        }
        loadingText={
          <tr>
            <td colSpan={4}>
              <Loader center='horizontal' className='w-full m-3' size={SIZE.SMALL} />
            </td>
          </tr>
        }
      />
    )
  }
)
