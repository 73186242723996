export interface UploadRequestCreate {
  requestee: { id: string; email: string }
  subject: string
  content: string
  targetFolderId: string
  replaceDocumentId?: string
  documentsLimit?: number
  timezone: string
}

export interface PublicUploadRequest {
  documentsLimit?: number
  message: {
    subject: string
    content: string
  }
}

export interface UploadRequest {
  readonly _id: string
  readonly companyId: string
  requestee: {
    id: string
    email: string
  }
  message: {
    subject: string
    content: string
  }
  targetFolderId: string
  /**
   * Document id to replace
   * @default undefined
   * @description
   * If not set, the upload request will create a new document
   * If set, the upload request will replace the document with the given id and save the old version
   * @important
   * If the upload request is replacing a document, the upload request must have a documents limit of 1
   */
  replaceDocumentId?: string
  upload: {
    /**
     * Id to use to public access instead of _id
     */
    publicId: string
    status: UploadRequestUploadStatus
    /**
     * Documents id to refer to the documents created from this upload request
     */
    documents: string[]
    uploadedAt?: Date
    /**
     * Limit of documents to upload
     * @default undefined
     * @description
     * If not set, the limit is unlimited
     * @important
     * If the upload request is replacing a document, the upload request documents limit is set to 1
     */
    documentsLimit?: number
  }
  /**
   * Timezone of the user that created the upload request
   * @default undefined
   */
  timezone?: string
  createdAt: Date
  readonly createdBy: string
  updatedAt: Date
  updatedBy: string
}

export enum UploadRequestUploadStatus {
  PENDING = 'pending',
  DONE = 'done',
}
export interface UploadRequestFilters {
  status?: UploadRequestUploadStatus
  /**
   * date in ISO format
   */
  startCreatedAt?: string
}
